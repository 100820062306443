import React, { useState, Fragment } from 'react';
import styledComponents from './styles';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';

import ModalTest from '../../components/Modal';
import useDelayModalUnmount from '../../components/Modal/hook';

const {
  H2,
  ViewAllButton,
} = commonStyles;

const {
  PricingAssumptionsContainer,
  PricingAssumptions,
  UnorderedListTruncated,
  EstimateDivider
} = styledComponents;

export default function PricingAssumptionsSection({ fetchedRequestData, noRebates, translations }) {

  const dataPA = fetchedRequestData.PricingAssumptions;
  const dataPE = fetchedRequestData.PricingExclusions;

  const [isMountedPA, setIsMountedPA] = useState({ show: false, data: null }); // keeps track of user desktop modal click
  const [isMountedPE, setIsMountedPE] = useState({ show: false, data: null });

  const modalOpenPA = useDelayModalUnmount(isMountedPA.show, 250, isMountedPA.data); // delays before switching boolean value of modalOpen for desktop
  const modalOpenPE = useDelayModalUnmount(isMountedPE.show, 250, isMountedPE.data);

  // For use with desktop modal
  const toggleMountModalPA = (props) => {
    setIsMountedPA({ show: !isMountedPA.show, data: props });
  }

  const toggleMountModalPE = (props) => {
    setIsMountedPE({ show: !isMountedPE.show, data: props });

  }

  return (
    <React.Fragment>
      <PricingAssumptionsContainer>
        <PricingAssumptions>
          <H2>{translations.pricingAssumptionsSection.titleOne}</H2>
          <UnorderedListTruncated>
            {
              dataPA.map((item, index) => {
                return (
                  <Fragment key={index + Math.random().toString(36).substring(2, 9)}>
                    <li>{item}</li>
                  </Fragment>
                )
              })
            }
          </UnorderedListTruncated>
          <ViewAllButton onClick={() => toggleMountModalPA('Pricing Assumptions')}>{translations.pricingAssumptionsSection.button}</ViewAllButton>
          {/* Desktop Modal 1 */}
          {modalOpenPA &&
            <ModalTest isMounted={isMountedPA.show}
              modalType={'Pricing Assumptions'}
              toggleMountModal={toggleMountModalPA}
              title={translations.pricingAssumptionsSection.titleOne}
              content={dataPA}
            />
          }
        </PricingAssumptions>
        <PricingAssumptions>
          <H2>{translations.pricingAssumptionsSection.titleTwo}</H2>
          <UnorderedListTruncated>
            {
              dataPE.map((item, index) => {
                return (
                  <li key={index + Math.random().toString(36).substring(2, 9)}>{item}</li>
                )
              })
            }
          </UnorderedListTruncated>
          <ViewAllButton onClick={() => toggleMountModalPE('Pricing Exclusions')}>{translations.pricingAssumptionsSection.button}</ViewAllButton>
          {/* Desktop Modal 2 */}
          {modalOpenPE && 
            <ModalTest isMounted={isMountedPE.show}
              modalType={'Pricing Exclusions'}
              toggleMountModal={toggleMountModalPE}
              title={translations.pricingAssumptionsSection.titleTwo}
              content={dataPE}
            />
          }
        </PricingAssumptions>
      </PricingAssumptionsContainer>
      <EstimateDivider noRebates={noRebates}/>
    </React.Fragment>
  )
}
