import styled, { css } from "styled-components";

const Container = styled.div`
    display: flex;
    height: 100vh;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const MistakeMessage = styled.p`
  width: 400px;
  height: 24px;
  color: rgb(62, 65, 72);
  font-size: 18px;
  font-family: Montserrat;
  font-weight: bold;
  text-align: center;
  line-height: 24px;
  margin: 16px;
  margin-top: 0px;
`;

const StatusMessage = styled.p`
  width: 400px;
  color: rgb(62, 65, 72);
  font-size: 16px;
  font-family: Lato;
  font-weight: normal;
  text-align: left;
  letter-spacing: 0px;
  line-height: 20px;
  margin: 0px;
`;

const StatusMessageRedirec = styled.a`
  color: rgb(0, 188, 212);
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  height: 40px;
  letter-spacing: 0px;
  line-height: 20px;
  text-align: left;
  width: 400px;
`;


const Footer = styled.img`
  display: block;
  margin: 0 auto;
  height: 24px;
  width: 81px;
  object-fit: contain;
  margin-bottom: 24px;
  position: absolute;
  bottom: 0px;
`;

export default {
    Container,
    MistakeMessage,
    StatusMessage,
    StatusMessageRedirec,
    Footer
}