import React from 'react';
import styledComponents from './styles';

const {
  StyledGhostButton
} = styledComponents;

export default function GhostButton({ hasIcon, text, textColor, borderColor, bgColor, onClick }) {
  return (
    <StyledGhostButton onClick={onClick} textColor={textColor} borderColor={borderColor} bgColor={bgColor} >
      {hasIcon &&
        <div className="iconBox">
          <i className="far fa-play-circle" alt="play icon" />
        </div>
      }
      {text}
    </StyledGhostButton>
  )
}