import styled, { css } from "styled-components";

const CustomePriceFileContainer = styled.div`
display: flex;
flex-direction: row;

${props => {
  if (props.displayed === false) {
    return (
      css`
        display: none;
      `
    );
  }
}}
`;


const addDocumentText = styled.p`
  width: 408px;
  height: 20px;
  color: rgb(62, 65, 72);
  font-size: 16px;
  font-family: Lato;
  font-weight: normal;
  line-height: 20px;

  ${props => props.gray && css`
    font-style: italic;
    color: #b9bbc1;
    margin-left: 0;
  `}

  ${props => props.customePrice && css`
    margin: 0 0 4px 48px; 
  `}

  ${props => {
    if (props.displayed === false) {
      return (
        css`
          display: none;
        `
      );
    }
  }}
`;

const QuoteCircle = styled.label`
  background: linear-gradient(to bottom, #00bcd4, #00acc1);
  color: #fff;
  text-align: center;
  border-radius: 100%;
  width: 16px;
  height: 16px;
  font-size: 16px;
  font-weight: bolder;
  cursor: pointer;
  font-family: "Font Awesome 5 Pro";
  margin-right: 4px;
  margin-top: 2px;
  line-height: 15px;
`;

const QuotePlus = styled.div`
  font-size: 16px;
font-weight: solid;
`;

const CustomePriceContainerRemoveDocument = styled.div`
  display: flex;
  flex-direction: column;


${props => {
  if (props.displayed === false) {
    return (
      css`
        display: none;
      `
    );
  }
}}
`;

const CustomePriceRemoveDocumentText = styled.p`
  color: rgb(185, 187, 193);
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  height: 16px;
  letter-spacing: 0.5px;
  line-height: 16px;
  text-align: center;
  width: 96px;
  cursor: pointer;
  text-align: center;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(224, 247, 250);
  border-radius: 0px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.14),
    0px 2px 2px 0px rgba(0, 0, 0, 0.12),
    0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  height: 96px;
  width: 96px;
  margin-top: 14px;
  cursor: pointer;
`;

const UploadBox = styled.div`
    width: 432px;
    height: 144px;
    background: rgb(244, 245, 246);
    border: 1px solid rgb(222, 223, 226);
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    .blueDot {
      align-self: center;
      width: 44px;
      height: 44px;
      background: linear-gradient(-180deg, rgb(57, 99, 254) 0%, rgb(47, 85, 254) 100%);
      border: 1px solid rgb(47, 85, 254);
      border-radius: 50%;
      justify-content: center;
      display: flex;
      
      i {
        width: 20px;
        height: 20px;
        color: rgb(255, 255, 255);
        font-size: 16px;
        font-weight: normal;
        text-align: center;
        line-height: 20px;
        align-self: center;
      }
    }
    p {
      text-align: center;
    }
`;

const SubmitPrimaryLabel = styled.p`
  color: rgb(62, 65, 72);
  font-size: 14px;
  font-family: Lato;
  font-weight: normal;
`;

const SubmitSubLabel = styled.p`
  color: rgb(62, 65, 72);
  font-size: 14px;
  font-family: Lato;
  font-weight: normal;
  padding: 0 0 4px 0;
  font-style: italic;
`;

export default {
    CustomePriceFileContainer,
    addDocumentText,
    QuoteCircle,
    QuotePlus,
    CustomePriceContainerRemoveDocument,
    CustomePriceRemoveDocumentText,
    ImageContainer,
    UploadBox, 
    SubmitSubLabel,
    SubmitPrimaryLabel
}