import React from 'react';
import styledComponents from './style';
import tierFile from '../../../../../../public/images/tierFile.png'


const  {CustomePriceFileContainer, QuoteCircle, CustomePriceContainerRemoveDocument,
    CustomePriceRemoveDocumentText, ImageContainer} = styledComponents


export const FileUpload = ({onHandlehandleFileToBase64, displayAddFile, onHandleRemoveDocument, displayed}) => {
    
if (displayed) {
    if (displayAddFile) {
        return (
            <CustomePriceContainerRemoveDocument>
                <ImageContainer>
                <img
                    src={tierFile}
                    style={{
                        height: 36, 
                        width: 28,
                    }}
                />
                </ImageContainer>
                <CustomePriceRemoveDocumentText onClick={onHandleRemoveDocument}>REMOVE</CustomePriceRemoveDocumentText>
            </CustomePriceContainerRemoveDocument>
        );
    } else {    
        
        return (
        <CustomePriceFileContainer>
            <QuoteCircle for='file-upload'>
                +
            </QuoteCircle>
            <addDocumentText>Add a document</addDocumentText>
            <input  
                    style={{display: 'none'}}
                    id='file-upload'
                    onChange={onHandlehandleFileToBase64} 
                    type="file"  accept="image/jpeg, image/png, image/jpg, application/pdf"/>
        </CustomePriceFileContainer>
        )
    }
} else {
    return <div/>;
}
}
