import React, { useEffect } from 'react';
import styledComponents from './styles';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';
import TotalRow from '../../components/TotalRow';
import AddItemButton from '../../components/AddItemButton';
import RowsMapper from '../../components/RowsMapper';
import { ToolTip } from '../../components/ToolTip';
import { useEditRow } from '../../hooks/useEditRow';
import { getSum } from '../../helper';

const {
  P_MicroBold
} = commonStyles;

const {
  TitleContainer,
  SectionHeaderSC,
  AppliancesContainer,
  Form
} = styledComponents;

export default function StandardAppliances({ data, setFxn, id, setCurrentRec, guid }) {
  const {
    rowsData,
    addRow,
    deleteRow,
    handleChange
  } = useEditRow(data, id, setCurrentRec, guid);

  useEffect(() => {
    const appliancesTotal = getSum(rowsData, 'total');
    setFxn(appliancesTotal);
  }, [rowsData]);

  return (
    <AppliancesContainer>
      <TitleContainer>
        <SectionHeaderSC>Standard Appliances</SectionHeaderSC>
        <AddItemButton onClick={() => addRow('Appliance')} mobile={true}/>
      </TitleContainer>
      <Form role="table" aria-label="Standard Appliances">
        <div className="header" role="row">
          <div className="applianceColumn" role="columnheader">
            <P_MicroBold>Appliance</P_MicroBold>
          </div>
          <div className="quantity" role="columnheader">
            <P_MicroBold>Quantity</P_MicroBold>
          </div>
          <div className="iconColumn">
            {' '}
          </div>
          <div className="watts" role="columnheader">
            <P_MicroBold>Watts</P_MicroBold>
            <ToolTip>
              These numbers are based on industry standards.
            </ToolTip>
          </div>
          <div className="iconColumn">
            {' '}
          </div>
          <div className="totalWattsColumn" role="columnheader">
            <P_MicroBold>Total Watts</P_MicroBold>
          </div>
          <div className="iconColumn">
            {' '}
          </div>
        </div>
        <RowsMapper data={rowsData} deleteRow={deleteRow} handleChange={handleChange} />
      </Form>
      <AddItemButton onClick={() => addRow('Appliance')} mobile={false}/>
      <TotalRow label={'Subtotal'} total={getSum(rowsData, 'total')} uom={'watts'} />
    </AppliancesContainer>
  )
}