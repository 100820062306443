import styled, { css, keyframes } from "styled-components";
import { theme, fade, mediaMax } from "../../shared/style";
const gap = 12;
export const CollapseRoot = styled.div`
  box-shadow: 0 1px 3px 0 ${fade(theme.Black, 0.2)},
    0 2px 2px 0 ${fade(theme.Black, 0.12)}, 0 0 2px 0 ${fade(theme.Black, 0.14)};
  border-radius: 4px;
  &:not(:nth-last-child(1)) {
    margin-bottom: 8px;
    ${({ mbc }) =>
      mbc &&
      css`
        margin-bottom: 16px;
      `};
  }
`;

const Arrow = styled.div`
  flex: 0 0 20px;
  max-width: 20px;
  text-align: center;
  i {
    transition: ${props =>
      props.active ? `0.25s transform` : `0.1s transform`};
    font-size: 16px;
    color: ${theme.IndiqoGrey[500]};
  }
`;
const CollapseButton = styled.button`
  text-align: left;
  height: 64px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  border: 0;
  background-color: ${theme.IndiqoGrey.main};
  transition: ${props => (props.active ? `0.1s all` : `0.25s all`)};
  display: flex;
  padding: ${gap}px;
  align-items: center;
  flex-wrap: wrap;
  ${mediaMax.phone`
  padding: 0 ${gap / 2}px;
  position:sticky;
  z-index: 99999;
  top:0;
  `} &:hover {
    background-color: ${theme.IndiqoGrey[100]};
  }
  ${props =>
    props.active &&
    `
    background-image: linear-gradient(to bottom, ${theme.IndiqoGrey.main}, ${
      theme.IndiqoGrey[100]
    });
    border-radius: 4px 4px 0 0;
    box-shadow: inset 0 -1px 0 0 ${theme.IndiqoGrey[300]};
    &:hover{
      background-image: linear-gradient(to bottom, ${theme.IndiqoGrey[100]}, ${
      theme.IndiqoGrey.main
    });
    }
    ${Arrow} { i { transform: rotate(90deg); transform-origin: 50% 50%; } }`};
`;

const Body = styled.div`
  transition: ${props => (props.active ? `0.25s all;` : `0.1s all;`)};
`;
const updateHeight = keyframes`
from 
{  height:calc(100vh  - 64px - 75px);}
to{
  height:calc(99.99vh  - 64px - 75px);
}
`;
const Handler = styled.div`
  ${mediaMax.phone`
   /* height:calc(100vh  - 64px - 75px);
    animation: 2s infinite ${updateHeight};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > div {
      &:nth-child(1){
        scroll-behavior: smooth;
        overflow: auto;
    height: 100%;
    padding-bottom: 72px;
      }
    }
    */
    `};
`;
export default {
  CollapseRoot,
  CollapseButton,
  Body,
  Handler,
  Arrow
};
