import _ from 'lodash';

const urlController = {
  url: '',
  validUrl: false,
  buildUrl: function(options) {
    this.url = options.root ? options.root + '/' : ''
    if (options.version && options.version.length > 0) this.url += options.version + "/"
    if (options.url) this.url += options.url
    if (options.query && options.query.length > 0) this.url += "?" + options.query
    return this
  },
  validateUrl: function() {
    // from: https://gist.github.com/dperini/729294
    this.validUrl = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(this.url);
    return this
  }
}

const getFetchOptions = options => {
    let newOptions = {};
    if (_.has(options, 'body')) {
        newOptions.body = JSON.stringify(options.body);
    }

    if (_.has(options, 'method')) {
        newOptions.method = options.method;
    }

    newOptions.headers = {
        'Content-Type': 'application/json'
    }

  return newOptions;
}

const status = (response) => {
    let test;
    if (response.ok) {
        test = Promise.resolve(response);
    } else {
        test = Promise.reject(response);
    }

    return test;
}

const json = (response) => {
    const { headers } = response;
    let type = headers.get('content-type'),
        resolved;

    if (type) {
        type = type.toLowerCase();
    }


    if (type && type.indexOf('json') !== -1) {
        resolved = Promise.resolve(response.json());
    } else {
        resolved = Promise.resolve(response.text());
    }
    return resolved;
}

const fetchImageUrl = options => {

  return fetch(urlController.buildUrl(options).validateUrl().url, getFetchOptions(options))
    .then(status)
    .then(json)
    .then(data => {
        return data
    })
    .catch(fetchError => {
      Promise.reject({isLoading: false, fetchedData: {ErrorCode: 500, ...fetchError} })
    })
}

export default fetchImageUrl
