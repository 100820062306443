import styled, { css } from "styled-components";

const PaymentConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 314px;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: 426px) {
    padding-left: 0px;
  padding-right: 0px;
  }

  .title {
    color: rgb(62, 65, 72);
    font-family: Montserrat;
    font-size: 18px;
    font-weight: bold;
    height: 24px;
    letter-spacing: -0.9px;
    line-height: 24px;
    text-align: center;


  @media (min-width: 426px) {
    width: 400px;
  }
  }

  .text {
  //  width: 400px;
    height: 60px;
    color: rgb(62, 65, 72);
    font-size: 16px;
    font-family: Lato;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0px;
    line-height: 20px;

    @media (min-width: 426px) {
    width: 400px;
  }
  }


  img {
    height: 72px;
    width: 77px;
  }
`;


export default {
  PaymentConfirmationContainer
}