import React from "react";
import styledComponents from "../../commanStyle";

const Error = props => {
  const { ErrorContainer, ErrorIcon, Error } = styledComponents;
  return (
    <ErrorContainer>
      <ErrorIcon>
        <i className="far fa-exclamation-circle" />
      </ErrorIcon>
      <Error>{props.text}</Error>
    </ErrorContainer>
  );
};
export default Error;
