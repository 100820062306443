import styled from 'styled-components';


const TitleWrapper = styled.div`

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;

`;

const TOU_Link = styled.a`
/* common */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin: 0px;

  /* ig/ig-40 */
  color: #3963FE;
`;

export default {

  TitleWrapper,
  TOU_Link
}