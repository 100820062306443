import React, { useState, useEffect } from 'react';
import Loader from "../../components/Loader";
import queryString from "query-string";
import _ from "lodash";

import DetailsComponent from './components/DetailsComponent';
import LaunchProject from './components/LaunchProjectContainer';
import MobileCancel from './mobileComponents/MobileCancelSection/';

import Banner from './sections/Banner';
import PreliminaryEstimate from './sections/PreliminaryEstimate';
import TitleSection from './sections/Title';
import ProjectInformation from './sections/ProjectInformation';
import PricingAssumptionsSection from './sections/PricingAssumptions';
import Rebates from './sections/Rebates';
import TotalSection from './sections/TotalSection';

import commonStyles from '../../components/DesignPatternV2.0/commonStyles';
import styledComponents from "./styles";

import translationsLocalData from "../../../server/locale/en_US.json"

const {
  MainWrapper,
  QmeritLogo,
  H4,
  P_Contact,
  GlobalTrackerStyles
} = commonStyles;

const {
  QmeritHeader,
  InfoWrapper,
  LeftColumn,
  EstimateDivider,
  MobileSection,
  RightColumn,
  Footer,
  FooterMobile,
  FooterDivider,
  FooterContainer
} = styledComponents;

export default function UpfrontPricePage(props) {

  //Getting Request Guid parsing token from the URL
  const getRequestGuid = (url) => {
    return queryString.parse(url).token
  }

  //Getting Request data using the Guis previously obtained
  const getRequestByGuid = (requestGuid) => {
    let optionalParams = {
      headers: { "content-type": "application/json; charset=UTF-8", "Subscription-Key": `${window.env.apiKey}` },
      method: "GET"
    }

    return new Promise((resolve, reject) => {
      fetch(`${window.env.qmtUrl}/Estimate/${requestGuid}`, optionalParams)
        .then(response => {
          setFetchedBidStatus(response.status)
          return response.json()
        })
        .then(data => {
          setFetchedRequestData(data);
          setIsLoading(false);
        })
        .catch(error => {
          reject({
            isLoading: false,
            fetchedData: { ErrorCode: 500, ...error }
          });
        })
    })
  }

  const [translations, setTranslations] = useState({});
  const [fetchedRequestData, setFetchedRequestData] = useState([]);
  const [fetchedBidStatus, setFetchedBidStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [browserLanguage, setBrowserLanguage] = useState("");
  const [token, setToken] = useState("");

  useEffect(() => {

    if (_.has(window, 'env.translations') && !_.isEmpty(window.env.translations)) {
      setTranslations(window.env.translations);
    }
    else {
      setTranslations(translationsLocalData);
    };

    fetch("/language", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => setBrowserLanguage(data.browserLanguage))

    let requestGuid = getRequestGuid(props.location.search)
    setToken(requestGuid);
    if (!_.isEmpty(requestGuid)) {
      getRequestByGuid(requestGuid)
    }


  }, []);

  if (!isLoading && fetchedBidStatus == 200) {
    const cultureCode = fetchedRequestData.Request.CultureCode;
    return (
      <React.Fragment>
        <GlobalTrackerStyles />
        <QmeritHeader cultureCode={cultureCode} domain={`${window.env.evBlob}`}>
          <QmeritLogo src={`${window.env.blobUrl}/qmerit/wordmark/png/Qmerit-Wordmark-White.png`} />
        </QmeritHeader>
        <MainWrapper>
          {/* Banner */}
          <Banner translations={translations.estimatePage} cultureCode={cultureCode} />
          <InfoWrapper>
            <LeftColumn>
              {/* Title */}
              <TitleSection translations={translations.estimatePage}/>
              <EstimateDivider />
              {/* Project Information */}
              <ProjectInformation fetchedRequestData={fetchedRequestData} translations={translations.estimatePage}/>
              <EstimateDivider />
              {/* Preliminary Estimate */}
              <PreliminaryEstimate fetchedRequestData={fetchedRequestData} translations={translations.estimatePage}/>
              <EstimateDivider />
              {/* Pricing Assumptions */}
              <PricingAssumptionsSection translations={translations.estimatePage} fetchedRequestData={fetchedRequestData} noRebates={(_.isEmpty(fetchedRequestData.Request.Rebates) || _.isNil(fetchedRequestData.Request.Rebates)) ? true : false} />
              {/* Rebates */}
              <Rebates fetchedRequestData={fetchedRequestData} />
              {/* Mobile View */}
              <MobileSection>
                <EstimateDivider noRebates={(_.isEmpty(fetchedRequestData.Request.Rebates) || _.isNil(fetchedRequestData.Request.Rebates)) ? true : false} />
                <DetailsComponent
                  fetchedRequestData={fetchedRequestData}
                  browserLanguage={browserLanguage} 
                  translations={translations.estimatePage}/>
              </MobileSection>
              {/* End of Mobile View */}
              {/* Mobile View */}
              <MobileSection>
                <EstimateDivider />
                <MobileCancel requestId={token} translations={translations.estimatePage}/>
              </MobileSection>
              {/* End of Mobile View */}
            </LeftColumn>
            <RightColumn>
              {/* Total Section */}
              <TotalSection
                fetchedRequestData={fetchedRequestData}
                browserLanguage={browserLanguage}
                requestId={token} 
                translations={translations.estimatePage}/>
            </RightColumn>
          </InfoWrapper>
          <FooterDivider />
          <FooterContainer>
            <Footer>
              <H4>{translations.estimatePage.footerSection.message}</H4>
              <P_Contact href={`tel: ${fetchedRequestData.Request.Program.CustomerSupportPhone}`}>{fetchedRequestData.Request.Program.CustomerSupportPhone}</P_Contact>
              <P_Contact href={`mailto: ${fetchedRequestData.Request.Program.CustomerSupportEmail}`}>{fetchedRequestData.Request.Program.CustomerSupportEmail}</P_Contact>
            </Footer>
            {/* Mobile View */}
            <FooterMobile>
              <LaunchProject fetchedRequestData={fetchedRequestData} requestId={token} translations={translations.estimatePage}/>
            </FooterMobile>
            {/* End of Mobile View */}
          </FooterContainer>
        </MainWrapper>
      </React.Fragment>
    )
  }

  if (!isLoading && [400, 404, 500].includes(fetchedBidStatus)) {
    return <TierUpdateFail translations={translations} />
  }

  return (<Loader color={"#000000"} isLoading={isLoading} />)

}
