import styled, { css } from "styled-components";

const WrapperImage = styled.img`
  max-height: 97px;
`;

const WrapperHeading = styled.h1`
  opacity: 0.75;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  text-align: center;
  color: #3e4148;
  margin-top: 5px;
  margin-bottom: 15px;
  padding: 0px 5px;
`;

const WrapperParagraph = styled.p`
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #3e4148;
  opacity: 1;
  text-align: left;
  margin-bottom: 20px;
`;

const ImageWrapper = styled.div`
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
`;

export default {
  WrapperImage,
  WrapperHeading,
  WrapperParagraph,
  ImageWrapper
};
