import React from 'react';
import styledComponents from './styles';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';

const {
  H2
} = commonStyles;

const {
  InformationSection,
  Table,
  TableHeader,
  TableRow,
  InformationMobile,
  RowContainer,
  RowTitle,
  RowDescription
} = styledComponents;

export default function WorkeTypeInformation({fetchedBidData, bidData, translations}) {

  return (
    <InformationSection>
      <H2>{translations.workerTypeInformationSection.title}{fetchedBidData.WorkerType} {translations.workerTypeInformationSection.titleTwo}</H2>
      <Table>
        <thead>
          <TableHeader>
            <th>{translations.workerTypeInformationSection.companyName}</th>
            <th>{translations.workerTypeInformationSection.email}</th>
            <th>{translations.workerTypeInformationSection.phone}</th>
          </TableHeader>
        </thead>
        <tbody>
          <TableRow>
            <td>{bidData.Assignee.Name}</td>
            <td>{bidData.Assignee.Email}</td>
            <td>{bidData.Assignee.Phone}</td>
          </TableRow>
        </tbody>
      </Table>
      {/* Mobile View */}
      <InformationMobile>
        <RowContainer>
          <RowTitle>{translations.workerTypeInformationSection.companyName}</RowTitle>
          <RowDescription>{bidData.Assignee.Name}</RowDescription>
        </RowContainer>
        <RowContainer>
          <RowTitle>{translations.workerTypeInformationSection.email}</RowTitle>
          <RowDescription>{bidData.Assignee.Email}</RowDescription>
        </RowContainer>
        <RowContainer>
          <RowTitle>{translations.workerTypeInformationSection.phone}</RowTitle>
          <RowDescription>{bidData.Assignee.Phone}</RowDescription>
        </RowContainer>
      </InformationMobile>
      {/* End of Mobile View */}
    </InformationSection>
  )
}
