import styled from 'styled-components';

const ButtonSC = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 276px;
  height: 40px;
  background: #3963FE;
  border-radius: 4px;
  border: none;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;

  &:hover {
    background: #3963FE;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }
`;

const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;

  i {
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #FFF;
  }
`;

export default {
  ButtonSC,
  IconBox
}