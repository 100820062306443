import styled, { css } from "styled-components";

const Container = styled.div`

    display: flex;
    height: 100vh;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const InstallerConfirmedTitle = styled.h2`
  color: rgb(62, 65, 72);
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  height: 24px;
  line-height: 24px;
  text-align: center;
  width: 400px;
`;

const InstallerConfirmedMessage = styled.p`
  color: rgb(62, 65, 72);
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  height: 40px;
  letter-spacing: 0px;
  line-height: 20px;
  text-align: center;
  width: 400px;
`;


const InstallerConfirmedMessageRedirec = styled.a`
  color: rgb(0, 188, 212);
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  height: 40px;
  letter-spacing: 0px;
  line-height: 20px;
  text-align: center;
  width: 400px;
`;

export default {
    Container,
    InstallerConfirmedTitle,
    InstallerConfirmedMessage,
    InstallerConfirmedMessageRedirec
}