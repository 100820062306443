import formatter from "../../../lib/formatter";
import _ from "lodash";

export function setDates(fetchedData) {
  if (fetchedData && fetchedData.Project) {
    const dueDate = formatter.getDateTime(
      fetchedData.Project && fetchedData.Project.DueDate
        ? fetchedData.Project.DueDate
        : null
    );
    const createdOnDate = formatter.getDateTime(
      fetchedData.Project && fetchedData.Project.CreatedOn
        ? fetchedData.Project.CreatedOn
        : null
    );
    const AcceptedOnDate = formatter.getDateTime(
      _.has(fetchedData, "AcceptedBy.AcceptedOn")
        ? fetchedData.AcceptedBy.AcceptedOn
        : null
    );
    const DeclinedOnDate = formatter.getDateTime(
      _.has(fetchedData, "DeclinedBy.DeclinedOn")
        ? fetchedData.DeclinedBy.DeclinedOn
        : null
    );
    return {
      localDueDate: dueDate.formattedDate,
      localDueTime: dueDate.formattedTime,
      localCreatedDate: createdOnDate.formattedDate,
      localCreatedTime: createdOnDate.formattedTime,
      localAcceptedDate: AcceptedOnDate.formattedDate,
      localAcceptedTime: AcceptedOnDate.formattedTime,
      localDeclinedDate: DeclinedOnDate.formattedDate,
      localDeclinedTime: DeclinedOnDate.formattedTime,
      localDeclineDateMessage: `${DeclinedOnDate.formattedDate}, ${
        DeclinedOnDate.formattedTime
      }`
    };
  }
  return;
}

export function setDatesInArray(userData) {
  let updatedActivityDates = [];
  let arrayedTime = _.map(userData.Activities, (bid, index) => {
    const dueDate = formatter.getDateTime(
      bid && bid.DueDate ? bid.DueDate : null
    );
    const createdOnDate = formatter.getDateTime(
      bid && bid.CreatedOn ? bid.CreatedOn : null
    );
    const AcceptedOnDate = formatter.getDateTime(
      _.has(bid, "AcceptedBy.AcceptedOn") ? bid.AcceptedBy.AcceptedOn : null
    );
    const DeclinedOnDate = formatter.getDateTime(
      _.has(bid, "DeclinedBy.DeclinedOn") ? bid.DeclinedBy.DeclinedOn : null
    );

    const converts = {
      localDueDate: dueDate.formattedDate,
      localDueTime: dueDate.formattedTime,
      localCreatedDate: createdOnDate.formattedDate,
      localCreatedTime: createdOnDate.formattedTime,
      localAcceptedDate: AcceptedOnDate.formattedDate,
      localAcceptedTime: AcceptedOnDate.formattedTime,
      localDeclinedDate: DeclinedOnDate.formattedDate,
      localDeclinedTime: DeclinedOnDate.formattedTime,
      localDeclineDateMessage: `${DeclinedOnDate.formattedDate}, ${
        DeclinedOnDate.formattedTime
      }`
    };
    const resultArray = Object.assign(userData.Activities[index], converts);
    updatedActivityDates.push(resultArray);
  });
  return updatedActivityDates;
}
