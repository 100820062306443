import _ from 'lodash';

//Getting Installation Appointment Date and Installed On Date (separately)

/* Filtering the Installations array to look for the one that has an Installation Appointment date 
and its status is equal to "new" */
export function filterForActiveInstallation(installationsArray) {
  if (installationsArray && !_.isEmpty(installationsArray)) {
    return installationsArray.filter((installation) => {
      return installation.AppointmentDateWindowStart && ['new', 'completed'].includes(installation.Status);
    });
  } else {
    return null;
  }
}

/* Filtering the Installations array to look for the one that has NO Installation Appointment date 
and its status is equal to "new" */
export function filterForActiveUnscheduledInstallation(installationsArray) {
  if (installationsArray && !_.isEmpty(installationsArray)) {
    return installationsArray.filter((installation) => {
      return ['new', 'completed'].includes(installation.Status);
    });
  } else {
    return null;
  }
}

/* Since filtering provides us with a new array with only one element in this case, we're gonna 
pick  the element [0] so we can take the actual Installation Appointment date or Installed On date from it */
export function getInstallationAppointmentDate(requestData) {
  const currentInstallationArr = filterForActiveInstallation(requestData.Installations);
  return currentInstallationArr && !_.isEmpty(currentInstallationArr)
    ? currentInstallationArr[0].AppointmentDateWindowStart
    : null;
}

export function getInstalledOnDate(requestData) {
  let installedOnDate = '';
  if (!_.isEmpty(requestData.Installations)) {
    const currentInstallationArr = filterForActiveInstallation(requestData.Installations);
    installedOnDate =
      currentInstallationArr && !_.isEmpty(currentInstallationArr) ? currentInstallationArr[0].InstalledOn : null;
  }
  return installedOnDate;
}

//Getting Appointment Start and End Date for the latest active Installation
export function getAppointmentDates(data, window) {
  let dates = '';
  let currentInstallationArr = [];
  if (!_.isEmpty(data.Installations)) {
    currentInstallationArr = filterForActiveInstallation(data.Installations);
    if (window == 'start') {
      dates =
        currentInstallationArr && !_.isEmpty(currentInstallationArr)
          ? currentInstallationArr[0].AppointmentDateWindowStart
          : null;
    } else if (window == 'end') {
      dates =
        currentInstallationArr && !_.isEmpty(currentInstallationArr)
          ? currentInstallationArr[0].AppointmentDateWindowEnd
          : null;
    }
  }
  return dates;
}
