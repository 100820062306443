import styled, { css } from "styled-components";
import { theme, primaryButton, mediaMax, fade } from "../../shared/style";

const Header = styled.header`
  display: flex;
  height: 56px;
  align-items: center;
  &.blured {
    filter: blur(8px);
  }
  &.mobileBlured {
    ${mediaMax.tablet`filter: blur(8px);`};
  }
  ${mediaMax.phone`
  height: 96px;
  align-items: flex-end;
  ${({ iphone }) =>
    iphone &&
    css`
      height: 84px;
    `}
  `};

  ${({ iphone }) =>
    iphone &&
    css`
      @media (width: 414px) and (height: 896px) {
        height: 96px !important;
        margin-left: 16px;
      }
      @media (width: 375px) and (height: 812px) {
        height: 96px !important;
        margin-left: 16px;
      }
      @media screen and (width: 700px) {
        height: 96px !important;
        margin-left: 16px;
      }
    `};
  ${({ maps }) =>
    maps &&
    css`
      width: 100%;
      ${mediaMax.tablet`position: fixed;
        backdrop-filter:blur(${({ backGround }) => (backGround ? 6 : 8)}px);
        background-color: ${({ backGround }) =>
          backGround ? fade(backGround, 0.2) : fade("#dedfe2", 0.5)};
        padding: 0 16px;
        top: 0;
        left: 0;
        z-index: 9999;
      `};
    `};
`;
const Wraper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${mediaMax.phone`
  padding: 0  0 12px;
  `};
  .Qlogo {
    margin: 16px;
    img {
      width: 88%;
    }
  }
`;



// const Logo = styled.div``;
const Logo = styled.div`
  font-weight: 400;
  i {
    color: ${theme.IndiqoGrey[400]};
  }
  color: #3e4148;
  span {
    ${({ noText }) =>
      noText &&
      css`
        ${mediaMax.tablet`
        display: none;`};
      `};
  }
`;
const Title = styled.div`
  ${({ noView }) =>
    noView &&
    css`
      ${mediaMax.tablet`
        opacity: 0`};
    `};
  float: right;
  text-align: right;
  h2 {
    margin: 0;
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.25;
    color: #3E4148;
    //color: ${({ maps }) => (maps ? theme.IndiqoGrey[800] : "#ffffff")};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100vw - 155px);
  }
  h3 {
    margin: 0;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.43;
    color: #3E4148;
    /*color: ${({ maps }) =>
      maps ? fade(theme.IndiqoGrey[800], 0.5) : "rgba(255, 255, 255, 0.5)"};*/
  }
`;

export default {
  Header,
  Logo,
  Title,
  Wraper
};
