import styled, { createGlobalStyle } from 'styled-components';
import { mediaMax } from '../../../../shared/style';

const ScrollDisabler = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const ModalBackground = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.50);
  backdrop-filter: blur(6px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transform: scale(1);
  animation: ${({ animate }) => 
    animate ? 'fadeIn .3s ease-in forwards' : 'fadeOut .3s ease-in forwards'};
  @keyframes fadeIn {
    0% { background: rgba(0, 0, 0, 0.0); }
    100% { background: rgba(0, 0, 0, 0.5); }
  }

  @keyframes fadeOut {
    0% { background: rgba(0, 0, 0, 0.5); }
    100% { background: rgba(0, 0, 0, 0.0); }
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  background: transparent;
  width: 854px;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  opacity: 0;
  animation: ${({ animate }) => 
    animate ? 'animateUp .3s ease-in forwards' : 'animateDown .3s ease-in forwards'};
  @keyframes animateUp {
    0% {
      transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  
  @keyframes animateDown {
    0% {
      transform: translateY(0px);
      opacity: 1;
    }
    100% {
      transform: translateY(1000px);
      opacity: 0;
    }  
  }

  iframe {
    height: 100%;
    width: 100%;
    border-style: none;
    aspect-ratio: 16 / 9;
  }

  .iconBoxModal {
    display:flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;

    i {
      font-style: normal;
      font-weight: 400;
      color: #B9BBC1;
      font-size: 32px;
      line-height: 40px;
      cursor: pointer;

      &:hover {
        color: #B9BBC1;
      }
    }

    ${mediaMax.desktop`
      display: none;
    `};
  }
`;

export default {
  ScrollDisabler,
  ModalBackground,
  ModalContainer
}