import styled from 'styled-components';

const Toggle = styled.div`
  display: flex;
  flex-direction: row;
  width: 304px;
`;

const On = styled.button`
  display: flex;
  padding: 8px;
  flex: 1 1 0;
  border-radius: 4px 0px 0px 4px;
  border: ${({toggle}) => toggle ? '1px solid #3963FE' : '1px solid #DEDFE2'};
  background: ${({toggle}) => toggle ? 'rgba(248, 249, 255, 0.75)' : 'rgba(255, 255, 255, 0.75)'};
  backdrop-filter: ${({toggle}) => toggle ? 'blur(12px)' : 'blur(6px)'};
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
  font-weight: ${({toggle}) => toggle ? '700' : '500'};
  color: ${({toggle}) => toggle ? '#3963FE' : '#6E727C'};
  justify-content: center;
  cursor: pointer;
`;

const Off = styled(On)`
  border-radius: 0px 4px 4px 0px;
  border: ${({toggle}) => toggle ? '1px solid #DEDFE2' : '1px solid #3963FE'};
  background: ${({toggle}) => toggle ? 'rgba(255, 255, 255, 0.75)' : 'rgba(248, 249, 255, 0.75)'};
  backdrop-filter: ${({toggle}) => toggle ? 'blur(6px)' : 'blur(12px)'};
  font-weight: ${({toggle}) => toggle ?  '500' : '700'};
  color: ${({toggle}) => toggle ? '#6E727C' : '#3963FE'};
`;

export default {
  Toggle,
  On,
  Off
}