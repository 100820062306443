import React, { Component } from "react";
import FetchFailure from "../../components/FetchFailure";
import Loader from "../../components/Loader";
import _ from "lodash";
import {
  getToken,
  fetchBidRequests,
  postCustomerResponse
} from "../../lib/getUserData";
import StyledComponents from "./styles";
import SelectedView from "./views/Selected";
import CustomerThanks from "../../components/CustomerThanks";
import EstimatesView from "./views/Estimates";
import ConfirmationView from "./views/Confirmation";
import mockGatheringEstimates from "../../lib/mockGatheringEstimates";
import mockInstallerSelected from "../../lib/mockInstallerSelected";
import mockInstallationCompleted from "../../lib/mockInstallationCompleted";

const { Root, Wrapper } = StyledComponents;

class InstallUpdate extends Component {
  state = {
    isLoading: true,
    fetchedData: null,
    fetchError: false,
    view: null,
    companies: null,
    completed: false,
    selectedCompanies: null,
    isMock: false
  };

  handleSubmit = async data => {
    this.setState({ isLoading: true });
    if (data && data.selectedCompanies) {
      const { selectedCompanies } = data;
      if (!this.state.isMock)
        await postCustomerResponse({ SelectedCompanies: selectedCompanies });
      this.setState({ selectedCompanies, isLoading: false });
    }
  };

  getActivityId = selectedCompanies => {
    const { fetchedData } = this.state;
    let activity = fetchedData.Bids.map(bid => {
      if (bid.Assignment.DBAName === selectedCompanies[0]) return bid.Id;
    });
    if (!_.isEmpty(activity)) return activity[0];

    return null;
  };

  filterBids = data => {
    if (data.Bids) {
      const bidsArr = [];
      data.Bids.map(bid => {
        if (bid.Status === "InProgress") bidsArr.push(bid);
      });
      data.Bids = bidsArr;
    }
    return data;
  };

  setCompanies = bids => {
    if (bids) {
      return bids.map(bid => bid.Assignment.DBAName).concat(["Other..."]);
    }
  };

  componentDidMount() {
    if (!this.state.fetchedData) {
      getToken(this.props.location.search).then(token => {
        if (
          token === "token=mockGatheringEstimates" ||
          token === "token=mockInstallerSelected" ||
          token === "token=mockInstallationCompleted"
        ) {
          let fetchedData = this.filterBids(mockGatheringEstimates);
          this.setState({
            fetchedData,
            companies: this.setCompanies(fetchedData.Bids),
            view: fetchedData.Type,
            isLoading: false,
            isMock: true
          });
        } else {
          fetchBidRequests(this.props.location.search).then(userData => {
            if (
              userData &&
              userData.fetchedData &&
              userData.fetchedData.ErrorCode
            ) {
              if (userData.fetchedData.ErrorCode === 404) {
                this.setState({ isLoading: false, completed: true });
              } else if (userData.fetchedData.ErrorCode === 500) {
                this.setState({ isLoading: false, fetchError: true });
              }
            } else {
              let fetchedData = this.filterBids(userData.fetchedData);
              this.setState({
                fetchedData,
                companies: this.setCompanies(fetchedData.Bids),
                view: fetchedData.Type,
                isLoading: false,
                token
              });
            }
          });
        }
      });
    }
    document.body.classList.add("bg-grey");
  }

  render() {
    const {
      view,
      companies,
      fetchError,
      isLoading,
      selectedCompanies,
      completed
    } = this.state;

    if (isLoading) {
      return (
        <Root>
          <Loader isLoading={true} color="#3e4148" />
        </Root>
      );
    } else if (fetchError) {
      return (
        <Root>
          <FetchFailure />
        </Root>
      );
    } else {
      return (
        <Root>
          {(completed ||
            (selectedCompanies && view !== "InstallationCompleted")) && (
            <CustomerThanks />
          )}
          {selectedCompanies && view === "InstallationCompleted" && (
            <ConfirmationView
              activityId={this.getActivityId(selectedCompanies)}
            />
          )}
          {!selectedCompanies &&
            (view === "InstallerSelected" ||
              view === "InstallationCompleted") && (
              <SelectedView
                companies={companies}
                onSubmit={this.handleSubmit}
              />
            )}
          {!selectedCompanies && view === "GatheringEstimates" && (
            <EstimatesView companies={companies} onSubmit={this.handleSubmit} />
          )}
        </Root>
      );
    }
  }
}

export default InstallUpdate;
