import styled, { css } from "styled-components"

const Container = styled.div`
  background: rgb(255, 255, 255);
  border-radius: 16px;
  width: 600px;
  margin: auto;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14),
    0px 4px 5px 0px rgba(0, 0, 0, 0.12),
    0px 1px 10px 0px rgba(0, 0, 0, 0.2);
`;

const Header = styled.div`
  width: 600px;
  height: 52px;
  background: linear-gradient(-180deg, rgb(56, 98, 197) 0%, rgb(43, 77, 172) 100%);
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  padding: 16px 24px 16px 24px;
`;

const HeaderH4 = styled.div`
  color: rgb(255, 255, 255);
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  height: 20px;
  line-height: 20px;
  width: 552px;
`;

const BidInfoContainer = styled.div`
  padding: 24px 24px 24px;
`;

const LogoContainer = styled.div`
  display: flex;
`;

const Qmerit = styled.p`
  color: rgb(62, 65, 72);
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  height: 20px;
  line-height: 20px;
  width: 504px;
  margin-top: 9px;
  margin-left: 5px;
`;

const TierDescription = styled.div`
  display: flex;
  flex-direction: column;
`;

const TierTittle = styled.p`
  color: #3e4148;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  height: 20px;
  letter-spacing: 0px;
  line-height: 20px;
  width: 552px;
  margin-top: 24px;
  margin-bottom: 4px;
`;

const RequestId = styled.p`
  color: rgb(110, 114, 124);
  font-family: Lato;
  font-size: 12px;
  font-weight: normal;
  height: 16px;
  letter-spacing: 0.25px;
  line-height: 16px;
  width: 552px;
  margin: 0px;
`;

const BidRequestDescription = styled.p`
  width: 552px;
  height: 80px;
  color: rgb(62, 65, 72);
  font-size: 16px;
  font-family: Lato;
  font-weight: normal;
  line-height: 20px;
  margin-bottom: 0;
  margin-top: 16px;
`;

const TierUpadteLink = styled.a`
  color: rgb(0, 188, 212);
  font-size: 16px;
  font-family: Lato;
  font-weight: normal;
  line-height: 20px;
`;

const ProjectScopeContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 120px;
  margin-top: 24px;
  margin-bottom: 24px;
  padding-left: 10px;
`;

const CustomerInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const CustomerInfoContainerSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const CustomerInfoContainerLabel = styled.label`
  width: 264px;
  height: 16px;
  color: rgb(110, 114, 124);
  font-size: 12px;
  font-family: Lato;
  font-weight: normal;
  letter-spacing: 0.5px;
  line-height: 16px;
  box-sizing: border-box;
  padding: 0 0 4px 0;
`;

const CustomerName = styled.p`
  width: 264px;
  height: 20px;
  color: rgb(62, 65, 72);
  font-size: 16px;
  font-family: Lato;
  font-weight: bold;
  line-height: 20px;
`;

const CustomerInfoText = styled.p`
  width: 264px;
  height: 20px;
  color: rgb(0, 188, 212);
  font-size: 16px;
  font-family: Lato;
  font-weight: normal;
  line-height: 20px;
`;

const CustomerProjectScopeLabel = styled.label`
  color: rgb(0, 188, 212);
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  height: 120px;
  line-height: 20px;
  width: 416px;
  padding: 48px 0 0 30px;
  box-sizing: border-box;
`;


const LocationAndDateContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LocationAndDateSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const LocationAndDateContainerLabel = styled.label`
  width: 264px;
  height: 16px;
  color: rgb(110, 114, 124);
  font-size: 12px;
  font-family: Lato;
  font-weight: normal;
  letter-spacing: 0.5px;
  line-height: 16px;
  box-sizing: border-box;
  padding: 0 0 4px 0;
`;

const LocationAndDateContainerText = styled.label`
  width: 264px;
  height: 20px;
  color: rgb(62, 65, 72);
  font-size: 16px;
  font-family: Lato;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 20px;
  box-sizing: border-box;
  margin: 0 0 24px 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${props => {
    if (props.declineSection || props.detailSection) {
      return css`
        justify-content: center;
   `} 
  }}
`;

const TieredBidRequestButton = styled.button`
  width: 200px;
  height: 40px;
  border-radius: 100px;
  color: rgb(255, 255, 255);
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 24px;


  ${props => {
      if (props.type === "accept") {
        return css`
        background: linear-gradient(-180deg, rgb(139, 195, 74) 0%, rgb(124, 179, 66) 100%);
        border: 1px solid rgb(124, 179, 66);
        `
      }

      if (props.type === "update") {
        return css`
        background: linear-gradient(-180deg, rgb(56, 98, 197) 0%, rgb(51, 89, 186) 100%);
        border: 1px solid rgb(51, 89, 186);
        `
      }

      if (props.type === "decline") {
        return css`
        background: linear-gradient(-180deg, rgb(244, 245, 246) 0%, rgb(237, 238, 239) 100%);
        border: 1px solid rgb(237, 238, 239);
        color: rgb(255, 87, 34);
        `
      }

      if (props.type === "details") {
        return css`
          background: linear-gradient(-180deg, rgb(56, 98, 197) 0%, rgb(51, 89, 186) 100%);
          border: 1px solid rgb(51, 89, 186);
        `
      }
    }
  }
`;

const QuestionsText = styled.p`
  width: 552px;
  height: 40px;
  color: rgb(62, 65, 72);
  font-size: 16px;
  font-family: Lato;
  font-weight: normal;
  line-height: 20px;
  margin:24px 0 0 0;
`;



export default {
    Container,
    Header,
    HeaderH4,
    BidInfoContainer,
    LogoContainer,
    Qmerit,
    TierDescription,
    TierUpadteLink,
    TierTittle,
    RequestId,
    BidRequestDescription,
    ProjectScopeContainer,
    CustomerInfoContainer,
    CustomerInfoContainerSection,
    CustomerInfoContainerLabel,
    CustomerName,
    CustomerInfoText,
    LocationAndDateContainer,
    LocationAndDateSection,
    LocationAndDateContainerLabel,
    LocationAndDateContainerText,
    ButtonsContainer,
    TieredBidRequestButton,
    QuestionsText,
    CustomerProjectScopeLabel,
}