import styled from 'styled-components';


const TitleWrapper = styled.div`

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;

`;

export default {

  TitleWrapper,
}