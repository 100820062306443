import styled, { css } from "styled-components";

import HappyPhew from '../../../../public/images/happyface-phew.png';

const Container = styled.div`
    display: flex;
    height: 100vh;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3e4148;

    
    &.status-other {
        img.happy {
            height: 72px;
            filter: drop-shadow(0px 3px 2px rgba(0,0,0,0.2));
            margin-bottom: 24px;
        }
    }
`;

const Title = styled.h1`
  font-size: 18px;
  font-family: Montserrat;
  font-weight: bold;
  line-height: 24px;
  margin: 0px;
  margin-bottom: 16px;
`;

const Message = styled.p`
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 20px;
  margin: 0px;
  max-width: 400px;
  text-align: center;

  a {
    color: #00bcd4;
  }
`;

export default {
    Container,
    Title,
    Message,
}