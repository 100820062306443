import React from 'react';
import styledComponents from './styles';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';

const {
  H2
} = commonStyles;

const {
  InformationSection,
  HeaderContainer,
  Table,
  TableHeader,
  TableRow,
  InformationMobile,
  MobileHeaderContainer,
  RowContainer,
  RowTitle,
  RowDescription,
  Link
} = styledComponents;

export default function ProjectInformation({data, translations}) {
  return (
    <InformationSection>
      <HeaderContainer>
        <H2>Project Information</H2>
        <Link href={data.projectScopeUrl} target="_blank">View Project Scope</Link>
      </HeaderContainer>
      <Table>
        <thead>
          <TableHeader>
            <th>{translations.projectInformationSection.name}</th>
            <th>{translations.projectInformationSection.siteAddress}</th>
            <th>{translations.projectInformationSection.email}</th>
            <th>{translations.projectInformationSection.phone}</th>
          </TableHeader>
        </thead>
        <tbody>
          <TableRow>
            <td>{data.customer.name}</td>
            <td>{data.customer.address}</td>
            <td>{data.customer.email}</td>
            <td>{data.customer.phone}</td>
          </TableRow>
        </tbody>
      </Table>
      {/* Mobile View */}
      <InformationMobile>
        <MobileHeaderContainer>
          <h3>Project Information</h3>
        </MobileHeaderContainer>
        <RowContainer>
          <RowDescription>{data.customer.name}</RowDescription>
        </RowContainer>
        <RowContainer>
          <RowDescription>{data.customer.address}</RowDescription>
        </RowContainer>
        <RowContainer>
          <RowDescription>{data.customer.email}</RowDescription>
        </RowContainer>
        <RowContainer>
          <RowDescription>{data.customer.phone}</RowDescription>
        </RowContainer>
        <RowContainer>
          <Link href={data.projectScopeUrl} target="_blank">View Project Scope</Link>
        </RowContainer>
      </InformationMobile>
      {/* End of Mobile View */}
    </InformationSection>
  )
}
