import styled from 'styled-components';
import {
  mediaMax,
  mediaMin
} from "../../../../shared/style"

const ProductRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0px;
  gap: 8px;
  width: 100%;
  box-shadow: inset 0px -1px 0px #EDEEEF;

  &:last-child{
    box-shadow: none;
  }

  ${mediaMax.phone`
    &:last-child{
      box-shadow: none;
      padding-bottom: 0px;
    }
    &:first-child{
      padding-top: 0px;
    }
  `};
  
`;

const ProductDescription = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0px;
  gap: 48px;
  width: 100%;

  .pItem {
    text-align: start;
    flex: 3 1 0;

    ${mediaMax.phone`
      flex: 1;
    `};
  }

  .pQty {
    text-align: end;
    flex: .25 1 0;

    ${mediaMax.phone`
      display: none;
    `};
  }

  .pUnitPrice {
    text-align: end;
    flex: .5 1 0;

    ${mediaMax.phone`
      display: none;
    `};
  }

  .pAmount {
    text-align: end;
    flex: .5 1 0;

    ${mediaMax.phone`
      flex: 0;
    `};
  }

  ${mediaMax.phone`
    justify-content: space-between;
  `};
`;

const MobileProductDescription = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  margin: 0px;
  gap: 4px;
  width: 100%;

  .pSm {
    color: #6E727C;
    margin: 0px;
  }

  .smallIconBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;

    i {
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      color: #969BA5;
    }
  }

  ${mediaMin.tablet`
    display: none;
  `};
`;

const SmallIconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;

  i {
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: #969BA5;
  }
`;

const ProductDetails = styled.div`
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  ul {
    padding-left: 20px;
    margin: 0px;

    li {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      margin: 0px;
    }
  }
`;

const UnorderedList = styled.ul`
  padding-left: 20px;
  margin: 0px;
`;

export default {
  ProductRow,
  ProductDescription,
  MobileProductDescription,
  SmallIconBox,
  ProductDetails,
  UnorderedList
}