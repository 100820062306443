import React, { Component } from "react";
import _ from "lodash";
import { getToken, fetchRequirementsData } from "../../lib/getUserData";
import Wrapper from "../../components/Wrapper";
import Loader from "../../components/Loader";
import FetchFailure from "../../components/FetchFailure";
import formatter from "../../lib/formatter";
import Requirements from "./Requirements";
import MockNewRequirements from "../../lib/mockNewRequirements";

class ActivityStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      fetchedData: null,
      fetchError: false,
      token: undefined
    };
  }

  componentDidMount() {
    if (!this.state.fetchedData) {
      getToken(this.props.location.search).then(token => {
        if (token === "token=mockRequirements") {
          const fetchedData = MockNewRequirements;
          this.setState({
            fetchedData,
            isLoading: false
          });
        } else {
          fetchRequirementsData(this.props.location.search).then(userData => {
            if (userData) {
              this.setState({
                fetchedData: userData.fetchedData,
                isLoading: false,
                token
              });
            }
          });
        }
      });
    }
  }

  render() {
    const fetchedData = this.state.fetchedData;
    if (this.state.fetchError) {
      return (
        <Wrapper name="" company="">
          <FetchFailure />
        </Wrapper>
      );
    }

    if (fetchedData) {
      return (
        <Wrapper
          name={fetchedData && fetchedData.User ? fetchedData.User.Name : ""}
          company={
            fetchedData && fetchedData.User ? fetchedData.User.CompanyName : ""
          }
        >
          <Requirements {...this.state} />
        </Wrapper>
      );
    }

    return (
      <Wrapper name="" company="">
        <Loader isLoading={true} />
      </Wrapper>
    );
  }
}

export default ActivityStatus;
