import React from "react";
import StyledComponent from "./style";
import Modal from "../Modal";

const PopUpModal = props => {
  const { Popup, MButton, Content, DeviceTitle, Title, Icon } = StyledComponent;
  return (
    <Popup>
      <Modal
        backDropClick={
          props.previous && props.previous !== "windowClose" && props.previous
        }
        content={
          <Content>
            <div>
              <Icon mb={18.37} style={props.iconStyle} src={props.icon} />
              <center>
                <Title>{props.title}</Title>
                <DeviceTitle>{props.deviceTitle}</DeviceTitle>
              </center>

              <MButton
                submit={true}
                onClick={() =>
                  props.previous !== "windowClose"
                    ? props.previous()
                    : window && window.close()
                }
              >
                OKAY
              </MButton>
            </div>
          </Content>
        }
      />
    </Popup>
  );
};
export default PopUpModal;
