export const mainBreakerOptions = [
  {
    id: 0,
    description: "100 amps",
    value: 100
  },
  {
    id: 1,
    description: "125 amps",
    value: 125
  },
  {
    id: 2,
    description: "150 amps",
    value: 150
  },
  {
    id: 3,
    description: "175 amps",
    value: 175
  },
  {
    id: 4,
    description: "200 amps",
    value: 200
  },
  {
    id: 5,
    description: "225 amps",
    value: 225
  },
  {
    id: 6,
    description: "320 amps",
    value: 320
  },
  {
    id: 7,
    description: "400 amps",
    value: 400
  }
];