import styled from 'styled-components';
import {
  mediaMax,
  mediaMin
} from '../../../../shared/style';

const TermsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  color: #3E4148;

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin: 0px;
  }

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin: 0px;
  }

  ol {
    margin: 0px;
    padding-left: 20px;

    ol {
      list-style-type: lower-alpha;
    }
  }  

  ${mediaMax.desktop`
    display: ${props => props.hideForMobile ? 'none' : 'flex'};
  `};
`;

const Divider = styled.div`
  display: block;
  height: 1px;
  background: #DEDFE2;
  margin: 48px 0px;

  ${mediaMax.desktop`
    display: ${props => props.hideForMobile ? 'none' : 'flex'};
  `};
`;

export default {
  TermsContainer,
  Divider
};