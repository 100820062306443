import React, { useState, useEffect } from "react"
import queryString from "query-string";
import StyledComponents from './styles'
import commonStyles from "../../components/DesignPatternV2.0/commonStyles";

import Loader from "../../components/Loader";
import WhiteWrapper from "../../components/WhiteQapWrapper";
import _ from "lodash";

const {
    H4,
    P
} = commonStyles

const {
    CalloutWrapper,
    Callout,
    Message,
    MessageTitle,
    Icon,
    DepositSpan,
    WhatsNext,
    Card,
    CardIconWrapper,
    CardIcon,
    CardTextWrapper
} = StyledComponents

const DepositReceived = (props) => {

    const [isLoading, setIsLoading] = useState(true);
    const [fetchRequestStatus, setFetchRequestStatus] = useState(false);
    const [requestData, setRequestData] = useState(false);

    //Getting Request Guid parsing requestId from the URL
    const getRequestId = (url) => {
        return queryString.parse(url).requestId
    }

    //Getting Request data using the Guis previously obtained
    const getRequestData = async (requestId) => {
        let optionalParams = {
            headers: { "Cache-Control": "no-cache", "content-type": "application/json; charset=UTF-8", "Subscription-Key": `${window.env.apiKey}` },
            method: "GET"
        }
        let data = null;
        const response = await fetch(`${window.env.qmtMrktPlcUrl}/Request/${requestId}`, optionalParams);
        //Setting the status of the fetch so we can use it as a flag of when the data fetching was performed correctly
        setFetchRequestStatus(response.status);
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        data = await response.json();
        //Filling the requestData state variable with the response data and then setting the loader to false so we can render accordingly
        setRequestData(data);
        setIsLoading(false);
        return data;
    }

    useEffect(() => {
        let requestId = getRequestId(props.location.search);
        if (!_.isEmpty(requestId)) getRequestData(requestId);
    }, [])

    if (!isLoading && ![400, 404, 500].includes(fetchRequestStatus)) {
        return (
            <WhiteWrapper>
                <CalloutWrapper>
                    <Callout>
                        <Icon className="fa-light fa-rocket-launch" />
                        <Message>
                            <MessageTitle>Project Launched!</MessageTitle>
                            {requestData.deposit &&
                                <MessageTitle>Deposit Received: <DepositSpan>{requestData.deposit.amount.shortFormat}</DepositSpan></MessageTitle>
                            }
                        </Message>
                    </Callout>
                    <WhatsNext>
                        <Card>
                            <CardIconWrapper>
                                <CardIcon className="fa-light fa-badge-check" />
                            </CardIconWrapper>
                            <CardTextWrapper>
                                <H4>Your Certified Installer Will Review Your Project</H4>
                                <P>You’ll be assigned to a certified installer within 1–3 business days.</P>
                            </CardTextWrapper>
                        </Card>
                        <Card>
                            <CardIconWrapper>
                                <CardIcon className="fa-light fa-file-lines" />
                            </CardIconWrapper>
                            <CardTextWrapper>
                                <H4>Receive a Final Quote</H4>
                                <P>Your certified installer will send you a final quote to accept or decline.</P>
                            </CardTextWrapper>
                        </Card>
                        <Card>
                            <CardIconWrapper>
                                <CardIcon className="fa-light fa-bolt" />
                            </CardIconWrapper>
                            <CardTextWrapper>
                                <H4>Enjoy an Electrified Future</H4>
                                <P>Once you accept the final quote, then you may schedule your installation.</P>
                            </CardTextWrapper>
                        </Card>
                        <Card>
                            <CardIconWrapper>
                                <CardIcon className="fa-light fa-hand-holding-dollar" />
                            </CardIconWrapper>
                            <CardTextWrapper>
                                <H4>Apply for Incentives and Rebates</H4>
                                <P>They’ll be included in your quote from Qmerit once your installation is completed!</P>
                            </CardTextWrapper>
                        </Card>
                    </WhatsNext>
                </CalloutWrapper>
            </WhiteWrapper>
        )
    }

    return (<Loader color={"#000000"} isLoading={isLoading} />)

}


export default DepositReceived;