import styled from 'styled-components';

const Recommendation = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.05);
  
  .h2 {
    color: ${({ color }) => {
      switch (color) {
        case 'SufficientCapacityWithOpenSlots':
          return '#7BC342';
        case 'SufficientCapacityWithoutOpenSlots':
          return '#FFB300';
        case 'InsufficientCapacity':
          return '#F4511E'
        default:
          return '#FFFFFFBF';
      }
    }};
  }

  .p {
    color: #fff;
  }

  .recHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
  }

  .recContent {
    display: flex;
    align-items: flex-start;
  }
`;

const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;

  i {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${({color}) => color};
  }
`;

export default {
    IconBox,
    Recommendation
  }