import styled from "styled-components";
import { mediaMax, mediaMin } from "../../../../shared/style";

const Select = styled.select`
  display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: 12px;
  border-radius: 4px;
  border: 1px solid #DEDFE2;
  background: #FAFAFA;
  margin: 0px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #3E4148;
  flex: 2 1 0;
  max-height: 46px;
  cursor: pointer;

  ${mediaMax.tablet`
    flex: 0 1 auto;
    width: 100%;
    background: var(--white-white-100, #FFF);
  `}
  
  option {
    padding: 8px 0px;
    border-radius: 4px;
    background: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: #3E4148;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14), 0px 4px 5px 0px rgba(0, 0, 0, 0.12), 0px 1px 10px 0px rgba(0, 0, 0, 0.20);
    cursor: pointer;
  }

  &:invalid {
    color: rgba(62, 65, 72, 0.35);
  }
`;

const Input = styled.input`
  display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #DEDFE2;
  background: #FAFAFA;
  margin: 0px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 2 1 0;
  cursor: ${({readOnly}) => readOnly ? 'default' : 'text'};

  ${mediaMax.tablet`
    flex: 0 1 auto;
    width: 100%;
    background: var(--white-white-100, #FFF);
  `}
`;

export default {
  Select,
  Input
}