import styled from 'styled-components';
import { mediaMin } from '../../../../shared/style';

const MobHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  gap: 8px;
  padding: 0px 16px;
  background-color: #0B1C54;
  border-bottom: 1px solid var(--white-white-35, rgba(255, 255, 255, 0.35));

  h3 {        
    color: #FFFFFF;

    
  };

  .divider {
    color: rgba(255, 255, 255, 0.35);
  };

  ${mediaMin.tablet`
      display: none;
    `}
`;

const LogoWrapper = styled.div`
  width: 81.082px;
  height: 24px;
`

const QmeritLogo = styled.img`
  width: 81.082px;
  height: 24px;
`

export default {
  MobHeader,
  LogoWrapper,
  QmeritLogo
};