import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import queryString from 'query-string';
import styledComponents from './styles';
import Loader from '../../components/Loader';
import useFetchData from './hooks';
import Modal from './components/Modal';

const {
  Container,
  Title,
  Message,
  Footer
} = styledComponents;

function stringToBoolean(str) {
  if (str === 'true') return true;
  return false;
}

export default function ManagerCanceled(props) {
  const [isCanceled, setIsCanceled] = useState(false || stringToBoolean(queryString.parse(props.location.search).canceled));
  const navigate = useHistory();

  const {
    data,
    isLoading,
    responseStatus,
    blobUrl,
    evBlob,
    translations,
    postData
  } = useFetchData(props);

  if (!isLoading && responseStatus === 200) {
    const bidStatus = data.Bids[0].Status;
    const requestId = data.Id;

    // handle cancel project button in modal
    const submitCancel = (radioActive, reason) => {
      setIsCanceled(true);
      postData(radioActive, reason);
      navigate.push(`/manager-canceled?canceled=true&token=${requestId}`); // persist isCanceled state in URL params
    }

    return (
      <Container>
        {!isCanceled && bidStatus !== 'cancelled' && 
          <Modal submitCancel={submitCancel} translations={translations.fleetReviewPage.modal} />
        }
        <img src={`${evBlob}/quote/clipboard.png`} alt="clipboard icon" />
        <Title>
          {translations.fleetReviewPage.projectCanceled.title}
        </Title>
        <Message>
          {translations.fleetReviewPage.projectCanceled.description} 
        </Message>
        <Footer src={`${blobUrl}/qmerit/wordmark/png/Qmerit-Wordmark-IG400.png`}/>
      </Container>
    )
  }

  return <Loader color={"#000000"} isLoading={isLoading} />
}