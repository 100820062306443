import styled, { css } from "styled-components";

const Container = styled.div`

    display: flex;
    height: 100vh;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ThanksForUpdate = styled.h2`
  color: rgb(62, 65, 72);
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  height: 24px;
  line-height: 24px;
  text-align: center;
  width: 400px;
  margin-bottom: 16px;
`;

const ThanksForUpdateText = styled.p`
  width: 400px;
  height: 20px;
  color: rgb(62, 65, 72);
  font-size: 16px;
  font-family: Lato;
  font-weight: normal;
  text-align: center;
  letter-spacing: 0px;
  line-height: 20px;
  margin:0;
`;


export default {
    Container,
    ThanksForUpdate,
    ThanksForUpdateText
}