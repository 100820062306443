import React, { useState, Fragment, useEffect } from 'react';
import styledComponents from './styles';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';
import SemiCircleProgressBar from '../../components/SemiCircleProgressBar';
import DropdownAmps from '../../components/DropdownAmps';
import { Modal1 } from '../../components/Modal';
import CalcContent from '../../components/CalcContent';
import { SVGToolTip } from '../../components/ToolTip';
import { mainBreakerOptions } from '../../components/DropdownAmps/options';
import { formatNumber } from '../../helper';
import { getProgressTextColor } from './getProgressTextColor';
import { linearGradient } from './linearGradientData';
import useDelayModalUnmount from '../../../UpfrontPricePage/components/Modal/hook';
import RecContainer from '../../components/RecContainer';
import { updateMainBreakerAmpAndRecs } from '../../services';

const {
  H2,
  H4
} = commonStyles;

const {
  Banner,
  ProgressBarContainer,
  ProgressHeader,
  ModalButton,
  MobileModalButton,
  InfoContainer,
  H2Progress,
  IconBox,
  BoxHeader,
  BoxText
} = styledComponents;

function getAvailableCapacity(mainBreakerAmp, totalAmp) {
  const capactiy = mainBreakerAmp * .8 - totalAmp;
  return formatNumber(capactiy >= 0 ? capactiy : 0);
}

function svgMagnifyingGlassChart() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
      <path d="M208 48a160 160 0 1 1 0 320 160 160 0 1 1 0-320zm0 368c48.8 0 93.7-16.8 129.1-44.9L471 505c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L371.1 337.1C399.2 301.7 416 256.8 416 208C416 93.1 322.9 0 208 0S0 93.1 0 208S93.1 416 208 416zM104 216v64c0 13.3 10.7 24 24 24s24-10.7 24-24l0-64c0-13.3-10.7-24-24-24s-24 10.7-24 24zm80-96V280c0 13.3 10.7 24 24 24s24-10.7 24-24V120c0-13.3-10.7-24-24-24s-24 10.7-24 24zm80 64v96c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24s-24 10.7-24 24z"
        fill="#3963fe"
      />
    </svg>
  )
}

function svgCalculator() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
      <path d="M336 176V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V176H336zm0-48H48V64c0-8.8 7.2-16 16-16H320c8.8 0 16 7.2 16 16v64zm48 0V64c0-35.3-28.7-64-64-64H64C28.7 0 0 28.7 0 64v64 24 24V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V176 152 128zM80 232a24 24 0 1 0 48 0 24 24 0 1 0 -48 0zm24 64a24 24 0 1 0 0 48 24 24 0 1 0 0-48zM80 408c0 13.3 10.7 24 24 24h88c13.3 0 24-10.7 24-24s-10.7-24-24-24H104c-13.3 0-24 10.7-24 24zM192 208a24 24 0 1 0 0 48 24 24 0 1 0 0-48zM168 320a24 24 0 1 0 48 0 24 24 0 1 0 -48 0zM280 208a24 24 0 1 0 0 48 24 24 0 1 0 0-48zM256 320a24 24 0 1 0 48 0 24 24 0 1 0 -48 0zm24 64a24 24 0 1 0 0 48 24 24 0 1 0 0-48z"
        fill="#FFFFFF"
      />
    </svg>
  )
}

export default function Summary({ mainBreaker, totalAmp, general, appliances, heavyLoad, ac, acValue1, recommendations, id, setCurrentRec, guid }) {
  const [mainBreakerAmp, setMainBreakerAmp] = useState(mainBreaker ? mainBreaker : 125);
  const [isMounted, setIsMounted] = useState(false);
  const modalOpen = useDelayModalUnmount(isMounted, 300);

  const handleDropdown = event => {
    setMainBreakerAmp(parseInt(event.target.value));
    updateMainBreakerAmpAndRecs(id, parseInt(event.target.value), setCurrentRec, guid);
  }

  const toggleMountModal = () => {
    setIsMounted(!isMounted);
  }
 
  return (
    <Banner domain={`${window.env.evBlob}`}>
      <ProgressBarContainer>
        <ProgressHeader>Current Load</ProgressHeader>
        <SemiCircleProgressBar key={mainBreakerAmp || totalAmp} totalAmp={totalAmp} mainBreakerAmp={mainBreakerAmp} />
        <ModalButton onClick={toggleMountModal}>
          <IconBox>{svgCalculator()}</IconBox>
          <span>How was this calculated?</span>
        </ModalButton>
        {modalOpen &&
          <Modal1 isMounted={isMounted} toggleMountModal={toggleMountModal} title={'How was this calculated?'}>
            <CalcContent general={general} appliances={appliances} heavyLoad={heavyLoad} acValue1={acValue1 ? parseFloat(acValue1) : ac.value1} />
          </Modal1>
        }
      </ProgressBarContainer>
      <InfoContainer>
        <div className="amps">
          <div className="box">
            <div className="boxHeader">
              <BoxHeader>Main Panel Size</BoxHeader>
              <div className="iconContainer">
                {!mainBreaker || mainBreaker === 0 ?
                  <IconBox>
                    {svgMagnifyingGlassChart()}
                  </IconBox>
                  :
                  <SVGToolTip svgIcon={svgMagnifyingGlassChart()}>
                    Detected by Panel Recognition AI
                  </SVGToolTip>
                }
              </div>   
            </div>
            <div className="boxHeader">
              <DropdownAmps key={mainBreakerAmp} options={mainBreakerOptions} defaultVal={mainBreakerAmp} handleDropdown={handleDropdown} />
            </div>
          </div>
          <div className="box">
            <BoxHeader>Available Capacity</BoxHeader>
            <div className="capacityContent">
              {recommendations.header ? 
                <Fragment>
                <H2Progress color={getProgressTextColor(linearGradient, 240, (totalAmp / mainBreakerAmp) * 100)}>{getAvailableCapacity(mainBreakerAmp, totalAmp)}</H2Progress> 
                <BoxText>amps</BoxText>
                </Fragment>
                :
                <H4 className="h4">Unable to calculate</H4>
              }
              
            </div>
          </div>
        </div>
        <RecContainer type={recommendations.type} header={recommendations.header} message={recommendations.message}/>
        <MobileModalButton onClick={toggleMountModal}>
          <IconBox>{svgCalculator()}</IconBox>
          <span>How was this calculated?</span>
        </MobileModalButton>
        {modalOpen &&
          <Modal1 isMounted={isMounted} toggleMountModal={toggleMountModal} title={'How was this calculated?'}>
            <CalcContent general={general} appliances={appliances} heavyLoad={heavyLoad} acValue1={acValue1 ? parseFloat(acValue1) : ac.value1} />
          </Modal1>
        }
      </InfoContainer>
    </Banner>
  )
}