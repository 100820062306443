import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    button {
        margin-top: 16px;
        max-width: 294px;
    }
    img {
        margin-bottom: 12px;
    }
    a {
        color: #ffffff
        width: 100%
    }
`;

export default {
    Container
};
