import { render } from "enzyme";
import React, {PureComponent} from "react"
import styledComponents from "./style";
import _ from "lodash";
import Wrapper from "../../components/Wrapper";
import Loader from "../../components/Loader";
import {TierUpdateSuccess} from './components/TierUpdateSuccess'
import { TierUpdateFail } from "../tierManage/components/TierUpdateFail";
import { InstallerStatus } from "../../components/InstallerStatus";
import FileEncoder from '../../lib/fileEncoder';
import {AddDocument, RemoveDocument, FileUpload} from './components/AddDocument';
import BidStatusError from '../../components/Errors';
import queryString from "query-string";

import Upload from "../../components/TierUpload";


class TieredSubmitQuote extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {  
            isLoading: true,
            selectedTier: '',
            tierChangeReason: '',
            guId: '',
            companyId: '',
            fetchedProjectInfo: {},
            tierSubmitSucess: false,
            tierFechedSucces: false,
            customPrice: '',
            document: '',
            contentType: '',
            permitCost: '',
            openNotes: true,
            calcObject: '',
            serviceFee: '',
            totalInstallation: '',
            uploadArray: [],
            previewArray: [],
            translations: {},
        };
    }

    componentDidMount() {

        let bidGuid = this.getProjectGuid(this.props.location.search)
        let companyId = this.getCompanyId(this.props.location.search)
        if (!_.isEmpty(bidGuid) || !_.isEmpty(companyId)) {
            this.setState({guId: bidGuid, companyId: companyId})
            this.getBidInfo(bidGuid)  
        } 
        if (_.has(window, 'env.translations') && !_.isEmpty(window.env.translations)) {
            this.setState({
                translations: window.env.translations
            })
        }
    }

    getProjectGuid = (url) => {
        if (url) {
            return queryString.parse(url).token
        }
    }

    getCompanyId = (url) => {
        if (url) {
            return queryString.parse(url).companyid
        }
    }


    handleTextareaChange = (event) => {
        event.persist();
        this.setState({tierChangeReason: event.target.value})
    }

    handleCustomPriceInput = (event) => {
        event.persist();
        let newValue = event.target.value;
        let modValue = (newValue.indexOf(".") >= 0) ? (newValue.substr(0, newValue.indexOf(".")) + newValue.substr(newValue.indexOf("."), 3)) : newValue;
        this.setState({customPrice: modValue});
        this.calculateOnBlur(newValue);
        return modValue;
    }

    handlePermitCostInput = (event) => {
        event.persist();
        let newValue = event.target.value;
        let modValue = (newValue.indexOf(".") >= 0) ? (newValue.substr(0, newValue.indexOf(".")) + newValue.substr(newValue.indexOf("."), 3)) : newValue;
        this.setState({permitCost: modValue});
        return modValue;
    }


    getBidInfo = (guId) => {
        let optionalParams = {
            headers: {"content-type": "application/json; charset=UTF-8", "Subscription-Key": `${window.env.apiKey}`},
            method: "GET"
        }

        return new Promise((resolve, reject) => {

            fetch(`${window.env.qmtUrl}/Bid/${guId}`, optionalParams)
                .then(res => {
                    this.setState({tierFechedSucces: res.status})
                    return res.json()})
                .then(data => {
                    this.setState({fetchedProjectInfo: data, isLoading: false})
            })
            .catch(error => {
                reject({
                    isLoading: false,
                    fetchedData: { ErrorCode: 500, ...error }
                });
            })
        })
    }

    handleFileToBase64 = (file) => {
        file.persist();
        const contentType = file.target.files[0].type;
        const formdata = new FormData();
        formdata.append("file", file.target.files[0]);

        let requestOptions = {
                headers: {"Subscription-Key": `${window.env.apiKey}`},
                method: 'POST',
                body: formdata,
                redirect: 'follow',
            };

        return new Promise((resolve, reject) => {

            fetch(`${window.env.qmtUrl}/Bid/${this.state.guId}/UploadQuote`, requestOptions)
                .then(res => {
                    return res.json()})
                .then(data => {
                  this.setState({
                    document: data,
                    contentType: contentType
                  })
            })
            .catch(error => {
                reject({
                    isLoading: false,
                    fetchedData: { ErrorCode: 500, ...error }
                });
            })
        })
    }

    handlePreviewDocument = () => {
      window.open(this.state.document, "_blank");
    }

    handleRemoveDocument = () => {
        this.setState({document: '', contentType: ''})
    }

    setOpenNotes = (value) => {
        this.setState({
            openNotes: value,
        });
    }

    setUploadArray = array => {
        this.setState({ uploadArray: array });
    };

    calculate = (value) => {
        let object = this.calculateOnBlur(value);
    }

    calculateOnBlur = (value) => {
        let bodyContent = {
            ProgramId: this.state.fetchedProjectInfo.Project.Tier.ProgramId ? this.state.fetchedProjectInfo.Project.Tier.ProgramId : 'DIRECT_PAY',
            Price: Number(value)
        }

        let optionalParams = {
            headers: {"content-type": "application/json; charset=UTF-8", "Subscription-Key": `${window.env.apiKey}`},
            method: "POST",
            body: JSON.stringify(bodyContent)
        }

        return new Promise((resolve, reject) => {

            fetch(`${window.env.qmtUrl}/ServiceFee/Calculate`, optionalParams)
                .then(res => {
                    return res.json()})
                .then(data => {
                    let total = (parseFloat(data.Payload.ServiceFeeAmount) + parseFloat(this.state.customPrice));
                    let modTotal = this.currencyFormatter(parseFloat(total).toFixed(2));
                    this.setState({
                        serviceFee: data.Payload.ServiceFeeAmount,
                        totalInstallation: modTotal,
                    });
            })
            .catch(error => {
                reject({
                    isLoading: false,
                    fetchedData: { ErrorCode: 500, ...error }
                });
            })
        })
    }

    decimalFormatter = (number) => {
        let formattedNumber = new Intl.NumberFormat('en-US', {
            style: 'decimal',
            currency: this.state.fetchedProjectInfo.ProgramCurrencyType,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });

          return formattedNumber.format(number);

    }


    currencyFormatter = (number) => {
        let formattedNumber = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          });

          return formattedNumber.format(number);

    }

    submitTierQuote = (event) => {
        if (event.detail === 1) {
            this.setState({isLoading: true})
            let data = {
                PermitCost: Number(this.state.permitCost),
                QuoteDocument: this.state.uploadArray[0].Url,
                QuoteReason: this.state.tierChangeReason,
                QuoteAmount: Number(this.state.customPrice),
            }
            let optionalParams = {
                headers: {"content-type": "application/json; charset=UTF-8", "Subscription-Key": `${window.env.apiKey}`},
                body: JSON.stringify(data),
                method: "POST"
            }
    
            new Promise((resolve, reject) => {
                fetch(`${window.env.qmtUrl}/Bid/${this.state.guId}/Accept`, optionalParams)
                .then(res => {
  
                    this.setState({tierSubmitSucess: res.status, isLoading: false})
                })
                .catch(error => {
    
                    reject({
                        isLoading: false,
                        fetchedData: { ErrorCode: 500, ...error }
                    });
                })
            })
        }

    }

    render() {
        const {
            Card,
            Header,
            PermitBidId,
            SubmitLabel,
            SubmitPrimaryLabel,
            SubmitSubLabel,
            PermitInfo,
            TierSubmitForm,
            Button,
            TierTextArea,
            CalcBox,
            UploadBox,
            TextAreaOpen,
        } = styledComponents;
 
        if ((_.has(this.state, 'fetchedProjectInfo.Status') && this.state.fetchedProjectInfo.Status === 'Declined')) {
            return <BidStatusError status={this.state.fetchedProjectInfo.Status} />
        }

        if (this.state.isLoading === false && !_.isEmpty(this.state.fetchedProjectInfo) && this.state.tierSubmitSucess === false) {
            return (
                <Wrapper name="" company="">
                    <Card>
                        <Header>Provide a custom quote</Header>
                        <PermitBidId>Project #{`${this.state.fetchedProjectInfo.Project.Id}`}</PermitBidId>
                       
                        <TierSubmitForm>
                            <div>
                                <SubmitPrimaryLabel>Labor and Materials Cost</SubmitPrimaryLabel>
                                <SubmitSubLabel>Exclude permitting costs.</SubmitSubLabel>
                                <div className="customPriceContainer">
                                    <span className='customePriceInputSpan' >{this.state.fetchedProjectInfo.ProgramCurrencySymbol}</span>
                                    <input
                                        className="customPriceInput"
                                        type={'number'}
                                        value={this.state.customPrice} 
                                        onChange={this.handleCustomPriceInput}
                                        // onBlur={(e) => this.calculateOnBlur(e.target.value)}
                                        data-scorpius-automation={'qap-tieredSubmitQuote-customPriceInput'}
                                    />
                                    <p className="currencyText">{this.state.fetchedProjectInfo.ProgramCurrencyType}</p>
                                </div>
                            </div>
                            <div>
                                <SubmitPrimaryLabel>Admin Fee</SubmitPrimaryLabel>
                                <SubmitSubLabel>Added fee for custom projects.</SubmitSubLabel>
                                <div className={`customPriceContainer ${this.state.customPrice ? '' : 'blank'}`}>
                                    <span className='customePriceInputSpan blank'>{this.state.fetchedProjectInfo.ProgramCurrencySymbol}</span>
                                        <div className="customPriceInput blank">
                                            {this.state.customPrice ? (
                                                <p data-scorpius-automation={'qap-tieredSubmitQuote-adminFee'}>{this.decimalFormatter(this.state.serviceFee)}</p>
                                            ) : (
                                                ''
                                            )}
                                        </div>

                                    <p className="currencyText">{this.state.fetchedProjectInfo.ProgramCurrencyType}</p>
                                </div>
                            </div>
                        </TierSubmitForm>
                        <CalcBox>
                        <p>Total Installation Services</p>
                            {this.state.customPrice ? (
                                <p data-scorpius-automation={'qap-tieredSubmitQuote-totalAmount'} className="boldText">{`${this.state.totalInstallation}`}</p>
                            ) : (
                                <p data-scorpius-automation={'qap-tieredSubmitQuote-NoTotalAmount'} className="italicText">To calculate, enter the Labor and Materials Cost.</p>
                            )}
                
      
                        </CalcBox>
                        <TierSubmitForm>
                        <div>
                                <SubmitLabel>Total Permit Cost</SubmitLabel>
                                <div className="customPriceContainer2">
                                    <span className='customePriceInputSpan' >{this.state.fetchedProjectInfo.ProgramCurrencySymbol}</span>
                                    <input
                                        className="customPriceInput"
                                        type={'number'}
                                        value={this.state.permitCost} 
                                        onChange={this.handlePermitCostInput}
                                        data-scorpius-automation={'qap-tieredSubmitQuote-permitCost'}
                                    />
                                    <p className="currencyText">{this.state.fetchedProjectInfo.ProgramCurrencyType}</p>
                                </div>
                            </div>
                        </TierSubmitForm>
                        {/* <div>
                            <SubmitPrimaryLabel>Quote Documents</SubmitPrimaryLabel>
                            <SubmitSubLabel>Verify the quote document displays the calculated Total Installation Services amount above before submitting.</SubmitSubLabel>
                            <UploadBox>
                                <div className='blueDot'>
                                    <i className="far fa-plus" />
                                </div>
                                <p>Upload</p>
                                <p>Drop or click to add</p>
                            </UploadBox>
                        </div> */}

                        {/* <FileUpload
                            displayed={true}
                            displayAddFile={!_.isEmpty(this.state.document) && !_.isEmpty(this.state.contentType)}
                            onHandlehandleFileToBase64={this.handleFileToBase64}
                            onHandleRemoveDocument={this.handleRemoveDocument} 
                            onHandlePreviewDocument={this.handlePreviewDocument}
                        /> */}
                        <SubmitPrimaryLabel>Quote Document</SubmitPrimaryLabel>
                        <SubmitSubLabel>Verify the quote document matches the calculated Total Installation Services amount above before uploading.</SubmitSubLabel>
                        <Upload
                            noLable
                            bgColor="#fafafa"
                            previewArray={this.state.previewArray}
                            setUploadArray={this.setUploadArray}
                            multiple={false}
                        />
                        
                        {this.state.openNotes === true ? (
                            <div>
                            <SubmitLabel>Customer Notes</SubmitLabel>
                            <TierTextArea
                                value={this.state.tierChangeReason}
                                onChange={this.handleTextareaChange}
                                data-scorpius-automation={'qap-tieredSubmitQuote-customerNotes'}
                            />
                        </div>
                        ) : (
                            <TextAreaOpen>
                                <div className='blueDot'>
                                    <div className="iconDiv">
                                        <i className="far fa-plus" />
                                    </div>
                                </div>
                                <p onClick={() => this.setOpenNotes(true)}>Include notes</p>
                            </TextAreaOpen>
                        )}

                        <PermitInfo>
                            <Button disabled={_.isEmpty(this.state.uploadArray) || _.isEmpty(this.state.permitCost) || _.isEmpty(this.state.customPrice) || (_.isEmpty(this.state.tierChangeReason) ? true : false )} onClick={this.submitTierQuote}>Submit</Button>
                        </PermitInfo>
                    </Card>
                </Wrapper>
            );

        }

    if (!this.state.isLoading && (this.state.fetchedProjectInfo.Status === 'Cancelled' || this.state.fetchedProjectInfo.Status === 'Closed' )) {
        return (<InstallerStatus translations={this.state.translations} installerStatus={this.state.fetchedProjectInfo.Status === "Closed" ? "Accepted" : this.state.fetchedProjectInfo.Status === "Cancelled" ? "Cancelled" : ''}/>)
    }

    if (!this.state.isLoading && (this.state.fetchedProjectInfo.Status === 'Declined' || this.state.fetchedProjectInfo.Status === 'Completed')) {
        return (<BidStatusError status={this.state.fetchedProjectInfo.Status} contactInfo={true} />)
    } 

    if (this.state.tierSubmitSucess === 200) {
        return <TierUpdateSuccess/>
    }

    if (this.state.tierSubmitSucess === 400 ||this.state.tierSubmitSucess === 404 || this.state.tierSubmitSucess === 500) {
        return <TierUpdateFail translations={this.state.translations} />
    }

     return (<Loader color={"#000000"} isLoading={this.state.isLoading} />)
    }
}

export default TieredSubmitQuote