import React, { Fragment } from 'react';
import StyledComponents from './styles';

const {
  ModalBackground,
  ModalContainer,
  ModalTitle,
  ModalCloseButton,
  ModalCloseIcon,
  ModalTimesIconDanger,
  ModalListContainer,
  ModalListItem,
  ModalListItemIconContainer,
  ModalCheckIcon,
  ModalListItemContentContainer,
  Header2,
  Text1,
  ScrollDisabler
} = StyledComponents;

const Modal = ({ isMounted, modalType, toggleMountModal, title, content }) => {

  return (
    <Fragment>
      <ModalBackground onClick={toggleMountModal} animate={isMounted ? 'in' : 'out'}>
        {/* Use stopPropagation so that close actions are not allowed in body of modal */}
        <ModalContainer onClick={e => e.stopPropagation()} animate={isMounted ? 'in' : 'out'}>
          <ModalTitle>
            <Header2>{title}</Header2>
            <ModalCloseButton onClick={toggleMountModal}>
              <ModalCloseIcon className="far fa-times" />
            </ModalCloseButton>
          </ModalTitle>
          <ModalListContainer>
            {/* Dynamic content */}
            {
              content.map((item, index) => {
                let printDivider = index === (content.length - 1)
                return (
                  <ModalListItem key={index + Math.random().toString(36).substring(2,9)}>
                    <ModalListItemIconContainer>
                      {modalType === 'Pricing Assumptions' ? <ModalCheckIcon className="fas fa-check" /> : <ModalTimesIconDanger className="fas fa-times" />}
                    </ModalListItemIconContainer>
                    <ModalListItemContentContainer  divider={printDivider ? 'none' : 'inset 0px -1px 0px #EDEEEF'}>
                      <Text1>{item}</Text1>
                    </ModalListItemContentContainer>
                  </ModalListItem>
                )
              })
            }
          </ModalListContainer>
        </ModalContainer>
      </ModalBackground>
      <ScrollDisabler />
    </Fragment>
  )
}

export default Modal