import styled from 'styled-components';
import {
  mediaMax
} from '../../../../../shared/style';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #DEDFE2;
  border-radius: 4px;
  margin-top: 8px;

  ${mediaMax.phone`
    border: none;
    padding: 24px 0px;
    margin-top: 0px;
    box-shadow: inset 0px -1px 0px #EDEEEF;
  `};
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  .timeStamp {
    color: #6E727C;
    margin: 0px;
    padding-left: 28px;
  }

  ${mediaMax.phone`
    gap: 4px;

    .timeStamp {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
  `};
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    margin: 0px;
    color: #3E4148;
  }

  ${mediaMax.phone`
    h3 {
      font-size: 16px;
      line-height: 20px;
    }
  `};
`;

const IconBox = styled.div`
  width: 20px;
  height: 20px;
  display:flex;
  align-items: center;
  justify-content: center;

  i {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #B9BBC1;
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 28px;

  p {
    margin: 0px;
  }

  ${mediaMax.phone`
    padding: 0px 0px 0px 28px;
  `};
`;

export default {
  Card,
  Title,
  TitleBox,
  IconBox,
  Details
};