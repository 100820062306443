import React from "react";
import styledComponents from "./style";
import commonStyledComponents from "../commanStyle";
import Switch from "../../../../../components/Switch";
import Upload from "../../../../../components/Upload";
import HeaderContent from "../Components/HeaderContent";
import Collapse from "../Components/Collapse";
import Message from "../Components/Message";

export default class RequirementHold extends React.PureComponent {
  // state = {
  //   isChecked: false,
  //   showHeader: false
  // };
  // uploadArray = () => {};
  // toggleSwitch = () => {
  //   this.setState({ isChecked: !this.state.isChecked });
  // };
  // toggleReq = () => {
  //   this.setState({ showHeader: !this.state.showHeader });
  // };
  render() {
    const {
      Text,
      // Label,
      // RequirementsApply,
      // Comment,
      // Msg,
      Content,
      // CenterMsg,
      Footer,
      MediumText
    } = commonStyledComponents;

    // const { Root, Req } = styledComponents;
    const { requirementData, status } = this.props;
    const headerDetail = {
      icon: status.icon,
      title: requirementData.Name,
      dec: status.showStatus,
      active: true
    };

    return (
      <Collapse headerDetail={headerDetail}>
        <div>
          <Message
            active={true}
            bigMsg={true}
            requirementData={{ ...requirementData }}
          />
          <Content>
            {/* <Root>
            <Req>
              <Text>
                <b>
                  Upload the required documents to complete this requirement
                </b>
              </Text>
            </Req>
            <Label>Attachments</Label>
            <Upload
              setUploadArray={this.uploadArray}
              readOnly={true}
              singleRow={true}
            />
            <RequirementsApply spaceTop={15}>
              <Switch
                text="This requirement does not apply to our company"
                toggleSwitch={this.toggleSwitch}
                isChecked={this.state.isChecked}
              />
            </RequirementsApply>
            <Comment>Add a comment…</Comment>
          </Root> */}
            <center>
              <Text spaceBottom={15}>
                <b>No action required</b>
              </Text>
            </center>
          </Content>
        </div>
        <Footer className="normal" spaceX={12}>
          <MediumText>
            On Hold requirements are completed by your connection.
          </MediumText>
        </Footer>
      </Collapse>
    );
  }
}
