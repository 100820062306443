import React, { Fragement } from "react";
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';
import styledComponents from './styles';

const {
  P
} = commonStyles;

const {
  Input,
  Label,
  CheckboxContainer
} = styledComponents;

export default function Checkbox({ isChecked, onClick }) {
  return (
    <Label>
      <CheckboxContainer>
        <Input defaultChecked={isChecked} onClick={onClick} />
      </CheckboxContainer>
      <P>I accept the Terms & Conditions for purchase of a Qmerit Installation Voucher</P>
    </Label>
  )
}