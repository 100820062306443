import styled from "styled-components";
import { buttonStyle } from "../../../../../shared/style";

export const CompleteStatusIcon = styled("img")`
  margin: 0 auto;
  height: 56px;
  margin-bottom: 20.67px;
  display: block;
`;

export const ProgressStatusIcon = styled("img")`
  margin: 0 auto;
  height: 52.89px;
  margin-bottom: 17.56px;
  display: block;
`;
export const Button = styled("a")`
  ${buttonStyle};
  display: flex;
  align-items: center;
  justify-content: center;
`;
