import React from 'react';
import styledComponents from './styles';


const {
  PanelSection,
  HeaderContainer,
  PanelContainer,
  IconBox,
  A
} = styledComponents;

export default function MainPanelMobile({image, analyticImage}) {
  return (      
  <PanelSection>
    <HeaderContainer>
      <h3>Main Panel</h3>
      <p>Detected by Panel Recognition AI</p>
    </HeaderContainer>
    <PanelContainer image={image} analyticImage={analyticImage}>
      <A href={image} target="_blank">  
        <div className="boxOriginal">
          <div className="imgOriginal"><IconBox><i className="far fa-expand-arrows"/></IconBox></div>
          <p className="link">Original</p>
        </div>
      </A>
      <A href={analyticImage} target="_blank">
        <div className="boxAnalyzed">
          <div className="imgAnalyzed"><IconBox><i className="far fa-expand-arrows"/></IconBox></div>
          <p className="link">AI Analyzed</p>
        </div>
      </A>
    </PanelContainer>
  </PanelSection>
  )
}