import styled, { css } from "styled-components";
import { theme, fade } from "../../shared/style";

export const Text = styled.p`
  margin: 0;
  padding-top: 3px;
  padding-left: 8px;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${theme.CoolGrey};
  ${props => props.checked && `color: ${theme.IndiqoGrey[800]};`};
`;
export const Label = styled.div`
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
`;
export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 22px;
  border: solid 1px ${theme.IndiqoGrey[300]};
  background-image: linear-gradient(
    to bottom,
    ${theme.IndiqoGrey[100]},
    ${theme.IndiqoGrey[200]}
  );
  background-color: ${theme.IndiqoGrey[100]};
  transition: 0.4s;
  border-radius: 34px;
  transition: 0.6s background;
  &::before {
    position: absolute;
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 22px;
    left: 3px;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0 1px 5px 0 ${fade(theme.Black, 0.2)},
      0 3px 4px 0 ${fade(theme.Black, 0.12)},
      0 2px 4px 0 ${fade(theme.Black, 0.14)};
    background-color: ${theme.White};
    transition: 0.4s left;
  }

  ${props =>
    props.checked &&
    ` 
    background-color: ${theme.Primary.main};
    background-image: none;
    &:before {
      left: calc(100% - 12px - 3px);
    }`};
`;

export default {
  Label,
  Slider,
  Text
};
