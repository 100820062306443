import styled from "styled-components";

const Root = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #3e4148;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    svg {
        ${props => props.absoluteLogo && 
            `position: absolute;
            bottom: 24px;`
        }
        width: 131px;
    }
`;

export const Title = styled.h2`
    font-family: Montserrat;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    margin: 0 0 16px;
`;

export const Description = styled.div`
    max-width: 360px;
    text-align: center;
`;

export default {
    Root,
    Wrapper
};