export default {
  User: {
    CompanyName: "AshbyKTech",
    Name: "Deepak AshbyKT"
  },
  Type: "Requirements",
  Activities: [
    {
      MasterActivityId: 23281,
      ParentActivityId: 23281,
      DerivedActivityId: null,
      GeneratedActivityId: null,
      Name: "BMW EV Charger Bid",
      Program: {
        Id: 4430,
        Name: "QMerit Default Program",
        DBAName: "AshbyKTech",
        ExternalData: null
      },
      OwnerProgram: {
        Id: 111,
        Name: "BMW EV Charger",
        DBAName: "Qmerit Inc.",
        ExternalData: null
      },
      ActivityTemplateId: 933,
      Priority: {
        Id: 5,
        Description: "Medium"
      },
      Status: {
        Id: "New",
        Name: "New"
      },
      StartBy: null,
      StartNoLaterThan: null,
      Assignment: null,
      Description:
        "Your company has been requested to bid for installing an EV charging station for a customer. Be sure to check the attached Customer Project Scope for the specific details of the installation needs!",
      Location: {
        Id: 3515,
        Name: "Residential",
        Description: null,
        Address: "4392 Casa Oro Dr.",
        City: "Anchorage",
        State: "AK",
        Zip: "99577",
        Country: "US",
        Latitude: 61.23396,
        Longitude: -149.270646,
        Contact: {
          Id: null,
          FirstName: null,
          LastName: null,
          Email: null,
          PhoneNumber: null,
          PreferredMethod: null,
          Title: null,
          Address: null,
          ImageUrl: null
        }
      },
      Customer: {
        Id: 7750,
        Name: "qmerit10+101_74 Long",
        Type: "Company",
        Contact: {
          Id: null,
          FirstName: "qmerit10+101_74",
          LastName: "Long",
          Email: "qmerit10+101_74@gmail.com",
          PhoneNumber: "+1 (212) 098-3732",
          PreferredMethod: "phoneNumber",
          Title: null,
          Address: "",
          ImageUrl: null
        }
      },
      DueDate: "2019-10-08T17:27:38.3696027",
      Tasks: {
        Id: "1",
        Name: "Submit bid information",
        Status: "New",
        CanComplete: true,
        Events: [],
        Actions: [
          {
            Files: [],
            MultiDocument: true,
            ProjectDocument: null,
            Id: "1b",
            Label: "Quote",
            Type: "Upload",
            IsCompleted: false
          },
          {
            CustomDecimal1: null,
            Id: "1c",
            Label: "Currency",
            Type: "Currency",
            IsCompleted: false
          },
          {
            CustomString1: null,
            Id: "1d",
            Label: "Bid Message",
            Type: "Text",
            IsCompleted: false
          }
        ],
        Tasks: [],
        Next: null
      },
      Events: [],
      Owner: {
        Id: 4508,
        DBAName: "Qmerit Inc.",
        ImageUrl:
          "https://senstorescorpiusqa.blob.core.windows.net/images-company/f572c8a1-8ed4-45b8-89b8-bda404814fc8",
        Contact: {
          Id: null,
          FirstName: "primaryFirst",
          LastName: "primaryLAst",
          Email: "ashbyqamike+qmerit@gmail.com",
          PhoneNumber: "18882720090",
          PreferredMethod: null,
          Title: "primaryTitle",
          Address: null,
          ImageUrl: null
        }
      },
      CompletionPercentage: 0,
      Type: "Bid",
      Project: {
        Id: 13423,
        Name: "BMW EV Charger",
        DueDate: null,
        Status: "New",
        ModifiedOn: null,
        Attachments: []
      },
      BidRequest: {
        Id: 13425,
        Name: "BMW EV Charger Bid",
        DueDate: "2019-10-08T17:27:38.3696027",
        Status: "New",
        ModifiedOn: "2019-09-24T17:27:40.2098469",
        Attachments: [
          {
            Id: 3386,
            Type: "BidRequest",
            Url:
              "https://evscorpiusqa.blob.core.windows.net/surveys/f9697e07-88e8-47ff-b627-5f91e4cc9adc",
            FileType: "application/pdf",
            Name: "Project Scope",
            IsProjectLevel: false,
            IsPublic: true,
            UploadType: "Qmerit",
            UploadId: null
          }
        ]
      },
      Collaborators: [],
      SelectedOn: null,
      CreatedOn: "2019-09-24T17:27:51.12",
      IsFrozen: false,
      AcceptedBy: null,
      DeclinedBy: null,
      ModifiedOn: null,
      RemovedOn: null,
      Operations: {
        ViewDetail: "5b67db1c-ed2b-4b03-9e9f-eda24f4ac401",
        Submit: "f7a74cf9-49a8-4028-8059-11c737e82d53",
        Decline: "9c9a4f7b-8ca3-4f06-a14c-a5385053ea59",
        Accept: "e20c5489-9f8f-43da-b16e-dd5f52e30238"
      },
      Id: 23282
    },
    {
      MasterActivityId: 23286,
      ParentActivityId: 23286,
      DerivedActivityId: null,
      GeneratedActivityId: null,
      Name: "BMW EV Charger Bid",
      Program: {
        Id: 4430,
        Name: "QMerit Default Program",
        DBAName: "AshbyKTech",
        ExternalData: null
      },
      OwnerProgram: {
        Id: 111,
        Name: "BMW EV Charger",
        DBAName: "Qmerit Inc.",
        ExternalData: null
      },
      ActivityTemplateId: 933,
      Priority: {
        Id: 5,
        Description: "Medium"
      },
      Status: {
        Id: "New",
        Name: "New"
      },
      StartBy: null,
      StartNoLaterThan: null,
      Assignment: null,
      Description:
        "Your company has been requested to bid for installing an EV charging station for a customer. Be sure to check the attached Customer Project Scope for the specific details of the installation needs!",
      Location: {
        Id: 3516,
        Name: "Residential",
        Description: null,
        Address: "4392 Casa Oro Dr.",
        City: "Anchorage",
        State: "AK",
        Zip: "99577",
        Country: "US",
        Latitude: 61.23396,
        Longitude: -149.270646,
        Contact: {
          Id: null,
          FirstName: null,
          LastName: null,
          Email: null,
          PhoneNumber: null,
          PreferredMethod: null,
          Title: null,
          Address: null,
          ImageUrl: null
        }
      },
      Customer: {
        Id: 7750,
        Name: "qmerit10+101_74 Long",
        Type: "Company",
        Contact: {
          Id: null,
          FirstName: "qmerit10+101_74",
          LastName: "Long",
          Email: "qmerit10+101_74@gmail.com",
          PhoneNumber: "+1 (212) 098-3732",
          PreferredMethod: "email",
          Title: null,
          Address: "",
          ImageUrl: null
        }
      },
      DueDate: "2019-10-09T20:30:09.7024943",
      Tasks: {
        Id: "1",
        Name: "Submit bid information",
        Status: "New",
        CanComplete: true,
        Events: [],
        Actions: [
          {
            Files: [],
            MultiDocument: true,
            ProjectDocument: null,
            Id: "1b",
            Label: "Quote",
            Type: "Upload",
            IsCompleted: false
          },
          {
            CustomDecimal1: null,
            Id: "1c",
            Label: "Currency",
            Type: "Currency",
            IsCompleted: false
          },
          {
            CustomString1: null,
            Id: "1d",
            Label: "Bid Message",
            Type: "Text",
            IsCompleted: false
          }
        ],
        Tasks: [],
        Next: null
      },
      Events: [],
      Owner: {
        Id: 4508,
        DBAName: "Qmerit Inc.",
        ImageUrl:
          "https://senstorescorpiusqa.blob.core.windows.net/images-company/f572c8a1-8ed4-45b8-89b8-bda404814fc8",
        Contact: {
          Id: null,
          FirstName: "primaryFirst",
          LastName: "primaryLAst",
          Email: "ashbyqamike+qmerit@gmail.com",
          PhoneNumber: "18882720090",
          PreferredMethod: null,
          Title: "primaryTitle",
          Address: null,
          ImageUrl: null
        }
      },
      CompletionPercentage: 0,
      Type: "Bid",
      Project: {
        Id: 13428,
        Name: "BMW EV Charger",
        DueDate: null,
        Status: "New",
        ModifiedOn: null,
        Attachments: []
      },
      BidRequest: {
        Id: 13429,
        Name: "BMW EV Charger Bid",
        DueDate: "2019-10-09T20:30:09.7024943",
        Status: "New",
        ModifiedOn: "2019-09-25T20:30:11.0965591",
        Attachments: [
          {
            Id: 3394,
            Type: "BidRequest",
            Url:
              "https://evscorpiusqa.blob.core.windows.net/surveys/02c1bce6-73a6-4093-ade3-1d551d06fa85",
            FileType: "application/pdf",
            Name: "Project Scope",
            IsProjectLevel: false,
            IsPublic: true,
            UploadType: "Qmerit",
            UploadId: null
          }
        ]
      },
      Collaborators: [],
      SelectedOn: null,
      CreatedOn: "2019-09-25T20:30:24.193",
      IsFrozen: false,
      AcceptedBy: null,
      DeclinedBy: null,
      ModifiedOn: null,
      RemovedOn: null,
      Operations: {
        ViewDetail: "2ac41687-d0b8-44f5-b97c-db8685b9744f",
        Submit: "312a96b1-5e5b-410d-b8ac-ec262a89aec2",
        Decline: "05206c0e-4ac6-4f0d-b877-290e31d895e3",
        Accept: "34d9bf57-2317-415b-b6d8-9fa668f8c1b9"
      },
      Id: 23287
    }
  ],
  OperationGuids: {}
};
