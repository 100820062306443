/* 
    This file can be used for styles specific to this component.
    Please keep it as simple and clean as possible :)
*/

import styled from "styled-components";
import { theme, fade, text } from "../../../../shared/style";

export const StatusTitle = styled("h2")`
  ${text};
  font-family: Montserrat;
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 16px;
`;
export const StatusButton = styled("button")`
  cursor: pointer;
  width: 100%;
  margin-bottom: 16px;
  border: 0px;
  background: rgb(255, 255, 255);
  border-radius: 12px;
  min-height: 48px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14),
    0px 3px 4px 0px rgba(0, 0, 0, 0.12), 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  padding: 8px 12px;
  img {
    margin-right: 12px;
  }
  &:last-child {
    margin-bottom: 8px;
  }
`;
export const StatusText = styled("p")`
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  color: ${fade(theme.IndiqoGrey[800], 0.75)};
`;
