import styled, { createGlobalStyle, css } from "styled-components"
import { mediaMax, mediaMin } from "../../../../shared/style"

const MobileModalBackground = styled.div`
  /* width: 100vw; */
  /* height: 100vh; */
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  background: #FFF;
  overflow: scroll;
`;

const MobileModalHeaderContainer = styled.div`
  width: 100%;
  height: 84px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  box-shadow: inset 0px -1px 0px #EDEEEF;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  background: #FFFFFF;
`;

const MobileModalHeaderTitle = styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 8px;
  width: 100%;
`;

const MobileModalCloseButton = styled.button`
  border: none;
  padding: 0px 5px;
  background: none;
`;

const MobileModalCloseIcon = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #B9BBC1;
`;

const MobileModalHeaderTitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;

const MobileModalHeaderTitleText = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #3E4148;
`;

// Everything rendered in this container should be dynamic
const MobileModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 16px 48px;
  gap: 24px;
  position: absolute;
  top: 88px;
`;

const MobileModalSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
`;

const MobileModalSectionHeaderText = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #3E4148;
  margin: 0px;
`;

const MobileUnorderedList = styled.ul`
  padding-left: 25px;
  margin: 0px;
`;

const MobileListItem = styled.li`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;  
  margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0px;
    }
`;

const ScrollDisabler = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

export default {
  MobileModalBackground,
  MobileModalHeaderContainer,
  MobileModalHeaderTitle,
  MobileModalCloseButton,
  MobileModalCloseIcon,
  MobileModalHeaderTitleContainer,
  MobileModalHeaderTitleText,
  MobileModalContentContainer,
  MobileModalSection,
  MobileModalSectionHeaderText,
  MobileUnorderedList,
  MobileListItem,
  ScrollDisabler
}