import styled, { createGlobalStyle } from 'styled-components';
import { mediaMax, mediaMin } from '../../shared/style';

const GlobalLoadCalculationFormStyles = createGlobalStyle`
  html {
      scrollbar-gutter: stable;
      scroll-behavior: smooth;
    }

  body {
    background: #F4F5F6;
    padding: 0px 24px;

    ${mediaMax.tablet`
      padding: 0px;
    `}
  }

  #root {
    color: #3E4148;
    padding: 0px !important;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  margin: 0px auto;
  width: 100%;

  @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;700;900&family=Red+Hat+Display:wght@400;500;700&display=swap');
  
  h1, h2, h3, h4 {
    font-family: "Red Hat Display", sans-serif;
  } 

  p, a, td, th, input, button, label, select, option, h5, span {
    font-family: "Mulish", sans-serif;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  
  .rightColumn {
    display: flex;
    flex-direction: column;
    flex: 3 1 0;
    gap: 48px;
    padding: 0px 0px 48px 24px;

    ${mediaMax.tablet`
      display: flex;
      flex-direction: column;
      flex: 0 1 auto;
      gap: 48px;
      padding: 0px 16px 48px;
    `}
  }

  .leftColumn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 24px;
    flex: 1 1 0;

    ${mediaMax.tablet`
      display: none;
    `}
  }

  ${mediaMax.tablet`
    display: flex;
    flex-direction: column;
    gap: normal;
    overflow-x: hidden;
  `}
`;

const Sticky = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: sticky;
  top: 68px;
  gap: 48px;
`;

export default {
  GlobalLoadCalculationFormStyles,
  Content,
  ColumnContainer,
  Sticky
}