import styled, { css, keyframes } from "styled-components";
import {
  theme,
  headerSizes,
  mediaMax,
  mediaMin,
  fade
} from "../../shared/style";
import { Body, Wraper } from "../Modal";

const Popup = styled.div`
  ${({ noMobile }) =>
    noMobile &&
    css`
      ${mediaMax.phone`display:none`};
    `} ${Wraper} {
      z-index: 9999999;
      .back-drop{
        background-color: ${fade("#dedfe2", 0.5)};
        /* backdrop-filter: blur(8px); */
      }
  }
  ${Body} {
    max-width: 311px;
    border-radius: 12px;
    /* box-shadow: 0 2px 4px 0 ${fade(theme.Black, 0.5)},
      0 3px 4px 0 ${fade(theme.Black, 0.23)}; */
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12),
    0px 3px 5px 0px rgba(0, 0, 0, 0.2);
    background-color: ${theme.White};
  }
`;

const Content = styled.div`
  padding: 15px;
  position: relative;
`;
const MButton = styled.button`
  cursor: pointer;
  width: 100%;
  border-radius: 100px;
  height: 32px;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 1px;
  text-align: center;
  ${props =>
    !props.submit
      ? `
    color: ${theme.IndiqoGrey[600]}
    border: solid 1px ${theme.IndiqoGrey[300]};
    background-image: linear-gradient(
      to bottom,
      ${theme.IndiqoGrey[100]}
      ,${theme.IndiqoGrey[300]}
    );
    &:hover {
      color: ${theme.IndiqoGrey[500]}
    border: solid 1px ${theme.IndiqoGrey[200]};
    background-image: linear-gradient(
      to bottom,
      ${theme.IndiqoGrey[300]}
      ,${theme.IndiqoGrey[100]}
    );
  }
    `
      : `
    color: ${theme.White}
    border: solid 1px ${theme.Action["700"]};
    background-image: linear-gradient(
      to bottom, ${theme.Primary.main}, ${theme.Action["700"]});
      &:hover {
        color: ${fade(theme.White, 0.8)}
    border: solid 1px ${fade(theme.Action["700"], 0.8)};
    background-image: linear-gradient(
      to bottom, ${theme.Action["700"]}, ${theme.Primary.main});
  }
    `};
`;

const Icon = styled.img`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${({ mb }) => (mb ? mb : 6)}px;
  display: block;
  margin-top: 10px;
`;
const Title = styled.h2`
  margin: 0 0 8px;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: normal;
  opacity: 0.75;
  color: #3e4148;
`;

const DeviceTitle = styled.p`
  margin: 0 0 24px;
  ${mediaMax.phone`margin: 0 0 24px;`};
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${theme.IndiqoGrey[600]};
  a {
    color: ${theme.Primary.main};
  }
`;
export default {
  Popup,
  Content,
  MButton,
  DeviceTitle,
  Title,
  Icon
};
