import React from 'react'
import styledComponents from "./styles";
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';

const {
  H2
} = commonStyles;

const {
  InformationSection,
  Table,
  TableHeader,
  TableRow,
  InformationMobile,
  RowContainer,
  RowTitle,
  RowDescription
} = styledComponents;

export default function ProjectInformation({fetchedRequestData, translations}) {
  return (
    <InformationSection>
      <H2>{translations.projectInformationSection.title}</H2>
      <Table>
        <thead>
          <TableHeader>
            <th>{translations.projectInformationSection.name}</th>
            <th>{translations.projectInformationSection.siteAddress}</th>
            <th>{translations.projectInformationSection.email}</th>
            <th>{translations.projectInformationSection.phone}</th>
          </TableHeader>
        </thead>
        <tbody>
          <TableRow>
            <td>{fetchedRequestData.Request.FirstName} {fetchedRequestData.Request.LastName}</td>
            <td>{fetchedRequestData.Request.FullAddress}</td>
            <td>{fetchedRequestData.Request.Email}</td>
            <td>{fetchedRequestData.Request.Phone}</td>
          </TableRow>
        </tbody>
      </Table>
      {/* Mobile View */}
      <InformationMobile>
        <RowContainer>
          <RowTitle>{translations.projectInformationSection.name}</RowTitle>
          <RowDescription>{fetchedRequestData.Request.FirstName} {fetchedRequestData.Request.LastName}</RowDescription>
        </RowContainer>
        <RowContainer>
          <RowTitle>{translations.projectInformationSection.siteAddress}</RowTitle>
          <RowDescription>{fetchedRequestData.Request.FullAddress}</RowDescription>
        </RowContainer>
        <RowContainer>
          <RowTitle>{translations.projectInformationSection.email}</RowTitle>
          <RowDescription>{fetchedRequestData.Request.Email}</RowDescription>
        </RowContainer>
        <RowContainer>
          <RowTitle>{translations.projectInformationSection.phone}</RowTitle>
          <RowDescription>{fetchedRequestData.Request.Phone}</RowDescription>
        </RowContainer>
      </InformationMobile>
      {/* End of Mobile View */}
    </InformationSection>
  )
}
