import React from "react";
import Message from "../../../components/message";
import ConfirmDeclineImage from "../../../../../public/images/Decline-Bid.png";

const BidDeclined = props => {
  return (
    <Message>
      <img alt="Confirm Decline" src={ConfirmDeclineImage} />
      <h1 style={{ color: "#3E4148" }}>Oh, we&apos;ll take care of this&hellip;</h1>
      <p style={{ color: "#3E4148" }}>
        Feel free to close this window. You can always adjust your settings to
        get bid requests that are more relevant to you.
      </p>
    </Message>
  );
};

export default BidDeclined;
