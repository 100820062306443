import styled, { css } from 'styled-components';
import {
	mediaMin,
	mediaMax,
} from "../../../../../shared/style"

const Card = styled.div`
  width: 100%;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  padding: 24px;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;

  /* height: 40px; */

  ${mediaMax.phone`
    gap: 8px;
    /* height: 20px; */
  `};
`;

const IconBox = styled.div`
  width: 40px;
  height: 40px;
  display:flex;
  align-items: center;

  ${mediaMax.phone`
    width: 20px;
    height: 20px;
  `};
`;

const TitleIcon = styled.i`
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */
  display: flex;
  align-items: center;
  text-align: center;

  /* indiqo/vibrant/300 - #3963F */
  color: #3963FE;

  ${mediaMax.phone`
    font-size: 16px;
    line-height: 20px;
  `};
`;

const TitleText = styled.h2`
  /* header/h2 */
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */
  display: flex;
  align-items: center;
  margin: 0px;

  /* text/default */
  color: #3E4148;

  ${mediaMax.phone`
    font-size: 16px;
    line-height: 20px;
  `};
`;

const Subtitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  display: flex;
  align-items: center;
  margin: 8px 0px 0px 0px;
  padding-left: 56px;

  /* text / secondary */
  color: #6E727C;

  ${mediaMax.phone`
    font-size: 14px;
    line-height: 16px;
    padding-left: 28px;
    margin: 4px 0px 0px 0px;
  `};
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 56px;
  gap: 16px;

  ${mediaMax.phone`
    /* padding: 16px 16px 24px 28px; */
    padding: 16px 0px 0px 28px;
    margin: 0px;
  `};
`;

const DetailsText = styled.p`

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin: 0px;
  /* identical to box height, or 125% */

  /* text/default */
  color: #3E4148;
`;

const DetailsList = styled.ul`
  margin: 0px;
`;

const DetailsListElement = styled.li`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 16px;
  /* identical to box height, or 125% */

  /* text/default */
  color: #3E4148;
`;

export default {
	Card,
	TitleBox,
	IconBox,
	TitleIcon,
	TitleText,
	Subtitle,
	Details,
	DetailsText,
	DetailsList,
	DetailsListElement
}

