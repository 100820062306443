import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import queryString from "query-string";
import _ from "lodash";
import formatter from "../../lib/formatter";
import styledComponents from "./style";
import DepositSuccess from "../../components//DepositSuccess";

import Loader from "../../components/Loader";
import CheckoutForm from "../../components/CheckoutForm/CheckoutForm"
import "./styles.css";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.

let stripePromise; 

const  { 
  SuperContainer,
  MainContainer,
  Container,
  MobileLogoContainer,
  MobileLogo,
  PreviewContainer,
  PreviewTitle,
  PreviewDescription,
  RevisedOnFlag,
  RevisedOnMessage,
  Table,
  TableHeader,
  TableRow,
  TableTotalRow,
  TableSubtotalRow,
  TableCurrencyRow,
  LogoContainer,
  Logo,
  PaymentContainer} = styledComponents

export default function TieredSubmitPayment(props) {

  const getInstallationId = (url) => {
    return queryString.parse(url).token
  }

  const formatNumber = (value) => {
    let formattedNumber = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });

    return formattedNumber.format(value);
  }

  function convertUTCDate(date, browserLanguage) {
    const localDate = formatter.getDateString(date, browserLanguage);
    return `${localDate.formattedDate}`;
  }

  let installationId = getInstallationId(props.location.search)

  const [clientSecret, setClientSecret] = useState("");
  const [fetchedInstallationData, setFetchedInstallationData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isAlreadyPaid, setIsAlreadyPaid] = useState(false);
  const [translations, setTranslations] = useState({});
  const [browserLanguage, setBrowserLanguage] = useState("");
  const [stripeLoaded, setStripeLoaded] = useState(false);

  useEffect(() => {

    fetch("/language", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => setBrowserLanguage(data.browserLanguage))

    if (_.has(window, 'env.translations') && !_.isEmpty(window.env.translations)) {
      setTranslations(window.env.translations)
    };

    if (stripeLoaded === false) {
      stripePromise = loadStripe(window.env.pKey);
      setStripeLoaded(true);
    }

    let optionalParams = {
      headers: {"content-type": "application/json; charset=UTF-8", "Subscription-Key": `${window.env.apiKey}`},
      method: "GET"
    }
    if (!_.isEmpty(installationId)) {
        fetch(`${window.env.qmtUrl}/Installation/${installationId}`, optionalParams)
            .then(res => res.json())
            .then(data => {
                setFetchedInstallationData(data);
                setIsLoading(false);

                if(data.Settlement.RemainingBalance > 0){
                  fetch("/create-payment-intent", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ 
                      amount: data.Settlement.RemainingBalance, 
                      requestId: data.RequestId,
                      currency: data.ProgramCurrencyType.toLowerCase(),
                      customerName: data.Customer.Name,
                      customerEmail: data.Customer.Email,
                      projectId: data.Project.Id
                    }),
                  })
                    .then((res) => res.json())
                    .then((data) => setClientSecret(data.clientSecret));
                }else{
                    setIsAlreadyPaid(true)
                }
            })
    } 
  }, []);

  const appearance = {
    theme: 'stripe',
    variables: {
      fontFamily: 'Lato-Regular, sans-serif',
      fontSizeBase: '14px',
      colorText: "rgb(62, 65, 72)",
      colorTextPlaceholder: 'rgba(62, 65, 72, 0.35)',
      spacingGridRow: "16px",
      borderRadius: "4px"
    }
  };

  const options = {
    clientSecret,
    appearance,
  };

  if(fetchedInstallationData.Settlement && !isAlreadyPaid){
    const charges = fetchedInstallationData.Settlement;
    const revisedOn = fetchedInstallationData.RevisedOn;
    return (
      <SuperContainer>
        <MainContainer>
        <Container>
          <MobileLogoContainer>
            <MobileLogo id='mobile-logo' alt="Qmerit" src={`${window.env.blobUrl}/qmerit/wordmark/svg/Qmerit-Wordmark-Indiqo.svg`} /> 
          </MobileLogoContainer>
          <PreviewContainer>
            <PreviewTitle>{translations.stripePayment.previewTitle}</PreviewTitle>
            <PreviewDescription>{translations.stripePayment.previewDescription}</PreviewDescription>
            {revisedOn && (
              <RevisedOnFlag>
                  <i className="far fa-exclamation-circle" />
                  {/* This needs to be updated using convertUTCDate function declared above once we get revisedOn from the backend */}
                  <RevisedOnMessage>{translations.stripePayment.revisedOnMessage} {convertUTCDate(revisedOn, browserLanguage)}. </RevisedOnMessage>
              </RevisedOnFlag>
            )}
            <Table>
              <thead>
                <TableHeader>
                  <th className="first-column">{translations.stripePayment.headerFirstColumn}</th>
                  <th className="second-column">{translations.stripePayment.headerSecondColumn}</th>
                  <th className="third-column">{translations.stripePayment.headerThirdColumn}</th>
                </TableHeader>
              </thead>
              <tbody>
                <TableRow>
                  <td className="first-column">{translations.stripePayment.installationServices}</td>
                  <td className="second-column">1</td>
                  <td className="third-column">{formatNumber(charges.TotalInstallationRetailCost)}</td>
                </TableRow>
                {!!charges.InstallCreditApplied && (
                  <TableRow>
                    <td className="first-column credit">{translations.stripePayment.installationCredit}</td>
                    <td className="second-column"></td>
                    <td className="third-column">{`- ${formatNumber(charges.InstallCreditApplied)}`}</td>
                  </TableRow>
                )}
                <TableRow>
                  <td className="first-column">{translations.stripePayment.permitTotal}</td>
                  <td className="second-column">1</td>
                  <td className="third-column">{formatNumber(charges.PermitCost)}</td>
                </TableRow>
                {!!charges.PermitCreditApplied && (
                  <TableRow>
                    <td className="first-column credit">{translations.stripePayment.permitCredit}</td>
                    <td className="second-column"></td>
                    <td className="third-column">{`- ${formatNumber(charges.PermitCreditApplied)}`}</td>
                  </TableRow>
                )}
                {!!charges.Deposit && (
                  <TableRow>
                    <td className="first-column">{translations.stripePayment.deposit}</td>
                    <td className="second-column">1</td>
                    <td className="third-column">- {formatNumber(charges.Deposit)}</td>
                  </TableRow>
                )}
                <TableSubtotalRow>
                  <td ></td>
                  <td className="label-field">{translations.stripePayment.subtotal}</td>
                  <td className="subtotal-field">{formatNumber(charges.RemainingBalance)}</td>
                </TableSubtotalRow>
                <TableTotalRow>
                  <td ></td>
                  <td className="label-field">{translations.stripePayment.total}</td>
                  <td className="total-field">{formatNumber(charges.RemainingBalance)}</td>
                </TableTotalRow>
                <TableCurrencyRow>
                  <td ></td>
                  <td ></td>
                  {/* This has to change once the endpoint returns currency */}
                  <td className="currency-field">{fetchedInstallationData.ProgramCurrencyType}</td>
                </TableCurrencyRow>
              </tbody>
            </Table>
          </PreviewContainer>
          <PaymentContainer>
            {clientSecret && (
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm 
                customerEmail={fetchedInstallationData.Customer.Email} 
                formTitle={translations.stripePayment.formTitle} 
                emailLabel={translations.stripePayment.emailLabel} 
                emailPlaceholder={translations.stripePayment.emailPlaceholder} 
                buttonLabel={translations.stripePayment.buttonLabel}
                paymentType='finalPayment'/>
            </Elements>
            )}
          </PaymentContainer>
        </Container>
        <Logo alt="Qmerit" src={`${window.env.blobUrl}/qmerit/wordmark/png/Qmerit-Wordmark-IG400.png`} />
        </MainContainer>
      </SuperContainer>
      
    )
}else if(isAlreadyPaid){
  return <DepositSuccess />
}else{
  return (<Loader color={"#000000"} isLoading={isLoading} />) 
}
}