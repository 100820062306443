import React from 'react'
import StyledComponents from './styles';
import _ from "lodash";
import formatter from '../../../../../lib/formatter';

const {
	Card,
	TitleBox,
	IconBox,
	TitleIcon,
	TitleText,
	Details,
	DetailsText,
} = StyledComponents;

export default function SignOffCard({ requestData, installedOnDate, translations, show }) {
	if(!show) return null;
	return (
		<React.Fragment>
			<Card>
				<TitleBox>
					<IconBox>
						<TitleIcon className="far fa-pen-nib" />
					</IconBox>
					<TitleText>
						{translations.title} {requestData.Project.Categories}.
					</TitleText>
				</TitleBox>
				<Details>
					<DetailsText>{translations.descriptionOne} {requestData.Project.ProjectName} {translations.descriptionTwo} <span style={{ fontWeight: 700 }}>{installedOnDate.formattedDate} {translations.descriptionThree} {installedOnDate.formattedTime}</span>.</DetailsText>
					<DetailsText>{translations.descriptionFour} <span style={{ fontWeight: 700 }}>{requestData.Email}</span>{translations.descriptionFive} {requestData.Project.Categories} {translations.descriptionSix}</DetailsText>
				</Details>
			</Card>
		</React.Fragment>
	)
}
