export default {
  User: null,
  Type: "GatheringEstimates",
  Activities: [],
  Bids: [
    {
      Reason: "Connection Removed",
      Status: "Declined",
      Assignment: {
        Id: 22119,
        DBAName: "Aayushi Parent Company",
        ImageUrl:
          "https://senstoresvcint.blob.core.windows.net/images-company/35d76bff-1a65-460d-9c20-5863759fb2d6",
        Score: 319
      },
      Tasks: null,
      SelectedOn: null,
      StartBy: null,
      StartNoLaterThan: null,
      ModifiedOn: "2019-11-01T07:22:42.3",
      AcceptedOn: null,
      RemovedOn: null,
      IsFrozen: false,
      Id: 50978
    },
    {
      Reason: null,
      Status: "Accepted",
      Assignment: {
        Id: 4472,
        DBAName: "Ashby7070a",
        ImageUrl:
          "https://senstoresvcint.blob.core.windows.net/images-company/0dbf1691-21eb-4345-bc60-e55c1e9332ad",
        Score: 337
      },
      Tasks: {
        Id: "1",
        Name: "Submit bid information",
        Status: "Completed",
        CanComplete: true,
        Events: [],
        Actions: [
          {
            Files: [
              {
                Id: "13084",
                Url:
                  "https://dacstoresvcint.blob.core.windows.net/documents/e09b04fb-d92c-4bc5-93f4-7cad06c30dee",
                FileType: "image/png",
                Type: "Qmerit",
                UploadId: null
              }
            ],
            MultiDocument: true,
            ProjectDocument: null,
            Id: "1b",
            Label: "Quote",
            Type: "Upload",
            IsCompleted: true
          },
          {
            CustomDecimal1: 909,
            Id: "1c",
            Label: "Currency",
            Type: "Currency",
            IsCompleted: true
          },
          {
            CustomString1: null,
            Id: "1d",
            Label: "Bid Message",
            Type: "Text",
            IsCompleted: false
          }
        ],
        Tasks: [],
        Next: null
      },
      SelectedOn: null,
      StartBy: null,
      StartNoLaterThan: null,
      ModifiedOn: "2019-10-31T11:57:07.917",
      AcceptedOn: "2019-10-31T11:55:29.866885",
      RemovedOn: null,
      IsFrozen: false,
      Id: 50979
    }
  ],
  Customer: {
    Id: 7921,
    Name: "testing cp SVCInt",
    ImageUrl: null,
    Type: "Company",
    Contact: {
      Id: null,
      FirstName: "testing cp",
      LastName: "SVCInt",
      Email: "ashbyqa7070@gmail.com",
      PhoneNumber: "9646227659",
      PreferredMethod: "email",
      Title: null,
      Address: "",
      ImageUrl: null
    }
  },
  OperationGuids: {}
};
