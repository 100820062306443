import { formatCurrencyWithFraction } from "../../../../QuoteFleetReviewPage/helper";
import _ from 'lodash';

const isFullySposored = (fullySponsoredThreshold, subtotal) => {
    if (!_.isNil(fullySponsoredThreshold) && !_.isNil(subtotal))
        return subtotal <= fullySponsoredThreshold;
    else
        return null;
}


function getQBAmount(quantity, unitPrice, userLocale = 'en-US', currencyType = 'USD') {
    const product = quantity * unitPrice;
    return formatCurrencyWithFraction(userLocale, currencyType, product);
}


export const itemValue = (itemData, description, fetchedBidData, translations) => {
    let item = '';
    fetchedBidData.Request.QuoteBuilderEnabled ?
        item = (!_.isNil(itemData.Name) ? itemData.Name : '')
        :
        itemData.Sku === '6' ?
            item = translations.preliminaryEstimateSection.customQuoteTitle
            :
            item = description

    return item;
}


export const unitPrice = (item, fetchedBidData, translations) => {
    let unitPrice = '';
    if (!_.isNil(fetchedBidData.Request.Program.FullySponsoredThreshold) && !_.isNil(fetchedBidData.Pricing.Subtotal)) {
        isFullySposored(fetchedBidData.Request.Program.FullySponsoredThreshold.Amount, fetchedBidData.Pricing.Subtotal.Amount) ?
            unitPrice = translations.preliminaryEstimateSection.fullySponsoredMessage
            :
            !_.isNil(item.RetailPrice.ShortFormat) ?
                unitPrice = item.RetailPrice.ShortFormat
                :
                !_.isNil(item.RetailPrice.Amount) ?
                    unitPrice = item.RetailPrice.Amount
                    :
                    unitPrice = null
    } else {
        !_.isNil(item.RetailPrice) ?
            unitPrice = item.RetailPrice.ShortFormat
            :
            unitPrice = null
    }

    return unitPrice;
}


export const qbAmount = (item, fetchedBidData, translations) => {
    let qbAmount = '';
    if (!_.isNil(fetchedBidData.Request.Program.FullySponsoredThreshold) && !_.isNil(fetchedBidData.Pricing.Subtotal)) {
        isFullySposored(fetchedBidData.Request.Program.FullySponsoredThreshold.Amount, fetchedBidData.Pricing.Subtotal.Amount) ?
            qbAmount = translations.preliminaryEstimateSection.fullySponsoredMessage
            :
            !_.isNil(item.RetailPrice.Amount) ?
                qbAmount = getQBAmount(item.Quantity, item.RetailPrice.Amount, fetchedBidData.Request.UserLocale, item.RetailPrice.CurrencyType)
                :
                qbAmount = null
    } else {
        !_.isNil(item.RetailPrice) ?
            qbAmount = getQBAmount(item.Quantity, item.RetailPrice.Amount, fetchedBidData.Request.UserLocale, item.RetailPrice.CurrencyType)
            :
            qbAmount = null
    }

    return qbAmount;
}


export const permit = (fetchedBidData, translations) => {
    let permit = '';
    if (!_.isNil(fetchedBidData.Request.Program.FullySponsoredThreshold) && !_.isNil(fetchedBidData.Pricing.Subtotal)) {
        isFullySposored(fetchedBidData.Request.Program.FullySponsoredThreshold.Amount, fetchedBidData.Pricing.Subtotal.Amount) ?
            permit = translations.preliminaryEstimateSection.fullySponsoredMessage
            :
            !_.isNil(fetchedBidData.Request.Settlement.PermitCost.ShortFormat) ?
                permit = fetchedBidData.Request.Settlement.PermitCost.ShortFormat
                :
                !_.isNil(fetchedBidData.Request.Settlement.PermitCost.Amount) ?
                    permit = fetchedBidData.Request.Settlement.PermitCost.Amount
                    :
                    permit = null
    } else {
        !_.isNil(fetchedBidData.Request.Settlement.PermitCost) ?
            permit = fetchedBidData.Request.Settlement.PermitCost.ShortFormat
            :
            permit = null
    }

    return permit;
}


export const installationCost = (fetchedBidData, translations) => {
    let installationCost = '';
    if (!_.isNil(fetchedBidData.Request.Program.FullySponsoredThreshold) && !_.isNil(fetchedBidData.Pricing.Subtotal)) {
        isFullySposored(fetchedBidData.Request.Program.FullySponsoredThreshold.Amount, fetchedBidData.Pricing.Subtotal.Amount) ?
            installationCost = translations.preliminaryEstimateSection.fullySponsoredMessage
            :
            !_.isNil(fetchedBidData.Request.Settlement.InstallationCost.ShortFormat) ?
                installationCost = fetchedBidData.Request.Settlement.InstallationCost.ShortFormat
                :
                !_.isNil(fetchedBidData.Request.Settlement.InstallationCost.Amount) ?
                    installationCost = fetchedBidData.Request.Settlement.InstallationCost.Amount
                    :
                    installationCost = null
    } else {
        !_.isNil(fetchedBidData.Request.Settlement.InstallationCost) ?
            installationCost = fetchedBidData.Request.Settlement.InstallationCost.ShortFormat
            :
            installationCost = null
    }


    return installationCost;
}


export const amount = (item, fetchedBidData, translations) => {
    let amount = '';
    if (!_.isNil(fetchedBidData.Request.Program.FullySponsoredThreshold) && !_.isNil(fetchedBidData.Pricing.Subtotal)) {
        isFullySposored(fetchedBidData.Request.Program.FullySponsoredThreshold.Amount, fetchedBidData.Pricing.Subtotal.Amount) ?
            amount = translations.preliminaryEstimateSection.fullySponsoredMessage
            :
            !_.isNil(item.RetailPrice.ShortFormat) ?
                amount = item.RetailPrice.ShortFormat
                :
                !_.isNil(item.RetailPrice.Amount) ?
                    amount = item.RetailPrice.Amount
                    :
                    amount = null
    } else {
        !_.isNil(item.RetailPrice) ?
            amount = item.RetailPrice.ShortFormat
            :
            amount = null
    }

    return amount;
}


