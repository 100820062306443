import styled, { css, createGlobalStyle } from 'styled-components';
import {
  mediaMax,
  mediaMin
} from "../../shared/style"

const QmeritHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 8px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  height: 88px;
  width: 100%;
  background: #0B1C54;
  ${props =>
    props.cultureCode === 'en-US' && css`
      background-image: ${({ domain }) =>  
        `url('${domain}/upfront-pricing/bg1.png'), url('${domain}/upfront-pricing/bg2.png')`};
      background-position: top left, top right;
      background-repeat: no-repeat;
      
      ${mediaMax.desktop`
        background-image: ${({ domain }) =>  
        `url('${domain}/upfront-pricing/bg2.png')`};
      background-position: top right;
      `};
  `};

  ${mediaMax.desktop`
    justify-content: flex-end;
    height: 52px;
    padding: 16px 16px;
    position: absolute;
  `};
`;

const InfoWrapper = styled.div`
  display: flex;
  gap: 0px 48px;
  padding: 48px 100px 0px;
  min-height: calc(100vh - 486px);
  display:flex;
  justify-content: center;
  background-color: white;

  ${mediaMax.desktop`
    flex-direction: column;
    padding: 16px 16px 0px 16px;
  `};

  ${mediaMax.tablet`
    gap: 0px 16px;
  `};
`;

const LeftColumn = styled.div`
  width: 1003px;

  ${mediaMax.desktop`
    width: 100%;
  `};

  ${mediaMin.desktop`
    min-width: 482px;
  `};

`;

const EstimateDivider = styled.div`
  display: block;
  height: 1px;
  background: #DEDFE2;
  margin: 48px 0px;

  ${mediaMax.desktop`
    margin: 24px 0px;
    width: 100%
  `};

  ${props => props.noRebates &&
    ` display: none;`
  } 

`;

const MobileSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;

  ${mediaMin.desktop`
    display: none;
  `};

`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 477px;

  ${mediaMax.desktop`
    display: none;
  `};

  ${mediaMin.desktop`
    min-width: 322px;
  `};
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 24px;
  height: 68px;
  width: 1528px;
  padding: 24px 0px;

  ${mediaMax.desktop`
    display: none;
  `};
`;

const FooterMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 100%;

  ${mediaMin.desktop`
    display: none;
  `};
`;

const FooterDivider = styled.div`
  display: block;
  height: 1px;
  background: #EDEEEF;
  margin-top: 100px;

  ${mediaMax.desktop`
    margin-top: 48px;
    height: 0px
  `};

`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 1728px) {
    padding: 0px 100px;
  }

  @media only screen and (max-width: 1166px) {
    padding: 0px 24px;
  }

  ${mediaMax.desktop`
    padding: 24px 24px 34px
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.75);
    border-top: 1px solid #DEDFE2;
    backdrop-filter: blur(12px);
  `};
`;

export default {
  QmeritHeader,
  InfoWrapper,
  LeftColumn,
  EstimateDivider,
  MobileSection,
  RightColumn,
  Footer,
  FooterMobile,
  FooterDivider,
  FooterContainer
}