import styled from "styled-components";
import {
    mediaMax,
    mediaMin,
    theme,
    smallText,
    text,
    fade,
    mediumText,
    PaddinMarginBits,
    headerSizes
  } from "../../../shared/style";

const TextWrap = styled.div`
  flex: 0 0 calc(100% - 20px);
  max-width: calc(100% - 20px);
  ${mediaMax.phone`padding: 0 ${12 / 2}px;`};

  p.accepted, p.submitted {
    color: #8bc34a
  }
  p.declined {
    color: #ff5722
  }
`;

const SubTitle = styled.p`
  ${mediumText};
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

`;

const Title = styled.h2`
  ${text};
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
`;

export default {
    TextWrap,
    SubTitle,
    Title
};
