import React from 'react'
import StyledComponents from './styles';
import _ from "lodash";

import {
	getBidServiceProviderName,
	getBidServiceProviderPhone,
	getBidServiceProviderEmail,
  } from "../../helpers/BidsDates"

const {
	Wrapper,
	ProjectDetailsCard,
	CardTitle,
	DetailTitle,
	DetailContent
} = StyledComponents;

export default function ProjectDetailsWrapper({ requestData, translations, currentStatus }) {

	return (
		<Wrapper>
			<ProjectDetailsCard>
				<CardTitle>{translations.general.projectDetails}</CardTitle>
				<DetailTitle>{translations.general.project}</DetailTitle>
				{!_.isNil(requestData.Project) &&
					<DetailContent>{requestData.Project.ProjectName}</DetailContent>
				}
				{_.isNil(requestData.Project) &&
					`${translations.general.noSPAssigned}`
				}
				<DetailTitle>{translations.general.contactInformation}</DetailTitle>
				<DetailContent>{requestData.FirstName} {requestData.LastName}</DetailContent>
				<DetailContent>{requestData.Phone}</DetailContent>
				<DetailContent>{requestData.Email}</DetailContent>
				<DetailTitle>{translations.general.site}</DetailTitle>
				<DetailContent>{requestData.Street}</DetailContent>
				<DetailContent>{requestData.City}, {requestData.State} {requestData.ZipCode}</DetailContent>
				<DetailTitle>{translations.general.serviceProvider}</DetailTitle>
				{(getBidServiceProviderName(requestData) && currentStatus.name !== translations.bannerSection.assignment.statusName) ?
					<React.Fragment>
						<DetailContent>{getBidServiceProviderName(requestData)}</DetailContent>
						<DetailContent>{getBidServiceProviderPhone(requestData)}</DetailContent>
						<DetailContent>{getBidServiceProviderEmail(requestData)}</DetailContent>
					</React.Fragment>
					:
					<DetailContent italic>{translations.general.noSPAssigned}</DetailContent>
				}
			</ProjectDetailsCard>
		</Wrapper>
	)
}
