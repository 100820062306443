import React from "react";
import HeaderContent from "../HeaderContent";
import styledComponent from "../../commanStyle";
class Message extends React.Component {
  state = {
    showHeader: this.props.active || false
  };
  toggleReq = () => {
    this.setState({ showHeader: !this.state.showHeader });
  };
  render() {
    const { uploadArray, requirementData, bigMsg } = this.props;
    const { Msg, CenterMsg } = styledComponent;
    return (
      <Msg>
        {this.state.showHeader && (
          <HeaderContent
            bigMsg={bigMsg}
            // uploadArray={uploadArray}
            requirementData={requirementData}
          />
        )}
        <CenterMsg onClick={this.toggleReq}>
          {this.state.showHeader ? "HIDE" : "VIEW"} REQUIREMENT DETAILS
        </CenterMsg>
      </Msg>
    );
  }
}
export default Message;
