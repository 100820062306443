import React, { PureComponent } from 'react';
import CustomRadio from '../../components/CustomRadio';
import QuickCard from '../../components/QuickCard';
import RequestCancelled from '../../components/RequestCancelled';
import FetchFailure from '../../components/FetchFailure';
import styledComponent from './style';
import { Button } from '../../shared/style';
import DropDown from '../../components/DropDown';
import Loader from '../../components/Loader/Loading';
import _ from 'lodash';
import Error from '../RequirementStatus/Card/CollapseData/Components/Error';
import queryString from "query-string";

import translationsLocalData from "../../../server/locale/en_US.json"

const options = ['SwitchGear', 'Tenkan', 'My own contractor'];
const { Root, Container, Title, Text, ContentWrapper, RadioWrapper, TextArea, MessageWrapper, PoweredBy } =
    styledComponent;

const reasonTextErrorStyle = {
    width: 600,
    height: 44,
    background: 'rgb(251, 233, 231)',
    border: '1px solid rgb(255, 87, 34)',
    borderRadius: 4,
    marginBottom: 0,
};

let radios = [];
let translations = {};

class TieredCancelRequest extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isDropDownActive: false,
            radioActive: null,
            cancelSuccess: null,
            reason: null,
            completedBy: null,
            isLoading: true,
            buttonDisable: 'disabled',
            translations: {},
            isError: false,
            fetchedBidStatus: null
        };
    }

    dropDownToggle = () => {
        const { isDropDownActive } = this.state;
        this.setState({ isDropDownActive: !isDropDownActive });
    };

    getRequestGuid = (url) => {
        return queryString.parse(url).token
    }

    getRequestByGuid = (requestGuid) => {

        let optionalParams = {
            headers: { "content-type": "application/json; charset=UTF-8", "Subscription-Key": `${window.env.apiKey}` },
            method: "GET",
            query: requestGuid
        }

        return new Promise((resolve, reject) => {
            fetch(`${window.env.qmtUrl}/Request/${requestGuid}`, optionalParams)
                .then(response => {
                    this.setState({ fetchedBidStatus: response.status });
                    return response.json()
                })
                .then(data => {
                    if (data) resolve({ fetchedData: data, isLoading: false });
                })
                .catch(error => {
                    reject({
                        isLoading: false,
                        fetchedData: { ErrorCode: 500, ...error }
                    });
                })
        })
    }


    cancelRequest = (guid) => {
        const { radioActive, completedBy, reason } = this.state;
        const body = {
            SelectedOption: radioActive,
            Reason: reason,
            CompletedBy: completedBy,
            CloseProject: true,
        };

        let optionalParams = {
            headers: { "content-type": "application/json; charset=UTF-8", "Subscription-Key": `${window.env.apiKey}` },
            body: JSON.stringify(body),
            method: "POST"
        }

        new Promise((resolve, reject) => {
            fetch(`${window.env.qmtUrl}/Request/${guid}/Cancel`, optionalParams)
                .then(response => {
                    return response.json()
                })
                .then(res => {
                    if (res) {
                        this.setState({
                            cancelSuccess: res.Success,
                            isLoading: false
                        });
                    }
                })
                .catch(error => {

                    reject({
                        isLoading: false,
                        fetchedData: { ErrorCode: 500, ...error }
                    });
                })
        })
    };

    componentDidMount() {
        if (_.has(window, 'env.translations') && !_.isEmpty(window.env.translations)) {
            translations = window.env.translations;
        } else {
            translations = translationsLocalData;
        }

        radios = [
            {
                id: 1,
                label: translations.cancelRequest.radiosLabelOne,
                value: translations.cancelRequest.radiosLabelOne,
            },
            {
                id: 2,
                label: translations.cancelRequest.radiosLabelTwo,
                value: translations.cancelRequest.radiosLabelTwo,
            },
            {
                id: 3,
                label: translations.cancelRequest.radiosLabelThree,
                value: translations.cancelRequest.radiosLabelThree,
            },
            {
                id: 4,
                label: translations.cancelRequest.radiosLabelFour,
                value: translations.cancelRequest.radiosLabelFour,
            },
            {
                id: 5,
                label: translations.cancelRequest.radiosLabelFive,
                value: translations.cancelRequest.radiosLabelFive,
            },
            {
                id: 6,
                label: translations.cancelRequest.radiosLabelSix,
                value: translations.cancelRequest.radiosLabelSix,
            },
            {
                id: 7,
                label: translations.cancelRequest.radiosLabelSeven,
                value: translations.cancelRequest.radiosLabelSeven,
            },
            {
                id: 8,
                label: translations.cancelRequest.radiosLabelEight,
                value: translations.cancelRequest.radiosLabelEight,
            },
        ];

        document.body.classList.add('bg-grey');

        let requestGuid = this.getRequestGuid(this.props.location.search);

        this.getRequestByGuid(requestGuid).then(data => {
            if (data.fetchedData && this.state.fetchedBidStatus == 200 && data.fetchedData.CancelledOn !== null) {
                this.setState({
                    ...data,
                    requestGuid,
                    cancelSuccess: true,
                    isLoading: false,
                });
            } else {
                this.setState({ requestGuid, ...data, isLoading: false });
            }
        });

    }

    completedBy = data => {
        const { isDropDownActive } = this.state;
        this.setState({ completedBy: data, isDropDownActive: !isDropDownActive });
    };

    radioChange = value => {
        let isError = false,
            buttonDisable = '',
            radioActive = value;

        if (radioActive === translations.cancelRequest.radiosLabelEight) {
            this.setState({ radioActive });
            this.reasonChange(this.state.reason, radioActive);
        } else {
            this.setState({ radioActive, buttonDisable, isError });
        }
    };

    reasonChange = (value, radioActive) => {
        let reason = value,
            isError = false,
            buttonDisable = '';

        if (radioActive === translations.cancelRequest.radiosLabelEight) {
            if (_.isNil(reason) || reason.length < 2) {
                isError = true;
                buttonDisable = 'disabled';
            }
        } else {
            if (_.isNil(radioActive)) {
                buttonDisable = 'disabled';
            }
        }

        this.setState({ reason, isError, buttonDisable });
    };

    cancelConfirm = () => {
        const { radioActive, isError } = this.state;

        let requestGuid = this.getRequestGuid(this.props.location.search);

        if (radioActive && !isError) {
            this.cancelRequest(requestGuid);
        }
    };


    dropDownCompletedBy = () => {
        return (
            <DropDown
                clr
                data={options}
                noLabel
                setSelected={this.completedBy}
                placeholder='Select who completed…'
                selected={this.state.completedBy}
            />
        );
    };

    renderRadio = () => {
        return radios.map((data, index) => {
            return (
                <div key={index}>
                    <CustomRadio
                        onChange={e => this.radioChange(e.target.value)}
                        id={data.id}
                        name='reason'
                        label={data.label}
                        value={data.value}
                    ></CustomRadio>
                </div>
            );
        });
    };

    render() {
        const { cancelSuccess, radioActive, reason, fetchError, isLoading, buttonDisable, isError } = this.state;

        if (isLoading) {
            return <Loader color='#6e727c' />;
        }
        if (fetchError) {
            return <FetchFailure maps />;
        }
        if (cancelSuccess) {
            return <RequestCancelled translations={translations} />;
        }

        return (
            <Root>
                <Container>
                    <QuickCard>
                        <div>
                            <Title>{translations.cancelRequest.title}</Title>
                            <ContentWrapper>
                                <Text>{translations.cancelRequest.description}</Text>
                                <RadioWrapper>{this.renderRadio()}</RadioWrapper>
                            </ContentWrapper>
                            <MessageWrapper>
                                <Text>
                                    {translations.cancelRequest.reasonLabel}
                                    {radioActive !== translations.cancelRequest.radiosLabelEight && (
                                        <i>{translations.cancelRequest.text}</i>
                                    )}
                                </Text>
                                <TextArea
                                    style={isError ? reasonTextErrorStyle : {}}
                                    onChange={e => this.reasonChange(e.target.value, this.state.radioActive)}
                                    placeholder={translations.cancelRequest.reason}
                                />
                                {isError === true && (
                                    <Error
                                        text={translations.cancelRequest.error}
                                    />
                                )}
                            </MessageWrapper>
                        </div>
                        <div>
                            <Button
                                danger
                                className={buttonDisable}
                                mx='auto'
                                style={{ padding: 0, marginTop: 24 }}
                                dBlock
                                onClick={this.cancelConfirm}
                            >
                                {translations.cancelRequest.button}
                            </Button>
                        </div>
                    </QuickCard>
                    <PoweredBy
                        desktop
                        src={`${window.env.blobUrl}/qmerit/wordmark/png/Qmerit-Wordmark-IG400.png`}
                        alt='Qmerit'
                    />
                </Container>
            </Root>
        );
    }
}

export default TieredCancelRequest;
