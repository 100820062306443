import styled, { css } from 'styled-components';
import { mediaMax, mediaMin } from '../../../../shared/style';

const BannerWrapper = styled.div`
  width: 100%;
  background: ${({ showBg }) => showBg ? 'linear-gradient(171.85deg, #0B1C54 -12.1%, #243A83 165.36%)' : 'transparent'};

  ${mediaMax.phone`
    background: ${({ showBg }) => showBg ? 'linear-gradient(171.85deg, #0B1C54 -12.1%, #243A83 165.36%)' : 'transparent'};
  `};
`;

const BannerHeader = styled.div`
  width: 100%;
  background: #0B1C54;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  color: #fff;
  border-bottom-left-radius: 50% 25%;
  border-bottom-right-radius: 50% 25%;
  ${props =>
    props.cultureCode === 'en-US' && css`
      background-image: ${({ domain }) =>  
        `url('${domain}/upfront-pricing/bg1.png'), url('${domain}/upfront-pricing/bg2.png')`};
      background-position: bottom -91px left, bottom -91px right;
      background-repeat: no-repeat;
      
      ${mediaMax.desktop`
        background-image: ${({ domain }) =>  
        `url('${domain}/upfront-pricing/bg2.png')`};
      background-position: bottom -91px right;
      `};
  `};

  ${mediaMin.tablet`
    padding: 48px 16px 24px;
    gap: 24px;
    border-bottom-left-radius: 50% 15%;
    border-bottom-right-radius: 50% 15%;
  `};

  ${mediaMax.phone`
    padding: 48px 16px 24px;
    gap: 24px;
    border-bottom-left-radius: 50% 10%;
    border-bottom-right-radius: 50% 10%;
  `};
`;

const H1 = styled.h1`
  width: 100%;
  padding-top: 48px;
  font-size: 32px;
  line-height: 40px;
  margin: 0px;

  ${mediaMin.tablet`
    padding-top: 0px;
  `};

  ${mediaMax.phone`
    padding-top: 0px;
  `};
`;

const H3 = styled.h3`
  max-width: 1129px;
  padding-top: 24px;
  font-size: 18px;
  line-height: 24px;
  margin: 0px;

  ${mediaMin.tablet`
    padding-top: 0px;
  `};

  ${mediaMax.phone`
      padding-top: 0px;
    `};
`;

const ToggleButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  text-transform: uppercase;
  background: transparent;
  color: #FFF;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  border: none;
  cursor: pointer;
  padding: 50px 0px 26px;

  ${mediaMin.tablet`
    padding: 26px 0px 0px;
  `};

  ${mediaMax.phone`
    padding: 26px 0px 0px;
  `};
`;

const MobileToggleButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  text-transform: uppercase;
  background: transparent;
  color: #FFF;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  border: none;
  cursor: pointer;
  padding: 0px;

  ${mediaMin.tablet`
    display: none;
  `};
`;

const TabletToggleButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  text-transform: uppercase;
  background: transparent;
  color: #FFF;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  border: none;
  cursor: pointer;
  padding: 0px;

  @media (min-width: 912px) {
    display: none;
  }

  ${mediaMax.phone`
    display: none;
  `};
`;

const Chevron = styled.i`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
`;

const BannerContent = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mediaMax.desktop`
    padding-bottom: 48px;
  `};

  ${mediaMax.phone`
    padding-bottom: 0px;
  `};
`;

const ValuePoints = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0px;
  gap: 48px;
  padding: 48px 100px;

  ${mediaMax.phone`
    flex-direction: column;
    align-items: center;
    padding: 48px 24px;
  `};

  ${mediaMax.desktop`
    padding: 48px 16px;
  `};
`;

const ValuePoint = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 16px;
  flex: 1;
  color: #fff;
  text-align: center;

  ${mediaMax.phone`
    width: 100%;
  `};

  ${mediaMax.desktop`
    flex: 1 1 20%;
  `};
`;

const H5 = styled.h5`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin: 0px;
`;

const Icon = styled.img`
  width: 72px;
  height: 72px;
`;

const ContentAndVid = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 100px 48px 100px;
  gap: 80px;

  iframe {
    border-style: none;
  }

  ${mediaMax.desktop`
    flex-direction: column;
    padding: 0;
    gap: 24px;
  `};

  ${mediaMax.tablet`
    flex-direction: column;
    padding: 0;
    gap: 24px;
  `};

  ${mediaMax.phone`
    flex-direction: column;
    padding: 0;
    gap: 24px;
  `};
    
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: start;
  padding: 0;
  gap: 24px;

  h1, h3 {
    max-width: 55ch;
  }

  ${mediaMax.tablet`
    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
  `};
`;

const DesktopVideoPreview = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-height: fit-content;

  .container {
    position: relative;
  }

  .imgDiv {
    width: 543px;
    max-width: 100%;
    background-image: ${props => `url('${props.domain}/upfront-pricing/maxresdefault.jpg')`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &:hover {
      background-image: ${props => `url('${props.domain}/upfront-pricing/preview.gif')`};
    }
  }

  img {
    max-width: 100%;
  }

  .iconBox {
    display:flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    i {
      font-style: normal;
      font-weight: 400;
      color: #FFFFFF;
      font-size: 64px;
      line-height: 72px;
      cursor: pointer;

      &:hover {
        color: #3963FE;
      }
    }
  }

  ${mediaMax.desktop`
    display: none;
  `};
`;

const MobileVideoView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  max-height: 100%;
  background: transparent;
  
  iframe {
    height: 100%;
    width: 100%;
    border-style: none;
    aspect-ratio: 16 / 9;
  }

  ${mediaMin.desktop`
    display: none;
  `};
`;

export default {
  BannerWrapper,
  BannerHeader,
  H1,
  H3,
  ToggleButton,
  MobileToggleButton,
  TabletToggleButton,
  Chevron,
  BannerContent,
  ValuePoints,
  ValuePoint, 
  H5,
  Icon,
  ContentAndVid,
  TextBox,
  DesktopVideoPreview,
  MobileVideoView
}