import React, { Component } from "react";
import { Note } from "../styles";
import _ from "lodash";
import moment from "moment";
import styledComponents from "./styles";
import ButtonComponent from "../ButtonComponent";
import { EstimateSubmitted, RenderTasks } from "./SubView";
import {
  fetchCreatingNotes,
  postStaleBidQuote,
  postStaleScheduleAppointment
} from "../../../../lib/getUserData";

const { Root, CheckListContainer } = styledComponents;
const mockTasks = [
  { label: "Contacted Customer", id: 1 },
  { type: "dateTime", label: "Schedule Site Visit", id: 2 },
  { type: "currency", label: "Provided Quote", id: 3 }
];
/**~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
Component OfferingEstimate renders flow for "Offering an estimate"
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
class OfferingEstimate extends Component {
  state = {
    disableSubmit: true,
    tasks: mockTasks,
    integerError: false,
    dateTimeError: false
  };

  updateStaleBidQuote = async () => {
    const { activity } = this.props;
    let actionId = null;

    activity.Tasks.Actions.map(action => {
      if (action.Type === "Currency") {
        actionId = action.Id;
      }
    });

    let actions = [];

    if (_.has(activity, 'Tasks.Actions') && !_.isEmpty(activity.Tasks.Actions)) {
      actions = _.map(activity.Tasks.Actions, (action) => {
        if (action.Label === 'Currency') {
          action.CustomDecimal1 = this.state.costValue
        }
        return action
      })
    }

    const body = {
      activityId: activity.Id,
      taskId: activity.Tasks.Id,
      actions,
    };

    await postStaleBidQuote(body);
  };

  updateAppointment = async dateTime => {
    const { activity } = this.props;
    const utcDate = dateTime.originDate.utc;

    const body = {
      StartBy: utcDate,
      StartNoLaterThan: utcDate
    };

    await postStaleScheduleAppointment(activity.Id, body);
  };

  /**
  function getDateTime =>
    - inputs : date, moment, dateTime
    -sets state for dateTime selected from "schedule site walk"  task
 */
  getDateTime = (date, moment, dateTime) => {
    if (dateTime.date === "" && dateTime.time === "") dateTime = undefined;
    this.setState({
      dateTime,
      dateTimeError: false
    });
    this.updateAppointment(dateTime);
  };

  /**
  function handleCostChange =>
    -sets state for costValue, also sets state for showIntegerError if costValue is not valid
 */
  handleCostChange = e => {
    const { value } = e.target,
      { dateTime, tasks } = this.state,
      test = /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{0,2}$|^\d+$/.test(value);

    this.setState({
      costValue: value,
      integerError: !test
    });
  };

  handleError = () => {
    const {
      tasks,
      costValue,
      dateTime,
      dateTimeError,
      integerError
    } = this.state;
    let dateError = false,
      costError = false;
    if (tasks[1].active && !dateTime) {
      dateError = true;
    } else if ((tasks[2].active && !costValue) || integerError) {
      costError = true;
    }

    if (dateError || costError) {
      this.setState({ dateTimeError: dateError, integerError: costError });
      return true;
    }
    return false;
  };

  /** 
  function handleSubmit =>
    - sets state that tasks are completed and required fields are filled successfully (submitted)
  */
  handleSubmit = () => {
    const { handleStatusChange, activity } = this.props,
      { tasks, dateTime, costValue } = this.state;

    const error = this.handleError();
    if (error) return;

    let taskRes = "";
    tasks.forEach((data, index) => {
      if (data.active) {
        index === tasks.length - 1
          ? (taskRes += data.label)
          : (taskRes += `${data.label}, `);
      }
    });
    const body = {
      Note: `Offering an estimate. The following tasks have been completed: ${taskRes}.`
    };
    if (costValue) this.updateStaleBidQuote();
    if (dateTime) this.updateAppointment();
    fetchCreatingNotes(body, activity.ParentActivityId).then(data => {
      this.setState({ submitted: true });
      handleStatusChange(activity.Id, {
        subTitle: "Updated!",
        status: null
      });
    });
  };

  /** 
  function handleSubmit =>
    - handleView called to set the previous of user ("updates")
  */
  goBack = () => {
    const { handleView, activity } = this.props;
    handleView(activity, "updates");
  };

  /** 
  function handleTaskStatus =>
    - sets state for every active task (which is checked off)
  */
  handleTaskStatus = (id, val) => {
    const { tasks, disableSubmit } = this.state;
    let prevTaskState = tasks.slice();
    let costValue = this.state.costValue;
    const activeTasks = prevTaskState.filter(task => task.active);
    let isAnyActive = false;
    const updatedTask = prevTaskState.map(task => {
      if (id === task.id) {
        if (id === 3 && !val) {
          costValue = "";
          return {
            ...task,
            active: val
          };
        }
        return {
          ...task,
          active: val
        };
      } else {
        return {
          ...task,
          active: task.active || false
        };
      }
    });
    updatedTask.map(task => {
      if (task.active) isAnyActive = true;
    });
    this.setState({
      tasks: updatedTask,
      costValue,
      disableSubmit: !isAnyActive
    });
  };

  /** Renders "Offering an estimate" flow */
  render() {
    const {
      submitted,
      disableSubmit,
      integerError,
      dateTimeError
    } = this.state;

    if (submitted) return <EstimateSubmitted {...this.props} />;
    return (
      <Root>
        Check off the tasks you’ve completed.
        <CheckListContainer>
          <RenderTasks
            {...this.state}
            getDateTime={this.getDateTime}
            handleCostChange={this.handleCostChange}
            handleTaskStatus={this.handleTaskStatus}
            showIntegerError={integerError}
            showDateTimeError={dateTimeError}
          />
        </CheckListContainer>
        <Note mt={8}>
          The customer will not be notified with any of this information.
        </Note>
        <ButtonComponent
          goBack={this.goBack}
          disableSubmit={disableSubmit}
          handleSubmit={this.handleSubmit}
        />
      </Root>
    );
  }
}

export default OfferingEstimate;
