import React, { Fragment } from 'react';
import StyledComponents from './styles';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';

const {
  Link,
  ChevronRight,
  H2
} = commonStyles;

const {
  MobileModalBackground,
  MobileModalHeaderContainer,
  MobileModalHeaderTitle,
  MobileModalCloseButton,
  MobileModalCloseIcon,
  MobileModalHeaderTitleContainer,
  ProgramTypeTextWrapper,
  MobileModalHeaderTitleText,
  MobileModalContentContainer,
  MobileModalSection,
  RebatesSection,
  ProgramTypeText,
  Rebate,
  RebateName,
  RebatePrice,
  LearnMore,
  ScrollDisabler,
} = StyledComponents;

export default function MobileModalRebates({ close, title, subtitle, content }) {
  let prevType = null;
  return (
    <Fragment>
      <MobileModalBackground>
        {/* Header */}
        <MobileModalHeaderContainer>
          <MobileModalHeaderTitle>
            <MobileModalCloseButton onClick={close}>
              <MobileModalCloseIcon className="far fa-chevron-left" />
            </MobileModalCloseButton>
            <MobileModalHeaderTitleContainer>
              <MobileModalHeaderTitleText>{title}</MobileModalHeaderTitleText>
            </MobileModalHeaderTitleContainer>
          </MobileModalHeaderTitle>
        </MobileModalHeaderContainer>
        {/* 'Rebates': */}
        <MobileModalContentContainer>
          <MobileModalSection>
            <H2>{subtitle}</H2>
            <RebatesSection>
              {/* Start mapping here */}
              {
                content.map(item => {
                  return (
                    <Fragment key={item.Id}>
                      {
                        item.Type !== prevType ? 
                        (
                          <ProgramTypeTextWrapper spacing={item.Type !== prevType ? '8px' : '0px'}>
                            {item.Type !== prevType && (prevType = item.Type) && <ProgramTypeText>{item.Type}s</ProgramTypeText>}
                          </ProgramTypeTextWrapper>
                        )
                        : null
                      }
                      <Rebate>
                        <RebateName>{item.Name}</RebateName>
                        <RebatePrice>{item.Text}</RebatePrice>
                        <LearnMore>
                          <Link href={item.WebAddress} target="_blank">Learn More</Link>
                          <Link href={item.WebAddress} target="_blank"><ChevronRight className="far fa-chevron-right"></ChevronRight></Link>
                        </LearnMore>
                      </Rebate>
                    </Fragment>
                  )
                })
              }
            </RebatesSection>
          </MobileModalSection>
        </MobileModalContentContainer>
      </MobileModalBackground>
      <ScrollDisabler />
    </Fragment>
  )
}