/* 
    This file can be used for global styles specific to this feature.
*/
import styled from "styled-components";

const Wrapper = styled.div`
  /* display: flex; */
  padding: 16px;
`;

export default {
  Wrapper
};
