import React, { Fragment } from 'react'
import StyledComponents from './styles'
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles'

const {
  H2
} = commonStyles;

const {
  ModalBackground,
  ModalContainer,
  ModalTitle,
  ModalTitle1,
  ModalContent,
  LargeIconBox,
  IconBox,
  ScrollDisabler
} = StyledComponents

export function Modal({ isMounted, toggleMountModal, title, children }) {
  return (
    <Fragment>
      <ModalBackground onClick={toggleMountModal} animate={isMounted ? 'in' : 'out'}>
        <ModalContainer role="dialog" aria-labelledby="dialogTitle" onClick={e => e.stopPropagation()} animate={isMounted ? 'in' : 'out'}>
          <ModalTitle>
            <LargeIconBox>
              <i className="far fa-spinner fa-pulse" alt="spinner icon" name="spinner icon" aria-label="spinner icon" />
            </LargeIconBox>
            <H2 id="dialogTitle">{title}</H2>
          </ModalTitle>
          <ModalContent>
            {children}
          </ModalContent>
        </ModalContainer>
      </ModalBackground>
      <ScrollDisabler />
    </Fragment>
  )
}

export function Modal1({ isMounted, toggleMountModal, title, children }) {
  return (
    <Fragment>
      <ModalBackground onClick={toggleMountModal} animate={isMounted ? 'in' : 'out'}>
        <ModalContainer role="dialog" aria-labelledby="dialogTitle" onClick={e => e.stopPropagation()} animate={isMounted ? 'in' : 'out'}>
          <ModalTitle1>
            <H2 id="dialogTitle">{title}</H2>
            <IconBox role="button" onClick={toggleMountModal}>
              <i className="far fa-times" alt="x icon" name="x icon" aria-label="x icon" />
            </IconBox>
          </ModalTitle1>
          <ModalContent>
            {children}
          </ModalContent>
        </ModalContainer>
      </ModalBackground>
      <ScrollDisabler />
    </Fragment>
  )
}