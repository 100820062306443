import React, { Fragment, useState, useEffect } from 'react';
import commonStyles from '../../components/DesignPatternV2.0/commonStyles';
import styledComponents from "./styles";

import Loader from "../../components/Loader";

const {
  QmeritLogo
} = commonStyles;

const {
  GlobalStyles,
  MainWrapper,
  Header,
  ContentContainer,
  Content,
  ContentText
} = styledComponents;

export default function UnderConstruction() {
  const [isLoading, setIsLoading] = useState(true);
  const [blobUrl, setblobUrl] = useState('');
  const [evBlob, setEvBlob] = useState('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsLoading(false);
      setblobUrl(window.env.blobUrl);
      setEvBlob(window.env.evBlob);
    }
  }, []);

  if (!isLoading) {
    return (
      <Fragment>
        <GlobalStyles />
        <MainWrapper>
          <Header>
            <QmeritLogo src={`${blobUrl}/qmerit/wordmark/png/Qmerit-Wordmark-White.png`} alt="Qmerit logo" />
          </Header>
          <ContentContainer>
            <Content>
              <ContentText>
                <h1>This page is under construction.</h1>
                <p>We’re currently working on our site and will be back shortly. Thank you for your patience!</p>
              </ContentText>
            </Content>
            <Content>
              <img src={`${evBlob}/images/under-construction-image.jpeg`} alt="Home charging image" />
            </Content>
          </ContentContainer>
        </MainWrapper>
      </Fragment>
    )
  }

  return (<Loader color={"#000000"} isLoading={isLoading} />)
}