import styled, { css } from "styled-components";

const DropDownBtn = styled.button`
  font-size: 16px;
  color: #6e727c;
  border: 0;
  background: transparent;
  cursor: pointer;
`;
const DropDownWraper = styled.div`
  overflow: auto;
  z-index: 1;
  position: absolute;
  background: #fff;
  top: 0;
  left: 0px;
  height: 0;
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-radius: 5px;
  box-shadow: 0 0 6px 0 #bdbdbd;
  /* Exceptional media-Query for dateTime Modal component 
     to render both components vertically rather than horizontally. */
  @media (min-width: 767.98px) and (max-width: 1030.98px) {
    left: initial;
    right: 0;
  }
  @media (max-width: 680px) {
    left: initial;
    right: 0;
  }
  ${props =>
    props.active &&
    css`padding:5px
   height:400px;
   
   @media (max-width: 767.98px) {
    height:212px;
  }  
   `};
`;
const DropDownItem = styled.div`
  color: #6e727c;
  cursor: pointer;
  min-width: 240px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
  border: 0;
  padding: 12px;
  background: ${props => (props.active ? `#f4f5f6` : "transparent")};
  transition: 0.2s;
  text-align: left;
  &:hover {
    background: #f4f5f6;
  }
  &:not(:last-child) {
    border-bottom: 1px solid #e6e6e6;
  }

  h3 {
    margin: 0;
    font-size: 16px;
  }
  p {
    margin: 0;
    padding-top: 4px;
    font-size: 12px;
    color: #6e727c;
  }
`;

const Wraper = styled.div`
  display: flex;
  flex-wrap: wrap;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
`;
const Header = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  background-color: #3862c5;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  text-align: center;
  color: #ffffff;
  padding: 12px 8px;
  position: relative;
  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    font-size: 20px;
    transition: 0.5 all;
    padding: 0px 10px;
    cursor: pointer;
    &.fa-arrow-left {
      font-size: 16px;
    }
    &.fa-angle-left,
    &.fa-arrow-left {
      left: 6px;
    }
    &.fa-angle-right {
      right: 6px;
    }
    &:hover {
      opacity: 0.7;
    }
  }
`;

const Body = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const calenderColumns = css`
  flex: 0 0 50%;
  max-width: 50%;
  ${props =>
    props.single &&
    `flex: 0 0 100%; max-width: 100%;`} @media (max-width: 767.98px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;
const CalendarWraper = styled.div`
  ${calenderColumns} background-color: #f4f5f6;
`;
const TimeSection = styled.div`
  min-height: 244px;
  ${calenderColumns} padding: 16px;
  box-shadow: inset 1px 0 0 0 #dedfe2;

  @media (max-width: 767.98px) {
    box-shadow: inset 0 1px 0 0 #dedfe2;
  }
  @media (max-width: 576.98px) {
    padding: 16px 8px;
  }
`;

const TimeWraper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const Time = styled.div`
  margin-right: 9px;
  span {
    width: 16px;
    display: inline-block;
    text-align: center;
  }
  input {
    width: 48px;
    height: 44px;
    padding: 0;
    margin: 0;
    text-align: center;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    color: #3e4148;
    border: solid 1px #dedfe2;
    background-color: #fafafa;
    -webkit-font-smoothing: antialiased;
    font-family: "Lato";

    &:active,
    &:focus {
      box-shadow: 0 0 4px 0 #4dd0e1;
      border: solid 1px #00bcd4;
    }
  }
`;
const Meridiem = styled.div`
  display: flex;

  button {
    font-family: Lato;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.25;
    width: 48px;
    height: 44px;
    padding: 0;
    border-radius: 4px;
    color: #b9bbc1;
    border: solid 1px #dedfe2;
    background: linear-gradient(to bottom, #f4f5f6, #edeeef);
    &:nth-child(1) {
      border-radius: 4px 0px 0px 4px;
    }
    &:nth-last-child(1) {
      border-radius: 0px 4px 4px 0px;
    }
    &.active {
      color: #3e4148;
      background: #fff;
      font-weight: bold;
      &:nth-child(1) {
        border-right: 0px;
      }
      &:nth-last-child(1) {
        border-left: 0px;
      }
    }
  }
`;
const TimeZoneWraper = styled.div`
  position: relative;
  color: #6e727c;
  height: fit-content;
  select {
    height: 44px;
    min-width: 35px;
    border: none;
    font-family: Lato;
    font-size: 16px;
    font-weight: normal;
    background: transparent;
    -webkit-font-smoothing: antialiased;
    color: #6e727c;
    -webkit-appearance: none;
    margin-left: 10px;
  }
`;
const HoursRange = styled.div`
  margin-top: 16px;
  padding-bottom: 5px;
  @media (max-width: 576.98px) {
    margin-top: 8px;
  }
  p {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 14px;
    font-family: Lato;
    font-weight: normal;
    color: #3e4148;
    -webkit-font-smoothing: antialiased;
  }
`;
const Footer = styled.div`
  padding: 0 12px;
  height: 40px;
  background: #fafafa;
  box-shadow: inset 0 1px 0 0 #dedfe2;
  align-items: center;
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 576.98px) {
    padding: 0 8px;
  }
  p,
  button {
    font-size: 12px;
    line-height: 1.33;
  }
  p {
    color: #6e727c;
    letter-spacing: 0.3px;
    font-weight: 400;
  }
  i {
    font-style: normal;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 0.5px;
    color: #b9bbc1;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    padding: 0;
    background: transparent;
    border: 0px;
    &:hover {
      color: #6e727c;
    }
  }
`;
export default {
  DropDownBtn,
  Meridiem,
  Wraper,
  Header,
  Body,
  CalendarWraper,
  TimeSection,
  TimeWraper,
  Time,
  TimeZoneWraper,
  DropDownBtn,
  DropDownWraper,
  DropDownItem,
  HoursRange,
  Footer
};
