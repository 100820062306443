import React, { Component, Fragment } from "react";
import StyledComponents from "../styles";
import ButtonComponent from "../ButtonComponent";
import RenderRadios, { radioData } from "./view/RenderRadios";
// import CompletedActivity from "./view/CompletedActivity";
import ProgressActivity from "./view/ProgressActivity";
import { fetchCreatingNotes } from "../../../../lib/getUserData";

const { TitleDesc, OptionWrapper } = StyledComponents;

/**~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
Component SPSelected renders flow for i was selected
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
export default class SPSelected extends Component {
  constructor(props) {
    super(props);
    this.state = { disableSubmit: true };
  }

  /** 
  function handleRadioChange =>
    - sets state for Which status is selected "completed" or "progress"
  */
  handleRadioChange = event => {
    const { value } = event.target;
    let status;
    if (radioData[0].label === value) status = "completed";
    else if (radioData[1].label === value) status = "progress";
    this.setState({ disableSubmit: false, status });
  };

  /** 
  function handleSubmit =>
    - sets state that status was selected successfully (submitted)
  */
  handleSubmit = () => {
    const { status } = this.state,
      { handleStatusChange, activity } = this.props;
    let noteStatus = "";
    let header = { status: "success" };
    if (status === "completed") {
      header = { ...header, subTitle: "Activity completed!", finished: true };
      noteStatus = "Activity completed!";
    } else if (status === "progress") {
      header = { ...header, subTitle: "Activity in progress!" };
      noteStatus = "Activity still in progress!";
    }
    const body = { Note: `I was selected! ${noteStatus}` };
    fetchCreatingNotes(body, activity.ParentActivityId).then(data => {
      this.setState({ submitted: true });
      handleStatusChange(activity.Id, header);
    });
  };

  /** 
  function handleSubmit =>
    - handleView called to set the previous of user ("updates")
  */
  goBack = () => {
    const { activity, handleView } = this.props;
    handleView(activity, "updates");
  };

  /** Renders "i was selected" flow */
  render() {
    const { disableSubmit, submitted, status } = this.state,
      { activity } = this.props;
    // if activity completed and status is "Activity completed"
    // if (submitted && status === "completed")
    //   return <CompletedActivity {...this.props} />;

    if (submitted && (status === "progress" || status === "completed"))
      return <ProgressActivity {...this.props} />;

    return (
      <Fragment>
        <TitleDesc>Is this activity complete?</TitleDesc>
        <OptionWrapper mt={10}>
          <RenderRadios onChange={this.handleRadioChange} activity={activity} />
        </OptionWrapper>
        <ButtonComponent
          goBack={this.goBack}
          disableSubmit={disableSubmit}
          handleSubmit={this.handleSubmit}
        />
      </Fragment>
    );
  }
}
