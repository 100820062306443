import React from "react";

export default (() => {
  return (
    <svg className = "quick-logo" width="116px" opacity=".5" height="24px" viewBox="0 0 116 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
      <title>Quick Actions Logo</title>
      <g id="App" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="a-Bid---Withdraw" transform="translate(-110.000000, -612.000000)" fill="#FFFFFF">
          <g id="Quick-Logo" transform="translate(110.000000, 612.000000)">
            <g id="uick-Action" transform="translate(25.000000, 3.000000)" fontFamily="Montserrat-Regular, Montserrat" fontSize="16" fontWeight="normal">
              <text>
                <tspan x="0" y="15">uick Action</tspan>
              </text>
            </g>
            <g id="QMerit-Icon/Badge">
              <g id="QMerit-Icon">
                <path d="M19.667374,3.68384959 L14.7888184,0.931762398 C12.7410787,-0.223441962 10.2139032,-0.223441962 8.1660962,0.931762398 L3.28754064,3.68384959 C1.25257865,4.8317951 2.01754386e-05,6.94647629 2.01754386e-05,9.23419292 L2.01754386e-05,14.7918605 C2.01754386e-05,17.0795771 1.25257865,19.1942583 3.28754064,20.3422038 L8.1660962,23.094291 C10.2139032,24.2494954 12.7410787,24.2494954 14.7888184,23.094291 L16.6522892,22.0431302 C16.7461722,21.9901602 16.7397161,21.8569504 16.6414617,21.8118932 C15.4328857,21.2574736 14.4777132,20.3517515 13.8446079,19.4737569 C13.7947746,19.4045689 13.7062716,19.3738986 13.6226781,19.3967869 C12.8804909,19.6004272 12.0940523,19.7014627 11.2803096,19.6820403 C6.96693538,19.5792392 3.5133038,16.0694845 3.58963421,11.8745853 C3.66596462,7.67726649 7.20910789,4.3023564 11.5532833,4.34205123 C15.8215991,4.3810267 19.3854558,7.8971248 19.3664909,12.0477515 C19.3545202,14.6774845 17.9815143,16.9944327 15.8973243,18.3677297 C15.8313506,18.4112174 15.8176985,18.4995008 15.8679354,18.5596643 C16.4946518,19.310073 17.4118272,19.9831847 18.4562424,20.1698877 C18.9406547,20.256667 19.4186108,20.2292011 19.8871518,20.089321 C20.1466079,20.0118278 20.3849471,19.8797297 20.5930904,19.7102256 C22.0751781,18.5032937 22.9549617,16.7085907 22.9549617,14.7918605 L22.9549617,9.23419292 C22.9549617,6.94647629 21.7024032,4.8317951 19.667374,3.68384959" id="Fill-3"></path>
                <path d="M11.5860281,6.45244251 C8.34127953,6.39397929 5.69883509,8.96348338 5.75902515,12.1186005 C5.81571813,15.0921809 8.31094912,17.5184698 11.3688731,17.5735978 C14.6136216,17.6321264 17.2560661,15.0626223 17.195876,11.9074398 C17.139183,8.9339248 14.643952,6.50757057 11.5860281,6.45244251" id="Fill-1"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
})();
