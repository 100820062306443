export default [
    {
        Id: 'Accept',
        Name: 'Scorpius Install',
        usingMock: true,
        fetchedData: {
            Type: 'Accept',
            Name: 'Quick Actions',
            User: {
                Name: "Melissa Guanzon",
                CompanyName: "Team Scorpius",
            },
            StatusId: 1,
            OperationGuids: {
                Accept: "mockAccept",
                Decline: "mockDecline",
                Submit: "mockSubmit",
                CustomerContacted: "mockCustomerContacted",
                CustomerDeclined: "mockCustomerDeclined",
                WeSelected: "mockWeSelected",
                Nearby: "mockNearby",
            },
            Project: {
               Id:9637,
               Attachments: [
                   {
                       FileType: 'application/pdf',
                       Name: 'Project Scope',
                       Url: 'https://qmtevqa.blob.core.windows.net/surveys/cab5317a-1485-4627-8e77-a4c69619c2b9',
                   },
               ],
               Name:"Survey for Honda Placentia",
               Description:"Project Template for EV Charger Installation (with Documents Gathering)",
               StartDate:"2019-01-31T06:00:00",
               Status:"New",
               DueDate:"2019-03-02T06:00:00",
               Owner:{
                  Id:103,
                  Name:"Luis Almazon",
                  Email:"ashbyqa6868@gmail.com",
                  Phone:"(555)555-5557",
                  ImageUrl:null,
                  Title: "Grand Master"
               },
               Collaborators:[
                  {
                      Id:103,
                      Name:"Luis Almazon",
                      Email:"ashbyqa6868@gmail.com",
                      Phone:"(555)555-5557",
                      ImageUrl:null,
                      Title: "Grand Master"
                  }
               ],
               Customer:null,
               CreatedOn:"2019-01-24T00:23:32.0243017",
               Tasks:{
                  Id:3303,
                  Next:null,
                  Tasks:[
                     {
                        SelectedOn:null,
                        Tasks:{
                           Id:"A11",
                           Name:"Schedule Remote Site Survey Appointment",
                           Status:"Completed",
                           CanComplete:true,
                           Events:[

                           ],
                           Actions:[
                               {
                                  CustomInt1:1,
                                  CustomString1:"https://survey-qa.qmerit.com/survey/bm27Gv?guid=21595-A12-AC12",
                                  CustomString2:"",
                                  Files:[

                                  ],
                                  MultiDocument:null,
                                  ProjectDocument:null,
                                  Id:"AC12",
                                  Label:"Perform Site Survey",
                                  Type:"Survey",
                                  IsCompleted:false
                               }
                           ],
                           Tasks:[

                           ],
                           Next:{
                              Id:"A12",
                              Name:"Perform Site Survey",
                              Status:"New",
                              CanComplete:true,
                              Events:[

                              ],
                              Actions:[
                                 {
                                    CustomInt1:1,
                                    CustomString1:"https://survey-qa.qmerit.com/survey/bm27Gv?guid=21595-A12-AC12",
                                    CustomString2:"",
                                    Files:[

                                    ],
                                    MultiDocument:null,
                                    ProjectDocument:null,
                                    Id:"AC12",
                                    Label:"Perform Site Survey",
                                    Type:"Survey",
                                    IsCompleted:false
                                 }
                              ],
                              Tasks:[

                              ],
                              Next:{
                                 Id:"A13",
                                 Name:"Review Site Survey Results",
                                 Status:"New",
                                 CanComplete:false,
                                 Events:[

                                 ],
                                 Actions:[
                                    {
                                       ChangesRequired:null,
                                       TaskId:"A12",
                                       ActionId:"AC12",
                                       Action:{
                                          CustomInt1:1,
                                          CustomString1:"https://survey-qa.qmerit.com/survey/bm27Gv?guid=21595-A12-AC12",
                                          CustomString2:"",
                                          Files:[

                                          ],
                                          MultiDocument:null,
                                          ProjectDocument:null,
                                          Id:"AC12",
                                          Label:"Perform Site Survey",
                                          Type:"Survey",
                                          IsCompleted:false
                                       },
                                       Files:[

                                       ],
                                       Id:"AC13",
                                       Label:"Review Document",
                                       Type:"Review",
                                       IsCompleted:false
                                    }
                                 ],
                                 Tasks:[

                                 ],
                                 Next:null
                              }
                           }
                        },
                        Assignment:{
                           Id:4472,
                           Name:"Ashby7070",
                           Type:"CorporateOffice",
                           ImageUrl:"/images-company/da15f172-704c-46b2-b75e-48e3bd16ccc5",
                           Contact:{
                              Id:4472,
                              FirstName:"test70",
                              LastName:"70",
                              Email:"ashbyqa7070@gmail.com",
                              PhoneNumber:"949-232-1111",
                              PreferredMethod:null,
                              Title:null,
                              Address:null,
                              ImageUrl:"/images-company/da15f172-704c-46b2-b75e-48e3bd16ccc5"
                           }
                        },
                        Id:21595,
                        Name:"Site Evaluation",
                        Type:"Activity",
                        Status:"New",
                        DueDate:"2019-03-02T06:00:00",
                        ModifiedOn:"2019-02-05T14:02:30.47",
                        Template:{
                           Id:1940,
                           Name:"Site Evaluation"
                        }
                     }
                  ],
                  CompletedOn:null,
                  ActivatedOn:"2019-01-24T00:23:33.9100638"
               }
            },
            Program: {
                Id: 22,
                Name: 'ParentCo',
                LogoUrl: '',
                Phone: '332-423-9999',
                Email: 'adasf@asf.com',
                ExternalData: {
                    Name: 'Still need a charger?',
                    Description: "Buy chargers at a discounted rate right now",
                    Link: 'http://www.google.com',
                    ImageUrl: 'https://senstoresvcint.blob.core.windows.net/collateral/49f73cb3-fe6a-4263-a99b-9bc194f5973f?sv=2017-07-29&sr=b&sig=MuT5YgmZ0%2FD%2BiU2qBf6yz%2FW2H8%2FuhWj8gAl2N5dwGdg%3D&se=2019-01-25T02%3A03%3A53Z&sp=r',
                },
            },
            Customer:   {
                Id: 150,
                Name: "Anita Goodinstall",
                Type: "Usual Suspension Company",
                Phone: '(231)444-2233',
                Email: 'test@test.com',
                PreferredContact: 'email',
            },
            Owner: {
                Id:103,
                Name:"Philip C. Lee",
                Email:"philip.lee@qmerit.com",
                Phone:"(555)555-5557",
                ImageUrl:null,
                Title:null
            },
            Description: "Your company has been requested to bid for the cost of installing a ChargePoint EV charging station for a customer. Be sure to check the attached Customer Project Scope for the specific details of the installation needs!",
            Location: {
              Id: 1345,
              Name: "JB Site 23",
              Street: "3435 Wilshire Boulevard",
              Address: "3435 Wilshire Boulevard",
              City: "Los Angeles",
              Zip: "90006",
              Country: "United States",
              State: "California",
            },
            AcceptedBy: {
                AcceptedOn: "2019-03-01T06:43:00",
                Name: "Melissa Guanzon",
            },
            Priority: "High",
        },
        token: 'mockAccept',
        isLoading: false,
        fetchError: false,
    },
    // record #2 Type === Declined
    {
        Id: 'Decline',
        usingMock: true,
        Name: 'Scorpius Install',
        fetchedData: {
            Type: 'Decline',
            Name: 'Quick Actions',
            User: {
                Name: "Melissa Guanzon",
                CompanyName: "Team Scorpius",
            },
            StatusId: 1,
            OperationGuids: {
                Accept: "mockAccept",
                Decline: "mockDecline",
                Submit: "mockSubmit",
                CustomerContacted: "mockCustomerContacted",
                CustomerDeclined: "mockCustomerDeclined",
                WeSelected: "mockWeSelected",
                Nearby: "mockNearby",
            },
            Project: {
               Id:9637,
               Name:"Walkthrough",
               Description:"Project Template for EV Charger Installation (with Documents Gathering)",
               StartDate:"2019-01-31T06:00:00",
               Status:"New",
               DueDate:"2019-03-02T06:00:00",
               Owner:{
                  Id:103,
                  Name:"Tony Lim",
                  Email:"ashbyqa6868@gmail.com",
                  Phone:"23",
                  ImageUrl:null,
                  Title:null
               },
               Collaborators:[
                  {
                     Id:103,
                     Name:"Tony Lim",
                     Email:"ashbyqa6868@gmail.com",
                     Phone:"23",
                     ImageUrl:"/images/56a8500a-c505-446f-a997-bf7debf951b7",
                     Title:null
                  }
               ],
               Customer:null,
               CreatedOn:"2019-01-24T00:23:32.0243017",
               Tasks:{
                  Id:3303,
                  Next:null,
                  Tasks:[
                     {
                        SelectedOn:null,
                        Tasks:{
                           Id:"A11",
                           Name:"Schedule Remote Site Survey Appointment",
                           Status:"Completed",
                           CanComplete:true,
                           Events:[

                           ],
                           Actions:[
                              {
                                 CustomDate1:"2019-02-15T14:02:00+00:00",
                                 CustomDate2:"2019-02-15T14:02:00+00:00",
                                 Id:"AC11",
                                 Label:"Schedule Site Survey Appointment",
                                 Type:"Schedule",
                                 IsCompleted:true
                              }
                           ],
                           Tasks:[

                           ],
                           Next:{
                              Id:"A12",
                              Name:"Perform Site Survey",
                              Status:"New",
                              CanComplete:true,
                              Events:[

                              ],
                              Actions:[
                                 {
                                    CustomInt1:1,
                                    CustomString1:"https://survey-qa.qmerit.com/survey/bm27Gv?guid=21595-A12-AC12",
                                    CustomString2:"",
                                    Files:[

                                    ],
                                    MultiDocument:null,
                                    ProjectDocument:null,
                                    Id:"AC12",
                                    Label:"Perform Site Survey",
                                    Type:"Survey",
                                    IsCompleted:false
                                 }
                              ],
                              Tasks:[

                              ],
                              Next:{
                                 Id:"A13",
                                 Name:"Review Site Survey Results",
                                 Status:"New",
                                 CanComplete:false,
                                 Events:[

                                 ],
                                 Actions:[
                                    {
                                       ChangesRequired:null,
                                       TaskId:"A12",
                                       ActionId:"AC12",
                                       Action:{
                                          CustomInt1:1,
                                          CustomString1:"https://survey-qa.qmerit.com/survey/bm27Gv?guid=21595-A12-AC12",
                                          CustomString2:"",
                                          Files:[

                                          ],
                                          MultiDocument:null,
                                          ProjectDocument:null,
                                          Id:"AC12",
                                          Label:"Perform Site Survey",
                                          Type:"Survey",
                                          IsCompleted:false
                                       },
                                       Files:[

                                       ],
                                       Id:"AC13",
                                       Label:"Review Document",
                                       Type:"Review",
                                       IsCompleted:false
                                    }
                                 ],
                                 Tasks:[

                                 ],
                                 Next:null
                              }
                           }
                        },
                        Assignment:{
                           Id:4472,
                           Name:"Ashby7070",
                           Type:"CorporateOffice",
                           ImageUrl:"/images-company/da15f172-704c-46b2-b75e-48e3bd16ccc5",
                           Contact:{
                              Id:4472,
                              FirstName:"test70",
                              LastName:"70",
                              Email:"ashbyqa7070@gmail.com",
                              PhoneNumber:"949-232-1111",
                              PreferredMethod:null,
                              Title:null,
                              Address:null,
                              ImageUrl:"/images-company/da15f172-704c-46b2-b75e-48e3bd16ccc5"
                           }
                        },
                        Id:21595,
                        Name:"Site Evaluation",
                        Type:"Activity",
                        Status:"New",
                        DueDate:"2019-03-02T06:00:00",
                        ModifiedOn:"2019-02-05T14:02:30.47",
                        Template:{
                           Id:1940,
                           Name:"Site Evaluation"
                        }
                     }
                  ],
                  CompletedOn:null,
                  ActivatedOn:"2019-01-24T00:23:33.9100638"
               }
            },
            Program: {
                Id: 22,
                Name: 'ParentCo',
                LogoUrl: '',
                Phone: '332-423-9999',
                Email: 'adasf@asf.com',
                ExternalData: {
                    Name: 'Still need a charger?',
                    Description: "Buy chargers at a discounted rate right now",
                    Link: 'http://www.google.com',
                    ImageUrl: 'https://senstoresvcint.blob.core.windows.net/collateral/49f73cb3-fe6a-4263-a99b-9bc194f5973f?sv=2017-07-29&sr=b&sig=MuT5YgmZ0%2FD%2BiU2qBf6yz%2FW2H8%2FuhWj8gAl2N5dwGdg%3D&se=2019-01-25T02%3A03%3A53Z&sp=r',
                },
            },
            Customer:   {
                Id: 150,
                Name: "Anita Goodinstall",
                Type: "Usual Suspension Company",
                Phone: '(231)444-2233',
                Email: 'test@test.com',
                PreferredContact: 'email',
            },
            Owner: {
                Id:103,
                Name:"Philip C. Lee",
                Email:"philip.lee@qmerit.com",
                Phone:"(555)555-5557",
                ImageUrl:null,
                Title:null
            },
            Description: "Your company has been requested to bid for the cost of installing a ChargePoint EV charging station for a customer. Be sure to check the attached Customer Project Scope for the specific details of the installation needs!",
            Location: {
              Id: 1345,
              Name: "JB Site 23",
              Street: "3243 Associated Road",
              Address: "3243 Associated Road",
              City: "Fullerton",
              Zip: "92835",
              Country: "United States",
              State: "California",
            },
            AcceptedBy: {
                AcceptedOn: "2019-03-01T06:43:00",
                Name: "Melissa Guanzon",
            },
            Priority: "High",
        },
        token: 'mockDecline',
        isLoading: false,
        fetchError: false,
    },

    // record #3 Type === view
    {
        Id: 'View',
        usingMock: true,
        fetchedData: {
            Type: 'View',
            Name: 'Quick Actions',
            User: {
                Name: "Melissa Guanzon",
                CompanyName: "Team Scorpius",
            },
            StatusId: 1,
            OperationGuids: {
                Accept: "mockAccept",
                Decline: "mockDecline",
                Submit: "mockSubmit",
                CustomerContacted: "mockCustomerContacted",
                CustomerDeclined: "mockCustomerDeclined",
                WeSelected: "mockWeSelected",
                Nearby: "mockNearby",
            },
            Project: {
               Id:9637,
               Name:"Survey Honday Westminster",
               Attachments: [
                   {
                       FileType: 'application/pdf',
                       Name: 'Project Scope',
                       Url: 'https://qmtevqa.blob.core.windows.net/surveys/cab5317a-1485-4627-8e77-a4c69619c2b9',
                   },
               ],
               Description:"Project Template for EV Charger Installation (with Documents Gathering)",
               StartDate:"2019-01-31T06:00:00",
               Status:"New",
               DueDate:"2019-03-02T06:00:00",
               Owner:{
                  Id:103,
                  Name:"Tony Lim",
                  Email:"ashbyqa6868@gmail.com",
                  Phone:"23",
                  ImageUrl:null,
                  Title:null
               },
               Collaborators:[
                  {
                     Id:103,
                     Name:"Tony Lim",
                     Email:"ashbyqa6868@gmail.com",
                     Phone:"23",
                     ImageUrl:"/images/56a8500a-c505-446f-a997-bf7debf951b7",
                     Title:null
                  }
               ],
               Customer:null,
               CreatedOn:"2019-01-24T00:23:32.0243017",
               Tasks:{
                  Id:3303,
                  Next:null,
                  Tasks:[
                     {
                        SelectedOn:null,
                        Tasks:{
                           Id:"A11",
                           Name:"Schedule Remote Site Survey Appointment",
                           Status:"Completed",
                           CanComplete:true,
                           Events:[

                           ],
                           Actions:[
                              {
                                 CustomDate1:"2019-02-15T14:02:00+00:00",
                                 CustomDate2:"2019-02-15T14:02:00+00:00",
                                 Id:"AC11",
                                 Label:"Schedule Site Survey Appointment",
                                 Type:"Schedule",
                                 IsCompleted:true
                              }
                           ],
                           Tasks:[

                           ],
                           Next:{
                              Id:"A12",
                              Name:"Perform Site Survey",
                              Status:"New",
                              CanComplete:true,
                              Events:[

                              ],
                              Actions:[
                                 {
                                    CustomInt1:1,
                                    CustomString1:"https://survey-qa.qmerit.com/survey/bm27Gv?guid=21595-A12-AC12",
                                    CustomString2:"",
                                    Files:[

                                    ],
                                    MultiDocument:null,
                                    ProjectDocument:null,
                                    Id:"AC12",
                                    Label:"Perform Site Survey",
                                    Type:"Survey",
                                    IsCompleted:false
                                 }
                              ],
                              Tasks:[

                              ],
                              Next:{
                                 Id:"A13",
                                 Name:"Review Site Survey Results",
                                 Status:"New",
                                 CanComplete:false,
                                 Events:[

                                 ],
                                 Actions:[
                                    {
                                       ChangesRequired:null,
                                       TaskId:"A12",
                                       ActionId:"AC12",
                                       Action:{
                                          CustomInt1:1,
                                          CustomString1:"https://survey-qa.qmerit.com/survey/bm27Gv?guid=21595-A12-AC12",
                                          CustomString2:"",
                                          Files:[

                                          ],
                                          MultiDocument:null,
                                          ProjectDocument:null,
                                          Id:"AC12",
                                          Label:"Perform Site Survey",
                                          Type:"Survey",
                                          IsCompleted:false
                                       },
                                       Files:[

                                       ],
                                       Id:"AC13",
                                       Label:"Review Document",
                                       Type:"Review",
                                       IsCompleted:false
                                    }
                                 ],
                                 Tasks:[

                                 ],
                                 Next:null
                              }
                           }
                        },
                        Assignment:{
                           Id:4472,
                           Name:"Ashby7070",
                           Type:"CorporateOffice",
                           ImageUrl:"/images-company/da15f172-704c-46b2-b75e-48e3bd16ccc5",
                           Contact:{
                              Id:4472,
                              FirstName:"test70",
                              LastName:"70",
                              Email:"ashbyqa7070@gmail.com",
                              PhoneNumber:"949-232-1111",
                              PreferredMethod:null,
                              Title:null,
                              Address:null,
                              ImageUrl:"/images-company/da15f172-704c-46b2-b75e-48e3bd16ccc5"
                           }
                        },
                        Id:21595,
                        Name:"Site Evaluation",
                        Type:"Activity",
                        Status:"New",
                        DueDate:"2019-03-02T09:00:00",
                        ModifiedOn:"2019-02-05T14:02:30.47",
                        Template:{
                           Id:1940,
                           Name:"Site Evaluation"
                        }
                     }
                  ],
                  CompletedOn:null,
                  ActivatedOn:"2019-01-24T00:23:33.9100638"
               }
            },
            Program: {
                Id: 22,
                Name: 'ParentCo',
                LogoUrl: '',
                Phone: '332-423-9999',
                Email: 'adasf@asf.com',
                ExternalData: {
                    Name: 'Still need a charger?',
                    Description: "Buy chargers at a discounted rate right now",
                    Link: 'http://www.google.com',
                    ImageUrl: 'https://senstoresvcint.blob.core.windows.net/collateral/49f73cb3-fe6a-4263-a99b-9bc194f5973f?sv=2017-07-29&sr=b&sig=MuT5YgmZ0%2FD%2BiU2qBf6yz%2FW2H8%2FuhWj8gAl2N5dwGdg%3D&se=2019-01-25T02%3A03%3A53Z&sp=r',
                },
            },
            Customer:   {
                Id: 150,
                Name: "Anita Goodinstall",
                Type: "Installation",
                Phone: '(231)444-2233',
                Email: 'test@test.com',
                PreferredContact: 'email',
            },
            Owner: {
                Id:103,
                Name:"Philip C. Lee",
                Email:"philip.lee@qmerit.com",
                Phone:"(555)555-5557",
                ImageUrl:null,
                Title:null
            },
            Description: "Your company has been requested to bid for the cost of installing a ChargePoint EV charging station for a customer. Be sure to check the attached Customer Project Scope for the specific details of the installation needs!",
            Location: {
              Id: 1345,
              Name: "JB Site 23",
              Street: "3243 Associated Road",
              Address: "3243 Associated Road",
              City: "Fullerton",
              Zip: "92835",
              Country: "United States",
              State: "California",
            },
            AcceptedBy: {
                AcceptedOn: "2019-03-01T06:43:00",
                Name: "Melissa Guanzon",
            },
            Priority: "High",
        },
        token: 'mockView',
        isLoading: false,
        fetchError: false,
    },

    //mockCustomerDeclined
    {
        Id: 'CustomerDeclined',
        usingMock: true,
        fetchedData: {
            Type: 'CustomerDeclined',
            Name: 'Quick Actions',
            User: {
                Name: "Melissa Guanzon",
                CompanyName: "Team Scorpius",
            },
            StatusId: 1,
            OperationGuids: {
                Accept: "mockAccept",
                Decline: "mockDecline",
                Submit: "mockSubmit",
                CustomerContacted: "mockCustomerContacted",
                CustomerDeclined: "mockCustomerDeclined",
                WeSelected: "mockWeSelected",
                Nearby: "mockNearby",
            },
            Project: {
               Id:9637,
               Name:"Survey Green Belt",
               Attachments: [
                   {
                       FileType: 'application/pdf',
                       Name: 'Project Scope',
                       Url: 'https://qmtevqa.blob.core.windows.net/surveys/cab5317a-1485-4627-8e77-a4c69619c2b9',
                   },
               ],
               Description:"Project Template for EV Charger Installation (with Documents Gathering)",
               StartDate:"2019-01-31T06:00:00",
               Status:"New",
               DueDate:"2019-03-02T06:00:00",
               Owner:{
                  Id:103,
                  Name:"Tony Lim",
                  Email:"ashbyqa6868@gmail.com",
                  Phone:"23",
                  ImageUrl:null,
                  Title:null
               },
               Collaborators:[
                  {
                     Id:103,
                     Name:"Tony Lim",
                     Email:"ashbyqa6868@gmail.com",
                     Phone:"23",
                     ImageUrl:"/images/56a8500a-c505-446f-a997-bf7debf951b7",
                     Title:null
                  }
               ],
               Customer:null,
               CreatedOn:"2019-01-24T00:23:32.0243017",
               Tasks:{
                  Id:3303,
                  Next:null,
                  Tasks:[
                     {
                        SelectedOn:null,
                        Tasks:{
                           Id:"A11",
                           Name:"Schedule Remote Site Survey Appointment",
                           Status:"Completed",
                           CanComplete:true,
                           Events:[

                           ],
                           Actions:[
                              {
                                 CustomDate1:"2019-02-15T14:02:00+00:00",
                                 CustomDate2:"2019-02-15T14:02:00+00:00",
                                 Id:"AC11",
                                 Label:"Schedule Site Survey Appointment",
                                 Type:"Schedule",
                                 IsCompleted:true
                              }
                           ],
                           Tasks:[

                           ],
                           Next:{
                              Id:"A12",
                              Name:"Perform Site Survey",
                              Status:"New",
                              CanComplete:true,
                              Events:[

                              ],
                              Actions:[
                                 {
                                    CustomInt1:1,
                                    CustomString1:"https://survey-qa.qmerit.com/survey/bm27Gv?guid=21595-A12-AC12",
                                    CustomString2:"",
                                    Files:[

                                    ],
                                    MultiDocument:null,
                                    ProjectDocument:null,
                                    Id:"AC12",
                                    Label:"Perform Site Survey",
                                    Type:"Survey",
                                    IsCompleted:false
                                 }
                              ],
                              Tasks:[

                              ],
                              Next:{
                                 Id:"A13",
                                 Name:"Review Site Survey Results",
                                 Status:"New",
                                 CanComplete:false,
                                 Events:[

                                 ],
                                 Actions:[
                                    {
                                       ChangesRequired:null,
                                       TaskId:"A12",
                                       ActionId:"AC12",
                                       Action:{
                                          CustomInt1:1,
                                          CustomString1:"https://survey-qa.qmerit.com/survey/bm27Gv?guid=21595-A12-AC12",
                                          CustomString2:"",
                                          Files:[

                                          ],
                                          MultiDocument:null,
                                          ProjectDocument:null,
                                          Id:"AC12",
                                          Label:"Perform Site Survey",
                                          Type:"Survey",
                                          IsCompleted:false
                                       },
                                       Files:[

                                       ],
                                       Id:"AC13",
                                       Label:"Review Document",
                                       Type:"Review",
                                       IsCompleted:false
                                    }
                                 ],
                                 Tasks:[

                                 ],
                                 Next:null
                              }
                           }
                        },
                        Assignment:{
                           Id:4472,
                           Name:"Ashby7070",
                           Type:"CorporateOffice",
                           ImageUrl:"/images-company/da15f172-704c-46b2-b75e-48e3bd16ccc5",
                           Contact:{
                              Id:4472,
                              FirstName:"test70",
                              LastName:"70",
                              Email:"ashbyqa7070@gmail.com",
                              PhoneNumber:"949-232-1111",
                              PreferredMethod:null,
                              Title:null,
                              Address:null,
                              ImageUrl:"/images-company/da15f172-704c-46b2-b75e-48e3bd16ccc5"
                           }
                        },
                        Id:21595,
                        Name:"Site Evaluation",
                        Type:"Activity",
                        Status:"New",
                        DueDate:"2019-03-02T06:00:00",
                        ModifiedOn:"2019-02-05T14:02:30.47",
                        Template:{
                           Id:1940,
                           Name:"Site Evaluation"
                        }
                     }
                  ],
                  CompletedOn:null,
                  ActivatedOn:"2019-01-24T00:23:33.9100638"
               }
            },
            Program: {
                Id: 22,
                Name: 'ParentCo',
                LogoUrl: '',
                Phone: '332-423-9999',
                Email: 'adasf@asf.com',
                ExternalData: {
                    Name: 'Still need a charger?',
                    Description: "Buy chargers at a discounted rate right now",
                    Link: 'http://www.google.com',
                    ImageUrl: 'https://senstoresvcint.blob.core.windows.net/collateral/49f73cb3-fe6a-4263-a99b-9bc194f5973f?sv=2017-07-29&sr=b&sig=MuT5YgmZ0%2FD%2BiU2qBf6yz%2FW2H8%2FuhWj8gAl2N5dwGdg%3D&se=2019-01-25T02%3A03%3A53Z&sp=r',
                },
            },
            Customer:   {
                Id: 150,
                Name: "Anita Goodinstall",
                Type: "Installation",
                Phone: '(231)444-2233',
                Email: 'test@test.com',
                PreferredContact: 'email',
            },
            Owner: {
                Id:103,
                Name:"Philip C. Lee",
                Email:"philip.lee@qmerit.com",
                Phone:"(555)555-5557",
                ImageUrl:null,
                Title:null
            },
            Description: "Your company has been requested to bid for the cost of installing a ChargePoint EV charging station for a customer. Be sure to check the attached Customer Project Scope for the specific details of the installation needs!",
            Location: {
              Id: 1345,
              Name: "JB Site 23",
              Street: "3243 Associated Road",
              Address: "3243 Associated Road",
              City: "Fullerton",
              Zip: "92835",
              Country: "United States",
              State: "California",
            },
            AcceptedBy: {
                AcceptedOn: "2019-03-01T06:43:00",
                Name: "Melissa Guanzon",
            },
            Priority: "High",
        },
        token: 'mockCustomerDeclined',
        isLoading: false,
        fetchError: false,
    },
    //mockCustomerContacted
    {
        Id: 'CustomerContacted',
        usingMock: true,
        fetchedData: {
            Type: 'CustomerContacted',
            Name: 'Quick Actions',
            User: {
                Name: "Melissa Guanzon",
                CompanyName: "Team Scorpius",
            },
            StatusId: 1,
            OperationGuids: {
                Accept: "mockAccept",
                Decline: "mockDecline",
                Submit: "mockSubmit",
                CustomerContacted: "mockCustomerContacted",
                CustomerDeclined: "mockCustomerDeclined",
                WeSelected: "mockWeSelected",
                Nearby: "mockNearby",
            },
            Project: {
               Id:9637,
               Name:"Walkthrough Hard",
               Attachments: [
                   {
                       FileType: 'application/pdf',
                       Name: 'Project Scope',
                       Url: 'https://qmtevqa.blob.core.windows.net/surveys/cab5317a-1485-4627-8e77-a4c69619c2b9',
                   },
               ],
               Description:"Project Template for EV Charger Installation (with Documents Gathering)",
               StartDate:"2019-01-31T06:00:00",
               Status:"New",
               DueDate:"2019-03-02T06:00:00",
               Owner:{
                  Id:103,
                  Name:"Tony Lim",
                  Email:"ashbyqa6868@gmail.com",
                  Phone:"23",
                  ImageUrl:null,
                  Title:null
               },
               Collaborators:[
                  {
                     Id:103,
                     Name:"Tony Lim",
                     Email:"ashbyqa6868@gmail.com",
                     Phone:"23",
                     ImageUrl:"/images/56a8500a-c505-446f-a997-bf7debf951b7",
                     Title:null
                  }
               ],
               Customer:null,
               CreatedOn:"2019-01-24T00:23:32.0243017",
               Tasks:{
                  Id:3303,
                  Next:null,
                  Tasks:[
                     {
                        SelectedOn:null,
                        Tasks:{
                           Id:"A11",
                           Name:"Schedule Remote Site Survey Appointment",
                           Status:"Completed",
                           CanComplete:true,
                           Events:[

                           ],
                           Actions:[
                              {
                                 CustomDate1:"2019-02-15T14:02:00+00:00",
                                 CustomDate2:"2019-02-15T14:02:00+00:00",
                                 Id:"AC11",
                                 Label:"Schedule Site Survey Appointment",
                                 Type:"Schedule",
                                 IsCompleted:true
                              }
                           ],
                           Tasks:[

                           ],
                           Next:{
                              Id:"A12",
                              Name:"Perform Site Survey",
                              Status:"New",
                              CanComplete:true,
                              Events:[

                              ],
                              Actions:[
                                 {
                                    CustomInt1:1,
                                    CustomString1:"https://survey-qa.qmerit.com/survey/bm27Gv?guid=21595-A12-AC12",
                                    CustomString2:"",
                                    Files:[

                                    ],
                                    MultiDocument:null,
                                    ProjectDocument:null,
                                    Id:"AC12",
                                    Label:"Perform Site Survey",
                                    Type:"Survey",
                                    IsCompleted:false
                                 }
                              ],
                              Tasks:[

                              ],
                              Next:{
                                 Id:"A13",
                                 Name:"Review Site Survey Results",
                                 Status:"New",
                                 CanComplete:false,
                                 Events:[

                                 ],
                                 Actions:[
                                    {
                                       ChangesRequired:null,
                                       TaskId:"A12",
                                       ActionId:"AC12",
                                       Action:{
                                          CustomInt1:1,
                                          CustomString1:"https://survey-qa.qmerit.com/survey/bm27Gv?guid=21595-A12-AC12",
                                          CustomString2:"",
                                          Files:[

                                          ],
                                          MultiDocument:null,
                                          ProjectDocument:null,
                                          Id:"AC12",
                                          Label:"Perform Site Survey",
                                          Type:"Survey",
                                          IsCompleted:false
                                       },
                                       Files:[

                                       ],
                                       Id:"AC13",
                                       Label:"Review Document",
                                       Type:"Review",
                                       IsCompleted:false
                                    }
                                 ],
                                 Tasks:[

                                 ],
                                 Next:null
                              }
                           }
                        },
                        Assignment:{
                           Id:4472,
                           Name:"Ashby7070",
                           Type:"CorporateOffice",
                           ImageUrl:"/images-company/da15f172-704c-46b2-b75e-48e3bd16ccc5",
                           Contact:{
                              Id:4472,
                              FirstName:"test70",
                              LastName:"70",
                              Email:"ashbyqa7070@gmail.com",
                              PhoneNumber:"949-232-1111",
                              PreferredMethod:null,
                              Title:null,
                              Address:null,
                              ImageUrl:"/images-company/da15f172-704c-46b2-b75e-48e3bd16ccc5"
                           }
                        },
                        Id:21595,
                        Name:"Site Evaluation",
                        Type:"Activity",
                        Status:"New",
                        DueDate:"2019-03-02T06:00:00",
                        ModifiedOn:"2019-02-05T14:02:30.47",
                        Template:{
                           Id:1940,
                           Name:"Site Evaluation"
                        }
                     }
                  ],
                  CompletedOn:null,
                  ActivatedOn:"2019-01-24T00:23:33.9100638"
               }
            },
            Program: {
                Id: 22,
                Name: 'ParentCo',
                LogoUrl: '',
                Phone: '332-423-9999',
                Email: 'adasf@asf.com',
                ExternalData: {
                    Name: 'Still need a charger?',
                    Description: "Buy chargers at a discounted rate right now",
                    Link: 'http://www.google.com',
                    ImageUrl: 'https://senstoresvcint.blob.core.windows.net/collateral/49f73cb3-fe6a-4263-a99b-9bc194f5973f?sv=2017-07-29&sr=b&sig=MuT5YgmZ0%2FD%2BiU2qBf6yz%2FW2H8%2FuhWj8gAl2N5dwGdg%3D&se=2019-01-25T02%3A03%3A53Z&sp=r',
                },
            },
            Customer:   {
                Id: 150,
                Name: "Anita Goodinstall",
                Type: "Installation",
                Phone: '(231)444-2233',
                Email: 'test@test.com',
                PreferredContact: 'email',
            },
            Owner: {
                Id:103,
                Name:"Philip C. Lee",
                Email:"philip.lee@qmerit.com",
                Phone:"(555)555-5557",
                ImageUrl:null,
                Title:null
            },
            Description: "Your company has been requested to bid for the cost of installing a ChargePoint EV charging station for a customer. Be sure to check the attached Customer Project Scope for the specific details of the installation needs!",
            Location: {
              Id: 1345,
              Name: "JB Site 23",
              Street: "3243 Associated Road",
              Address: "3243 Associated Road",
              City: "Fullerton",
              Zip: "92835",
              Country: "United States",
              State: "California",
            },
            AcceptedBy: {
                AcceptedOn: "2019-03-01T06:00:00",
                Name: "Melissa Guanzon",
            },
            Priority: "High",
        },
        token: 'mockCustomerContacted',
        isLoading: false,
        fetchError: false,
    },

    //mockWeSelected
    {
        Id: 'WeSelected',
        usingMock: true,
        fetchedData: {
            Type: 'WeSelected',
            Name: 'Quick Actions',
            User: {
                Name: "Melissa Guanzon",
                CompanyName: "Team Scorpius",
            },
            StatusId: 1,
            OperationGuids: {
                Accept: "mockAccept",
                Decline: "mockDecline",
                Submit: "mockSubmit",
                CustomerContacted: "mockCustomerContacted",
                CustomerDeclined: "mockCustomerDeclined",
                WeSelected: "mockWeSelected",
                Nearby: "mockNearby",
            },
            Project: {
               Id:9637,
               Name:"Survey This",
               Attachments: [
                   {
                       FileType: 'application/pdf',
                       Name: 'Project Scope',
                       Url: 'https://qmtevqa.blob.core.windows.net/surveys/cab5317a-1485-4627-8e77-a4c69619c2b9',
                   },
               ],
               Description:"Project Template for EV Charger Installation (with Documents Gathering)",
               StartDate:"2019-01-31T06:00:00",
               Status:"New",
               DueDate:"2019-03-02T06:00:00",
               Owner:{
                  Id:103,
                  Name:"Tony Lim",
                  Email:"ashbyqa6868@gmail.com",
                  Phone:"23",
                  ImageUrl:null,
                  Title:null
               },
               Collaborators:[
                  {
                     Id:103,
                     Name:"Tony Lim",
                     Email:"ashbyqa6868@gmail.com",
                     Phone:"23",
                     ImageUrl:"/images/56a8500a-c505-446f-a997-bf7debf951b7",
                     Title:null
                  }
               ],
               Customer:null,
               CreatedOn:"2019-01-24T00:23:32.0243017",
               Tasks:{
                  Id:3303,
                  Next:null,
                  Tasks:[
                     {
                        SelectedOn:null,
                        Tasks:{
                           Id:"A11",
                           Name:"Schedule Remote Site Survey Appointment",
                           Status:"Completed",
                           CanComplete:true,
                           Events:[

                           ],
                           Actions:[
                              {
                                 CustomDate1:"2019-02-15T14:02:00+00:00",
                                 CustomDate2:"2019-02-15T14:02:00+00:00",
                                 Id:"AC11",
                                 Label:"Schedule Site Survey Appointment",
                                 Type:"Schedule",
                                 IsCompleted:true
                              }
                           ],
                           Tasks:[

                           ],
                           Next:{
                              Id:"A12",
                              Name:"Perform Site Survey",
                              Status:"New",
                              CanComplete:true,
                              Events:[

                              ],
                              Actions:[
                                 {
                                    CustomInt1:1,
                                    CustomString1:"https://survey-qa.qmerit.com/survey/bm27Gv?guid=21595-A12-AC12",
                                    CustomString2:"",
                                    Files:[

                                    ],
                                    MultiDocument:null,
                                    ProjectDocument:null,
                                    Id:"AC12",
                                    Label:"Perform Site Survey",
                                    Type:"Survey",
                                    IsCompleted:false
                                 }
                              ],
                              Tasks:[

                              ],
                              Next:{
                                 Id:"A13",
                                 Name:"Review Site Survey Results",
                                 Status:"New",
                                 CanComplete:false,
                                 Events:[

                                 ],
                                 Actions:[
                                    {
                                       ChangesRequired:null,
                                       TaskId:"A12",
                                       ActionId:"AC12",
                                       Action:{
                                          CustomInt1:1,
                                          CustomString1:"https://survey-qa.qmerit.com/survey/bm27Gv?guid=21595-A12-AC12",
                                          CustomString2:"",
                                          Files:[

                                          ],
                                          MultiDocument:null,
                                          ProjectDocument:null,
                                          Id:"AC12",
                                          Label:"Perform Site Survey",
                                          Type:"Survey",
                                          IsCompleted:false
                                       },
                                       Files:[

                                       ],
                                       Id:"AC13",
                                       Label:"Review Document",
                                       Type:"Review",
                                       IsCompleted:false
                                    }
                                 ],
                                 Tasks:[

                                 ],
                                 Next:null
                              }
                           }
                        },
                        Assignment:{
                           Id:4472,
                           Name:"Ashby7070",
                           Type:"CorporateOffice",
                           ImageUrl:"/images-company/da15f172-704c-46b2-b75e-48e3bd16ccc5",
                           Contact:{
                              Id:4472,
                              FirstName:"test70",
                              LastName:"70",
                              Email:"ashbyqa7070@gmail.com",
                              PhoneNumber:"949-232-1111",
                              PreferredMethod:null,
                              Title:null,
                              Address:null,
                              ImageUrl:"/images-company/da15f172-704c-46b2-b75e-48e3bd16ccc5"
                           }
                        },
                        Id:21595,
                        Name:"Site Evaluation",
                        Type:"Activity",
                        Status:"New",
                        DueDate:"2019-03-02T06:00:00",
                        ModifiedOn:"2019-02-05T14:02:30.47",
                        Template:{
                           Id:1940,
                           Name:"Site Evaluation"
                        }
                     }
                  ],
                  CompletedOn:null,
                  ActivatedOn:"2019-01-24T00:23:33.9100638"
               }
            },
            Program: {
                Id: 22,
                Name: 'ParentCo',
                LogoUrl: '',
                Phone: '332-423-9999',
                Email: 'adasf@asf.com',
                ExternalData: {
                    Name: 'Still need a charger?',
                    Description: "Buy chargers at a discounted rate right now",
                    Link: 'http://www.google.com',
                    ImageUrl: 'https://senstoresvcint.blob.core.windows.net/collateral/49f73cb3-fe6a-4263-a99b-9bc194f5973f?sv=2017-07-29&sr=b&sig=MuT5YgmZ0%2FD%2BiU2qBf6yz%2FW2H8%2FuhWj8gAl2N5dwGdg%3D&se=2019-01-25T02%3A03%3A53Z&sp=r',
                },
            },
            Customer:   {
                Id: 150,
                Name: "Anita Goodinstall",
                Type: "Installation",
                Phone: '(231)444-2233',
                Email: 'test@test.com',
                PreferredContact: 'email',
            },
            Owner: {
                Id:103,
                Name:"Philip C. Lee",
                Email:"philip.lee@qmerit.com",
                Phone:"(555)555-5557",
                ImageUrl:null,
                Title:null
            },
            Description: "Your company has been requested to bid for the cost of installing a ChargePoint EV charging station for a customer. Be sure to check the attached Customer Project Scope for the specific details of the installation needs!",
            Location: {
              Id: 1345,
              Name: "JB Site 23",
              Street: "3243 Associated Road",
              Address: "3243 Associated Road",
              City: "Fullerton",
              Zip: "92835",
              Country: "United States",
              State: "California",
            },
            AcceptedBy: {
                AcceptedOn: "2019-03-01T06:00:00",
                Name: "Melissa Guanzon",
            },
            Priority: "High",
        },
        token: 'mockWeSelected',
        isLoading: false,
        fetchError: false,
    },



];
