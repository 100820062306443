import moment from "moment";
export function getTime(date) {
  if (date) {
    let time = moment(date).fromNow();
    let ago = time.indexOf("ago");
    if (ago !== -1) {
      if (time.indexOf("an") !== -1) {
        time = time.slice(3, ago - 1);
      } else if (time.indexOf("few") !== -1) {
        time = `1 minute`;
      } else if (time.indexOf("a ") !== -1) {
        time = "1" + time.slice(2, ago - 1);
      } else {
        time = time.slice(0, ago - 1);
      }
    } else {
      if (time.indexOf("in ") !== -1) {
        time = time.slice(3);
      }
      if (time.indexOf("a ") !== -1) {
        time = "1" + time.slice(1);
      }
    }
    return time;
  }
}
export function getCaption(uploadArray, data) {
  let array = uploadArray.slice();
  let obj = array[0];
  let caption = getTime(data || obj.Expiration);
  obj.caption = caption;
  if (!obj.Expiration) obj.Expiration = data;
  array[0] = obj;
  return array;
}
