import React from "react";
import styledComponents from "./style";

export default class Switch extends React.PureComponent {
  state = { isChecked: false };
  toggle = () => {
    const { isChecked } = this.state;
    this.setState({ isChecked: !isChecked });
    this.props.toggleSwitch();
  };
  render() {
    const { Label, Slider, Text } = styledComponents;
    const { text } = this.props;
    const { isChecked } = this.state;
    return (
      <div style={{ display: "flex" }}>
        <Label onClick={this.toggle}>
          <Slider checked={this.props.isChecked || isChecked} />
        </Label>
        {text && (
          <Text checked={this.props.isChecked || isChecked}>{text}</Text>
        )}
      </div>
    );
  }
}
