export function getHref(location) {
  const ios = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const android = /(android)/i.test(navigator.userAgent);
  if (ios) {
    return `https://maps.apple.com/?q=${location.Address} ${location.City}, ${location.State} ${location.Zip}`;
  } else if (android) {
    return `geo:?q=${location.Address}+${location.City},+${location.State}+${location.Zip}`;
  } else {
    return `https://www.google.com/maps/search/?api=1&query=${location.Address}+${location.City},+${location.State}+${
      location.Zip
    }`;
  }
}
