import React, { PureComponent } from "react";
import styled from "styled-components";
import { theme, mediaMax, mediaMin } from "../shared/style";
class QuickCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { warningHeader, maxWidth, padding } = this.props;
    return (
      <Article maxWidth={maxWidth}>
        {this.props.header && (
          <Header
            className={this.props.headerType}
            warningHeader={warningHeader}
          >
            <h2>{this.props.headerText}</h2>
          </Header>
        )}
        <Section padding={padding}>{this.props.children}</Section>
      </Article>
    );
  }
}

export default QuickCard;

const Header = styled.header`
  background-image: linear-gradient(
    to bottom,
    ${theme.Primary.dark},
    ${theme.Primary.extraDark}
  );

  ${props =>
    props.warningHeader &&
    `
   background-image: linear-gradient(to bottom, ${theme.Warning[500]}, ${
      theme.Warning[700]
    });
 `} &.wrapper {
    display: flex;
    justify-content: space-between;
    min-height: 40px;
    ${mediaMax.phone`padding: 0 12px;`} .title {
      float: right;
    }
    h2 {
      text-align: right;
      margin: 0;
    }
    h3 {
      opacity: 0.75;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: 0.3px;
      text-align: right;
      margin: 0;
    }
  }
`;

export const Article = styled.article`
  max-width: ${props => props.maxWidth || '600px'};
  width: 100%;
  margin: 0 auto 24px;
  /* min-height: 400px; */
  border-radius: 8px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
  color: ${theme.IndiqoGrey[800]};
  ${mediaMax.phone`

    margin-bottom: 0px;
    border-radius: 0;
`} ${Header} {
    height: 52px;
    ${mediaMin.phone`
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;`} h2 {
      margin-top: 0;
      padding: 16px;
      color: #ffffff;
      img {
        margin-right: 8px;
      }
    }
  }
`;

export const Section = styled.section`
  padding: ${props => props.padding || '24px'};
  ${mediaMax.phone`padding: 16px;`};
`;
