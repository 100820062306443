import React, { Fragment } from "react";
import styledComponents from "./style";
import commonStyledComponents from "../commanStyle";
import Collapse from "../Components/Collapse";
import Message from "../Components/Message";
import Error from "../Components/Error";
import FooterWrapper from "../Components/FooterWrapper";
import moment from "moment";
import * as cf from "../methods";

const {
  Text,
  InputWraper,
  Checkbox,
  CheckBoxWraper,
  SignatureWraper,
  Signature,
  Date
} = commonStyledComponents;

export default class RequirementComplete extends React.PureComponent {
  state = {
    // commentValue: "",
    checked: false,
    // addComment: false,
    submit: false,
    showEdit: false,
    editMode: false,
    fieldError: false,
    dateTime: { date: null, time: null }
  };

  checkboxClick = () => {
    const { checked } = this.state;
    this.setState({ checked: !checked, dateTime: this.getDateTime() });
  };

  getDateTime = () => {
    const date = moment()
      .format("YYYY-MMM-DD")
      .toUpperCase();
    const time = moment()
      .format("hh:mmA")
      .toLowerCase();
    return { date, time };
  };

  submit = () => {
    const checked = this.state.checked;
    if (checked) {
      const options = {
        body: {
          RequirementId: this.props.requirementData.Id,
          CustomBool1: true
        }
      };
      this.setState(
        {
          submit: true,
          showEdit: true,
          editMode: false,
          fieldError: false,
          dateTime: this.getDateTime()
        },
        () => {
          this.collapse.toggle();
          this.props.updateRequirements(options);
        }
      );
    } else {
      this.setState({
        fieldError: true
      });
    }
  };

  edit = () => {
    this.setState({ showEdit: false, editMode: true });
  };
  cancel = () => {
    this.setState({
      editMode: false,
      showEdit: this.state.submit ? true : false,
      fieldError: false,
      checked: this.state.submit ? true : false
    });
    this.collapse.toggle();
  };
  // fieldChange = e => {
  //   this.setState({ fieldValue: e.target.value });
  // };
  // commentChange = e => {
  //   this.setState({ commentValue: e.target.value });
  // };
  // addComment = () => {
  //   this.setState({ addComment: true });
  // };

  showWhileEdit = (user, dateTime) => {
    return (
      <Fragment>
        <Text spaceBottom={16}>
          <b>This requirement has been met</b>
        </Text>
        <InputWraper spaceBottom={17}>
          <SignatureWraper>
            <Signature>{user}</Signature>
            <Date noWrap desktop={true}>
              {dateTime.date} <span>•</span> {dateTime.time}
            </Date>
          </SignatureWraper>
          <Date desktop={false}>
            {dateTime.date} <span>•</span> {dateTime.time}
          </Date>
        </InputWraper>
        {/* {this.state.commentValue && (
      <Fragment>
        <SmallText>Comment</SmallText>
        <Text spaceBottom={12}>{this.state.commentValue}</Text>
      </Fragment>
    )} */}
      </Fragment>
    );
  };

  showWhileEditting = (user, dateTime, checked) => {
    return (
      <Fragment>
        <Text spaceBottom={22}>
          <b>Confirm that you have completed this requirement</b>
        </Text>
        <InputWraper spaceBottom={12}>
          <CheckBoxWraper spaceLeft={14}>
            <Checkbox type="checkbox" checked={checked} />
            <label onClick={this.checkboxClick}>
              This requirement has been met
            </label>
          </CheckBoxWraper>
          <InputWraper spaceBottom={17}>
            <SignatureWraper>
              {checked && (
                <Fragment>
                  <Signature>{user}</Signature>
                  <Date noWrap desktop={true}>
                    {dateTime.date} <span>•</span> {dateTime.time}
                  </Date>
                </Fragment>
              )}
            </SignatureWraper>
            {checked && (
              <Date desktop={false}>
                {dateTime.date} <span>•</span> {dateTime.time}
              </Date>
            )}
          </InputWraper>
          {this.state.fieldError && <Error text="This field is required." />}
        </InputWraper>
        {/* {this.state.addComment ? (
          <InputWraper>
            <Label>Comment</Label>
            <Textarea
              onChange={this.commentChange}
              value={this.state.commentValue}
            />
          </InputWraper>
        ) : (
          <Comment onClick={() => this.addComment()}>Add a comment…</Comment>
        )} */}
      </Fragment>
    );
  };

  render() {
    const { Content } = commonStyledComponents;
    const { Root } = styledComponents,
      { checked, submit, showEdit, dateTime } = this.state,
      { requirementData, status, fetchedData } = this.props,
      iconData = cf.iconData(
        submit,
        requirementData.VerificationRequired,
        status
      ),
      headerDetail = {
        ...iconData,
        title: requirementData.Name
      },
      user = fetchedData.User.Name;

    return (
      <Collapse headerDetail={headerDetail} ref={ref => (this.collapse = ref)}>
        <div>
          <Message bigMsg={true} requirementData={{ ...requirementData }} />
          <Content>
            <Root>
              {showEdit
                ? this.showWhileEdit(user, dateTime)
                : this.showWhileEditting(user, dateTime, checked)}
            </Root>
          </Content>
        </div>
        <FooterWrapper
          verified={submit}
          confirmation={checked}
          cancel={this.cancel}
          submitChanges={this.submit}
          edit={this.edit}
          {...this.state}
        />
      </Collapse>
    );
  }
}
