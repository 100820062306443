import styled, { css } from "styled-components";
import { theme, mediaMax } from "../../../../shared/style";
const Row = styled("div")`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
  margin-bottom: ${({ mb }) => -16 || 0}px;
`;
const Col = styled("div")`
  padding: 0 12px;
  flex: 0 0 50%;
  max-width: 50%;
  ${mediaMax.phone`
    flex: 0 0 100%;
    max-width: 100%;
  `};
`;
const ContentCard = styled.div`
  margin-bottom: ${({ mb }) => (mb ? mb : 24)}px;
  ${({ mt }) =>
    mt &&
    css`
      margin-top: ${mt}px;
    `};
`;
const Label = styled.h4`
  margin: 0;
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: 0.25px;
  color: ${theme.IndiqoGrey[600]};
  ${({ italic }) =>
    italic &&
    css`
      font-style: italic;
    `};
  ${({ faclity }) =>
    faclity &&
    css`
      margin-top: 3px;
    `};
`;
const content = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  -webkit-font-smoothing: antialiased;
`;
const Content = styled.h3`
  margin: 0;
  ${content};
  color: ${theme.IndiqoGrey[800]};
  letter-spacing: 0.5px;
`;
const anchorHover = css`
  cursor: pointer;
  transition: 0.3 opacity;
  &:hover {
    opacity: 0.7;
  }
`;
const link = css`
  margin-top: 3px;
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
`;
const Link = styled("a")`
  ${link};
  ${anchorHover};
  color: #00bcd4;
`;
const LinkText = styled("p")`
  ${link};
  color: ${theme.IndiqoGrey[800]};
`;
export default { Row, Col, ContentCard, Label, Content, Link, LinkText };
