import { render } from "enzyme";
import React, {PureComponent} from "react"
import styledComponents from "./style";
import _ from "lodash";
import Wrapper from "../../components/Wrapper";
import Loader from "../../components/Loader";
import {TierUpdateSuccess} from './components/TierUpdateSuccess'
import { TierUpdateFail } from "../tierManage/components/TierUpdateFail";
import BidStatusError from '../../components/Errors';
import formatter from "../../lib/formatter";
import queryString from "query-string";
import { InstallerStatus } from "../../components/InstallerStatus";


class TieredSubmitPermit extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {  
            isLoading: true,
            guId: '',
            companyId: '',
            fetchedProjectInfo: {},
            tierEditionSuccess: false,
            tierFechedSucces: false,
            permitCost: '',
            translations: {},
        };
    }

    componentDidMount() {       
        
        let bidGuid = this.getProjectGuid(this.props.location.search)
        let companyId = this.getCompanyId(this.props.location.search)
        if (!_.isEmpty(bidGuid) || !_.isEmpty(companyId)) {
            this.setState({guId: bidGuid, companyId: companyId})
            this.getBidInfo(bidGuid)
        } 
        if (_.has(window, 'env.translations') && !_.isEmpty(window.env.translations)) {
            this.setState({
                translations: window.env.translations
            })
        }
    }

    getProjectGuid = (url) => {
        return queryString.parse(url).token
    }

    getCompanyId = (url) => {
        return queryString.parse(url).companyid
    }

    handleCustomePriceInput = (event) => {
        event.persist();
        let newValue = event.target.value;
        let modValue = (newValue.indexOf(".") >= 0) ? (newValue.substr(0, newValue.indexOf(".")) + newValue.substr(newValue.indexOf("."), 3)) : newValue;
        this.setState({permitCost: modValue});
        return modValue;
    }

    getBidInfo = (guId) => {
        let optionalParams = {
            headers: {"content-type": "application/json; charset=UTF-8", "Subscription-Key": `${window.env.apiKey}`},
            method: "GET"
        }

        return new Promise((resolve, reject) => {

                      fetch(`${window.env.qmtUrl}/Bid/${guId}`, optionalParams)
                      .then(res => {
                        this.setState({tierFechedSucces: res.status})
                        return res.json()})
                      .then(data => {
                        //   console.log('bid info', data);
                          this.setState({fetchedProjectInfo: data, isLoading: false})
                      })
                      .catch(error => {
                          reject({
                              isLoading: false,
                              fetchedData: { ErrorCode: 500, ...error }
                          });
                      })
        })
    }

    formatNumber = (value) => {
        let number = parseInt(value).toFixed(2),
            locale = 'en';
        
        return number.toLocaleString(locale);
    }

    postPermit = (event) => {
        if (event.detail === 1) {
            this.setState({isLoading: true})
            let data = {
                PermitCost: Number(this.state.permitCost),
            }
            let optionalParams = {
                headers: {"content-type": "application/json; charset=UTF-8", "Subscription-Key": `${window.env.apiKey}`},
                body: JSON.stringify(data),
                method: "POST"
            }
    
            new Promise((resolve, reject) => {
                fetch(`${window.env.qmtUrl}/Bid/${this.state.guId}/Accept`, optionalParams)
                .then(res => {
  
                    this.setState({tierEditionSuccess: res.status, isLoading: false})
                })
                .catch(error => {
    
                    reject({
                        isLoading: false,
                        fetchedData: { ErrorCode: 500, ...error }
                    });
                })
            })
        }

    }


    
    render() {
        const {
            Card,
            Header,
            PermitBidId,
            PermitAcceptP,
            PermitInfo,
            PermitDetails,
            CustomForm,
            Button,
        } = styledComponents;
        console.log("fetched", this.state.fetchedProjectInfo);
        const dueDate = formatter.getDateTime(this.state.fetchedProjectInfo.DueDate) || '';

        if ((_.has(this.state, 'fetchedProjectInfo.Status') && this.state.fetchedProjectInfo.Status === 'Declined') || (_.has(this.state, 'fetchedProjectInfo.Status') && this.state.fetchedProjectInfo.Status === 'Completed')) {
            return <BidStatusError status={this.state.fetchedProjectInfo.Status} />
        }
        
        if (!_.isEmpty(this.state.fetchedProjectInfo) && this.state.isLoading === false && this.state.tierEditionSuccess === false && (this.state.fetchedProjectInfo.Status === 'New' || this.state.fetchedProjectInfo.Status === 'InProgress')) {
            return (
                <Wrapper name="" company="">
                    <Card>
                        {/* <Header>{this.state.fetchedProjectInfo.BidRequest.Name}</Header> */}
                        <Header>{this.state.fetchedProjectInfo.BidRequest.Name}</Header>
                        <PermitBidId>Project #{`${this.state.fetchedProjectInfo.Project.Id}`}</PermitBidId>
                        <PermitAcceptP>To accept, you must confirm the following request details and enter the total permit cost.</PermitAcceptP>
                        <PermitDetails>
                            <p className="permitLabel">Project Scope</p>
                            <p className="permitValue">{this.state.fetchedProjectInfo.Project.Tier.Description}</p>
                            <p className="permitLabel">Quantity Not to Exceed</p>
                            <p className="permitValue">${this.formatNumber(this.state.fetchedProjectInfo.Project.Tier.WholesalePrice)}</p>
                        </PermitDetails>
                        {/* <PermitInfo>
                            <p className="permitInfo">You&apos;ll gain access to the customer&apos;s contact indormation if an in-erson site evaluation is needed to provide a custom quote</p>
                        </PermitInfo> */}
                        {!_.isEmpty(this.state.fetchedProjectInfo.BidRequest.Location) && 
                            <CustomForm>
                                <p className="customInstructions">To continue, the total permit cost must be entered for the site below.</p>
                                <a
                                    href={`https://www.google.com/maps/search/?api=1&query=${this.state.fetchedProjectInfo.BidRequest.Location.Street}${this.state.fetchedProjectInfo.BidRequest.Location.City}, ${this.state.fetchedProjectInfo.BidRequest.Location.State} ${this.state.fetchedProjectInfo.BidRequest.Location.Zip}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                    >
                                    <p className="customAddy">{this.state.fetchedProjectInfo.BidRequest.Location.Street}</p>
                                    <p className="customAddy">{`${this.state.fetchedProjectInfo.BidRequest.Location.City}, ${this.state.fetchedProjectInfo.BidRequest.Location.State} ${this.state.fetchedProjectInfo.BidRequest.Location.Zip}`}</p>
                                </a>
                                <p className="customLabel">Total Permit Cost</p>
                                <div className="customPriceContainer">
                                    <span className='customePriceInputSpan' >$</span>
                                    <input
                                        className="customPriceInput"
                                        type={'number'}
                                        value={this.state.permitCost} 
                                        onChange={this.handleCustomePriceInput}
                                    />
                                    <p className="currencyText">{this.state.fetchedProjectInfo.ProgramCurrencyType}</p>
                                </div>
                            </CustomForm>
                        }
                        <PermitInfo>
                            <Button 
                                disabled={_.isEmpty(this.state.permitCost) ? true : false} onClick={this.postPermit}>Confirm</Button>
                        </PermitInfo>
                    </Card>
                </Wrapper>
            );

        }

    if (!this.state.isLoading && (this.state.fetchedProjectInfo.Status === 'Cancelled' || this.state.fetchedProjectInfo.Status === 'Closed' || this.state.fetchedProjectInfo.Status === 'Completed') ){
        return (<InstallerStatus translations={this.state.translations} installerStatus={this.state.fetchedProjectInfo.Status === "Closed" ? "Accepted" : this.state.fetchedProjectInfo.Status === "Cancelled" ? "Cancelled" : ''}/>)
    }

    if (!this.state.isLoading && this.state.fetchedProjectInfo.Status === 'Declined') {
        return (<BidStatusError status={this.state.fetchedProjectInfo.Status} contactInfo={true} />)
    }  

    if (this.state.tierEditionSuccess === 200) {
        return <TierUpdateSuccess/>
    }

    if (this.state.tierEditionSuccess === 400 ||this.state.tierEditionSuccess === 404) {
        return <TierUpdateFail translations={this.state.translations}/>
    }

     return (<Loader color={"#000000"} isLoading={this.state.isLoading} />)
    }
}

export default TieredSubmitPermit