import React from 'react'
import styledComponents from './styles';

const {
  LaunchProjectContainer,
  ApproveQuoteButton,
  DeclineQuoteButton
} = styledComponents;

export default function ApproveDeclineQuote({ acceptBidByGuid, declineBidByGuid, handleContinue, translations, financingEnabled }) {

  return (
    <LaunchProjectContainer>
      {financingEnabled ? 
        <ApproveQuoteButton type="button" onClick={handleContinue}>Continue...</ApproveQuoteButton>
        :
        <ApproveQuoteButton type="button" onClick={acceptBidByGuid}>{translations.totalSection.total.approveDeclineQuote.buttonApprove}</ApproveQuoteButton>
      }
      <DeclineQuoteButton type="button" onClick={declineBidByGuid}>{translations.totalSection.total.approveDeclineQuote.buttonDecline}</DeclineQuoteButton>
    </LaunchProjectContainer>
  )
}
