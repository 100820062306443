import React, { Fragment } from 'react';
import StyledComponents from './styles';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';
import Markdown from 'react-markdown';
import _ from 'lodash';
import { itemValue, unitPrice, qbAmount, permit, installationCost, amount } from './helperFunctions';
import { QUOTE } from '../../constants';
import { filterPrepaid, filterNonPrepaid } from '../../helpers/servicesList';

const { P, Link, P_SM } = commonStyles;

const { ProductRow, ProductDescription, MobileProductDescription, SmallIconBox, ProductDetails, UnorderedList } =
  StyledComponents;

export default function PreliminaryEstimateTable({ fetchedBidData, bidData, translations, tableType = QUOTE }) {
  const servicesList =
    tableType === QUOTE ? filterNonPrepaid(fetchedBidData.ServicesList) : filterPrepaid(fetchedBidData.ServicesList);

  return (
    <Fragment>
      {servicesList.map((item) => {
        let description = !_.isNil(item.Descriptions) ? item.Descriptions[0].Description : '';
        let ukPermit =
          description == 'Permit' && (!_.isNil(item.Description) ? item.Descriptions[0].CultureCode == 'en-GB' : false);

        return !ukPermit ? (
          <ProductRow key={item.Id}>
            <ProductDescription role="row">
              {/* Using helper functions to obtain the correct tem value */}
              <P className="pItem">{itemValue(item, description, fetchedBidData, translations)}</P>
              {fetchedBidData.Request.QuoteBuilderEnabled && (
                <P className="pQty">{!_.isNil(item.Quantity) ? item.Quantity : 0}</P>
              )}
              {fetchedBidData.Request.QuoteBuilderEnabled ? (
                <Fragment>
                  {/* Using helper functions to obtain the correct Unit Price and Amount when considering a Quote Builder Request */}
                  <P className="pUnitPrice" number>
                    {unitPrice(item, fetchedBidData, translations)}
                  </P>
                  <P className="pAmount" number>
                    {qbAmount(item, fetchedBidData, translations)}{' '}
                  </P>
                </Fragment>
              ) : //First we make sure we have a PermitCost and InstallationCost value
              fetchedBidData.Request.Settlement.PermitCost && fetchedBidData.Request.Settlement.InstallationCost ? (
                //It seems like at some point, the permit value (SKU 7) was not being sent in the Services List object and we had to take it from the settlement
                item.Sku === '7' && fetchedBidData.Request.Settlement.PermitCost.Amount >= 0.0 ? (
                  // Using helper functions to obtain the correct Permit value
                  <P number>{permit(fetchedBidData, translations)}</P>
                ) : //It seems like at some point, the Installation Cost value (SKU 6) was not being sent in the Services List object and we had to take it from the settlement
                item.Sku === '6' && fetchedBidData.Request.Settlement.InstallationCost.Amount >= 0.0 ? (
                  // Using helper functions to obtain the correct Installation Cost value
                  <P number>{installationCost(fetchedBidData, translations)}</P>
                ) : (
                  // Using helper functions to obtain the correct Amount value
                  <P number>{amount(item, fetchedBidData, translations)}</P>
                )
              ) : (
                <P number>{amount(item, fetchedBidData, translations)}</P>
              )}
            </ProductDescription>
            {fetchedBidData.Request.QuoteBuilderEnabled && (
              <MobileProductDescription role="row">
                <P_SM className="pSm">{!_.isNil(item.Quantity) ? item.Quantity : 0}</P_SM>
                <SmallIconBox>
                  <i className="far fa-times" alt="times icon" name="times icon" aria-label="times icon" />
                </SmallIconBox>
                <P_SM className="pSm" number>
                  {amount(item, fetchedBidData, translations)}
                </P_SM>
              </MobileProductDescription>
            )}
            <ProductDetails>
              {fetchedBidData.Request.QuoteBuilderEnabled ? (
                <Markdown children={description} />
              ) : (
                <UnorderedList>
                  {item.Sku === '6'
                    ? translations.preliminaryEstimateSection.customDetails.map((detail) => {
                        return <li key={detail.id}>{detail.description}</li>;
                      })
                    : !_.isNil(item.Details)
                    ? item.Details.map((detail) => {
                        return <li key={detail.SortPriorityId}>{detail.Detail}</li>;
                      })
                    : null}
                </UnorderedList>
              )}
            </ProductDetails>
            {item.Sku === '6' ? (
              <Link style={{ paddingLeft: '20px' }} href={bidData.Sow} target="_blank">
                {translations.preliminaryEstimateSection.customQuoteSOW}
              </Link>
            ) : null}
          </ProductRow>
        ) : null;
      })}
    </Fragment>
  );
}
