import styled, { css } from 'styled-components';
import {
	mediaMin,
	mediaMax,
} from "../../../../../shared/style"

const Card = styled.div`
  box-sizing: border-box;
  padding: 24px;
  gap: 8px;
  width: 100%;
  background: #FFFFFF;
  /* container/ig */
  border: 1px solid #DEDFE2;
  border-radius: 4px;
  margin-top: 8px;

  ${mediaMax.phone`
    border: none;
    padding: 24px 0px;
    margin-top: 0px;
    box-shadow: inset 0px -1px 0px #EDEEEF;
  `};
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
`;

const IconBox = styled.div`
  width: 20px;
  height: 20px;
`;

const TitleIcon = styled.i`
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  display: flex;
  align-items: center;
  text-align: center;
  color: #B9BBC1;
`;

const TitleText = styled.h3`
  /* header/h2 */
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  display: flex;
  align-items: center;
  margin: 0px;

  /* text/default */
  color: #3E4148;

  ${mediaMax.phone`
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  `};
`;

const Subtitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  display: flex;
  align-items: center;
  margin: 8px 0px 0px 0px;
  padding-left: 28px;

  /* text / secondary */
  color: #6E727C;

  ${mediaMax.phone`
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin: 4px 0px 0px 0px;
  `};
`;

const Details = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 28px;
  gap: 24px;
  margin-top: 16px;

  ${mediaMax.phone`
  border: none;
  gap: 0px; 
  padding: 16px 0px 0px 28px;
  margin-top: 0px;
`};

  ${mediaMax.desktop`
    flex-direction: column;
    align-items: flex-start;
  `};
`;

const CompanyImage = styled.img`
  width: 168px;
  height: 102px;

  ${mediaMax.phone`
    margin-bottom: 16px
`};
`;

const CompanyInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0px;

  height: 80px;
`;

const CompanyInfoTitle = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  display: flex;
  align-items: center;
  margin: 0px 0px 4px 0px;

  /* label/field */
  color: #6E727C;
`;

const CompanyInfoContent = styled.a`
  /* body/p */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */
  display: flex;
  align-items: center;
  margin: 0px;

  /* text/default */
  color: #3E4148;

  ${props =>
		props.link &&
		css`
      color: #3963FE;
  `};
`;

export default {
	Card,
	TitleBox,
	IconBox,
	TitleIcon,
	TitleText,
	Subtitle,
	Details,
	CompanyImage,
	CompanyInfo,
	CompanyInfoTitle,
	CompanyInfoContent
}

