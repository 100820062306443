import React, { Fragment, useState, useEffect } from 'react';
import styledComponents from './styles';

const {
  Toggle,
  On,
  Off
} = styledComponents;

export default function ToggleButton({ toggle, setToggle, onText, offText }) {
  const handleToggleOn = () => {
    setToggle(true);
  }

  const handleToggleOff = () => {
    setToggle(false);
  }

  return (
    <Toggle>
      <On onClick={handleToggleOn} toggle={toggle}>
        {onText}
      </On>
      <Off onClick={handleToggleOff} toggle={toggle}>
        {offText}
      </Off>
    </Toggle>
  )
}