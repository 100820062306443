const isBrowser = typeof window !== "undefined" && window.env;

export const apiConfig = {
  getConsumerInfo: {
    root: `${
      isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT
    }`,
    method: "GET",
    version: "v1",
    url: "Quick/Activity",
    query: undefined
  },
  getRequirementsInfo: {
    root: `${
      isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT
    }`,
    method: "GET",
    version: "v1",
    url: "Quick/Requirements",
    query: undefined
  },
  postConsumerInfo: {
    root: `${
      isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT
    }`,
    method: "POST",
    version: "v1",
    url: "Quick/Activity",
    query: undefined
  },
  postRequirementsInfo: {
    root: `${
      isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT
    }`,
    method: "POST",
    version: "v1",
    url: "Quick/Requirements",
    query: undefined
  },
  getSurveyLocations: {
    root: `${
      isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT
    }`,
    method: "GET",
    version: "v1",
    url: "Quick/Activity",
    query: "lat"
  },
  postUploadImage: {
    root: `${
      isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT
    }`,
    method: "POST",
    version: "v1",
    url: "Bids/Upload"
  },
  postRequirementsUploadImage: {
    root: `${
      isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT
    }`,
    method: "POST",
    version: "v2",
    url: "Documents/Upload"
  },
  postCancelRequest: {
    root: `${
      isBrowser ? window.env.qmtUrl : process.env.QMT_URL
    }`,
    method: "POST",
    version: "api",
    url: "Consumer/Cancel"
  },
  postTieredCancelRequest: {
    root: `${
      isBrowser ? window.env.qmtUrl : process.env.QMT_URL
    }`,
    method: "POST",
    version: "api",
    url: "Consumer/Request"
  },
  getCancelRequestStatus: {
    root: `${
      isBrowser ? window.env.qmtUrl : process.env.QMT_URL
    }`,
    method: "GET",
    version: "api",
    url: "Consumer/Status"
  },
  getPendingBids: {
    root: `${
      isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT
    }`,
    method: "GET",
    version: "v1",
    url: "Quick/PendingBids"
  },
  getBidRequests: {
    root: `${
      isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT
    }`,
    method: "GET",
    version: "v1",
    url: "Quick/ActivityBids"
  },
  postCustomerResponse: {
    root: `${
      isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT
    }`,
    method: "POST",
    version: "v1",
    url: "Quick/CustomerReminderResponse"
  },
  getBids: {
    root: `${
      isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT
    }`,
    method: "POST",
    version: "v1",
    url: "Quick/BidSelect"
  },
  getAcceptedBids: {
    root: `${
      isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT
    }`,
    method: "GET",
    version: "v1",
    url: "Quick/StaleBids"
  },
  postCreateNote: {
    root: `${
      isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT
    }`,
    method: "POST",
    version: "v1",
    url: "Quick/CreateNote"
  },
  postCancelStaleActivity: {
    root: `${
      isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT
    }`,
    method: "POST",
    version: "v1",
    url: "Quick/CancelStaleActivity"
  },
  submitBid: {
    root: `${
      isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT
    }`,
    method: "POST",
    version: "v1",
    url: "Quick/SubmitBid"
  },
  staleBidOperation: {
    root: `${
      isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT
    }`,
    method: "POST",
    version: "v1",
    url: "Quick/StaleBidOperation"
  },
  staleScheduleAppointment: {
    root: `${
      isBrowser ? window.env.apiRoot : process.env.RAZZLE_RUNTIME_API_ROOT
    }`,
    method: "POST",
    version: "v1"
  }
};
