import QmeritLogo from '../../../public/apple-touch-icon.png'

export default {
  "User": null,
  "Type": "BidSelect",
  "Activities": [
    {
      "MasterActivityId": 23892,
      "ParentActivityId": null,
      "DerivedActivityId": null,
      "GeneratedActivityId": null,
      "Name": "EV Charger Bid",
      "Program": {
        "Id": 218,
        "Name": "QMerit EV Charger",
        "DBAName": "Qmerit Inc.",
        "ExternalData": {
          "ProgramId": 218,
          "Name": "Still need a charger?",
          "Description": "Buy chargers at a discounted rate right now. Compare models and find the right charger at the best price.",
          "ImageUrl": "https://storeqmtprd.blob.core.windows.net/images/Juicy-Charger.png",
          "Link": "https://emotorwerks.com/store/residential?token=wvBP0dzxwt3DVBej2zMABZKPoHtp/uiOSKr3esykq2A="
        }
      },
      "OwnerProgram": {
        "Id": 218,
        "Name": "QMerit EV Charger",
        "DBAName": "Qmerit Inc.",
        "ExternalData": {
          "ProgramId": 218,
          "Name": "Still need a charger?",
          "Description": "Buy chargers at a discounted rate right now. Compare models and find the right charger at the best price.",
          "ImageUrl": "https://storeqmtprd.blob.core.windows.net/images/Juicy-Charger.png",
          "Link": "https://emotorwerks.com/store/residential"
        }
      },
      "ActivityTemplateId": 941,
      "Priority": {
        "Id": 5,
        "Description": "Medium"
      },
      "Status": {
        "Id": "Completed",
        "Name": "Completed"
      },
      "StartBy": null,
      "StartNoLaterThan": null,
      "Assignment": {
        "Id": 8284,
        "Name": "AshbyKTech",
        "Type": "CorporateOffice",
        "ImageUrl": QmeritLogo,
        "Contact": {
          "Id": null,
          "FirstName": "Deepak",
          "LastName": "AshbyKT",
          "Email": "ashbyqaktech@gmail.com",
          "PhoneNumber": "(949) 232-3333",
          "PreferredMethod": null,
          "Title": "President",
          "Address": null,
          "ImageUrl": null
        }
      },
      "Description": "Your company has been requested to bid for installing an EV charging station for a customer. Be sure to check the attached Customer Project Scope for the specific details of the installation needs!",
      "Location": {
        "Id": 3889,
        "Name": "Residential",
        "Description": null,
        "Address": "32750 Eagle River Rd",
        "City": "Anchorage",
        "State": "AK",
        "Zip": "99577",
        "Country": "US",
        "Latitude": 61.23396,
        "Longitude": -149.270646,
        "Contact": {
          "Id": null,
          "FirstName": null,
          "LastName": null,
          "Email": null,
          "PhoneNumber": null,
          "PreferredMethod": null,
          "Title": null,
          "Address": null,
          "ImageUrl": null
        }
      },
      "Customer": {
        "Id": 4078,
        "Name": "qmerit10+1018_695 Long",
        "Type": "Company",
        "Contact": {
          "Id": null,
          "FirstName": "qmerit10+1018_695",
          "LastName": "Long",
          "Email": "qmerit10+1018_695@gmail.com",
          "PhoneNumber": "1234",
          "PreferredMethod": "email",
          "Title": null,
          "Address": "",
          "ImageUrl": null
        }
      },
      "DueDate": "2019-12-06T00:00:00",
      "Tasks": {
        "Id": "1",
        "Name": "Submit bid information",
        "Status": "Completed",
        "CanComplete": true,
        "Events": [],
        "Actions": [
          {
            "Files": [
              {
                "Id": "4143",
                "Url": "https://dacstorescorpiusqa.blob.core.windows.net/documents/b914ad25-cc27-4d8b-b9f2-47903c8700e4",
                "FileType": "application/pdf",
                "Type": "Qmerit",
                "UploadId": null
              }
            ],
            "MultiDocument": true,
            "ProjectDocument": null,
            "Id": "1b",
            "Label": "Quote",
            "Type": "Upload",
            "IsCompleted": true
          },
          {
            "CustomDecimal1": 1234,
            "Id": "1c",
            "Label": "Currency",
            "Type": "Currency",
            "IsCompleted": true
          },
          {
            "CustomString1": "from ashbyqaktech",
            "Id": "1d",
            "Label": "Bid Message",
            "Type": "Text",
            "IsCompleted": true
          }
        ],
        "Tasks": [],
        "Next": null
      },
      "Events": [],
      "Owner": {
        "Id": 4508,
        "DBAName": "Qmerit Inc.",
        "ImageUrl": "https://senstorescorpiusqa.blob.core.windows.net/images-company/f572c8a1-8ed4-45b8-89b8-bda404814fc8",
        "Contact": {
          "Id": null,
          "FirstName": "primaryFirst",
          "LastName": "primaryLAst",
          "Email": "ashbyqamike+qmerit@gmail.com",
          "PhoneNumber": "18882720090",
          "PreferredMethod": null,
          "Title": "primaryTitle",
          "Address": null,
          "ImageUrl": null
        }
      },
      "CompletionPercentage": 1,
      "Type": "Bid",
      "Project": {
        "Id": 14146,
        "Name": "EV Charger",
        "DueDate": null,
        "Status": "New",
        "ModifiedOn": null,
        "Attachments": []
      },
      "BidRequest": {
        "Id": 14147,
        "Name": "EV Charger Bid",
        "DueDate": "2019-12-06T00:00:00",
        "Status": "New",
        "ModifiedOn": "2019-11-22T22:40:02.7789665",
        "Attachments": [
          {
            "Id": 4142,
            "Type": "BidRequest",
            "Url": "https://evscorpiusqa.blob.core.windows.net/surveys/192a5ce2-9b24-4f15-9f63-e51ce754dc9a",
            "FileType": "application/pdf",
            "Name": "Project Scope",
            "IsProjectLevel": false,
            "IsPublic": true,
            "UploadType": "Qmerit",
            "UploadId": null
          }
        ]
      },
      "Collaborators": [],
      "SelectedOn": null,
      "CreatedOn": "2019-11-22T22:40:05.863",
      "IsFrozen": false,
      "AcceptedBy": {
        "Name": "Deepak AshbyKT",
        "AcceptedOn": "2019-11-22T22:42:03.396192"
      },
      "DeclinedBy": null,
      "ModifiedOn": "2019-11-22T22:42:03.897",
      "RemovedOn": null,
      "Operations": {},
      "Id": 23892
    },
    {
      "MasterActivityId": 23894,
      "ParentActivityId": null,
      "DerivedActivityId": null,
      "GeneratedActivityId": null,
      "Name": "EV Charger Bid",
      "Program": {
        "Id": 218,
        "Name": "QMerit EV Charger",
        "DBAName": "Qmerit Inc.",
        "ExternalData": {
          "ProgramId": 218,
          "Name": "Still need a charger?",
          "Description": "Buy chargers at a discounted rate right now. Compare models and find the right charger at the best price.",
          "ImageUrl": "https://storeqmtprd.blob.core.windows.net/images/Juicy-Charger.png",
          "Link": "https://emotorwerks.com/store/residential?token=zf9NvGdFdn9ZtRyLrFtfqCdnE3D/M4bGOTPSY6Im8yk="
        }
      },
      "OwnerProgram": {
        "Id": 218,
        "Name": "QMerit EV Charger",
        "DBAName": "Qmerit Inc.",
        "ExternalData": {
          "ProgramId": 218,
          "Name": "Still need a charger?",
          "Description": "Buy chargers at a discounted rate right now. Compare models and find the right charger at the best price.",
          "ImageUrl": "https://storeqmtprd.blob.core.windows.net/images/Juicy-Charger.png",
          "Link": "https://emotorwerks.com/store/residential"
        }
      },
      "ActivityTemplateId": 941,
      "Priority": {
        "Id": 5,
        "Description": "Medium"
      },
      "Status": {
        "Id": "InProgress",
        "Name": "InProgress"
      },
      "StartBy": "2019-11-23T11:20:00",
      "StartNoLaterThan": "2019-11-23T12:25:00",
      "Assignment": {
        "Id": 4390,
        "Name": "Ashby 6868",
        "Type": "CorporateOffice",
        "ImageUrl": QmeritLogo,
        "Contact": {
          "Id": null,
          "FirstName": "Deepak",
          "LastName": "Toni test",
          "Email": "ashbyqa6868@gmail.com",
          "PhoneNumber": "555-555-5555",
          "PreferredMethod": null,
          "Title": "vp",
          "Address": null,
          "ImageUrl": null
        }
      },
      "Description": "Your company has been requested to bid for installing an EV charging station for a customer. Be sure to check the attached Customer Project Scope for the specific details of the installation needs!",
      "Location": {
        "Id": 3889,
        "Name": "Residential",
        "Description": null,
        "Address": "32750 Eagle River Rd",
        "City": "Anchorage",
        "State": "AK",
        "Zip": "99577",
        "Country": "US",
        "Latitude": 61.23396,
        "Longitude": -149.270646,
        "Contact": {
          "Id": null,
          "FirstName": null,
          "LastName": null,
          "Email": null,
          "PhoneNumber": null,
          "PreferredMethod": null,
          "Title": null,
          "Address": null,
          "ImageUrl": null
        }
      },
      "Customer": {
        "Id": 4078,
        "Name": "qmerit10+1018_695 Long",
        "Type": "Company",
        "Contact": {
          "Id": null,
          "FirstName": "qmerit10+1018_695",
          "LastName": "Long",
          "Email": "qmerit10+1018_695@gmail.com",
          "PhoneNumber": "1234",
          "PreferredMethod": "email",
          "Title": null,
          "Address": "",
          "ImageUrl": null
        }
      },
      "DueDate": "2019-12-06T00:00:00",
      "Tasks": {
        "Id": "1",
        "Name": "Submit bid information",
        "Status": "New",
        "CanComplete": true,
        "Events": [],
        "Actions": [
          {
            "Files": [],
            "MultiDocument": true,
            "ProjectDocument": null,
            "Id": "1b",
            "Label": "Quote",
            "Type": "Upload",
            "IsCompleted": false
          },
          {
            "CustomDecimal1": null,
            "Id": "1c",
            "Label": "Currency",
            "Type": "Currency",
            "IsCompleted": false
          },
          {
            "CustomString1": null,
            "Id": "1d",
            "Label": "Bid Message",
            "Type": "Text",
            "IsCompleted": false
          }
        ],
        "Tasks": [],
        "Next": null
      },
      "Events": [],
      "Owner": {
        "Id": 4508,
        "DBAName": "Qmerit Inc.",
        "ImageUrl": "https://senstorescorpiusqa.blob.core.windows.net/images-company/f572c8a1-8ed4-45b8-89b8-bda404814fc8",
        "Contact": {
          "Id": null,
          "FirstName": "primaryFirst",
          "LastName": "primaryLAst",
          "Email": "ashbyqamike+qmerit@gmail.com",
          "PhoneNumber": "18882720090",
          "PreferredMethod": null,
          "Title": "primaryTitle",
          "Address": null,
          "ImageUrl": null
        }
      },
      "CompletionPercentage": 0,
      "Type": "Bid",
      "Project": {
        "Id": 14146,
        "Name": "EV Charger",
        "DueDate": null,
        "Status": "New",
        "ModifiedOn": null,
        "Attachments": []
      },
      "BidRequest": {
        "Id": 14147,
        "Name": "EV Charger Bid",
        "DueDate": "2019-12-06T00:00:00",
        "Status": "New",
        "ModifiedOn": "2019-11-22T22:40:02.7789665",
        "Attachments": [
          {
            "Id": 4142,
            "Type": "BidRequest",
            "Url": "https://evscorpiusqa.blob.core.windows.net/surveys/192a5ce2-9b24-4f15-9f63-e51ce754dc9a",
            "FileType": "application/pdf",
            "Name": "Project Scope",
            "IsProjectLevel": false,
            "IsPublic": true,
            "UploadType": "Qmerit",
            "UploadId": null
          }
        ]
      },
      "Collaborators": [],
      "SelectedOn": null,
      "CreatedOn": "2019-11-22T22:41:12.227",
      "IsFrozen": false,
      "AcceptedBy": {
        "Name": "Toni Li",
        "AcceptedOn": "2019-11-22T23:10:35.0273824"
      },
      "DeclinedBy": null,
      "ModifiedOn": "2019-11-22T23:22:31.857",
      "RemovedOn": null,
      "Operations": {},
      "Id": 23894
    },
    {
      "MasterActivityId": 23896,
      "ParentActivityId": null,
      "DerivedActivityId": null,
      "GeneratedActivityId": null,
      "Name": "EV Charger Bid",
      "Program": {
        "Id": 218,
        "Name": "QMerit EV Charger",
        "DBAName": "Qmerit Inc.",
        "ExternalData": {
          "ProgramId": 218,
          "Name": "Still need a charger?",
          "Description": "Buy chargers at a discounted rate right now. Compare models and find the right charger at the best price.",
          "ImageUrl": "https://storeqmtprd.blob.core.windows.net/images/Juicy-Charger.png",
          "Link": "https://emotorwerks.com/store/residential?token=tylSBeJFKjj/ikRaR6lVrBWmiEYEs028TSIeOt9yVak="
        }
      },
      "OwnerProgram": {
        "Id": 218,
        "Name": "QMerit EV Charger",
        "DBAName": "Qmerit Inc.",
        "ExternalData": {
          "ProgramId": 218,
          "Name": "Still need a charger?",
          "Description": "Buy chargers at a discounted rate right now. Compare models and find the right charger at the best price.",
          "ImageUrl": "https://storeqmtprd.blob.core.windows.net/images/Juicy-Charger.png",
          "Link": "https://emotorwerks.com/store/residential"
        }
      },
      "ActivityTemplateId": 941,
      "Priority": {
        "Id": 5,
        "Description": "Medium"
      },
      "Status": {
        "Id": "InProgress",
        "Name": "InProgress"
      },
      "StartBy": null,
      "StartNoLaterThan": null,
      "Assignment": {
        "Id": 4472,
        "Name": "Ashby7070 &",
        "Type": "CorporateOffice",
        "ImageUrl": "https://senstorescorpiusqa.blob.core.windows.net/images-company/690a7a06-9007-451b-ba8d-0536061a14d7",
        "Contact": {
          "Id": null,
          "FirstName": "philip",
          "LastName": "smith",
          "Email": "ashbyqa7070@gmail.com",
          "PhoneNumber": "949-232-1111",
          "PreferredMethod": null,
          "Title": "branch manager",
          "Address": null,
          "ImageUrl": null
        }
      },
      "Description": "Your company has been requested to bid for installing an EV charging station for a customer. Be sure to check the attached Customer Project Scope for the specific details of the installation needs!",
      "Location": {
        "Id": 3889,
        "Name": "Residential",
        "Description": null,
        "Address": "32750 Eagle River Rd",
        "City": "Anchorage",
        "State": "AK",
        "Zip": "99577",
        "Country": "US",
        "Latitude": 61.23396,
        "Longitude": -149.270646,
        "Contact": {
          "Id": null,
          "FirstName": null,
          "LastName": null,
          "Email": null,
          "PhoneNumber": null,
          "PreferredMethod": null,
          "Title": null,
          "Address": null,
          "ImageUrl": null
        }
      },
      "Customer": {
        "Id": 4078,
        "Name": "qmerit10+1018_695 Long",
        "Type": "Company",
        "Contact": {
          "Id": null,
          "FirstName": "qmerit10+1018_695",
          "LastName": "Long",
          "Email": "qmerit10+1018_695@gmail.com",
          "PhoneNumber": "1234",
          "PreferredMethod": "email",
          "Title": null,
          "Address": "",
          "ImageUrl": null
        }
      },
      "DueDate": "2019-12-06T00:00:00",
      "Tasks": {
        "Id": "1",
        "Name": "Submit bid information",
        "Status": "New",
        "CanComplete": true,
        "Events": [],
        "Actions": [
          {
            "Files": [],
            "MultiDocument": true,
            "ProjectDocument": null,
            "Id": "1b",
            "Label": "Quote",
            "Type": "Upload",
            "IsCompleted": false
          },
          {
            "CustomDecimal1": null,
            "Id": "1c",
            "Label": "Currency",
            "Type": "Currency",
            "IsCompleted": false
          },
          {
            "CustomString1": null,
            "Id": "1d",
            "Label": "Bid Message",
            "Type": "Text",
            "IsCompleted": false
          }
        ],
        "Tasks": [],
        "Next": null
      },
      "Events": [],
      "Owner": {
        "Id": 4508,
        "DBAName": "Qmerit Inc.",
        "ImageUrl": "https://senstorescorpiusqa.blob.core.windows.net/images-company/f572c8a1-8ed4-45b8-89b8-bda404814fc8",
        "Contact": {
          "Id": null,
          "FirstName": "primaryFirst",
          "LastName": "primaryLAst",
          "Email": "ashbyqamike+qmerit@gmail.com",
          "PhoneNumber": "18882720090",
          "PreferredMethod": null,
          "Title": "primaryTitle",
          "Address": null,
          "ImageUrl": null
        }
      },
      "CompletionPercentage": 0,
      "Type": "Bid",
      "Project": {
        "Id": 14146,
        "Name": "EV Charger",
        "DueDate": null,
        "Status": "New",
        "ModifiedOn": null,
        "Attachments": []
      },
      "BidRequest": {
        "Id": 14147,
        "Name": "EV Charger Bid",
        "DueDate": "2019-12-06T00:00:00",
        "Status": "New",
        "ModifiedOn": "2019-11-22T22:40:02.7789665",
        "Attachments": [
          {
            "Id": 4142,
            "Type": "BidRequest",
            "Url": "https://evscorpiusqa.blob.core.windows.net/surveys/192a5ce2-9b24-4f15-9f63-e51ce754dc9a",
            "FileType": "application/pdf",
            "Name": "Project Scope",
            "IsProjectLevel": false,
            "IsPublic": true,
            "UploadType": "Qmerit",
            "UploadId": null
          }
        ]
      },
      "Collaborators": [],
      "SelectedOn": null,
      "CreatedOn": "2019-11-22T23:26:00.973",
      "IsFrozen": false,
      "AcceptedBy": {
        "Name": "test70 test70",
        "AcceptedOn": "2019-11-22T23:51:59.5497715"
      },
      "DeclinedBy": null,
      "ModifiedOn": null,
      "RemovedOn": null,
      "Operations": {},
      "Id": 23896
    }
  ],
  "Bids": [],
  "Customer": null,
  "Project": null,
  "OperationGuids": {
    "CancelRequest": "asdf"
  }
}