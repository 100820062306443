import styled, { css } from 'styled-components';
import {
  mediaMin,
  mediaMax,
} from "../../../../shared/style"


const TimelineWrapper = styled.div`
  background: white;
  /* padding: 48px 0px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  /* height: 280px; */
  left: 0px;
  right: 0px;
  top: 0px;
  padding: 0px;
  box-shadow: 0px 1px 4px rgba(222, 223, 226, 0.75);
`;

const StatusTitle = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #3E4148;
  text-align: center;
  margin: 48px 0px 16px;

  ${mediaMax.phone`
    font-size: 24px;
    line-height: 32px;
    margin: 48px 0px 8px;
  `};
`;

const StatusSubtitle = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #3E4148;
  text-align: center;
  margin: 0px 0px 48px;

  ${mediaMax.phone`
    font-size: 16px;
    line-height: 20px;
  `};
`;

const HighlightedSubtitle = styled.span`
  font-style: bold;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #3E4148;
  text-align: center;
  margin: 0px 0px 50px;

  ${mediaMax.phone`
    font-size: 16px;
    line-height: 20px;
  `};
`;

const Timeline = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  margin-bottom: 48px;
  /* height: 56px; */

/*   ${mediaMax.phone`
    width: 361px;
    height: 40px;
  `}; */
`;

const TrackerSegment = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 16px;

  /* width: 133px; */
  /* height: 56px; */

  ${mediaMax.phone`
    /* width: 72.2px; */
    /* height: 40px; */
    gap: 12px;
  `};
`;

const Step = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 133px;
  height: 24px;

  ${mediaMax.phone`
    width: 72.2px;
    height: 12px;
  `};

  ${props =>
    props.active &&
    css`
      gap: 9px;
  `};
`;

const TrackerConnectorLeft = styled.div`
  width: 48.5px;
  height: 2px;
  background: #B9BBC1;

  ${mediaMax.phone`
  width: 26.1px;
  `};

  ${props =>
    props.first &&
    css`
      opacity: 0;
    `};
    
  ${props =>
    props.past &&
    css`
    /* white/white-100 */
    background: #3963FE;
  `};

  ${props =>
    props.canceled &&
    css`
    background: #B9BBC1;
  `};
`;

const TackerIcon = styled.div`
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;

  width: 36px;
  height: 36px;

  /* white/white-100 */
  border: 2px solid #3963FE;
  border-radius: 100px;
`;

const TrackerCircle = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;

  width: 20px;
  height: 20px;
  border: 2px solid #B9BBC1;

  border-radius: 100px;

  ${mediaMax.phone`
    width: 12px;
    height: 12px;
  `};

  ${props =>
    props.active &&
    css`
      
    /* Auto layout */
    padding: 12px 14px;
    gap: 8px;

    width: 44px;
    height: 44px;

    ${mediaMax.phone`
      width: 32px;
      height: 32px;
    `};

    /* white/white-100 */
    border: 2px solid #3963FE;
  `};

  ${props =>
    props.past &&
    css`
      
    /* Auto layout */
    gap: 8px;

    width: 24px;
    height: 24px;

    /* white/white-100 */
    background: #3963FE;
    border-radius: 100px;
    border: 2px solid #3963FE;
    
    ${mediaMax.phone`
      padding: 2px;
      width: 16px;
      height: 16px;
    `};
  `};

  ${props =>
    props.canceled &&
    css`
    background: #B9BBC1;
    border: 2px solid #B9BBC1;
  `};
`;

const Icon = styled.i`

  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  display: flex !important;
  align-items: center;
  text-align: center;

  /* white/white-100 */
  color: #3963FE;

  ${mediaMax.phone`
    font-size: 12px;
    line-height: 16px;
  `};
`;

const PastIcon = styled.i`
  display: flex !important;
  align-items: center;
  text-align: center;
  width: 16px;
  height: 16px;
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: white;

  ${mediaMax.phone`
    font-size: 8px;
    line-height: 12px;
  `};
`;

const TrackerConnectorRight = styled.div`
  width: 48.5px;
  height: 2px;
  background: #B9BBC1;

  ${mediaMax.phone`
    width: 26.1px;
  `};

  ${props =>
    props.last &&
    css`
      opacity: 0;
    `};

  ${props =>
    props.past &&
    css`
    background: #3963FE;
  `};

  ${props =>
    props.canceled &&
    css`
    background: #B9BBC1;
  `};
`;

const TrackerTextBox = styled.div`

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  height: 16px;

`;

const TrackerText = styled.p`

  width: 117px;

  /* body/p-micro-bold */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0px;

  /* white/white-100 */
  color: #6E727C;

  opacity: 0.85;

  ${props =>
    props.active &&
    css`
      opacity: 1;
      color: #3963FE;
      font-weight: 700;
      font-size: 14px;

      ${mediaMax.phone`
        font-size: 12px;
      `};
  `};

  ${props =>
    props.past &&
    css`
    color: #3E4148;
  `};

  ${mediaMax.desktop`
    width: 72.2px;
`};
`;

export default {
  Timeline,
  TrackerSegment,
  Step,
  TrackerConnectorLeft,
  TackerIcon,
  TrackerCircle,
  TimelineWrapper,
  StatusTitle,
  StatusSubtitle,
  Icon,
  PastIcon,
  TrackerConnectorRight,
  TrackerTextBox,
  TrackerText,
  HighlightedSubtitle,
}