import React, { PureComponent } from "react";

import { apiConfig } from "../../../lib/apiConfig";
import { getToken } from "../../../lib/getUserData";
import Loader from "../../../components/Loader";
import fetchData from "../../../lib/fetchData";
import FetchFailure from "../../../components/FetchFailure";
import QuickCard, { Section } from "../../../components/QuickCard";
import BidDeclineConfirm from "../BidDeclineConfirm";
import styles from "./styles.module.css";
import Radio from "../../../components/Radio";
import styled from "styled-components";
import { mediaMax, headerSizes } from "../../../shared/style";

class BidDecline extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      isLoading: false,
      fetchSuccess: false,
      fetchError: false,
      token: "token=" + props.fetchedData.OperationGuids.Decline,
      reason: "",
      reasonSelected: "",
      otherReason: "",
      openTextArea: false,
      usingMock: false,
      submitDisabled: true
    };
  }

  componentDidMount() {}

  handleChange(event, label) {
    if (event.target.id === "reason-1" || event.target.id === "reason-2") {
      this.setState({
        reason: label,
        reasonSelected: event.target.id,
        openTextArea: false,
        submitDisabled: false
      });
    }
    if (event.target.id === "reason-3") {
      this.setState({
        reason: this.state.otherReason,
        reasonSelected: event.target.id,
        openTextArea: true,
        submitDisabled: this.state.otherReason.length < 1
      });
    }
    if (event.target.id === "reason-4") {
      this.setState({
        reason: event.target.value,
        reasonSelected: "reason-3",
        otherReason: event.target.value,
        submitDisabled: event.target.value.length < 1
      });
    }
  }

  submitForm() {
    if (!this.state.usingMock) {
      const options = {
        ...apiConfig.postConsumerInfo,
        query: this.state.token,
        body: {
          reason: this.state.reason
        }
      };
      fetchData(options).then(data => {
        if (data.fetchedData && !data.fetchedData.ErrorCode) {
          this.setState({ data, fetchSuccess: true });
        } else {
          this.setState({ fetchError: true });
        }
      });
    } else {
      this.setState({ fetchSuccess: true });
    }
  }

  render() {
    if (this.state.fetchError) {
      return <FetchFailure />;
    }

    if (this.state.fetchSuccess) {
      return <BidDeclineConfirm />;
    }
    const fetchedData = this.state.fetchedData;
    const fetchedDataActivities = fetchedData.Activities[0];
    return (
      <Root>
        <QuickCard>
          <Loader isLoading={this.state.isLoading}>
            <div className="main-content">
              <div className={styles.declineFlexContainer}>
                <div>
                  <h2 className={styles.bidName}>
                    {fetchedData ? fetchedDataActivities.Name : "Bid Name"}
                  </h2>
                  {fetchedData !== undefined && (
                    <div>
                      <p className={styles.bidNumber}>
                        Bid Request #{fetchedDataActivities.BidRequest.Id}
                      </p>
                      <p className={styles.bdDescription}>
                        Please let {fetchedDataActivities.Owner.DBAName} know why you won’t be
                        providing a bid.
                      </p>
                    </div>
                  )}

                  <form>
                    <Radio
                      name="reason"
                      id="reason-1"
                      label="Out of the service area"
                      onChange={e => {
                        this.handleChange(e, "Out of the service area");
                      }}
                    />
                    <Radio
                      name="reason"
                      id="reason-2"
                      label="Unable to perform"
                      onChange={e => {
                        this.handleChange(e, "Unable to perform");
                      }}
                    />
                    <Radio
                      name="reason"
                      id="reason-3"
                      label="Other..."
                      onChange={e => {
                        this.handleChange(e, "Other...");
                      }}
                    />
                  </form>
                  {this.state.openTextArea && (
                    <div className={styles.textAreaContainer}>
                      <label
                        className={styles.declineReasonLabel}
                        htmlFor="reason-4"
                      >
                        Reason
                      </label>
                      <textarea
                        className={styles.declineTextArea}
                        value={this.state.reason}
                        id="reason-4"
                        onChange={e => {
                          this.handleChange(e);
                        }}
                        placeholder="Let us know why you&apos;re declining to bid..."
                        rows="2"
                        cols="50"
                      />
                    </div>
                  )}
                </div>

                <div>
                  <div className={styles.buttonContainer}>
                    <button
                      className={styles.declineButton}
                      disabled={this.state.submitDisabled}
                      onClick={() => this.submitForm()}
                    >
                      Decline Bid
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Loader>
        </QuickCard>
      </Root>
    );
  }
}

export default BidDecline;

const Root = styled.div`
  ${Section} {
    ${mediaMax.phone`
      min-height: calc(100vh - ${headerSizes.phone}px);
    `};
    .main-content > div {
      ${mediaMax.phone`
      padding-bottom: 33px;
      min-height: calc(100vh - ${headerSizes.phone}px - 32px);
    `};
    }
  }
`;
