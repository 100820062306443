import styled, { css } from "styled-components";

const Card = styled.div`
  width: 480px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14),
    0px 4px 5px 0px rgba(0, 0, 0, 0.12),
    0px 1px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  padding: 24px 24px 24px 24px;
  margin: auto;
  margin-top: 1.5rem;
`;

const Header = styled.h2`
  color: rgb(62, 65, 72);
  font-size: 18px;
  font-family: Montserrat;
  font-weight: bold;
  margin: 0;
`;

const PermitBidId = styled.p`
  color: rgba(62, 65, 72, 0.7);
  font-size: 14px;
  font-weight: normal;
  height: 20px;
  line-height: 20px;
  width: 448px;
  margin: 0 0 1.5rem 0;
`;

const PermitDetails = styled.div`
margin-top: 1rem;
.permitLabel {
  color: #6e727c;
  font-size: 12px;
  font-family: Lato;
  font-weight: normal;
  letter-spacing: 0.5px;
}
.permitValue {
  width: 430px;
  color: rgb(62, 65, 72);
  font-size: 16px;
  font-family: Lato;
  font-weight: normal;
  margin-bottom: 1rem;
}
`;

const PermitAcceptP = styled.p`
  height: 40px;
  color: #3e4148;
  font-size: 16px;
  font-family: Lato;
  font-weight: normal;
  line-height: 20px;
`;

const PermitInfo = styled.div`
  display: flex;
  justify-content: center;
  .permitInfo {
    color: rgba(62, 65, 72, 0.7);
    font-size: 12px;
    font-family: Lato;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0.25px;
    line-height: 16px;
    width: 432px;
    margin: 0 0 1.5rem 0;
  }
`;

const TierChecksContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 6px;
`;


const TierTextContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const TierText = styled.p`
  color: #3e4148;
  font-size: 14px;
  font-weight: normal;
  height: 20px;
  letter-spacing: 0.25px;
  line-height: 20px;
  margin: 18px 0 4px 24px;

  ${props => props.gray && css`
    font-style: italic;
    color: #b9bbc1;
    margin-left: 0;
  `}

  ${props => props.customePrice && css`
    margin: 0 0 4px 48px; 
  `}

  ${props => {
    if (props.displayed === false) {
      return (
        css`
          display: none;
        `
      );
    }
  }}
`;

const TierTextArea = styled.textarea`
  background: rgb(250, 250, 250);
  border-radius: 4px;
  border: 1px solid rgb(222, 223, 226);
  height: 64px;
  width: 400px;
  margin-left: 24px;

  &::placeholder {
    color: rgba(62, 65, 72, 0.35);
    font-family: Lato;
    font-size: 16px;
    font-weight: normal;
    height: 20px;
    line-height: 20px;
  }

  ${props => {
    if (props.active) {
      return (
        css`
          border: 1px solid rgb(57, 99, 254);
          box-shadow: 0px 0px 4px 0px rgb(57, 99, 254);
        `
      )
    }
  }}
`;

const Button = styled.button`

  width: 200px;
  height: 40px;
  background: #2f55fe;
  border: 1px solid rgb(47, 85, 254);
  border-radius: 8px;

  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 16px;
  text-align: center;
  text-transform: capitalize;
  margin: 24px 124px 8px 124px;
  cursor: pointer;

  ${props => props.disabled && css`
    background: #8aa3ff;
    border: none;
   `}
`;

const CustomePriceContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const CustomePriceInput = styled.input`
  background: rgb(250, 250, 250);
  border-radius: 4px 0px 0px 4px;
  border: 1px solid rgb(222, 223, 226);
  height: 44px;
  width: 260px;
  margin: 0 0 16px 48px;

  &::placeholder {
    color: rgba(62, 65, 72, 0.35);
    font-family: Lato;
    font-size: 16px;
    font-weight: normal;
    height: 20px;
    line-height: 20px;
    text-align: left;
    padding: 12px;
  }

  ${props => {
    if (props.active) {
      return (
        css`
          border: 1px solid rgb(57, 99, 254);
          box-shadow: 0px 0px 4px 0px rgb(57, 99, 254);
        `
      )
    }
  }}

`;

const CurrencyText = styled.p`
  background: rgb(222, 223, 226);
  border-radius: 0px 4px 4px 0px;
  padding: 12px 10px 10px 10px;
  height: 44px;
  width: 92px;
  margin: 0;
  text-align: center;
  color: rgb(110, 114, 124);
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 1px;
  line-height: 20px;
  text-align: center;
`;

const CustomForm = styled.div`
    width: 100%;
    border: 1px solid rgb(222, 223, 226);
    border-radius: 8px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    p {
        margin: 0;
    }

    
    .customInstructions {
        color: rgb(62, 65, 72);
        font-size: 16px;
        font-family: Lato;
        font-weight: normal;
        line-height: 20px;
        margin: 0 0 1rem 0;
    }
    .customAddy {
        color: #3963fe;
        font-size: 16px;
        font-family: Lato;
        font-weight: normal;
    }
    .customLabel {
        color: rgb(62, 65, 72);
        font-size: 14px;
        font-family: Lato;
        font-weight: normal;
        margin: 1rem 0 4px 0;
    }
    .customPriceContainer {
        display: flex;
        flex-direction: row;

        .customePriceInputSpan {
            background: rgb(250, 250, 250);
            border-radius: 4px 0px 0px 4px;
            border: 1px solid rgb(222, 223, 226);
            border-right: 0;
            height: 44px;
            margin: 0;
            padding: 12px;
            font-size: 16px;
        }

        .customPriceInput {
            background: rgb(250, 250, 250);
            border-radius: 0px 0px 0px 0px;
            border: 1px solid rgb(222, 223, 226);
            border-left: 0;
            height: 44px;
            width: 100%;
            margin: 0;
            text-align: left ;
            padding: 12px ;
            padding-left: 0px;
            font-family: Lato;
            font-size: 16px;
            font-weight: normal;
            &::placeholder {
                color: rgba(62, 65, 72, 0.35);
                height: 20px;
                line-height: 20px;
            }
        }
        .currencyText {
            background: rgb(222, 223, 226);
            border-radius: 0px 4px 4px 0px;
            padding: 12px 10px 10px 10px;
            height: 44px;
            width: 92px;
            margin: 0;
            text-align: center;
            color: rgb(110, 114, 124);
            font-family: Lato;
            font-size: 16px;
            font-weight: normal;
            letter-spacing: 1px;
            line-height: 20px;
            text-align: center;
        }
    }
`;

export default {
    Card,
    Header,
    PermitBidId,
    PermitAcceptP,
    PermitDetails,
    PermitInfo,
    Button,
    CustomForm,
}