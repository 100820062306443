import React from 'react';

export const emailLink = (email) => (
    <a
        href={`mailto:${email}`}
        title="Email Address"
    >
        <span className="link">
            {email}
        </span>
    </a>
)

export const phoneLink = (phone) => {
    return (
        <a
            href={`tel:${phone}`}
            title="Phone Number"
        >
            <span className="link">
                {phone}
            </span>
        </a>
    )
}

export const addressLink = (location) => {
    const address = `${location.Address} ${location.City}, ${location.State} ${location.Zip}`

    return (
        <a
            href={`https://www.google.com/maps/search/?api=1&query=${address}`}
            target="_blank"
            rel="noopener noreferrer"
        >
            <span className="link">
                {location.Address} <br/> 
                {location.City}, {location.State} {location.Zip}
            </span>
        </a>
    )
}
