import React, { Fragment } from "react";
import InfoCard from "../InfoCard";
import { StatusCard } from "../styles";
import { NoResponseIcon } from "./styles";
import NoResponse from "../../../../../../public/images/no-response.svg";

/** Renders "waiting for customer" view */
export default props => {
  return (
    <Fragment>
      <StatusCard pt={8}>
        <NoResponseIcon src={NoResponse} />
        <p>
          Thanks for the update! We'll stop reminding you about this item for
          now.
        </p>
      </StatusCard>
      <InfoCard isDone {...props} />
    </Fragment>
  );
};
