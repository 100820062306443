import React from "react";
import _ from 'lodash';
import Message from "../../../components/message";
import BidConfirmedImage from '../../../../../public/images/BidConfirmed.png';

const BidChanged = (props) => {
  return (
        <Message>
            <img alt="Already Decline" src={BidConfirmedImage} />
            <h1 style={{ color: "#3E4148" }}>Thanks for bidding!</h1>

            <p style={{ color: "#3E4148" }}>Feel free to close this window.  We&apos;ll let the customer know that your bid has been added.</p>

        </Message>
    );
};

export default BidChanged;
