import React, { Fragment } from 'react';
import StyledComponents from './styles'
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';

const {
  P
} = commonStyles;

const {
  Row
} = StyledComponents;


const ConceptRow = ({show, concept, amount}) => {
  if(show <= 0) return null;
  return (
    <Fragment>
      <Row>
        <P>{concept}</P>
        <P>- {amount}</P>
      </Row>
    </Fragment>
  );
};

export default ConceptRow;