import styled from "styled-components";
import { mediaMax } from "../../../../shared/style";
import { endsWith } from "lodash";

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const SectionHeaderSC = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin: 0px;

  ${mediaMax.tablet`
    font-size: 18px;
    line-height: 24px;
  `}
`;

const HeavierLoadsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  ${mediaMax.tablet`
    gap: 8px;
  `}
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  .banner {
    display: flex;
    flex-direction: column;
    gap: 16px;
    /* padding: 24px 24px 24px 200px; */
    padding: 24px 24px 24px 175px;
    border-radius: 8px;
    /* background: ${({domain}) => `url('${domain}/upfront-pricing/panel-recog-ai.png') no-repeat 241px 136px, 
      linear-gradient(269deg, #F8F9FF 0%, rgba(248, 249, 255, 0.00) 159.39%)`};
    background-position-x: left;
    background-position-y: top; */
    background: ${({domain}) => `url('${domain}/upfront-pricing/electrician-left-img.png') left top no-repeat , 
      linear-gradient(91deg, #F8F9FF -3.4%, rgba(248, 249, 255, 0.00) 109.53%)`};
    background-size: contain;

    ${mediaMax.tablet`
      display: none;
    `}

    p {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin: 0px;
    }

    .bannerHeader {
      display: flex;
      flex-direction: row;
      gap: 4px;

      h3 {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 0px;
      }

      span {
        font-family: Red Hat Display;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        color: #3963FE;
        margin: 0px;
      }
    }
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;

  ${mediaMax.tablet`
    gap: 4px;
  `}

  .header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
    box-shadow: 0px -1px 0px 0px #EDEEEF inset;

    ${mediaMax.tablet`
      display: none;
    `}

    .brokerIdColumn {
      display: flex;
      padding: 8px;
      align-items: flex-start;
      gap: 4px;
      flex: 1 1 0;
    }

    .descriptionColumn {
      display: flex;
      padding: 8px;
      align-items: flex-start;
      gap: 4px;
      flex: 2 1 0;
    }

    .amps {
      display: flex;
      flex-direction: row;
      padding: 8px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      flex: 1 1 0;
    }

    .eighty {
      display: flex;
      flex-direction: row;
      padding: 8px;
      justify-content: center;
      align-items: center;
      color: transparent;
    }

    .volts {
      display: flex;
      padding: 8px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      flex: 1 1 0;
    }

    .totalWattsColumn {
      display: flex;
      padding: 8px;
      justify-content: flex-end;
      align-items: center;
      gap: 4px;
      flex: 1 1 0;
    }

    .iconColumn {
      display: flex;
      width: 20px;
      padding: 8px;
      justify-content: flex-end;
      align-items: center;
      gap: 4px;
      color: #FFF;
    }
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 2.8 1 0;
  gap: 16px;
`;

const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;

  i {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #3963FE;
  }
`;

const ModalContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export default {
  TitleContainer,
  SectionHeaderSC,
  HeavierLoadsContainer,
  HeaderContainer,
  Form,
  FlexWrapper,
  IconBox,
  ModalContent
}