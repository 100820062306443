import React from "react";
import Message from "../../components/message";
import cardBoardCross from "../../../../public/images/cancelled-activity.png";
import styledComponents from "./styles";

const {
  WrapperImage,
  WrapperHeading,
  WrapperParagraph,
  ImageWrapper
} = styledComponents;

const RequestCancelled = ({translations}) => {
  return (
    <React.Fragment>
      <Message background={true}>
        <div>
          <WrapperImage alt="Oops" src={cardBoardCross} />
          <WrapperHeading>{translations.requestCanceled.title}</WrapperHeading>
          <WrapperParagraph>
            {translations.requestCanceled.descriptionOne}
            <br />
            {translations.requestCanceled.descriptionTwo}
          </WrapperParagraph>
        </div>
      </Message>
      <ImageWrapper>
        <img
          style={{height: 24}}
          src={`${window.env.blobUrl}/qmerit/wordmark/png/Qmerit-Wordmark-IG400.png`}
          alt="Qmerit"
        />
      </ImageWrapper>
    </React.Fragment>
  );
};

export default RequestCancelled;