import React from "react";
import { mediaMax } from "../../../../../shared/style";
import styled from "styled-components";
import icon from "../../../../../../../public/images/location-tracker.png";

export default () => (
  <Root>
    <div>
      <img src={icon} />
      <h2>Looking for work nearby?</h2>
      <p>
        Select an item to view more information. If you’re planning to be out
        and about.
      </p>
    </div>
  </Root>
);

export const Root = styled.div`
  text-align: center;
  min-height: 100%;
  display: flex;
  align-items: center;
  ${mediaMax.tablet`display:none;`} > div {
    padding: 0 16px;
  }
  img {
    margin-bottom: 20px;
  }
  h2,
  p {
    margin-top: 0;
  }
  h2 {
    margin-bottom: 16px;
    font-family: Montserrat;
    color: #3e4148;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
`;
