import React, { PureComponent } from "react";

import { apiConfig } from "../../../lib/apiConfig";
import { getToken } from "../../../lib/getUserData";
import Loader from "../../../components/Loader";
import fetchData from "../../../lib/fetchData";
import FetchFailure from "../../../components/FetchFailure";
import QuickCard from "../../../components/QuickCard";
import NoteImg from "../../../../../public/images/note-02.svg";
import ArrowText from "../../../../../public/images/icon-02.svg";
import Nearby from "../Nearby";
import CallIcon from "../../../shared/icons/matrial-icons/call";
import EmailIcon from "../../../shared/icons/matrial-icons/email";
import _ from "lodash";
import StyledComponent from "./style";
class NearbyInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      fetchedData: props.fetchedData || "",
      selectedBid: props.selectedBid.fetchedData || "",
      isLoading: false,
      fetchSuccess: false,
      fetchError: false,
      reasonSelected: "",
      otherReason: "",
      openTextArea: false,
      submitDisabled: true,
      openList: false
    };
  }

  componentDidMount() {

    this.findSurveyLink();
  }

  openListPage(value) {
    this.setState({
      openList: value
    });
  }

  findSurveyLink() {
    let taskArray = [];
    _.map(this.state.selectedBid.Project.Tasks.Tasks, (action, index) => {

      let xxx = this.filterSeqTasks(
        this.state.selectedBid.Project.Tasks.Tasks,
        taskArray
      );

    });
    // this.state.fetchedData.splice(this.state.record, 1);
    // this.openListPage(true);
  }

  filterSeqTasks(tasks, taskArray) {

    if (tasks && (!tasks.Tasks || _.isEmpty(tasks.Tasks)) && !tasks.Next) {
      taskArray.push(tasks);
      return taskArray;
    }
    taskArray.push(tasks);

    if (tasks.Next) {
      const res = this.filterSeqTasks(tasks.Next, taskArray);
    }

    if (tasks.Tasks) {
      const res = this.filterSeqTasks(tasks.Tasks, taskArray);
    }
    return taskArray;
  }

  render() {
    if (this.state.fetchError) {
      return <FetchFailure />;
    }

    if (this.state.openList) {
      return <Nearby {...this.state} />;
    }
    const {
      Root,
      Back,
      Wraper,
      Title,
      Activity,
      Row,
      Col,
      NearbyLabel,
      NearbyLabelContent,
      TimeLeft,
      Connect,
      NearbyLabelAddress,
      Button
    } = StyledComponent;
    return (
      <Root>
        <QuickCard>
          <Loader isLoading={this.state.isLoading}>
            <Back onClick={() => this.openListPage(true)}>
              <i className="far fa-arrow-left" />
              <p>BACK</p>
            </Back>

            <Wraper>
              <div>
                <Title>{this.state.selectedBid.Project.Name}</Title>
                <Activity>
                  Activity #{this.state.selectedBid.Project.Id}
                </Activity>
                <Row>
                  <Col>
                    <NearbyLabel>Due</NearbyLabel>
                    <NearbyLabelContent>
                      {this.props.localDueDate} {this.props.localDueTime}
                    </NearbyLabelContent>
                    <TimeLeft>2 days left</TimeLeft>
                  </Col>
                  <Col>
                    <NearbyLabel>Customer</NearbyLabel>
                    <NearbyLabelContent>
                      {this.state.selectedBid.Customer.Name}
                    </NearbyLabelContent>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <NearbyLabel>Contact</NearbyLabel>
                    <NearbyLabelContent>
                      {this.state.selectedBid.Customer.Name}
                    </NearbyLabelContent>
                    <NearbyLabel faclity>Facility Manager</NearbyLabel>
                    <Connect
                      call
                      href={`tel:${this.state.selectedBid.Customer.Phone}`}
                    >
                      <CallIcon />
                      {this.state.selectedBid.Customer.Phone}
                    </Connect>
                    <br />
                    <Connect
                      email
                      href={`mailto:${this.state.selectedBid.Customer.Email}`}
                    >
                      <EmailIcon />
                      {this.state.selectedBid.Customer.Email}
                    </Connect>
                  </Col>
                  <Col>
                    <NearbyLabel>Site</NearbyLabel>
                    <NearbyLabelAddress
                      href={`https://www.google.com/maps/search/?api=1&query=${
                        this.state.selectedBid.Location.Street
                      } ${this.state.selectedBid.Location.City}, ${
                        this.state.selectedBid.Location.State
                      } ${this.state.selectedBid.Location.Zip}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {this.state.selectedBid.Location.Address}
                      <br />
                      {this.state.selectedBid.Location.City},{" "}
                      {this.state.selectedBid.Location.State}{" "}
                      {this.state.selectedBid.Location.Zip}
                      <br />
                      United States
                    </NearbyLabelAddress>
                  </Col>
                </Row>
              </div>
              <div>
                <center>
                  <Button
                    onClick={() => {
                      this.findSurveyLink();
                    }}
                  >
                    Start Survey
                  </Button>
                </center>
              </div>
            </Wraper>
          </Loader>
        </QuickCard>
      </Root>
    );
  }
}

export default NearbyInfo;
