import React, { useState, Fragment } from 'react';
import commonStyles from '../../components/DesignPatternV2.0/commonStyles';
import styledComponents from './styles';
import Loader from '../../components/Loader';
import _ from 'lodash';

import ProjectInformation from '../QuotePage/sections/ProjectInformation';
import WorkeTypeInformation from '../QuotePage/sections/WorkerInformation';
import PreliminaryEstimate from '../QuotePage/sections/PreliminaryEstimate';
import PricingAssumptionsSection from '../QuotePage/sections/PricingAssumptions';
import Button from './components/Button';
import Total from './components/Total';
import useFetchData from './hooks';
import { calculateCustomerPays, disableCancelProjectButton, getDisabledButtonStyle, getDisabledButtonText, getNteForUnreviewedQuote } from './helper';
import CancelSection from './sections/CancelSection';
import ManagerDeclined from '../ManagerDeclined';
import Cancelled from './components/Cancelled';

const {
  GlobalTrackerStyles,
  Header,
  QmeritLogo, 
  H1
} = commonStyles;

const {
  Content,
  Divider,
  QuoteDetails,
  HideTotalWhenMobile,
  Footer,
  ButtonContainer
} = styledComponents;

export default function QuoteFleetReviewPage(props) {
  const [credit, setCredit] = useState(-1);
  const [customerPays, setCustomerPays] = useState(-1);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showMinError, setShowMinError] = useState(false);
  const [showMaxError, setShowMaxError] = useState(false);
  
  const {
    data,
    isLoading,
    responseStatus,
    translations,
    declineHander,
    approveHandler,
    cancelHandler
  } = useFetchData(props);

  if (responseStatus === 200 && !isLoading) {
    const dataBidList = data.BidList[0];
    const bidId = dataBidList.BidId;
    const bidStatus = dataBidList.Status;
    const categoryType = dataBidList.CategoryType;
    const needsReview = dataBidList.NeedsReview;
    const bidListSponsoredCredit = dataBidList.SponsoredCredit ? dataBidList.SponsoredCredit.Amount : 0;
    const dataRequestObj = data.Request;
    const requestId = dataRequestObj.Id;
    const userLocale = dataRequestObj.UserLocale;
    const rebates = dataRequestObj.Rebates;
    const dataProgramObj = dataRequestObj.Program;
    const customerQuoteAmountThreshold = dataRequestObj.Customer ? dataRequestObj.Customer.QuoteAmountThreshold : 0;
    const currencyType = dataProgramObj.ProgramCurrencyType;
    const programQuoteAmountThreshold = dataProgramObj.QuoteAmountThreshold ? dataProgramObj.QuoteAmountThreshold : 0;
    const dataPricingObj = data.Pricing;
    const permitCredit = dataPricingObj.PermitCredit.Amount;
    const sponsoredCredit = dataPricingObj.SponsoredCredit.Amount;
    const subtotal = dataPricingObj.Subtotal.Amount;
    const total = dataPricingObj.Total.Amount;

    if (bidStatus === 'rejected') {
      return <ManagerDeclined />;
    } else if (bidStatus === 'cancelled') {
      return <Cancelled />;
    } else {
      // handle and calculate input changes for SponsoredCredit
      const inputHandler = event => {
        const creditInput = event.target.value;
        const difference = calculateCustomerPays(subtotal, permitCredit, creditInput);
        const isMinError = creditInput < getNteForUnreviewedQuote(sponsoredCredit, programQuoteAmountThreshold, customerQuoteAmountThreshold);
        const isMaxError = creditInput > subtotal;
        setCredit(creditInput);
        setCustomerPays(difference);
        setShowMinError(isMinError); 
        setShowMaxError(isMaxError);
        setIsDisabled(isMinError || isMaxError || Number.isNaN(customerPays));
      }

      return (
        <Fragment>
          <GlobalTrackerStyles />
          <Header>
            <QmeritLogo src={`${window.env.blobUrl}/qmerit/wordmark/png/Qmerit-Wordmark-White.png`} alt="Qmerit logo" />
          </Header>
          <Content>
            <H1>{translations.fleetReviewPage.header1.frenchTitle}{categoryType} {translations.fleetReviewPage.header1.englishTitle} #{bidId}</H1>
            <ProjectInformation fetchedBidData={data} translations={translations.quotePage} />
            <Divider />
            <WorkeTypeInformation fetchedBidData={data} bidData={dataBidList} translations={translations.quotePage} />
            <Divider />
            <QuoteDetails>
              <PreliminaryEstimate 
                fetchedBidData={data} 
                bidData={dataBidList} 
                translations={translations.quotePage} 
                items={<Total 
                  userLocale={userLocale} 
                  currencyType={currencyType} 
                  subtotal={subtotal}
                  customerQuoteAmountThreshold={customerQuoteAmountThreshold}
                  programQuoteAmountThreshold={programQuoteAmountThreshold}
                  sponsoredCredit={sponsoredCredit}
                  bidListSponsoredCredit={bidListSponsoredCredit}
                  permitCredit={permitCredit}
                  total={total} 
                  customerPays={customerPays} 
                  onChange={inputHandler} 
                  outOfBounds={isDisabled} 
                  needsReview={needsReview}
                  bidStatus={bidStatus}
                  showMinError={showMinError}
                  showMaxError={showMaxError}
                  translations={translations.fleetReviewPage}
                />} 
              />
              <HideTotalWhenMobile>
                <Total 
                  userLocale={userLocale} 
                  currencyType={currencyType} 
                  subtotal={subtotal}
                  customerQuoteAmountThreshold={customerQuoteAmountThreshold}
                  programQuoteAmountThreshold={programQuoteAmountThreshold}
                  sponsoredCredit={sponsoredCredit}
                  bidListSponsoredCredit={bidListSponsoredCredit}
                  permitCredit={permitCredit}
                  total={total}
                  customerPays={customerPays} 
                  onChange={inputHandler} 
                  outOfBounds={isDisabled} 
                  needsReview={needsReview}
                  bidStatus={bidStatus}
                  showMinError={showMinError}
                  showMaxError={showMaxError}
                  translations={translations.fleetReviewPage}
                />
              </HideTotalWhenMobile>
            </QuoteDetails>
            <Divider />
            <PricingAssumptionsSection fetchedBidData={data} noRebates={(_.isEmpty(rebates) || _.isNil(rebates)) ? true : false} translations={translations.quotePage} />
            <CancelSection cancelHandler={() => cancelHandler(requestId)} disabled={disableCancelProjectButton(bidStatus)} translations={translations.fleetReviewPage} />
          </Content>
          <Footer>
            <ButtonContainer>
              {needsReview ?
                <Fragment>
                  <Button text={translations.fleetReviewPage.footer.buttonDecline} onClick={declineHander} isDisabled={isDisabled} styled={'red'} showIcon={false} />
                  <Button text={translations.fleetReviewPage.footer.buttonApprove} onClick={() => approveHandler(credit === -1 ? getNteForUnreviewedQuote(sponsoredCredit, programQuoteAmountThreshold, customerQuoteAmountThreshold) : credit)} isDisabled={isDisabled} styled={'green'} showIcon={false} />
                </Fragment>
                :
                <Button text={getDisabledButtonText(bidStatus)} isDisabled={true} styled={getDisabledButtonStyle(bidStatus)} showIcon={true} />
              }
            </ButtonContainer>
          </Footer>
        </Fragment>
      )
    }
  }

  return <Loader color={"#000000"} isLoading={isLoading} />
}