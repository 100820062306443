import React, { Component, Fragment } from "react";
import styledComponents from "./style";
import QmeritLogo from "../../../../../public/images/Qmerit-logo.svg";
import { apiConfig } from "../../../lib/apiConfig";
import * as f from "./methods";
import fetchData from "../../../lib/fetchData";
// import Tenkan from "../../../../../public/images/tenkan.png";
// import commonStyledComponents from "./CollapseData/commanStyle";

class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      showDone: false
    };
  }

  updateRequirements = body => {
    const options = {
      ...apiConfig.postRequirementsInfo,
      query: this.state.token,
      ...body
    };
    fetchData(options).then(data => {
      this.setState({ showDone: true });
      // if (data && !data.ErrorCode) {
      //   let connectedReq = this.state.fetchedData.ConnectedRequirements.slice();
      //   connectedReq.map(cp => {
      //     cp.ConnectedPrograms.map(req => {
      //       req.Requirements.map((req, index) => {
      //         if (req.Id === data.fetchedData.Id) {
      //           req = data.fetchedData;
      //         }
      //       });
      //     });
      //   });
      // } else {
      //   this.setState({ fetchError: true });
      // }
    });
  };

  // showDone = () => {
  //   this.setState({ showDone: true });
  // };

  headerWraper = (image, title, dec) => {
    const { HeaderWraper, Header, LogoWraper, Title, Dec } = styledComponents;
    return (
      <HeaderWraper>
        <Header>
          <LogoWraper>
            <img src={image} />
          </LogoWraper>
          <Title>{title}</Title>
          <Dec>{dec}</Dec>
        </Header>
      </HeaderWraper>
    );
  };

  renderOffices = () => {
    const { CardRoot } = styledComponents,
      fetchedData = this.state.fetchedData;
    return fetchedData.ConnectedRequirements.map((data, key) => {
      const { ConnectedPrograms, MinimalConnectedCorporateOffice } = data;
      let totalRequirements = 0;
      ConnectedPrograms.map(req => {
        totalRequirements += req.Requirements.length;
      });
      return (
        totalRequirements > 0 && (
          <CardRoot key={key}>
            {this.headerWraper(
              `${MinimalConnectedCorporateOffice.ImageUrl}`,
              `${MinimalConnectedCorporateOffice.DBAName}`,
              `${totalRequirements} ${
                totalRequirements == 1
                  ? "requirement needs"
                  : "requirements need"
              } updates`
            )}
            {this.renderConnectedPrograms(
              ConnectedPrograms,
              MinimalConnectedCorporateOffice
            )}
          </CardRoot>
        )
      );
    });
  };

  renderConnectedPrograms = (programs, corpOffice) => {
    const { ContentWraper } = styledComponents;
    return programs.map((req, index) => {
      return (
        req.Requirements &&
        req.Requirements.length > 0 && (
          <ContentWraper key={index}>
            {this.renderTitle(req.Name)}
            {this.renderRequirements(req.Requirements, corpOffice)}
          </ContentWraper>
        )
      );
    });
  };

  renderTitle = name => {
    const { CollapseTitle } = styledComponents;
    return (
      <CollapseTitle>
        Required for
        {/* <strong>General Requirements</strong> and */}{" "}
        <strong>{name}</strong>
      </CollapseTitle>
    );
  };

  renderRequirements = (req, corpOffice) => {
    return (
      <div>
        {req.map((val, inx) => {
          return this.requirementByStatus(val, inx, corpOffice);
        })}
      </div>
    );
  };

  requirementByStatus = (val, inx, corpOffice) => {
    const statusData = f.getStatus(val, corpOffice);
    const RequirementComponent = f.getComponent(val, statusData.status);
    return (
      <RequirementComponent
        key={inx}
        requirementData={val}
        {...this.props}
        status={statusData}
        // showDoneButton={this.showDone}
        updateRequirements={this.updateRequirements}
      />
    );
  };

  render() {
    const { DoneButton } = styledComponents;
    return (
      <React.Fragment>
        {this.renderOffices()}
        {this.state.showDone && (
          <DoneButton onClick={this.props.showSuccess}>Done</DoneButton>
        )}
      </React.Fragment>
    );
  }
}

export default Card;
