import React from "react";
import styled from "styled-components";
const renderDots = () => {
  let dots = [];
  for (let i = 0; i <= 11; i++) {
    dots.push(".");
  }
  return dots;
};
const timeRangeComponent = props => {
  return (
    <Wraper>
      <RangeBarWraper onDragOver={props.handleDragOver}>
        <RangeDots>
          {renderDots().map((value, index) => {
            return <span>{value}</span>;
          })}
        </RangeDots>
        <FillBar
          style={{
            width: `calc(${
              props.max === 12
                ? props.value == 1
                  ? 0
                  : (props.value - 1) * 9.09
                : props.value == 0
                  ? 0
                  : props.value * 1.666
            }% - 0px)`
          }}
        />
        <RangeBar
          type="range"
          min={`${props.min}`}
          max={`${props.max}`}
          step={`${props.step}`}
          value={`${props.value}`}
          onInput={props.onInput}
        />
      </RangeBarWraper>
    </Wraper>
  );
};
export default timeRangeComponent;
const Wraper = styled.div`
  position: relative;
  padding: 0px;
`;
const RangeBarWraper = styled.div`
  height: 24px;
  position: relative;
`;
const RangeDots = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 100px;
  height: 8px;
  background: #edeeef;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  span {
    line-height: 0;
    margin: -2px 2px 0;
    font-size: 20px;
    color: #b9bbc1;
  }
`;
const FillBar = styled.div`
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  /* border-radius: 0 100px 100px 0; */
  border-radius: 100px;
  height: 8px;
  background-color: #00bcd4;
  /* &::before {
    content: "";
    display: block;
    margin-left: -12px;
    left: 0;
    top: 0;
    height: 8px;
    width: 12px;
    border-radius: 100px 0 0 100px;
    background-color: #00bcd4;
    background-color: red;
  } */
`;
const RangeBar = styled.input`
  appearance: none;
  background-color: transparent !important;
  padding: 0 !important;
  outline: none;
  border-radius: 15px;
  width: 100%;
  height: 8px !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  &::-webkit-slider-thumb {
    appearance: none;
    width: 24px;
    height: 24px;
    background: #fff;
    border: 0px;
    border-radius: 50%;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.12),
      0 2px 4px 0 rgba(0, 0, 0, 0.14);
  }
`;
