import styled, { createGlobalStyle, css } from "styled-components";

const MobileModalBackground = styled.div`
  /* width: 100vw; */
  /* height: 100vh; */
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  background: #FFF;
  overflow: scroll;
`;

const MobileModalHeaderContainer = styled.div`
  width: 100%;
  height: 84px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  box-shadow: inset 0px -1px 0px #EDEEEF;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  background: #FFFFFF;
`;

const MobileModalHeaderTitle = styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 8px;
  width: 100%;
`;

const MobileModalCloseButton = styled.button`
  border: none;
  padding: 0px 5px;
  background: none;
`;

const MobileModalCloseIcon = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #B9BBC1;
`;

const MobileModalHeaderTitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;

const MobileModalHeaderTitleText = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #3E4148;
`;

// Everything rendered in this container should be dynamic
const MobileModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 16px 48px;
  gap: 24px;
  position: absolute;
  top: 88px;
  width: 100%;
`;

const MobileModalSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 100%;
`;

const MobileModalSectionHeaderText = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #3E4148;
  margin: 0px;
  margin-bottom: 8px;
`;

const RebatesSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  /* gap: ${props => props.spacing}; */
  width: 100%;
`;

// Wrap ProgramTextType
const ProgramTypeTextWrapper = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #6E727C;
  margin-top: ${props => props.spacing};
`;

const ProgramTypeText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #6E727C;
  margin: 8px 0px 0px;
`;

const Rebate = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  /* gap: 8px; */
  width: 100%;
  border: 1px solid #EDEEEF;
`;

const RebateName = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #3E4148;
  margin: 0px;
`;

const RebatePrice = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #6E727C;
  margin: 4px 0px 0px;
`;

const LearnMore = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;

  width: 103px;
  height: 20px;
  margin: 8px 0px 0px;
`;

const ScrollDisabler = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

export default {
  MobileModalBackground,
  MobileModalHeaderContainer,
  MobileModalHeaderTitle,
  MobileModalCloseButton,
  MobileModalCloseIcon,
  MobileModalHeaderTitleContainer,
  MobileModalHeaderTitleText,
  MobileModalContentContainer,
  MobileModalSection,
  MobileModalSectionHeaderText,
  RebatesSection,
  ProgramTypeTextWrapper,
  ProgramTypeText,
  Rebate,
  RebateName,
  RebatePrice,
  LearnMore,
  ScrollDisabler
}