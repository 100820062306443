import React, { Component } from "react";
import BidUpdates from "./BidUpdates";
import SPSelected from "./SPSelected";
import AcceptedBidStyles from "../styles";
import CustomerDeclined from "./CustomerDeclined";
import OfferingEstimate from "./OfferingEstimate";
import WaitingCustomer from "./WaitingOnCustomer";
import { fetchCreatingNotes } from "../../../lib/getUserData";

const { Wrapper } = AcceptedBidStyles;

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    Component BidView is rendering view based on button clicks
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
class BidView extends Component {
  state = {
    view: {}
  };

  /** Handle changes of views according to status of activity  */
  handleView = (activity, view) => {
    this.setState({
      view: { ...this.state.view, [activity.Id]: view }
    });
    if (view === "waitingCustomer") {
      const body = { Note: `Waiting on customer` };
      fetchCreatingNotes(body, activity.ParentActivityId).then(data => {
        this.props.handleStatusChange(activity.Id, {
          subTitle: "Updated!",
          status: null
        });
      });
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isWaiting && !prevState.isWaitingForCustomer) {
      nextProps.resetPreviousStatuses();
      return { view: {}, isWaitingForCustomer: true };
    }
    return {};
  }

  /** Rendering views of activity as each activity's status */
  render() {
    const view = this.state.view,
      { activity } = this.props,
      currentView = view[activity.Id] ? view[activity.Id] : "updates",
      setProps = {
        ...this.props,
        handleView: this.handleView,
        view: currentView
      };
    return (
      <Wrapper>
        {currentView === "updates" && <BidUpdates {...setProps} />}
        {currentView === "declined" && <CustomerDeclined {...setProps} />}
        {currentView === "estimate" && <OfferingEstimate {...setProps} />}
        {currentView === "spSelected" && <SPSelected {...setProps} />}
        {currentView === "waitingCustomer" && <WaitingCustomer {...setProps} />}
      </Wrapper>
    );
  }
}

export default BidView;
