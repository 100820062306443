import styled, {css} from 'styled-components';
import {
  mediaMax,
} from "../../../../shared/style"

const TitleWrapper = styled.div`
  width: 100%;
  margin: 48px 0px 16px;
  display: flex;
  justify-content: center;

  ${mediaMax.phone`
    justify-content: flex-start;
    margin: 24px 0px 16px;
  `};

  ${props =>
      props.canceled &&
      css`
         margin: 0px 0px 16px !important;
      }
  `};
`;

const StatusHistoryTitle = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0px;

  /* text/default */
  color: #3E4148;
`;

const Divider = styled.div`
  display: none;

  ${mediaMax.phone`
    display: block;
    height: 1px
    background: #DEDFE2;
    margin: 24px 0px 0px;

    ${props =>
      props.canceled &&
      css`
         display: none;
      }
  `};
  `};
`;

export default {
  TitleWrapper,
  StatusHistoryTitle,
  Divider
}