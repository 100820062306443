/**
 * Determines if object in ServicesList has IsPrepaidItem = true
 * @param {Array} arr - ServicesList
 * @returns {boolean}
 */
export function doesPrepaidItemExist(arr) {
  if (arr.length === 0) {
    return false;
  }
  return arr.some((service) => service?.IsPrepaidItem === true);
}

/**
 * Filters ServicesList for objects where IsPrepaidItem = true
 * @param {Array} arr - ServicesList
 * @returns {Array} Array of Service objects or empty array
 */
export function filterPrepaid(arr) {
  return arr.filter((service) => service?.IsPrepaidItem === true);
}

/**
 * Filters ServicesList for objects where IsPrepaidItem = false or undefined
 * @param {Array} arr - ServicesList
 * @returns {Array} Array of Service objects or empty array
 */
export function filterNonPrepaid(arr) {
  return arr.filter((service) => service?.IsPrepaidItem !== true);
}
