import React, { useEffect, useState } from "react"
import styledComponents from "./style";
import thumpUp from '../../../../public/images/thumpUp.png'
import Loader from "../../components/Loader";
import { useLocation } from 'react-router-dom';

const  {Container, RequestSubmittedTitle ,RequestSubmittedMessage, Footer} = styledComponents



export default function FinancedRequestSubmitted () {
    const [isLoading, setIsLoading] = useState(true);
    const [blobUrl, setblobUrl] = useState('');

    useEffect(() => {
        if (typeof window !== 'undefined') {
          setIsLoading(false);
          setblobUrl(window.env.blobUrl);
        }
      }, []);

    const location = useLocation();
    let data = location.state;

    if (typeof data === 'undefined') {
      data = { assigneeName: "TOTO", partnerName: "MISSES THE RAIN DOWN IN AFRICA" };
    }
    
    
    if(!isLoading) {

      return (
        <Container>
           <img alt="Oops" src={thumpUp}  />
            <RequestSubmittedTitle>Financed Request Submitted</RequestSubmittedTitle>
            {/* add assignee name and partner name to message!! */}
            <RequestSubmittedMessage>{data.assigneeName} has been confirmed to complete your installation! After the delivery date of your vehicle has been confirmed, you'll be invited to schedule installation.
            <br/>
            <br/>
            {data.partnerName} will request to see the details of your quote when confirming your vehicle financing. Please contact Qmerit for any questions regarding next steps.</RequestSubmittedMessage>            
            <Footer src={`${blobUrl}/qmerit/wordmark/png/Qmerit-Wordmark-IG400.png`}/>
        </Container>
    )
}
return (<Loader color={"#000000"} isLoading={isLoading} />)
}
