import React, { Component } from "react";
import FetchFailure from "../../../components/FetchFailure";
import NearbyInfo from "../NearbyInfo";
import StyledComponent from "./style";
import Looking from "./common/looking";
import _ from "lodash";
import ListActivities from "./common/ListActivities";
import * as cf from "./common/methods";
import { detectmob } from "./method";
import AlreadyTaken from "../../../components/AlreadyTaken";
import * as constants from "../../../../constants";

class Nearby extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      openInfo: false,
      record: "",
      surveyLink: "",
      dueDateTime: "",
      dueTime: "",
      locationDiffArray: [],
      lat: props.latitude,
      lng: props.longitude,
      dueTimeColor: "",
      locationFound: props.latitude !== "" && props.longitude !== "",
      showAlreadyTaken: false,
      locNotFound: false
    };
  }

  componentDidMount() {
    const state = this.state;
    let data = cf.locationDiffArrayForm(
      state.fetchedData,
      state.latitude !== "" ? state.latitude : constants.staticLatLng.latitude,
      state.longitude !== ""
        ? state.longitude
        : constants.staticLatLng.longitude
    );
    this.setState({ ...data });
  }

  componentDidUpdate(props, state) {
    let mobileCard = document.getElementById("mobileSearchCard");
    const { openInfo, showList } = this.state;
    if (
      mobileCard &&
      ((state.openInfo !== openInfo && openInfo) ||
        (state.showList !== showList &&
          showList &&
          state.openInfo === openInfo))
    )
      mobileCard.classList.remove("reverse");
    else if (
      mobileCard &&
      ((state.openInfo !== openInfo && openInfo) ||
        (state.showList !== showList &&
          !showList &&
          state.openInfo === openInfo))
    ) {
      mobileCard.classList.add("reverse");
    }
  }

  surveyLinkDone = success => {
    let data = cf.surveyDone(
      this.state.record,
      this.state.fetchedData,
      this.state.locationDiffArray,
      this.state.token
    );
    this.setState({
      ...data,
      showAlreadyTaken: success
        ? false
        : this.state.token !== "token=mockNearby"
          ? true
          : false
    });
  };

  backToList = () => {
    this.setState({ showAlreadyTaken: false });
  };

  componentWillReceiveProps(nextProps) {
    const check = nextProps.latitude !== "" && nextProps.longitude !== "";
    const newLat = nextProps.latitude
        ? nextProps.latitude
        : constants.staticLatLng.latitude,
      newLon = nextProps.longitude
        ? nextProps.longitude
        : constants.staticLatLng.longitude;
    let data = cf.locationDiffArrayForm(nextProps.fetchedData, newLat, newLon);
    this.setState({
      latitude: newLat,
      longitude: newLon,
      locationFound: check,
      showSearchAlert: !check,
      isLocationError: nextProps.isLocationError,
      lat: newLat,
      lng: newLon,
      ...data
    });
  }

  openInfo = value => {
    !value &&
      document.getElementById("mobileSearchCard").classList.add("reverse");
    value
      ? this.setState({
          openInfo: value
        })
      : setTimeout(() => {
          this.setState({
            openInfo: value
          });
        }, 300);
  };

  openInfoPage = data => {
    this.setState({ ...data });
  };

  showActivity = forward => {
    const { MobileSearchCard } = StyledComponent;
    return (
      <MobileSearchCard
        id="mobileSearchCard"
        card
        back="#fff"
        showActivity
        listing
      >
        <NearbyInfo
          {...forward}
          surveyLinkDone={this.surveyLinkDone}
          openInfo={this.openInfo}
        />
      </MobileSearchCard>
    );
  };

  render() {
    let {
      showList,
      locationFound,
      showSearchAlert,
      showAlreadyTaken,
      openInfo,
      fetchError,
      locNotFound,
      showFilters,
      setFilters,
      locationDiffArray
    } = this.state;
    const isMobile = detectmob();
    if (fetchError) {
      return <FetchFailure />;
    }
    let d = cf.getData({ ...this.state });

    const { Root, MapContainer, MapWrapper, DataWrapper } = StyledComponent;
    const { blured } = this.props;
    return (
      <Root blured={blured || showAlreadyTaken}>
        {
          <MapContainer>
            <MapWrapper id="mapWrapper">
              <ListActivities
                fetchedData={d.fetchedData}
                dataLength={d.dataLength}
                locationDiffArray={locationDiffArray}
                showAlreadyTaken={showAlreadyTaken}
                openInfoPage={this.openInfoPage}
              />
            </MapWrapper>
            <DataWrapper>
              <Looking />

              {openInfo && this.showActivity({ ...d.forward })}

              {showAlreadyTaken && (
                <AlreadyTaken
                  mobile={isMobile === "iOS" || isMobile === "android"}
                  backToList={this.backToList}
                />
              )}
            </DataWrapper>
          </MapContainer>
        }
      </Root>
    );
  }
}

export default Nearby;
