import styled from 'styled-components';
import {
  mediaMax,
  mediaMin
} from "../../../../shared/style"


const Sticky = styled.div`
  position: sticky;
  top: 135px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  ${mediaMax.phone`
    display: none;
  `}
`;

const TotalContainer = styled.div`

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;

  width: 100%;

  background: #FFFFFF;
  border: 1px solid #DEDFE2;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  ${mediaMax.desktop`
    display: none;
  `}; 

`;

const TotalDivider = styled.div`
  display: block;
  height: 1px;
  background: #DEDFE2;
  margin: 0px;
  width: 100%;
`;


const CancelSection = styled.div`

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 16px;
  width: 100%;

`;

export default {
  Sticky,
  TotalContainer,
  TotalDivider,
  CancelSection,
}