import React, { Component } from "react";
import _ from "lodash";
import { renderBidStatus, toCurrency, modifyDate } from "./helper";
import ImageFallBack from "../../../../components/ImageFallBack";
import { Button } from "../../../../shared/style";
import DocImage from "../../../../../../public/images/DocImage.png";
import { emailLink, phoneLink } from "../../../../lib/linkFunctions";
import StyledComponents from "./styles";

const {
  Root,
  Header,
  HeaderCorpOffice,
  Status,
  Body,
  Detail,
  Attachment
} = StyledComponents;

class BidCard extends Component {
  bidAttachment = files => {
    return files.map((file, i) => (
      <Attachment
        href={file.Url}
        target="_blank"
        rel="noopener noreferrer"
        key={i}
      >
        <img src={DocImage} alt="document thumbnail" />
      </Attachment>
    ));
  };

  render() {
    const { activity, onSelect, activityId } = this.props;
    console.log(activity);

    return (
      <Root>
        <Header>
          <HeaderCorpOffice>
            <ImageFallBack
              type="corp-logo"
              src={activity.Assignment.ImageUrl}
              name={activity.Assignment.Name}
            />
            <div className="corpOfficeStatus">
              <p>{activity.Assignment.Name}</p>
              <Status className={activity.Tasks.Status}>
                {renderBidStatus(activity)}
              </Status>
            </div>
          </HeaderCorpOffice>
          {activityId && activity.Status.Name === "Completed" && (
            <Button
              action={true}
              onClick={() => onSelect(activityId, activity.BidRequest.Id)}
            >
              Select
            </Button>
          )}
        </Header>
        <Body>
          {activity.Tasks &&
            activity.Tasks.Actions &&
            activity.Tasks.Actions[1] &&
            activity.Tasks.Actions[1].CustomDecimal1 && (
              <Detail>
                <i class="far fa-money-bill-wave-alt"></i>
                {toCurrency(activity.Tasks.Actions[1].CustomDecimal1)}
              </Detail>
            )}
          {activity.Tasks &&
            activity.Tasks.Actions &&
            activity.Tasks.Actions[0] &&
            !_.isEmpty(activity.Tasks.Actions[0].Files) && (
              <Detail>
                <i class="far fa-cloud-upload"></i>
                {this.bidAttachment(activity.Tasks.Actions[0].Files)}
              </Detail>
            )}
          {activity.Tasks &&
            activity.Tasks.Actions &&
            activity.Tasks.Actions[2] &&
            activity.Tasks.Actions[2].CustomString1 && (
              <Detail>
                <i class="far fa-sticky-note"></i>
                {activity.Tasks.Actions[2].CustomString1}
              </Detail>
            )}
          {activity.StartBy === null &&
            activity.Status.Name !== "Completed" &&
            activity.AcceptedBy !== null && (
              <Detail>
                <i class="far fa-info-circle"></i>
                <div className="waiting">Waiting for a response…</div>
              </Detail>
            )}
          {activity.StartBy !== null && activity.Status.Name !== "Completed" && (
            <Detail>
              <i class="far fa-calendar-check"></i>
              {modifyDate(activity.StartBy, activity.StartNoLaterThan)}
            </Detail>
          )}
          {activity.Assignment && activity.Assignment.Contact && (
            <Detail>
              <i class="far fa-id-card"></i>
              <div className="contact">
                {activity.Assignment.Contact.Email &&
                  emailLink(activity.Assignment.Contact.Email)}
                {activity.Assignment.Contact.PhoneNumber &&
                  phoneLink(activity.Assignment.Contact.PhoneNumber)}
              </div>
            </Detail>
          )}
        </Body>
      </Root>
    );
  }
}

export default BidCard;
