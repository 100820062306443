import React, { Fragment } from 'react'
import StyledComponents from './styles'
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';

const {
  H2
} = commonStyles;

const {
  MobileModalBackground,
  MobileModalHeaderContainer,
  MobileModalHeaderTitle,
  MobileModalCloseButton,
  MobileModalCloseIcon,
  MobileModalHeaderTitleContainer,
  MobileModalHeaderTitleText,
  MobileModalContentContainer,
  MobileModalSection,
  MobileUnorderedList,
  MobileListItem,
  ScrollDisabler
} = StyledComponents



export default function MobileModalEstimateDetails({ close, content, fetchedRequestData, translations }) {

  const dataPA = fetchedRequestData.PricingAssumptions
  const dataPE = fetchedRequestData.PricingExclusions

  return (
    <Fragment>
      <MobileModalBackground>
        {/* Header */}
        <MobileModalHeaderContainer>
          <MobileModalHeaderTitle>
            <MobileModalCloseButton onClick={close}>
              <MobileModalCloseIcon className="far fa-chevron-left" />
            </MobileModalCloseButton>
            <MobileModalHeaderTitleContainer>
              <MobileModalHeaderTitleText>{content}</MobileModalHeaderTitleText>
            </MobileModalHeaderTitleContainer>
          </MobileModalHeaderTitle>
        </MobileModalHeaderContainer>
        {/* 'Estimate Details': */}
        <MobileModalContentContainer>
          <MobileModalSection>
            <H2>{translations.pricingAssumptionsSection.titleOne}</H2>
            <MobileUnorderedList>
              {
                dataPA.map((item, index) => {
                  return (
                    <MobileListItem key={index + index + Math.random().toString(36).substring(2,9)}>{item}</MobileListItem>
                  )
                })
              }
            </MobileUnorderedList>
          </MobileModalSection>
          <MobileModalSection>
            <H2>{translations.pricingAssumptionsSection.titleTwo}</H2>
            <MobileUnorderedList>
              {
                dataPE.map((item, index) => {
                  return (
                    <MobileListItem key={index + index + Math.random().toString(36).substring(2,9)}>{item}</MobileListItem>
                  )
                })
              }
            </MobileUnorderedList>
          </MobileModalSection>
        </MobileModalContentContainer>
      </MobileModalBackground>
      <ScrollDisabler />
    </Fragment>
  )
}