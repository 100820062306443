import React, { Fragment } from 'react';
import styledComponents from './styles';
import { useHistory } from 'react-router-dom';

import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';

const {
  Label_Field,
} = commonStyles;

const {
  LaunchProjectContainer,
  LaunchProjectButton,
  LaunchProjectSuccess
} = styledComponents;

export default function LaunchProject({ fetchedRequestData, requestId, translations }) {

  const navigate = useHistory();

  function handleClick() {
    navigate.push(`/tiered-tou-deposit?token=${requestId}`);
  }

    // Create boolean variables for conditional rendering of button and deposit text components
    const projectPending = fetchedRequestData.Request.SurveyProjectId === null;
    const depositRequired = fetchedRequestData.Request.Settlement.DepositBalance.Amount > 0;

  return (
    <LaunchProjectContainer>
      {projectPending ?
        <Fragment>
          {depositRequired && <Label_Field>{translations.totalSection.total.launchProject.launchMessage} <b>{fetchedRequestData.Request.Settlement.DepositAmount.ShortFormat}</b></Label_Field> }
          <LaunchProjectButton type="button" onClick={handleClick}>{translations.totalSection.total.launchProject.buttonActive}</LaunchProjectButton>
        </Fragment>
        : 
        <Fragment>
          <LaunchProjectSuccess>
            <i className="far fa-check" />
            {translations.totalSection.total.launchProject.buttonDisabled}
          </LaunchProjectSuccess>
        </Fragment>
      }
    </LaunchProjectContainer>
  )
}
