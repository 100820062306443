import React, { useEffect, useState, useRef } from 'react';
import styledComponents from './styles';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';
import TotalRow from '../../components/TotalRow';
import AddItemButton from '../../components/AddItemButton';
import RowsMapper from '../../components/RowsMapper';
import { ToolTip } from '../../components/ToolTip';
import { Modal } from '../../components/Modal';
import { useEditRow } from '../../hooks/useEditRow';
import useDelayModalUnmount from '../../../UpfrontPricePage/components/Modal/hook';
import { getSum } from '../../helper';

const {
  P_MicroBold,
  P
} = commonStyles;

const {
  TitleContainer,
  SectionHeaderSC,
  HeavierLoadsContainer,
  HeaderContainer,
  Form,
  FlexWrapper,
  IconBox,
  ModalContent
} = styledComponents;

function foundCompletedACObject(arr) {
  return arr.filter(e => e.description === "AC" && e.total > 0);
} 

function checkObjectsAreEqual(obj1, obj2) {
  return Object.keys(obj1).length === Object.keys(obj2).length && 
      Object.keys(obj1).every(property => obj1[property] === obj2[property]);
}

function checkArraysAreEqual(arr1, arr2) {
  return arr1.length === arr2.length && 
      arr1.every((element, index) => checkObjectsAreEqual(element, arr2[index]));
}

function getACValueToUse(acDotValue1, acValue1) {
  return acValue1 ? acValue1 : acDotValue1;
}

export default function HeavierLoads({ data, setFxn, id, acValue1, setACValue1, ac, setCurrentRec, guid, acRef }) {
  const [isMounted, setIsMounted] = useState(false);
  const modalOpen = useDelayModalUnmount(isMounted, 300);
  const prevACObjsLength = useRef();
  const prevrRowsDataArr = useRef([]);

  const {
    rowsData,
    addRow,
    deleteRow,
    handleChange
  } = useEditRow(data, id, setCurrentRec, guid);

  useEffect(() => {
    const heavyTotal = getSum(rowsData, 'total');
    setFxn(heavyTotal);

    let moveTimeoutId;
    const completeACObjectsArr = foundCompletedACObject(rowsData);
    if (completeACObjectsArr.length !== 0 && prevACObjsLength.current < completeACObjectsArr.length && checkArraysAreEqual(rowsData, prevrRowsDataArr.current)) {
      setIsMounted(true); // mount modal
      setACValue1(getACValueToUse(ac.value1, acValue1) + (completeACObjectsArr.slice(-1))[0].total); // add acValue1 and most recent total in array
      moveTimeoutId = setTimeout(() => {
        setIsMounted(false);
        acRef.current.scrollIntoView({ behavior: "smooth" });
      }, 2000);
    }
    prevACObjsLength.current = completeACObjectsArr.length;
    prevrRowsDataArr.current = rowsData;

    return () =>  clearInterval(moveTimeoutId);
  }, [rowsData])

  const toggleMountModal = () => {
    setIsMounted(!isMounted);
    acRef.current.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <HeavierLoadsContainer>
      <HeaderContainer domain={`${window.env.evBlob}`}>
        <TitleContainer>
          <SectionHeaderSC>Heavier Loads</SectionHeaderSC>
          <AddItemButton onClick={() => addRow('HeavyLoad')} mobile={true}/>
        </TitleContainer>
        <div className="banner">
          <div className="bannerHeader">
            <h3>Detected by <span>Panel Recoginition Artificial Intelligence</span></h3>
            <IconBox>
              <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                <path d="M208 48a160 160 0 1 1 0 320 160 160 0 1 1 0-320zm0 368c48.8 0 93.7-16.8 129.1-44.9L471 505c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L371.1 337.1C399.2 301.7 416 256.8 416 208C416 93.1 322.9 0 208 0S0 93.1 0 208S93.1 416 208 416zM104 216v64c0 13.3 10.7 24 24 24s24-10.7 24-24l0-64c0-13.3-10.7-24-24-24s-24 10.7-24 24zm80-96V280c0 13.3 10.7 24 24 24s24-10.7 24-24V120c0-13.3-10.7-24-24-24s-24 10.7-24 24zm80 64v96c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24s-24 10.7-24 24z"
                  fill="#3963fe"
                />
              </svg>
            </IconBox>
          </div>
          <p>
            Our new panel insight is here to assist you in pre-calculating available load capacity. 
            Leverage this new resource to build more accurate quotes and to streamline the permitting process.
          </p>
        </div>
      </HeaderContainer>
      <Form role="table" aria-label="Heavier Loads">
        <div className="header" role="row">
          <div className="brokerIdColumn" role="columnheader">
            <P_MicroBold>Breaker Id</P_MicroBold>
          </div>
          <div className="descriptionColumn" role="columnheader">
            <P_MicroBold>Description</P_MicroBold>
          </div>
          <FlexWrapper>
            <div className="amps" role="columnheader">
              <P_MicroBold>Amps</P_MicroBold>
              <ToolTip>
                If unknown, amperage should be determined by multiplying the detected breaker by 80%. 
              </ToolTip>
            </div>
            <div className="iconColumn">
              {' '}
            </div>
            <div className="eighty">
              0.80
            </div>
            <div className="iconColumn">
              {' '}
            </div>
            <div className="volts" role="columnheader">
              <P_MicroBold>Volts</P_MicroBold>
            </div>
          </FlexWrapper>
          <div className="iconColumn">
            {' '}
          </div>
          <div className="totalWattsColumn" role="columnheader">
            <P_MicroBold>Total Watts</P_MicroBold>
          </div>
          <div className="iconColumn">
            {' '}
          </div>
        </div>
        <RowsMapper data={rowsData} deleteRow={deleteRow} handleChange={handleChange} />
      </Form>
      {modalOpen &&
        <Modal isMounted={isMounted} toggleMountModal={toggleMountModal} title={'Moving A/C Load'}>
          <ModalContent>
            <P>
              A/C load is not calculated in this section.
            </P>
          </ModalContent>
        </Modal>
      }
      <AddItemButton onClick={() => addRow('HeavyLoad')} mobile={false}/>
      <TotalRow label={'Subtotal'} total={getSum(rowsData, 'total')} uom={'watts'} />
    </HeavierLoadsContainer>
  )
}