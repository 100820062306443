import React, { Fragment, useState } from 'react';
import styledComponents from './styles';
import { checkIfOptionIsDefault } from '../Dropdown';

const {
  Select
} = styledComponents;

function getSelectDescription(obj1, obj2) {
  for (const key of Object.keys(obj1)) {
    if (obj1[key].value === obj2.value) {
      return obj1[key].description;
    }
  }
  return null;
}

export default function DropdownAmps({ options, defaultVal, handleDropdown }) {
  const [selectedOption, setSelectedOption] = useState(getSelectDescription(options, {value: defaultVal}));

  const handleSelection = event => {
    handleDropdown(event);
    setSelectedOption(getSelectDescription(options, {value: event.target.value}));
  }

  return (
    <Fragment>
      <Select id="heavyload" name="value" defaultValue={selectedOption} onChange={handleSelection} required>
        {options.map(amps =>
          <option key={amps.id} value={amps.description}>{amps.description}</option>
        )}
        {!checkIfOptionIsDefault(options, {description: selectedOption}) &&
          <option value={selectedOption} hidden>{selectedOption}</option>
        }
      </Select>
    </Fragment>
  )
}