import * as sd from "./setDates";
import { fetchSurveyLocations } from "../../../lib/getUserData";
import * as constants from "../../../../constants";

export function useSurveyLocations(state, lat, long, type) {
  if ((lat, long)) {
    return new Promise((resolve, reject) => {
      fetchSurveyLocations(state.token, lat, long, constants.radius)
        .then(stateObject => {
          if (stateObject) {
            const stateFetchedData = stateObject.fetchedData;
            let setData = {
              ...state,
              ...stateObject,
              fetchedData: {
                ...stateFetchedData,
                Activities: sd.setDatesInArray(stateObject.fetchedData)
              }
            };
            if (type === "custom") {
              setData = {
                ...setData,
                latitude: lat,
                longitude: long
              };
            }
            resolve(setData);
          }
        })
        .catch(err => console.log("err"));
    });
  }
}

export function checkLocationAccess() {
  return new Promise((resolve, reject) => {
    let getLoc = false;
    let data = { accessLocationAlert: true };
    if (
      window.navigator &&
      window.navigator.permissions &&
      window.navigator.permissions.query
    ) {
      window.navigator.permissions.query({ name: "geolocation" }).then(e => {
        if (e.state === "granted") {
          getLoc = true;
          data = { accessLocationAlert: false };
        } else if (e.state === "denied") {
          getLoc = true;
          data = { accessLocationAlert: false, showLocationAccessIns: true };
        } else if (e.state === "prompt") {
          data = { accessLocationAlert: true };
        }
        resolve({ getLoc, data });
      });
    } else {
      const permission = localStorage.getItem("gotLocationPermission");
      if (!(permission !== "1")) {
        getLoc = true;
        data = null;
      }
      resolve({ getLoc, data });
    }
  });
}
