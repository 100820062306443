import React, {PureComponent} from "react";
import styledComponents from "./style";
import QmeritQIndiqo from '../../../../public/images/QmeritQIndiqo.svg';
import queryString from "query-string";
import _ from "lodash";

import Wrapper from "../../components/Wrapper";
import Loader from "../../components/Loader";
import formatter from '../../lib/formatter';
import {TierUpdateSatatus} from './components/AlreadyAccepted'
import {AcceptedConfirmation} from './components/acceptedConfirmation'
import {TierUpdateFail} from '../tierManage/components/TierUpdateFail';

import tieredFile from '../../../../public/images/tieredFile.png'


class TieredBidStatus extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            token: "",
            fechedBidData: {},
            fechedBidStatus: false,
            translations: {}
        }
    }

    componentDidMount() {

        let bidGuid = this.getBidGuid(this.props.location.search)
        if (!_.isEmpty(bidGuid)) {
            this.setState({token: bidGuid})
            this.getBidByGuid(bidGuid)
           
        } 
        if (_.has(window, 'env.translations') && !_.isEmpty(window.env.translations)) {
            this.setState({
                translations: window.env.translations
            })
        }
    }

    getBidGuid = (url) => {
        return queryString.parse(url).token
    }

    getBidByGuid = (bidGuid) => {
        let optionalParams = {
            headers: {"content-type": "application/json; charset=UTF-8", "Subscription-Key": `${window.env.apiKey}`},
            method: "GET"
        }

        return new Promise((resolve, reject) => {
                      fetch(`${window.env.qmtUrl}/Bid/${bidGuid}`, optionalParams)
                      .then(res => res.json())
                      .then(data => {
                        this.setState({fechedBidData: data})
                        this.acceptBidByGuid(bidGuid)
                      })
                      .catch(error => {
                          reject({
                              isLoading: false,
                              fetchedData: { ErrorCode: 500, ...error }
                          });
                      })
        })
    }

    acceptBidByGuid = (guid) => {

        new Promise((resolve, reject) => {
            fetch(`${window.env.qmtUrl}/Bid/${guid}/AcceptCustom`, {
                headers: {"Subscription-Key": `${window.env.apiKey}`},
                method: "POST"
            })
            .then(res => {
                this.setState({fechedBidStatus: res.status, isLoading: false})
            })
            .catch(error => {

                reject({
                    isLoading: false,
                    fetchedData: { ErrorCode: 500, ...error }
                });
            })
        })


    }

    render() {

        const {
            Container,
            Header,
            HeaderH4,
            BidInfoContainer,
            LogoContainer,
            Qmerit,
            TierDescription,
            TierUpadteLink,
            TierTittle,
            RequestId,
            BidRequestDescription,
            ProjectScopeContainer,
            LocationAndDateContainer,
            LocationAndDateSection,
            LocationAndDateContainerLabel,
            LocationAndDateContainerText,
            QuestionsText,
            CustomerInfoContainer,
            CustomerInfoContainerSection,
            CustomerInfoContainerLabel,
            CustomerName,
            CustomerInfoText,
            CustomerProjectScopeLabel,
        } = styledComponents;


        const dueDate = formatter.getDateTime(this.state.fechedBidData.DueDate) || '';
        const creationDate = formatter.getDateTime(this.state.fechedBidData.CreatedOn) || '';
        const acceptedDate = formatter.getDateTime(this.state.fechedBidData.AcceptedOn) || '';

        if (this.state.fechedBidData.Status === 'Declined' || this.state.fechedBidData.Status === 'Completed') {
            return (<TierUpdateSatatus bitStatus={this.state.fechedBidData.Status === "Completed" ? "Accepted" : this.state.fechedBidData.Status === "Declined" ? "Declined" : ''}/>)
        }

        if (this.state.fechedBidData.Status === 'Cancelled') {
            return (<TierUpdateSatatus bitStatus="Cancelled" />)
        }

        if (!this.state.isLoading && this.state.fechedBidStatus === 200) {
             {/* return ( 
            <Wrapper name="" company="">
                  <Container>
                      <Header>
                          <HeaderH4>Thanks for accepting!</HeaderH4>
                      </Header>
                      <BidInfoContainer>
                        <LogoContainer>
                          <img style={{height: 40, width: 40}}  alt="Q logo" src={QmeritQIndiqo} />
                          <Qmerit>Qmerit</Qmerit>
                        </LogoContainer>
                        
                        <TierDescription>
                            <TierTittle>{`${this.state.fechedBidData.Name}`}</TierTittle>
                            <RequestId>Project #{`${this.state.fechedBidData.Id}`}</RequestId>
                            <BidRequestDescription>Your company has been requested to bid for the cost of installing an EV charging station for a customer. 
                                Be sure to check the attached Customer Project Scope for the specific details of the installation needs. 
                                You can <TierUpadteLink href={`/tier-manage?token=${this.state.token}`}>update assesment</TierUpadteLink> pricing if needed.
                            </BidRequestDescription>
                        </TierDescription>

                        <ProjectScopeContainer>
                            <a href={"https://evgeminiqa.blob.core.windows.net/surveys/22b749b3-1002-4782-b278-42580049bbcd"}>
                                <img style={{height: 120, width: 93}} src={tieredFile}  />
                            </a>
                            <CustomerProjectScopeLabel>Customer Project Scope</CustomerProjectScopeLabel>
                        </ProjectScopeContainer>

                        <CustomerInfoContainer>
                            <CustomerInfoContainerSection>
                                <CustomerInfoContainerLabel>Customer Contact</CustomerInfoContainerLabel>
                                <CustomerName>{`${this.state.fechedBidData.BidRequest.Customer.Name}`}</CustomerName>
                                <CustomerInfoText>{`${this.state.fechedBidData.BidRequest.Customer.Phone}`}</CustomerInfoText>
                                <CustomerInfoContainerLabel>Preferred Contact</CustomerInfoContainerLabel>
                                <CustomerInfoText>{`${this.state.fechedBidData.BidRequest.Customer.Email}`}</CustomerInfoText>
                            </CustomerInfoContainerSection>

                            <CustomerInfoContainerSection>
                                <CustomerInfoContainerLabel>Site</CustomerInfoContainerLabel>
                                <CustomerInfoText>{`${this.state.fechedBidData.BidRequest.Address}`}</CustomerInfoText>
                            </CustomerInfoContainerSection>
                        </CustomerInfoContainer>

                        <LocationAndDateContainer>
                            <LocationAndDateSection>
                                <LocationAndDateContainerLabel>Due</LocationAndDateContainerLabel>
                                <LocationAndDateContainerText>{`${dueDate.formattedDate}, ${dueDate.formattedTime}`}</LocationAndDateContainerText>
                                <LocationAndDateContainerLabel>Created</LocationAndDateContainerLabel>
                                <LocationAndDateContainerText>{`${creationDate.formattedDate}, ${creationDate.formattedTime}`}</LocationAndDateContainerText>
                            </LocationAndDateSection>

                            <LocationAndDateSection>
                                <LocationAndDateContainerLabel>Priority</LocationAndDateContainerLabel>
                                <LocationAndDateContainerText>{`${this.state.fechedBidData.Priority}`}</LocationAndDateContainerText>
                                <LocationAndDateContainerLabel>Accepted</LocationAndDateContainerLabel>
                                <LocationAndDateContainerText>{`${acceptedDate.formattedDate}, ${acceptedDate.formattedTime}`}</LocationAndDateContainerText>
                            </LocationAndDateSection>
                        </LocationAndDateContainer>
                        <QuestionsText>If you have any questions about this request, contact Qmerit by calling <TierUpadteLink>+1 (888) 272-0090</TierUpadteLink> or emailing <TierUpadteLink>customerservice@qmerit.com</TierUpadteLink>.</QuestionsText>
                      </BidInfoContainer>
                  </Container>
              </Wrapper>
            );*/}

            return <AcceptedConfirmation />
        }


        if (this.state.fechedBidStatus === 400 || this.state.fechedBidStatus === 404) {
            return <TierUpdateFail translations={this.state.translations}/>
        }
        

        return (<Loader color={"#000000"} isLoading={this.state.isLoading} />) 
    }
}

export default TieredBidStatus;