import React from 'react';
import styledComponents from './styles';

const {
  ButtonStyle,
  IconBox
} = styledComponents;

export default function Button({ text, onClick, isDisabled, styled, showIcon }) {
  return (
    <ButtonStyle type="button" disabled={isDisabled} onClick={onClick} styled={styled}>
      {showIcon && styled === 'green' &&
        <IconBox>
          <i className="fas fa-check" />
        </IconBox>
      }
      {text}
    </ButtonStyle>
  )
}