
import React from "react"
import styledComponents from "./style";
import HappyPhew from '../../../../../../public/images/happyface-phew.png'


const  {Container, AlreadyUpdatedTitle, AlreadyUpdatedText} = styledComponents
 export const AlreadyUpdated = () => {
    return (
        <Container>
            <img style={{"height": 70}} className="happy" alt="Oops" src={HappyPhew} />
            <AlreadyUpdatedTitle>Oops, it seems there was a mistake!</AlreadyUpdatedTitle>
            <AlreadyUpdatedText>You've already updated this request.</AlreadyUpdatedText>
        </Container>
    );
}