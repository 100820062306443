import React, { Component } from "react";
import { getToken, fetchAcceptedBids } from "../../lib/getUserData";
import { setDatesInArray } from "../ActivityStatus/Methods/setDates";
import BidsList from "../../components/BidsList";
import BidView from "./views";
import _ from "lodash";
import mock from "../../lib/mockPendingBids";
import Wrapper from "../../components/Wrapper";
import Loader from "../../components/Loader";

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    Component AcceptedBid
     - NO DEVELOPMENT NEEDED in this component EXCEPT 
       the handleView function
     - Please reach out to Brett if logic needs updating
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
export default class AcceptedBid extends Component {
  state = {
    fetchedData: undefined,
    isLoading: true,
    bidStatuses: []
  };

  /* function setBidStatus =>
      input: list of activities
      return: set's state of each bid/activity status
      ex: { 
              subTitle: "subtitle under activity name" 
              status: "success" || "warning"  (sets subTitle color)
          }
    */
  setBidStatus = activities => {
    let bidStatuses = activities.map(activity => ({
      id: activity.Id,
      subTitle: activity.Customer.Name,
      status: null
    }));
    this.setState({ bidStatuses });
  };

  /* function handleStatusChange =>
      input: activity, status
      return: set's state of each bid/activity status
      ex: { 
              subTitle: "subtitle under activity name"
              status: "success" || "warning"  (sets subTitle color)
          }
    */
  handleStatusChange = (id, header) => {
    let bidStatuses = this.state.bidStatuses.slice();
    if (id && header) {
      let indexToUpdate = _.findIndex(bidStatuses, { id: id });
      bidStatuses[indexToUpdate] = { ...header, id };
      if (_.every(bidStatuses, { finished: true })) {
        this.setState({ bidStatuses, finished: true });
      } else {
        this.setState({ bidStatuses });
      }
    }
  };

  /*
  function componentDidMount =>
      input: none
      return: sets state for AcceptBid Component
      details:
          - if url has token of mockAcceptedBids, component sets
            state with mock data
          - calls fetchAcceptedBids for data to populate the
            AcceptedBid Component
    */
  componentDidMount() {
    if (!this.state.fetchedData) {
      getToken(this.props.location.search).then(token => {
        if (token === "token=mockAcceptedBids") {
          let fetchedData = mock;
          fetchedData.Activities = setDatesInArray(mock);
          this.setState({
            isMockData: true,
            fetchedData,
            isLoading: false
          });
          this.setBidStatus(fetchedData.Activities);
        } else {
          // TODO: setup fetchAcceptedBids function once endpoint is ready
          fetchAcceptedBids(this.props.location.search).then(userData => {
            if (userData) {
              let fetchedData = userData.fetchedData;
              fetchedData.Activities = setDatesInArray(fetchedData);
              this.setState({
                fetchedData,
                isLoading: false,
                token
              });
              this.setBidStatus(fetchedData.Activities);
            }
          });
        }
      });
    }
    document.body.classList.add("bg-grey"); // TODO: find better way to set
  }

  /**  
    function resetPreviousStatuses =>
      return: sets state for resetting all previous statuses by calling setBidStatus
               which is used to set initial states.  
  */
  resetPreviousStatuses = () => {
    this.setBidStatus(this.state.fetchedData.Activities);
  };

  /**  
    function afterDone =>
      return: sets state for AcceptBid Component 
      details:
        - "isWaitingForCustomer" is set to true, by this "offering an estimate" is 
           replaced with "Waiting on customer"
  */
  afterDone = () => {
    this.setState({
      isWaitingForCustomer: true
    });
  };

  /*
        function setView =>
            input: individual activity
            - set state for updated view

        ~~ DEVELOPMENMT NEEDED HERE  ~~
    */
  setView = activity => {
    const { finished, isWaitingForCustomer } = this.state;
    const isWaiting = activity.Status.Id === "Completed";
    return (
      <BidView
        activity={activity}
        finished={finished}
        isWaiting={isWaitingForCustomer || isWaiting}
        handleStatusChange={this.handleStatusChange}
        resetPreviousStatuses={this.resetPreviousStatuses}
      />
    );
  };

  /* 
        NOTE: BidsList Component is the Parent Component for the entire feature.
              It includes the floating Card & the Collapse Component wrapper.
  */
  render() {
    const {
      fetchedData,
      bidStatuses,
      finished,
      isWaitingForCustomer
    } = this.state;
    if (fetchedData)
      return (
        <Wrapper
          name={fetchedData && fetchedData.User ? fetchedData.User.Name : ""}
          company={
            fetchedData && fetchedData.User ? fetchedData.User.CompanyName : ""
          }
          maps
        >
          <BidsList
            ptHead={0}
            setView={this.setView}
            itemHeaders={bidStatuses}
            afterDone={this.afterDone}
            activities={fetchedData.Activities}
            finished={finished && !isWaitingForCustomer}
            header="Do you have any updates on these items?"
          />
        </Wrapper>
      );

    return (
      <Wrapper name="" company="" maps>
        <Loader isLoading={true} color="#6e727c" />
      </Wrapper>
    );
  }
}
