import React, { useState } from 'react'
import styledComponents from './styles'
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';

import PreliminaryEstimateTable from '../../components/PreliminaryEstimateTable';
import MobileModalEstimateDetails from '../../components/MobileModalEstimateDetails';
import Concepts from '../../components/Concepts';

const {
  H2,
  P_MicroBold,
  P_Micro,
  ViewAllMobileButton
} = commonStyles;

const {
  PreliminaryEstimateSection,
  PreliminaryEstimeateWrapper,
  EstimateHeaderContainer,
  ItemHeader,
  AmountHeader,
  PreliminaryContainer,
  MobileAdditionalItemizedInfo,
} = styledComponents;

export default function PreliminaryEstimate({fetchedRequestData, translations}) {

  const [isMobileModalOpen, setIsMobileModalOpen] = useState({ show: false, data: null })

  const handleCloseMobileModal = () => {
    setIsMobileModalOpen({ show: false, data: null })
  }

  const handleOpenMobileModal = (props) => {
    setIsMobileModalOpen({ show: true, data: props})
  }

  return (
    <React.Fragment>
      {/* Preliminary Estimate Section */}
      <PreliminaryEstimateSection>
        <H2>{translations.preliminaryEstimateSection.title}</H2>
        <PreliminaryEstimeateWrapper>
          <EstimateHeaderContainer>
            <ItemHeader>
              <P_MicroBold>{translations.preliminaryEstimateSection.item}</P_MicroBold>
            </ItemHeader>
            <AmountHeader>
              <P_MicroBold>{translations.preliminaryEstimateSection.amount}</P_MicroBold>
            </AmountHeader>
          </EstimateHeaderContainer>
          <PreliminaryContainer>
            <PreliminaryEstimateTable fetchedRequestData={fetchedRequestData} translations={translations}/>
          </PreliminaryContainer>
        </PreliminaryEstimeateWrapper>
        {(fetchedRequestData.Request.CultureCode === "fr-CA" || fetchedRequestData.Request.CultureCode === "en-CA") && <P_Micro>{translations.preliminaryEstimateSection.canadaDisclaimer}</P_Micro>}
        {(fetchedRequestData.Request.CultureCode === "en-GB") && <P_Micro>{translations.preliminaryEstimateSection.ukDisclaimer}</P_Micro>}
      </PreliminaryEstimateSection>
      {/* Mobile View */}
      <MobileAdditionalItemizedInfo>
        <Concepts fetchedRequestData={fetchedRequestData} translations={translations}/>
        <ViewAllMobileButton onClick={() => handleOpenMobileModal(translations.preliminaryEstimateSection.modalTitle)}>{translations.preliminaryEstimateSection.button}</ViewAllMobileButton>
        {/* Mobile Modal */}
        {isMobileModalOpen.show && (isMobileModalOpen.data === translations.preliminaryEstimateSection.modalTitle) && <MobileModalEstimateDetails translations={translations} close={handleCloseMobileModal} content={isMobileModalOpen.data} fetchedRequestData={fetchedRequestData}/>}
      </MobileAdditionalItemizedInfo>
      {/* End of Mobile View */}
    </React.Fragment>
  )
}
