import React, { PureComponent } from 'react';
import _ from "lodash";
import queryString from "query-string";

import Loader from "../../components/Loader";
import ParseUrl from '../../components/ParseUrl'
import { InstallerStatus } from '../../components/InstallerStatus';
import { InstallerConfirmed } from './components/InstallerConfirmed';
import { TierUpdateFail } from '../tierManage/components/TierUpdateFail';
import TermsOfUse from './components/TermsOfUser';
import BidStatusError from '../../components/Errors';
import DepositWebApp from '../../components/DepositWebApp';

class QmeritTermsPayingWithStripe extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            installerStatus: false,
            installerConfirmed: true,
            token: "",
            fechedRequestData: {},
            bidConfirmed: false,
            acceptedBy: '',
            termsReaded: true,
            depositAmount: '',
            skipTou: true,
            proceeded: false,
            translations: {},
            userLocale: '',
            programCurrency: '',
            requestStatus: null,
            customerName: '',
            customerEmail: '',
            programTermsOfUse: '',
            country: "",
            fetchRequestStatus: false
        }
    }

    componentDidMount() {
        let bidGuid = this.getBidGuid(this.props.location.search);
        let country = queryString.parse(this.props.location.search).country;
        if (!_.isEmpty(country)) this.setState({country: country})
        // let depositParse = this.getDepositAmount(this.props.location.search);
        // this.setState({
        //     depositAmount: depositParse,
        // });

        if (_.has(window, 'env.translations') && !_.isEmpty(window.env.translations)) {
            this.setState({
                translations: window.env.translations
            })
        }

        if (!_.isEmpty(bidGuid)) {
            this.setState({ token: bidGuid })
        }

    }

    getBidGuid = (url) => {
        let x = ParseUrl(this.props.location.search, 'token');
        this.getRequest(x);

        return x;
    }

    getRequest = (guid) => {
        this.setState({ isLoading: true })

        let optionalParams = {
            headers: {
                "content-type": "application/json; charset=UTF-8",
                // "Sec-Fetch-Mode": "no-cors", 
                // "Access-Control-Request-Headers": "*",
                // "Access-Control-Request-Method": "*",
                // "Access-Control-Allow-Origin": "*"
                "Subscription-Key": `${window.env.apiKey}`
            },
            method: "GET"
        }

        new Promise((resolve, reject) => {
            fetch(`${window.env.qmtUrl}/Request/${guid}`, optionalParams)
                .then(res => {
                    this.setState({ fetchRequestStatus: res.status })
                    return res.json()
                })
                .then(data => {
                    let depositAmount = parseInt(data.Settlement.DepositBalance);
                    this.setState({
                        fechedRequestData: data,
                        programTermsOfUse: data.ProgramTermsOfUse,
                        requestStatus: data.Status,
                        depositAmount,
                        skipTou: !_.isEmpty(data.AcceptedTerms) ? true : false,
                        userLocale: data.UserLocale,
                        programCurrency: data.ProgramCurrencyType,
                        customerName: `${data.FirstName} ${data.LastName}`,
                        customerEmail: data.Email,
                        proceeded: !_.isEmpty(data.ProceededOn) && !_.isNil(data.ProceededOn)
                    })

                    if (this.state.requestStatus === 'Active') {
                        if (!_.isEmpty(data.AcceptedTerms) && _.isNull(data.ProceededOn)) {
                            let needProceed = true;
                            this.acceptRequestById(guid, needProceed);
                        }
                        else {
                            this.setState({isLoading: false})
                        }
                    } 
                    
                    if (this.state.requestStatus  === 'Cancelled') {
                        this.setState({
                            skipTou: true,
                        });
                    }
                })
                .catch(error => {
                    reject({
                        isLoading: false,
                        fetchedData: { ErrorCode: 500, ...error }
                    });
                })
        })
    }


    acceptRequestById = (guid, needProceed) => {
        this.setState({ isLoading: true })
        let tou = `${this.state.programTermsOfUse}.pdf`
        let data = { TermsOfUseUrl: tou }
        let optionalParams = {
            headers: {
                "content-type": "application/json; charset=UTF-8",
                "Sec-Fetch-Mode": "no-cors",
                "Access-Control-Request-Headers": "*",
                "Access-Control-Request-Method": "*",
                "Access-Control-Allow-Origin": "*", 
                "Subscription-Key": `${window.env.apiKey}`
            },
            body: JSON.stringify(data),
            method: "POST"
        }

        if (this.state.skipTou === false || (needProceed === true)) {

            new Promise((resolve, reject) => {
                fetch(`${window.env.qmtUrl}/Request/${this.state.token}/Proceed`, optionalParams)
                    .then(res => {
                        this.setState({ bidConfirmed: res.status, isLoading: false, acceptedBy: res.AcceptedBy })
                    })
                    .catch(error => {
                        reject({
                            isLoading: false,
                            fetchedData: { ErrorCode: 500, ...error }
                        });
                    })
            })
        }
    }

    render() {
        if (!this.state.isLoading && (this.state.bidConfirmed === 202 || this.state.proceeded) && ![400, 404, 500].includes(this.state.fetchRequestStatus)) {
            if (_.has(this.state, 'requestStatus') && !_.isNil(this.state.requestStatus)) {
                if (this.state.requestStatus === 'Active') {
                    if (this.state.depositAmount > 0) {
                        return (
                            <div>
                               <DepositWebApp token={this.state.token}/>
                            </div>
                        )
                    } else {
                        return (
                            <InstallerConfirmed translations={this.state.translations} />
                        )
                    }
                } else if (this.state.requestStatus === 'InstallationStarted') {
                    return (
                        <InstallerConfirmed translations={this.state.translations} />
                    )
                } else if (this.state.requestStatus === 'Cancelled') {
                    return (<InstallerStatus translations={this.state.translations} installerStatus="Cancelled" />);                    
                }
            } else {
                return null
            }
        }
        

        if (!this.state.isLoading && ([400, 404, 500].includes(this.state.bidConfirmed) || [400, 404, 500].includes(this.state.fetchRequestStatus))) {
            return <TierUpdateFail translations={this.state.translations} country={this.state.country}/>
        }

        if (!this.state.isLoading && (this.state.skipTou === false)) {
            return (<TermsOfUse confirmTerms={this.acceptRequestById} userLocale={this.state.userLocale} programTermsOfUse={this.state.programTermsOfUse}/>)
        }

        return (<Loader color={"#000000"} isLoading={this.state.isLoading} />)

    }
}

export default QmeritTermsPayingWithStripe;

