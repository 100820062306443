import React from "react"
import styledComponents from "./style";
import formatter from "../../../../lib/formatter";

import Banner from "../../../../../../public/images/request-accepted-banner.png"
import ProjectScopeImage from "../../../../../../public/images/Project-Scope-Img.png"
import _ from 'lodash';



const {
    MainContainer,
    CornerContainer,
    CornerName,
    CornerCompanyName,
    PoweredBy,
    ContentContainer,
    Container,
    HeroTitleContainer,
    HeroTextContainer,
    DescriptionContainer,
    DescriptionTitle,
    DescriptionSteps,
    ButtonToCore,
    Divider,
    DetailsContainer,
    DetailsTitleContainer,
    DetailsTitle,
    DetailsSubtitle,
    ProjectScopeContainer,
    ProjectScopeImg,
    ProjectScopeDetails,
    ProjectScopeTitle,
    ProjectScopeDescription,
    ProjectData,
    ProjectDetail,
    DataTitle,
    DataText,
    DataTextURL
} = styledComponents;

 export const AcceptedConfirmation = ({FetchedBidData}) => {

    const dueDate = formatter.getDateWithZTime(FetchedBidData.DueDate) || '';
    const acceptedDate = formatter.getDateWithZTime(FetchedBidData.AcceptedOn) || '';

    return (
        <MainContainer>
            <PoweredBy
                phone
                src={`${window.env.blobUrl}/qmerit/Qmerit-Wordmark-Indiqo.png`}
                alt="Qmerit"
            />
            <CornerContainer>
                <CornerName>
                    {FetchedBidData.AcceptedBy}
                </CornerName>
                <CornerCompanyName>
                    {FetchedBidData.Assignee.Name}
                </CornerCompanyName>
            </CornerContainer>
            <Container style={{backgroundImage: `url(${Banner})`}}>
                    <HeroTitleContainer>
                        Request Accepted
                    </HeroTitleContainer>
                    <HeroTextContainer>
                        You have 3 days to provide the customer a full quote.
                    </HeroTextContainer>
            </Container>
            <ContentContainer>
                <DescriptionContainer>
                    <DescriptionTitle>Once you’re ready to send the customer a quote…</DescriptionTitle>
                    <DescriptionSteps>
                        <li>Go to the Qmerit App.</li>
                        <li>Select this project under your Activities.</li>
                        <li>Fill in the required information and submit!</li>
                    </DescriptionSteps>
                </DescriptionContainer>
                <Divider />
                <DetailsContainer>
                    <DetailsTitleContainer>
                        <DetailsTitle>Project Details</DetailsTitle>
                        <DetailsSubtitle>Project #{FetchedBidData.Project.Id}</DetailsSubtitle>
                    </DetailsTitleContainer>
                    <ProjectScopeContainer>
                        <ProjectScopeImg style={{backgroundImage: `url(${ProjectScopeImage})`}} href={FetchedBidData.BidRequest.Attachments[0].URL} target="_blank"/>
                        <ProjectScopeDetails>
                            <ProjectScopeTitle href={FetchedBidData.BidRequest.Attachments[0].URL} target="_blank">Customer Project Scope</ProjectScopeTitle>
                            <ProjectScopeDescription>{FetchedBidData.Project.Tier.Description}</ProjectScopeDescription>
                        </ProjectScopeDetails>
                    </ProjectScopeContainer>
                    <ProjectData>
                        <ProjectDetail>
                            <DataTitle>Quote Not to Exceed</DataTitle>
                            <DataText style={{fontWeight: 'bold'}}>
                                {FetchedBidData.Project.Tier.Code == "CUSTOM" ? 
                                    "Custom quote required" 
                                    : 
                                    !_.isNil(FetchedBidData.Project.Tier.WholesalePrice.LongFormat) ?
                                        FetchedBidData.Project.Tier.WholesalePrice.LongFormat
                                        :
                                        `$${FetchedBidData.Project.Tier.WholesalePrice} ${FetchedBidData.ProgramCurrencyType}`                                
                                }                            
                            </DataText>                            
                        </ProjectDetail>
                        <ProjectDetail>
                            <DataTitle>Quote Due</DataTitle>
                            <DataText>{dueDate.formattedDate}, {dueDate.formattedTime}</DataText>
                        </ProjectDetail>
                    </ProjectData>
                    <ProjectData>
                        <ProjectDetail>
                            <DataTitle>Program</DataTitle>
                            <DataText>{FetchedBidData.Project.Name}</DataText>
                            <DataTextURL href={FetchedBidData.BidRequest.Attachments[0].URL} target="_blank">View program details</DataTextURL>
                        </ProjectDetail>
                        <ProjectDetail>
                            <DataTitle>Accepted</DataTitle>
                            <DataText>{acceptedDate.formattedDate}, {acceptedDate.formattedTime}</DataText>
                        </ProjectDetail>
                    </ProjectData>
                    <DetailsTitleContainer>
                        <DetailsTitle>Customer Information</DetailsTitle>
                    </DetailsTitleContainer>
                    <ProjectData>
                        <ProjectDetail>
                            <DataTitle>Customer Contact</DataTitle>
                            <DataText>{FetchedBidData.BidRequest.Customer.Name}</DataText>
                            <DataTextURL href={`tel:${FetchedBidData.BidRequest.Customer.Phone}`}>{FetchedBidData.BidRequest.Customer.Phone}</DataTextURL>
                            <DataTextURL href={`mailto:${FetchedBidData.BidRequest.Customer.Email}`}>{FetchedBidData.BidRequest.Customer.Email}</DataTextURL>
                        </ProjectDetail>
                        <ProjectDetail>
                            <DataTitle>Site</DataTitle>
                            <DataText>{FetchedBidData.BidRequest.Location.Street}</DataText>
                            <DataText style={{margin: '0px'}}>{FetchedBidData.BidRequest.Location.City}, {FetchedBidData.BidRequest.Location.State}</DataText>
                            <DataText style={{margin: '0px'}}>{FetchedBidData.BidRequest.Location.Zip}</DataText>
                        </ProjectDetail>
                    </ProjectData>
                    <DataText style={{marginTop: '48px'}}>If you have any questions, contact Qmerit by calling <a style={{color: '#3963FE'}} href={`tel:${FetchedBidData.BidRequest.CustomerServiceInfo.CustomerServicePhone}`}>{FetchedBidData.BidRequest.CustomerServiceInfo.CustomerServicePhone}</a> or emailing  <a style={{color: '#3963FE'}} href={`mailto:${FetchedBidData.BidRequest.CustomerServiceInfo.CustomerServiceEmail}`}>{FetchedBidData.BidRequest.CustomerServiceInfo.CustomerServiceEmail}</a>.</DataText>
                </DetailsContainer>
            </ContentContainer>
        </MainContainer>
    )
}