export function groupArrays(items) {
  return items.reduce((accumulator, currentObj) => {
    accumulator[currentObj.category] = [...(accumulator[currentObj.category] || []), currentObj];
    getUom(currentObj);

    if (currentObj.value1 === null || currentObj.value1 === '') {
      currentObj.value1 = 0;
    } else if (currentObj.value2 === null || currentObj.value2 === '') {
      currentObj.value2 = 0;
    }

    if (currentObj.category === 'HeavyLoad') {
      currentObj.total = getActualLoad(currentObj.value1, currentObj.value2);
    } else {
      currentObj.total = getProduct(currentObj.value1, currentObj.value2);
    }

    if (currentObj.name === '' || currentObj.value1 === 0 || currentObj.value2 === 0) {
      currentObj.newRow = true;
    }

    return accumulator;
  }, {});
}

function getUom(currentObj) {
  if (currentObj.category === 'General') {
      if (currentObj.name === 'General Lighting' || currentObj.type === 'SquareFootage') {
          currentObj.uom1 = 'sq. ft.';
      } else {
          currentObj.uom1 = 'circuits';
      }
      currentObj.uom2 = 'watts';
  } else if (currentObj.category === 'HeavyLoad') {
      currentObj.uom1 = 'amps';
      currentObj.uom2 = 'volts';
  } else {
      currentObj.uom1 = 'qty';
      currentObj.uom2 = 'watts';
  }
}

export function getGeneral(data) {
  return groupArrays(data).General;
}

export function getAppliances(data) {
  return groupArrays(data).Appliance;
}

export function getHeavyLoad(data) {
  return groupArrays(data).HeavyLoad;
}

export function getAC(data) {
  return groupArrays(data.items).AC;
}

export function getActualLoad(value1, value2) {
  return value1 * 0.8 * value2;
}

export function getProduct(value1, value2) {
  return value1 * value2;
}

export function getSum(arr, property) {
  if (!arr || arr.length === 0) {
    return 0;
  }
  let totalSum = -1;

  if (property !== undefined) {
    totalSum = arr.reduce((accumulator, currentObj) =>
      (currentObj.category === 'HeavyLoad' && currentObj.description === 'AC') ? // excludes AC line totals
      accumulator
      :
      accumulator + parseFloat(currentObj[property]), 0);
  } else {
    totalSum = arr.reduce((accumulator, current) => 
      accumulator + parseFloat(current), 0);
  }
  
  return totalSum;
}

export function formatNumber(number, userLocale = 'en-US') {
  const options = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  }; 

  return new Intl.NumberFormat(userLocale, options).format(number);
}