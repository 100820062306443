import React, { PureComponent } from "react";
import _ from "lodash";

import { getToken, fetchUserData } from "../../lib/getUserData";
import Wrapper from "../../components/Wrapper";
import Loader from "../../components/Loader";
import FetchFailure from "../../components/FetchFailure";
import formatter from "../../lib/formatter";

import BidAccept from "./BidAccept";
import BidDecline from "./BidDecline";
import BidDeclineAlready from "./BidDeclineAlready";
import QuickSubmitQuote from "./QuickSubmitQuote";
import BidAlreadySubmitted from "./BidAlreadySubmitted";
import ActionBidAccept from "./ActionBidAccept";
import CustomerDeclined from "./CustomerDeclined";
import CustomerContacted from "./CustomerContacted";
import WeSelected from "./WeSelected";
import Mock from "../../lib/mockNearby";
import Nearby from "./Nearby";
import NotFound from "../../components/NotFound";
import MockAccept from "../../lib/mockNewAccept";
import MockNewActionBidAccept from "../../lib/mockNewActionBidAccept";
import MockDecline from "../../lib/mockNewDecline";
import MockNewWeSelected from "../../lib/mockNewWeSelected";

class BidStatus extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      fetchedData: null,
      fetchError: false,
      token: undefined
    };
  }

  componentDidMount() {
    if (!this.state.fetchedData) {
      getToken(this.props.location.search).then(token => {
        if (token === "token=mockAccept") {
          const userData = MockAccept;

          this.setState({
            fetchedData: userData[0],
            ...this.setDates(userData[0])
          });
          this.setIsLoadingForArray(false);
        } else if (token === "token=mockDecline") {
          const userData = MockDecline;

          this.setState({
            fetchedData: userData[0],
            ...this.setDates(userData[0])
          });
          this.setIsLoadingForArray(false);
        } else if (token === "token=mockView") {
          const userData = MockNewActionBidAccept;
          console.log("Im mock", userData);
          this.setState({
            fetchedData: userData[0],
            ...this.setDates(userData[0])
          });
          this.setIsLoadingForArray(false);
        } else if (token === "token=mockCustomerDeclined") {
          const userData = Mock[3];
          console.log("Im mock", Mock[3]);
          this.setState({
            ...userData,
            ...this.setDates(userData.fetchedData)
          });
        } else if (token === "token=mockCustomerContacted") {
          const userData = Mock[4];
          console.log("Im mock", Mock[4]);
          this.setState({
            ...userData,
            ...this.setDates(userData.fetchedData)
          });
        } else if (token === "token=mockWeSelected") {
          const userData = MockNewWeSelected;
          console.log("Im mock", userData);
          this.setState({
            fetchedData: userData,
            ...this.setDates(userData)
          });
          this.setIsLoadingForArray(false);
        } else if (token === "token=mockNearby") {
          const userData = Mock;

          console.log("Im mock", userData);
          this.setState({ fetchedData: this.setDatesInArray(userData) });
          this.setIsLoadingForArray(false);
        } else {
          fetchUserData(this.props.location.search).then(userData => {
            if (userData) {
              this.setState({
                fetchedData: userData.fetchedData,
                ...this.setDates(userData.fetchedData),
                isLoading: false
              });
            }
            // if (userData & _.isArray(userData)) {
            //     console.log("I AM AN ARRAY", userData);
            //   this.setState({ fetchedData: this.setDatesInArray(userData) });
            //   this.setIsLoadingForArray(false);
            // }
          });
        }
        //     fetchUserData(this.props.location.search).then( userData => {
        //       if(userData) {
        //         this.setState({...userData, ...this.setDates(userData.fetchedData)})
        //       }
        //     })
      });
    }
  }

  setIsLoadingForArray(value) {
    if (this.state.fetchedData) {
      this.setState({
        isLoading: value
      });
    }
  }
  setDates(fetchedData) {
    if (fetchedData && fetchedData.Activities) {
      const dueDate = formatter.getDateTime(
        fetchedData.Activities[0] && fetchedData.Activities[0].DueDate
          ? fetchedData.Activities[0].DueDate
          : null
      );
      const createdOnDate = formatter.getDateTime(
        fetchedData.Activities[0] && fetchedData.Activities[0].CreatedOn
          ? fetchedData.Activities[0].CreatedOn
          : null
      );
      const AcceptedOnDate = formatter.getDateTime(
        _.has(fetchedData.Activities[0], "AcceptedBy.AcceptedOn")
          ? fetchedData.Activities[0].AcceptedBy.AcceptedOn
          : null
      );
      const DeclinedOnDate = formatter.getDateTime(
        _.has(fetchedData.Activities[0], "DeclinedBy.DeclinedOn")
          ? fetchedData.Activities[0].DeclinedBy.DeclinedOn
          : null
      );

      return {
        localDueDate: dueDate.formattedDate,
        localDueTime: dueDate.formattedTime,
        localCreatedDate: createdOnDate.formattedDate,
        localCreatedTime: createdOnDate.formattedTime,
        localAcceptedDate: AcceptedOnDate.formattedDate,
        localAcceptedTime: AcceptedOnDate.formattedTime,
        localDeclinedDate: DeclinedOnDate.formattedDate,
        localDeclinedTime: DeclinedOnDate.formattedTime,
        localDeclineDateMessage: `${DeclinedOnDate.formattedDate}, ${
          DeclinedOnDate.formattedTime
        }`
      };
    }
    return;
  }

  setDatesInArray(userData) {
    let updatedBidDates = [];
    let arrayedTime = _.map(userData, (bid, index) => {
      const dueDate = formatter.getDateTime(
        userData.Project && userData.Project.DueDate
          ? userData.Project.DueDate
          : null
      );
      const createdOnDate = formatter.getDateTime(
        userData.Project && userData.Project.CreatedOn
          ? userData.Project.CreatedOn
          : null
      );
      const AcceptedOnDate = formatter.getDateTime(
        _.has(userData, "AcceptedBy.AcceptedOn")
          ? userData.AcceptedBy.AcceptedOn
          : null
      );
      const DeclinedOnDate = formatter.getDateTime(
        _.has(userData, "DeclinedBy.DeclinedOn")
          ? userData.DeclinedBy.DeclinedOn
          : null
      );

      const converts = {
        localDueDate: dueDate.formattedDate,
        localDueTime: dueDate.formattedTime,
        localCreatedDate: createdOnDate.formattedDate,
        localCreatedTime: createdOnDate.formattedTime,
        localAcceptedDate: AcceptedOnDate.formattedDate,
        localAcceptedTime: AcceptedOnDate.formattedTime,
        localDeclinedDate: DeclinedOnDate.formattedDate,
        localDeclinedTime: DeclinedOnDate.formattedTime,
        localDeclineDateMessage: `${DeclinedOnDate.formattedDate}, ${
          DeclinedOnDate.formattedTime
        }`
      };
      const resultArray = Object.assign(userData[index], converts);
      updatedBidDates.push(resultArray);
    });

    return updatedBidDates;
  }

  getChildComponent() {
    if (
      !this.state.isLoading &&
      !this.state.fetchError &&
      this.state.fetchedData
    ) {
      if (this.state.fetchedData.Activities[0].Status.Id === "New") {
        if (this.state.fetchedData.Type === "Accept") {
          return BidAccept;
        } else if (this.state.fetchedData.Type === "Decline") {
          return BidDecline;
        } else if (this.state.fetchedData.Type === "Submit") {
          return QuickSubmitQuote;
        } else if (this.state.fetchedData.Type === "ViewDetail") {
          return ActionBidAccept;
        } else if (this.state.fetchedData.Type === "CustomerDeclined") {
          return CustomerDeclined;
        } else if (this.state.fetchedData.Type === "CustomerContacted") {
          return CustomerContacted;
        } else if (this.state.fetchedData.Type === "WeSelected") {
          return WeSelected;
        }
      } else if (
        this.state.fetchedData.Activities[0].Status.Id === "InProgress"
      ) {
        if (this.state.fetchedData.Type === "Submit") {
          return QuickSubmitQuote;
        }
        if (this.state.fetchedData.Type === "Accept") {
          return BidAccept;
        }
        if (this.state.fetchedData.Type === "Decline" || this.state.fetchedData.Type === "ViewDetail") {
          this.setState({
            token: this.state.fetchedData.OperationGuids.Accept
          });
          return BidAccept;
        }
        if (this.state.fetchedData.Type === "WeSelected") {
          return WeSelected;
        }
      } else if (
        this.state.fetchedData.Activities[0].Status.Id === "Declined"
      ) {
        return BidDeclineAlready;
      } else if (
        this.state.fetchedData.Activities[0].Status.Id === "Completed"
      ) {
        return BidAlreadySubmitted;
      }
      return NotFound;
    }
  }

  render() {
    const gotGoodData =
      !this.state.isLoading && !this.state.fetchError && this.state.fetchedData
        ? true
        : false;
    const BidStatusChild = this.getChildComponent();
    if (this.state.fetchError) {
      return (
        <Wrapper name="" company="">
          <FetchFailure />
        </Wrapper>
      );
    }
    console.log(gotGoodData)
    if (gotGoodData) {
      return (
        <Wrapper
          name={
            this.state.fetchedData && this.state.fetchedData.User
              ? this.state.fetchedData.User.Name
              : ""
          }
          company={
            this.state.fetchedData && this.state.fetchedData.User
              ? this.state.fetchedData.User.CompanyName
              : ""
          }
        >
          <BidStatusChild {...this.state} />
        </Wrapper>
      );
    }

    return (
      <Wrapper name="" company="">
        <Loader isLoading={true} />
      </Wrapper>
    );
  }
}

export default BidStatus;
