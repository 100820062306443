import styled from 'styled-components';
import { mediaMax, mediaMin } from '../../../../shared/style';

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  align-self: stretch; 

  ${mediaMax.tablet`
    flex-direction: column;
    padding: 8px;
    gap: 8px;
    border-radius: 8px;
    background: var(--ig-ig-10, #F4F5F6);
  `}
`;

export default {
  FormRow
}