import React from "react";
import styledComponents from "./style";
const {
  Title,
  QuoteDollar,
  QuoteErrorContainer,
  TotalEstimatedWraper,
  TotalEstimatedCostBtnWraper,
  TotalEstimatedCostInputWraper
} = styledComponents;

export default props => {
  const {
    value,
    handleChange,
    showIntegerError,
    placeholder = "0.00",
    costLabel = "Total Estimated Cost"
  } = props;
  return (
    <div>
      <Title>{costLabel}</Title>
      <TotalEstimatedWraper error={showIntegerError}>
        <TotalEstimatedCostInputWraper>
          <QuoteDollar>$</QuoteDollar>
          <input
            id="quoteDecimal"
            value={value || ""}
            onChange={handleChange}
            placeholder={placeholder || ""}
          />
        </TotalEstimatedCostInputWraper>
        <TotalEstimatedCostBtnWraper error={showIntegerError}>
          <button disabled>USD</button>
        </TotalEstimatedCostBtnWraper>
      </TotalEstimatedWraper>
      {showIntegerError && (
        <QuoteErrorContainer>
          <span>
            <i className="fal fa-info-circle" />
          </span>
          <div>Please enter a valid amount.</div>
        </QuoteErrorContainer>
      )}
    </div>
  );
};
