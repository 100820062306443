import styled, { createGlobalStyle, css } from "styled-components"
import { mediaMax, mediaMin } from "../../../../shared/style"

const GlobalDraftStyles = createGlobalStyle`
    #root {
        padding: 0px !important;

        @media (max-width: 600px) {
            flex-direction: column;
        }
    }
`;

const MainWrapper = styled.div`
   
    @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;700;900&family=Red+Hat+Display:wght@400;500;700&display=swap');

    & p, a, li, span, td, th, button {
    font-family: "Mulish", sans-serif;
    }

    & h1, h2, h3 {
    font-family: "Red Hat Display", sans-serif;
    }

    background: #ffffff !important;
    padding: 0px !important;
    width: 100%;
  
`;

const Header = styled.div`
    display: flex;
    width: 100%;
    height: 64px;
    padding: 12px 24px;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-bottom: 1px solid var(--border-light-decorative, #E1E3E9);
    background: var(--Background-Page-Default, #FFF);
`;

const PoweredBy = styled.img`
    width: 108.11px;
    height: 32px;
`;

const CalloutWrapper = styled.div`
    display: flex;
    width: 100%;
    height: calc(100vh - 64px - 73px);
    justify-content: center;
    align-items: center;

    @media (max-width: 600px) {
        height: calc(100vh - 64px - 121px);
    }
`;

const Callout = styled.div`
    display: flex;
    width: 480px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
`;

const Message = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    align-self: stretch;
`;

const MessageTitle = styled.h2`
    color: var(--Text-Primary, #000);
    text-align: center;
    font-family: "Red Hat Display";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
    margin: 0px;
`;

const MessageContent = styled.p`
    color: var(--Text-Primary-Light, #3C3E49);
    text-align: center;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
    margin: 0px;
`;

const Icon = styled.i`
    display: flex;
    width: 64px;
    height: 64px;
    justify-content: center;
    align-items: center;
    color: var(--Icon-Primary, #2F55FE);
    text-align: center;

    /* Icon/Large ++/Light */
    font-family: "Font Awesome 6 Pro";
    font-size: 54px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%; /* 54px */
`;

const Footer = styled.div`
    display: flex;
    width: 100%;
    padding: 16px 24px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-top: 1px solid var(--border-light-decorative, #E1E3E9);
    background: var(--Background-Page-Default, #FFF);
`;

const Support = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    @media (max-width: 600px) {
        flex-direction: column;
    }
`;

const SecondaryText = styled.p`
    color: var(--Text-Secondary, #6C6F7F);
    text-align: right;
    font-family: Mulish;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    margin: 0px;
`;

const SupportAnchor = styled.a`
    color: var(--Text-Secondary, #6C6F7F);
    text-align: right;
    font-family: Mulish;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`;

export default {
    GlobalDraftStyles,
    MainWrapper,
    Header,
    PoweredBy,
    CalloutWrapper,
    Callout,
    Message,
    MessageTitle,
    MessageContent,
    Icon,
    Footer,
    Support,
    SecondaryText,
    SupportAnchor
}