import styled from 'styled-components';
import { mediaMax, mediaMin } from '../../../../shared/style';

const AddItem = styled.button`
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 4px #FFF;
  background: #FFF;
  color: #3963FE;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: 16px;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    background: #E4E8FF;
  }

  ${mediaMax.tablet`
    ${props => props.mobile == true ? `display: flex; padding: 0px;` : `display: none`}
  `} 
  ${mediaMin.tablet`
    ${props => props.mobile && `display: none`}
  `}
`;

const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;

  i {
    color: #3963FE;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
`;

export default {
  AddItem,
  IconBox
}