import styled, { createGlobalStyle, css } from "styled-components";

const ModalBackground = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.0);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  transform: scale(1);
  animation: ${({ animate }) => {
    switch (animate) {
      case 'in':
        return 'fadeIn .3s ease-in forwards';
      case 'out':
        return 'fadeOut .3s ease-in forwards';
      default:
        return 'fadeIn .3s ease-in forwards';
    }
  }};
  @keyframes fadeIn {
    0% { background: rgba(0, 0, 0, 0.0); }
    100% { background: rgba(0, 0, 0, 0.5); }
  }

  @keyframes fadeOut {
    0% { background: rgba(0, 0, 0, 0.5); }
    100% { background: rgba(0, 0, 0, 0.0); }
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  gap: 8px;
  width: 800px;
  max-width: 90%;
  background: #FFFFFF;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 8px;

  opacity:0;
  animation: ${({ animate }) => {
    switch (animate) {
      case 'in':
        return 'animateUp .3s ease-in forwards';
      case 'out':
        return 'animateDown .3s ease-in forwards';
      default:
        return 'animateUp .3s ease-in forwards';
    }
  }};
  @keyframes animateUp {
    0% {
      transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  
  @keyframes animateDown {
    0% {
      transform: translateY(0px);
      opacity: 1;
    }
    100% {
      transform: translateY(1000px);
      opacity: 0;
    }  
  }
`;

const ModalTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 24px;

  width: 100%;
  height: 32px;

  justify-content: space-between;
`;

const ModalCloseButton = styled.button`
  border: none;
  padding: 0px 5px;
  background: none;
  cursor: pointer;
`;

const ModalCloseIcon = styled.i`
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #B9BBC1;
`;

const ModalListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  width: 100%;
  max-height: 496px;
  padding-right: ${props => props.padding};
  overflow-y: auto;
  overflow-x: hidden;

  /* Fancy scrollbar */
  /// ::-webkit-scrollbar {
  //  width: 12px;
  //  }
 
  /* Track */
  /* ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2); 
  } */
  
  /* Handle */
  /* ::-webkit-scrollbar-thumb {
      background: rgba(0,0,0,0.3); 
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  }
  ::-webkit-scrollbar-thumb:window-inactive {
  	background: rgba(0,0,0,0.3); 
  } */

  /* Hide scrollbar and allow scrolling */
  // -ms-overflow-style: none; /* for Internet Explorer, Edge */
  // scrollbar-width: none !important; /* for Firefox */
  // overflow: auto; 

  // &::-webkit-scrollbar {
  //   display: none !important; /* for Chrome, Safari, and Opera */
  // }     
`;

const Header2 = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin: 0px;
`;

const RebatesSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  /* gap: ${props => props.spacing}; */
  width: 100%;
`;

// Wrap ProgramTextType
const ProgramTypeTextWrapper = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #6E727C;
  margin: ${props => props.spacing};
`;

const ProgramTypeText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #6E727C;
  margin: 8px 0px 0px;
`;

const Rebate = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  /* gap: 8px; */
  width: 100%;
  border: 1px solid #EDEEEF;
`;

const RebateName = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #3E4148;
  margin: 0px;
`;

const RebatePrice = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #6E727C;
  margin: 4px 0px 0px;
`;

const LearnMore = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;

  width: 103px;
  height: 20px;
  margin: 8px 0px 0px;
`;

const ScrollDisabler = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

export default {
  ModalBackground,
  ModalContainer,
  ModalTitle,
  ModalCloseButton,
  ModalCloseIcon,
  ModalListContainer,
  Header2,
  RebatesSection,
  ProgramTypeTextWrapper,
  ProgramTypeText,
  Rebate,
  RebateName,
  RebatePrice,
  LearnMore,
  ScrollDisabler
}