import styled from "styled-components";

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const RadioContainer = styled.div`
    width: 100%
    margin: 16px 0;
    p {
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0.25px;
        line-height: 20px;
        margin: 0 0 8px;
    }
`

export default {
    CardContainer,
    RadioContainer,
};
