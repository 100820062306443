import React from "react";
import Radio from "../../../../../../components/Radio";

export const radioData = [
  { id: 1, label: "Activity completed!" },
  { id: 2, label: "Activity still in progress" }
];

/** Component RenderRadios renders radio buttons for get status updates in "i was selected" flow*/
export default ({ onChange, activity }) => {
  return radioData.map((data, inx) => {
    return (
      <Radio
        key={inx}
        label={data.label}
        onChange={onChange}
        id={`${activity.Id}-${inx}`}
        name={"reason" + activity.Id}
      />
    );
  });
};
