import styled, { css } from "styled-components";
import { theme, mediaMax } from "../../../shared/style";
import { Article, Section } from "../../../components/QuickCard";

const Root = styled.div`
  ${Article} {
    ${props =>
      props.notFiend &&
      css`
        min-height: 300px;
      `};
  }
  ${Section} {
    ${mediaMax.phone`padding-top:0`};
  }
`;

const Wraper = styled.div`
  ${mediaMax.phone`min-height: calc(100vh - 84px);`};
`;
const NotFind = styled.div`
  text-align: center;
  ${mediaMax.phone`
display: flex;
height: calc(100vh - 112px - 16px - 50px);
align-items: center;
`} > div {
    margin: 0 auto;
  }
  i {
    font-size: 64px;
    line-height: 1.13;
    margin-bottom: 24px;
    color: ${theme.IndiqoGrey[400]};
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    color: rgba(62, 65, 72, 0.7);
    max-width: 328px;
    margin: 0 auto;
  }
`;
const TotalItems = styled.h2`
  margin-top: 0;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  color: ${theme.IndiqoGrey[800]};
  ${mediaMax.phone`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
  color: ${theme.IndiqoGrey[600]};
  margin: 0 -16px;
  padding: 12px 16px;
  box-shadow: inset 0 -1px 0 0 ${theme.IndiqoGrey[300]};
`};
`;
const Items = styled.div``;

const Icon = styled.div`
  flex: 0 0 52px;
  max-width: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    &[class*="clipboard"] {
      color: ${theme.Primary.dark};
    }
    &[class*="user"] {
      color: ${theme.IndiqoGrey[500]};
    }
    &[class*="invoice"] {
      color: ${theme.Primary.main};
    }
  }
`;

const Info = styled.div`
  flex: 0 0 calc(100% - 52px);
  max-width: calc(100% - 52px);
  display: flex;
  align-items: center;
  padding: 12px 0;
`;
const Item = styled.div`
  display: flex;
  flex-wrap: wrap;
  &:not(:nth-last-child(1)) {
    ${Info} {
      box-shadow: inset 0 -1px 0 0 ${theme.IndiqoGrey[300]};
    }
  }
  ${mediaMax.phone`margin-left: -16px;`};
`;

const InfoWraper = styled.div`
  width: 100%;
`;

const Holder = styled.div`
  display: flex;
  justify-content: space-between;
`;
const noMargin = css`
  margin: 0;
`;
const Name = styled.h3`
  ${noMargin};
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${theme.IndiqoGrey[800]};
`;

const Mi = styled.p`
  ${noMargin};
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33;
  color: ${theme.IndiqoGrey[800]};
  transform: translateY(2px);
`;

const Ca = styled.h4`
  ${noMargin};
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
  color: ${theme.IndiqoGrey[600]};
`;

const Time = styled.p`
  ${noMargin};
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33;
  color: ${theme.IndiqoGrey[600]};
  transform: translateY(2px);
`;

export default {
  Root,
  Wraper,
  NotFind,
  TotalItems,
  Items,
  Icon,
  Info,
  Item,
  InfoWraper,
  Holder,
  Name,
  Mi,
  Ca,
  Time
};
