import styled, { css } from "styled-components";
import {
  theme,
  primaryButton,
  mediaMax,
  mediaMin
} from "../../../shared/style";
import { Article, Section } from "../../../components/QuickCard";

const gap = 24;

const noMargin = css`
  margin: 0;
`;

const anchorHover = css`
  cursor: pointer;
  transition: 0.3 opacity;
  &:hover {
    opacity: 0.7;
  }
`;

const Root = styled.div`
  ${Section} {
    ${mediaMax.phone`
   padding-top:0;
   position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
`};
  }
  ${Article} {
    ${mediaMax.phone`
        background-color: transparent;
        box-shadow: none;
        margin-top: 0px;
    `};
  }
`;
const Wraper = styled.div`
  padding-bottom: 80px;
 ${mediaMin.tablet`
  padding: 0 16px 80px;
  overflow: auto;
  border-radius: 8px;
  height: 100%;
 `}
  /* ${mediaMax.phone`
    min-height: calc(100vh  - 112px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top:16px;
    padding-bottom: 100px;
    position:relative;
    &::before{
        content:'';
        position:absolute;
        top:0;
        left:-16px;
        right:-16px;
        bottom:-16px;
        background-color:#fff;
        z-index:-1;
    }
`}; */
`;
const Back = styled.div`
  display: flex;

  align-items: center;

  button {
    transition: 0.3s opacity;
    cursor: pointer;
    padding: 0;
    border: 0;
    background: transparent;
    display: flex;
    align-items: center;
    &:hover {
      opacity: 0.7;
    }
  }
  i {
    color: #6e727c;
    font-size: 16px;
    line-height: 1.25;
    margin-right: 8px;
  }

  p {
    margin: 0;
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 0.5px;
    color: #6e727c;
    ${mediaMax.phone`color:#fff`};
  }
`;

const Title = styled.h2`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  ${noMargin};
  font-family: "Montserrat", cursive;
  font-size: 18px;
  font-weight: bold;
  color: #3e4148;
  position: sticky;
  padding-top: 16px;
  z-index: 2;
  ${mediaMin.phone`
    top: 0;
    padding-left:16px;
    padding-right:16px;
  
    margin-left:-16px;
    margin-right:-16px;
    margin-bottom:0;
`};
  ${mediaMin.tablet`
    background: #fff;
    padding-bottom: 16px;
`};
  ${({ textMove }) =>
    textMove &&
    css`
      ${mediaMax.tablet`padding-left:0;margin-left: 26px`};
    `};
  ${mediaMax.tablet`
    position: sticky;
    top: 2px;
    z-index: 99999;`};
  ${mediaMax.phone`font-size: 16px;  top: 38px;`};
  .main-wrap {
    ${mediaMax.tablet`width: 100%;`};
  }
  .text-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  i {
    cursor: pointer;
    font-size: 16px;
    height: 20px;
    width: 20px;
    height: 20px;
    width: 20px;
    text-align: center;
    line-height: 20px;
  }
`;

const Activity = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33;
  color: ${theme.IndiqoGrey[600]};
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Col = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: ${gap - 2}px;
  ${mediaMax.phone`
    flex: 0 0 100%;
    max-width: 100%;`};
`;
const ContentCard = styled.div`
  margin-bottom: 24px;
  ${({ mt }) =>
    mt &&
    css`
      margin-top: ${mt}px;
    `};
  ${({ onDesktop }) => onDesktop && mediaMax.tablet`display:none;`};
  ${({ onMobile }) => onMobile && mediaMin.tablet`display:none;`};
  ${({ dateWrapper }) =>
    dateWrapper &&
    css`
      padding-left: 49px;
    `};
`;

const NearbyLabel = styled.h4`
  ${noMargin};
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: 0.25px;
  color: ${theme.IndiqoGrey[600]};
  ${({ italic }) =>
    italic &&
    css`
      font-style: italic;
    `};
  ${({ faclity }) =>
    faclity &&
    css`
      margin-top: 3px;
    `};
`;

const Caption = styled.div`
  backdrop-filter: blur(8px);
  position: relative;

  max-width: 343px;
  ${noMargin};
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: 0.5px;
  background: rgba(0, 0, 0, 0.5);
  padding: 8px 0 8px 8px;
  text-align: left;
  color: ${theme.White};
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  ${props =>
    props.faclity &&
    css`
      margin-top: 3px;
    `};
  ${mediaMax.phone`
  margin-bottom: 11px;
  `};
  h4 {
    margin: 0;
  }
  i {
    position: absolute;
    right: 15px;
    top: 10px;
    font-size: 13.4px;
  }
`;
const Servey = styled.div`
  position: absolute;
  bottom: 16px;
  /* width: 100%; */
  /* left: 0; */
  left: 16px;
  right: 16px;
  ${mediaMax.tablet`position: fixed;`};
  @media (width: 414px) and( height:896px) {
    bottom: 32px !important;
  }
  @media (width: 375px) and (height: 812px) {
    bottom: 32px !important;
  }
`;

const content = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  -webkit-font-smoothing: antialiased;
`;

const NearbyLabelContent = styled.h3`
  ${noMargin};
  ${content};
  color: ${theme.IndiqoGrey[800]};
  letter-spacing: 0.5px;
`;

const NearbyLabelAddress = styled.a`
  ${noMargin};
  ${content};
  ${anchorHover};
  color: ${theme.Primary.main};
`;
const DateCard = styled.div`
  width: 66px;
  text-align: center;
  width: 66px;
  .date-card {
    margin-bottom: 8px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.14),
      0px 2px 2px 0px rgba(0, 0, 0, 0.12), 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  }
  .date-month {
    padding: 4px 0;
    margin: 0;
    text-transform: uppercase;
    border-radius: 4px 4px 0 0;
    background: linear-gradient(-180deg, #8bc34a 0%, #7cb342 100%);
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.25px;
    line-height: 16px;
  }
  .date-day {
    margin: 0;
    padding: 4px 0;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
  }
  .date-time {
    margin: 0;
    color: #3e4148;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
`;
const TimeLeft = styled.p`
  ${noMargin};
  font-size: 12px;
  font-weight: 400;
  line-height: 1.43;
  -webkit-font-smoothing: antialiased;
  color: ${props => props.dueTime};
  ${mediaMax.tablet`font-size: 14px;`};
`;
const Connect = styled.a`
  ${anchorHover};
  margin-top: 3px;
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  color: #00bcd4;
  svg {
    ${props =>
      props.call &&
      css`
        width: 21.33px;
        height: auto;
        margin-right: 8px;
      `};
    ${props =>
      props.email &&
      css`
        width: 19.21px;
        height: auto;
        margin-right: 9px;
      `};
  }
`;

const ButtonWrapper = styled.div`
  padding: 0 16px;

  ${mediaMax.tablet` max-width: 335px;
   margin: auto;`};
`;

const Button = styled.button`
  ${primaryButton};
  text-transform: uppercase;
  min-height: 40px;
  min-width: 200px;
  width:100%;
  /* ${mediaMax.phone`max-width:303px;`}; */
`;
const CommonButtonWrapper = styled.div`
  display: flex;
  button {
    flex-grow: 1;
  }
`;
const AttachmentWraper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: -24px;
  .attachment-container {
    width: 120px;
    margin-right: 16px;
    margin-top: 24px;
  }
  .attachment {
    background: #fafafa;
    width: 120px;
    height: 120px;
    position: relative;
    z-index: 0;
  }
  .attachment-img {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: -webkit-fill-available;
    max-height: -webkit-fill-available;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.14),
      0px 2px 2px 0px rgba(0, 0, 0, 0.12), 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  }
  .attachment-text {
    margin: 8px 0 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #3e4148;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  }
`;
export default {
  Root,
  Back,
  Wraper,
  Title,
  Activity,
  Row,
  Col,
  ContentCard,
  NearbyLabel,
  NearbyLabelContent,
  TimeLeft,
  Connect,
  NearbyLabelAddress,
  ButtonWrapper,
  Button,
  Caption,
  Servey,
  DateCard,
  CommonButtonWrapper,
  AttachmentWraper
};
