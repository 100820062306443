import React from 'react';
import StyledComponents from './styles';

const {
  Card,
  Title,
  TitleBox,
  IconBox,
  Details
} = StyledComponents;

export default function ProjectCancelledCard({ requestCancelledOnDate, translations, show }) {
  // translations = projectStatusTracker.statusScreen.completedStatus.projectCancelled
  const {
    title,
    dateTimeSeparator,
    descriptionOne,
    descriptionTwo,
    descriptionThree
  } = translations;

  if(!show) return null;
  return (
    <Card>
      <Title>
        <TitleBox>
          <IconBox>
            <i className="far fa-times-circle" />
          </IconBox>
          <h3>{title}</h3>
        </TitleBox>
        <p className='timeStamp'>
          {requestCancelledOnDate.formattedDate} {dateTimeSeparator} {requestCancelledOnDate.formattedTime}
        </p>
      </Title>
      <Details>
        <p>{descriptionOne}{descriptionTwo}</p>
        <br/>
        <p>{descriptionThree}</p>
      </Details>
    </Card>
  )
}