
import React, { useState } from "react";
import styledComponents from "./style";
import formatter from "../../../../lib/formatter";
import _ from "lodash";

const { Container, CardContainer, Card, Header, QuoteInfo, QuoteDetails, QuoteDetailsHeader, Button, PoweredBy } = styledComponents
export const TierAcceptConfirm = (props) => {
    const [showDetails, setShowDetails] = useState(props.hasRevision === true ? true : false);
    const modDate = props.bidData.Settlement.RevisedOn ? formatter.getIntlFormattedDateTime(props.bidData.Settlement.RevisedOn, props.browserLanguage) : '';
    const hasDeposit = _.has(props, 'bidData.Settlement.Deposit') && props.bidData.Settlement.Deposit > 0 ? true : false;
    let fullySponsored = !_.isNil(props.bidData.ProgramFullySponsoredThreshold);

    function openDetails(value) {
        setShowDetails(value);
    }

    return (
        <Container>
            <CardContainer>
                <Card>
                    <Header>{props.translations.confirmQuote.title}</Header>
                    <QuoteInfo>
                        <ul>
                            <li><label>{props.translations.confirmQuote.labelOne}</label></li>
                            <li>{props.bidData.Assignee.Name ? props.bidData.Assignee.Name : ''}</li>
                            <li><a href={`tel:${props.bidData.Assignee.Phone ? props.bidData.Assignee.Phone : ''}`}>{props.bidData.Assignee.Phone ? props.bidData.Assignee.Phone : ''}</a></li>
                            <li><a className="emailElips" href={`tel:${props.bidData.Assignee.Email ? props.bidData.Assignee.Email : ''}`}>{props.bidData.Assignee.Email ? props.bidData.Assignee.Email : ''}</a></li>

                        </ul>
                        {props.bidData.SOWUrl &&
                            <ul>
                                <li><label>{props.translations.confirmQuote.labelTwo}</label></li>
                                <a className="viewFull" href={props.bidData.SOWUrl} target="_blank">{props.translations.confirmQuote.blankLabel}</a>
                            </ul>
                        }

                    </QuoteInfo>
                    <QuoteDetails>
                        <QuoteDetailsHeader>
                            <label>{props.translations.confirmQuote.labelThree}</label>
                            {modDate &&
                                <label className="date">{props.translations.confirmQuote.lastUpdated} {modDate.shortDateWithoutDay}</label>
                            }
                            {
                                (fullySponsored == true && (props.bidData.Settlement.InstallationCost <= props.bidData.ProgramFullySponsoredThreshold)) ?
                                    <h1 className="balance">$0.00 {props.bidData.ProgramCurrencyType}</h1>
                                        : <h1 className="balance">{props.formatNumber(props.bidData.Settlement.RemainingBalance)} {props.bidData.ProgramCurrencyType}</h1>
                            }
                            {
                                !fullySponsored ? (
                                    showDetails === false ? (
                                        <div className="chevron">
                                            <p className="details" onClick={() => openDetails(true)}>{props.translations.confirmQuote.viewDetails}</p>
                                            <i className="far fa-chevron-down" onClick={() => openDetails(true)} />
                                        </div>

                                    ) : (
                                        <div>
                                            <div className="detailBox">
                                                <div className="chevron">
                                                    <p className="details" onClick={() => openDetails(false)}>{props.translations.confirmQuote.hideDetails}</p>
                                                    <i className="far fa-chevron-up" onClick={() => openDetails(false)} />
                                                </div>
                                                <div className="innerData">

                                                    <div className="detailItem">
                                                        <div className="labelSpan">
                                                            <p className="itemLabel">{props.translations.confirmQuote.itemOne}&nbsp;</p>
                                                            {/* {props.bidData.Settlement.RevisedOn &&
                                                            <p className="itemMicroLabel">(Updated)</p>
                                                        } */}
                                                        </div>

                                                        <p className="itemData">{props.bidData.Settlement.InstallationCost ? props.formatNumber(props.bidData.Settlement.InstallationCost) : "0"}</p>
                                                    </div>
                                                    {parseInt(props.bidData.Settlement.InstallCreditApplied, 10) > 0 &&
                                                        <div className="detailItem">
                                                            <p className="credit">{props.translations.confirmQuote.itemCredit}</p>
                                                            <p className="itemData credit">- {props.bidData.Settlement.InstallCreditApplied ? props.formatNumber(props.bidData.Settlement.InstallCreditApplied) : "0"}</p>
                                                        </div>
                                                    }

                                                    {/* Do not show 'Total Permit Cost' for UK */}
                                                    {props.bidData.UserLocale != "en-GB" &&
                                                        <div className="detailItem">
                                                            <div className="labelSpan">
                                                                <p className="itemLabel">{props.translations.confirmQuote.itemTwo}&nbsp;</p>
                                                                {/* {props.bidData.Settlement.RevisedOn &&
                                                                <p className="itemMicroLabel">(Updated)</p>
                                                            } */}
                                                            </div>

                                                            <p className="itemData">{props.bidData.Settlement.PermitCost ? props.formatNumber(props.bidData.Settlement.PermitCost) : "0"}</p>
                                                        </div>
                                                    }
                                                    {props.bidData.UserLocale != "en-GB" && parseInt(props.bidData.Settlement.PermitCreditApplied, 10) > 0 &&
                                                        <div className="detailItem">
                                                            <p className="credit">{props.translations.confirmQuote.itemCredit}</p>

                                                            <p className="itemData credit">- {props.bidData.Settlement.PermitCreditApplied ? props.formatNumber(props.bidData.Settlement.PermitCreditApplied) : "0"}</p>

                                                        </div>
                                                    }



                                                    {hasDeposit &&
                                                        <div className="detailItem balance">
                                                            <div className="labelSpan">
                                                                <p className="itemLabel">{props.translations.confirmQuote.itemThree}&nbsp;</p>
                                                            </div>

                                                            <p className="itemData">- {props.bidData.Settlement.Deposit ? props.formatNumber(props.bidData.Settlement.Deposit) : "0"}</p>
                                                        </div>
                                                    }
                                                    <div className="borderBalance">
                                                        <div className="balanceBox">
                                                            <div className="detailItem">
                                                                <p className="remain">{props.translations.confirmQuote.balance}</p>
                                                                <p className="remain">{props.formatNumber(props.bidData.Settlement.RemainingBalance)} {props.bidData.ProgramCurrencyType}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                ) : null
                            }

                            <Button
                                onClick={() => props.acceptBidByGuid()}
                            >
                                {props.translations.confirmQuote.button}
                            </Button>
                        </QuoteDetailsHeader>
                    </QuoteDetails>
                </Card>
            </CardContainer>
            <PoweredBy
                phone
                src={`${window.env.blobUrl}/qmerit/wordmark/png/Qmerit-Wordmark-IG400.png`}
                alt="Qmerit"
            />
        </Container>

    )
}