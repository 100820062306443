import styled from 'styled-components';
import {
  mediaMax
} from '../../../../../shared/style';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  padding: 24px;

  ${mediaMax.phone`
    gap: 16px;
    padding: 24px 16px;
  `}
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: row;
  height: 40px;
  align-items: center;
  padding: 0px;
  gap: 16px;

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin: 0px;
    color: #3E4148;
  }

  ${mediaMax.phone`
    gap: 8px;
    height: 20px;

    h2 {
      font-size: 16px;
      line-height: 20px;
    }
  `};
`;

const IconBox = styled.div`
  width: 40px;
  height: 40px;
  display:flex;
  align-items: center;
  justify-content: center;

  i {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #3963FE;
  }

  ${mediaMax.phone`
    width: 20px;
    height: 20px;

    i {
      font-size: 16px;
      line-height: 20px;
    }
  `};
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 56px;

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin: 0px;
  }

  ${mediaMax.phone`
    padding: 0px 0px 0px 28px;
  `};
`;

export default {
  Card,
  TitleBox,
  IconBox,
  Details
};