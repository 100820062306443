import React, { PureComponent } from "react";
import Modal from "../Modal";
import DateTimeModal from "./dateTimeModal";
import moment from "moment";

class DateTimeComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      rangeCurrentHourValue: "00",
      rangeCurrentMinuteValue: "00",
      rangeHourValue: "00",
      rangeMinuteValue: "00",
      timePeriod: "am",
      selectedDate: {
        date: "",
        month: "",
        year: ""
      },
      setDateTime: ""
    };
  }

  componentDidMount() {
    const m = this.props.value || moment();

    let dateValue = {
      date: m.date(),
      month: m.format("MMMM"),
      year: m.year(),
      day: m.format("dddd")
    };
    let timePeriod = "";
    let hour = 0;
    if (m.hours() > 12) {
      timePeriod = "pm";
      hour = m.hours() - 12;
    } else {
      hour = m.hours();
      timePeriod = "am";
    }
    this.setState({
      selectedDate: dateValue,
      rangeHourValue: this.timeFormatter(hour),
      rangeMinuteValue: this.timeFormatter(m.minutes()),
      rangeCurrentHourValue: this.timeFormatter(hour),
      rangeCurrentMinuteValue: this.timeFormatter(m.minutes()),
      setDateTime: m,
      timePeriod
    });
  }

  timeFormatter = value => {
    if (`${value}`.length > 1 && `${value}`.length < 3) {
      return value;
    } else if (`${value}`.length > 2) {
      let valueNew = value.slice(1);
      return valueNew;
    } else {
      return "0" + value;
    }
  };

  onInputHours = e => {
    this.setState({
      rangeCurrentHourValue: e.target.value,
      rangeHourValue: this.timeFormatter(Math.round(e.target.value))
    });
  };

  onInputMinutes = e => {
    this.setState({
      rangeCurrentMinuteValue: e.target.value,
      rangeMinuteValue: this.timeFormatter(Math.round(e.target.value))
    });
  };

  handleTimePeriodChange = e => {
    e.preventDefault();
    e.target.id === "am"
      ? this.state.timePeriod !== "am" && this.setState({ timePeriod: "am" })
      : this.state.timePeriod !== "pm" && this.setState({ timePeriod: "pm" });
  };

  handleHoursChange = e => {
    if (e.target.value === "") {
      this.setState({
        rangeCurrentHourValue: "01",
        rangeHourValue: "00"
      });
    } else {
      if (e.target.value <= 12 && e.target.value > 0) {
        this.setState({
          rangeCurrentHourValue: this.timeFormatter(e.target.value),
          rangeHourValue: this.timeFormatter(e.target.value)
        });
      } else {
        if (e.target.value > 12) {
          this.setState({
            rangeCurrentHourValue: 12,
            rangeHourValue: 12
          });
        } else {
          this.setState({
            rangeCurrentHourValue: "01",
            rangeHourValue: "00"
          });
        }
      }
    }
  };

  handleMinutesChange = e => {
    if (e.target.value === "") {
      this.setState({
        rangeCurrentMinuteValue: "00",
        rangeMinuteValue: "00"
      });
    } else {
      if (e.target.value <= 59 && e.target.value >= 0) {
        this.setState({
          rangeCurrentMinuteValue: this.timeFormatter(e.target.value),
          rangeMinuteValue: this.timeFormatter(e.target.value)
        });
      } else {
        if (e.target.value > 59) {
          this.setState({
            rangeCurrentMinuteValue: 59,
            rangeMinuteValue: 59
          });
        } else {
          this.setState({
            rangeCurrentMinuteValue: "00",
            rangeMinuteValue: "00"
          });
        }
      }
    }
  };

  dateSelection = date => {
    let dateValue = {
      date: date.date(),
      month: date.format("MMMM"),
      year: date.year(),
      day: date.format("dddd")
    };
    date.arrowCheck
      ? this.setState({ selectedDate: dateValue, setDateTime: date })
      : this.setState({ selectedDate: dateValue });
  };

  changeMonth = arrow => {
    if (arrow === "arrowLeft") {
      let s = this.state.setDateTime.subtract(1, "month");
      s.arrowCheck = true;
      this.dateSelection(s);
    } else if (arrow === "arrowRight") {
      let a = this.state.setDateTime.add(1, "month");
      a.arrowCheck = true;
      this.dateSelection(a);
    }
  };

  onBlurHoursInput = e => {
    if (e.target.value === "00") {
      this.setState({ rangeHourValue: "01" });
    }
  };

  render() {
    return (
      <Modal
        content={
          <DateTimeModal
            {...this.state}
            value={this.props.value}
            timeZone={this.props.timeZone}
            show={this.props.showModalFor}
            closeDateTime={this.props.closeDateTime}
            changeMonth={this.changeMonth}
            dateSelection={this.dateSelection}
            handleHoursChange={this.handleHoursChange}
            handleMinutesChange={this.handleMinutesChange}
            handleTimePeriodChange={this.handleTimePeriodChange}
            onInputMinutes={this.onInputMinutes}
            onInputHours={this.onInputHours}
            onBlurHoursInput={this.onBlurHoursInput}
          />
        }
      />
    );
  }
}
export default DateTimeComponent;
