import {
  theme,
  fade,
  mediumText,
  mediaMax,
  mediaMin
} from "../../../../shared/style";
import styled, { css } from "styled-components";

const Root = styled.div`
  padding: 0 16px 16px;
  display: flex;
  flex-direction: column;
  ${mediaMin.tablet`padding: 16px;`};
`;

const CorpInfo = styled.div`
  display: flex;
  align-items: center;
  img {
    height: 40px;
    width: 40px;
    object-fit: contain;
    margin-right: 8px;
    border-radius: 8px;
  }
  p {
    color: #3e4148;
    font-size: 16px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  p.bid {
    font-size: 14px;
    color: rgba(62, 65, 72, 0.7);
  }
`;

const Description = styled.p`
  color: #3e4148;
  font-size: 16px;
  margin: 16px 0;
`;

const Details = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0 24px;
`

const Detail = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: #6e727c;
  width: 50%;
  margin-bottom: 24px;

  span {
    font-size: 16px;
    color: #3e4148;
  }
`;


const Action = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: rgba(62, 65, 72, 0.7);
  text-align: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between
  width: 100%;
  margin-top: 16px;

  button {
    margin: 0 8px;
  }
`

export default {
  ButtonWrapper,
  Root,
  Header,
  CorpInfo,
  Description,
  Details,
  Detail,
  Action
};
