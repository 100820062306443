import React, { useEffect } from 'react'
import Loading from '../Loader/Loading';

function DepositWebApp({ token }) {

  useEffect(() => {

    fetch(`${window.env.qmtUrl}/Request/${token}/Deposit`, {
      method: "GET",
      headers: { "Content-Type": "application/json; charset=UTF-8", "Subscription-Key": `${window.env.apiKey}` },
    })
      .then((res) => res.json())
      .then((data) => window.location.replace(data.Url))

  }, []);

  return (
    <Loading color={"#000000"} />
  )
}

export default DepositWebApp
