export default {
    "User":{
       "CompanyName":"ashbyqaktech",
       "Name":"ashbyqaktech automation"
    },
    "Type":"Accept",
    "Activities":[
       {
          "MasterActivityId":49544,
          "ParentActivityId":49544,
          "DerivedActivityId":null,
          "GeneratedActivityId":null,
          "Name":"JuiceBox Charger Bid",
          "Program":{
             "Id":505,
             "Name":"QMerit Default Program",
             "DBAName":"ashbyqaktech",
             "ExternalData":null
          },
          "OwnerProgram":{
             "Id":218,
             "Name":"Qmerit EV Charger",
             "DBAName":"Qmerit EV Solutions",
             "ExternalData":{
                "ProgramId":218,
                "Name":"Still need a charger?",
                "Description":"Buy chargers at a discounted rate right now. Compare models and find the right charger at the best price.",
                "ImageUrl":"https://storeqmtprd.blob.core.windows.net/images/Juicy-Charger.png",
                "Link":"https://emotorwerks.com/store/residential"
             }
          },
          "ActivityTemplateId":220,
          "Priority":{
             "Id":5,
             "Description":"Medium"
          },
          "Status":{
             "Id":"InProgress",
             "Name":"InProgress"
          },
          "StartBy":null,
          "StartNoLaterThan":null,
          "Assignment":null,
          "Description":"Your company has been requested to Bid on an EV Charging Station installation for a customer. Be sure to view the attached Customer Project Scope for the specific details of the installation needs!",
          "Location":{
             "Id":8829,
             "Name":"Residential",
             "Description":null,
             "Address":"32750 Eagle River Rd",
             "City":"Anchorage",
             "State":"AK",
             "Zip":"99577",
             "Country":"US",
             "Latitude":61.23396,
             "Longitude":-149.270646,
             "Contact":{
                "Id":null,
                "FirstName":null,
                "LastName":null,
                "Email":null,
                "PhoneNumber":null,
                "PreferredMethod":null,
                "Title":null,
                "Address":null,
                "ImageUrl":null
             }
          },
          "Customer":{
             "Id":7750,
             "Name":"qmerit10+101_74 Long",
             "Type":"Company",
             "Contact":{
                "Id":null,
                "FirstName":"qmerit10+101_74",
                "LastName":"Long",
                "Email":"qmerit10+101_74@gmail.com",
                "PhoneNumber":"1234",
                "PreferredMethod":"email",
                "Title":null,
                "Address":"",
                "ImageUrl":null
             }
          },
          "DueDate":"2019-11-07T06:50:06.6025204",
          "Tasks":{
             "Id":"1",
             "Name":"Submit bid information",
             "Status":"New",
             "CanComplete":true,
             "Events":[
 
             ],
             "Actions":[
                {
                   "Files":[
 
                   ],
                   "MultiDocument":true,
                   "ProjectDocument":null,
                   "Id":"1b",
                   "Label":"Quote",
                   "Type":"Upload",
                   "IsCompleted":false
                },
                {
                   "CustomDecimal1":null,
                   "Id":"1c",
                   "Label":"Currency",
                   "Type":"Currency",
                   "IsCompleted":false
                },
                {
                   "CustomString1":null,
                   "Id":"1d",
                   "Label":"Bid Message",
                   "Type":"Text",
                   "IsCompleted":false
                }
             ],
             "Tasks":[
 
             ],
             "Next":null
          },
          "Events":[
 
          ],
          "Owner":{
             "Id":4508,
             "DBAName":"Qmerit EV Solutions",
             "ImageUrl":"https://senstoresvcint.blob.core.windows.net/images-company/132505d1-17a7-4bb5-bf6d-0e5f2198d187",
             "Contact":{
                "Id":null,
                "FirstName":"Customer",
                "LastName":"Service",
                "Email":"qmerit.main@email.com",
                "PhoneNumber":"888-272-0090",
                "PreferredMethod":null,
                "Title":"Network Owner",
                "Address":null,
                "ImageUrl":null
             }
          },
          "CompletionPercentage":0.0,
          "Type":"Bid",
          "Project":{
             "Id":23841,
             "Name":"JuiceBox Charger",
             "DueDate":null,
             "Status":"New",
             "ModifiedOn":null,
             "Attachments":[
 
             ]
          },
          "BidRequest":{
             "Id":23842,
             "Name":"JuiceBox Charger Bid",
             "DueDate":"2019-11-07T06:50:06.6025204",
             "Status":"New",
             "ModifiedOn":"2019-10-07T20:50:06.9091216",
             "Attachments":[
                {
                   "Id":12262,
                   "Type":"BidRequest",
                   "Url":"https://evintg.blob.core.windows.net/surveys/46b4b343-337f-448e-9f15-857d4e0bf0c5",
                   "FileType":"application/pdf",
                   "Name":"Project Scope",
                   "IsProjectLevel":false,
                   "IsPublic":true,
                   "UploadType":"Qmerit",
                   "UploadId":null
                }
             ]
          },
          "Collaborators":[
 
          ],
          "SelectedOn":null,
          "CreatedOn":"2019-10-07T20:50:10.217",
          "IsFrozen":false,
          "AcceptedBy":{
             "Name":"ashbyqaktech automation",
             "AcceptedOn":"2019-10-07T20:51:06.218912"
          },
          "DeclinedBy":null,
          "ModifiedOn":null,
          "RemovedOn":null,
          "Id":49547
       }
    ],
    "OperationGuids":{
       "Submit":"65111764-0e94-4e79-b5da-1b13678fec16",
       "Decline":"d5458aef-5354-4897-97ba-a342b3ba170f",
       "Accept":"caa1b04c-7971-4c77-bd98-e7c9c780be6b"
    }
 }