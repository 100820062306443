import React, { useState, Fragment } from 'react';
import styledComponents from './styles';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';

import PreliminaryEstimateTable from '../../components/PreliminaryEstimateTable';
import MobileModalEstimateDetails from '../../components/MobileModalEstimateDetails';
import { QUOTE, PREPAID } from '../../constants';

const { H2, P_MicroBold, P_Micro, ViewAllMobileButton } = commonStyles;

const {
  PreliminaryEstimateSection,
  PreliminaryEstimeateWrapper,
  EstimateHeaderContainer,
  ItemHeader,
  AmountHeader,
  QtyHeader,
  UnitPriceHeader,
  PreliminaryContainer,
  MobileAdditionalItemizedInfo,
  Notes,
} = styledComponents;

export default function PreliminaryEstimate({ fetchedBidData, bidData, translations, items = null, title = QUOTE }) {
  const [isMobileModalOpen, setIsMobileModalOpen] = useState({ show: false, data: null });

  const handleCloseMobileModal = () => {
    setIsMobileModalOpen({ show: false, data: null });
  };

  const handleOpenMobileModal = (props) => {
    setIsMobileModalOpen({ show: true, data: props });
  };

  const changeReason = bidData.ChangeReason;

  return (
    <Fragment>
      {/* Preliminary Estimate Section */}
      <PreliminaryEstimateSection>
        <H2>{title === QUOTE ? translations.preliminaryEstimateSection.title : PREPAID}</H2>
        <PreliminaryEstimeateWrapper role="table">
          <EstimateHeaderContainer role="row">
            <ItemHeader>
              <P_MicroBold role="columnheader">{translations.preliminaryEstimateSection.item}</P_MicroBold>
            </ItemHeader>
            {fetchedBidData.Request.QuoteBuilderEnabled && (
              <Fragment>
                <QtyHeader>
                  <P_MicroBold role="columnheader">Qty</P_MicroBold>
                </QtyHeader>
                <UnitPriceHeader>
                  <P_MicroBold role="columnheader">Unit Price</P_MicroBold>
                </UnitPriceHeader>
              </Fragment>
            )}
            <AmountHeader>
              <P_MicroBold role="columnheader">{translations.preliminaryEstimateSection.amount}</P_MicroBold>
            </AmountHeader>
          </EstimateHeaderContainer>
          <PreliminaryContainer role="rowgroup">
            <PreliminaryEstimateTable
              fetchedBidData={fetchedBidData}
              bidData={bidData}
              translations={translations}
              tableType={title}
            />
          </PreliminaryContainer>
        </PreliminaryEstimeateWrapper>
        {(fetchedBidData.Request.CultureCode === 'fr-CA' || fetchedBidData.Request.CultureCode === 'en-CA') && (
          <P_Micro>{translations.preliminaryEstimateSection.canadaDisclaimer}</P_Micro>
        )}
        {fetchedBidData.Request.CultureCode === 'en-GB' && (
          <P_Micro>{translations.preliminaryEstimateSection.ukDisclaimer}</P_Micro>
        )}
        {changeReason && (
          <Notes>
            <P_Micro>{translations.preliminaryEstimateSection.notes}</P_Micro>
            <p>{changeReason}</p>
          </Notes>
        )}
      </PreliminaryEstimateSection>
      {/* Mobile View */}
      {items && (
        <MobileAdditionalItemizedInfo>
          {items}
          <ViewAllMobileButton
            onClick={() => handleOpenMobileModal(translations.preliminaryEstimateSection.modalTitle)}
          >
            {translations.preliminaryEstimateSection.button}
          </ViewAllMobileButton>
          {/* Mobile Modal */}
          {isMobileModalOpen.show && (
            <MobileModalEstimateDetails
              close={handleCloseMobileModal}
              content={isMobileModalOpen.data}
              fetchedBidData={fetchedBidData}
              translations={translations}
            />
          )}
        </MobileAdditionalItemizedInfo>
      )}
      {/* End of Mobile View */}
    </Fragment>
  );
}
