import React from 'react';
import styledComponents from "./styles";

const {
  QuoteApprovedContainer
} = styledComponents;

export default function QuoteApprovedButton({ acceptFinance, acceptBidByGuid, currentIdx, isChecked }) {
  let disableClickAction = (currentIdx === -1 || currentIdx === 0 && !isChecked) ? true : false;
  return (
    <QuoteApprovedContainer type="button" disabled={disableClickAction} onClick={currentIdx === 0 ? acceptFinance : acceptBidByGuid} >
      Approve
    </QuoteApprovedContainer>
  )
};