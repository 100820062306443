export const extensionToType = {
    png: 'image/png',
    jpg: 'image/jpeg',
    pdf: 'application/pdf',
    ppt: 'application/vnd.ms-powerpoint',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    key: 'application/x-iwork-keynote-sffkey',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xls: 'application/vnd.ms-excel',
    csv: 'text/csv',
}
export default {
    image: ['png', 'jpeg'],
    application: [
        'pdf',
        'vnd.ms-powerpoint',
        'vnd.openxmlformats-officedocument.wordprocessingml.document',
        'vnd.openxmlformats-officedocument.presentationml.presentation',
        'vnd.ms-excel',
        'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'x-iwork-keynote-sffkey'
    ],
    text: ['csv']
}
