import styled, { createGlobalStyle, css } from "styled-components"

const ModalBackground = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.0);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  transform: scale(1);
  animation: ${({ animate }) => {
    switch (animate) {
      case 'in':
        return 'fadeIn .3s ease-in forwards';
      case 'out':
        return 'fadeOut .3s ease-in forwards';
      default:
        return 'fadeIn .3s ease-in forwards';
    }
  }};
  @keyframes fadeIn {
    0% { background: rgba(0, 0, 0, 0.0); }
    100% { background: rgba(0, 0, 0, 0.5); }
  }

  @keyframes fadeOut {
    0% { background: rgba(0, 0, 0, 0.5); }
    100% { background: rgba(0, 0, 0, 0.0); }
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  gap: 24px;
  width: 800px;
  max-width: 90%;
  background: #FFFFFF;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 8px;

  opacity:0;
  animation: ${({ animate }) => {
    switch (animate) {
      case 'in':
        return 'animateUp .3s ease-in forwards';
      case 'out':
        return 'animateDown .3s ease-in forwards';
      default:
        return 'animateUp .3s ease-in forwards';
    }
  }};
  @keyframes animateUp {
    0% {
      transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  
  @keyframes animateDown {
    0% {
      transform: translateY(0px);
      opacity: 1;
    }
    100% {
      transform: translateY(1000px);
      opacity: 0;
    }  
  }
`;

const ModalTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 24px;

  width: 100%;
  height: 32px;

  justify-content: space-between;
`;

const ModalCloseButton = styled.button`
  border: none;
  padding: 0px 5px;
  background: none;
  cursor: pointer;
`;

const ModalCloseIcon = styled.i`
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #B9BBC1;
`;

const ModalListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  
  width: 100%;
`;

const ModalListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 16px;
  width: 100%;

  justify-content: space-between;
`;

const ModalListItemIconContainer = styled.div`
  width: 40px;
  height: 40px;
  margin: 14px 16px 14px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalCheckIcon = styled.i`
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #7BC342;
`;

const ModalTimesIconDanger = styled.i`
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #F4511E;
`;

const ModalListItemContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  padding: 24px 0px;
  box-shadow: ${props => props.divider};
`;

const Header2 = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin: 0px;
`;

const Text1 = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin: 0px;
`;

const ScrollDisabler = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

export default {
  ModalBackground,
  ModalContainer,
  ModalTitle,
  ModalCloseButton,
  ModalCloseIcon,
  ModalTimesIconDanger,
  ModalListContainer,
  ModalListItem,
  ModalListItemIconContainer,
  ModalCheckIcon,
  ModalListItemContentContainer,
  Header2,
  Text1,
  ScrollDisabler
}