import React, { useState, Fragment } from 'react';
import styledComponents from './styles';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';
import _ from "lodash";

import Modal from '../../components/ModalRebates';
import useDelayModalUnmount from '../../components/Modal/hook';

import MobileModalRebates from '../../components/MobileModalRebates';

const {
  H2,
  P,
  Label_Field,
  P_Overflow,
  P_SM_Overflow,
  Link,
  ChevronRight,
  ViewAllButton,
  ViewAllMobileButton
} = commonStyles;

const {
  RebatesSection,
  RebatesContainer,
  Rebate,
  RebateBox,
  LearnMore,
  ProgramTypeTextWrapper
} = styledComponents;

export default function Rebates({ fetchedRequestData }) {

  let rebatesLen = "";

  const rebates = fetchedRequestData.Request.Rebates // Rebates array from fetchedRequestData
  if(!_.isNil(rebates)){
    rebatesLen = rebates.length;
  }
  const showRebatesSection = rebatesLen > 0;
  let prevType = null;

  const [isMounted, setIsMounted] = useState(false); // Keeps track of user desktop modal click

  const modalOpen = useDelayModalUnmount(isMounted, 250); // Delays before switching boolean value of modalOpen for desktop

  // For use with desktop modal
  const toggleMountModal = () => {
    setIsMounted(!isMounted);
  }

  const [isMobileModalOpen, setIsMobileModalOpen] = useState({ show: false, data: null });

  const handleCloseMobileModal = () => {
    setIsMobileModalOpen({ show: false, data: null });
  }

  const handleOpenMobileModal = (props) => {
    setIsMobileModalOpen({ show: true, data: props });
  }

  // Section header title for main page and modal
  const title = 'Rebates and incentives';

  return (
    <Fragment>
      {showRebatesSection &&
        <RebatesSection>
          <H2>{title}</H2>
          <P>We found these rebates that you may be eligible for!</P>
          <RebatesContainer>
            {
              rebates.slice(0, 3).map(item => {
                return (
                  <Rebate key={item.Id}>
                    {
                      item.Type !== prevType ?
                        (
                          <ProgramTypeTextWrapper>
                            {item.Type !== prevType && (prevType = item.Type) && <Label_Field>{rebatesLen > 1 ? `${item.Type}s` : `${item.Type}`}</Label_Field>}
                          </ProgramTypeTextWrapper>
                        )
                        : null
                    }
                    <RebateBox>
                      <P_Overflow>{item.Name}</P_Overflow>
                      <P_SM_Overflow>{item.Text}</P_SM_Overflow>
                      <LearnMore>
                        <Link href={item.WebAddress} target="_blank">Learn More</Link>
                        <Link href={item.WebAddress} target="_blank"><ChevronRight className="far fa-chevron-right"></ChevronRight></Link>
                      </LearnMore>
                    </RebateBox>
                  </Rebate>
                )
              })
            }
          </RebatesContainer>
          <ViewAllButton onClick={toggleMountModal}>View All Rebates</ViewAllButton>
          {modalOpen &&
            <Modal isMounted={isMounted}
              toggleMountModal={toggleMountModal}
              title={title}
              content={rebates}
            />
          }
          {/* Mobile View */}
          <ViewAllMobileButton onClick={() => handleOpenMobileModal('Rebates')}>View All Rebates</ViewAllMobileButton>
          {/* Mobile Modal */}
          {isMobileModalOpen.show && (isMobileModalOpen.data === 'Rebates') && <MobileModalRebates close={handleCloseMobileModal} title={isMobileModalOpen.data} subtitle={title} content={rebates} />}
          {/* End of Mobile View */}
        </RebatesSection>
      }
    </Fragment>
  )
}
