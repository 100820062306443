import styled from 'styled-components';
import { mediaMin } from '../../../../shared/style';


const PanelSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  margin-top: -32px;

  ${mediaMin.desktop`
    display: none;
  `}
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 0px 0px 8px 0px;

  h3 {
    color: #3E4148;
    font-family: Red Hat Display;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin: 0px;
  }

  p {
    color: black;
    font-family: Mulish;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; 
  }

`;

const PanelContainer = styled.div`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  background: #F4F5F6;

  .imgOriginal {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 112px;
    height: 150px;
    background-size: 100%;
    border-radius: 8px;
    background-image: ${({image}) => `url('${image}')`};
  }

  .imgAnalyzed {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 112px;
    height: 150px;
    background-size: 100%;
    border-radius: 8px;
    background-image: ${({analyticImage}) => `url('${analyticImage}')`};
  }

  .boxOriginal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 4px;
  }

  .boxAnalyzed {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 4px;
  }

  .link {
    margin: 0px;
  }
`

const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background: var(--white-white-50, rgba(255, 255, 255, 0.50));
  padding: 4px;
  margin: 4px 4px 0px 0px;

  i {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #000;
  }
`

const A = styled.a`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: none;
  display: flex;
  align-items: center;
  text-align: center;

  /* link / new */
  color: #3963FE;
`

export default {
    PanelSection,
    HeaderContainer,
    PanelContainer,
    IconBox,
    A
  };