import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import _ from 'lodash';
import translationsLocalData from '../../../../server/locale/en_US.json';

export default function useFetchData(props) {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [responseStatus, setResponseStatus] = useState(null);
  const [translations, setTranslations] = useState({});
  const guid = queryString.parse(props.location.search).token;
  const navigate = useHistory();

  const getData = async (guid, signal) => {
    const settings = {
      method: 'GET',
      headers: { 'content-type': 'application/json', "Subscription-Key": `${window.env.apiKey}` }
    }

    try {
      setIsLoading(true);
      const response = await fetch(`${window.env.qmtUrl}/Estimate/Bid/${guid}`, settings);
      setResponseStatus(response.status);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

      const dataObj = await response.json();
      
      if (!signal.aborted) {
        setData(dataObj);
      }
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (_.has(window, 'env.translations') && !_.isEmpty(window.env.translations)) {
      setTranslations(window.env.translations);
    }
    else { 
      setTranslations(translationsLocalData);
    }; 

    const abortController = new AbortController();
    getData(guid, abortController.signal);

    return () => abortController.abort(); // Cleanup 

  }, []);

  const postData = async (api, includeBody = false, credit = 0) => {
    setIsLoading(true);

    const url = `${window.env.qmtUrl}/` + api;

    let settings = {
      method: 'POST',
      headers: { 'content-type': 'application/json', "Subscription-Key": `${window.env.apiKey}` }
    };

    if (includeBody) {
      settings = {
        ...settings,
        body: JSON.stringify({ SponsoredCredit: credit })
      }
    }

    try {
      const response = await fetch(url, settings);
      setResponseStatus(response.status);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  }

  // handle Decline and navigate to manager declined QAP
  const declineHander = () => {
    postData(`Estimate/Bid/${guid}/Decline`);
    navigate.push(`/manager-declined`);
  }

  // handle Save & Approve and navigate to manager approved QAP
  const approveHandler = (credit) => {
    postData(`Estimate/Bid/${guid}/Approve`, true, credit);
    navigate.push(`/manager-approved`);
  }

  // handle Cancel Project navigation
  const cancelHandler = (requestId) => {
    navigate.push(`/manager-canceled?token=${requestId}`);
  }

  return {
    data,
    isLoading,
    responseStatus,
    translations,
    declineHander,
    approveHandler,
    cancelHandler
  }
}