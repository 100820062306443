import styled from 'styled-components';
import {
  mediaMax,
  mediaMin
} from "../../../../shared/style"


const PreliminaryEstimateSection = styled.div`

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;

`;


const PreliminaryEstimeateWrapper = styled.div`

  display: flex;
  flex-direction: column;
  padding: 0px;

  border: 1px solid #DEDFE2;
  border-radius: 4px;

  width: 100%;

  ${mediaMax.phone`
    border: none;
 `};
`;

const EstimateHeaderContainer = styled.div`

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  height: 32px;

  ${mediaMax.phone`
   display: none;
  `};
`;

const ItemHeader = styled.div`

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 24px;

  flex: 3 1 0;
  height: 32px;

  background: #F4F5F6;
  box-shadow: inset 0px -1px 0px #EDEEEF;
`;

const AmountHeader = styled.div`

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 24px;

  flex: .5 1 0;
  height: 32px;

  background: #F4F5F6;
  box-shadow: inset 0px -1px 0px #EDEEEF;
`;

const QtyHeader = styled(AmountHeader)`
  flex: .25 1 0;
`;

const UnitPriceHeader = styled(AmountHeader)`
`;

const PreliminaryContainer = styled.div`

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px;

  ${mediaMax.phone`
    padding: 0px;
`};

  `;

  const MobileAdditionalItemizedInfo = styled.div`

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0px 0px;
  gap: 16px;

  ${mediaMin.desktop`
    display: none;
  `};

`;

const Notes = styled.div`
`;

export default {
  PreliminaryEstimateSection,
  PreliminaryEstimeateWrapper,
  EstimateHeaderContainer,
  ItemHeader,
  AmountHeader,
  QtyHeader,
  UnitPriceHeader,
  PreliminaryContainer,
  MobileAdditionalItemizedInfo,
  Notes
}

