import React, {PureComponent} from "react"
import styledComponents from "./style";
import queryString from "query-string";
import _ from "lodash";

import Wrapper from "../../components/Wrapper";
import Loader from "../../components/Loader";
import Radio from '../../components/Radio'

import {AcceptDeclineBid} from './components/AcceptDeclineBid';
import {TierUpdateSatatus} from '../TieredBidStatus/components/AlreadyAccepted';
import {TierUpdateFail} from '../tierManage/components/TierUpdateFail';
import {InstallerStatus} from '../../components/InstallerStatus'

class DeclineTieredBid extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            token: '',
            companyId: '',
            tierDeclineReason: "",
            fechedBidData: {},
            radioDeclineReaseon: '',
            textAreaDeclineReason: '',
            tierDeclineSucces: false,
            buttonDisabled: true,
            translations: {},
            browserLanguage: "",
            country: ""
        }
    }

    componentDidMount() {

        let bidGuid = this.getBidGuid(this.props.location.search);
        let companyId = this.getBidCompanyId(this.props.location.search);
        let country = queryString.parse(this.props.location.search).country;
        if (!_.isEmpty(bidGuid)) {
            this.setState({token: bidGuid, companyId: companyId})
            this.getBidByGuid(bidGuid)
           
        } 
        if (_.has(window, 'env.translations') && !_.isEmpty(window.env.translations)) {
            this.setState({
                translations: window.env.translations
            })
        }
        if (!_.isEmpty(country)) this.setState({country: country})

        fetch("/language", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => res.json())
            .then((data) => this.setState({browserLanguage: data.browserLanguage}))
    }

    getBidGuid = (url) => {
        return queryString.parse(url).token
    }

    getBidCompanyId = (url) => {
        return queryString.parse(url).companyId
    }

    getBidByGuid = (bidGuid) => {
        let optionalParams = {
            headers: {"content-type": "application/json; charset=UTF-8", "Subscription-Key": `${window.env.apiKey}`},
            method: "GET"
        }

        return new Promise((resolve, reject) => {
                      fetch(`${window.env.qmtUrl}/Bid/${bidGuid}`, optionalParams)
                      .then(res => res.json())
                      .then(data => {
                          this.setState({fechedBidData: data, isLoading: false})
                      })
                      .catch(error => {
                          reject({
                              isLoading: false,
                              fetchedData: { ErrorCode: 500, ...error }
                          });
                      })
        })
    }

    setDisabled = (radio, textarea) => {
            let disabled = true;

        if (radio === 'Other') {
            if (!_.isEmpty(textarea)) {
                disabled = false;
            }
        } else {
            disabled = false;
        }

        this.setState({
            buttonDisabled: disabled,
        });
    }

    handleRadioOnchange = (radioDeclineReaseon) => {
        this.setState({radioDeclineReaseon: radioDeclineReaseon})
        this.setDisabled(radioDeclineReaseon, this.state.textAreaDeclineReason);
    }

    handleTextareaChange = (event) => {
        event.persist();
        this.setState({textAreaDeclineReason: event.target.value})
        this.setDisabled(this.state.radioDeclineReaseon, event.target.value);
    }

    declineProjectTier = () => {
        this.setState({isLoading: true})
        let data = {
            Reason: this.state.radioDeclineReaseon === "Other" ? this.state.textAreaDeclineReason : this.state.radioDeclineReaseon
        }

        let optionalParams = {
            headers: {
            "content-type": "application/json; charset=UTF-8", 
            "Sec-Fetch-Mode": "no-cors", 
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*",
            "Access-Control-Allow-Origin": "*", 
            "Subscription-Key": `${window.env.apiKey}`
            },
            body: JSON.stringify(data),
            method: "POST"
        }

        new Promise((resolve, reject) => {
            fetch(`${window.env.qmtUrl}/Bid/${this.state.token}/Decline`, optionalParams)
            .then(res => {
                this.setState({tierDeclineSucces: res.status, isLoading: false})
            })
            .catch(error => {
                reject({
                    isLoading: false,
                    fetchedData: { ErrorCode: 500, ...error }
                });
            })
        })

    }


    render() {

        const {
            Card,
            RequestName,
            RequestId,
            CompanyMessage,
            TierChecksContainer,
            TierTextContainer,
            TierText,
            TierTextArea,
            Button,
            OptionalTag,
        } = styledComponents;

        const mockData = [
            {Name: "Price too low", Id: 1 },
            {Name: "Too busy to service", Id: 2 },
            {Name: "Insufficient information", Id: 3 },
            {Name: "Outside of service area", Id: 4},
            {Name: "Unable to deliver scope of services", Id: 5},
            {Name: `${this.state.browserLanguage == 'en_GB' ? "Unable to service HMOs" : "Unable to service multifamily complexes"}`, Id: 6},
            {Name: "Other", Id: 7 },
        ];

        if (this.state.fechedBidData.Status === 'Declined' || this.state.fechedBidData.Status === 'Completed') {
            return (<TierUpdateSatatus bitStatus={this.state.fechedBidData.Status === "Completed" ? "Accepted" : this.state.fechedBidData.Status === "Declined" ? "Declined" : ''}/>)
        }

        if (this.state.fechedBidData.Status === 'Cancelled') {
            return <AcceptDeclineBid status="Cancelled" />
        }

        if (!this.state.isLoading && this.state.fechedBidData.Status === 'Closed'  || this.state.fechedBidData.Status === 'InProgress') {
            return (<InstallerStatus translations={this.state.translations} installerStatus='Accepted'/>)
        }   

        if ((this.state.fechedBidData.Status !== 'Declined' || this.state.fechedBidData.Status !== 'Completed') && !this.state.isLoading && !this.state.tierDeclineSucces ) {
            return (
                <Wrapper>
                    <Card>
                        <RequestName>{`${this.state.fechedBidData.Name}`}</RequestName>
                        <RequestId>Project #{`${this.state.fechedBidData.Project.Id}`}</RequestId>
                        <CompanyMessage>Please let us know why you won't be accepting this request.</CompanyMessage>
                        <TierChecksContainer>
                        {mockData.map((data, inx) => {
                                return (
                                    <Radio
                                        key={inx}
                                        name="reason"
                                        id={`reason-${data.Id}`}
                                        label={`${data.Name}`}
                                        onChange={e => this.handleRadioOnchange(data.Name)}
                                    />
                                );
                            })}
                        </TierChecksContainer>
                        <TierTextContainer>
                            <TierText>Reason { this.state.radioDeclineReaseon !== 'Other' && <OptionalTag>- Optional</OptionalTag>}</TierText>
                        </TierTextContainer>
                        <TierTextArea
                            placeholder="Let us know why you're declining this request…"
                            value={this.state.textAreaDeclineReason}
                            onChange={this.handleTextareaChange}
                        />
                        <Button disabled={this.state.buttonDisabled} onClick={this.declineProjectTier}>DECLINE</Button>
                    </Card>
                </Wrapper>
            );
        }

        if (this.state.tierDeclineSucces === 200) {
            return <AcceptDeclineBid/>
        }

        if ([400, 404, 500].includes(this.state.tierDeclineSucces)) {
            return <TierUpdateFail translations={this.state.translations} country={this.state.country}/>
        } 

        return (<Loader color={"#000000"} isLoading={this.state.isLoading} />) 
    }
}

export default DeclineTieredBid;

