import React, { Fragment } from 'react';
import StyledComponents from './styles';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';
import ConceptRow from '../ConceptRow';
import _ from 'lodash';

const {
  P_Bold,
  P,
  P_Lrg_Bold,
  H2,
  P_Micro
} = commonStyles;

const {
  ConceptsContainer,
  SubtotalRow,
  TotalRow,
  TotalDivider,
  CreditsContainer,
  CreditsLogo,
  LogoImg
} = StyledComponents;

export default function Concepts({ fetchedRequestData, translations }) {

  return (
    <Fragment>
      {/* Top section to display amounts */}
      <ConceptsContainer>
        <SubtotalRow>
          <P_Bold>{translations.totalSection.total.concepts.subtotal}</P_Bold>
          {/* if Subtotal > 0 display Subtotal short format amount, else custom display To Be Determined */}
          {fetchedRequestData.Pricing.Subtotal.Amount > 0 ?
            <P_Bold>{fetchedRequestData.Pricing.Subtotal.ShortFormat}</P_Bold>
            :
            <P_Bold>{translations.totalSection.total.concepts.toBeDetermined}</P_Bold>
          }
        </SubtotalRow>
        {/* show only sponsored if Sponsored credit amount is > 0 and not Null
            otherwise display Installation Credit 
            "Show" is the prop used to control conditional rendering using Guard-clauses */}
        {!_.isNil(fetchedRequestData.Pricing.SponsoredCredit) && !_.isEmpty(fetchedRequestData.Pricing.SponsoredCredit) ?
          !(fetchedRequestData.Pricing.SponsoredCredit.Amount > 0) ?
            <ConceptRow
              show={fetchedRequestData.Pricing.InstallationCredit.Amount}
              concept={translations.totalSection.total.concepts.installationCredit}
              amount={fetchedRequestData.Pricing.InstallationCredit.ShortFormat}
            />
            :
            <ConceptRow
              show={fetchedRequestData.Pricing.SponsoredCredit.Amount}
              concept={translations.totalSection.total.concepts.sponsoredCredit}
              amount={fetchedRequestData.Pricing.SponsoredCredit.ShortFormat}
            />
          :
          <ConceptRow
            show={fetchedRequestData.Pricing.InstallationCredit.Amount}
            concept={translations.totalSection.total.concepts.installationCredit}
            amount={fetchedRequestData.Pricing.InstallationCredit.ShortFormat}
          />
        }
        {/* display Financed Amount if applicable */}
        {!_.isNil(fetchedRequestData.Pricing.FinancedAmount) && !_.isEmpty(fetchedRequestData.Pricing.FinancedAmount) &&
          <ConceptRow
            show={fetchedRequestData.Pricing.FinancedAmount.Amount}
            concept={translations.totalSection.total.concepts.financedAmount}
            amount={fetchedRequestData.Pricing.FinancedAmount.ShortFormat}
          />
        }
        {/* display Deposit if applicable */}
        {!_.isNil(fetchedRequestData.Pricing.Deposit) && !_.isEmpty(fetchedRequestData.Pricing.Deposit) &&
          <ConceptRow
            show={fetchedRequestData.Pricing.Deposit.Amount}
            concept={translations.totalSection.total.concepts.deposit}
            amount={fetchedRequestData.Pricing.Deposit.ShortFormat}
          />
        }
      </ConceptsContainer>
      <TotalRow>
        <P_Lrg_Bold>{translations.totalSection.total.concepts.total}</P_Lrg_Bold>
        {/* if Total > 0 display Total long format amount, else custom display To Be Determined */}
        {fetchedRequestData.Pricing.Subtotal.Amount > 0 ?
          <P_Lrg_Bold>{fetchedRequestData.Pricing.Total.LongFormat}</P_Lrg_Bold>
          :
          <P_Lrg_Bold>{translations.totalSection.total.concepts.toBeDetermined}</P_Lrg_Bold>
        }
      </TotalRow>

      {/* Middle section to conditionally display financing message */}
      {(!_.isNil(fetchedRequestData.Pricing.FinancedAmount) && !_.isEmpty(fetchedRequestData.Pricing.FinancedAmount) && fetchedRequestData.Pricing.FinancedAmount.Amount > 0) ?
        <P_Micro style={{ color: "#6E727C", textAlign: "center", width: "100%" }}>
          {translations.totalSection.total.financedMessage.messageOne} {fetchedRequestData.Pricing.FinancedAmount.ShortFormat} {translations.totalSection.total.financedMessage.messageTwo} {fetchedRequestData.Request.Program.PartnerName}.
        </P_Micro>
        :
        null
      }

      {/* Middle section to conditionally display logo (InstallationCredit > 0) or nothing or custom message (CUSTOM Tier)*/}
      {(fetchedRequestData.Pricing.InstallationCredit.Amount > 0 || fetchedRequestData.Pricing.SponsoredCredit.Amount > 0) && fetchedRequestData.Request.Program.CreditDescripiton ?
        <Fragment>
          <TotalDivider />
          <CreditsContainer>
            <P>{fetchedRequestData.Request.Program.CreditDescripiton}</P>
            {fetchedRequestData.Request.Program.Logo &&
              <CreditsLogo>
                <LogoImg src={fetchedRequestData.Request.Program.Logo} alt="No Image" />
              </CreditsLogo>
            }
          </CreditsContainer>
        </Fragment>
        :
        null
      }

      {fetchedRequestData.Request.Tier.Code === "CUSTOM" && fetchedRequestData.Pricing.Subtotal.Amount == 0 &&
        <Fragment>
          <TotalDivider />
          <CreditsContainer custom>
            <H2>{translations.totalSection.total.concepts.customTitle}</H2>
            <P>{translations.totalSection.total.concepts.customMessage}</P>
          </CreditsContainer>
        </Fragment>
      }
    </Fragment>
  )
}
