import styled, { css } from "styled-components"

const Card = styled.div`
  width: 600px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14),
    0px 4px 5px 0px rgba(0, 0, 0, 0.12),
    0px 1px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  padding: 24px 24px 24px 24px;
  margin: auto;
  margin-top: 148px;
`;

const RequestName = styled.p`
  width: 343px;
  height: 20px;
  color: rgb(62, 65, 72);
  font-size: 16px;
  font-family: Lato;
  font-weight: bold;
  line-height: 20px;
`;

const RequestId = styled.p`
  width: 343px;
  height: 16px;
  color: rgb(110, 114, 124);
  font-size: 12px;
  font-family: Lato;
  font-weight: normal;
  letter-spacing: 0.25px;
  line-height: 16px;
`;

const CompanyMessage = styled.p`
  width: 552px;
  height: 20px;
  color: rgb(62, 65, 72);
  font-size: 16px;
  font-family: Lato;
  font-weight: normal;
  line-height: 20px;
  margin-top: 16px;
`;

const TierChecksContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 22px;
`;

const TierTextContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const TierText = styled.p`
  color: #3e4148;
  font-size: 14px;
  font-weight: normal;
  height: 20px;
  letter-spacing: 0.25px;
  line-height: 20px;
  margin: 14px 0 4px 40px;

  ${props => props.gray && css`
    font-style: italic;
    color: #b9bbc1;
    margin-left: 0;
`}
`;

const TierTextArea = styled.textarea`
  width: 472px;
  height: 64px;
  background: rgb(250, 250, 250);
  border: 1px solid rgb(222, 223, 226);
  border-radius: 4px;
  margin-left: 40px;

  &::placeholder {
    color: rgba(62, 65, 72, 0.35);
    font-family: Lato;
    font-size: 16px;
    font-weight: normal;
    height: 20px;
    line-height: 20px;
  }
`;

const Button = styled.button`
  background: linear-gradient(-180deg, rgb(255, 87, 34) 0%, rgb(244, 81, 30) 100%);
  border-radius: 100px;
  border: 1px solid rgb(244, 81, 30);
  height: 40px;
  width: 200px;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  margin: 24px 176px 0 176px;
  cursor: pointer;

  ${props => props.disabled && css`
    background: rgb(255, 204, 188);
    color: rgb(255, 171, 145);
    border: none;
    
   `}
`;

const OptionalTag = styled.span`
  font-style: italic;
  color: #b9bbc1;
  margin-left: 4px;
`;


export default {
    Card,
    RequestName,
    RequestId,
    CompanyMessage,
    TierChecksContainer,
    TierTextContainer,
    TierText,
    TierTextArea,
    Button,
    OptionalTag,
}