import React, { Component, Fragment } from "react";
import styledComponents from "./style";
import QuickCard from "../../components/QuickCard";
import Collapse from "../../components/Collapse";
import AllDone from "../AllDone";
import { Button } from "../../shared/style";
import _ from "lodash";

const {
  HeadTitle,
  ButtonWrapper,
  AddItem,
  TitleWrap,
  BidsContainer,
  TextWrap,
  Title,
  SubTitle
} = styledComponents;

/** Component BidsList renders collapse-able dropdowns for every activity to get updates */
class BidsList extends Component {
  state = {
    allDoneView: false
  };

  /**
    function bidTitle =>
            input: activity, header
            - return : component for header title and status 
  */
  bidTitle = (activity, header) => {
    const { subTitle, status } = header;
    return (
      <TextWrap>
        <Title>{activity.Name}</Title>
        <SubTitle className={status ? status : ""}>{subTitle}</SubTitle>
      </TextWrap>
    );
  };

  allDone = () => {
    if (this.props.afterDone) {
      this.props.afterDone();
      return;
    }
    this.setState({ allDoneView: true });
  };

  /** Renders collapse-able dropdowns for every activity to get/set updates */
  render() {
    const {
      activities,
      finished,
      header,
      forceCollapseIndex,
      itemHeaders,
      setView,
      ptHead
    } = this.props;
    const { allDoneView } = this.state;

    if (allDoneView) {
      return <AllDone />;
    } else {
      return (
        <QuickCard>
          <TitleWrap pt={(ptHead || ptHead === 0) && `${ptHead}`}>
            <HeadTitle>{header}</HeadTitle>
            <AddItem>{activities.length} Items</AddItem>
          </TitleWrap>
          <BidsContainer>
            {activities.map((activity, index) => (
              <Collapse
                mbc={16}
                key={index}
                wrapperContent={
                  !_.isEmpty(itemHeaders) &&
                  this.bidTitle(activity, itemHeaders[index])
                }
                startActive={index === 0}
                forceCollapse={forceCollapseIndex === index}
              >
                {setView(activity, index)}
              </Collapse>
            ))}
          </BidsContainer>
          {finished && (
            <ButtonWrapper>
              <Button primary action={true} onClick={this.allDone}>
                Done
              </Button>
            </ButtonWrapper>
          )}
        </QuickCard>
      );
    }
  }
}

export default BidsList;
