import styled, { css } from "styled-components";

const SuperContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: white;
    margin: 0px -16px;

    @media only screen and (max-height: 600px) {
        height: auto;
        width: 100vw;
    }

    @media (max-width: 600px){
        display: flex;
        align-items: center;
        background-color: white;
        width: auto;
        height: auto;
        margin: initial;
    }

    @media (min-width: 600px) and (max-height: 600px){
        height: 100vh;
    }
`;


const MainContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media only screen and (max-height: 710px) {
        height: 100%;
    }

    @media (max-width: 600px){
        width: auto;
        height: auto;
    }

    @media (max-width: 1200px){
        width: auto;
        height: auto;
    }
`;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1280px;
    height: 735px;

    @media (max-width: 600px){
        flex-direction: column;
        width: auto;
        min-height: 100vh;
        height: auto;
    }

    @media (max-width: 1280px){
        width: auto;
    }

    @media (min-width: 600px) and (max-height: 600px){
        height: 100%;
    }
`;

const MobileLogoContainer = styled.div`
    display: none; 

    @media (max-width: 600px){
        width: 100%;
        display: flex;
        justify-content: center;
        box-shadow: inset 0px -1px 0px 0px rgb(237, 238, 239);
        position: fixed;
        z-index: 100;
        background-color: white;
    }
`;

const MobileLogo = styled.img`
    display: none;

    @media (max-width: 600px){
        margin: 0 auto;
        max-height: 24px;
        width: auto;
        display: block;
        margin: 24px 0px;
    }
`;

const PreviewContainer = styled.div`
    width: 600px;
    align-self: flex-start;
    margin-left: 88px;
    margin-top: 56px;
    padding: 24px;

    @media (max-width: 600px){
        margin: 0px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 16px;
        width: 100%;
        height: auto;
        position: relative;
        top: 72px;
        margin-bottom: 80px;
    }

    @media (min-width: 1281px){
        padding-left: 0px;
    }

    @media (max-width: 1280px){
        margin-left: 0px;
    }
`;

const PreviewTitle = styled.h2`
    color: rgb(62, 65, 72);
    font-family: Montserrat;
    display: block;
    height: 24px;
    line-height: 24px;
    margin: 0px;
    font-size: 18px;
    font-weight: bold;
`;

const PreviewDescription = styled.p`
    display: block;
    height: 20px;
    line-height: 20px;
    margin: 0px;
    font-size: 14px;
    font-weight: normal;
    font-style: italic;
    padding: 0px;
    margin-bottom: 24px;
    color: rgba(62, 65, 72, 0.7);
`;

const RevisedOnFlag = styled.div`
    height: 36px;
    background: rgba(255, 236, 179, 0.95);
    border-radius: 0px;
    margin-bottom: 24px;
    color: rgb(255, 111, 0);
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    display: flex;
    align-items: center;
    width: 552px;

    i {
        padding: 0px 8px 0px 12px;
    }

    @media (max-width: 600px){
        height: 56px;
        width: 100%;
    }
`;

const RevisedOnMessage = styled.p`
    color: rgb(255, 111, 0);
    margin: 0px;
    padding: 0px;
    width: auto;

    @media (max-width: 600px){
        padding: 8px 8px 8px 0px;
    }
`;

const Table = styled.table`
    color: rgb(62, 65, 72);
    font-size: 12px;
    font-family: Lato;
    width: 552px;

    @media (max-width: 600px){
       width: 100%;
    }
`;

const TableHeader = styled.tr`

    th{
        height: 32px;
        font-weight: bold;
        box-shadow: inset 0px -1px 0px 0px rgb(222, 223, 226);
        padding: 0px;
    }
    
    .first-column{
        text-align: left;
        width: 192px;

        @media (max-width: 600px){
            padding: 8px 8px 8px 0px;
        }
    }

    .second-column{
        text-align: center;
        width: 81px;

        @media (max-width: 600px){
            padding: 8px 8px 8px 0px;
        }
    }

    .third-column{
        text-align: right;
        width: 85px;

        @media (max-width: 600px){
            padding: 8px 8px 8px 0px;
        }
    }
`; 

const TableRow = styled.tr`

    td{
        height: 48px;   
        padding: 0px;
    }
   
    .first-column{
        text-align: left;
        width: 296px;

        @media (max-width: 600px){
            width: 192px;
        }
    }

    .second-column{
        text-align: center;
        width: 125px;

        @media (max-width: 600px){
            width: 81px;
        }
    }

    .third-column{
        text-align: right;
        width: 131px;

        @media (max-width: 600px){
            width: 85px;
        }
    }

    .credit{
        padding-left: 24px;
        color: rgba(62, 65, 72, 0.35);
    }
`; 

const TableCurrencyRow = styled.tr`

    td{
        height: 20px;  
        padding: 0px; 
    }

    .currency-field{
        text-align: right;
        width: 131px;
        vertical-align: top;
        text-align: right;
        color: rgba(62, 65, 72, 0.35);
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
    }
`; 

const TableTotalRow = styled.tr`

    td{
        height: 32px;
        padding: 24px 0px 0px 0px;   
    }

    .total-field{
        width: 106px;
        color: rgb(62, 65, 72);
        font-family: Montserrat
        font-size: 24px;
        font-weight: bold;
        text-align: right;
        line-height: 32px;
        padding-top: 24px;

        @media (max-width: 600px){
            width: 85px;
            font-size: 16px;
            height: 20px;
            line-height: 20px;
        }
    }

    .label-field{
        font-size: 14px;
        height: 20px;
        width: 125px;
        text-align: right;
        padding-right: 48px;
        color: rgba(62, 65, 72, 0.35);
        padding-top: 24px;
        line-height: 20px;
    }
`; 

const TableSubtotalRow = styled.tr`

    td{
        height: 20px;
        padding: 24px 0px 0px 0px;   
    }

    .subtotal-field{
        text-align: right;
        width: 131px;
        font-size: 16px;
        padding-top: 24px;
        line-height: 20px;

        @media (max-width: 600px){
            
        }
    }

    .label-field{
        font-size: 14px;
        height: 20px;
        width: 125px;
        text-align: right;
        padding-right: 48px;
        color: rgba(62, 65, 72, 0.35);
        padding-top: 24px;
        line-height: 20px;
    }
`; 

const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 24px;

    @media only screen and (max-height: 710px) {
        margin-top: 32px;
    }

    @media (max-width: 600px){
        display: none;
    }
`;

const Logo = styled.img`
    display: block;
    margin: 0 auto;
    height: 24px;
    width: 81px;
    object-fit: contain;
    margin-bottom: 24px;
    position: absolute;
    bottom: 0px;

    // @media only screen and (max-height: 710px) {
    //     position: initial;
    //     bottom: none;
    // }

    @media (max-width: 600px){
        display: none;
    }
`;

const PaymentContainer = styled.div`
    margin-right: 88px;
    @media (max-width: 600px){
        margin-right: 0px;
    }

    @media (max-width: 1280px){
        margin-right: 0px;
    }
`;

export default {
    SuperContainer,
    MainContainer,
    Container,
    MobileLogoContainer,
    MobileLogo,
    PreviewContainer,
    PreviewTitle,
    PreviewDescription,
    RevisedOnFlag,
    RevisedOnMessage,
    Table,
    TableHeader,
    TableRow,
    TableTotalRow,
    TableSubtotalRow,
    TableCurrencyRow,
    LogoContainer,
    Logo,
    PaymentContainer,
}
