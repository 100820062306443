import React from 'react';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';
import styledComponents from './styles';

const {
  P_SM,
  P_Bold
} = commonStyles;

const {
  Button,
  ButtonText
} = styledComponents;

export default function OptionButton({ isActive, onClickFxn, primaryText, secondaryText }) {
  return (
    <Button onClick={onClickFxn} active={isActive}>
      <ButtonText>
        <P_Bold className='pBold'>{primaryText}</P_Bold>
        <P_SM className='pSm'>{secondaryText}</P_SM>
      </ButtonText>
    </Button>
  )
}
