export default [
  {
    User: {
      CompanyName: "AshbyKTech",
      Name: "adam ashbyqakteck"
    },
    Type: "Nearby",
    Activities: [
      {
        MasterActivityId: 22072,
        ParentActivityId: null,
        DerivedActivityId: null,
        GeneratedActivityId: null,
        Name: "Site Readiness Review",
        Program: {
          Id: 4430,
          Name: "QMerit Default Program",
          DBAName: "AshbyKTech",
          ExternalData: null
        },
        OwnerProgram: {
          Id: 4430,
          Name: "QMerit Default Program",
          DBAName: "AshbyKTech",
          ExternalData: null
        },
        ActivityTemplateId: 1491,
        Priority: {
          Id: 5,
          Description: "Medium"
        },
        Status: {
          Id: "New",
          Name: "New"
        },
        StartBy: null,
        StartNoLaterThan: null,
        Assignment: {},
        Description:
          "Evaluate the installation site and collect enough information to create an acurate proposal.",
        Location: {
          Id: 3066,
          Name: "WHS",
          Description: null,
          Address: "2 Meadowbrook",
          City: "Irvine",
          State: "California",
          Zip: "92604",
          Country: "United States",
          Latitude: 31.6783988,
          Longitude: -111.8064609,
          Contact: {
            Id: null,
            FirstName: null,
            LastName: null,
            Email: null,
            PhoneNumber: null,
            PreferredMethod: null,
            Title: null,
            Address: null,
            ImageUrl: null
          }
        },
        Customer: {
          Id: 3256,
          Name: " High",
          Type: "Company",
          Contact: {
            Id: null,
            FirstName: "woodbridge",
            LastName: "irvine",
            Email: "woodbridge@na.com",
            PhoneNumber: "null",
            PreferredMethod: "null",
            Title: "null",
            Address: "null",
            ImageUrl: null
          }
        },
        DueDate: "2019-08-15T17:03:19.0854732",
        Tasks: {
          Id: "b",
          Name: "Perform site readiness review",
          Status: "New",
          CanComplete: true,
          Events: [],
          Actions: [
            {
              CustomInt1: 59,
              CustomString1:
                "https://survey2.qmerit.com/newsurvey/3?guid=22076-b-1b",
              CustomString2: "",
              Files: [],
              MultiDocument: null,
              ProjectDocument: null,
              Id: "1b",
              Label:
                "The site survey will assist you with gathering all the necessary information for creating an accurate proposal for the customer. You will be able to add as many charging stations, electrical runs, and power sources necessary for the customer's unique installation needs.",
              Type: "Survey",
              IsCompleted: false
            }
          ],
          Tasks: [],
          Next: null
        },
        Events: [],
        Owner: {
          Id: 8284,
          DBAName: "AshbyKTech",
          ImageUrl: null
        },
        CompletionPercentage: 0,
        Type: "Activity",
        Project: {
          Id: 12484,
          Name: " session pre-assign",
          DueDate: null,
          Status: "New",
          ModifiedOn: "2019-03-19T22:01:28.4083924",
          Attachments: []
        },
        Collaborators: [],
        SelectedOn: null,
        CreatedOn: "2019-03-19T22:00:42.433",
        IsFrozen: false,
        AcceptedBy: null,
        Id: 22080
      },
      {
        MasterActivityId: 22075,
        ParentActivityId: null,
        DerivedActivityId: null,
        GeneratedActivityId: null,
        Name: "Site Readiness Review",
        Program: {
          Id: 4430,
          Name: "QMerit Default Program",
          DBAName: "AshbyKTech",
          ExternalData: null
        },
        OwnerProgram: {
          Id: 4430,
          Name: "QMerit Default Program",
          DBAName: "AshbyKTech",
          ExternalData: null
        },
        ActivityTemplateId: 1491,
        Priority: {
          Id: 5,
          Description: "Medium"
        },
        Status: {
          Id: "New",
          Name: "New"
        },
        StartBy: null,
        StartNoLaterThan: null,
        Assignment: {},
        Description:
          "Evaluate the installation site and collect enough information to create an acurate proposal.",
        Location: {
          Id: 3066,
          Name: "WHS",
          Description: null,
          Address: "2 Meadowbrook",
          City: "Irvine",
          State: "California",
          Zip: "92604",
          Country: "United States",
          Latitude: 36.6783988,
          Longitude: -112.8064609,
          Contact: {
            Id: null,
            FirstName: null,
            LastName: null,
            Email: null,
            PhoneNumber: null,
            PreferredMethod: null,
            Title: null,
            Address: null,
            ImageUrl: null
          }
        },
        Customer: {
          Id: 3256,
          Name: "High High",
          Type: "Company",
          Contact: {
            Id: null,
            FirstName: "woodbridge",
            LastName: "irvine",
            Email: "woodbridge@na.com",
            PhoneNumber: null,
            PreferredMethod: null,
            Title: null,
            Address: null,
            ImageUrl: null
          }
        },
        DueDate: "2019-08-15T08:03:19.0854732",
        Tasks: {
          Id: "b",
          Name: "Perform site readiness review",
          Status: "New",
          CanComplete: true,
          Events: [],
          Actions: [
            {
              CustomInt1: 59,
              CustomString1:
                "https://survey2.qmerit.com/newsurvey/3?guid=22076-b-1b",
              CustomString2: "",
              Files: [],
              MultiDocument: null,
              ProjectDocument: null,
              Id: "1b",
              Label:
                "The site survey will assist you with gathering all the necessary information for creating an accurate proposal for the customer. You will be able to add as many charging stations, electrical runs, and power sources necessary for the customer's unique installation needs.",
              Type: "Survey",
              IsCompleted: false
            }
          ],
          Tasks: [],
          Next: null
        },
        Events: [],
        Owner: {
          Id: 8284,
          DBAName: "AshbyKTech",
          ImageUrl: null
        },
        CompletionPercentage: 0,
        Type: "Activity",
        Project: {
          Id: 12484,
          Name: "session depre",
          DueDate: null,
          Status: "New",
          ModifiedOn: "2019-03-19T22:01:28.4083924",
          Attachments: []
        },
        Collaborators: [],
        SelectedOn: null,
        CreatedOn: "2019-03-19T22:00:42.433",
        IsFrozen: false,
        AcceptedBy: null,
        Id: 22084
      },
      {
        MasterActivityId: 22073,
        ParentActivityId: null,
        DerivedActivityId: null,
        GeneratedActivityId: null,
        Name: "Site Readiness Review",
        Program: {
          Id: 4430,
          Name: "QMerit Default Program",
          DBAName: "AshbyKTech",
          ExternalData: null
        },
        OwnerProgram: {
          Id: 4430,
          Name: "QMerit Default Program",
          DBAName: "AshbyKTech",
          ExternalData: null
        },
        ActivityTemplateId: 1491,
        Priority: {
          Id: 5,
          Description: "Medium"
        },
        Status: {
          Id: "New",
          Name: "New"
        },
        StartBy: null,
        StartNoLaterThan: null,
        Assignment: {},
        Description:
          "Evaluate the installation site and collect enough information to create an acurate proposal.",
        Location: {
          Id: 3066,
          Name: "WHS",
          Description: null,
          Address: "2 Meadowbrook",
          City: "Irvine",
          State: "California",
          Zip: "92604",
          Country: "United States",
          Latitude: 38.7783988,
          Longitude: -117.8064609,
          Contact: {
            Id: null,
            FirstName: null,
            LastName: null,
            Email: null,
            PhoneNumber: null,
            PreferredMethod: null,
            Title: null,
            Address: null,
            ImageUrl: null
          }
        },
        Customer: {
          Id: 3256,
          Name: "Woodbridge High",
          Type: "Company",
          Contact: {
            Id: null,
            FirstName: "woodbridge",
            LastName: "irvine",
            Email: "woodbridge@na.com",
            PhoneNumber: null,
            PreferredMethod: null,
            Title: null,
            Address: null,
            ImageUrl: null
          }
        },
        DueDate: "2019-04-22T22:03:19.0854732",
        Tasks: {
          Id: "b",
          Name: "Perform site readiness review",
          Status: "New",
          CanComplete: true,
          Events: [],
          Actions: [
            {
              CustomInt1: 59,
              CustomString1:
                "https://survey2.qmerit.com/newsurvey/3?guid=22076-b-1b",
              CustomString2: "",
              Files: [],
              MultiDocument: null,
              ProjectDocument: null,
              Id: "1b",
              Label:
                "The site survey will assist you with gathering all the necessary information for creating an accurate proposal for the customer. You will be able to add as many charging stations, electrical runs, and power sources necessary for the customer's unique installation needs.",
              Type: "Survey",
              IsCompleted: false
            }
          ],
          Tasks: [],
          Next: null
        },
        Events: [],
        Owner: {
          Id: 8284,
          DBAName: "AshbyKTech",
          ImageUrl: null
        },
        CompletionPercentage: 0,
        Type: "Activity",
        Project: {
          Id: 12484,
          Name: " High EV Stations pre-assign",
          DueDate: null,
          Status: "New",
          ModifiedOn: "2019-03-19T22:01:28.4083924",
          Attachments: []
        },
        Collaborators: [],
        SelectedOn: null,
        CreatedOn: "2019-03-19T22:00:42.433",
        IsFrozen: false,
        AcceptedBy: null,
        Id: 22089
      },

      {
        MasterActivityId: 22074,
        ParentActivityId: null,
        DerivedActivityId: null,
        GeneratedActivityId: null,
        Name: "Site Readiness Review",
        Program: {
          Id: 4430,
          Name: "QMerit Default Program",
          DBAName: "AshbyKTech",
          ExternalData: null
        },
        OwnerProgram: {
          Id: 4430,
          Name: "QMerit Default Program",
          DBAName: "AshbyKTech",
          ExternalData: null
        },
        ActivityTemplateId: 1491,
        Priority: {
          Id: 5,
          Description: "Medium"
        },
        Status: {
          Id: "New",
          Name: "New"
        },
        StartBy: null,
        StartNoLaterThan: null,
        Assignment: {
          Id: 12551,
          Name: "adam ashbyqakteck",
          Type: "Employee",
          ImageUrl: null,
          Contact: {
            Id: 12551,
            FirstName: "adam",
            LastName: "ashbyqakteck",
            Email: "ashbyqaktech+adam@gmail.com",
            PhoneNumber: "+19494364242",
            PreferredMethod: null,
            Title: null,
            Address: "",
            ImageUrl: null
          }
        },
        Description:
          "Evaluate the installation site and collect enough information to create an acurate proposal.",
        Location: {
          Id: 3066,
          Name: "WHS",
          Description: null,
          Address: "factory area",
          City: "patiala",
          State: "punjab",
          Zip: "147001",
          Country: "India",
          Latitude: 33.1383988,
          Longitude: -117.140646,
          Contact: {
            Id: null,
            FirstName: null,
            LastName: null,
            Email: null,
            PhoneNumber: null,
            PreferredMethod: null,
            Title: null,
            Address: null,
            ImageUrl: null
          }
        },
        Customer: {
          Id: 3256,
          Name: "dbridge High",
          Type: "Company",
          Contact: {
            Id: null,
            FirstName: "woodbridge",
            LastName: "irvine",
            Email: "woodbridge@na.com",
            PhoneNumber: null,
            PreferredMethod: null,
            Title: null,
            Address: null,
            ImageUrl: null
          }
        },
        DueDate: "2019-04-02T22:03:19.0854732",
        Tasks: {
          Id: "b",
          Name: "Perform site readiness review",
          Status: "New",
          CanComplete: true,
          Events: [],
          Actions: [
            {
              CustomInt1: 59,
              CustomString1:
                "https://survey2.qmerit.com/newsurvey/3?guid=22076-b-1b",
              CustomString2: "",
              Files: [],
              MultiDocument: null,
              ProjectDocument: null,
              Id: "1b",
              Label:
                "The site survey will assist you with gathering all the necessary information for creating an accurate proposal for the customer. You will be able to add as many charging stations, electrical runs, and power sources necessary for the customer's unique installation needs.",
              Type: "Survey",
              IsCompleted: false
            }
          ],
          Tasks: [],
          Next: null
        },
        Events: [],
        Owner: {
          Id: 8284,
          DBAName: "AshbyKTech",
          ImageUrl: null
        },
        CompletionPercentage: 0,
        Type: "Activity",
        Project: {
          Id: 12484,
          Name: " EV Stations pre-assign",
          DueDate: null,
          Status: "New",
          ModifiedOn: "2019-03-19T22:01:28.4083924",
          Attachments: []
        },
        Collaborators: [],
        SelectedOn: null,
        CreatedOn: "2019-03-19T22:00:42.433",
        IsFrozen: false,
        AcceptedBy: null,
        Id: 22077
      },
      {
        MasterActivityId: 22071,
        ParentActivityId: null,
        DerivedActivityId: null,
        GeneratedActivityId: null,
        Name: "Site Readiness Review",
        Program: {
          Id: 4430,
          Name: "QMerit Default Program",
          DBAName: "AshbyKTech",
          ExternalData: null
        },
        OwnerProgram: {
          Id: 4430,
          Name: "QMerit Default Program",
          DBAName: "AshbyKTech",
          ExternalData: null
        },
        ActivityTemplateId: 1491,
        Priority: {
          Id: 5,
          Description: "Medium"
        },
        Status: {
          Id: "New",
          Name: "New"
        },
        StartBy: null,
        StartNoLaterThan: null,
        Assignment: null,
        Description:
          "Evaluate the installation site and collect enough information to create an acurate proposal.",
        Location: {
          Id: 3066,
          Name: "WHS",
          Description: null,
          Address: "urban estate",
          City: "patiala",
          State: "punjab",
          Zip: "147001",
          Country: "India",
          Latitude: 34.9783988,
          Longitude: -117.3064609,
          Contact: {
            Id: null,
            FirstName: null,
            LastName: null,
            Email: null,
            PhoneNumber: null,
            PreferredMethod: null,
            Title: null,
            Address: null,
            ImageUrl: null
          }
        },
        Customer: {
          Id: 3256,
          Name: " High",
          Type: "Company",
          Contact: {
            Id: null,
            FirstName: "woodbridge",
            LastName: "irvine",
            Email: "woodbridge@na.com",
            PhoneNumber: null,
            PreferredMethod: null,
            Title: null,
            Address: null,
            ImageUrl: null
          }
        },
        DueDate: "2019-04-02T22:03:19.0854732",
        Tasks: {
          Id: "b",
          Name: "Perform site readiness review",
          Status: "New",
          CanComplete: true,
          Events: [],
          Actions: [
            {
              CustomInt1: 59,
              CustomString1:
                "https://survey2.qmerit.com/newsurvey/3?guid=22076-b-1b",
              CustomString2: "",
              Files: [],
              MultiDocument: null,
              ProjectDocument: null,
              Id: "1b",
              Label:
                "The site survey will assist you with gathering all the necessary information for creating an accurate proposal for the customer. You will be able to add as many charging stations, electrical runs, and power sources necessary for the customer's unique installation needs.",
              Type: "Survey",
              IsCompleted: false
            }
          ],
          Tasks: [],
          Next: null
        },
        Events: [],
        Owner: {
          Id: 8284,
          DBAName: "AshbyKTech",
          ImageUrl: null
        },
        CompletionPercentage: 0,
        Type: "Activity",
        Project: {
          Id: 12484,
          Name: "Woodbridge High EV Stations pre-assign",
          DueDate: null,
          Status: "New",
          ModifiedOn: "2019-03-19T22:01:28.4083924",
          Attachments: []
        },
        Collaborators: [],
        SelectedOn: null,
        CreatedOn: "2019-03-19T22:00:42.433",
        IsFrozen: false,
        AcceptedBy: null,
        Id: 22078
      }
      // {
      //   MasterActivityId: 22076,
      //   ParentActivityId: null,
      //   DerivedActivityId: null,
      //   GeneratedActivityId: null,
      //   Name: "Site Readiness Review",
      //   Program: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   OwnerProgram: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   ActivityTemplateId: 1491,
      //   Priority: {
      //     Id: 5,
      //     Description: "Medium"
      //   },
      //   Status: {
      //     Id: "New",
      //     Name: "New"
      //   },
      //   StartBy: null,
      //   StartNoLaterThan: null,
      //   Assignment: {},
      //   Description:
      //     "Evaluate the installation site and collect enough information to create an acurate proposal.",
      //   Location: {
      //     Id: 3066,
      //     Name: "WHS",
      //     Description: null,
      //     Address: "2 Meadowbrook",
      //     City: "Irvine",
      //     State: "California",
      //     Zip: "92604",
      //     Country: "United States",
      //     Latitude: 31.6783988,
      //     Longitude: -111.8064609,
      //     Contact: {
      //       Id: null,
      //       FirstName: null,
      //       LastName: null,
      //       Email: null,
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   Customer: {
      //     Id: 3256,
      //     Name: "Woodbridge High",
      //     Type: "Company",
      //     Contact: {
      //       Id: null,
      //       FirstName: "woodbridge",
      //       LastName: "irvine",
      //       Email: "woodbridge@na.com",
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   DueDate: "2019-04-02T22:03:19.0854732",
      //   Tasks: {
      //     Id: "b",
      //     Name: "Perform site readiness review",
      //     Status: "New",
      //     CanComplete: true,
      //     Events: [],
      //     Actions: [
      //       {
      //         CustomInt1: 59,
      //         CustomString1:
      //           "https://survey2.qmerit.com/newsurvey/3?guid=22076-b-1b",
      //         CustomString2: "",
      //         Files: [],
      //         MultiDocument: null,
      //         ProjectDocument: null,
      //         Id: "1b",
      //         Label:
      //           "The site survey will assist you with gathering all the necessary information for creating an accurate proposal for the customer. You will be able to add as many charging stations, electrical runs, and power sources necessary for the customer's unique installation needs.",
      //         Type: "Survey",
      //         IsCompleted: false
      //       }
      //     ],
      //     Tasks: [],
      //     Next: null
      //   },
      //   Events: [],
      //   Owner: {
      //     Id: 8284,
      //     DBAName: "AshbyKTech",
      //     ImageUrl: null
      //   },
      //   CompletionPercentage: 0,
      //   Type: "Activity",
      //   Project: {
      //     Id: 12484,
      //     Name: " High EV Stations pre-assign",
      //     DueDate: null,
      //     Status: "New",
      //     ModifiedOn: "2019-03-19T22:01:28.4083924",
      //     Attachments: []
      //   },
      //   Collaborators: [],
      //   SelectedOn: null,
      //   CreatedOn: "2019-03-19T22:00:42.433",
      //   IsFrozen: false,
      //   AcceptedBy: null,
      //   Id: 220850
      // },
      // {
      //   MasterActivityId: 22076,
      //   ParentActivityId: null,
      //   DerivedActivityId: null,
      //   GeneratedActivityId: null,
      //   Name: "Site Readiness Review",
      //   Program: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   OwnerProgram: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   ActivityTemplateId: 1491,
      //   Priority: {
      //     Id: 5,
      //     Description: "Medium"
      //   },
      //   Status: {
      //     Id: "New",
      //     Name: "New"
      //   },
      //   StartBy: null,
      //   StartNoLaterThan: null,
      //   Assignment: {},
      //   Description:
      //     "Evaluate the installation site and collect enough information to create an acurate proposal.",
      //   Location: {
      //     Id: 3066,
      //     Name: "WHS",
      //     Description: null,
      //     Address: "2 Meadowbrook",
      //     City: "Irvine",
      //     State: "California",
      //     Zip: "92604",
      //     Country: "United States",
      //     Latitude: 36.6783988,
      //     Longitude: -112.8064609,
      //     Contact: {
      //       Id: null,
      //       FirstName: null,
      //       LastName: null,
      //       Email: null,
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   Customer: {
      //     Id: 3256,
      //     Name: "Woodbridge High",
      //     Type: "Company",
      //     Contact: {
      //       Id: null,
      //       FirstName: "woodbridge",
      //       LastName: "irvine",
      //       Email: "woodbridge@na.com",
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   DueDate: "2019-04-02T22:03:19.0854732",
      //   Tasks: {
      //     Id: "b",
      //     Name: "Perform site readiness review",
      //     Status: "New",
      //     CanComplete: true,
      //     Events: [],
      //     Actions: [
      //       {
      //         CustomInt1: 59,
      //         CustomString1:
      //           "https://survey2.qmerit.com/newsurvey/3?guid=22076-b-1b",
      //         CustomString2: "",
      //         Files: [],
      //         MultiDocument: null,
      //         ProjectDocument: null,
      //         Id: "1b",
      //         Label:
      //           "The site survey will assist you with gathering all the necessary information for creating an accurate proposal for the customer. You will be able to add as many charging stations, electrical runs, and power sources necessary for the customer's unique installation needs.",
      //         Type: "Survey",
      //         IsCompleted: false
      //       }
      //     ],
      //     Tasks: [],
      //     Next: null
      //   },
      //   Events: [],
      //   Owner: {
      //     Id: 8284,
      //     DBAName: "AshbyKTech",
      //     ImageUrl: null
      //   },
      //   CompletionPercentage: 0,
      //   Type: "Activity",
      //   Project: {
      //     Id: 12484,
      //     Name: " High EV Stations pre-assign",
      //     DueDate: null,
      //     Status: "New",
      //     ModifiedOn: "2019-03-19T22:01:28.4083924",
      //     Attachments: []
      //   },
      //   Collaborators: [],
      //   SelectedOn: null,
      //   CreatedOn: "2019-03-19T22:00:42.433",
      //   IsFrozen: false,
      //   AcceptedBy: null,
      //   Id: 220384
      // },
      // {
      //   MasterActivityId: 22076,
      //   ParentActivityId: null,
      //   DerivedActivityId: null,
      //   GeneratedActivityId: null,
      //   Name: "Site Readiness Review",
      //   Program: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   OwnerProgram: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   ActivityTemplateId: 1491,
      //   Priority: {
      //     Id: 5,
      //     Description: "Medium"
      //   },
      //   Status: {
      //     Id: "New",
      //     Name: "New"
      //   },
      //   StartBy: null,
      //   StartNoLaterThan: null,
      //   Assignment: {},
      //   Description:
      //     "Evaluate the installation site and collect enough information to create an acurate proposal.",
      //   Location: {
      //     Id: 3066,
      //     Name: "WHS",
      //     Description: null,
      //     Address: "2 Meadowbrook",
      //     City: "Irvine",
      //     State: "California",
      //     Zip: "92604",
      //     Country: "United States",
      //     Latitude: 38.7783988,
      //     Longitude: -117.8064609,
      //     Contact: {
      //       Id: null,
      //       FirstName: null,
      //       LastName: null,
      //       Email: null,
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   Customer: {
      //     Id: 3256,
      //     Name: "Woodbridge High",
      //     Type: "Company",
      //     Contact: {
      //       Id: null,
      //       FirstName: "woodbridge",
      //       LastName: "irvine",
      //       Email: "woodbridge@na.com",
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   DueDate: "2019-04-22T22:03:19.0854732",
      //   Tasks: {
      //     Id: "b",
      //     Name: "Perform site readiness review",
      //     Status: "New",
      //     CanComplete: true,
      //     Events: [],
      //     Actions: [
      //       {
      //         CustomInt1: 59,
      //         CustomString1:
      //           "https://survey2.qmerit.com/newsurvey/3?guid=22076-b-1b",
      //         CustomString2: "",
      //         Files: [],
      //         MultiDocument: null,
      //         ProjectDocument: null,
      //         Id: "1b",
      //         Label:
      //           "The site survey will assist you with gathering all the necessary information for creating an accurate proposal for the customer. You will be able to add as many charging stations, electrical runs, and power sources necessary for the customer's unique installation needs.",
      //         Type: "Survey",
      //         IsCompleted: false
      //       }
      //     ],
      //     Tasks: [],
      //     Next: null
      //   },
      //   Events: [],
      //   Owner: {
      //     Id: 8284,
      //     DBAName: "AshbyKTech",
      //     ImageUrl: null
      //   },
      //   CompletionPercentage: 0,
      //   Type: "Activity",
      //   Project: {
      //     Id: 12484,
      //     Name: " High EV Stations pre-assign",
      //     DueDate: null,
      //     Status: "New",
      //     ModifiedOn: "2019-03-19T22:01:28.4083924",
      //     Attachments: []
      //   },
      //   Collaborators: [],
      //   SelectedOn: null,
      //   CreatedOn: "2019-03-19T22:00:42.433",
      //   IsFrozen: false,
      //   AcceptedBy: null,
      //   Id: 222089
      // },

      // {
      //   MasterActivityId: 22076,
      //   ParentActivityId: null,
      //   DerivedActivityId: null,
      //   GeneratedActivityId: null,
      //   Name: "Site Readiness Review",
      //   Program: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   OwnerProgram: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   ActivityTemplateId: 1491,
      //   Priority: {
      //     Id: 5,
      //     Description: "Medium"
      //   },
      //   Status: {
      //     Id: "New",
      //     Name: "New"
      //   },
      //   StartBy: null,
      //   StartNoLaterThan: null,
      //   Assignment: {
      //     Id: 12551,
      //     Name: "adam ashbyqakteck",
      //     Type: "Employee",
      //     ImageUrl: null,
      //     Contact: {
      //       Id: 12551,
      //       FirstName: "adam",
      //       LastName: "ashbyqakteck",
      //       Email: "ashbyqaktech+adam@gmail.com",
      //       PhoneNumber: "+19494364242",
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: "",
      //       ImageUrl: null
      //     }
      //   },
      //   Description:
      //     "Evaluate the installation site and collect enough information to create an acurate proposal.",
      //   Location: {
      //     Id: 3066,
      //     Name: "WHS",
      //     Description: null,
      //     Address: "factory area",
      //     City: "patiala",
      //     State: "punjab",
      //     Zip: "147001",
      //     Country: "India",
      //     Latitude: 34,
      //     Longitude: 76,
      //     Contact: {
      //       Id: null,
      //       FirstName: null,
      //       LastName: null,
      //       Email: null,
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   Customer: {
      //     Id: 3256,
      //     Name: "dbridge High",
      //     Type: "Company",
      //     Contact: {
      //       Id: null,
      //       FirstName: "woodbridge",
      //       LastName: "irvine",
      //       Email: "woodbridge@na.com",
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   DueDate: "2019-04-02T22:03:19.0854732",
      //   Tasks: {
      //     Id: "b",
      //     Name: "Perform site readiness review",
      //     Status: "New",
      //     CanComplete: true,
      //     Events: [],
      //     Actions: [
      //       {
      //         CustomInt1: 59,
      //         CustomString1:
      //           "https://survey2.qmerit.com/newsurvey/3?guid=22076-b-1b",
      //         CustomString2: "",
      //         Files: [],
      //         MultiDocument: null,
      //         ProjectDocument: null,
      //         Id: "1b",
      //         Label:
      //           "The site survey will assist you with gathering all the necessary information for creating an accurate proposal for the customer. You will be able to add as many charging stations, electrical runs, and power sources necessary for the customer's unique installation needs.",
      //         Type: "Survey",
      //         IsCompleted: false
      //       }
      //     ],
      //     Tasks: [],
      //     Next: null
      //   },
      //   Events: [],
      //   Owner: {
      //     Id: 8284,
      //     DBAName: "AshbyKTech",
      //     ImageUrl: null
      //   },
      //   CompletionPercentage: 0,
      //   Type: "Activity",
      //   Project: {
      //     Id: 12484,
      //     Name: " EV Stations pre-assign",
      //     DueDate: null,
      //     Status: "New",
      //     ModifiedOn: "2019-03-19T22:01:28.4083924",
      //     Attachments: []
      //   },
      //   Collaborators: [],
      //   SelectedOn: null,
      //   CreatedOn: "2019-03-19T22:00:42.433",
      //   IsFrozen: false,
      //   AcceptedBy: null,
      //   Id: 220774
      // },
      // {
      //   MasterActivityId: 22076,
      //   ParentActivityId: null,
      //   DerivedActivityId: null,
      //   GeneratedActivityId: null,
      //   Name: "Site Readiness Review",
      //   Program: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   OwnerProgram: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   ActivityTemplateId: 1491,
      //   Priority: {
      //     Id: 5,
      //     Description: "Medium"
      //   },
      //   Status: {
      //     Id: "New",
      //     Name: "New"
      //   },
      //   StartBy: null,
      //   StartNoLaterThan: null,
      //   Assignment: null,
      //   Description:
      //     "Evaluate the installation site and collect enough information to create an acurate proposal.",
      //   Location: {
      //     Id: 3066,
      //     Name: "WHS",
      //     Description: null,
      //     Address: "urban estate",
      //     City: "patiala",
      //     State: "punjab",
      //     Zip: "147001",
      //     Country: "India",
      //     Latitude: 34.5,
      //     Longitude: 76,
      //     Contact: {
      //       Id: null,
      //       FirstName: null,
      //       LastName: null,
      //       Email: null,
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   Customer: {
      //     Id: 3256,
      //     Name: " High",
      //     Type: "Company",
      //     Contact: {
      //       Id: null,
      //       FirstName: "woodbridge",
      //       LastName: "irvine",
      //       Email: "woodbridge@na.com",
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   DueDate: "2019-04-02T22:03:19.0854732",
      //   Tasks: {
      //     Id: "b",
      //     Name: "Perform site readiness review",
      //     Status: "New",
      //     CanComplete: true,
      //     Events: [],
      //     Actions: [
      //       {
      //         CustomInt1: 59,
      //         CustomString1:
      //           "https://survey2.qmerit.com/newsurvey/3?guid=22076-b-1b",
      //         CustomString2: "",
      //         Files: [],
      //         MultiDocument: null,
      //         ProjectDocument: null,
      //         Id: "1b",
      //         Label:
      //           "The site survey will assist you with gathering all the necessary information for creating an accurate proposal for the customer. You will be able to add as many charging stations, electrical runs, and power sources necessary for the customer's unique installation needs.",
      //         Type: "Survey",
      //         IsCompleted: false
      //       }
      //     ],
      //     Tasks: [],
      //     Next: null
      //   },
      //   Events: [],
      //   Owner: {
      //     Id: 8284,
      //     DBAName: "AshbyKTech",
      //     ImageUrl: null
      //   },
      //   CompletionPercentage: 0,
      //   Type: "Activity",
      //   Project: {
      //     Id: 12484,
      //     Name: "Woodbridge High EV Stations pre-assign",
      //     DueDate: null,
      //     Status: "New",
      //     ModifiedOn: "2019-03-19T22:01:28.4083924",
      //     Attachments: []
      //   },
      //   Collaborators: [],
      //   SelectedOn: null,
      //   CreatedOn: "2019-03-19T22:00:42.433",
      //   IsFrozen: false,
      //   AcceptedBy: null,
      //   Id: 220781
      // },
      // {
      //   MasterActivityId: 22076,
      //   ParentActivityId: null,
      //   DerivedActivityId: null,
      //   GeneratedActivityId: null,
      //   Name: "Site Readiness Review",
      //   Program: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   OwnerProgram: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   ActivityTemplateId: 1491,
      //   Priority: {
      //     Id: 5,
      //     Description: "Medium"
      //   },
      //   Status: {
      //     Id: "New",
      //     Name: "New"
      //   },
      //   StartBy: null,
      //   StartNoLaterThan: null,
      //   Assignment: {},
      //   Description:
      //     "Evaluate the installation site and collect enough information to create an acurate proposal.",
      //   Location: {
      //     Id: 3066,
      //     Name: "WHS",
      //     Description: null,
      //     Address: "2 Meadowbrook",
      //     City: "Irvine",
      //     State: "California",
      //     Zip: "92604",
      //     Country: "United States",
      //     Latitude: 31.6783988,
      //     Longitude: -111.8064609,
      //     Contact: {
      //       Id: null,
      //       FirstName: null,
      //       LastName: null,
      //       Email: null,
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   Customer: {
      //     Id: 3256,
      //     Name: "Woodbridge High",
      //     Type: "Company",
      //     Contact: {
      //       Id: null,
      //       FirstName: "woodbridge",
      //       LastName: "irvine",
      //       Email: "woodbridge@na.com",
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   DueDate: "2019-04-02T22:03:19.0854732",
      //   Tasks: {
      //     Id: "b",
      //     Name: "Perform site readiness review",
      //     Status: "New",
      //     CanComplete: true,
      //     Events: [],
      //     Actions: [
      //       {
      //         CustomInt1: 59,
      //         CustomString1:
      //           "https://survey2.qmerit.com/newsurvey/3?guid=22076-b-1b",
      //         CustomString2: "",
      //         Files: [],
      //         MultiDocument: null,
      //         ProjectDocument: null,
      //         Id: "1b",
      //         Label:
      //           "The site survey will assist you with gathering all the necessary information for creating an accurate proposal for the customer. You will be able to add as many charging stations, electrical runs, and power sources necessary for the customer's unique installation needs.",
      //         Type: "Survey",
      //         IsCompleted: false
      //       }
      //     ],
      //     Tasks: [],
      //     Next: null
      //   },
      //   Events: [],
      //   Owner: {
      //     Id: 8284,
      //     DBAName: "AshbyKTech",
      //     ImageUrl: null
      //   },
      //   CompletionPercentage: 0,
      //   Type: "Activity",
      //   Project: {
      //     Id: 12484,
      //     Name: " High EV Stations pre-assign",
      //     DueDate: null,
      //     Status: "New",
      //     ModifiedOn: "2019-03-19T22:01:28.4083924",
      //     Attachments: []
      //   },
      //   Collaborators: [],
      //   SelectedOn: null,
      //   CreatedOn: "2019-03-19T22:00:42.433",
      //   IsFrozen: false,
      //   AcceptedBy: null,
      //   Id: 2208011
      // },
      // {
      //   MasterActivityId: 22076,
      //   ParentActivityId: null,
      //   DerivedActivityId: null,
      //   GeneratedActivityId: null,
      //   Name: "Site Readiness Review",
      //   Program: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   OwnerProgram: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   ActivityTemplateId: 1491,
      //   Priority: {
      //     Id: 5,
      //     Description: "Medium"
      //   },
      //   Status: {
      //     Id: "New",
      //     Name: "New"
      //   },
      //   StartBy: null,
      //   StartNoLaterThan: null,
      //   Assignment: {},
      //   Description:
      //     "Evaluate the installation site and collect enough information to create an acurate proposal.",
      //   Location: {
      //     Id: 3066,
      //     Name: "WHS",
      //     Description: null,
      //     Address: "2 Meadowbrook",
      //     City: "Irvine",
      //     State: "California",
      //     Zip: "92604",
      //     Country: "United States",
      //     Latitude: 36.6783988,
      //     Longitude: -112.8064609,
      //     Contact: {
      //       Id: null,
      //       FirstName: null,
      //       LastName: null,
      //       Email: null,
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   Customer: {
      //     Id: 3256,
      //     Name: "Woodbridge High",
      //     Type: "Company",
      //     Contact: {
      //       Id: null,
      //       FirstName: "woodbridge",
      //       LastName: "irvine",
      //       Email: "woodbridge@na.com",
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   DueDate: "2019-04-02T22:03:19.0854732",
      //   Tasks: {
      //     Id: "b",
      //     Name: "Perform site readiness review",
      //     Status: "New",
      //     CanComplete: true,
      //     Events: [],
      //     Actions: [
      //       {
      //         CustomInt1: 59,
      //         CustomString1:
      //           "https://survey2.qmerit.com/newsurvey/3?guid=22076-b-1b",
      //         CustomString2: "",
      //         Files: [],
      //         MultiDocument: null,
      //         ProjectDocument: null,
      //         Id: "1b",
      //         Label:
      //           "The site survey will assist you with gathering all the necessary information for creating an accurate proposal for the customer. You will be able to add as many charging stations, electrical runs, and power sources necessary for the customer's unique installation needs.",
      //         Type: "Survey",
      //         IsCompleted: false
      //       }
      //     ],
      //     Tasks: [],
      //     Next: null
      //   },
      //   Events: [],
      //   Owner: {
      //     Id: 8284,
      //     DBAName: "AshbyKTech",
      //     ImageUrl: null
      //   },
      //   CompletionPercentage: 0,
      //   Type: "Activity",
      //   Project: {
      //     Id: 12484,
      //     Name: " High EV Stations pre-assign",
      //     DueDate: null,
      //     Status: "New",
      //     ModifiedOn: "2019-03-19T22:01:28.4083924",
      //     Attachments: []
      //   },
      //   Collaborators: [],
      //   SelectedOn: null,
      //   CreatedOn: "2019-03-19T22:00:42.433",
      //   IsFrozen: false,
      //   AcceptedBy: null,
      //   Id: 2208488
      // },
      // {
      //   MasterActivityId: 22076,
      //   ParentActivityId: null,
      //   DerivedActivityId: null,
      //   GeneratedActivityId: null,
      //   Name: "Site Readiness Review",
      //   Program: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   OwnerProgram: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   ActivityTemplateId: 1491,
      //   Priority: {
      //     Id: 5,
      //     Description: "Medium"
      //   },
      //   Status: {
      //     Id: "New",
      //     Name: "New"
      //   },
      //   StartBy: null,
      //   StartNoLaterThan: null,
      //   Assignment: {},
      //   Description:
      //     "Evaluate the installation site and collect enough information to create an acurate proposal.",
      //   Location: {
      //     Id: 3066,
      //     Name: "WHS",
      //     Description: null,
      //     Address: "2 Meadowbrook",
      //     City: "Irvine",
      //     State: "California",
      //     Zip: "92604",
      //     Country: "United States",
      //     Latitude: 38.7783988,
      //     Longitude: -117.8064609,
      //     Contact: {
      //       Id: null,
      //       FirstName: null,
      //       LastName: null,
      //       Email: null,
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   Customer: {
      //     Id: 3256,
      //     Name: "Woodbridge High",
      //     Type: "Company",
      //     Contact: {
      //       Id: null,
      //       FirstName: "woodbridge",
      //       LastName: "irvine",
      //       Email: "woodbridge@na.com",
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   DueDate: "2019-04-22T22:03:19.0854732",
      //   Tasks: {
      //     Id: "b",
      //     Name: "Perform site readiness review",
      //     Status: "New",
      //     CanComplete: true,
      //     Events: [],
      //     Actions: [
      //       {
      //         CustomInt1: 59,
      //         CustomString1:
      //           "https://survey2.qmerit.com/newsurvey/3?guid=22076-b-1b",
      //         CustomString2: "",
      //         Files: [],
      //         MultiDocument: null,
      //         ProjectDocument: null,
      //         Id: "1b",
      //         Label:
      //           "The site survey will assist you with gathering all the necessary information for creating an accurate proposal for the customer. You will be able to add as many charging stations, electrical runs, and power sources necessary for the customer's unique installation needs.",
      //         Type: "Survey",
      //         IsCompleted: false
      //       }
      //     ],
      //     Tasks: [],
      //     Next: null
      //   },
      //   Events: [],
      //   Owner: {
      //     Id: 8284,
      //     DBAName: "AshbyKTech",
      //     ImageUrl: null
      //   },
      //   CompletionPercentage: 0,
      //   Type: "Activity",
      //   Project: {
      //     Id: 12484,
      //     Name: " High EV Stations pre-assign",
      //     DueDate: null,
      //     Status: "New",
      //     ModifiedOn: "2019-03-19T22:01:28.4083924",
      //     Attachments: []
      //   },
      //   Collaborators: [],
      //   SelectedOn: null,
      //   CreatedOn: "2019-03-19T22:00:42.433",
      //   IsFrozen: false,
      //   AcceptedBy: null,
      //   Id: 2208999
      // },

      // {
      //   MasterActivityId: 22076,
      //   ParentActivityId: null,
      //   DerivedActivityId: null,
      //   GeneratedActivityId: null,
      //   Name: "Site Readiness Review",
      //   Program: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   OwnerProgram: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   ActivityTemplateId: 1491,
      //   Priority: {
      //     Id: 5,
      //     Description: "Medium"
      //   },
      //   Status: {
      //     Id: "New",
      //     Name: "New"
      //   },
      //   StartBy: null,
      //   StartNoLaterThan: null,
      //   Assignment: {
      //     Id: 12551,
      //     Name: "adam ashbyqakteck",
      //     Type: "Employee",
      //     ImageUrl: null,
      //     Contact: {
      //       Id: 12551,
      //       FirstName: "adam",
      //       LastName: "ashbyqakteck",
      //       Email: "ashbyqaktech+adam@gmail.com",
      //       PhoneNumber: "+19494364242",
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: "",
      //       ImageUrl: null
      //     }
      //   },
      //   Description:
      //     "Evaluate the installation site and collect enough information to create an acurate proposal.",
      //   Location: {
      //     Id: 3066,
      //     Name: "WHS",
      //     Description: null,
      //     Address: "factory area",
      //     City: "patiala",
      //     State: "punjab",
      //     Zip: "147001",
      //     Country: "India",
      //     Latitude: 34,
      //     Longitude: 76,
      //     Contact: {
      //       Id: null,
      //       FirstName: null,
      //       LastName: null,
      //       Email: null,
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   Customer: {
      //     Id: 3256,
      //     Name: "dbridge High",
      //     Type: "Company",
      //     Contact: {
      //       Id: null,
      //       FirstName: "woodbridge",
      //       LastName: "irvine",
      //       Email: "woodbridge@na.com",
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   DueDate: "2019-04-02T22:03:19.0854732",
      //   Tasks: {
      //     Id: "b",
      //     Name: "Perform site readiness review",
      //     Status: "New",
      //     CanComplete: true,
      //     Events: [],
      //     Actions: [
      //       {
      //         CustomInt1: 59,
      //         CustomString1:
      //           "https://survey2.qmerit.com/newsurvey/3?guid=22076-b-1b",
      //         CustomString2: "",
      //         Files: [],
      //         MultiDocument: null,
      //         ProjectDocument: null,
      //         Id: "1b",
      //         Label:
      //           "The site survey will assist you with gathering all the necessary information for creating an accurate proposal for the customer. You will be able to add as many charging stations, electrical runs, and power sources necessary for the customer's unique installation needs.",
      //         Type: "Survey",
      //         IsCompleted: false
      //       }
      //     ],
      //     Tasks: [],
      //     Next: null
      //   },
      //   Events: [],
      //   Owner: {
      //     Id: 8284,
      //     DBAName: "AshbyKTech",
      //     ImageUrl: null
      //   },
      //   CompletionPercentage: 0,
      //   Type: "Activity",
      //   Project: {
      //     Id: 12484,
      //     Name: " EV Stations pre-assign",
      //     DueDate: null,
      //     Status: "New",
      //     ModifiedOn: "2019-03-19T22:01:28.4083924",
      //     Attachments: []
      //   },
      //   Collaborators: [],
      //   SelectedOn: null,
      //   CreatedOn: "2019-03-19T22:00:42.433",
      //   IsFrozen: false,
      //   AcceptedBy: null,
      //   Id: 220777895
      // },
      // {
      //   MasterActivityId: 22076,
      //   ParentActivityId: null,
      //   DerivedActivityId: null,
      //   GeneratedActivityId: null,
      //   Name: "Site Readiness Review",
      //   Program: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   OwnerProgram: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   ActivityTemplateId: 1491,
      //   Priority: {
      //     Id: 5,
      //     Description: "Medium"
      //   },
      //   Status: {
      //     Id: "New",
      //     Name: "New"
      //   },
      //   StartBy: null,
      //   StartNoLaterThan: null,
      //   Assignment: null,
      //   Description:
      //     "Evaluate the installation site and collect enough information to create an acurate proposal.",
      //   Location: {
      //     Id: 3066,
      //     Name: "WHS",
      //     Description: null,
      //     Address: "urban estate",
      //     City: "patiala",
      //     State: "punjab",
      //     Zip: "147001",
      //     Country: "India",
      //     Latitude: 34.5,
      //     Longitude: 76,
      //     Contact: {
      //       Id: null,
      //       FirstName: null,
      //       LastName: null,
      //       Email: null,
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   Customer: {
      //     Id: 3256,
      //     Name: " High",
      //     Type: "Company",
      //     Contact: {
      //       Id: null,
      //       FirstName: "woodbridge",
      //       LastName: "irvine",
      //       Email: "woodbridge@na.com",
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   DueDate: "2019-04-02T22:03:19.0854732",
      //   Tasks: {
      //     Id: "b",
      //     Name: "Perform site readiness review",
      //     Status: "New",
      //     CanComplete: true,
      //     Events: [],
      //     Actions: [
      //       {
      //         CustomInt1: 59,
      //         CustomString1:
      //           "https://survey2.qmerit.com/newsurvey/3?guid=22076-b-1b",
      //         CustomString2: "",
      //         Files: [],
      //         MultiDocument: null,
      //         ProjectDocument: null,
      //         Id: "1b",
      //         Label:
      //           "The site survey will assist you with gathering all the necessary information for creating an accurate proposal for the customer. You will be able to add as many charging stations, electrical runs, and power sources necessary for the customer's unique installation needs.",
      //         Type: "Survey",
      //         IsCompleted: false
      //       }
      //     ],
      //     Tasks: [],
      //     Next: null
      //   },
      //   Events: [],
      //   Owner: {
      //     Id: 8284,
      //     DBAName: "AshbyKTech",
      //     ImageUrl: null
      //   },
      //   CompletionPercentage: 0,
      //   Type: "Activity",
      //   Project: {
      //     Id: 12484,
      //     Name: "Woodbridge High EV Stations pre-assign",
      //     DueDate: null,
      //     Status: "New",
      //     ModifiedOn: "2019-03-19T22:01:28.4083924",
      //     Attachments: []
      //   },
      //   Collaborators: [],
      //   SelectedOn: null,
      //   CreatedOn: "2019-03-19T22:00:42.433",
      //   IsFrozen: false,
      //   AcceptedBy: null,
      //   Id: 207115558
      // },
      // {
      //   MasterActivityId: 22072,
      //   ParentActivityId: null,
      //   DerivedActivityId: null,
      //   GeneratedActivityId: null,
      //   Name: "Site Readiness Review",
      //   Program: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   OwnerProgram: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   ActivityTemplateId: 1491,
      //   Priority: {
      //     Id: 5,
      //     Description: "Medium"
      //   },
      //   Status: {
      //     Id: "New",
      //     Name: "New"
      //   },
      //   StartBy: null,
      //   StartNoLaterThan: null,
      //   Assignment: {},
      //   Description:
      //     "Evaluate the installation site and collect enough information to create an acurate proposal.",
      //   Location: {
      //     Id: 3066,
      //     Name: "WHS",
      //     Description: null,
      //     Address: "2 Meadowbrook",
      //     City: "Irvine",
      //     State: "California",
      //     Zip: "92604",
      //     Country: "United States",
      //     Latitude: 31.6783988,
      //     Longitude: -111.8064609,
      //     Contact: {
      //       Id: null,
      //       FirstName: null,
      //       LastName: null,
      //       Email: null,
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   Customer: {
      //     Id: 3256,
      //     Name: "Woodbridge High",
      //     Type: "Company",
      //     Contact: {
      //       Id: null,
      //       FirstName: "woodbridge",
      //       LastName: "irvine",
      //       Email: "woodbridge@na.com",
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   DueDate: "2019-04-02T22:03:19.0854732",
      //   Tasks: {
      //     Id: "b",
      //     Name: "Perform site readiness review",
      //     Status: "New",
      //     CanComplete: true,
      //     Events: [],
      //     Actions: [
      //       {
      //         CustomInt1: 59,
      //         CustomString1:
      //           "https://survey2.qmerit.com/newsurvey/3?guid=22076-b-1b",
      //         CustomString2: "",
      //         Files: [],
      //         MultiDocument: null,
      //         ProjectDocument: null,
      //         Id: "1b",
      //         Label:
      //           "The site survey will assist you with gathering all the necessary information for creating an accurate proposal for the customer. You will be able to add as many charging stations, electrical runs, and power sources necessary for the customer's unique installation needs.",
      //         Type: "Survey",
      //         IsCompleted: false
      //       }
      //     ],
      //     Tasks: [],
      //     Next: null
      //   },
      //   Events: [],
      //   Owner: {
      //     Id: 8284,
      //     DBAName: "AshbyKTech",
      //     ImageUrl: null
      //   },
      //   CompletionPercentage: 0,
      //   Type: "Activity",
      //   Project: {
      //     Id: 12484,
      //     Name: " High EV Stations pre-assign",
      //     DueDate: null,
      //     Status: "New",
      //     ModifiedOn: "2019-03-19T22:01:28.4083924",
      //     Attachments: []
      //   },
      //   Collaborators: [],
      //   SelectedOn: null,
      //   CreatedOn: "2019-03-19T22:00:42.433",
      //   IsFrozen: false,
      //   AcceptedBy: null,
      //   Id: 22080
      // },
      // {
      //   MasterActivityId: 22075,
      //   ParentActivityId: null,
      //   DerivedActivityId: null,
      //   GeneratedActivityId: null,
      //   Name: "Site Readiness Review",
      //   Program: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   OwnerProgram: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   ActivityTemplateId: 1491,
      //   Priority: {
      //     Id: 5,
      //     Description: "Medium"
      //   },
      //   Status: {
      //     Id: "New",
      //     Name: "New"
      //   },
      //   StartBy: null,
      //   StartNoLaterThan: null,
      //   Assignment: {},
      //   Description:
      //     "Evaluate the installation site and collect enough information to create an acurate proposal.",
      //   Location: {
      //     Id: 3066,
      //     Name: "WHS",
      //     Description: null,
      //     Address: "2 Meadowbrook",
      //     City: "Irvine",
      //     State: "California",
      //     Zip: "92604",
      //     Country: "United States",
      //     Latitude: 36.6783988,
      //     Longitude: -112.8064609,
      //     Contact: {
      //       Id: null,
      //       FirstName: null,
      //       LastName: null,
      //       Email: null,
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   Customer: {
      //     Id: 3256,
      //     Name: "Woodbridge High",
      //     Type: "Company",
      //     Contact: {
      //       Id: null,
      //       FirstName: "woodbridge",
      //       LastName: "irvine",
      //       Email: "woodbridge@na.com",
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   DueDate: "2019-04-02T22:03:19.0854732",
      //   Tasks: {
      //     Id: "b",
      //     Name: "Perform site readiness review",
      //     Status: "New",
      //     CanComplete: true,
      //     Events: [],
      //     Actions: [
      //       {
      //         CustomInt1: 59,
      //         CustomString1:
      //           "https://survey2.qmerit.com/newsurvey/3?guid=22076-b-1b",
      //         CustomString2: "",
      //         Files: [],
      //         MultiDocument: null,
      //         ProjectDocument: null,
      //         Id: "1b",
      //         Label:
      //           "The site survey will assist you with gathering all the necessary information for creating an accurate proposal for the customer. You will be able to add as many charging stations, electrical runs, and power sources necessary for the customer's unique installation needs.",
      //         Type: "Survey",
      //         IsCompleted: false
      //       }
      //     ],
      //     Tasks: [],
      //     Next: null
      //   },
      //   Events: [],
      //   Owner: {
      //     Id: 8284,
      //     DBAName: "AshbyKTech",
      //     ImageUrl: null
      //   },
      //   CompletionPercentage: 0,
      //   Type: "Activity",
      //   Project: {
      //     Id: 12484,
      //     Name: " High EV Stations pre-assign",
      //     DueDate: null,
      //     Status: "New",
      //     ModifiedOn: "2019-03-19T22:01:28.4083924",
      //     Attachments: []
      //   },
      //   Collaborators: [],
      //   SelectedOn: null,
      //   CreatedOn: "2019-03-19T22:00:42.433",
      //   IsFrozen: false,
      //   AcceptedBy: null,
      //   Id: 22084
      // },
      // {
      //   MasterActivityId: 22073,
      //   ParentActivityId: null,
      //   DerivedActivityId: null,
      //   GeneratedActivityId: null,
      //   Name: "Site Readiness Review",
      //   Program: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   OwnerProgram: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   ActivityTemplateId: 1491,
      //   Priority: {
      //     Id: 5,
      //     Description: "Medium"
      //   },
      //   Status: {
      //     Id: "New",
      //     Name: "New"
      //   },
      //   StartBy: null,
      //   StartNoLaterThan: null,
      //   Assignment: {},
      //   Description:
      //     "Evaluate the installation site and collect enough information to create an acurate proposal.",
      //   Location: {
      //     Id: 3066,
      //     Name: "WHS",
      //     Description: null,
      //     Address: "2 Meadowbrook",
      //     City: "Irvine",
      //     State: "California",
      //     Zip: "92604",
      //     Country: "United States",
      //     Latitude: 38.7783988,
      //     Longitude: -117.8064609,
      //     Contact: {
      //       Id: null,
      //       FirstName: null,
      //       LastName: null,
      //       Email: null,
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   Customer: {
      //     Id: 3256,
      //     Name: "Woodbridge High",
      //     Type: "Company",
      //     Contact: {
      //       Id: null,
      //       FirstName: "woodbridge",
      //       LastName: "irvine",
      //       Email: "woodbridge@na.com",
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   DueDate: "2019-04-22T22:03:19.0854732",
      //   Tasks: {
      //     Id: "b",
      //     Name: "Perform site readiness review",
      //     Status: "New",
      //     CanComplete: true,
      //     Events: [],
      //     Actions: [
      //       {
      //         CustomInt1: 59,
      //         CustomString1:
      //           "https://survey2.qmerit.com/newsurvey/3?guid=22076-b-1b",
      //         CustomString2: "",
      //         Files: [],
      //         MultiDocument: null,
      //         ProjectDocument: null,
      //         Id: "1b",
      //         Label:
      //           "The site survey will assist you with gathering all the necessary information for creating an accurate proposal for the customer. You will be able to add as many charging stations, electrical runs, and power sources necessary for the customer's unique installation needs.",
      //         Type: "Survey",
      //         IsCompleted: false
      //       }
      //     ],
      //     Tasks: [],
      //     Next: null
      //   },
      //   Events: [],
      //   Owner: {
      //     Id: 8284,
      //     DBAName: "AshbyKTech",
      //     ImageUrl: null
      //   },
      //   CompletionPercentage: 0,
      //   Type: "Activity",
      //   Project: {
      //     Id: 12484,
      //     Name: " High EV Stations pre-assign",
      //     DueDate: null,
      //     Status: "New",
      //     ModifiedOn: "2019-03-19T22:01:28.4083924",
      //     Attachments: []
      //   },
      //   Collaborators: [],
      //   SelectedOn: null,
      //   CreatedOn: "2019-03-19T22:00:42.433",
      //   IsFrozen: false,
      //   AcceptedBy: null,
      //   Id: 22089
      // },

      // {
      //   MasterActivityId: 22074,
      //   ParentActivityId: null,
      //   DerivedActivityId: null,
      //   GeneratedActivityId: null,
      //   Name: "Site Readiness Review",
      //   Program: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   OwnerProgram: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   ActivityTemplateId: 1491,
      //   Priority: {
      //     Id: 5,
      //     Description: "Medium"
      //   },
      //   Status: {
      //     Id: "New",
      //     Name: "New"
      //   },
      //   StartBy: null,
      //   StartNoLaterThan: null,
      //   Assignment: {
      //     Id: 12551,
      //     Name: "adam ashbyqakteck",
      //     Type: "Employee",
      //     ImageUrl: null,
      //     Contact: {
      //       Id: 12551,
      //       FirstName: "adam",
      //       LastName: "ashbyqakteck",
      //       Email: "ashbyqaktech+adam@gmail.com",
      //       PhoneNumber: "+19494364242",
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: "",
      //       ImageUrl: null
      //     }
      //   },
      //   Description:
      //     "Evaluate the installation site and collect enough information to create an acurate proposal.",
      //   Location: {
      //     Id: 3066,
      //     Name: "WHS",
      //     Description: null,
      //     Address: "factory area",
      //     City: "patiala",
      //     State: "punjab",
      //     Zip: "147001",
      //     Country: "India",
      //     Latitude: 34,
      //     Longitude: 76,
      //     Contact: {
      //       Id: null,
      //       FirstName: null,
      //       LastName: null,
      //       Email: null,
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   Customer: {
      //     Id: 3256,
      //     Name: "dbridge High",
      //     Type: "Company",
      //     Contact: {
      //       Id: null,
      //       FirstName: "woodbridge",
      //       LastName: "irvine",
      //       Email: "woodbridge@na.com",
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   DueDate: "2019-04-02T22:03:19.0854732",
      //   Tasks: {
      //     Id: "b",
      //     Name: "Perform site readiness review",
      //     Status: "New",
      //     CanComplete: true,
      //     Events: [],
      //     Actions: [
      //       {
      //         CustomInt1: 59,
      //         CustomString1:
      //           "https://survey2.qmerit.com/newsurvey/3?guid=22076-b-1b",
      //         CustomString2: "",
      //         Files: [],
      //         MultiDocument: null,
      //         ProjectDocument: null,
      //         Id: "1b",
      //         Label:
      //           "The site survey will assist you with gathering all the necessary information for creating an accurate proposal for the customer. You will be able to add as many charging stations, electrical runs, and power sources necessary for the customer's unique installation needs.",
      //         Type: "Survey",
      //         IsCompleted: false
      //       }
      //     ],
      //     Tasks: [],
      //     Next: null
      //   },
      //   Events: [],
      //   Owner: {
      //     Id: 8284,
      //     DBAName: "AshbyKTech",
      //     ImageUrl: null
      //   },
      //   CompletionPercentage: 0,
      //   Type: "Activity",
      //   Project: {
      //     Id: 12484,
      //     Name: " EV Stations pre-assign",
      //     DueDate: null,
      //     Status: "New",
      //     ModifiedOn: "2019-03-19T22:01:28.4083924",
      //     Attachments: []
      //   },
      //   Collaborators: [],
      //   SelectedOn: null,
      //   CreatedOn: "2019-03-19T22:00:42.433",
      //   IsFrozen: false,
      //   AcceptedBy: null,
      //   Id: 22077
      // },
      // {
      //   MasterActivityId: 22071,
      //   ParentActivityId: null,
      //   DerivedActivityId: null,
      //   GeneratedActivityId: null,
      //   Name: "Site Readiness Review",
      //   Program: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   OwnerProgram: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   ActivityTemplateId: 1491,
      //   Priority: {
      //     Id: 5,
      //     Description: "Medium"
      //   },
      //   Status: {
      //     Id: "New",
      //     Name: "New"
      //   },
      //   StartBy: null,
      //   StartNoLaterThan: null,
      //   Assignment: null,
      //   Description:
      //     "Evaluate the installation site and collect enough information to create an acurate proposal.",
      //   Location: {
      //     Id: 3066,
      //     Name: "WHS",
      //     Description: null,
      //     Address: "urban estate",
      //     City: "patiala",
      //     State: "punjab",
      //     Zip: "147001",
      //     Country: "India",
      //     Latitude: 34.5,
      //     Longitude: 76,
      //     Contact: {
      //       Id: null,
      //       FirstName: null,
      //       LastName: null,
      //       Email: null,
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   Customer: {
      //     Id: 3256,
      //     Name: " High",
      //     Type: "Company",
      //     Contact: {
      //       Id: null,
      //       FirstName: "woodbridge",
      //       LastName: "irvine",
      //       Email: "woodbridge@na.com",
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   DueDate: "2019-04-02T22:03:19.0854732",
      //   Tasks: {
      //     Id: "b",
      //     Name: "Perform site readiness review",
      //     Status: "New",
      //     CanComplete: true,
      //     Events: [],
      //     Actions: [
      //       {
      //         CustomInt1: 59,
      //         CustomString1:
      //           "https://survey2.qmerit.com/newsurvey/3?guid=22076-b-1b",
      //         CustomString2: "",
      //         Files: [],
      //         MultiDocument: null,
      //         ProjectDocument: null,
      //         Id: "1b",
      //         Label:
      //           "The site survey will assist you with gathering all the necessary information for creating an accurate proposal for the customer. You will be able to add as many charging stations, electrical runs, and power sources necessary for the customer's unique installation needs.",
      //         Type: "Survey",
      //         IsCompleted: false
      //       }
      //     ],
      //     Tasks: [],
      //     Next: null
      //   },
      //   Events: [],
      //   Owner: {
      //     Id: 8284,
      //     DBAName: "AshbyKTech",
      //     ImageUrl: null
      //   },
      //   CompletionPercentage: 0,
      //   Type: "Activity",
      //   Project: {
      //     Id: 12484,
      //     Name: "Woodbridge High EV Stations pre-assign",
      //     DueDate: null,
      //     Status: "New",
      //     ModifiedOn: "2019-03-19T22:01:28.4083924",
      //     Attachments: []
      //   },
      //   Collaborators: [],
      //   SelectedOn: null,
      //   CreatedOn: "2019-03-19T22:00:42.433",
      //   IsFrozen: false,
      //   AcceptedBy: null,
      //   Id: 22078
      // },
      // {
      //   MasterActivityId: 22076,
      //   ParentActivityId: null,
      //   DerivedActivityId: null,
      //   GeneratedActivityId: null,
      //   Name: "Site Readiness Review",
      //   Program: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   OwnerProgram: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   ActivityTemplateId: 1491,
      //   Priority: {
      //     Id: 5,
      //     Description: "Medium"
      //   },
      //   Status: {
      //     Id: "New",
      //     Name: "New"
      //   },
      //   StartBy: null,
      //   StartNoLaterThan: null,
      //   Assignment: {},
      //   Description:
      //     "Evaluate the installation site and collect enough information to create an acurate proposal.",
      //   Location: {
      //     Id: 3066,
      //     Name: "WHS",
      //     Description: null,
      //     Address: "2 Meadowbrook",
      //     City: "Irvine",
      //     State: "California",
      //     Zip: "92604",
      //     Country: "United States",
      //     Latitude: 31.6783988,
      //     Longitude: -111.8064609,
      //     Contact: {
      //       Id: null,
      //       FirstName: null,
      //       LastName: null,
      //       Email: null,
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   Customer: {
      //     Id: 3256,
      //     Name: "Woodbridge High",
      //     Type: "Company",
      //     Contact: {
      //       Id: null,
      //       FirstName: "woodbridge",
      //       LastName: "irvine",
      //       Email: "woodbridge@na.com",
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   DueDate: "2019-04-02T22:03:19.0854732",
      //   Tasks: {
      //     Id: "b",
      //     Name: "Perform site readiness review",
      //     Status: "New",
      //     CanComplete: true,
      //     Events: [],
      //     Actions: [
      //       {
      //         CustomInt1: 59,
      //         CustomString1:
      //           "https://survey2.qmerit.com/newsurvey/3?guid=22076-b-1b",
      //         CustomString2: "",
      //         Files: [],
      //         MultiDocument: null,
      //         ProjectDocument: null,
      //         Id: "1b",
      //         Label:
      //           "The site survey will assist you with gathering all the necessary information for creating an accurate proposal for the customer. You will be able to add as many charging stations, electrical runs, and power sources necessary for the customer's unique installation needs.",
      //         Type: "Survey",
      //         IsCompleted: false
      //       }
      //     ],
      //     Tasks: [],
      //     Next: null
      //   },
      //   Events: [],
      //   Owner: {
      //     Id: 8284,
      //     DBAName: "AshbyKTech",
      //     ImageUrl: null
      //   },
      //   CompletionPercentage: 0,
      //   Type: "Activity",
      //   Project: {
      //     Id: 12484,
      //     Name: " High EV Stations pre-assign",
      //     DueDate: null,
      //     Status: "New",
      //     ModifiedOn: "2019-03-19T22:01:28.4083924",
      //     Attachments: []
      //   },
      //   Collaborators: [],
      //   SelectedOn: null,
      //   CreatedOn: "2019-03-19T22:00:42.433",
      //   IsFrozen: false,
      //   AcceptedBy: null,
      //   Id: 220850
      // },
      // {
      //   MasterActivityId: 22076,
      //   ParentActivityId: null,
      //   DerivedActivityId: null,
      //   GeneratedActivityId: null,
      //   Name: "Site Readiness Review",
      //   Program: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   OwnerProgram: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   ActivityTemplateId: 1491,
      //   Priority: {
      //     Id: 5,
      //     Description: "Medium"
      //   },
      //   Status: {
      //     Id: "New",
      //     Name: "New"
      //   },
      //   StartBy: null,
      //   StartNoLaterThan: null,
      //   Assignment: {},
      //   Description:
      //     "Evaluate the installation site and collect enough information to create an acurate proposal.",
      //   Location: {
      //     Id: 3066,
      //     Name: "WHS",
      //     Description: null,
      //     Address: "2 Meadowbrook",
      //     City: "Irvine",
      //     State: "California",
      //     Zip: "92604",
      //     Country: "United States",
      //     Latitude: 36.6783988,
      //     Longitude: -112.8064609,
      //     Contact: {
      //       Id: null,
      //       FirstName: null,
      //       LastName: null,
      //       Email: null,
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   Customer: {
      //     Id: 3256,
      //     Name: "Woodbridge High",
      //     Type: "Company",
      //     Contact: {
      //       Id: null,
      //       FirstName: "woodbridge",
      //       LastName: "irvine",
      //       Email: "woodbridge@na.com",
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   DueDate: "2019-04-02T22:03:19.0854732",
      //   Tasks: {
      //     Id: "b",
      //     Name: "Perform site readiness review",
      //     Status: "New",
      //     CanComplete: true,
      //     Events: [],
      //     Actions: [
      //       {
      //         CustomInt1: 59,
      //         CustomString1:
      //           "https://survey2.qmerit.com/newsurvey/3?guid=22076-b-1b",
      //         CustomString2: "",
      //         Files: [],
      //         MultiDocument: null,
      //         ProjectDocument: null,
      //         Id: "1b",
      //         Label:
      //           "The site survey will assist you with gathering all the necessary information for creating an accurate proposal for the customer. You will be able to add as many charging stations, electrical runs, and power sources necessary for the customer's unique installation needs.",
      //         Type: "Survey",
      //         IsCompleted: false
      //       }
      //     ],
      //     Tasks: [],
      //     Next: null
      //   },
      //   Events: [],
      //   Owner: {
      //     Id: 8284,
      //     DBAName: "AshbyKTech",
      //     ImageUrl: null
      //   },
      //   CompletionPercentage: 0,
      //   Type: "Activity",
      //   Project: {
      //     Id: 12484,
      //     Name: " High EV Stations pre-assign",
      //     DueDate: null,
      //     Status: "New",
      //     ModifiedOn: "2019-03-19T22:01:28.4083924",
      //     Attachments: []
      //   },
      //   Collaborators: [],
      //   SelectedOn: null,
      //   CreatedOn: "2019-03-19T22:00:42.433",
      //   IsFrozen: false,
      //   AcceptedBy: null,
      //   Id: 220384
      // },
      // {
      //   MasterActivityId: 22076,
      //   ParentActivityId: null,
      //   DerivedActivityId: null,
      //   GeneratedActivityId: null,
      //   Name: "Site Readiness Review",
      //   Program: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   OwnerProgram: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   ActivityTemplateId: 1491,
      //   Priority: {
      //     Id: 5,
      //     Description: "Medium"
      //   },
      //   Status: {
      //     Id: "New",
      //     Name: "New"
      //   },
      //   StartBy: null,
      //   StartNoLaterThan: null,
      //   Assignment: {},
      //   Description:
      //     "Evaluate the installation site and collect enough information to create an acurate proposal.",
      //   Location: {
      //     Id: 3066,
      //     Name: "WHS",
      //     Description: null,
      //     Address: "2 Meadowbrook",
      //     City: "Irvine",
      //     State: "California",
      //     Zip: "92604",
      //     Country: "United States",
      //     Latitude: 38.7783988,
      //     Longitude: -117.8064609,
      //     Contact: {
      //       Id: null,
      //       FirstName: null,
      //       LastName: null,
      //       Email: null,
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   Customer: {
      //     Id: 3256,
      //     Name: "Woodbridge High",
      //     Type: "Company",
      //     Contact: {
      //       Id: null,
      //       FirstName: "woodbridge",
      //       LastName: "irvine",
      //       Email: "woodbridge@na.com",
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   DueDate: "2019-04-22T22:03:19.0854732",
      //   Tasks: {
      //     Id: "b",
      //     Name: "Perform site readiness review",
      //     Status: "New",
      //     CanComplete: true,
      //     Events: [],
      //     Actions: [
      //       {
      //         CustomInt1: 59,
      //         CustomString1:
      //           "https://survey2.qmerit.com/newsurvey/3?guid=22076-b-1b",
      //         CustomString2: "",
      //         Files: [],
      //         MultiDocument: null,
      //         ProjectDocument: null,
      //         Id: "1b",
      //         Label:
      //           "The site survey will assist you with gathering all the necessary information for creating an accurate proposal for the customer. You will be able to add as many charging stations, electrical runs, and power sources necessary for the customer's unique installation needs.",
      //         Type: "Survey",
      //         IsCompleted: false
      //       }
      //     ],
      //     Tasks: [],
      //     Next: null
      //   },
      //   Events: [],
      //   Owner: {
      //     Id: 8284,
      //     DBAName: "AshbyKTech",
      //     ImageUrl: null
      //   },
      //   CompletionPercentage: 0,
      //   Type: "Activity",
      //   Project: {
      //     Id: 12484,
      //     Name: " High EV Stations pre-assign",
      //     DueDate: null,
      //     Status: "New",
      //     ModifiedOn: "2019-03-19T22:01:28.4083924",
      //     Attachments: []
      //   },
      //   Collaborators: [],
      //   SelectedOn: null,
      //   CreatedOn: "2019-03-19T22:00:42.433",
      //   IsFrozen: false,
      //   AcceptedBy: null,
      //   Id: 222089
      // },

      // {
      //   MasterActivityId: 22076,
      //   ParentActivityId: null,
      //   DerivedActivityId: null,
      //   GeneratedActivityId: null,
      //   Name: "Site Readiness Review",
      //   Program: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   OwnerProgram: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   ActivityTemplateId: 1491,
      //   Priority: {
      //     Id: 5,
      //     Description: "Medium"
      //   },
      //   Status: {
      //     Id: "New",
      //     Name: "New"
      //   },
      //   StartBy: null,
      //   StartNoLaterThan: null,
      //   Assignment: {
      //     Id: 12551,
      //     Name: "adam ashbyqakteck",
      //     Type: "Employee",
      //     ImageUrl: null,
      //     Contact: {
      //       Id: 12551,
      //       FirstName: "adam",
      //       LastName: "ashbyqakteck",
      //       Email: "ashbyqaktech+adam@gmail.com",
      //       PhoneNumber: "+19494364242",
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: "",
      //       ImageUrl: null
      //     }
      //   },
      //   Description:
      //     "Evaluate the installation site and collect enough information to create an acurate proposal.",
      //   Location: {
      //     Id: 3066,
      //     Name: "WHS",
      //     Description: null,
      //     Address: "factory area",
      //     City: "patiala",
      //     State: "punjab",
      //     Zip: "147001",
      //     Country: "India",
      //     Latitude: 34,
      //     Longitude: 76,
      //     Contact: {
      //       Id: null,
      //       FirstName: null,
      //       LastName: null,
      //       Email: null,
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   Customer: {
      //     Id: 3256,
      //     Name: "dbridge High",
      //     Type: "Company",
      //     Contact: {
      //       Id: null,
      //       FirstName: "woodbridge",
      //       LastName: "irvine",
      //       Email: "woodbridge@na.com",
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   DueDate: "2019-04-02T22:03:19.0854732",
      //   Tasks: {
      //     Id: "b",
      //     Name: "Perform site readiness review",
      //     Status: "New",
      //     CanComplete: true,
      //     Events: [],
      //     Actions: [
      //       {
      //         CustomInt1: 59,
      //         CustomString1:
      //           "https://survey2.qmerit.com/newsurvey/3?guid=22076-b-1b",
      //         CustomString2: "",
      //         Files: [],
      //         MultiDocument: null,
      //         ProjectDocument: null,
      //         Id: "1b",
      //         Label:
      //           "The site survey will assist you with gathering all the necessary information for creating an accurate proposal for the customer. You will be able to add as many charging stations, electrical runs, and power sources necessary for the customer's unique installation needs.",
      //         Type: "Survey",
      //         IsCompleted: false
      //       }
      //     ],
      //     Tasks: [],
      //     Next: null
      //   },
      //   Events: [],
      //   Owner: {
      //     Id: 8284,
      //     DBAName: "AshbyKTech",
      //     ImageUrl: null
      //   },
      //   CompletionPercentage: 0,
      //   Type: "Activity",
      //   Project: {
      //     Id: 12484,
      //     Name: " EV Stations pre-assign",
      //     DueDate: null,
      //     Status: "New",
      //     ModifiedOn: "2019-03-19T22:01:28.4083924",
      //     Attachments: []
      //   },
      //   Collaborators: [],
      //   SelectedOn: null,
      //   CreatedOn: "2019-03-19T22:00:42.433",
      //   IsFrozen: false,
      //   AcceptedBy: null,
      //   Id: 220774
      // },
      // {
      //   MasterActivityId: 22076,
      //   ParentActivityId: null,
      //   DerivedActivityId: null,
      //   GeneratedActivityId: null,
      //   Name: "Site Readiness Review",
      //   Program: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   OwnerProgram: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   ActivityTemplateId: 1491,
      //   Priority: {
      //     Id: 5,
      //     Description: "Medium"
      //   },
      //   Status: {
      //     Id: "New",
      //     Name: "New"
      //   },
      //   StartBy: null,
      //   StartNoLaterThan: null,
      //   Assignment: null,
      //   Description:
      //     "Evaluate the installation site and collect enough information to create an acurate proposal.",
      //   Location: {
      //     Id: 3066,
      //     Name: "WHS",
      //     Description: null,
      //     Address: "urban estate",
      //     City: "patiala",
      //     State: "punjab",
      //     Zip: "147001",
      //     Country: "India",
      //     Latitude: 34.5,
      //     Longitude: 76,
      //     Contact: {
      //       Id: null,
      //       FirstName: null,
      //       LastName: null,
      //       Email: null,
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   Customer: {
      //     Id: 3256,
      //     Name: " High",
      //     Type: "Company",
      //     Contact: {
      //       Id: null,
      //       FirstName: "woodbridge",
      //       LastName: "irvine",
      //       Email: "woodbridge@na.com",
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   DueDate: "2019-04-02T22:03:19.0854732",
      //   Tasks: {
      //     Id: "b",
      //     Name: "Perform site readiness review",
      //     Status: "New",
      //     CanComplete: true,
      //     Events: [],
      //     Actions: [
      //       {
      //         CustomInt1: 59,
      //         CustomString1:
      //           "https://survey2.qmerit.com/newsurvey/3?guid=22076-b-1b",
      //         CustomString2: "",
      //         Files: [],
      //         MultiDocument: null,
      //         ProjectDocument: null,
      //         Id: "1b",
      //         Label:
      //           "The site survey will assist you with gathering all the necessary information for creating an accurate proposal for the customer. You will be able to add as many charging stations, electrical runs, and power sources necessary for the customer's unique installation needs.",
      //         Type: "Survey",
      //         IsCompleted: false
      //       }
      //     ],
      //     Tasks: [],
      //     Next: null
      //   },
      //   Events: [],
      //   Owner: {
      //     Id: 8284,
      //     DBAName: "AshbyKTech",
      //     ImageUrl: null
      //   },
      //   CompletionPercentage: 0,
      //   Type: "Activity",
      //   Project: {
      //     Id: 12484,
      //     Name: "Woodbridge High EV Stations pre-assign",
      //     DueDate: null,
      //     Status: "New",
      //     ModifiedOn: "2019-03-19T22:01:28.4083924",
      //     Attachments: []
      //   },
      //   Collaborators: [],
      //   SelectedOn: null,
      //   CreatedOn: "2019-03-19T22:00:42.433",
      //   IsFrozen: false,
      //   AcceptedBy: null,
      //   Id: 220781
      // },
      // {
      //   MasterActivityId: 22076,
      //   ParentActivityId: null,
      //   DerivedActivityId: null,
      //   GeneratedActivityId: null,
      //   Name: "Site Readiness Review",
      //   Program: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   OwnerProgram: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   ActivityTemplateId: 1491,
      //   Priority: {
      //     Id: 5,
      //     Description: "Medium"
      //   },
      //   Status: {
      //     Id: "New",
      //     Name: "New"
      //   },
      //   StartBy: null,
      //   StartNoLaterThan: null,
      //   Assignment: {},
      //   Description:
      //     "Evaluate the installation site and collect enough information to create an acurate proposal.",
      //   Location: {
      //     Id: 3066,
      //     Name: "WHS",
      //     Description: null,
      //     Address: "2 Meadowbrook",
      //     City: "Irvine",
      //     State: "California",
      //     Zip: "92604",
      //     Country: "United States",
      //     Latitude: 31.6783988,
      //     Longitude: -111.8064609,
      //     Contact: {
      //       Id: null,
      //       FirstName: null,
      //       LastName: null,
      //       Email: null,
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   Customer: {
      //     Id: 3256,
      //     Name: "Woodbridge High",
      //     Type: "Company",
      //     Contact: {
      //       Id: null,
      //       FirstName: "woodbridge",
      //       LastName: "irvine",
      //       Email: "woodbridge@na.com",
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   DueDate: "2019-04-02T22:03:19.0854732",
      //   Tasks: {
      //     Id: "b",
      //     Name: "Perform site readiness review",
      //     Status: "New",
      //     CanComplete: true,
      //     Events: [],
      //     Actions: [
      //       {
      //         CustomInt1: 59,
      //         CustomString1:
      //           "https://survey2.qmerit.com/newsurvey/3?guid=22076-b-1b",
      //         CustomString2: "",
      //         Files: [],
      //         MultiDocument: null,
      //         ProjectDocument: null,
      //         Id: "1b",
      //         Label:
      //           "The site survey will assist you with gathering all the necessary information for creating an accurate proposal for the customer. You will be able to add as many charging stations, electrical runs, and power sources necessary for the customer's unique installation needs.",
      //         Type: "Survey",
      //         IsCompleted: false
      //       }
      //     ],
      //     Tasks: [],
      //     Next: null
      //   },
      //   Events: [],
      //   Owner: {
      //     Id: 8284,
      //     DBAName: "AshbyKTech",
      //     ImageUrl: null
      //   },
      //   CompletionPercentage: 0,
      //   Type: "Activity",
      //   Project: {
      //     Id: 12484,
      //     Name: " High EV Stations pre-assign",
      //     DueDate: null,
      //     Status: "New",
      //     ModifiedOn: "2019-03-19T22:01:28.4083924",
      //     Attachments: []
      //   },
      //   Collaborators: [],
      //   SelectedOn: null,
      //   CreatedOn: "2019-03-19T22:00:42.433",
      //   IsFrozen: false,
      //   AcceptedBy: null,
      //   Id: 2208011
      // },
      // {
      //   MasterActivityId: 22076,
      //   ParentActivityId: null,
      //   DerivedActivityId: null,
      //   GeneratedActivityId: null,
      //   Name: "Site Readiness Review",
      //   Program: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   OwnerProgram: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   ActivityTemplateId: 1491,
      //   Priority: {
      //     Id: 5,
      //     Description: "Medium"
      //   },
      //   Status: {
      //     Id: "New",
      //     Name: "New"
      //   },
      //   StartBy: null,
      //   StartNoLaterThan: null,
      //   Assignment: {},
      //   Description:
      //     "Evaluate the installation site and collect enough information to create an acurate proposal.",
      //   Location: {
      //     Id: 3066,
      //     Name: "WHS",
      //     Description: null,
      //     Address: "2 Meadowbrook",
      //     City: "Irvine",
      //     State: "California",
      //     Zip: "92604",
      //     Country: "United States",
      //     Latitude: 36.6783988,
      //     Longitude: -112.8064609,
      //     Contact: {
      //       Id: null,
      //       FirstName: null,
      //       LastName: null,
      //       Email: null,
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   Customer: {
      //     Id: 3256,
      //     Name: "Woodbridge High",
      //     Type: "Company",
      //     Contact: {
      //       Id: null,
      //       FirstName: "woodbridge",
      //       LastName: "irvine",
      //       Email: "woodbridge@na.com",
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   DueDate: "2019-04-02T22:03:19.0854732",
      //   Tasks: {
      //     Id: "b",
      //     Name: "Perform site readiness review",
      //     Status: "New",
      //     CanComplete: true,
      //     Events: [],
      //     Actions: [
      //       {
      //         CustomInt1: 59,
      //         CustomString1:
      //           "https://survey2.qmerit.com/newsurvey/3?guid=22076-b-1b",
      //         CustomString2: "",
      //         Files: [],
      //         MultiDocument: null,
      //         ProjectDocument: null,
      //         Id: "1b",
      //         Label:
      //           "The site survey will assist you with gathering all the necessary information for creating an accurate proposal for the customer. You will be able to add as many charging stations, electrical runs, and power sources necessary for the customer's unique installation needs.",
      //         Type: "Survey",
      //         IsCompleted: false
      //       }
      //     ],
      //     Tasks: [],
      //     Next: null
      //   },
      //   Events: [],
      //   Owner: {
      //     Id: 8284,
      //     DBAName: "AshbyKTech",
      //     ImageUrl: null
      //   },
      //   CompletionPercentage: 0,
      //   Type: "Activity",
      //   Project: {
      //     Id: 12484,
      //     Name: " High EV Stations pre-assign",
      //     DueDate: null,
      //     Status: "New",
      //     ModifiedOn: "2019-03-19T22:01:28.4083924",
      //     Attachments: []
      //   },
      //   Collaborators: [],
      //   SelectedOn: null,
      //   CreatedOn: "2019-03-19T22:00:42.433",
      //   IsFrozen: false,
      //   AcceptedBy: null,
      //   Id: 2208488
      // },
      // {
      //   MasterActivityId: 22076,
      //   ParentActivityId: null,
      //   DerivedActivityId: null,
      //   GeneratedActivityId: null,
      //   Name: "Site Readiness Review",
      //   Program: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   OwnerProgram: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   ActivityTemplateId: 1491,
      //   Priority: {
      //     Id: 5,
      //     Description: "Medium"
      //   },
      //   Status: {
      //     Id: "New",
      //     Name: "New"
      //   },
      //   StartBy: null,
      //   StartNoLaterThan: null,
      //   Assignment: {},
      //   Description:
      //     "Evaluate the installation site and collect enough information to create an acurate proposal.",
      //   Location: {
      //     Id: 3066,
      //     Name: "WHS",
      //     Description: null,
      //     Address: "2 Meadowbrook",
      //     City: "Irvine",
      //     State: "California",
      //     Zip: "92604",
      //     Country: "United States",
      //     Latitude: 38.7783988,
      //     Longitude: -117.8064609,
      //     Contact: {
      //       Id: null,
      //       FirstName: null,
      //       LastName: null,
      //       Email: null,
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   Customer: {
      //     Id: 3256,
      //     Name: "Woodbridge High",
      //     Type: "Company",
      //     Contact: {
      //       Id: null,
      //       FirstName: "woodbridge",
      //       LastName: "irvine",
      //       Email: "woodbridge@na.com",
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   DueDate: "2019-04-22T22:03:19.0854732",
      //   Tasks: {
      //     Id: "b",
      //     Name: "Perform site readiness review",
      //     Status: "New",
      //     CanComplete: true,
      //     Events: [],
      //     Actions: [
      //       {
      //         CustomInt1: 59,
      //         CustomString1:
      //           "https://survey2.qmerit.com/newsurvey/3?guid=22076-b-1b",
      //         CustomString2: "",
      //         Files: [],
      //         MultiDocument: null,
      //         ProjectDocument: null,
      //         Id: "1b",
      //         Label:
      //           "The site survey will assist you with gathering all the necessary information for creating an accurate proposal for the customer. You will be able to add as many charging stations, electrical runs, and power sources necessary for the customer's unique installation needs.",
      //         Type: "Survey",
      //         IsCompleted: false
      //       }
      //     ],
      //     Tasks: [],
      //     Next: null
      //   },
      //   Events: [],
      //   Owner: {
      //     Id: 8284,
      //     DBAName: "AshbyKTech",
      //     ImageUrl: null
      //   },
      //   CompletionPercentage: 0,
      //   Type: "Activity",
      //   Project: {
      //     Id: 12484,
      //     Name: " High EV Stations pre-assign",
      //     DueDate: null,
      //     Status: "New",
      //     ModifiedOn: "2019-03-19T22:01:28.4083924",
      //     Attachments: []
      //   },
      //   Collaborators: [],
      //   SelectedOn: null,
      //   CreatedOn: "2019-03-19T22:00:42.433",
      //   IsFrozen: false,
      //   AcceptedBy: null,
      //   Id: 2208999
      // },

      // {
      //   MasterActivityId: 22076,
      //   ParentActivityId: null,
      //   DerivedActivityId: null,
      //   GeneratedActivityId: null,
      //   Name: "Site Readiness Review",
      //   Program: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   OwnerProgram: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   ActivityTemplateId: 1491,
      //   Priority: {
      //     Id: 5,
      //     Description: "Medium"
      //   },
      //   Status: {
      //     Id: "New",
      //     Name: "New"
      //   },
      //   StartBy: null,
      //   StartNoLaterThan: null,
      //   Assignment: {
      //     Id: 12551,
      //     Name: "adam ashbyqakteck",
      //     Type: "Employee",
      //     ImageUrl: null,
      //     Contact: {
      //       Id: 12551,
      //       FirstName: "adam",
      //       LastName: "ashbyqakteck",
      //       Email: "ashbyqaktech+adam@gmail.com",
      //       PhoneNumber: "+19494364242",
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: "",
      //       ImageUrl: null
      //     }
      //   },
      //   Description:
      //     "Evaluate the installation site and collect enough information to create an acurate proposal.",
      //   Location: {
      //     Id: 3066,
      //     Name: "WHS",
      //     Description: null,
      //     Address: "factory area",
      //     City: "patiala",
      //     State: "punjab",
      //     Zip: "147001",
      //     Country: "India",
      //     Latitude: 34,
      //     Longitude: 76,
      //     Contact: {
      //       Id: null,
      //       FirstName: null,
      //       LastName: null,
      //       Email: null,
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   Customer: {
      //     Id: 3256,
      //     Name: "dbridge High",
      //     Type: "Company",
      //     Contact: {
      //       Id: null,
      //       FirstName: "woodbridge",
      //       LastName: "irvine",
      //       Email: "woodbridge@na.com",
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   DueDate: "2019-04-02T22:03:19.0854732",
      //   Tasks: {
      //     Id: "b",
      //     Name: "Perform site readiness review",
      //     Status: "New",
      //     CanComplete: true,
      //     Events: [],
      //     Actions: [
      //       {
      //         CustomInt1: 59,
      //         CustomString1:
      //           "https://survey2.qmerit.com/newsurvey/3?guid=22076-b-1b",
      //         CustomString2: "",
      //         Files: [],
      //         MultiDocument: null,
      //         ProjectDocument: null,
      //         Id: "1b",
      //         Label:
      //           "The site survey will assist you with gathering all the necessary information for creating an accurate proposal for the customer. You will be able to add as many charging stations, electrical runs, and power sources necessary for the customer's unique installation needs.",
      //         Type: "Survey",
      //         IsCompleted: false
      //       }
      //     ],
      //     Tasks: [],
      //     Next: null
      //   },
      //   Events: [],
      //   Owner: {
      //     Id: 8284,
      //     DBAName: "AshbyKTech",
      //     ImageUrl: null
      //   },
      //   CompletionPercentage: 0,
      //   Type: "Activity",
      //   Project: {
      //     Id: 12484,
      //     Name: " EV Stations pre-assign",
      //     DueDate: null,
      //     Status: "New",
      //     ModifiedOn: "2019-03-19T22:01:28.4083924",
      //     Attachments: []
      //   },
      //   Collaborators: [],
      //   SelectedOn: null,
      //   CreatedOn: "2019-03-19T22:00:42.433",
      //   IsFrozen: false,
      //   AcceptedBy: null,
      //   Id: 220777895
      // },
      // {
      //   MasterActivityId: 22076,
      //   ParentActivityId: null,
      //   DerivedActivityId: null,
      //   GeneratedActivityId: null,
      //   Name: "Site Readiness Review",
      //   Program: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   OwnerProgram: {
      //     Id: 4430,
      //     Name: "QMerit Default Program",
      //     DBAName: "AshbyKTech",
      //     ExternalData: null
      //   },
      //   ActivityTemplateId: 1491,
      //   Priority: {
      //     Id: 5,
      //     Description: "Medium"
      //   },
      //   Status: {
      //     Id: "New",
      //     Name: "New"
      //   },
      //   StartBy: null,
      //   StartNoLaterThan: null,
      //   Assignment: null,
      //   Description:
      //     "Evaluate the installation site and collect enough information to create an acurate proposal.",
      //   Location: {
      //     Id: 3066,
      //     Name: "WHS",
      //     Description: null,
      //     Address: "urban estate",
      //     City: "patiala",
      //     State: "punjab",
      //     Zip: "147001",
      //     Country: "India",
      //     Latitude: 34.5,
      //     Longitude: 76,
      //     Contact: {
      //       Id: null,
      //       FirstName: null,
      //       LastName: null,
      //       Email: null,
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   Customer: {
      //     Id: 3256,
      //     Name: " High",
      //     Type: "Company",
      //     Contact: {
      //       Id: null,
      //       FirstName: "woodbridge",
      //       LastName: "irvine",
      //       Email: "woodbridge@na.com",
      //       PhoneNumber: null,
      //       PreferredMethod: null,
      //       Title: null,
      //       Address: null,
      //       ImageUrl: null
      //     }
      //   },
      //   DueDate: "2019-04-02T22:03:19.0854732",
      //   Tasks: {
      //     Id: "b",
      //     Name: "Perform site readiness review",
      //     Status: "New",
      //     CanComplete: true,
      //     Events: [],
      //     Actions: [
      //       {
      //         CustomInt1: 59,
      //         CustomString1:
      //           "https://survey2.qmerit.com/newsurvey/3?guid=22076-b-1b",
      //         CustomString2: "",
      //         Files: [],
      //         MultiDocument: null,
      //         ProjectDocument: null,
      //         Id: "1b",
      //         Label:
      //           "The site survey will assist you with gathering all the necessary information for creating an accurate proposal for the customer. You will be able to add as many charging stations, electrical runs, and power sources necessary for the customer's unique installation needs.",
      //         Type: "Survey",
      //         IsCompleted: false
      //       }
      //     ],
      //     Tasks: [],
      //     Next: null
      //   },
      //   Events: [],
      //   Owner: {
      //     Id: 8284,
      //     DBAName: "AshbyKTech",
      //     ImageUrl: null
      //   },
      //   CompletionPercentage: 0,
      //   Type: "Activity",
      //   Project: {
      //     Id: 12484,
      //     Name: "Woodbridge High EV Stations pre-assign",
      //     DueDate: null,
      //     Status: "New",
      //     ModifiedOn: "2019-03-19T22:01:28.4083924",
      //     Attachments: []
      //   },
      //   Collaborators: [],
      //   SelectedOn: null,
      //   CreatedOn: "2019-03-19T22:00:42.433",
      //   IsFrozen: false,
      //   AcceptedBy: null,
      //   Id: 207115558
      // }
    ],
    OperationGuids: {}
  }
];
