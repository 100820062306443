import React, { PureComponent, Fragment } from "react";
import QuickCard from "./../../../components/QuickCard";
import styledComponents from "./style";
import Card from "./../Card";
import SavedChanges from "../../../components/SavedChanges";
import AllDone from "../../../components/AllDone";

class Requirements extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      collapseActive: false,
      showSuccess: false
    };
  }

  showSuccess = () => {
    this.setState({ showSuccess: true });
  };
  render() {
    if (this.state.showSuccess) return <SavedChanges />;
    const { Root, Title, Dec } = styledComponents;
    let connectedPrograms = 0;
    const connectedRequirements = this.state.fetchedData.ConnectedRequirements;
    connectedRequirements.map(data => {
      connectedPrograms += data.ConnectedPrograms.length;
    });
    return (
      <Root id="collapseCard">
        {connectedPrograms > 0 ? (
          <QuickCard>
            <Title>Update Requirements</Title>
            <Dec>
              In order to send you requests, companies must have your completed
              requirements. Here are the requirements that are missing, expired,
              or expiring soon:
            </Dec>
            <Card {...this.state} showSuccess={this.showSuccess} />
          </QuickCard>
        ) : (
          <AllDone />
        )}
      </Root>
    );
  }
}

export default Requirements;
