import React, { Fragment } from 'react';
import styledComponents from './styles';
import VimeoIframe from '../VimeoIframe';

const {
  ScrollDisabler,
  ModalBackground,
  ModalContainer
} = styledComponents;

export default function VideoModal({ isMounted, toggleMountModal }) {
  return (
    <Fragment>
      <ModalBackground onClick={toggleMountModal} animate={isMounted}>
        <ModalContainer onClick={e => e.stopPropagation()} animate={isMounted}>
          <VimeoIframe onClick={toggleMountModal} />
          <div className="iconBoxModal" onClick={toggleMountModal}>
            <i className="far fa-times" alt="close icon" />
          </div>
        </ModalContainer>
      </ModalBackground>
      <ScrollDisabler />
    </Fragment>
  )
}