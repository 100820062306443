import React from 'react'
import StyledComponents from './styles';
import _ from "lodash";

const {
	Card,
	TitleBox,
	IconBox,
	TitleIcon,
	TitleText,
	Subtitle
} = StyledComponents;

export default function QuoteSubmittedCard({ requestData, submittedOnDate, translations, serviceProviderName, show }) {

	if(!show) return null;
	return (
		<React.Fragment>
			<Card>
				<React.Fragment>
					<TitleBox>
						<IconBox>
							<TitleIcon className="far fa-file-invoice-dollar" />
						</IconBox>
						<TitleText>
							{serviceProviderName} {translations.title}
						</TitleText>
					</TitleBox>
					<Subtitle>
						{submittedOnDate.formattedDate} {translations.dateTimeSeparator} {submittedOnDate.formattedTime}
					</Subtitle>
				</React.Fragment>
			</Card>
		</React.Fragment>
	)
}
