import React from 'react'
import styledComponents from './styles';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';

import { useHistory } from "react-router-dom";

const {
  Link_Gray,
  CancelIcon
} = commonStyles;

const {
  CancelBoxWrapper
} = styledComponents;

export default function CancelBox({requestId, translations}) {

  const navigate = useHistory();

  function handleClick() {
    navigate.push(`/tiered-cancel-request?token=${requestId}`);
  }

  return (
    <CancelBoxWrapper>
      <Link_Gray type="button" onClick={handleClick}><CancelIcon className="far fa-times" /></Link_Gray>
      <Link_Gray type="button" onClick={handleClick}>{translations.totalSection.total.cancel.cancelBox}</Link_Gray>
    </CancelBoxWrapper>
  )
}
