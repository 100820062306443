import React, { Fragment } from "react";
import commonStyledComponents from "../../commanStyle";
import styledComponents from "./style";
import Upload from "../Upload";
import moment from "moment";

class HeaderContent extends React.Component {
  render() {
    const { TextWrapper } = styledComponents;
    const { Text, SmallText } = commonStyledComponents;
    const { requirementData, bigMsg } = this.props;
    const requiredDate =
      requirementData &&
      requirementData.RequiredDate &&
      moment(requirementData.RequiredDate)
        .format("YYYY-MMM-DD")
        .toUpperCase();
    return (
      <Fragment>
        {requirementData &&
          requirementData.Description && (
            <Fragment>
              <SmallText>Description</SmallText>
              <TextWrapper>
                <Text>{requirementData.Description}</Text>
              </TextWrapper>
            </Fragment>
          )}
        {requiredDate && (
          <TextWrapper>
            <SmallText>Required By</SmallText>
            <Text>{requiredDate}</Text>
          </TextWrapper>
        )}
        {requirementData &&
          requirementData.Attachment && (
            <Fragment>
              <div>
                <SmallText>Provided Attachments</SmallText>
              </div>
              <Upload
                bigMsg={bigMsg}
                showNames={true}
                hideCaptions={true}
                data={requirementData.Attachment}
              />
            </Fragment>
          )}
      </Fragment>
    );
  }
}
export default HeaderContent;
