import React from 'react';
import styledComponents from './styles';

const {
  MobHeader,
  LogoWrapper,
  QmeritLogo
} = styledComponents;

export default function MobileHeader() {
  return (

    <MobHeader>
      <LogoWrapper>
        <QmeritLogo src={`${window.env.blobUrl}/qmerit/wordmark/png/Qmerit-Wordmark-White.png`} />
      </LogoWrapper>  
      <h3 className="divider">|</h3>
      <h3>Load Calculator</h3>
    </MobHeader>
  )
};