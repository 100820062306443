import styled from 'styled-components';
import { mediaMin, mediaMax } from '../../../../shared/style';

const Total = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-radius: 4px;
  background: #FAFAFA;

  h5 {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; 
    text-align: right;
    margin: 0px;
  }

  .label {
    display: flex;
    padding: 12px;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;

    ${mediaMax.tablet`
      padding: 8px;
    `}
  }

  .total {
    display: flex;
    max-width: 161px;
    min-width: 128px;
    padding: 12px;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 8px;

    ${mediaMax.tablet`
      padding: 8px;
    `}
  }

  ${mediaMax.tablet`
    justify-content: space-between;
    background: var(--ig-ig-10,#F4F5F6);
    height: 46px;
  `}
`;

export default {
  Total
}