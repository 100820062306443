import React, { Fragment } from "react";
import styledComponents from "./style";
import commonStyledComponents from "../commanStyle";
import * as m from "./methods";
import UploadForShow from "../Components/Upload";

export const PreView = props => {
  const { SmallText } = commonStyledComponents,
    { Root } = styledComponents;
  return (
    <Root>
      <SmallText>Attachments</SmallText>
      <UploadForShow
        hideCaptions={true}
        showTime={true}
        uploadArray={m.getCaption(props.uploadArray)}
      />
    </Root>
  );
};
