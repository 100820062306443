import React, { Fragment } from "react";
import styledComponents from "./style";
import {
  QuoteBoundingBox,
  Doc,
  UploadFileWrapper
} from "../../../../../../shared/style";
import { quotePreviewSection } from "../../../../../../shared/methods";

const extensions =
  "text/rtf,application/rtf,text/plain,text/csv,image/jpeg,image/png,image/jpg,application/pdf,application/msword,application/vnd.ms-excel,application/vnd.ms-powerpoint,application/vnd.oasis.opendocument.text,application/vnd.oasis.opendocument.spreadsheet,application/vnd.oasis.opendocument.presentation,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.presentationml.presentation";

export default class Upload extends React.PureComponent {
  Item = src => {
    const { Item } = styledComponents;
    return (
      <Item>
        <img src={src && src} />
      </Item>
    );
  };

  uploadIconFileWrapper = () => {
    return (
      <Fragment>
        <i className="fas fa-file" />
        <UploadFileWrapper>
          <div>View</div>
          <div>Document</div>
        </UploadFileWrapper>
      </Fragment>
    );
  };

  renderDocument = (type, data, index) => {
    const { ItemWraper, Time, Remove, Replace } = styledComponents;
    return (
      <ItemWraper key={index && index}>
        {type !== "quoteImage" ? (
          <QuoteBoundingBox href={data.Url} target="_blank">
            <Doc type={type}>{this.uploadIconFileWrapper()}</Doc>
          </QuoteBoundingBox>
        ) : (
          this.Item(data && data.Url)
        )}
        {this.props.showTime && <Time>{data.caption}</Time>}
        {this.props.showNames && <Time>{data.Name}</Time>}
        {!this.props.hideCaptions &&
          data.caption && (
            // <Remove onClick={this.props.replaceDocument}>{data.caption}</Remove>
            <Replace>
              <span>Replace</span>
              <input
                type="file"
                accept={extensions}
                multiple={false}
                onChange={e => this.props.replaceDocument(e)}
              />
            </Replace>
          )}
      </ItemWraper>
    );
  };

  render() {
    // Item,
    // Remove,
    // UploadButton
    const { Root } = styledComponents;
    const bigMsg = this.props.bigMsg ? this.props.bigMsg : false;
    if (this.props.uploadArray) {
      return (
        <Root style={{ marginBottom: 13 }}>
          {this.props.uploadArray.map((data, index) => {
            const type = quotePreviewSection(data);
            return this.renderDocument(type, data, index);
          })}
        </Root>
      );
    }

    if (bigMsg) {
      const { data } = this.props;
      const type = data && quotePreviewSection(data);
      return (
        <Root
          style={{
            marginBottom: this.props.spaceBottom ? this.props.spaceBottom : 0
          }}
        >
          {this.renderDocument(type, data)}
        </Root>
      );
    }
  }
  //   else
  //     return (
  //       <Root>
  //         <ItemWraper>
  //           {this.Item(
  //             "https://www.fitzmuseum.cam.ac.uk/sites/default/files/1_0.jpg"
  //           )}
  //           <Time>1 Year</Time>
  //           <Remove>Remove</Remove>
  //         </ItemWraper>
  //         <ItemWraper>
  //           {this.Item(
  //             "https://greendotbd.com/wp-content/uploads/2015/08/image-5.jpg"
  //           )}
  //           <Time warning={true}>1 Year</Time>
  //           <Remove>Remove</Remove>
  //         </ItemWraper>
  //         <ItemWraper>
  //           <Item>
  //             <UploadButton>
  //               <i className="far fa-plus" />
  //               <p>Upload</p>
  //             </UploadButton>
  //           </Item>
  //         </ItemWraper>
  //       </Root>
  //     );
}
