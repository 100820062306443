import styled, { createGlobalStyle, css } from "styled-components"
import { mediaMax, mediaMin } from "../../shared/style";

const GlobalDraftStyles = createGlobalStyle`
    #root {
        padding: 0px !important;

        @media (max-width: 600px) {
            flex-direction: column;
        }
    }
`;

const MainWrapper = styled.div`
   
    @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;700;900&family=Red+Hat+Display:wght@400;500;700&display=swap');

    & p, a, li, span, td, th, button {
    font-family: "Mulish", sans-serif;
    }

    & h1, h2, h3 {
    font-family: "Red Hat Display", sans-serif;
    }

    background: #ffffff !important;
    padding: 0px !important;
    width: 100%;
  
`;

const Header = styled.div`
    display: flex;
    width: 100%;
    height: 64px;
    padding: 12px 24px;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-bottom: 1px solid var(--border-light-decorative, #E1E3E9);
    background: var(--Background-Page-Default, #FFF);

    ${mediaMax.tablet`
        height: 49px;
    `};
`;

const PoweredBy = styled.img`
    width: 108.11px;
    height: 32px;

    ${mediaMax.tablet`
        width: 81.082px;
        height: 24px;
    `};
`;

const Footer = styled.div`
    display: flex;
    width: 100%;
    padding: 16px 24px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-top: 1px solid var(--border-light-decorative, #E1E3E9);
    background: var(--Background-Page-Default, #FFF);

    ${props =>
        props.projectId &&
        css`
            height: 73px;
        `
    };

    ${mediaMax.tablet`
        height: 81px;

        ${props =>
            props.projectId &&
            css`
                height: 105px;
            `
        };
    `}; 
`;

const Support = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    ${mediaMax.tablet`
        flex-direction: column;
        gap: 0px;
    `}; 
`;

const SecondaryText = styled.p`
    color: var(--Text-Secondary, #6C6F7F);
    text-align: right;
    font-family: Mulish;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    margin: 0px;
`;

const SupportAnchor = styled.a`
    color: var(--Text-Secondary, #6C6F7F);
    text-align: right;
    font-family: Mulish;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`;

export default {
    GlobalDraftStyles,
    MainWrapper,
    Header,
    PoweredBy,
    Footer,
    Support,
    SecondaryText,
    SupportAnchor
}