import Geocode from "react-geocode";
import moment from "moment";
import * as constants from "../../../../../constants";
const API_KEY = constants.apiKey;

export const distance = (lat1, lon1, lat2, lon2, unit) => {
  if (lat1 == lat2 && lon1 == lon2) {
    return 0;
  } else {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    //divide by / 0.6213717 for km
    dist = dist * 60 * 1.151556; //miles
    return dist;
  }
};

export const getSurveyLinkFromAction = actionArr => {
  for (const action of actionArr) {
    if (action.Type === "Survey" && action.CustomString1) {
      return action.CustomString1;
    }
  }
};

export const getSurvey = tasks => {
  if (tasks.Actions) {
    const link = getSurveyLinkFromAction(tasks.Actions);
    if (link) {
      return link;
    }
  }
  if (tasks.Tasks && tasks.Tasks.length > 0) {
    for (const task of tasks.Tasks) {
      const link = getSurvey(task);
      if (link) {
        return link;
      }
    }
  }
  if (tasks.Tasks && typeof tasks.Tasks === "object") {
    const link = getSurvey(tasks.Tasks);
    if (link) {
      return link;
    }
  }
  if (tasks.Next) {
    const link = getSurvey(tasks.Next);
    if (link) {
      return link;
    }
  }
};

export const getDueTime = (localDueDate, localDueTime) => {
  let dueTime = moment(
    `${localDueDate} ${localDueTime}`,
    "YYYY-MMM-DD HH:mma Z"
  ).fromNow();
  let ago = dueTime.indexOf("ago");
  if (ago !== -1) {
    if (dueTime.indexOf("an") !== -1) {
      dueTime = "- 1" + dueTime.slice(2, ago - 1);
    } else if (dueTime.indexOf("few") !== -1) {
      dueTime = `-1 minute`;
    } else if (dueTime.indexOf("a ") !== -1) {
      dueTime = "- 1" + dueTime.slice(1, ago - 1);
    } else {
      dueTime = "- " + dueTime.slice(0, ago - 1);
    }
  } else {
    if (dueTime.indexOf("in ") !== -1) {
      dueTime = dueTime.slice(3);
    }
    if (dueTime.indexOf("a ") !== -1) {
      dueTime = "1" + dueTime.slice(1);
    }
  }
  return dueTime;
};

export const getDueTimeColor = dueTime => {
  return dueTime.indexOf("-") === -1
    ? dueTime.indexOf("minutes") !== -1 ||
      dueTime.indexOf("minute") !== -1 ||
      dueTime.indexOf("hours") !== -1 ||
      dueTime.indexOf("hour") !== -1
      ? "#ffa000"
      : "#6e727c"
    : "#ff5722";
};

export const findWithAttr = (array, attr, value) => {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return array[i];
    }
  }
  return -1;
};

export const sortActivities = (arr1, arr2) => {
  let resultActivities = [];
  arr1.forEach(key => {
    let found = false;
    arr2 = arr2.filter(item => {
      if (!found && item.Id === key.id) {
        resultActivities.push(item);
        found = true;
        return false;
      } else return true;
    });
  });
  return resultActivities;
};

export const surveyDone = (record, fetchedData, locationDiffArray, token) => {
  if (token === "token=mockNearby") {
    if (fetchedData && fetchedData.Activities) {
      fetchedData.Activities = editOne(fetchedData.Activities, record.id, "Id");
    }
  } else {
    if (fetchedData && fetchedData.Activities) {
      fetchedData.Activities = spliceOne(
        fetchedData.Activities,
        record.id,
        "Id"
      );
    }
    if (locationDiffArray) {
      locationDiffArray = spliceOne(locationDiffArray, record.id, "id");
    }
  }
  return {
    fetchedData,
    initialMarkers: fetchedData.Activities.length,
    locationDiffArray: locationDiffArray,
    openInfo: false
  };
};

export const spliceOne = (a, b, key) => {
  for (let i = 0; i < a.length; i++) {
    if (a[i][key] === b) {
      a.splice(i, 1);
      break;
    }
  }
  return a;
};

function editOne(a, b, key) {
  for (let i = 0; i < a.length; i++) {
    if (a[i][key] === b) {
      a[i].Assignment = { assigned: true };
      break;
    }
  }
  return a;
}

export const getCoordinates = address => {
  return new Promise((resolve, reject) => {
    Geocode.setApiKey(API_KEY);
    Geocode.enableDebug();

    if (address.Location) {
      Geocode.fromAddress(
        `${address.Location.Address} ${address.Location.City}, ${
          address.Location.State
        } ${address.Location.Zip}`
      ).then(
        response => {
          const { lat, lng } = response.results[0].geometry.location;
          resolve({ lat: lat, lng: lng });
        },
        error => {
          console.error(error);
        }
      );
    } else {
      Geocode.fromAddress(address).then(
        response => {
          const { lat, lng } = response.results[0].geometry.location;
          resolve({ lat: lat, lng: lng });
        },
        error => {
          resolve({ error: "Location not found" });
          console.error(error);
        }
      );
    }
  });
};

export const getData = state => {
  let { fetchedData, record, openInfo } = state;

  const isData = fetchedData ? fetchedData.Activities : null;
  const dataLength = isData ? isData.length : 0;
  let forward = null;
  if (openInfo) {
    let selectedBid = {};
    for (let i = 0; i < dataLength; i++) {
      if (isData[i].Id === record.id) {
        selectedBid = isData[i];
      }
    }
    forward = { ...state, selectedBid };
  }
  let newFetchedData = fetchedData,
    newdataLength = dataLength;

  return { fetchedData: newFetchedData, dataLength: newdataLength, forward };
};

//get distance for each activity from current location
export function locationDiffArrayForm(fetchedData, baseLat, baseLng) {
  if (fetchedData && fetchedData.Activities) {
    let arr = [];
    let val = {};
    fetchedData.Activities.forEach((data, index) => {
      if (data.Location) {
        let item = getLocationDistance(data, baseLat, baseLng);
        let obj1 = {
          id: data.Id,
          value: item
        };
        arr.push(obj1);
        if (fetchedData.Activities.length - 1 === index) {
          arr.sort((a, b) => {
            return a.value - b.value;
          });
          let fetchedDataResultant = fetchedData;
          fetchedDataResultant.Activities = sortActivities(
            arr,
            fetchedData.Activities
          );
          val = {
            locationDiffArray: arr,
            fetchedData: fetchedDataResultant,
            initialMarkers: fetchedDataResultant.Activities.length
          };
        }
      }
    });
    return val;
  }
}

//get current location co-ordinates
export function setCustomLocation(value) {
  return new Promise(resolve => {
    getCoordinates(value).then(data => {
      if (data) {
        resolve(data);
      }
    });
  });
}

export const getLocationDistance = (data, baseLat, baseLng) => {
  if (data && data.Location) {
    const diff = distance(
      baseLat,
      baseLng,
      data.Location.Latitude,
      data.Location.Longitude
    );
    return diff;
  }
};
