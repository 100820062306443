import styled from "styled-components";

const Icon = styled.img`
  max-height: 104px !important;
`;

const Heading = styled.h2`
  margin-top: 8px;
  margin-bottom: 16px;
  opacity: 0.75;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  color: ${({ bgWhite }) => (bgWhite ? "#3e4148" : "#ffffff")};
`;

const Description = styled.p`
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
  margin: 0;
  color: ${({ bgWhite }) => (bgWhite ? "#3e4148" : "#ffffff")};
`;
export default { Icon, Heading, Description };
