import styled from 'styled-components';

const SmallIconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  position: relative;
  cursor: pointer;

  i {
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: #B9BBC1;
  }

  .tooltip {
    display: flex;
    align-items: flex-start;
    padding: 12px;
    gap: 8px;
    width: 400px;
    height: auto;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(6px);
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    white-space: normal;
    position: absolute;
    top: 0;
    left: 424px;
    transform: translate(-100%, 0);
    z-index: 1;
  }

  &:hover .tooltip {
    visibility: visible;
  }
`;

const IconBox = styled(SmallIconBox)`
  width: 20px;
  height: 20px;
`;

export default {
  SmallIconBox,
  IconBox
}