function convertToLinearPercentage(percent, width) {
  if (percent === 0) return 0;
  if (percent === 50) return 50;
  if (percent === 100) return 100;
  const radius = width / 2;
  const degree = (percent * 180) / 100;

  if (percent < 50) {
    const adjacent = Math.cos((degree * Math.PI) / 180) * radius;
    return ((radius - adjacent) / width) * 100;
  }
  const complementDegree = 180 - degree;
  const adjacent = Math.cos((complementDegree * Math.PI) / 180) * radius;
  return ((radius + adjacent) / width) * 100;
}

function getColorRange(linearGradientData, convertedPercent) {
  const index = linearGradientData.findIndex(
    (element) => convertedPercent <= element[0]
  );
  return [index - 1, index];
}

function getRgbColor(color1, color2, weight) {
  const w = weight * 2 - 1;
  const w1 = (w / 1 + 1) / 2;
  const w2 = 1 - w1;
  const rgb = [
    Math.round(color1[0] * w1 + color2[0] * w2),
    Math.round(color1[1] * w1 + color2[1] * w2),
    Math.round(color1[2] * w1 + color2[2] * w2)
  ];
  return rgb;
}

export function getProgressTextColor(linearGradientData, width, percent) {
  const convertedPercent = convertToLinearPercentage(percent, width);
  if (convertedPercent === 0) return [255, 255, 255];
  const colorRange = getColorRange(linearGradientData, convertedPercent);
  // get 2 closest colors
  const firstColor = linearGradientData[colorRange[0]][1];
  const secondColor = linearGradientData[colorRange[1]][1];
  // calc ratio btwn 2 closest colors
  const firstColor_x = width * (linearGradientData[colorRange[0]][0] / 100);
  const secondColor_x =
    width * (linearGradientData[colorRange[1]][0] / 100) - firstColor_x;
  const distance_x = width * (convertedPercent / 100) - firstColor_x;
  const ratio = distance_x / secondColor_x;
  return getRgbColor(secondColor, firstColor, ratio);
}
