import styled from 'styled-components';

import {
    mediaMin
} from "../../../../shared/style";

const Root = styled.div`
  padding: 0 16px 16px;
  display: flex;
  flex-direction: column;
  ${mediaMin.tablet`padding: 16px;`};

  .banner {
    box-shadow: none !important;
  }
`;

const CorpInfo = styled.div`
  display: flex;
  align-items: center;
  img {
    height: 40px;
    width: 40px;
    object-fit: contain;
    margin-right: 8px;
    border-radius: 8px;
  }
  p {
    color: #3e4148;
    font-size: 16px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  p.bid {
    font-size: 14px;
    color: rgba(62, 65, 72, 0.7);
  }
`;

const Description = styled.p`
  color: #3e4148;
  font-size: 16px;
  margin: 16px 0;
`;

const Details = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0 24px;

  .link {
      color: #00bcd4;
      cursor: pointer;
  }

  .preferred-contact {
      color: #6e727c;
      font-size: 12px;
      font-weight: italic;
  }
`

const Detail = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: #6e727c;
  width: 50%;
  margin-bottom: 24px;

  h3 {
      color: #3e4148;
      font-weight: bold;
      font-size: 16px;
      margin: 4px 0 8px;

  }

  span {
    font-size: 16px;
    color: #3e4148;
  }
`;

const ContactDetail = styled.div`
  display: flex;
  flex-direction: column;
  margin: 4px 0;
`;

const QuickBidWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;

  button {
      width: 50%;
  }
  h3 {
      color: #8bc34a;
      margin: 0;
      font-size: 16px;
      font-weight: bold;
  }
`;

export default {
    Root,
    CorpInfo,
    Header,
    Description,
    Details,
    Detail,
    ContactDetail,
    QuickBidWrapper
}
