
import React from "react"
import styledComponents from "./style";
import tierUpdateSuccess from '../../../../../public/images/tierUpdateSuccess.svg';

const  {Container, ThanksForUpdate ,ThanksForUpdateText} = styledComponents
 export const TierUpdateSuccess = (props) => {
    return (
        <Container>
            <img alt="Oops" src={tierUpdateSuccess} />
            <ThanksForUpdate>Thanks for accepting this request!</ThanksForUpdate>
            <ThanksForUpdateText>We’ll relay this information to the customer. Feel free to close this window and you’ll be notified once the customer approves.</ThanksForUpdateText>

        </Container>

    )
}