import styled, { css } from "styled-components";

const Card = styled.div`
  background: rgb(255, 255, 255);
  border-radius: 16px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14),
  0px 4px 5px 0px rgba(0, 0, 0, 0.12),
  0px 1px 10px 0px rgba(0, 0, 0, 0.2);
  width: 480px;
  margin: auto;
  margin-top: 146px;
  padding: 16px;
`;

const Header = styled.h2`
  color: rgb(62, 65, 72);
  font-size: 18px;
  font-family: Montserrat;
  font-weight: bold;
  height: 24px;
  line-height: 24px;
  width: 189px;
  margin: 0;
`;

const BidId = styled.p`
  color: rgba(62, 65, 72, 0.7);
  font-size: 14px;
  font-weight: normal;
  height: 20px;
  line-height: 20px;
  width: 448px;
  margin: 0;
`;

const TierPrice = styled.p`
  color: #3e4148 !important;
  font-size: 14px;
  font-weight: normal;
  height: 20px;
  letter-spacing: 0.25px;
  line-height: 20px;
  width: 370px;
  margin: 16px 86px 10px 8px;
`;

const TierChecksContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 6px;
`;


const TierTextContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const TierText = styled.p`
  color: #3e4148;
  font-size: 14px;
  font-weight: normal;
  height: 20px;
  letter-spacing: 0.25px;
  line-height: 20px;
  margin: 18px 0 4px 24px;

  ${props => props.gray && css`
    font-style: italic;
    color: #b9bbc1;
    margin-left: 0;
  `}

  ${props => props.customePrice && css`
    margin: 0 0 4px 48px; 
  `}

  ${props => {
    if (props.displayed === false) {
      return (
        css`
          display: none;
        `
      );
    }
  }}
`;

const TierTextArea = styled.textarea`
  background: rgb(250, 250, 250);
  border-radius: 4px;
  border: 1px solid rgb(222, 223, 226);
  height: 64px;
  width: 400px;
  margin-left: 24px;

  &::placeholder {
    color: rgba(62, 65, 72, 0.35);
    font-family: Lato;
    font-size: 16px;
    font-weight: normal;
    height: 20px;
    line-height: 20px;
  }

  ${props => {
    if (props.active) {
      return (
        css`
          border: 1px solid rgb(57, 99, 254);
          box-shadow: 0px 0px 4px 0px rgb(57, 99, 254);
        `
      )
    }
  }}
`;

const Button = styled.button`
  background: linear-gradient(-180deg, rgb(0, 188, 212) 0%, rgb(0, 172, 193) 100%);
  border-radius: 100px;
  border: 1px solid rgb(0, 172, 193);
  height: 40px;
  width: 200px;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  margin: 24px 124px 8px 124px;
  cursor: pointer;


  ${props => props.disabled && css`
    background: rgb(178, 235, 242);
    border: none;
   `}
`;

const CustomePriceContainer = styled.div`
  display: flex;
  flex-direction: row;

  ${props => {
    if (props.displayed === false) {
      return (
        css`
          display: none;
        `
      );
    }
  }}
`;

const CustomePriceInput = styled.input`
  background: rgb(250, 250, 250);
  border-radius: 4px 0px 0px 4px;
  border: 1px solid rgb(222, 223, 226);
  height: 44px;
  width: 260px;
  margin: 0 0 16px 48px;

  &::placeholder {
    color: rgba(62, 65, 72, 0.35);
    font-family: Lato;
    font-size: 16px;
    font-weight: normal;
    height: 20px;
    line-height: 20px;
    text-align: left;
    padding: 12px;
  }

  ${props => {
    if (props.active) {
      return (
        css`
          border: 1px solid rgb(57, 99, 254);
          box-shadow: 0px 0px 4px 0px rgb(57, 99, 254);
        `
      )
    }
  }}

`;

const CurrencyText = styled.p`
  background: rgb(222, 223, 226);
  border-radius: 0px 4px 4px 0px;
  padding: 12px 10px 10px 10px;
  height: 44px;
  width: 92px;
  margin: 0;
  text-align: center;
  color: rgb(110, 114, 124);
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 1px;
  line-height: 20px;
  text-align: center;
`;

export default {
    Card,
    Header,
    BidId,
    TierPrice,
    TierChecksContainer,
    TierTextContainer,
    TierText,
    TierTextArea,
    Button,
    CustomePriceInput,
    CustomePriceContainer,
    CurrencyText,
}