import React from "react";
import Message from "../../components/message";
import Grimace from "../../../../public/images/Grimace@3x.png";

const FetchFailure = props => {
  return (
    <Message background={props.maps}>
      <img alt="Oops" src={Grimace} />
      <h1>Well, this is embarassing&hellip;</h1>
      <p style={{ color: "#3E4148" }}>
        Looks like something went wrong. Try again, and if the problem
        continues, contact us at{" "}
        <a href="mailto:customerservice@qmerit.com">
          customerservice@qmerit.com
        </a>
      </p>
    </Message>
  );
};

export default FetchFailure;
