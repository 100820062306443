import React, { Fragment } from "react";
import InfoCard from "../../InfoCard";
import { StatusIcon } from "./styles";
import { StatusCard } from "../../styles";
import Radio from "../../../../../components/Radio";
import UserTimes from "../../../../../../../public/images/User-times.svg";

export const radioData = [
  { id: 1, label: "Pricing" },
  { id: 2, label: "Only shopping" },
  { id: 3, label: "Used personal contractor" },
  { id: 4, label: "Other…" }
];

/** Component DeclineSubmitted renders view when customer successfully declined */
export const DeclineSubmitted = props => {
  return (
    <Fragment>
      <StatusCard pt={15}>
        <StatusIcon src={UserTimes} />
        <p>
          This item has been removed from your list. Here are some of the
          details.
        </p>
      </StatusCard>
      <InfoCard isDone partial allDisable {...props} />
    </Fragment>
  );
};

/** Component RenderRadios renders radio buttons for reasons in customer decline flow*/
export const RenderRadios = ({ onChange, activity }) => {
  return radioData.map((data, inx) => {
    return (
      <Radio
        key={inx}
        label={data.label}
        onChange={onChange}
        id={`${activity.Id}-${inx}`}
        name={"reason" + activity.Id}
      />
    );
  });
};
