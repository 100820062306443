import styled from 'styled-components';
import { mediaMax, mediaMin } from '../../../../shared/style';

const ACContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  .inputSection {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 16px;

    .inputLabel {
      display: flex;
      padding: 12px;
      align-items: flex-start;
      text-align: start;
      width: 100%;

      ${mediaMax.tablet`
        display: none;
      `}
    }

    ${mediaMax.tablet`
      flex-direction: column;
      align-items: center;
      gap: 4px;
      width: 100%;
    `}
  }

  ${mediaMax.tablet`
    gap: 8px;
  `}
`;

const SectionHeaderSC = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin: 0px;

  ${mediaMax.tablet`
    font-size: 18px;
    line-height: 24px;
    display: none;
  `}
`;

const MobileSectionHeaderSC = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin: 0px;
  display: none;

  ${mediaMax.tablet`
    font-size: 18px;
    line-height: 24px;
    display: initial;
  `}
`;

const InputField = styled.div`
  display: flex;
  padding: 12px;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 8px;
  flex: 1 1 auto;
  border-radius: 4px;
  border: 1px solid #DEDFE2;
  background: #FAFAFA;
  max-width: 202px;
  white-space: nowrap;

  input {
    border: none;
    outline: none;
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding: 0px;
    background: transparent;

    ${mediaMax.tablet`
      text-align: left
    `}
  }

  label {
    display: flex;
    color: rgba(62, 65, 72, 0.35);
    justify-content: flex-end;
  }

  ${mediaMax.tablet`
    background: #FFF;
    max-width: none;
    width: 100%;
    justify-content: space-between;
  `}
`;

const InputWrapperSC = styled.div`
  width: 100%;

  ${mediaMax.tablet`
    padding: 8px;
    border-radius: 8px;
    background: #F4F5F6;
  `}
`;

const TotalWrapperSC = styled.div`
  width: 100%;

  ${mediaMin.tablet`
    display: none;
  `}
`;

export default {
  ACContainer,
  SectionHeaderSC,
  MobileSectionHeaderSC,
  InputField,
  InputWrapperSC,
  TotalWrapperSC
}