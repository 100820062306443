import { useEffect, useState } from 'react';
import queryString from 'query-string';
import _ from 'lodash';

export default function useFetchData(props) {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [responseStatus, setResponseStatus] = useState(null);
  const [blobUrl, setblobUrl] = useState('');
  const [evBlob, setEvBlob] = useState('');
  const [translations, setTranslations] = useState({});
  const guid = queryString.parse(props.location.search).token;

  const getData = async (guid, signal) => {
    const settings = {
      method: 'GET',
      headers: { 'content-type': 'application/json', "Subscription-Key": `${window.env.apiKey}` }
    }

    try {
      setIsLoading(true);
      const response = await fetch(`${window.env.qmtUrl}/Request/${guid}`, settings);
      setResponseStatus(response.status);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

      const dataObj = await response.json();
      
      if (!signal.aborted) {
        setData(dataObj);
      }
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  }
  
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsLoading(false);
      setblobUrl(window.env.blobUrl);
      setEvBlob(window.env.evBlob);
      setTranslations(window.env.translations);
    };

    const abortController = new AbortController();
    getData(guid, abortController.signal);

    return () => abortController.abort(); // Cleanup 

  }, []);

  const postData = async (radioActive, reason, completedBy = 'Fleet Manager') => {
    setIsLoading(true);

    const url = `${window.env.qmtUrl}/` + `Request/${guid}/Cancel`;

    let settings = {
      method: 'POST',
      headers: { 
        'content-type': 'application/json', 
        'Subscription-Key': `${window.env.apiKey}`
      },
      body: JSON.stringify({ 
        SelectedOption: radioActive,
        Reason: reason,
        CompletedBy: completedBy,
        CloseProject: true 
      })
    };

    try {
      const response = await fetch(url, settings);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  }

  return {
    data,
    isLoading,
    responseStatus,
    blobUrl,
    evBlob,
    translations,
    postData
  }
}