import React from "react"
import styledComponents from "./styles";
import Wrapper from "../Wrapper";
import HappyPhew from '../../../../public/images/happyface-phew.png';
import bidUnavailable from '../../../../public/images/bidUnavailable.png'

const  {Container, Title, Message } = styledComponents
const BidStatusError = (props) => {
    return (
        <Wrapper>
            {props.status === 'Cancelled' &&
                <Container className="status-cancelled">
                    <img style={{height: 72}} alt="Bid Unavailable" src={bidUnavailable} />
                    <Title>This request is not available.</Title>
                    <Message>It seems this request is no longer available—it may have been cancelled or assigned to another company.</Message>            
                </Container>
            }
            { props.status !== 'Cancelled' && 
                <Container className="status-other">
                    <img className="happy" alt="Oops" src={HappyPhew} />
                    <Title>Oops, it seems there was a mistake!</Title>
                    { props.contactInfo === true && 
                        <Message>You've already {props.status} this request. If this is incorrect, contact Qmerit Customer Service by calling  <a href={`tel:${window.env.csPhone}`}>{window.env.csPhone}</a> or emailing <a href={`mailto:${window.env.csEmail}`}>{window.env.csEmail}</a></Message>
                    }

                    { !props.contactInfo && 
                        <Message>You've already {props.status} this request.</Message>
                    }                    
                </Container>
            }
        </Wrapper>
    )
}


export default BidStatusError;