import styled, { css } from 'styled-components';
import {
  mediaMax,
  mediaMin
} from '../../../../shared/style';

const Button = styled.button`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  width: 50%;
  border: 2px solid #DEDFE2;
  border-radius: 4px;
  background: #fff;
  text-align: start;
  cursor: pointer;
  color: #6E727C;

  .pSm {
    margin: 0px;
  }

  .pBold {
    color: ${props => props.active ? '#3963FE' : '#3E4148'};
  }

  &:hover {
    background: #E4E8FF;
  }

  ${({active}) => active && css`
    background: #E4E8FF;
    border: 2px solid #3963FE;
    color: #3963FE;
  `}

  ${mediaMax.tablet`
    width: 100%;
  `}
`;

const ButtonText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  max-width: 43ch;
`;

export default {
  Button,
  ButtonText
}