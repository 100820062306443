import React from "react";
import _ from 'lodash';
import Message from "../../../components/message";
import BidAlreadySubmittedImage from '../../../../../public/images/BidAlreadySubmitted.svg';

const BidAlreadySubmitted = (props) => {
  return (
        <Message>
            <img alt="Already Decline" src={BidAlreadySubmittedImage} />
            <h1 style={{ color: "#3E4148" }}>Oops, it seems there was a mistake!</h1>

            <p style={{ color: "#3E4148" }}>You&apos;ve already submitted a bid for this request.</p>

        </Message>
    );
};

export default BidAlreadySubmitted;
