
import React from "react"
import styledComponents from "./style";
import bidStatus from '../../../../../../public/images/bidStatus.png'
import bidUnavailable from '../../../../../../public/images/icon-clipboard.png'

const  {Container, MistakeMessage, StatusMessage} = styledComponents
 export const TierUpdateSatatus = (props) => {

    if (props.bidStatus === 'Cancelled') {
        return (
            <Container>
                <img className="headerImage" alt="Bid Unavailable" src={bidUnavailable} />
                <MistakeMessage>Request unavailable.</MistakeMessage>
                <StatusMessage>It seems this request is no longer available and may have been cancelled or accepted by another company first. The good news? If the request becomes available again, your company will have first access.</StatusMessage>
                <StatusMessage>To lock in your requests, we recommend quickening your response time— this will increase your chances of securing a work opportunity!</StatusMessage>
            </Container>
        )
    } else {
        return (
            <Container>
                <img style={{height: 160, width: 190}} alt="Oops" src={bidStatus} />
                <MistakeMessage>Oops, it seems there was a mistake!</MistakeMessage>
                <StatusMessage>{`You’ve already ${props.bidStatus} this request.`}</StatusMessage>            
            </Container>
        )
    }
}