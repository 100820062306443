import { useEffect, useState } from 'react';

export default function useDebounce(input = '', delay = 500) {
  const [actualInput, setActualInput] = useState(input);
  const [debounceInput, setDebounceInput] = useState(input);

  useEffect(() => {
    const debounceId = setTimeout(() => {
      setDebounceInput(actualInput);
    }, delay);

    return () => clearTimeout(debounceId);
  }, [actualInput, delay]);

  return [debounceInput, setActualInput];
}