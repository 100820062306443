import styled from "styled-components";
import { mediaMax } from '../../../../shared/style';

const Select = styled.select`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  border: none;
  background: transparent;
  margin: 0px;
  font-family: Red Hat Display !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  color: #FFF;
  flex: 1 1 0;
  cursor: pointer;

  ${mediaMax.tablet`
    font-size: 16px;
    line-height: 20px;
  `}
  
  option {
    padding: 8px 0px;
    border-radius: 4px;
    background: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: #3E4148;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14), 0px 4px 5px 0px rgba(0, 0, 0, 0.12), 0px 1px 10px 0px rgba(0, 0, 0, 0.20);
    cursor: pointer;
  }
`;

export default {
  Select
}