import styled, { css } from "styled-components";

const Card = styled.div`
  width: 480px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14),
    0px 4px 5px 0px rgba(0, 0, 0, 0.12),
    0px 1px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  padding: 24px 24px 24px 24px;
  margin: auto;
  margin-top: 1.5rem;

  @media screen and (max-width: 767px) {
        border-radius: 0;
        box-shadow: none;
        margin-top: 20px
        width: 100%;
        overflow: hidden;
      }
    
      .custom-quote-form{
        @media screen and (max-width: 767px) {
          display: flex;
          flex-direction: column;
        }
      }
`;

const Header = styled.h2`
  color: rgb(62, 65, 72);
  font-size: 18px;
  font-family: Montserrat;
  font-weight: bold;
  height: 24px;
  line-height: 24px;
  margin: 0;
`;

const PermitBidId = styled.p`
  color: rgba(62, 65, 72, 0.7);
  font-size: 14px;
  font-weight: normal;
  height: 20px;
  line-height: 20px;
  width: 448px;
  margin: 0 0 1.5rem 0;
`;


const PermitInfo = styled.div`
  display: flex;
  justify-content: center;
  .permitInfo {
    color: rgba(62, 65, 72, 0.7);
    font-size: 12px;
    font-family: Lato;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0.25px;
    line-height: 16px;
    width: 432px;
    margin: 0 0 1.5rem 0;
  }
`;

const CalcBox = styled.div`
    height: 92px;
    border: 1px solid rgb(222, 223, 226);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    .italicText {
      color: rgba(62, 65, 72, 0.7);
      font-size: 16px;
      font-style: italic;
    }
    .boldText {
      color: rgb(62, 65, 72);
      font-size: 18px;
      font-family: Montserrat;
      font-weight: bold;
      text-align: center;
    }
`;

const UploadBox = styled.div`
    width: 432px;
    height: 144px;
    background: rgb(244, 245, 246);
    border: 1px solid rgb(222, 223, 226);
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    

    .blueDot {
      align-self: center;
      width: 44px;
      height: 44px;
      background: linear-gradient(-180deg, rgb(57, 99, 254) 0%, rgb(47, 85, 254) 100%);
      border: 1px solid rgb(47, 85, 254);
      border-radius: 50%;
    }
    p {
      text-align: center;
    }
`;

const TextAreaOpen = styled.div`
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    .blueDot {
      align-self: center;
      width: 18px;
      height: 18px;
      background: linear-gradient(-180deg, rgb(57, 99, 254) 0%, rgb(47, 85, 254) 100%);
      border: 1px solid rgb(47, 85, 254);
      border-radius: 50%;
      justify-content: center;
      text-align: center;
      margin-right: 4px;
      .iconDiv {
        display: flex;
        flex-direction: column;
        justify-content: center;
        i {
          color: #fff;
          font-size: 16px;
        }
      }
      

    }
`;




const TierTextArea = styled.textarea`
  background: rgb(250, 250, 250);
  border-radius: 4px;
  border: 1px solid rgb(222, 223, 226);
  height: 64px;
  width: 100%;
  resize: none;
  &::placeholder {
    color: rgba(62, 65, 72, 0.35);
    font-family: Lato;
    font-size: 16px;
    font-weight: normal;
    height: 20px;
    line-height: 20px;
  }
`;

const Button = styled.button`

  width: 200px;
  height: 40px;
  background: #2f55fe;
  border: 1px solid rgb(47, 85, 254);
  border-radius: 8px;

  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 16px;
  text-align: center;
  margin: 24px 124px 8px 124px;
  cursor: pointer;
  @media screen and (max-width: 767px) {
    margin 24px 0 0 0;
    width: 100%;
  }

  ${props => props.disabled && css`
    background: #8aa3ff;
    border: none;
   `}
`;


const SubmitLabel = styled.p`
  color: rgb(62, 65, 72);
  font-size: 14px;
  font-family: Lato;
  font-weight: normal;
  padding: 1rem 0 4px 0;
  .optional {
    width: 63px;
    height: 20px;
    color: rgb(185, 187, 193);
    font-size: 14px;
    font-family: Lato;
    font-weight: normal;
    font-style: italic;
    letter-spacing: 0.25px;
    line-height: 20px;
  }
`;

const SubmitPrimaryLabel = styled.p`
  color: rgb(62, 65, 72);
  font-size: 14px;
  font-family: Lato;
  font-weight: normal;
  @media screen and (max-width: 767px) {
    margin-top: 16px;
  }
`;

const SubmitSubLabel = styled.p`
  color: rgb(62, 65, 72);
  font-size: 14px;
  font-family: Lato;
  font-weight: normal;
  padding: 0 0 4px 0;
  font-style: italic;
`;


const TierSubmitForm = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 0 0 1rem 0;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      order: 2;
      margin-bottom: 0px;
    }
    p {
        margin: 0;
    }
    .customLabel {
        color: rgb(62, 65, 72);
        font-size: 14px;
        font-family: Lato;
        font-weight: normal;
        margin: 1rem 0 0 0;
    }

    .customPriceContainer {
        display: flex;
        flex-direction: row;


        .customePriceInputSpan {
            background: rgb(250, 250, 250);
            border-radius: 4px 0px 0px 4px;
            border: 1px solid rgb(222, 223, 226);
            border-right: 0;
            height: 44px;
            margin: 0;
            padding: 12px 0px 12px 12px;
            font-size: 16px;
            color: rgba(62, 65, 72, 0.35);
            &.blank {
              background: #dedfe2;
            }
            @media screen and (max-width: 767px) {
              width: 100%;
              margin-bottom: 16px;
            }
        }

        .customPriceInput {
            background: rgb(250, 250, 250);
            border-radius: 0px 0px 0px 0px;
            border: 1px solid rgb(222, 223, 226);
            height: 44px;
            margin: 0;
            width: 94px;
            border-left: 0;
            text-align: left;
            padding: 12px 12px 12px 12px;
            color: #3e4148;
            font-family: Lato;
            font-size: 16px;
            font-weight: normal;
            &::placeholder {
                height: 20px;
                line-height: 20px;
            }
            &.blank {
              background: #dedfe2;
            }
        }
        .currencyText {
            background: rgb(222, 223, 226);
            border-radius: 0px 4px 4px 0px;
            padding: 12px 10px 10px 10px;
            height: 44px;
            width: 92px;
            margin: 0;
            text-align: center;
            color: rgb(110, 114, 124);
            font-family: Lato;
            font-size: 16px;
            font-weight: normal;
            letter-spacing: 1px;
            line-height: 20px;
            text-align: center;
        }
    }

    .customPriceContainer2 {
      display: flex;
      flex-direction: row;


      .customePriceInputSpan {
          background: rgb(250, 250, 250);
          border-radius: 4px 0px 0px 4px;
          border: 1px solid rgb(222, 223, 226);
          border-right: 0;
          height: 44px;
          margin: 0;
          padding: 12px 0px 12px 12px;
          font-size: 16px;
          color: rgba(62, 65, 72, 0.35);
      }

      .customPriceInput {
          background: rgb(250, 250, 250);
          border-radius: 0px 0px 0px 0px;
          border: 1px solid rgb(222, 223, 226);
          height: 44px;
          margin: 0;
          border-left: 0;
          text-align: left;
          padding: 12px 12px 12px 12px;
          color: #3e4148;
          font-family: Lato;
          font-size: 16px;
          font-weight: normal;
          width: 318px;
          &::placeholder {
              height: 20px;
              line-height: 20px;
          }
          @media screen and (max-width: 767px) {
            width: 100%;
          }
      }
      .currencyText {
          background: rgb(222, 223, 226);
          border-radius: 0px 4px 4px 0px;
          padding: 12px 10px 10px 10px;
          height: 44px;
          width: 92px;
          margin: 0;
          text-align: center;
          color: rgb(110, 114, 124);
          font-family: Lato;
          font-size: 16px;
          font-weight: normal;
          letter-spacing: 1px;
          line-height: 20px;
          text-align: center;
      }
  }
`;

export default {
    Card,
    Header,
    PermitBidId,
    SubmitLabel,
    SubmitPrimaryLabel,
    SubmitSubLabel,
    PermitInfo,
    TierSubmitForm,
    Button,
    TierTextArea,
    CalcBox,
    UploadBox,
    TextAreaOpen,
}