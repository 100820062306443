import styled, { css } from "styled-components";
import { mediaMax } from "../../shared/style";

const TermsContainer = styled.div`
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;

  ${mediaMax.tablet`
    width: 100%
  `};
`;

const TermsOfUseTitles = styled.div`
    color: rgb(62, 65, 72);
    font-family: Montserrat;
    font-size: 24px;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 16px;
`;

const TermsOfUseMainSubTitles = styled.div`
    color: rgb(62, 65, 72);
    font-family: Montserrat;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
`;

const TermsInstructions = styled.div`
    color: rgb(62, 65, 72);
    font-family: Montserrat;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    margin-top: 16px;
`;

const TermsOfUseSubTitles = styled.div`
    color: rgb(62, 65, 72);
    font-family: Montserrat;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 16px;
    margin-top: 16px;
`;

const TermsText = styled.div`
    color: rgb(0, 0, 0);
   // font-family: RubikRoman-Regular;
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
    text-align: justify;
    margin: 10px 0 10px 0;
`;

const OfficeDataText = styled.div`
    color: rgb(0, 0, 0);
   // font-family: RubikRoman-Regular;
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
    text-align: justify;
    width: 200px;
    margin-left: 48px;
`;

const OfficeDataTextLast = styled.div`
    color: rgb(0, 0, 0);
   // font-family: RubikRoman-Regular;
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
    text-align: justify;
    width: 200px;
    margin-left: 48px;
    margin-bottom: 16px;
`;

const TermsTextPoints = styled.li`
    color: rgb(0, 0, 0);
    //font-family: RubikRoman-Regular;
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
    margin: 10px 0 10px 0;
`;

const TermsButtonContainer = styled.div`
  display: flex;
  position: fixed;
  padding: 16px 16px 14px 16px;
  bottom: 0px;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 0.95);

  @supports (backdrop-filter: blur(8px)) {
    backdrop-filter: blur(8px);
    background: none;
  }
  @media (min-width: 431px) {
    padding-top: 24px;
    padding-bottom: 24px;
    padding-right: 40px;
    flex-direction: row;
    justify-content: flex-end;
  }

  @media (min-width: 1025px) {
    padding: 24px 250px 24px 0px;
  }  

`;

const TermsSideTextButton = styled.p`
  margin: 0px 0px 12px 0px;
  color: rgb(96, 103, 112);
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  text-align: center;

  @media (min-width: 431px) {
    margin-bottom: 0px;
    margin-right: 16px;
    text-align: end;
  }

  ${props => !props.displayed && css`
    display: none;
`}
`;

const TermsButton = styled.button`
  background: linear-gradient(-180deg, rgb(57, 99, 254) 0%, rgb(47, 85, 254) 100%);
  border-radius: 12px;
  border: 1px solid rgb(47, 85, 254);
  height: 52px;
  width: 100%;
  color: rgb(255, 255, 255);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 20px;
  cursor: pointer;

  @media (min-width: 431px) {
    width: 200px;
  }

  ${props => props.actived && css`
    background: rgb(138, 163, 255);
    border: 1px solid rgb(138, 163, 255);
  `}
`;


const Iframe = styled.iframe`
  background-color: white;
  overflow: hidden; 
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: 0px solid
`;

const Img = styled.img`
  height: 40px;
  width: 135px;
  margin: 48px;
  z-index: 1;
`;

const LogoContainer = styled.div`
  width: 100%;
  height: 136px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-content: center;
  z-index: 100;

  ${mediaMax.tablet`
    justify-content: flex-start;
  `};
`


export default {
  TermsContainer,
  TermsOfUseTitles,
  TermsOfUseMainSubTitles,
  TermsInstructions,
  TermsOfUseSubTitles,
  TermsText,
  TermsTextPoints,
  TermsButton,
  TermsButtonContainer,
  TermsSideTextButton,
  OfficeDataText,
  OfficeDataTextLast,
  Iframe,
  Img,
  LogoContainer
}