import React, { PureComponent, Fragment } from "react";
import Radio from "../../components/Radio";
import QuickCard from "../../components/QuickCard";
import RequestCancelled from "../../components/RequestCancelled";
import FetchFailure from "../../components/FetchFailure";
import { getToken, fetchCancelRequest } from "../../lib/getUserData";
import fetchData from "../../lib/fetchData";
import { apiConfig } from "../../lib/apiConfig";
import styledComponent from "./style";
import { Button } from "../../shared/style";
import DropDown from "../../components/DropDown";
import Loader from "../../components/Loader/Loading";

const options = ["SwitchGear", "Tenkan", "My own contractor"];

class CancelRequest extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isDropDownActive: false,
      radioActive: null,
      cancelSuccess: null,
      reason: null,
      completedBy: null,
      isLoading: true
    };
  }

  dropDownToggle = () => {
    const { isDropDownActive } = this.state;
    this.setState({ isDropDownActive: !isDropDownActive });
  };

  componentDidMount() {
    document.body.classList.add("bg-grey");
    getToken(this.props.location.search).then(token => {
      const options = {
        ...apiConfig.getCancelRequestStatus,
        query: token
      };
      fetchData(options).then(data => {
        if (
          data.fetchedData &&
          data.fetchedData.Success &&
          data.fetchedData.Payload === "Canceled"
        ) {
          this.setState({
            ...data,
            token,
            cancelSuccess: true,
            isLoading: false
          });
        } else {
          this.setState({ token, ...data, isLoading: false });
        }
      });
    });
  }

  completedBy = data => {
    const { isDropDownActive } = this.state;
    this.setState({ completedBy: data, isDropDownActive: !isDropDownActive });
  };

  radioChange = e => {
    this.setState({ radioActive: e.target.nextElementSibling.innerHTML });
  };

  reasonChange = e => {
    this.setState({ reason: e.target.value });
  };

  cancelConfirm = () => {
    const { radioActive, completedBy } = this.state;
    if (radioActive === "Already Completed") {
      if (completedBy) {
        this.cancelRequest();
      }
      return;
    }
    if (radioActive) {
      this.cancelRequest();
    }
  };

  cancelRequest = () => {
    const { radioActive, completedBy, reason } = this.state;
    const body = {
      SelectedOption: radioActive,
      Reason: reason,
      CompletedBy: completedBy,
      CloseProject: true,
    };

    fetchCancelRequest(body).then(userData => {
      if (userData && userData.fetchedData.Success) {
        this.setState({
          ...userData,
          cancelSuccess: userData.fetchedData.Success
        });
      }
    });
  };

  dropDownCompletedBy = () => {
    return (
      <DropDown
        clr
        data={options}
        noLabel
        setSelected={this.completedBy}
        placeholder="Select who completed…"
        selected={this.state.completedBy}
      />
    );
  };

  renderRadio = () => {
    const { Text } = styledComponent;
    return this.props.translations.cancelRequest.radios.map((data, index) => {
      return (
        <Fragment>
          <Radio
            key={index}
            onChange={this.radioChange}
            id={data.id}
            name="reason"
            label={data.label}
          >
            {/* {data.id === 2 && (
              <Fragment>
                <Text>Who completed your work request?</Text>
                {this.dropDownCompletedBy()}
              </Fragment>
            )} */}
          </Radio>
        </Fragment>
      );
    });
  };

  render() {
    const { cancelSuccess, radioActive, fetchError, isLoading } = this.state;
    if (isLoading) {
      return <Loader color="#6e727c" />;
    }
    if (fetchError) {
      return <FetchFailure maps />;
    }
    if (cancelSuccess) {
      return (
        <RequestCancelled maps translations={this.props.translations}/>
      );
    }
    const {
      Root,
      Title,
      Text,
      Btn,
      ContentWrapper,
      RadioWrapper,
      TextArea,
      MessageWrapper,
      PoweredBy
    } = styledComponent;
    return (
      <Root>
        <div style={{ width: "100%" }}>
          <QuickCard>
            <div>
              <Title>{this.props.translations.cancelRequest.title}</Title>
              <ContentWrapper>
                <Text>{this.props.translations.cancelRequest.description}</Text>
                <RadioWrapper>{this.renderRadio()}</RadioWrapper>
              </ContentWrapper>
              <MessageWrapper>
                <Text>
                  {this.props.translations.cancelRequest.reasonLabel} <i>{this.props.translations.cancelRequest.text}</i>
                </Text>
                <TextArea
                  onChange={this.reasonChange}
                  placeholder={this.props.translations.cancelRequest.reason}
                />
              </MessageWrapper>
            </div>
            <div>
              <Button
                danger
                className={radioActive ? "" : "disabled"}
                mx="auto"
                style={{ padding: 0 }}
                dBlock
                onClick={this.cancelConfirm}
              >
                {this.props.translations.cancelRequest.button}
              </Button>
              <PoweredBy
                phone
                src={`${window.env.blobUrl}/qmerit/wordmark/png/Qmerit-Wordmark-IG400.png`}
                alt="Qmerit"
              />
            </div>
          </QuickCard>
          <PoweredBy
           // desktop
            src={`${window.env.blobUrl}/qmerit/wordmark/png/Qmerit-Wordmark-IG400.png`}
            alt="Qmerit"
          />
        </div>
      </Root>
    );
  }
}
export default CancelRequest;