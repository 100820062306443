import React from 'react';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';
import styledComponents from './styles';
import { formatNumberWithFraction, getErrorCurrency, getCustomerPays, getNotToExceed, getNteForReviewedQuote, getNteForUnreviewedQuote } from '../../helper';

const {
  P_Bold,
  P
} = commonStyles;

const {
  AlignmentContainer,
  Form,
  Item,
  InputField,
  SponsoredCreditItem,
  Input,
  Divider
} = styledComponents;

export default function Total({ userLocale, currencyType, subtotal, customerQuoteAmountThreshold, programQuoteAmountThreshold, sponsoredCredit, bidListSponsoredCredit, permitCredit, total, customerPays, onChange, outOfBounds, needsReview, bidStatus, showMinError, showMaxError, translations }) {
  return (
    <AlignmentContainer>
      <Form>
        <Item>
          <P_Bold>{translations.totals.subtotal}</P_Bold>
          <P>{formatNumberWithFraction(userLocale, subtotal)}</P>
        </Item>
        {permitCredit > 0 &&
          <Item>
            <P_Bold>{translations.totals.permitCredit}</P_Bold>
            <P>-{formatNumberWithFraction(userLocale, permitCredit)}</P>
          </Item>
        }
        {!needsReview ?
          <Item>
            <P_Bold>{translations.totals.notToExceed}</P_Bold>
            <P>-{formatNumberWithFraction(userLocale, getNteForReviewedQuote(bidStatus, sponsoredCredit, bidListSponsoredCredit))}</P>
          </Item>
          :
          <InputField>
            <SponsoredCreditItem>
              <div className="pDiv"><P_Bold>{translations.totals.notToExceed}</P_Bold></div>
              <div className="inputDiv">
                <div className="iconDiv">
                  <i className="far fa-minus" />
                </div>
                <Input 
                  type="number" 
                  title="Numeric and decimal separator input only."
                  placeholder={getNteForUnreviewedQuote(sponsoredCredit, programQuoteAmountThreshold, customerQuoteAmountThreshold)} 
                  defaultValue={getNteForUnreviewedQuote(sponsoredCredit, programQuoteAmountThreshold, customerQuoteAmountThreshold)} 
                  onChange={onChange} 
                  outOfBounds={outOfBounds} 
                />
              </div>
            </SponsoredCreditItem>
            {showMinError &&
              <div className="errorDiv">
                <p>{translations.totals.minimumOf} {getErrorCurrency(needsReview, getNteForUnreviewedQuote(sponsoredCredit, programQuoteAmountThreshold, customerQuoteAmountThreshold), userLocale, currencyType)}</p>
              </div>
            }
            {showMaxError &&
              <div className="errorDiv">
                <p>{translations.totals.maximumOf} {getErrorCurrency(needsReview, subtotal, userLocale, currencyType)}</p>
              </div>
            }
          </InputField>
        }
        <Divider />
        <Item>
          <h3>{translations.totals.customerPays}</h3>
          <h3>
            {getCustomerPays({
              customerPays: customerPays,
              needsReview: needsReview,
              bidStatus: bidStatus,
              userLocale: userLocale,
              currencyType: currencyType,
              subtotal: subtotal,
              permitCredit: permitCredit,
              notToExceed: getNotToExceed(needsReview, bidStatus, userLocale, sponsoredCredit, programQuoteAmountThreshold, customerQuoteAmountThreshold, bidListSponsoredCredit),
              total: total
            })} 
            {' '}{currencyType}
          </h3>
        </Item>
      </Form>
    </AlignmentContainer>
  )
}