import React, { useState, useEffect } from 'react'
import queryString from "query-string";
import _ from "lodash";
import Loader from "../../components/Loader";

import { AcceptedConfirmation } from './components/AcceptedConfirmation';
import { TierUpdateSatatus } from '../TieredBidStatus/components/AlreadyAccepted';
import { TierUpdateFail } from '../tierManage/components/TierUpdateFail';

function BidAccept(props) {

  const getBidGuid = (url) => {
    return queryString.parse(url).token
  }

  const getBidByGuid = (bidGuid) => {
    let optionalParams = {
      headers: { "content-type": "application/json; charset=UTF-8", "Subscription-Key": `${window.env.apiKey}` },
      method: "GET"
    }

    return new Promise((resolve, reject) => {
      fetch(`${window.env.qmtUrl}/Bid/${bidGuid}`, optionalParams)
        .then(res => {
          setFetchedBidStatus(res.status)
          return res.json();
        })
        .then(data => {
          setFetchedBidData(data);
          if (!data.AcceptedOn) {
            if (data.Project.Tier.Code == "CUSTOM") {
              acceptCustomBidByGuid(bidGuid)
            } else {
              acceptBidByGuid(bidGuid)
            }
          } else {
            setIsLoading(false)
          }
        })
        .catch(error => {
          reject({
            isLoading: false,
            fetchedData: { ErrorCode: 500, ...error }
          });
        })
    })
  }

  const acceptCustomBidByGuid = (guid) => {

    new Promise((resolve, reject) => {
      fetch(`${window.env.qmtUrl}/Bid/${guid}/AcceptCustom`, {
        headers: { "Subscription-Key": `${window.env.apiKey}` },
        method: "POST"
      })
        .then(response => {
          setAcceptBidStatus(response.status)
          return response.json()
        })
        .then(res => {
          setFetchedBidData(res);
          setIsLoading(false);
        })
        .catch(error => {
          reject({
            isLoading: false,
            fetchedData: { ErrorCode: 500, ...error }
          });
        })
    })
  }

  const acceptBidByGuid = (guid) => {
    let data = {
      PermitCost: null,
    }
    let optionalParams = {
      headers: { "content-type": "application/json; charset=UTF-8", "Subscription-Key": `${window.env.apiKey}` },
      body: JSON.stringify(data),
      method: "POST"
    }

    new Promise((resolve, reject) => {
      fetch(`${window.env.qmtUrl}/Bid/${guid}/Accept`, optionalParams)
        .then(response => {
          setAcceptBidStatus(response.status)
          return response.json()
        })
        .then(res => {
          setFetchedBidData(res);
          setIsLoading(false);
        })
        .catch(error => {

          reject({
            isLoading: false,
            fetchedData: { ErrorCode: 500, ...error }
          });
        })
    })
  }

  const [translations, setTranslations] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState("");
  const [fetchedBidData, setFetchedBidData] = useState({});
  const [fetchedBidStatus, setFetchedBidStatus] = useState(false);
  const [acceptBidStatus, setAcceptBidStatus] = useState(false);
  const [country, setCountry] = useState("");

  useEffect(() => {
    let bidGuid = getBidGuid(props.location.search);
    let country = queryString.parse(props.location.search).country;
    if (!_.isEmpty(bidGuid)) {
      setToken(bidGuid);
      getBidByGuid(bidGuid);
    }
    if (!_.isEmpty(country)) setCountry(country);

    if (_.has(window, 'env.translations') && !_.isEmpty(window.env.translations)) {
      setTranslations(window.env.translations);
    };

  }, [props.location.search]);

  const bidStatus = ['Completed', 'InProgress', 'Closed'].includes(fetchedBidData.Status) ? "Accepted" : fetchedBidData.Status === "Declined" ? "Declined" : '';

  if (!isLoading) {
    if (acceptBidStatus === 200 && fetchedBidData.Status !== 'Cancelled') {
      return (
        <AcceptedConfirmation FetchedBidData={fetchedBidData} />
      )
    }

    if (['Declined', 'Completed', 'InProgress', 'Closed'].includes(fetchedBidData.Status)) {
      return (<TierUpdateSatatus bidStatus={bidStatus} />)
    }

    if (fetchedBidData.Status === 'Cancelled') {
      return (<TierUpdateSatatus bidStatus="Cancelled" />)
    }

    if ([400, 404, 500].includes(fetchedBidStatus) || [400, 404, 500].includes(acceptBidStatus)) {
      return <TierUpdateFail translations={translations} country={country} />
    }

  }


  return (<Loader color={"#000000"} isLoading={isLoading} />)
}

export default BidAccept;