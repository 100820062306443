import React from 'react';
import StyledComponents from './styles';

const {
  Card,
  Title,
  TitleBox,
  IconBox,
  Details
} = StyledComponents;

export default function QuoteReviewedCard({ reviewedOnDate, translations, show }) {

  if(!show) return null;
  return (
    <Card>
      <Title>
        <TitleBox>
          <IconBox>
            <i className="far fa-search-dollar" />
          </IconBox>
          <h3>{translations.title}</h3>
        </TitleBox>
        <p className='timeStamp'>
          {reviewedOnDate.formattedDate} {translations.dateTimeSeparator} {reviewedOnDate.formattedTime}
        </p>
      </Title>
      <Details>
        <p>{translations.description}</p>
      </Details>
    </Card>
  )
}