import styled from 'styled-components';
import {
  mediaMax,
  mediaMin
} from "../../../../shared/style"

const Details = styled.div`

  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;

  width: 100%;
  /* height: 124px; */

  border: 1px solid #DEDFE2;
  border-radius: 4px;

  ${mediaMax.desktop`
    border: none;
    gap: 16px;
    padding: 0px;
  `};

`;

const DetailBox = styled.div`

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  gap: 24px;

  width: 100%;
  height: 20px;

`;

export default {
  Details,
  DetailBox
}