import queryString from "query-string";
import _ from "lodash";
import { apiConfig } from "./apiConfig";
import fetchData from "./fetchData";
import safeSessionStorage from "./safeSessionStorage";

export const fetchUserData = url => {
  return new Promise((resolve, reject) => {
    const storedFetchedData = safeSessionStorage.getJSONItem("fetchedData"),
      urlToken = getUrlToken(url),
      savedToken = _.has(storedFetchedData, "token")
        ? getUrlToken(storedFetchedData.token)
        : null;

    if (storedFetchedData && _.isEqual(urlToken, savedToken)) {
      resolve(storedFetchedData);
    } else {
      const options = {
        ...apiConfig.getConsumerInfo,
        query: safeSessionStorage.getItem("token")
      };
      return fetchData(options)
        .then(data => {
          if (data && !data.ErrorCode) {
            const stateObject = {
              ...data,
              fetchError:
                data.fetchedData && data.fetchedData.ErrorCode ? true : false,
              token: safeSessionStorage.getItem("token")
            };
            safeSessionStorage.setJSONItem("fetchedData", stateObject);
            resolve(stateObject);
          } else {
            reject({ fetchError: true, details: data });
          }
        })
        .catch(error => {
          reject(error);
        });
    }
  });
};

export const fetchRequirementsData = url => {
  return new Promise((resolve, reject) => {
    const options = {
      ...apiConfig.getRequirementsInfo,
      query: safeSessionStorage.getItem("token")
    };
    return fetchData(options)
      .then(data => {
        if (data && !data.ErrorCode) {
          const stateObject = {
            ...data,
            fetchError:
              data.fetchedData && data.fetchedData.ErrorCode ? true : false,
            token: safeSessionStorage.getItem("token")
          };
          resolve(stateObject);
        } else {
          reject({ fetchError: true, details: data });
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const fetchPendingBids = url => {
  return new Promise((resolve, reject) => {
    const qaCheck = url.indexOf("qa=true") != -1 ? "&hours=0" : "";
    const options = {
      ...apiConfig.getPendingBids,
      query: safeSessionStorage.getItem("token") + qaCheck
    };
    return fetchData(options)
      .then(data => {
        if (data && !data.ErrorCode) {
          const stateObject = {
            ...data,
            fetchError:
              data.fetchedData && data.fetchedData.ErrorCode ? true : false,
            token: safeSessionStorage.getItem("token")
          };
          resolve(stateObject);
        } else {
          reject({ fetchError: true, details: data });
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const fetchBidRequests = url => {
  return new Promise((resolve, reject) => {
    const options = {
      ...apiConfig.getBidRequests,
      query: safeSessionStorage.getItem("token")
    };
    return fetchData(options)
      .then(data => {
        if (data && !data.ErrorCode) {
          const stateObject = {
            ...data,
            fetchError:
              data.fetchedData && data.fetchedData.ErrorCode ? true : false,
            token: safeSessionStorage.getItem("token")
          };
          resolve(stateObject);
        } else {
          reject({ fetchError: true, details: data });
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const fetchBids = url => {
  return new Promise((resolve, reject) => {
    const options = {
      ...apiConfig.getBids,
      query: safeSessionStorage.getItem("token")
    };
    return fetchData(options)
      .then(data => {
        if (data && !data.ErrorCode) {
          const stateObject = {
            ...data,
            fetchError:
              data.fetchedData && data.fetchedData.ErrorCode ? true : false,
            token: safeSessionStorage.getItem("token")
          };
          resolve(stateObject);
        } else {
          reject({ fetchError: true, details: data });
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const submitBid = (bidId, bidRequestId) => {
  return new Promise((resolve, reject) => {
    const options = {
      ...apiConfig.submitBid,
      query: `bidId=${bidId}&bidRequestId=${bidRequestId}`
    };
    return fetchData(options)
      .then(data => {
        const stateObject = {
          ...data
        };
        resolve(stateObject);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const postCustomerResponse = customerResponse => {
  return new Promise((resolve, reject) => {
    const options = {
      ...apiConfig.postCustomerResponse,
      query: safeSessionStorage.getItem("token"),
      body: customerResponse
    };
    return fetchData(options)
      .then(data => {
        if (data && !data.ErrorCode) {
          const stateObject = {
            ...data,
            fetchError:
              data.fetchedData && data.fetchedData.ErrorCode ? true : false,
            token: safeSessionStorage.getItem("token"),
            customerResponse: customerResponse
          };
          resolve(stateObject);
        } else {
          reject({ fetchError: true, details: data });
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const fetchSurveyLocations = (url, lat, long, radius) => {
  return new Promise((resolve, reject) => {
    const options = {
      ...apiConfig.getSurveyLocations,
      query: safeSessionStorage.getItem("token"),
      locParams: `latitude=${lat}&longitude=${long}&radius=${radius}`
    };
    return fetchData(options)
      .then(data => {
        if (data && !data.ErrorCode) {
          const stateObject = {
            ...data,
            fetchError:
              data.fetchedData && data.fetchedData.ErrorCode ? true : false,
            token: safeSessionStorage.getItem("token")
          };
          safeSessionStorage.setJSONItem("fetchedData", stateObject);

          resolve(stateObject);
        } else {
          reject({ fetchError: true, details: data });
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const fetchCancelRequest = body => {
  const token = safeSessionStorage.getItem("token");
  return new Promise((resolve, reject) => {
    const options = {
      ...apiConfig.postCancelRequest,
      query: token,
      body: token !== "undefined" ? body : undefined
    };
    return fetchData(options)
      .then(data => {
        if (data && !data.ErrorCode) {
          const stateObject = {
            ...data,
            fetchError:
              data.fetchedData && data.fetchedData.ErrorCode ? true : false,
            token: safeSessionStorage.getItem("token")
          };
          safeSessionStorage.setJSONItem("fetchedData", stateObject);

          resolve(stateObject);
        } else {
          reject({ fetchError: true, details: data });
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const fetchTieredCancelRequest = body => {
  const token = safeSessionStorage.getItem("token");
  return new Promise((resolve, reject) => {
    const options = {
      ...apiConfig.postTieredCancelRequest,
      query: token,
      body: token !== "undefined" ? body : undefined
    };
    return fetchData(options)
      .then(data => {
        if (data && !data.ErrorCode) {
          const stateObject = {
            ...data,
            fetchError:
              data.fetchedData && data.fetchedData.ErrorCode ? true : false,
            token: safeSessionStorage.getItem("token")
          };
          safeSessionStorage.setJSONItem("fetchedData", stateObject);

          resolve(stateObject);
        } else {
          reject({ fetchError: true, details: data });
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const fetchAcceptedBids = url => {
  return new Promise((resolve, reject) => {
    const qaCheck = url.indexOf("qa=true") != -1 ? "&hours=0" : "";
    const options = {
      ...apiConfig.getAcceptedBids,
      query: safeSessionStorage.getItem("token") + qaCheck
    };
    return fetchData(options)
      .then(data => {
        if (data && !data.ErrorCode) {
          const stateObject = {
            ...data,
            fetchError:
              data.fetchedData && data.fetchedData.ErrorCode ? true : false,
            token: safeSessionStorage.getItem("token")
          };
          resolve(stateObject);
        } else {
          reject({ fetchError: true, details: data });
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const fetchCreatingNotes = (
  body,
  entityId,
  entityType = "Activity",
  entityView = "Network"
) => {
  return new Promise((resolve, reject) => {
    const options = {
      ...apiConfig.postCreateNote,
      query: safeSessionStorage.getItem("token"),
      locParams: `entityId=${entityId}&entityType=${entityType}&entityView=${entityView}`,
      body: safeSessionStorage.getItem("token") ? body : undefined
    };
    return fetchData(options)
      .then(data => {
        if (data && !data.ErrorCode) {
          const stateObject = {
            ...data,
            fetchError:
              data.fetchedData && data.fetchedData.ErrorCode ? true : false
          };
          safeSessionStorage.setJSONItem("fetchedData", stateObject);

          resolve(stateObject);
        } else {
          reject({ fetchError: true, details: data });
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const postCancelStaleActivity = (reason, bidId) => {
  return new Promise((resolve, reject) => {
    const options = {
      ...apiConfig.postCancelStaleActivity,
      query: safeSessionStorage.getItem("token"),
      locParams: `activityId=${bidId}&reason=${reason}`
    };
    return fetchData(options)
      .then(data => {
        if (data && !data.ErrorCode) {
          const stateObject = {
            ...data,
            fetchError:
              data.fetchedData && data.fetchedData.ErrorCode ? true : false
          };
          safeSessionStorage.setJSONItem("fetchedData", stateObject);

          resolve(stateObject);
        } else {
          reject({ fetchError: true, details: data });
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const postStaleBidQuote = body => {
  return new Promise((resolve, reject) => {
    const options = {
      ...apiConfig.staleBidOperation,
      query: safeSessionStorage.getItem("token"),
      locParams: `operation=Submit`,
      body
    };
    return fetchData(options)
      .then(data => {
        if (data && !data.ErrorCode) {
          const stateObject = {
            ...data,
            fetchError:
              data.fetchedData && data.fetchedData.ErrorCode ? true : false
          };
          safeSessionStorage.setJSONItem("fetchedData", stateObject);

          resolve(stateObject);
        } else {
          reject({ fetchError: true, details: data });
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const postStaleScheduleAppointment = (activityId, body) => {
  return new Promise((resolve, reject) => {
    const options = {
      ...apiConfig.staleScheduleAppointment,
      url: `Quick/${activityId}/UpdateAppointment`,
      query: safeSessionStorage.getItem("token"),
      body
    };
    return fetchData(options)
      .then(data => {
        if (data && !data.ErrorCode) {
          const stateObject = {
            ...data,
            fetchError:
              data.fetchedData && data.fetchedData.ErrorCode ? true : false
          };
          safeSessionStorage.setJSONItem("fetchedData", stateObject);

          resolve(stateObject);
        } else {
          reject({ fetchError: true, details: data });
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const getToken = urlQueryString => {
  return new Promise((resolve, reject) => {
    const savedToken = safeSessionStorage.getItem("token"),
      urlToken = queryString.parse(urlQueryString).token;

    let sessionToken = savedToken;

    if (!_.isEqual(sessionToken, urlToken)) {
      sessionToken = getQueryToken(urlQueryString);
    }

    resolve(sessionToken);
  });
};

const getUrlToken = url => {
  return queryString.parse(url).token;
};

const getQueryToken = queryParams => {
  return new Promise((resolve, reject) => {
    const rawToken = getUrlToken(queryParams);
    const queryToken = rawToken ? "token=" + rawToken : undefined;
    safeSessionStorage.setItem("token", queryToken);
    resolve(queryToken);
  });
};
