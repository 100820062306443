import React, { PureComponent, Fragment } from "react";
import Loader from "../../../components/Loader";
import fetchData from "../../../lib/fetchData";
import QuickCard from "../../../components/QuickCard";
import QmeritLogo from "../../../components/QmeritLogo";
import DocImage from "../../../../../public/images/DocImage.png";
import styles from "./styles.module.css";
import { apiConfig } from "../../../lib/apiConfig";
import FetchFailure from "../../../components/FetchFailure";
import { Banner, Pill } from "@q-core/components/lib/general";
import _ from "lodash";
import BidCard from "../../../../../public/images/BidCard.png";
import QuickSubmitQuote from "../../../features/BidStatus/QuickSubmitQuote";
import BidAccept from "../../../features/BidStatus/BidAccept";
import BidDecline from "../../../features/BidStatus/BidDecline";
import styled from "styled-components";
import { theme, mediaMax } from "../../../shared/style";
class ActionBidAccept extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props,
      hasPostData: false,
      showSubmit: false,
      showDecline: false,
      showAccept: false
      // usingMock: true
    };
  }

  componentDidMount() {
    // if (this.state.usingMock) {
    //   console.log("mocking bird", this.state);
    // } else {
    //   if (!this.state.hasPostData) {
    //     const options = {
    //       ...apiConfig.postConsumerInfo,
    //       query: "token=" + this.state.fetchedData.OperationGuids.Accept
    //     };
    //     fetchData(options).then(data => {
    //       if (data && data.fetchedData && !data.fetchedData.ErrorCode) {
    //         this.setState({ ...data, fetchSuccess: true });
    //       } else {
    //         this.setState({ fetchError: true });
    //       }
    //     });
    //   }
    // }
    this.setState({
      coreLink: `${window.env.webRoot}`
    });
  }

  changeBidView(showValue) {
    this.setState({
      [showValue]: true
    });
  }
  bidAcceptComponent = () => {
    return (
      <AcceptBidWraper>
        <AcceptBidText>
          Your company will be charged if you accept the bid request.
        </AcceptBidText>

        <AcceptBidButtonWraper>
          <div>
            <AcceptBidButton onClick={() => this.changeBidView("showAccept")}>
              Accept
            </AcceptBidButton>
          </div>
          <div>
            <AcceptBidButton
              decline
              onClick={() => this.changeBidView("showDecline")}
            >
              Decline...
            </AcceptBidButton>
          </div>
        </AcceptBidButtonWraper>
      </AcceptBidWraper>
    );
  };
  render() {
    const {
      fetchedData,
      localDueDate,
      localDueTime,
      localCreatedDate,
      localCreatedTime,
      localAcceptedDate,
      localAcceptedTime
    } = this.state;

    if (this.state.fetchError) {
      return <FetchFailure />;
    }

    if (this.state.showSubmit) {
      return <QuickSubmitQuote {...this.state} />;
    }

    if (this.state.showAccept) {
      return <BidAccept {...this.state} />;
    }

    if (this.state.showDecline) {
      return <BidDecline {...this.state} />;
    }
    const fetchedDataActivities = fetchedData && fetchedData.Activities[0];
    return (
      <QuickCard
        header
        warningHeader={true}
        headerText="New Bid Request!"
        headerType="action"
      >
        <Loader isLoading={this.state.isLoading}>
          <div className="main-content bid-view">
            <div className={styles.bidderLogo}>
              {QmeritLogo}
              <p>Qmerit</p>
            </div>
            <a href={this.state.coreLink} className={styles.bidderName}>
              {fetchedDataActivities
                ? fetchedDataActivities.Project &&
                  fetchedDataActivities.Project.Name
                : ""}
            </a>
            <h3 className={styles.bidRequest}>
              Bid Request #
              {fetchedDataActivities
                ? fetchedDataActivities.BidRequest &&
                  fetchedDataActivities.BidRequest.Id
                : ""}
            </h3>
            <p className={styles.description}>
              {fetchedDataActivities.Description}
            </p>
            {fetchedDataActivities &&
              fetchedDataActivities.Project &&
              fetchedDataActivities.Project.Attachments &&
              fetchedDataActivities.Project.Attachments.length > 0 && (
                <Fragment>
                  {fetchedDataActivities.Project.Attachments.map(
                    (file, index) => (
                      <div key={index} className={styles.documentArea}>
                        <a
                          href={file.Url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={DocImage}
                            alt="document thumbnail"
                            className={styles.over}
                          />
                        </a>
                        <a
                          href={file.Url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <h4>{file.Name}</h4>
                        </a>
                      </div>
                    )
                  )}
                </Fragment>
              )}
            <div className={styles.customerInfo}>
              {fetchedDataActivities &&
                fetchedDataActivities.Location && (
                  <div className={styles.customerOverflow}>
                    <div className={styles.infoLabel}>site</div>
                    <a
                      href={`https://www.google.com/maps/search/?api=1&query=${
                        fetchedDataActivities.Location.Address
                      } ${fetchedDataActivities.Location.City}, ${
                        fetchedDataActivities.Location.State
                      } ${fetchedDataActivities.Location.Zip}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p className={styles.siteAddressDetails}>
                        {fetchedDataActivities.Location.Address}
                      </p>
                      <p className={styles.siteAddressDetails}>
                        {fetchedDataActivities.Location.City},{" "}
                        {fetchedDataActivities.Location.State}{" "}
                        {fetchedDataActivities.Location.Zip}
                      </p>
                    </a>
                  </div>
                )}
              {fetchedDataActivities &&
                fetchedDataActivities.Priority && (
                  <div className={styles.customerLocationWrapper}>
                    <p className={styles.infoLabel}>priority</p>
                    <p className={styles.bidDetails}>
                      {fetchedDataActivities.Priority.Description}
                    </p>
                  </div>
                )}
            </div>
            <div className={styles.infoField}>
              {fetchedDataActivities &&
                fetchedDataActivities.DueDate &&
                localDueDate &&
                localDueTime && (
                  <div className={styles.customerLocationWrapper}>
                    <p className={styles.infoLabel}>due</p>
                    <p className={styles.bidDetails}>
                      {localDueDate}, {localDueTime}
                    </p>
                  </div>
                )}
              {fetchedDataActivities &&
                fetchedDataActivities.CreatedOn &&
                localCreatedDate &&
                localCreatedTime && (
                  <div className={styles.customerLocationWrapper}>
                    <p className={styles.infoLabel}>Created</p>
                    <p className={styles.bidDetails}>
                      {localCreatedDate}, {localCreatedTime}
                    </p>
                  </div>
                )}
            </div>

            {this.bidAcceptComponent()}
          </div>
          <div className={styles.bidNowSection}>
            <div className={styles.viewBidNowTitle}>
              Have enough info? Bid now!
            </div>
            <div className={styles.viewBidNowText}>
              All you&apos;ll need to do is upload your bid and input the total
              estimated cost. You can even add a messge that the customer will
              be able to see.
            </div>

            <BidNowWraper>
              <BidNowCol>
                <BidNowButton onClick={() => this.changeBidView("showSubmit")}>
                  ACCEPT AND BID NOW
                </BidNowButton>
              </BidNowCol>
              <BidNowCol>
                <BidNowImg src={BidCard} />
              </BidNowCol>
            </BidNowWraper>
          </div>
          <div className={styles.viewBidContact}>
            If you have any questions about this bid, contact{" "}
            {fetchedDataActivities.Owner.Contact.FirstName}{" "}
            {fetchedDataActivities.Owner.Contact.LastName} by calling{" "}
            <a
              href={`tel:${fetchedDataActivities.Owner.Contact.PhoneNumber}`}
              title="Phone Number"
              className={styles.viewBidContactText}
            >
              {fetchedDataActivities.Owner.Contact.PhoneNumber}
            </a>{" "}
            or emailing{" "}
            <a
              href={`mailto:${fetchedDataActivities.Owner.Contact.Email}`}
              title="Email Address"
              className={styles.viewBidContactText}
            >
              {fetchedDataActivities.Owner.Contact.Email}
            </a>
            .
          </div>
        </Loader>
      </QuickCard>
    );
  }
}

export default ActionBidAccept;
const AcceptBidWraper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0;
`;

const AcceptBidText = styled.p`
  text-align: center;
  margin: 0 0 24px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${theme.IndiqoGrey[600]};
`;

const AcceptBidButtonWraper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -${24 / 2}px;
  margin-right: -${24 / 2}px;
  > div {
    padding-left: ${24 / 2}px;
    padding-right: ${24 / 2}px;
    flex: 0 0 50%;
    max-width: 50%;
    ${mediaMax.phone`
   flex: 0 0 100%;
   max-width: 100%;
   `};
  }
`;

const AcceptBidButton = styled.a`
  margin-bottom: 24px;
  padding: 11.5px;
  display: block;
  width: 100%;
  border-radius: 100px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 1px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  text-transform: uppercase;
  transition: 0.3s opacity;
  &:hover,
  &:focus {
    opacity: 0.7;
  }
  ${mediaMax.phone`
     max-width: 264px;
     margin-left: auto;
 margin-right: auto;
 `} ${props =>
    !props.decline
      ? `border: solid 1px #7cb342;
     background-image: -webkit-gradient(linear, left top, left bottom, from(#8bc34a), to(#7cb342));
   background-image: -webkit-linear-gradient(top, #8bc34a, #7cb342);
   background-image: -o-linear-gradient(top, #8bc34a, #7cb342);
   background-image: linear-gradient(to bottom, #8bc34a, #7cb342);`
      : `border: solid 1px #f4511e;
     background-image: -webkit-gradient(linear, left top, left bottom, from(#ff5722), to(#f4511e));
   background-image: -webkit-linear-gradient(top, #ff5722, #f4511e);
   background-image: -o-linear-gradient(top, #ff5722, #f4511e);
   background-image: linear-gradient(to bottom, #ff5722, #f4511e);`};
`;
const BidNowWraper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -12px;
  line-height: 0;
`;

const BidNowCol = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 12px;
  ${mediaMax.phone`
   flex: 0 0 100%;
   max-width: 100%;
   text-align: center;
 `};
`;
const BidNowButton = styled.button`
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 1px;

  height: 40px;
  background-image: linear-gradient(to bottom, #ffffff, #f4f5f6);
  border-radius: 100px;
  color: #00bcd4;
  cursor: pointer;
  border: none;

  width: 100%;

  ${mediaMax.phone`
  max-width: 264px;margin-bottom: 12px;
 `};
`;
const BidNowImg = styled.img`
  width: 100%;
  max-height: 100%;
  margin-top: 1px;
  ${mediaMax.phone`
  max-width: 264px;
 `};
`;
