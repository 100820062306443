import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.css";
import QuickLogo from "./QuickLogo";
import StyledComponent from "./style";
import QuickActionLogo from "../../../../public/images/QmeritLogo2.png";
const { Header, Logo, Title, Wraper } = StyledComponent;
export const HeaderContent = props => (
  <Header
    iphone={props.iphone}
    backGround={props.backGround}
    className={props.className}
    id={props.id ? props.id : ""}
    maps={props.maps}
  >
    <Wraper>
      {!props.maps ? (
        <div className="Qlogo">
            <img alt="QuickActionLogo" src={QuickActionLogo} />
        </div>
        
      ) : (
        <Fragment>
          {!props.id ? (
            <Logo
              noText={props.noText}
              onClick={() => {
                props.previous && props.previous(false);
              }}
            >
              <i
                className={`${props.id ? "far fa-home" : "far fa-arrow-left"}`}
              />{" "}
              <span>
                {props.id ? (
                  <React.Fragment>
                    Go
                    <span className="noMobile"> to</span> Home
                  </React.Fragment>
                ) : (
                  "Back"
                )}
              </span>
            </Logo>
          ) : (
            <img src={QuickActionLogo} />
          )}
        </Fragment>
      )}
      <Title noView={props.noView} maps={props.maps}>
        <h2>{props.name}</h2>
        <h3 className={styles.companyOpacity}>{props.company}</h3>
      </Title>
    </Wraper>
  </Header>
);
class Wrapper extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      fetchedData: null,
      fetchError: false,
      coreLink: null,
      iphone: false
    };
  }

  componentDidMount() {
    this.setState({
      coreLink: `${
        window.env && window.env.webRoot ? window.env.webRoot : "app.qmerit.com"
      }`,
      iphone: window.navigator && window.navigator.userAgent.match(/iPhone/i)
    });
  }

  render() {
    const { maps } = this.props;

    return (
      <main role="main">
        <HeaderContent
          id={"mainHeader"}
          maps={maps}
          QuickLogo={QuickLogo}
          name={this.props.name}
          company={this.props.company}
          iphone={this.state.iphone}
          backGround={this.props.backGround && this.props.backGround}
        />
        <div className="row mainRow">{this.props.children}</div>
      </main>
    );
  }
}

Wrapper.propTypes = {
  name: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired
};

export default Wrapper;
