
import React from "react"
import styledComponents from "./style";
import bidStatus from '../../../../public/images/bidStatus.png'

const  {Container, MistakeMessage, StatusMessage, StatusMessageRedirec, Footer} = styledComponents
 export const InstallerStatus = ({translations, installerStatus}) => {
    return (
        <Container>
            <img style={{height: 160, width: 190}} alt="Oops" src={bidStatus} />
            <MistakeMessage>{translations.installerStatus.mistakeMessage}</MistakeMessage>
            <StatusMessage>
                {
                    `${translations.installerStatus.messageOne} ${installerStatus} ${translations.installerStatus.messageTwo} `
                } <StatusMessageRedirec href={`tel:${window.env.csPhone}`}>{window.env.csPhone}</StatusMessageRedirec> {
                    `${translations.installerStatus.messageThree}`
                } <StatusMessageRedirec href={`mailto:${window.env.csEmail}`}>{window.env.csEmail}</StatusMessageRedirec>.
            </StatusMessage>
            <Footer src={`${window.env.blobUrl}/qmerit/wordmark/png/Qmerit-Wordmark-IG400.png`}/>
        </Container>

    )
}