import styled, { css } from "styled-components";
import { theme } from "../../../../../shared/style";

export const InvoiceIcon = styled("img")`
  height: 53.47px;
  margin: 0 auto;
  display: block;
  margin-bottom: 19.99px;
`;

export const CheckListWrapper = styled("div")`
  padding-right: 32px;
  &:not(:last-child) {
    box-shadow: inset 0px -1px 0px 0px rgb(237, 238, 239);
  }
  label {
    display: block;
    width: fit-content;
    cursor: pointer;
    margin: 0;
    padding: 16px 0;
    position: relative;
    ${({ active }) =>
      active &&
      css`
        text-decoration: line-through;
      `};
    i {
      cursor: pointer;
      position: absolute;
      top: 16px;
      left: -32px;
      color: ${theme.IndiqoGrey[500]};
      width: 20px;
      height: 20px;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.fa-check {
        color: ${theme.Success[500]};
      }
      &.fa-circle {
        color: ${theme.IndiqoGrey[400]};
      }
    }
  }
  .inp-label {
    font-size: 14px;
    margin: 0 0 4px;
  }
`;

export const InputWrapper = styled("div")`
  padding-bottom: 16px;
`;
