import styled, { css } from "styled-components";
import {
  theme,
  fade,
  text,
  mediaMin,
  mediaMax
} from "../../../../shared/style";

export const Text = styled.p`
  margin: 0;
  /* ${props => props.breakAll && `word-break: break-all;`} */
   ${text};
  ${props => props.dis && `color: ${theme.CoolGrey};`};
  ${props => props.spaceBottom && `margin-bottom:${props.spaceBottom}px`};
`;
const SmallText = styled.p`
  margin: 0;
  margin-bottom: 3px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: 0.3px;
  ${props =>
    props.link
      ? `color: ${theme.Primary.main}`
      : `color: ${theme.IndiqoGrey[600]};`};
`;

const AlertHeading = styled.h2`
  margin-top: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
`;
const alertText = css`
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: 0.3px;
`;
const AlertReason = styled.p`
  ${alertText};
`;
const AlertReasonTime = styled.p`
  ${alertText};
`;
const Alert = styled.div`
  width: 100%;
  padding: 12px 12px 13px;
  margin-bottom: 16px;
  ${props =>
    props.type === "warning" &&
    `background-color: ${theme.Warning[100]};
    ${AlertHeading}, ${AlertReason}, ${AlertReasonTime}{
      color: ${theme.Warning[900]}
    }
    `};
  ${props =>
    props.type === "primary" &&
    ` background-color: ${theme.Primary[100]};
      ${AlertHeading}, ${AlertReason}, ${AlertReasonTime}{
        color: ${theme.Primary[900]}
      }
      `};
`;

const AlertReasonWraper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InputWraper = styled.div`
  ${props => props.spaceBottom && `margin-bottom:${props.spaceBottom}px`};
  ${props =>
    props.flow &&
    css`
      overflow: hidden;
      height: 69px;
      transition: 0.3s;
    `} ${props =>
    props.active &&
    css`
      height: 0;
    `};
`;
const CheckBoxWraper = styled.div`
  ${props =>
    props.spaceLeft
      ? `padding-left:${2 + props.spaceLeft}px`
      : `padding-left: 2px;`};
`;

const InputBox = styled.div`
  margin-bottom: 16px;
`;
const Label = styled.label`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.3px;
  color: ${theme.IndiqoGrey[800]};
  display: inline-block;
  margin-bottom: 4px;
`;
const inpField = css`
  border-radius: 4px;
  border: solid 1px ${theme.IndiqoGrey[300]};
  background-color: ${theme.IndiqoGrey.main};
`;
const Input = styled.input`
  margin: 0;
  height: 44px;
  width: 100%;
  ${inpField} ${text};
  padding: 10px;
  text-align: left;
  ${props =>
    props.disabled &&
    `
  background-color: ${theme.IndiqoGrey[300]};
  `};

  ${props =>
    props.signature &&
    `
    text-indent: 5px;
    font-family: "La Belle Aurore"; 
    font-size: 24px;
    padding:0  12px;
    padding-left: 4px;
    color: ${theme.Primary[900]};
`};
`;

const InputSelect = styled.select`
  -moz-appearance: window;
  -webkit-appearance: none;
  margin: 0;
  height: 44px;
  width: 100%;
  ${inpField} ${text};
  padding: 10px;
  text-align: left;
  ${props =>
    props.disabled &&
    `
  background-color: ${theme.IndiqoGrey[300]};
  `};

  ${props =>
    props.signature &&
    `
    text-indent: 5px;
    font-family: "La Belle Aurore"; 
    font-size: 24px;
    padding:0  12px;
    padding-left: 4px;
    color: ${theme.Primary[900]};
`};

  position: relative;
  &::before {
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
    content: "\F078";
  }
`;
const SignatureWraper = styled.div`
  height: 44px;
  display: flex;
  justify-content: space-between;
  padding: 7px 12px;
  align-items: center;
  border-radius: 4px;
  background-color: ${theme.IndiqoGrey[300]};
`;
const Signature = styled.p`
  font-family: "La Belle Aurore";
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 24px;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: normal;
  color: ${theme.Primary[900]};
  ${mediaMin.phone`padding-right: 7px;`};
`;
const Date = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: 0.3px;
  color: ${theme.IndiqoGrey[600]};

  ${props => props.noWrap && `white-space:nowrap;`} span {
    margin: 0 3px;
  }
  ${mediaMin.phone`
  ${props => !props.desktop && `display:none;`}
  `} ${mediaMax.phone`
  ${props =>
    props.desktop
      ? `display:none;`
      : `padding: 8px;
  text-align: center;
  `}
  `};
`;

const Checkbox = styled.input`
  display: none;

  & + label {
    position: relative;
    padding-left: 26px;
    cursor: pointer;
    padding-left: 26px;
    color: rgba(62, 65, 72, 0.35);
    margin-bottom: 14px;
    display: inline-block;
    min-height: 20px;
    &::before,
    &::after {
      font-family: "Font Awesome 5 Pro";
      position: absolute;
      left: 0;
      content: "";
    }
    &::before {
      content: "\\F0C8";
      font-weight: 300;
      font-size: 16px;
      color: #969ba4;
      top: 2px;
    }
  }
  ${props =>
    props.checked &&
    `
    & + label {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.25;
      color: #3e4148;

      &::before {
        font-weight: 900;
        content: "\\F0C8";
        color: #00bcd4;
        top: 0px;
      }
      &::after {
        font-weight: 400;
        content: "\\F00C";
        color: #fff;
        font-size: 10.01px;
        left: 2px;
        top: 4px;
      }
    }

    `};
`;
const Textarea = styled.textarea`
  ${inpField} border-radius: 4px;
  padding: 10px;
  height: 64px;
  width: 100% !important;
`;
const RequirementsApply = styled.div`
  display: flex;
  ${props => props.spaceTop && `margin-top:${props.spaceTop}px`};
  ${props => props.bottomSpace && `margin-bottom:${props.bottomSpace}px`};
`;
const Comment = styled.a`
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${theme.Primary.main};
  transition: 0.3s color;
  margin-bottom: 17px;
  &:hover {
    color: ${fade(theme.Primary.main, 0.7)};
  }
`;
const Note = styled.div`
  padding: 8px;
  padding-left: 40px;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${theme.Action[900]};
  background-color: ${fade(theme.Action[100], 0.95)};
  ${props => props.spaceTop && `margin-top:${props.spaceTop}px`};
  ${props => props.spaceBottom && `margin-bottom:${props.spaceBottom}px`};
  ${mediaMin.phone`margin-left: 20px; margin-right: 20px;`} i {
    position: absolute;
    left: 13px;
    top: 10px;
    font-size: 16px;
    color: ${theme.Primary.main};
    margin-right: 10px;
  }
`;

const Content = styled.div`
  padding: 16px;
`;
const Msg = styled.div`
  padding: 16px;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: left;
  background-color: ${theme.IndiqoGrey.main};
`;

const CenterMsg = styled.p`
  margin: 0 auto;
  width: fit-content;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${theme.CoolGrey};
  text-align: center;
  cursor: pointer;
  transition: 0.3s color;
  &:not(:nth-child(1)) {
    padding-top: 13px;
  }
  &:hover {
    color: ${theme.IndiqoGrey[600]};
  }
`;
const ButtonHolder = styled.div`
  ${mediaMin.phone`${props => props.onlyMobile && `display:none;`}`};
`;

const greenBtn = css`
  color: ${theme.White};
  background-color: ${theme.Success[500]};
  &:hover {
    background-color: "#7cb342";
  }
  ${mediaMax.phone`
border: solid 1px ${theme.Success[600]};
background-image: linear-gradient(to bottom, ${theme.Success[500]}, ${
    theme.Success[600]
  });
&:hover {
  background-image: linear-gradient(to bottom, ${theme.Success[600]}, ${
    theme.Success[500]
  });
}
`};
`;

const greyBtn = css`
  color: ${theme.IndiqoGrey[600]};
  background-color: ${theme.IndiqoGrey[100]};
  background-image: linear-gradient(
    to bottom,
    ${theme.IndiqoGrey[100]},
    ${theme.IndiqoGrey[200]}
  );
  &:hover {
    color: ${theme.IndiqoGrey[600]};
    background-image: linear-gradient(
      to bottom,
      ${theme.IndiqoGrey[200]},
      ${theme.IndiqoGrey[100]}
    );
  }
  ${mediaMax.phone`
border: 0;
background-color: ${theme.IndiqoGrey[100]};
background-image: none;
&:hover {
  background-color: ${fade(theme.IndiqoGrey[100], 0.8)};
}
`};
  &.active {
    ${mediaMax.phone`${greenBtn}`};
  }
`;

const Button = styled.button`
  cursor: pointer;
  text-transform: uppercase;
  height: 32px;
  min-width: 120px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 1px;
  border: solid 1px ${theme.IndiqoGrey[200]};
  color: ${theme.DisabledGreen[200]};
  background-color: ${theme.DisabledGreen[100]};
  ${mediaMax.phone`
   color: ${theme.White};
border: solid 1px ${theme.Success[600]};
  background-image: linear-gradient(to bottom, ${theme.Success[500]}, ${
    theme.Success[600]
  });
    &:hover {
      background-image: linear-gradient(to bottom, ${theme.Success[600]}, ${
    theme.Success[500]
  });
    }
    `};
  border-radius: 100px;
  transition: 0.3s all;
  ${mediaMin.phone`
  margin-left: 8px;
  `} ${mediaMax.phone`
  height: 40px;
    box-shadow: 0 1px 8px 0 ${fade(theme.Black, 0.2)}, 0 3px 4px 0 ${fade(
    theme.Black,
    0.12
  )}, 0 3px 3px 0 ${fade(theme.Black, 0.14)};
  `}
  &:hover {
    color: ${fade(theme.DisabledGreen[200], 0.7)};
    background-color: ${fade(theme.DisabledGreen[100], 0.8)};
  }

  &.green {
    ${greenBtn};
  }
  &.grey {
    ${greyBtn};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  ${mediaMin.phone`
  margin-left: auto;
  margin-right: 0;
  width: fit-content;
  `};
  ${mediaMax.phone`
  flex-Wrap:wrap;
  margin: 0 -${17 / 2}px;
  > div{
    padding: 0 ${17 / 2}px;
    flex: 0 0 50%;
    max-width:50%;
    margin-left:auto;
    margin-right:0;
  }
  ${Button}{
    width:100%;
  }
  `};
`;
export const Footer = styled.div`
  width: 100%;
  padding: 12px;
  background-color: ${theme.IndiqoGrey[100]};
  ${mediaMin.phone`
    box-shadow: inset 0 1px 0 0 ${theme.IndiqoGrey[300]};
    
    border-radius: 0 0 4px 4px;
  `};
  &:not(.normal) {
    ${mediaMax.phone`background-color: ${theme.White};`};
  }
  ${mediaMax.phone`padding: 16px; position:fixed; z-index:99999; bottom: 0; left:0;right:0;
  
  
  ${props =>
    props.spaceX &&
    `padding-left: ${props.spaceX}px;padding-right: ${props.spaceX}px;`};
  
  `};

  ${props =>
    props.spaceX &&
    `padding-left: ${props.spaceX}px;padding-right: ${props.spaceX}px;`};
`;

const MediumText = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${theme.IndiqoGrey[600]};
  text-align: right;
`;
const CommentSection = styled.div`
  margin-top: 17px;
`;

const VerifiedText = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: right;
  color: ${theme.IndiqoGrey[600]};
  margin: 6px 0;
`;
const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ErrorIcon = styled.span`
  margin: 2.55px;
  color: #ff5722;
  font-size: 12px;
`;
const Error = styled.div`
  color: #ff5722;
  font-size: 12px;
  font-style: italic;
  margin-top: 2.55px;
`;
export default {
  Text,
  Alert,
  AlertHeading,
  AlertReasonWraper,
  AlertReason,
  AlertReasonTime,
  SmallText,
  InputWraper,
  CheckBoxWraper,
  Checkbox,
  Label,
  Input,
  InputSelect,
  Textarea,
  RequirementsApply,
  InputBox,
  Comment,
  Note,
  Content,
  CenterMsg,
  Msg,
  Button,
  Footer,
  ButtonWrapper,
  ButtonHolder,
  MediumText,
  CommentSection,
  SignatureWraper,
  Signature,
  Date,
  VerifiedText,
  ErrorContainer,
  ErrorIcon,
  Error
};
