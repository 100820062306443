import { useState, useEffect } from "react"

/* Custom hook which delays the unmount (modalOpen) stage and allows the 
  animation to finish before the modal is removed from the DOM (and before
  changing modalOpen to false)
 */
function useDelayModalUnmount(isMounted, delayTime, whichModal) {
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    let timeoutId;
    if (isMounted && !modalOpen) {
      setModalOpen(true)
    } else if (!isMounted && modalOpen) {
      timeoutId = setTimeout(() => setModalOpen(false), delayTime); //delay our unmount
    }
    return () => clearTimeout(timeoutId); // cleanup mechanism for effects , the use of setTimeout generate a sideEffect
  }, [isMounted, delayTime, modalOpen]);
  return modalOpen;
}

export default useDelayModalUnmount