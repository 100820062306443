import React, { useState, useEffect } from 'react';
import styledComponents from './styles';
import Loader from '../../components/Loader';

const {
  Container,
  Title,
  Message,
  Footer
} = styledComponents;

export default function ManagerDeclined() {
  const [isLoading, setIsLoading] = useState(true);
  const [blobUrl, setblobUrl] = useState('');
  const [evBlob, setEvBlob] = useState('');
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsLoading(false);
      setblobUrl(window.env.blobUrl);
      setEvBlob(window.env.evBlob);
      setTranslations(window.env.translations);
    }
  }, []);

  if (!isLoading) {
    return (
      <Container>
        <img src={`${evBlob}/quote/clipboard.png`} alt="clipboard icon" />
        <Title>
          {translations.fleetReviewPage.quoteDeclined.title}
        </Title>
        <Message>
          {translations.fleetReviewPage.quoteDeclined.description} 
        </Message>
        <Footer src={`${blobUrl}/qmerit/wordmark/png/Qmerit-Wordmark-IG400.png`}/>
      </Container>
    )
}

  return <Loader color={"#000000"} isLoading={isLoading} />
}