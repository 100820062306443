import React, { Fragment } from 'react'
import StyledComponents from './styles'
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles'

const {
  H3,
  ModalOkButon
} = commonStyles;

const {
  ModalBackground,
  ModalContainer,
  ModalTitle,
  ModalContent,
  ModalCloseButton,
  ModalCloseIcon,
  ScrollDisabler
} = StyledComponents

function Modal({ isMounted, toggleMountModal, title, children }) {
  return (
    <Fragment>
      <ModalBackground onClick={toggleMountModal} animate={isMounted ? 'in' : 'out'}>
        <ModalContainer role="dialog" aria-labelledby="dialogTitle" onClick={e => e.stopPropagation()} animate={isMounted ? 'in' : 'out'}>
          <ModalTitle>
            <H3 style={{flex: "1 0 0"}} id="dialogTitle">{title}</H3>
            <ModalCloseButton role="button" onClick={toggleMountModal}>
              <ModalCloseIcon className="far fa-times" alt="x icon" name="x icon" aria-label="x icon"/>
            </ModalCloseButton>
          </ModalTitle>
          <ModalContent>
            {children}
          </ModalContent>
          <ModalOkButon onClick={toggleMountModal}>
            OK
          </ModalOkButon>
        </ModalContainer>
      </ModalBackground>
      <ScrollDisabler />
    </Fragment>
  )
}

export default Modal;