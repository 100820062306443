import React, { Fragment, useState, useEffect } from "react";
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';
import styledComponents from './styles';
import Markdown from 'react-markdown';

const {
  TermsContainer,
  Divider
} = styledComponents;

export default function TermsSection({ markdown, isQuotePage }) {
  const [mdFile, setMdFile] = useState("");

  useEffect(() => {
    fetch(markdown)
      .then(res => res.text())
      .then(md => setMdFile(md));
  }, []);

  return (
    <Fragment>
      <Divider hideForMobile={isQuotePage} />
      <TermsContainer hideForMobile={isQuotePage} >
        <Markdown children={mdFile} />
      </TermsContainer>
    </Fragment>
  )
}