import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StyledComponents from "./styles"
import Collapse from '../../../components/Collapse'
import ViewBid from './View';
import DeclineBid from './Decline';
import AcceptBid from './Accept';
import SubmitBid from './Submit';
import mockAccept from '../../../lib/mockPendingAccept';

class PendingBidView extends Component {
    state = {
        activity: this.props.activity,
        status: undefined,
        quickBid: false,
        view: 'view',
        location: this.props.activity.Location,
        forceCollapse: false,
        acceptedActivity: this.context.isMockData ? mockAccept : null
    }

    handleActionClick = ({ view, header, quickBid }) => {
        if(header)  {
            this.props.onStatusChange(this.props.index, header)
            if(header.subTitle === 'declined') {
                this.props.setForceCollapse(this.props.index)
            }
        }

        this.setState({ view, header, quickBid })
    }

    render() {
        const { 
            activity, 
            location, 
            status, 
            view,
            quickBid,
            acceptedActivity,
        } = this.state;

        return (
            <div>
                {activity && !status && view === 'view' &&
                    <ViewBid 
                        activity={activity} 
                        location={location} 
                        onActionClick={this.handleActionClick}
                        onAcceptedActivity={(data) => this.setState({ acceptedActivity: data })}
                    />
                }
                {activity && view === 'decline' && 
                    <DeclineBid 
                        activity={activity}
                        onActionClick={this.handleActionClick}
                    />
                }
                {activity && view === 'accept' &&
                    <AcceptBid 
                        activity={acceptedActivity.Activities[0]}
                        quickBid={quickBid}
                        location={acceptedActivity.Activities[0].Location}
                        onActionClick={this.handleActionClick}
                    />
                }
                {activity && view === 'submit' &&
                    <SubmitBid 
                        onActionClick={this.handleActionClick}
                        activity={activity}
                    />
                }
            </div>
        )
    }
}

PendingBidView.contextTypes = {
    isMockData: PropTypes.boolean
};

export default PendingBidView;
