import styled from 'styled-components';
import { mediaMax } from '../../../../shared/style';

const AlignmentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  ${mediaMax.desktop`
    width: 100%;
  `}
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 42%;
  gap: 24px;

  ${mediaMax.desktop`
    width: 55%;
  `}
  
  ${mediaMax.tablet`
    width: 100%;
  `}
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 12px 12px 12px 0px;

  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    margin: 0px;
  }

  ${mediaMax.desktop`
    padding: 12px 0px;
  `}
`;

const InputField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px;
  gap: 4px;

  .errorDiv {
    p {
      font-style: italic;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: right;
      color: #F4511E;
      margin: 0px;
    }
  }
`;

const SponsoredCreditItem = styled(Item)`
  padding: 0px;

  .pDiv {
    padding: 12px 0px;
  }

  .inputDiv {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0px;
    gap: 4px;

    .iconDiv {
      display:flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      margin: 12px 0px;
      
      i {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #B9BBC1;
      }
    }
  }
`;

const Input = styled.input`
  background: ${({ outOfBounds }) => outOfBounds ? '#FBE9E7' : '#FAFAFA'};
  border: ${({ outOfBounds }) => outOfBounds ? '1px solid #FF5722' : '1px solid #3963FE'};
  box-shadow: 0px 0px 4px rgba(57, 99, 254, 0.25);
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${({ outOfBounds }) => outOfBounds ? '#F4511E' : '#3E4148'};
  margin: 0px;
  padding: 1px 12px 1px 2px;

  ${mediaMax.desktop`
    font-size: 14px;
    line-height: 16px;
  `};
`;

const Divider = styled.div`
  display: block;
  height: 1px;
  background: #DEDFE2;
`;

export default {
  AlignmentContainer,
  Form,
  Item,
  InputField,
  SponsoredCreditItem,
  Input,
  Divider
}