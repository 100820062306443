const isBrowser = typeof window !== 'undefined' && window.sessionStorage ? true : false;

const safeSessionStorage = {
    getItem: function (storageKey) {
        if (isBrowser) return window.sessionStorage.getItem(storageKey)
        return
    },
    setItem: function (storageKey, storageValue) {
        if (isBrowser) return window.sessionStorage.setItem(storageKey, storageValue)
        return
    },
    getJSONItem: function (storageKey) {
        if (isBrowser) return tryParse(sessionStorage.getItem(storageKey))
        return
    },
    setJSONItem: function (storageKey, storageValue) {
        if (isBrowser) return window.sessionStorage.setItem(storageKey, JSON.stringify(storageValue))
        return
    },
}

const tryParse = data => {
    try {
        return JSON.parse(data);
    } catch (e) {
        return
    }
}

export default safeSessionStorage
