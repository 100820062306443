import React from "react";
import styledComponents from "./style";

export default class Collapse extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      style: {
        height: 0,
        overflow: "hidden"
      },
      // active: true,
      // style: {}
    };
    this.myRef = React.createRef();
    this.toggle = this.toggle.bind(this);
  }

  toggle = () => {
    const { active } = this.state;
    let bodyHeight = this.myRef.current.clientHeight;
    this.setState({
      active: !active,
      style: {
        height: bodyHeight,
        overflow: "hidden"
      }
    });
    setTimeout(() => {
      this.setState({
        style: active
          ? {
              height: 0,
              overflow: "hidden"
            }
          : {}
      });
    }, active ? 100 : 250);
  };

  componentDidMount() {
    if(this.props.startActive) {
      this.toggle()
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.forceCollapse !== prevProps.forceCollapse) {
      if(this.props.forceCollapse) {
        this.toggle()
      }
    }
  }

  render() {
    const {
      CollapseRoot,
      CollapseButton,
      Body,
      Handler,
      Arrow
    } = styledComponents;
    const { active, style } = this.state;
    const { wrapperContent, mbc } = this.props;

    return (
      <CollapseRoot
        active={active}
        className={`${active && `active`}`}
        mbc={mbc}
      >
        <CollapseButton onClick={this.toggle} active={active}>
          {wrapperContent}
          <Arrow active={active}>
            <i className="far fa-chevron-right" />
          </Arrow>
        </CollapseButton>
        <Body active={active} style={style}>
          <Handler ref={this.myRef}>{this.props.children}</Handler>
        </Body>
      </CollapseRoot>
    );
  }
}
