import styled, { css } from "styled-components";
import { Article, Section } from "./../../../components/QuickCard";
import { theme, mediaMax } from "./../../../shared/style";
import { CollapseRoot } from "../Card/CollapseData/Components/Collapse/style";
import { Header, CollapseTitle, CardRoot, DoneButton } from "../Card/style";
const Title = styled.h2`
  margin-top: 0;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${theme.IndiqoGrey[800]};
`;
const Dec = styled.p`
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${theme.IndiqoGrey[800]};
`;

const mobileHidden = css`
${Article}{
  box-shadow:none;
}
${Section}{padding:0;}
${CardRoot}{margin:0;}
${Title},${Dec},${Header},${CollapseTitle},${DoneButton}{
  display: none;
}
${CollapseRoot}{
  &.active{
    padding-bottom: 72px;
    box-shadow: none;
    min-height: 100vh;
    border-radius: 0;
        margin: 0 !important;
  }
  &:not(.active){display: none;}
}
`;
const Root = styled.div`
  ${Article} {
    min-height: initial;
  }
  /* ${mediaMax.phone`${props => props.collapse && mobileHidden}`}; */
  ${mediaMax.phone`&.requirements-collapse{${mobileHidden}}`}
}
  ;
`;

export default { Root, Title, Dec };
