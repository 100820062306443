/* 
    This file can be used for styles specific to this component.
    Please keep it as simple and clean as possible :)
*/

import styled from "styled-components";

export const StatusIcon = styled("img")`
  margin: 0 auto;
  height: 44.33px;
  margin-bottom: 20.67px;
  display: block;
`;
