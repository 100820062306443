import styled from 'styled-components';
import { mediaMin } from '../../../../shared/style';

const StyledGhostButton = styled.button`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 100%;
  height: 40px;
  border: 2px solid;
  border-color: ${({borderColor}) => borderColor};
  border-radius: 4px;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${({textColor}) => textColor};
  background: ${({bgColor}) => bgColor};
  cursor: pointer;

  .iconBox {
    display:flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
  }

  i {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  ${mediaMin.desktop`
    display: none;
  `};
`;

export default {
  StyledGhostButton
}