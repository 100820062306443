import { mediaMin } from "../../../../shared/style";
import styled, { css } from "styled-components";

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 -8px;
  margin-top: 48px;
  ${mediaMin.tablet`margin-top: 12px`};
  > div {
    padding: 0 8px;
    width: 100%;
    text-align: center;
  }
  ${({ sideDesktop }) =>
    sideDesktop &&
    css`
      ${mediaMin.tablet`margin-left: auto;
      margin-right: 0;
      max-width: 352px;`};
    `};
`;
