import React from 'react'
import _ from "lodash";

import StyledComponents from './styles';

import getStatusesList from '../../helpers/Status/statusesList'
import { getInstalledOnDate } from '../../helpers/InstallationsDates';
import { getBidServiceProviderName } from '../../helpers/BidsDates';

const {
	Timeline,
	TrackerSegment,
	Step,
	TrackerConnectorLeft,
	TrackerCircle,
	TimelineWrapper,
	StatusTitle,
	StatusSubtitle,
	Icon,
	PastIcon,
	TrackerConnectorRight,
	TrackerTextBox,
	TrackerText,
	HighlightedSubtitle,
} = StyledComponents;

export default function TimelineTracker({ currentStatus, submittedOnDate, appointmentDateStart, appointmentDateEnd, installedOnDate, fetchedRequestData, translations }) {

	let statusTitle = "";

	//Different functions were created to determine if a quote was rejected, revised or cancelled
	const isBidRejected = () => {
		if (["BidRejected"].includes(_.last(fetchedRequestData.RequestLogs).LogType)) return true
		else return false
	}
	const isBidRevised = () => {
		if (["BidRevised"].includes(_.last(fetchedRequestData.RequestLogs).LogType)) return true
		else return false
	}
	const isRequestCancelled = () => {
		if (fetchedRequestData.Status == "Canceled") return true
		else return false
	}

	//Here we determine if we're on the "active" or "inactive" Sign-Off status
	const unformattedInstalledOnDate = getInstalledOnDate(fetchedRequestData);
	let statuses = getStatusesList(translations);
	statuses.forEach((status) => {
		if (currentStatus.name == status.name) {
			if (isRequestCancelled()) status.active = false;
			else if ((status.name == translations.activeSignOff.statusName && unformattedInstalledOnDate) || status.name != translations.activeSignOff.statusName) {
				status.active = true;
			}
		}
	})

	//Here we define the status title based on different factors
	if (fetchedRequestData.Status == "Canceled") {
		statusTitle = translations.canceled.title;
	} else if (currentStatus.name == translations.activeSignOff.statusName && !currentStatus.active) {
		statusTitle = currentStatus.inactiveTitle;
	} else if ((currentStatus.name == translations.assignment.statusName && isBidRejected())) {
		statusTitle = currentStatus.declinedTitle;
	} else if ((currentStatus.name == translations.approval.statusName && isBidRevised())) {
		statusTitle = currentStatus.revisedTitle;
	} else {
		statusTitle = currentStatus.title;
	}

	return (
		<TimelineWrapper>
			<StatusTitle>{statusTitle}</StatusTitle>
			{/* In this case, if the request is cancelled, we set the "cancelled" subtitle, if not, 
			we perform the logic to set the different subtitles according to the statuses*/}
			{fetchedRequestData.Status == "Canceled" ?
				<StatusSubtitle>
					{translations.canceled.subtitle}
				</StatusSubtitle> :
				<React.Fragment>
					{/* If the current status is assigned, but the bid is rejected, we display the "declined bid" subtitle;
					If it's not rejected, we display the regular subtitle for the assignment status*/}
					{currentStatus.name == translations.assignment.statusName && isBidRejected() ?
						<StatusSubtitle>
							{translations.assignment.declinedSubtitle} <HighlightedSubtitle>{fetchedRequestData.WorkerType}.</HighlightedSubtitle>
						</StatusSubtitle> :
						currentStatus.name == translations.assignment.statusName ?
							<StatusSubtitle>
								{translations.assignment.subtitle} <HighlightedSubtitle>{submittedOnDate.formattedDate}.</HighlightedSubtitle>
							</StatusSubtitle> : null
					}
					{currentStatus.name == translations.quoting.statusName &&
						<StatusSubtitle>
							<HighlightedSubtitle>{getBidServiceProviderName(fetchedRequestData)}</HighlightedSubtitle> {translations.quoting.subtitle}
						</StatusSubtitle>
					}
					{/* If the current status is approval, but the bid is revised, we display the "revised bid" subtitle;
					If it's not revised, we display the regular subtitle for the approval status*/}
					{currentStatus.name == translations.approval.statusName && isBidRevised() ?
						<StatusSubtitle>
							<HighlightedSubtitle>{getBidServiceProviderName(fetchedRequestData)}</HighlightedSubtitle> {translations.approval.revisedSubtitle}
						</StatusSubtitle> :
						currentStatus.name == translations.approval.statusName ?
							<StatusSubtitle>
								<HighlightedSubtitle>{getBidServiceProviderName(fetchedRequestData)}</HighlightedSubtitle> {translations.approval.subtitle}
							</StatusSubtitle> : null
					}
					{currentStatus.name == translations.scheduling.statusName &&
						<StatusSubtitle>
							{translations.scheduling.subtitle}
						</StatusSubtitle>
					}
					{(currentStatus.name == translations.activeSignOff.statusName && !currentStatus.active) &&
						<StatusSubtitle>
							{translations.inactiveSignOff.subtitleOne} <HighlightedSubtitle>{appointmentDateStart.formattedDate}</HighlightedSubtitle> {appointmentDateStart.formattedTime == appointmentDateEnd.formattedTime ? translations.inactiveSignOff.subtitleNotWindow : translations.inactiveSignOff.subtitleWindow} {appointmentDateStart.formattedTime == appointmentDateEnd.formattedTime ? <HighlightedSubtitle>{appointmentDateStart.formattedTime} {appointmentDateEnd.timeZone}</HighlightedSubtitle> : <HighlightedSubtitle>{appointmentDateStart.formattedTime} - {appointmentDateEnd.formattedTime} {appointmentDateEnd.timeZone}</HighlightedSubtitle>}.
						</StatusSubtitle>
					}
					{(currentStatus.name == translations.activeSignOff.statusName && currentStatus.active) &&
						<StatusSubtitle>
							{translations.activeSignOff.subtitle} <HighlightedSubtitle>{installedOnDate.formattedDate}.</HighlightedSubtitle>
						</StatusSubtitle>
					}
				</React.Fragment>
			}
			<Timeline>
				{statuses.map((status, i) => {
					return (
						<TrackerSegment key={i}>
							<Step active={status.active}>
								{/* Here, a "cancelled" prop was added to the tracker connectors and circles so they change color if the request is cancelled*/}
								<TrackerConnectorLeft first={i == 0 ? true : false} past={status.value <= currentStatus.value ? true : false} canceled={(status.value <= currentStatus.value && isRequestCancelled()) ? true : false}/>
								<TrackerCircle active={status.active} past={status.value < currentStatus.value ? true : false} canceled={(status.value < currentStatus.value && isRequestCancelled()) ? true : false}>
									{status.active ? <Icon className={status.icon} /> : ""}
									{status.value < currentStatus.value ? <PastIcon className="far fa-check" /> : ""}
								</TrackerCircle>
								<TrackerConnectorRight last={i == (statuses.length - 1) ? true : false} past={status.value < currentStatus.value ? true : false} canceled={(status.value < currentStatus.value && isRequestCancelled()) ? true : false}/>
							</Step>
							<TrackerTextBox>
								<TrackerText active={status.active} past={status.value < currentStatus.value ? true : false}>
									{status.name}
								</TrackerText>
							</TrackerTextBox>
						</TrackerSegment>
					)
				})}
			</Timeline>
		</TimelineWrapper>
	)
}
