import _ from "lodash";

export function filterForCurrentBid(bidsArr) {
  return bidsArr.filter((bid) => {
    return !["declined", "rejected"].includes(bid.Status);
  })
}

export function getServiceProviderName(data) {
  const currentBidArr = filterForCurrentBid(data.Bids);
  return currentBidArr[0].ServiceProviderName;
}

export function getId(data) {
  const currentBidArr = filterForCurrentBid(data.Bids);
  return currentBidArr[0].Id;
}

// TODO: add more getters and other helper functions