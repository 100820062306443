import styled, { css } from 'styled-components';
import { mediaMax, mediaMin } from '../../../../shared/style';

const TaxExemptionContainer = styled.div`
  width: 100%;

  ${props =>
    props.screenSize == 'mobile' &&
    css`
      ${mediaMin.desktop`
      display: none;
    `};
  `};

  ${props =>
    props.screenSize == 'desktop' &&
    css`
      ${mediaMax.desktop`
      display: none;
    `};
  `};
`;

const TaxContentWrapper = styled.div`
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: var(--Banner-Radius, 4px);
  background: #FCE6E6;

    ${mediaMax.desktop`
      padding: 12px;
  `};
`;

const TaxExemptionTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`;

const CircleInfoIconWrapper = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  ${mediaMax.desktop`
    width: 16px;
    height: 16px;
  `};
`;

const Divider = styled.div`
  display: block;
  height: 1px;
  background: #DEDFE2;
  margin: 24px 0px 0px;
  width: 100%;

  ${mediaMax.desktop`
    margin: 24px 0px;
  `};
`;

export default {
  TaxExemptionContainer,
  TaxContentWrapper,
  TaxExemptionTextWrapper,
  CircleInfoIconWrapper,
  Divider
}