import React, { PureComponent, Fragment } from "react";
import DateTimeComponent from "./dateTimeComponent";
import moment from "moment";
import ComponentStyles from "./styles";

const {
  SelectDateWraper,
  SelectDateDots,
  SelectDateValue,
  DateTimeError
} = ComponentStyles;

class DateTime extends PureComponent {
  constructor(props) {
    super(props);
    const callFrom = props.callFrom && props.callFrom === "upload";
    this.state = {
      showModal: callFrom || false,
      initialDateTime: true,
      currentTime: props.currentTime || moment(),
      setDateTimeField: {
        date: "",
        time: "",
        timeZone: ""
      },
      showModalFor: callFrom ? "date" : "dateTime",
      showInput: callFrom || !props.showInput ? false : true
    };
  }

  clearDateTIme = () => {
    const dateTimeObj = { date: "", time: "", timeZone: "" };
    this.setState({
      setDateTimeField: dateTimeObj,
      initialDateTime: true
    });
    this.props.getDate(dateTimeObj, "", dateTimeObj);
  };

  openDateTime = () => {
    this.setState({
      showModal: true,
      initialDateTime: false
    });
  };

  closeDateTime = setDateTimeField => {
    let m = moment();
    if (
      setDateTimeField.time &&
      setDateTimeField.time !== "" &&
      setDateTimeField.date &&
      setDateTimeField.date !== ""
    ) {
      m = moment(
        `${setDateTimeField.date} ${setDateTimeField.time}`,
        "YYYY-MMM-DD hh:mma"
      );
    } else if (setDateTimeField.date && setDateTimeField.date !== "") {
      m = moment(`${setDateTimeField.date}`, "YYYY-MMM-DD");
    } else if (setDateTimeField.time && setDateTimeField.time !== "") {
      m = moment(`${setDateTimeField.time}`, "hh:mma");
    }
    this.setState({
      showModal: false,
      setDateTimeField,
      currentTime: m
    });
    if (
      (this.props.callFrom && this.props.callFrom === "upload") ||
      this.props.showInput
    ) {
      this.props.getDate &&
        this.props.getDate(setDateTimeField.originDate, m, setDateTimeField);
    }
  };

  dateTimeInput = () => {
    const dateTime = this.state.setDateTimeField;
    return this.state.initialDateTime ? (
      <SelectDateValue onClick={this.openDateTime} error={this.props.error}>
        Select a date...
      </SelectDateValue>
    ) : (
      <SelectDateValue
        className="active"
        onClick={this.openDateTime}
        error={this.props.error}
      >
        {dateTime.date !== "" && dateTime.time === "" ? (
          dateTime.date
        ) : dateTime.date === "" && dateTime.time !== "" ? (
          <Fragment>
            {dateTime.time} {dateTime.timeZone}
          </Fragment>
        ) : (
          <Fragment>
            {dateTime.date} <SelectDateDots /> {dateTime.time}{" "}
            {dateTime.timeZone}
          </Fragment>
        )}
      </SelectDateValue>
    );
  };

  render() {
    const { initialDateTime } = this.state;

    return (
      <Fragment>
        {this.state.showInput && (
          <SelectDateWraper error={this.props.error}>
            {this.dateTimeInput()}
            {initialDateTime ? (
              <i className="fal fa-calendar-alt" onClick={this.openDateTime} />
            ) : (
              <i className="fal fa-times-circle" onClick={this.clearDateTIme} />
            )}
          </SelectDateWraper>
        )}
        {this.state.showModal && (
          <DateTimeComponent
            value={this.state.currentTime}
            timeZone={this.state.setDateTimeField.timeZone}
            showModalFor={this.state.showModalFor}
            closeDateTime={this.closeDateTime}
          />
        )}
        {this.props.error && (
          <DateTimeError>
            <i className="fal fa-info-circle" />
            Select a date and/or time
          </DateTimeError>
        )}
      </Fragment>
    );
  }
}
export default DateTime;
