import styled, { css } from "styled-components";

const Container = styled.div`
    display: flex;
    height: 100vh;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;

    .headerImage {
      filter: drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 18px rgba(0, 0, 0, 0.12)) drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.2));
      margin-bottom: 24px;
      height: 80px;
    }
`;

const MistakeMessage = styled.p`
  color: rgb(62, 65, 72);
  font-size: 24px;
  font-family: Montserrat;
  font-weight: bold;
  text-align: center;
  line-height: 32px;
  margin-bottom: 16px;
`;

const StatusMessage = styled.p`
  max-width: 522px;
  color: rgb(62, 65, 72);
  font-size: 16px;
  font-family: Lato;
  font-weight: normal;
  text-align: center;
  letter-spacing: 0px;
  margin: 0px 0px 16px 0px;
  padding: 0px 20px;
  line-height: 20px;`;


export default {
    Container,
    MistakeMessage,
    StatusMessage,
}