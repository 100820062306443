import styled, { css } from "styled-components"


const PoweredBy = styled.img`
  max-height: 24px;
  position: absolute;
  top: 14px;
  left: 16px;
`
const CornerContainer = styled.div`
  position: absolute;
  top: 6px;
  right: 16px;
`;

const CornerName = styled.p`

  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  color: #3E4148;
  margin: 0px;
`;

const CornerCompanyName = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #6E727C;
  margin: 0px;
`;

const MainContainer = styled.div`

  @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;700;900&family=Red+Hat+Display:wght@700&display=swap');

  & p, a, li, span {
    font-family: "Mulish", sans-serif;
  }

  & h2, h3 {
    font-family: "Red Hat Display", sans-serif;
  }

  width:100%
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 56px 0px;

  @media only screen and (max-width: 600px) {
    margin: 0px;
    padding-top: 52px;
    background: #f4f5f6;
  }

`;

const ContentContainer = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 24px;
  background: white;
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);

  @media only screen and (max-width: 600px) {
    width: 100%;
    border-radius: 0px 0px 0px 0px;
    box-shadow: none;
  }
`;

const Container = styled.div`
  
  width: 600px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);

  @media only screen and (max-width: 600px) {
    width: 100%;
    border-radius: 0px 0px 0px 0px;
    box-shadow: none;
    padding: 0px 24px
  }
`;

const HeroTitleContainer = styled.h2`
  width: 100%;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  vertical-align: center;
  color: #FFFFFF;
  margin-bottom: 16px;
`;

const HeroTextContainer = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
`;

const DescriptionContainer = styled.div`
  width: 100%;
  background: white;
  display: flex; 
  flex-direction: column;
`;

const DescriptionTitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #3E4148;
  margin: 0px;
`;

const DescriptionSteps = styled.ol`
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  color: #3E4148;
  list-style-position: inside;
  padding-left: 0px;
  margin: 16px 0px 48px;
`;

const ButtonToCore = styled.a`
  height: 40px;
  width: 276px;
  color: white;
  background: #3963FE
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  margin: 48px 0px;
  

  font-weight: 900;
  font-size: 12px;
  line-height: 16px

  &:hover {
    background: #2F55FE;
  }
`;



const Divider = styled.hr`
    border-top: 1px solid #DEDFE2;
    margin: 0px;
    width: 100%;
`;

const DetailsContainer = styled.div`
  width: 100%;
  background: white;
  display: flex; 
  flex-direction: column;
`;

const DetailsTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 48px;
`;

const DetailsTitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #3E4148;
  margin: 0px;
`;

const DetailsSubtitle = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #6E727C;
`;

const ProjectScopeContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 24px;
`;

const ProjectScopeImg = styled.a`
  height: 126px;
  width: 120px;
  background: gray;
  background-size: contain;
`;

const ProjectScopeDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
`;

const ProjectScopeTitle = styled.a`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: #3963FE;
  margin: 0px;
`;

const ProjectScopeDescription = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #6E727C;
  margin: 0px;
`;

const ProjectData = styled.div`
  display: flex;
  margin-top: 24px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    margin-top: 0px;
  }
`;

const ProjectDetail = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;

    @media only screen and (max-width: 600px) {
      width: 100%;
    }
`;

const DataTitle = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #6E727C;
  margin: 0px;

  @media only screen and (max-width: 600px) {
    margin-top: 24px;
  }
`;

const DataText = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #3E4148;
  margin: 4px 0px 0px 0px;
`;

const DataTextURL = styled.a`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #3963FE;
  margin: 4px 0px 0px 0px;
  margin: 0px;
`;

export default {
    MainContainer,
    PoweredBy,
    CornerContainer,
    CornerName,
    CornerCompanyName,
    ContentContainer,
    Container,
    HeroTitleContainer,
    HeroTextContainer,
    DescriptionContainer,
    DescriptionTitle,
    DescriptionSteps,
    ButtonToCore,
    Divider,
    DetailsContainer,
    DetailsTitleContainer,
    DetailsTitle,
    DetailsSubtitle,
    ProjectScopeContainer,
    ProjectScopeImg,
    ProjectScopeDetails,
    ProjectScopeTitle,
    ProjectScopeDescription,
    ProjectData,
    ProjectDetail,
    DataTitle,
    DataText,
    DataTextURL
}