import styled from 'styled-components';
import { mediaMax } from '../../../../shared/style';

const Divider = styled.div`
  display: block;
  height: 1px;
  background: #DEDFE2;

  ${mediaMax.desktop`
    display: none;
  `}
`;

const StyledCancelSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

const StyledGhostButtonSmall = styled.button`
  display: flex;
  flex-direction: row;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 0px;
  background-color: transparent;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #6E727C;

  .iconBox {
    display:flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
  }

  i {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  &:hover {
    background: #EDEEEF;
    cursor: pointer;
  }

  &:active {
    background: #DEDFE2;
    cursor: pointer;
  }

  &:disabled {
    background: transparent;
    color: #B9BBC1;

    :hover {
      cursor: not-allowed;
    }
  }
`;

export default {
  Divider,
  StyledCancelSection,
  StyledGhostButtonSmall
}