import styled, { css } from "styled-components";

const Container = styled.div`
    display: flex;
    height: 100vh;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ThanksForAccepting = styled.p`
color: rgb(62, 65, 72);
font-family: Montserrat;
font-size: 18px;
font-weight: bold;
height: 24px;
line-height: 24px;
text-align: center;
width: 400px;
`;

const StatusMessage = styled.p`
color: rgb(62, 65, 72);
font-family: Lato;
font-size: 16px;
font-weight: normal;
height: 60px;
letter-spacing: 0px;
line-height: 20px;
text-align: center;
width: 400px;
`;

export default {
    Container,
    ThanksForAccepting,
    StatusMessage,
}
