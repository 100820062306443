import React, { PureComponent } from "react";

import { apiConfig } from "../../../lib/apiConfig";
import { getToken } from "../../../lib/getUserData";
import Loader from "../../../components/Loader";
import fetchData from "../../../lib/fetchData";
import FetchFailure from "../../../components/FetchFailure";
import QuickCard from "../../../components/QuickCard";
import BidDeclineConfirm from "../BidDeclineConfirm";
import styles from "./styles.module.css";
import SavedChanges from "../../../components/SavedChanges/index";
// import NoteImg from "../../../../../public/images/note-02.svg";
import ArrowText from "../../../../../public/images/icon-02.svg";
import successNoteIcom from "../../../../../public/images/note-02.svg";

class CustomerDeclined extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      isLoading: false,
      fetchSuccess: false,
      fetchError: false,
      token: "token=" + props.fetchedData.OperationGuids.Decline,
      reason: "",
      reasonSelected: "",
      otherReason: "",
      openTextArea: false,
      submitDisabled: true,
      done: false
    };
  }

  componentDidMount() {}

  handleChange(event) {
    if (
      event.target.id === "reason-1" ||
      event.target.id === "reason-2" ||
      event.target.id === "shopping"
    ) {
      this.setState({
        reason: event.target.value,
        reasonSelected: event.target.id,
        openTextArea: false,
        submitDisabled: false
      });
    }
    if (event.target.id === "reason-3") {
      this.setState({
        reason: this.state.otherReason,
        reasonSelected: event.target.id,
        openTextArea: true,
        submitDisabled: this.state.otherReason.length < 1
      });
    }
    if (event.target.id === "reason-4") {
      this.setState({
        reason: event.target.value,
        reasonSelected: "reason-3",
        otherReason: event.target.value,
        submitDisabled: event.target.value.length < 1
      });
    }
  }

  submitForm() {
    this.setState({ done: true });
    const options = {
      ...apiConfig.postConsumerInfo,
      query: this.state.token,
      body: {
        reason: this.state.reason
      }
    };
    fetchData(options).then(data => {
      if (data.fetchedData && !data.fetchedData.ErrorCode) {
        this.setState({ data, fetchSuccess: true });
      } else {
        this.setState({ fetchError: true });
      }
    });
  }

  render() {
    if (this.state.done) {
      return <SavedChanges />;
    }

    if (this.state.fetchError) {
      return <FetchFailure />;
    }

    if (this.state.fetchSuccess) {
      return <BidDeclineConfirm />;
    }

    return (
      <QuickCard>
        <Loader isLoading={this.state.isLoading}>
          <div className="main-content">
            <div className={styles.declineFlexContainer}>
              <div>
                <h2 className={styles.bidName}>
                  {this.state.fetchedData
                    ? this.state.fetchedData.Name
                    : "Bid Name"}
                </h2>
                {this.state.fetchedData !== undefined && (
                  <div>
                    <p className={styles.bidNumber}>
                      Bid Request #{this.state.fetchedData.Project.Id}
                    </p>
                    {/* <p className={styles.bdDescription}>
                      Please let {this.state.fetchedData.Owner.Name} know why
                      you won&apos;t be providing a bid.
                    </p> */}
                  </div>
                )}

                <div className={styles.alertMsg}>
                  <img src={successNoteIcom} />
                  <p>
                    Thanks! We'll note that the customer declined. We won't send
                    you anymore reminders about this bid and we'll remove it
                    from your list.
                  </p>
                </div>
                <div className={styles.headingcustomer}>
                  <p>
                    Why did <b> {this.state.fetchedData.Customer.Name}</b>{" "}
                    choose not to proceed?
                  </p>
                </div>

                <form>
                  <div className={styles.radioFieldWraperSec}>
                    <input
                      id="reason-1"
                      checked={this.state.reasonSelected === "reason-1"}
                      name="reason"
                      type="radio"
                      onChange={e => {
                        this.handleChange(e);
                      }}
                    />
                    <label for="reason-1">Pricing</label>
                  </div>

                  <div className={styles.radioFieldWraperSec}>
                    <input
                      id="reason-2"
                      checked={this.state.reasonSelected === "reason-2"}
                      name="reason"
                      type="radio"
                      onChange={e => {
                        this.handleChange(e);
                      }}
                    />
                    <label for="reason-2">Used personal electrician</label>
                  </div>

                  <div className={styles.radioFieldWraperSec}>
                    <input
                      id="shopping"
                      checked={this.state.reasonSelected === "shopping"}
                      name="reason"
                      type="radio"
                      onChange={e => {
                        this.handleChange(e);
                      }}
                    />
                    <label for="shopping">Customer was shopping</label>
                  </div>

                  <div className={styles.radioFieldWraperSec}>
                    <input
                      className={styles.declineInput}
                      checked={this.state.reasonSelected === "reason-3"}
                      type="radio"
                      name="reason"
                      id="reason-3"
                      onChange={e => {
                        this.handleChange(e);
                      }}
                    />
                    <label for="reason-3">Other...</label>
                  </div>
                </form>

                {this.state.openTextArea && (
                  <div className={styles.textAreaContainer}>
                    <label
                      className={styles.declineReasonLabel}
                      htmlFor="reason-4"
                    >
                      Reason
                    </label>
                    <textarea
                      className={styles.declineTextArea}
                      value={this.state.reason}
                      id="reason-4"
                      onChange={e => {
                        this.handleChange(e);
                      }}
                      placeholder="Let us know why the customer choose not to proceed…"
                      rows="2"
                      cols="50"
                      maxlength="500"
                    />
                  </div>
                )}
              </div>

              <div>
                <div className={styles.buttonContainer}>
                  {/* <button
                    className={styles.declineButton}
                    disabled={this.state.submitDisabled}
                    onClick={() => this.submitForm()}
                  >
                    Decline Bid
                  </button> */}

                  <p className={styles.add_a_note}>Add a Note</p>
                  <button
                    className={styles.declineButton}
                    disabled={this.state.submitDisabled}
                    onClick={() => this.submitForm()}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Loader>
      </QuickCard>
    );
  }
}

export default CustomerDeclined;
