import React from 'react'
import StyledComponents from './styles';

const {
	Card,
	CurrentStatusTitleBox,
	CurrentStatusIconBox,
	CurrentStatusTitleIcon,
	CurrentStatusTitleText,
	CurrentStatusDetails
} = StyledComponents;

export default function AssigningProjectCard( {translations, show} ) {
	
	if(!show) return null;
	return (
		<React.Fragment>
			<Card>
				<CurrentStatusTitleBox>
					<CurrentStatusIconBox>
						<CurrentStatusTitleIcon className="far fa-user-check" />
					</CurrentStatusIconBox>
					<CurrentStatusTitleText>
						{translations.title}
					</CurrentStatusTitleText>
				</CurrentStatusTitleBox>
				<CurrentStatusDetails>
					{translations.description}
				</CurrentStatusDetails>
			</Card>
		</React.Fragment>
	)
}
