import React, { useEffect } from 'react';
import styledComponents from './styles';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';
import TotalRow from '../../components/TotalRow';
import RowsMapper from '../../components/RowsMapper';
import { ToolTip } from '../../components/ToolTip';
import { useEditRow } from '../../hooks/useEditRow';
import { getSum } from '../../helper';

const {
  P_MicroBold
} = commonStyles;

const {
  GeneralAssumptionsContainer,
  SectionHeaderSC,
  Form
} = styledComponents;

export default function GeneralAssumptions({ data, setFxn, id, setCurrentRec, guid }) {
  const {
    rowsData,
    deleteRow,
    handleChange
  } = useEditRow(data, id, setCurrentRec, guid);

  useEffect(() => {
    const generalTotal = getSum(rowsData, 'total');
    setFxn(generalTotal);
  }, [rowsData]);

  return (
    <GeneralAssumptionsContainer>
      <SectionHeaderSC>General Assumptions</SectionHeaderSC>
      <Form role="table" aria-label="General Assumptions">
        <div className="header" role="row">
          <div className="generalItemsColumn" role="columnheader">
            <P_MicroBold>General Items</P_MicroBold>
          </div>
          <div className="multiplier" role="columnheader">
            <P_MicroBold>Multiplier</P_MicroBold>
            <ToolTip>
              General Lighting and Receptacle Lighting is calculated by multiplying the square footage of the house by 3. The square footage of this home was captured by the customer’s Home Charging Assessment.
              <br />
              <br />
              Small Appliance Branch Circuits load is calculated by multiplying the number of circuits (with a minimum of 2) by 1,500 watts.
              <br />
              <br />
              Laundry Branch Circuits load is calculated by multiplying the number of circuits (with a minimum of 1) by 1,500 watts.
            </ToolTip>
          </div>
          <div className="iconColumn">
            {' '}
          </div>
          <div className="watts" role="columnheader">
            <P_MicroBold>Watts</P_MicroBold>
          </div>
          <div className="iconColumn">
            {' '}
          </div>
          <div className="totalWattsColumn" role="columnheader">
            <P_MicroBold>Total Watts</P_MicroBold>
          </div>
          <div className="iconColumn">
            {' '}
          </div>
        </div>
        <RowsMapper data={rowsData} deleteRow={deleteRow} handleChange={handleChange} />
      </Form>
      <TotalRow label={'Subtotal'} total={getSum(rowsData, 'total')} uom={'watts'} />
    </GeneralAssumptionsContainer>
  )
}