import React from "react";
import styledComponents from "./style";
import paymentEnvelope from '../../../../public/images/logistics_payment.svg'


const {
    PaymentConfirmationContainer
} = styledComponents;

export const PaymentConfirmation = () => {

    return (
        <PaymentConfirmationContainer>
            <img src={paymentEnvelope}/>
            <p className="title">Your payment has been received. </p>
            <p className="text">The details you provided have been sent to a certified installer near you. We’ll notify you by email or text when a company has accepted your request. Thanks!</p>
        </PaymentConfirmationContainer>
    );
}