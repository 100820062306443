export const heavyLoadOptions = [
  {
    id: 0,
    description: '',
    value: ''
  },
  {
    id: 1,
    description: '-Custom Description-',
    value: '-Custom Description'
  },
  {
    id: 2,
    description: 'Not Legible',
    value: 'Not Legible'
  },
  {
    id: 3,
    description: 'AC',
    value: 'AC'
  },
  {
    id: 4,
    description: 'Dryer',
    value: 'Dryer'
  },
  {
    id: 5,
    description: 'Heat Pump',
    value: 'Heat Pump'
  },
  {
    id: 6,
    description: 'Jacuzzi',
    value: 'Jacuzzi'
  },
  {
    id: 7,
    description: 'Oven',
    value: 'Oven'
  },
  {
    id: 8,
    description: 'Pool',
    value: 'Pool'
  },
  {
    id: 9,
    description: 'Range',
    value: 'Range'
  },
  {
    id: 10,
    description: 'Water Heater',
    value: 'Water Heater'
  }
];