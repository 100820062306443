import React, { Component } from "react";
import Sad from "../../../../public/images/sad.svg";
import PopUpModal from "../PopUpModal";

class AlreadyTaken extends Component {
  componentDidMount() {
    if (document) document.body.style.background = "none";
  }
  render() {
    const iconStyle = {
      width: 97,
      maxHeight: 97,
      marginTop: -6,
      marginBottom: 6
    };

    return (
      <PopUpModal
        deviceTitle={"Oh, no! Looks like someone aleady grabbed this."}
        title={"This item is taken."}
        icon={Sad}
        iconStyle={iconStyle}
        previous={this.props.backToList ? this.props.backToList : "windowClose"}
      />
    );
  }
}

export default AlreadyTaken;
