import React from 'react';
import styledComponents from './styles';

const {
  BackBar,
  IconBox
} = styledComponents;

export default function BackNavigation() {
  return (
    <BackBar>
      <div className="back" onClick={() => window.history.back()}>
        <IconBox><i className="far fa-arrow-left" alt="left arrow" /></IconBox>
        Back
      </div>
      <IconBox onClick={() => window.history.back()}><i className="far fa-times" alt="x icon" /></IconBox>
    </BackBar>
  )
}