import styled, { css } from 'styled-components';

const Input = styled.input.attrs({ type: "checkbox"})`
  display: flex;
  cursor: pointer;
  outline: 2px solid #3963FE;
  border: 1px solid #3963FE;
  border-radius: 2px;
  appearance: none;
  /* appearance: checkbox; */
  width: 18px;
  height: 18px;
  margin: 3px;

  &:checked {
    outline: 0px solid #3963FE;
    appearance: checkbox;
    border: 0px solid #3963FE;
    border-radius: 0px;
  }
`;

const Label = styled.label`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  text-align: start;
  padding: 0px;
  gap: 8px;
  margin: 0px;
  cursor: pointer;
`;

const CheckboxContainer = styled.div`
  padding: 3px;
`;

export default {
  Input,
  Label,
  CheckboxContainer
}