import React from 'react';
import StyledComponents from './styles';

const {
  Card,
  Title,
  TitleBox,
  IconBox
} = StyledComponents;

export default function QuoteRevisedCard({ revisedOnDate, serviceProviderName, translations, show }) {
  // translations = projectStatusTracker.statusScreen.completedStatus.quoteRevised
  const {
    title,
    dateTimeSeparator
  } = translations;

  if(!show) return null;
  return (
    <Card>
      <Title>
        <TitleBox>
          <IconBox>
            <i className="far fa-file-invoice-dollar" />
          </IconBox>
          <h3>{serviceProviderName} {title}</h3>
        </TitleBox>
        <p className='timeStamp'>
          {revisedOnDate.formattedDate} {dateTimeSeparator} {revisedOnDate.formattedTime}
        </p>
      </Title>
    </Card>
  )
}