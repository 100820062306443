import styled from "styled-components";
import { theme, fade } from "../../../../../../shared/style";
const Root = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;
const LogoWrapper = styled("div")`
  display: flex;
  align-items: center;
`;
const ImageWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right:8px
  height: 40px;
  width: 40px;
  img {
    height: 32px;
    width: 32px;
  }
`;
const Name = styled("p")`
  color: ${theme.IndiqoGrey["800"]};
  font-size: 16px;
`;
const BidNumber = styled("p")`
  color: ${fade(theme.IndiqoGrey["800"], 0.7)};
  font-size: 14px;
`;

export default { Root, LogoWrapper, ImageWrapper, Name, BidNumber };
