import styled from 'styled-components';

const zoomLevel = 5;

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 8px;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 448px;
  height: 560px;
  border-radius: 8px;
  position: relative;
  background-color: #000;
`;

const Img = styled.img`
  width: 420px;
  height: 560px;
  object-fit: cover;
  background-color: #000;
`;

const ImageZoom = styled.div.attrs(
  ({ image, imgWidth, imgHeight, x, y}) => ({
    style: {
      backgroundImage: "url('" + image + "')",
      backgroundSize: (imgWidth * zoomLevel) + "px " + (imgHeight * zoomLevel) + "px",
      backgroundPositionX: (-x * zoomLevel + 920 / 2) + "px",
      backgroundPositionY: (-y * zoomLevel + 560 / 2) + "px"
    }
  })
)`
  display: flex;
  pointer-events: none;
  width: 920px;
  height: 560px;
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: -945px;
  opacity: 1;
  background-color: #000;
  background-repeat: no-repeat;
`;

const ImageOptions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding-top: 16px;
  width: 100%;
  gap: 16px;
  /* position: absolute;
  top: 0;
  left: 0;
  transform: translate(0, 0); */
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
`;

export default {
  Panel,
  ImageContainer,
  Img,
  ImageZoom,
  ImageOptions,
  TextContainer
}