import { render } from "enzyme";
import React, {PureComponent} from "react"
import styledComponents from "./style";
import _ from "lodash";
import Wrapper from "../../components/Wrapper";
import Loader from "../../components/Loader";
import {TierUpdateSuccess} from './components/TierUpdateSuccess'
import { TierUpdateFail } from "../tierManage/components/TierUpdateFail";
import { InstallerStatus } from "../../components/InstallerStatus";
import formatter from "../../lib/formatter";
import BidStatusError from '../../components/Errors';
import queryString from "query-string";
import moment from "moment";



class TieredAcceptCustom extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {  
            isLoading: true,
            guId: '',
            fetchedProjectInfo: {},
            tierEditionSuccess: false,
            tierFechedSucces: false,
            translations: {},
        };
    }

    componentDidMount() {

        let bidGuid = this.getProjectGuid(this.props.location.search)
        if (!_.isEmpty(bidGuid)) {
            this.setState({guId: bidGuid})
            this.getBidInfo(bidGuid)
        } 
        if (_.has(window, 'env.translations') && !_.isEmpty(window.env.translations)) {
            this.setState({
                translations: window.env.translations
            })
        }

    }

    getProjectGuid = (url) => {
        return queryString.parse(url).token
    }

    getBidInfo = (guId) => {
        let optionalParams = {
            headers: {"content-type": "application/json; charset=UTF-8", "Subscription-Key": `${window.env.apiKey}`},
            method: "GET"
        }

        return new Promise((resolve, reject) => {

                      fetch(`${window.env.qmtUrl}/Bid/${guId}`, optionalParams)
                      .then(res => {
                        this.setState({tierFechedSucces: res.status})
                        return res.json()})
                      .then(data => {
                        //   console.log('bid info', data);
                          this.setState({fetchedProjectInfo: data, isLoading: false})
                      })
                      .catch(error => {
                          reject({
                              isLoading: false,
                              fetchedData: { ErrorCode: 500, ...error }
                          });
                      })
        })
    }


    postRecommend = (event) => {
        if (event.detail === 1) {
            this.setState({isLoading: true})
            let data = {
                ...this.state.fetchedProjectInfo,
            }
            let optionalParams = {
                headers: {"content-type": "application/json; charset=UTF-8", "Subscription-Key": `${window.env.apiKey}`},
                method: "POST"
            }
    
            new Promise((resolve, reject) => {
                fetch(`${window.env.qmtUrl}/Bid/${this.state.guId}/RecommendCustom`, optionalParams)
                .then(res => {
  console.log(res)
                    this.setState({tierEditionSuccess: res.status, isLoading: false})
                })
                .catch(error => {
    
                    reject({
                        isLoading: false,
                        fetchedData: { ErrorCode: 500, ...error }
                    });
                })
            })
        }

    }



    render() {

        const {
            Card,
            Header,
            PermitBidId,
            PermitAcceptP,
            PermitInfo,
            PermitDetails,
            Button,
        } = styledComponents;

        // console.log("fetched", this.state.fetchedProjectInfo);
        const dueDate = formatter.getDateTime(this.state.fetchedProjectInfo.DueDate) || '';
        const threeFromNow = formatter.getDateTime(moment.utc().add('days', 3));
       // console.log("from now", threeFromNow);

        
        
        if (!_.isEmpty(this.state.fetchedProjectInfo) && this.state.isLoading === false && this.state.tierEditionSuccess === false && this.state.fetchedProjectInfo.Status === 'New') {
            return (
                <Wrapper name="" company="">
                    <Card>
                        <Header>Custom quote required</Header>
                        <PermitBidId>Project #{`${this.state.fetchedProjectInfo.Project.Id}`}</PermitBidId>
                        <PermitAcceptP>By confirming a custom quote is required, you are accepting this request and agreeing to submit a quote in 2 business days.</PermitAcceptP>
                        <PermitDetails>
                            <p className="permitLabel">Quote Due</p>
                            <p className="permitValue">{`${threeFromNow.formattedDate}, 3:00pm ${threeFromNow.timeZone}`}</p>
                            <p className="permitLabel">Site</p>
                            <p className="permitValue">{this.state.fetchedProjectInfo.BidRequest.Location.Address}</p>
                        </PermitDetails>
                        <PermitInfo>
                            <p className="permitInfo">You&apos;ll gain access to the customer&apos;s contact information if an in-person site evaluation is needed to provide a custom quote</p>
                        </PermitInfo>
                        <PermitInfo>
                            <Button disabled={false} onClick={this.postRecommend}>confirm</Button>
                        </PermitInfo>
                    </Card>
                </Wrapper>
            );

        }

        if ((_.has(this.state, 'fetchedProjectInfo.Status') && this.state.fetchedProjectInfo.Status === 'Declined') || (_.has(this.state, 'fetchedProjectInfo.Status') && this.state.fetchedProjectInfo.Status === 'Completed')) {
            return <BidStatusError status={this.state.fetchedProjectInfo.Status} />
        }
    
        if (!this.state.isLoading && this.state.fetchedProjectInfo.Status === 'Cancelled' || this.state.fetchedProjectInfo.Status === 'Closed'  || this.state.fetchedProjectInfo.Status === 'InProgress') {
            return (<InstallerStatus translations={this.state.translations} installerStatus={(this.state.fetchedProjectInfo.Status === "Closed" || this.state.fetchedProjectInfo.Status === "InProgress") ? "Accepted" : this.state.fetchedProjectInfo.Status === "Cancelled" ? "Cancelled" : ''}/>)
        }

        if ((_.has(this.state, 'fetchedProjectInfo.Status') && this.state.fetchedProjectInfo.Status === 'Declined') || (_.has(this.state, 'fetchedProjectInfo.Status') && this.state.fetchedProjectInfo.Status === 'Completed') && this.state.fetchedProjectInfo.Status === 'Accepted') {
            return <BidStatusError status={this.state.fetchedProjectInfo.Status} />
        }


    if (this.state.tierEditionSuccess === 200) {
        return <TierUpdateSuccess/>
    }

    if (this.state.tierEditionSuccess === 400 ||this.state.tierEditionSuccess === 404  || this.state.tierEditionSuccess === 500) {
        return <TierUpdateFail translations={this.state.translations}/>
    }

     return (<Loader color={"#000000"} isLoading={this.state.isLoading} />)
    }
}

export default TieredAcceptCustom