import styled, { css } from "styled-components";

const Card = styled.div`
  background: rgb(255, 255, 255);
  border-radius: 16px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14),
  0px 4px 5px 0px rgba(0, 0, 0, 0.12),
  0px 1px 10px 0px rgba(0, 0, 0, 0.2);
  width: 600px;
  margin: auto;
  margin-top: 146px;
  padding: 24px;
  display: flex;
  flex-direction: column;
`;

const UATTitle = styled.p`
  color: rgb(62, 65, 72);
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  height: 20px;
  line-height: 20px;
  width: 552px;
  margin-bottom: 24px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const InputLabel = styled.p`
  width: 110px;
  height: 20px;
  color: rgb(62, 65, 72);
  font-size: 14px;
  font-family: Lato;
  font-weight: normal;
  letter-spacing: 0.25px;
  line-height: 20px;
`;

const UATInput = styled.input`
  background: rgb(250, 250, 250);
  border-radius: 4px;
  border: 1px solid rgb(222, 223, 226);
  height: 44px;
  width: 100%;
  margin: 0;

  ::placeholder {
    text-align: left;
    padding: 12px;
  }


  ${props => props.disabled && css`
  background: rgb(222, 223, 226);
  `}
`;

const UATButton = styled.button`
  background: linear-gradient(-180deg, rgb(0, 188, 212) 0%, rgb(0, 172, 193) 100%);
  border-radius: 100px;
  border: 1px solid rgb(0, 172, 193);
  height: 40px;
  width: 200px;
  color: rgb(255, 255, 255);
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 24px;
  cursor: pointer;
  align-self: center;
`;

export default {
  Card,
  UATTitle,
  InputContainer,
  InputLabel,
  UATInput,
  UATButton,
}