import styled from 'styled-components';

const CancelBoxWrapper = styled.div`

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 4px;

`;
export default {
  CancelBoxWrapper
}