import React from 'react';
import styledComponents from './styles';

const {
  SmallIconBox,
  IconBox
} = styledComponents;

export function ToolTip({ children }) {
  return (
    <SmallIconBox role="tooltip">
      <i className="far fa-info-circle" alt="information icon" aria-label="information icon" />
      <span className="tooltip">
        {children}
      </span>
    </SmallIconBox>
  )
}

export function SVGToolTip({svgIcon, children}) {
  return (
    <IconBox role="tooltip">
      {svgIcon}
      <span className="tooltip">
        {children}
      </span>
    </IconBox>
  )
}