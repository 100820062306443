import React, { Component } from 'react';
import { emailLink, phoneLink } from '../../lib/linkFunctions';
import QmeritLogo from '../Powered-by-Qmerit-Indiqo-Grey';
import FaceImg from '../../../../public/images/smile-beam.svg';
import { Wrapper, Title, Description } from '../../features/InstallUpdate/styles';
import StyledComponents from './styles';

const { Container } = StyledComponents;

class CompletedView extends Component {
    render() {
        const { company, phone, email } = this.props;

        return (
            <Wrapper absoluteLogo>
                <Container>
                    <img alt="happy-face" src={FaceImg} />
                    <Title>Thanks for checking in!</Title>
                    <Description>
                        If you need more assistance, contact {company ? company+' ' : "Qmerit Customer Service" +' '} 
                        by calling &nbsp;
                        {phoneLink(phone ? phone : '+18882720090')} 
                        &nbsp; or emailing &nbsp;
                        {emailLink(email ? email : 'customerservice@qmerit.com')}.
                    </Description>
                </Container>
                <QmeritLogo />
            </Wrapper>
        )
    }
}

export default CompletedView;
