import React, { Component } from "react";
import BidCard from "../BidCard";
import { Button } from "../../../../shared/style";
import QuickCard from "../../../../components/QuickCard";
import QmeritLogo from "../../../../components/Powered-by-Qmerit-Indiqo-Grey";
import { phoneLink, emailLink } from "../../../../lib/linkFunctions";
import FeatureStyles from "../../styles";
import _ from "lodash";

const {
  Wrapper,
  CardContainer,
  Title,
  Company,
  CancelRequest,
  ConfirmContainer
} = FeatureStyles;

class SelectBid extends Component {
  state = {
    selectedBid: null,
    bidReguestId: null,
  };

  handleSelect = (id, bidReguestId) => {
    this.setState({ selectedBid: id, bidReguestId });
  };

  handleCancelRequest = () => {
    const { operations } = this.props;

    this.props.history.push(
      `/cancel-request?token=${operations.CancelRequest}`
    );
  };

  handleCancelConfirm = () => {
    this.setState({ selectedBid: null });
  };

  render() {
    const { selectedBid, bidReguestId } = this.state;
    const { activities, onConfirm } = this.props;

    return (
      <Wrapper>
        <QuickCard maxWidth="600px" padding="0px">
          <CardContainer>
            <Title>
              <h4>
                {selectedBid
                  ? "Confirm your selection"
                  : "Select the winning company"}
              </h4>
              {selectedBid && (
                <p>
                  Once you’ve confirmed, we’ll relay the message to the other
                  bidding companies.
                </p>
              )}
            </Title>
            {!selectedBid &&
              activities.map((activity, i) => (
                <BidCard
                  key={i}
                  activityId={activity.Id}
                  activity={activity}
                  onSelect={this.handleSelect}
                />
              ))}
            {selectedBid && (
              <BidCard
                activity={activities.find(
                  activity => activity.Id === selectedBid
                )}
                onSelect={this.handleSelect}
              />
            )}
            {selectedBid && (
              <ConfirmContainer>
                <Button onClick={this.handleCancelConfirm}>Cancel</Button>
                <Button
                  action={true}
                  onClick={() => onConfirm(selectedBid, bidReguestId)}
                >
                  Confirm
                </Button>
              </ConfirmContainer>
            )}
            {activities[0].Owner && activities[0].Owner.Contact && (
              <Company>
                <p>
                  If you need more assistance, contact{" "}
                  {activities[0].Owner.DBAName}&nbsp; by calling{" "}
                  {phoneLink(activities[0].Owner.Contact.PhoneNumber)}&nbsp; or
                  emailing {emailLink(activities[0].Owner.Contact.Email)}.
                </p>
              </Company>
            )}
          </CardContainer>
          {!selectedBid && (
            <CancelRequest>
              <p>
                Had a change in plans? We understand. You can cancel your work
                request here.
              </p>
              <Button onClick={this.handleCancelRequest}>CANCEL REQUEST</Button>
            </CancelRequest>
          )}
        </QuickCard>
        <QmeritLogo />
      </Wrapper>
    );
  }
}

export default SelectBid;
