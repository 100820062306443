import styled from 'styled-components';
import {
  mediaMax,
  mediaMin
} from "../../../../shared/style"

const LaunchProjectContainer = styled.div`

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 16px;

  width: 100%;


  & p{
    text-align: center;
  }

`;

const LaunchProjectButton = styled.button`

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;

  width: 100%;
  height: 52px;

  background: #3963FE;
  border-radius: 4px;

  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;

  text-transform: uppercase;
  color: #FFFFFF;
  border: none;

  cursor: pointer;

  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  ${mediaMin.desktop`
    &:active {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  `};  

`;

const LaunchProjectSuccess = styled(LaunchProjectButton)`
  flex-direction: row;
  gap: 8px;
  background: #F1F8E9;
  color: #AED581;
  cursor: auto;

  &:hover {
    box-shadow: none;
  }

  ${mediaMin.desktop`
    &:active {
      box-shadow: none;
    }
  `}; 
`;

export default {
  LaunchProjectContainer,
  LaunchProjectButton,
  LaunchProjectSuccess
}