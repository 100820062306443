import styled from "styled-components";
import {
  mediaMax,
  mediaMin,
  theme,
  smallText,
  text,
  fade,
  mediumText,
  PaddinMarginBits,
  headerSizes
} from "../../shared/style";
const gap = 12;

const HeadTitle = styled.h2`
  margin: 0;
  color: #3e4148;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  ${mediaMax.tablet`max-width: 282px;`};
  /* ${mediaMin.tablet`margin-bottom: 24px;`}; */
`;

const ListContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div:nth-child(1) {
    width: calc(100% - 24px);
  }
  h2,
  p {
    margin: 0;
    line-height: 20px;
    font-weight: 400;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .name,
    .customer {
      flex: 0 0 calc(100% - 70.19px);
      max-width: calc(100% - 70.19px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .distance,
    .due {
      text-align: right;
      flex: 0 0 70.19px;
      max-width: 70.19px;
    }
  }
  h2 {
    color: #3e4148;
    font-size: 16px;
  }
  p {
    font-size: 14px;
    color: ${fade("#3e4148", 0.7)};
  }
`;
const List = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
  height: 64px;
  &:not(:last-child) {
    ${ListContent} {
      box-shadow: inset 0px -1px 0px 0px rgb(237, 238, 239);
    }
  }
  .icon-container {
    width: 40px;
    height: 40px;
    margin-right: 12px;
    position: relative;
    img,
    i,
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .acceptReject {
      width: 32px;
      height: 32px;
    }
    .circle-progress-wrapper {
      width: 35px;
      height: 35px;

      circle {
        stroke-width: 2.5px;
      }
    }
    i {
      font-size: 15px;
      &.fa-file-invoice-dollar {
        margin-top: 1.2px;
        margin-left: 0.5px;
      }
      &.fa-check {
        font-size: 16px;
        height: 32px;
        line-height: 32px;
        width: 32px;
        color: #fff;
        background-color: #8bc34a;
        border-radius: 100%;
      }
    }
  }
`;
const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  padding-top: ${({ pt }) => pt || 16}px;
  margin-bottom: 24px;
  ${mediaMax.tablet`margin-bottom: 16px;`};
`;

const AddItem = styled.button`
  border: 0;
  color: ${fade(theme.IndiqoGrey[800], 0.7)};
  background: transparent;
  padding: 0;
  font-family: Lato
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 16px;
  white-space: nowrap;
`;

const Arrow = styled.div`
  flex: 0 0 20px;
  max-width: 20px;
  text-align: center;
  i {
    transition: ${props =>
      props.active ? `0.25s transform` : `0.1s transform`};
    font-size: 16px;
    color: ${theme.IndiqoGrey[500]};
  }
`;

const ActivityHeader = styled("div")`
 ${mediaMin.tablet`display: none !important;`};
 position: fixed;
 top: 0;
 left: 0;
 width: 100%;
 /* backdrop-filter: blur(8px); */
 background-color: #ffffff;
 z-index: 999;
 padding: 12px;
 height: ${headerSizes.phone}px;
 display: flex;
 align-items: flex-end;
 /* ${mediaMax.phone`height: ${headerSizes.phone}px;`}; */
 .container {
   display: flex;
 }
 i {
   margin-top: 2px;
   color: #b9bbc1;
   font-size: 16px;
   height: 20px;
   line-height: 20px;
   text-align: center;
   width: 20px;
   margin-right: 8px;
 }
 h2 {
   margin: 0;
   color: #3e4148;
   font-size: 18px;
   font-weight: bold;
   line-height: 24px;
   font-family: Montserrat;
 }
 p {
   margin: 0;
   font-size: 14px;
   font-weight: 400;
   line-height: 20px;
   font-family: Lato;
   color: ${fade("#3e4148", 0.7)}
 }
`;

const BidsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1.5rem 0 0;
  button {
    width: 10rem;
  }
`;

const TextWrap = styled.div`
  flex: 0 0 calc(100% - 20px);
  max-width: calc(100% - 20px);
  ${mediaMax.phone`padding: 0 ${12 / 2}px;`};

  p.success,
  p.submitted {
    color: #8bc34a;
  }
  p.warning {
    color: #ff5722;
  }
`;

const SubTitle = styled.p`
  ${mediumText};
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Title = styled.h2`
  ${text};
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
`;

export default {
  HeadTitle,
  TextWrap,
  Title,
  List,
  ListContent,
  TitleWrap,
  Arrow,
  AddItem,
  ActivityHeader,
  BidsContainer,
  ButtonWrapper,
  SubTitle
};
