import React from "react";
import Message from "../../components/message";
import styled from "styled-components";
import NotFound from "../../../../public/images/notFound.svg";

const AccountNotFound = props => {
  return (
    <Message background={props.maps}>
      <Icon
        alt="Account not found."
        src={NotFound}
        style={{ maxHeight: "86px !important" }}
      />
      <h1>Account not found.</h1>
      <p>
        It seems this phone number is not associated with an account. To get set
        up, contact us at
      </p>
      <a href="mailto:sean.nagle@qmerit.com">sean.nagle@qmerit.com</a>
    </Message>
  );
};

export default AccountNotFound;

const Icon = styled.img`
  max-height: 86px !important;
  margin-bottom: 8px;
`;
