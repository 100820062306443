import styled from "styled-components";

const SelectDateWraper = styled.div`
  position: relative;
  margin-bottom: ${({ error }) => (error ? 0 : "15px")};
  cursor: pointer;
  i {
    color: #969ba5;
    font-size: 18.27px;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    transition: 0.3s all;
    &:hover {
      border-color: #6c7079;
    }
  }
  ${({ error }) =>
    error &&
    `
    i {
        color: #ff5722;
    }
  `}
`;
const SelectDateValue = styled.div`
  border-radius: 4px;
  font-size: 16px;
  font-family: Lato;
  font-weight: 400;
  line-height: 1.25;
  color: rgba(62, 65, 72, 0.35);
  text-align: left;
  border: solid 1px #dedfe2;
  background: #fafafa;
  height: 44px;
  margin: 0;
  width: 100%;
  padding: 0 30px 0 12px;
  display: flex;
  align-items: center;
  &.active {
    color: #3e4148;
  }
  ${({ error }) =>
    error &&
    `
    border-color: #ff5722;
    background: #fbe9e7;
  `}
`;

const SelectDateDots = styled.span`
  height: 6px;
  width: 6px;
  background-color: currentColor;
  border-radius: 100%;
  margin: 1px 7px 0 7px;
`;

const DateTimeError = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-style: italic;
  color: #ff5722;
  margin-top: 4px;
  i {
    margin-right: 4px;
    color: #ff5722;
  }
`;

export default {
  SelectDateDots,
  SelectDateValue,
  SelectDateWraper,
  DateTimeError
};
