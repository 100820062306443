import React from 'react';
import styledComponents from './styles';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';
import NewTabButton from '../../components/NewTabButton';

const {
  FeedbackContainer,
  Text
} = styledComponents;

const {
  P
} = commonStyles;

export default function Feedback({ externalRef }) {
  return (
    <FeedbackContainer>
      <Text>
        <h3>Help us improve!</h3>
        <P>Learn more and provide feedback.</P>
      </Text>
      <NewTabButton text={'continue'} url={`${window.env.typeformLoadUrl}?guid=${externalRef}`} />
    </FeedbackContainer>
  )
}