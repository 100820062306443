import styled, { css } from 'styled-components';
import {
  mediaMax,
  mediaMin
} from "../../../../shared/style"

const RebatesSection = styled.div`

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;

`;

const RebatesContainer = styled.div`

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 16px 0px;
  gap: 16px;

  width: 100%;
  /* height: 148px; */

  ${mediaMax.desktop`
    display: none;
  `};

`;

const Rebate = styled.div`

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 0;
  flex: 1;
  max-width: 323.67px;

`;

const RebateBox = styled.div`

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;

  width: 100%;
  /* height: 92px; */

  border: 1px solid #EDEEEF;

`;

const LearnMore = styled.div`

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;

  width: 103px;
  height: 20px;
  margin: 8px 0px 0px;

`;

const ProgramTypeTextWrapper = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0px;

  ${props =>
    props.tablet &&
    css`
      text-align: center;
    `};

  ${mediaMax.phone`
    text-align: left;
  `};
`;

export default {
  RebatesSection,
  RebatesContainer,
  Rebate,
  RebateBox,
  LearnMore,
  ProgramTypeTextWrapper
}