import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import StyledComponent from "./styles";
import { Button } from "../../../../shared/style";
import { BidInput } from "../../../../components/BidComponent";
import Switch from "../../../../components/Switch";
import Upload from "../../../../components/Upload";
import { apiConfig } from "../../../../lib/apiConfig";
import fetchData from "../../../../lib/fetchData";

const {
  ButtonWrapper,
  Close,
  Title,
  FieldTitle,
  Root,
  Textarea,
  InputWrapper,
  SwitchWrapper
} = StyledComponent;

class SubmitBid extends Component {
  state = {
    bidCost: undefined,
    showAttachments: false,
    message: undefined,
    showIntegerError: false,
    disableSubmit: true,
    uploadArray: []
  };

  setUploadArray = array => {
    this.setState({ uploadArray: array });
  };

  handleChange = (e, type) => {
    let bidCost = e.target.value;
    let showIntegerError = false;
    if (type === "total") {
      const test = /^[1-9]{1}\d{0,}\.{0,1}\d{0,2}$/.test(bidCost);
      showIntegerError = !test;
    }
    if (!showIntegerError) {
      this.setState({ bidCost, showIntegerError, disableSubmit: false });
    } else {
      this.setState({ bidCost, showIntegerError, disableSubmit: true });
    }
  };

  addAttachments = () => {
    this.setState({ showAttachments: !this.state.showAttachments });
  };

  submitBid = (QuoteDecimal, QuoteFiles, QuoteMessage) => {
    const { bidCost, message, uploadArray } = this.state;

    this.props.activity.Tasks.Actions.map((action, i) => {
      if (action.Type === "Currency") {
        QuoteDecimal = action.Id;
      } else if (action.Type === "Upload") {
        QuoteFiles = action.Id;
      } else if (action.Type === "Text") {
        QuoteMessage = action.Id;
      }
    });
    const options = {
      ...apiConfig.postConsumerInfo,
      query: "token=" + this.props.activity.Operations.Submit,
      body: {
        taskId: this.props.activity.Tasks.Id,
        actions: [
          {
            Id: QuoteDecimal,
            CustomDecimal1: bidCost
          },
          {
            Id: QuoteFiles,
            Files: uploadArray
          },
          {
            Id: QuoteMessage,
            CustomString1: message
          }
        ]
      }
    };
    fetchData(options).then(data => {
      if (data.fetchedData && !data.fetchedData.ErrorCode) {
        this.props.onActionClick({
          view: "accept",
          header: { subTitle: "submitted", status: "success" },
          quickBid: true
        });
      } else {
        this.setState({ fetchError: true });
      }
    });
  };

  handleSubmit = async () => {
    if (!this.state.showIntegerError && this.state.bidCost) {
      if (!this.context.isMockData) {
        let QuoteDecimal,
          QuoteFiles,
          QuoteMessage = null;

        this.props.activity.Tasks.Actions.map((action, i) => {
          if (action.Type === "Currency") {
            QuoteDecimal = action.Id;
          } else if (action.Type === "Upload") {
            QuoteFiles = action.Id;
          } else if (action.Type === "Text") {
            QuoteMessage = action.Id;
          }
        });
        await this.submitBid(QuoteDecimal, QuoteFiles, QuoteMessage);
      } else {
        this.props.onActionClick({
          view: "accept",
          header: { subTitle: "submitted", status: "success" },
          quickBid: true
        });
      }
    } else {
      this.setState({ showIntegerError: true });
    }
  };

  handleCancelClick = () => {
    this.props.onActionClick({
      view: "accept",
      header: { subTitle: "accepted", status: "success" }
    });
  };

  render() {
    const {
      bidCost,
      showAttachments,
      showIntegerError,
      previewArray,
      message,
      disableSubmit
    } = this.state;

    const { onActionClick, activity } = this.props;

    return (
      <Root>
        <Title>Submit Bid</Title>
        <InputWrapper>
          <BidInput
            handleChange={e => this.handleChange(e, "total")}
            value={bidCost}
            placeholder="0.00"
            showIntegerError={showIntegerError}
          />
        </InputWrapper>
        <SwitchWrapper>
          <Switch
            text="Include attachment"
            toggleSwitch={this.addAttachments}
          />
        </SwitchWrapper>
        {showAttachments && (
          <Upload
            noLable
            bgColor="#fafafa"
            previewArray={previewArray}
            setUploadArray={this.setUploadArray}
          />
        )}
        <FieldTitle mt={16}>
          Bid Message <span>- Optional</span>
        </FieldTitle>
        <Textarea
          req={true}
          onChange={e => this.setState({ message: e.target.value })}
          value={message}
          placeholder="Enter any additional information you'd like the customer to know…"
        />
        <ButtonWrapper sideDesktop>
          <div>
            <Button fWidth pFloating onClick={this.handleCancelClick}>
              CANCEL
            </Button>
          </div>
          <div>
            <Button
              className={disableSubmit ? "disabled" : ""}
              fWidth
              pFloating
              success={true}
              onClick={this.handleSubmit}
            >
              SUBMIT
            </Button>
          </div>
        </ButtonWrapper>
      </Root>
    );
  }
}

SubmitBid.contextTypes = {
  isMockData: PropTypes.boolean
};

export default SubmitBid;
