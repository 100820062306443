import React, { useState, useEffect } from 'react';
import styledComponents from './style';
import _ from 'lodash';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import Loader from '../../../../components/Loader';
import CheckoutForm from ".././../../../components/CheckoutForm/CheckoutForm"
import "./styles.css";

let stripePromise; 

const  { 
  SuperContainer,
  MainContainer,
  Container,
  MobileLogoContainer,
  MobileLogo,
  PreviewContainer,
  PreviewTitle,
  PreviewDescription,
  Table,
  TableHeader,
  TableRow,
  TableTotalRow,
  TableSubtotalRow,
  TableCurrencyRow,
  LogoContainer,
  Logo,
  PaymentContainer} = styledComponents

function Deposit(props) {

    const formatNumber = (value) => {
    let formattedNumber = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return formattedNumber.format(value);
    }

    const [clientSecret, setClientSecret] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [translations, setTranslations] = useState({})
    const [token, setToken] = useState(props.token || '');

    useEffect(() => {
        stripePromise = loadStripe(window.env.pKey)

        if (_.has(window, 'env.translations') && !_.isEmpty(window.env.translations)) {
          setTranslations(window.env.translations)
        };

        fetch("/create-payment-intent-deposit", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ 
            amount: props.remainingBalance, 
            requestId: token,
            currency: props.currency.toLowerCase(),
            }),
        })
            .then((res) => res.json())
            .then((data) => {
              setClientSecret(data.clientSecret);
              setIsLoading(false);
            });
    }, []);

    const appearance = {
        theme: 'stripe',
        variables: {
          fontFamily: 'Lato-Regular, sans-serif',
          fontSizeBase: '14px',
          colorText: "rgb(62, 65, 72)",
          colorTextPlaceholder: 'rgba(62, 65, 72, 0.35)',
          spacingGridRow: "16px",
          borderRadius: "4px"
        }
      };
    
      const options = {
        clientSecret,
        appearance,
      };
    
      if(props.remainingBalance && translations.stripePayment){
        return (
          <SuperContainer>
            <MainContainer>
            <Container>
              <MobileLogoContainer>
                <MobileLogo id='mobile-logo' alt="Qmerit" src={`${window.env.blobUrl}/qmerit/wordmark/svg/Qmerit-Wordmark-Indiqo.svg`} /> 
              </MobileLogoContainer>
              <PreviewContainer>
                <PreviewTitle>{translations.stripePayment.previewTitle}</PreviewTitle>
                <PreviewDescription>{translations.stripePayment.previewDescription}</PreviewDescription>
                <Table>
                  <thead>
                    <TableHeader>
                      <th className="first-column">{translations.stripePayment.headerFirstColumn}</th>
                      <th className="second-column">{translations.stripePayment.headerSecondColumn}</th>
                      <th className="third-column">{translations.stripePayment.headerThirdColumn}</th>
                    </TableHeader>
                  </thead>
                  <tbody>
                    {props.remainingBalance && (
                      <TableRow>
                        <td className="first-column">{translations.stripePayment.deposit}</td>
                        <td className="second-column">1</td>
                        <td className="third-column">{formatNumber(props.remainingBalance)}</td>
                      </TableRow>
                    )}
                    <TableSubtotalRow>
                      <td ></td>
                      <td className="label-field">{translations.stripePayment.subtotal}</td>
                      <td className="subtotal-field">{formatNumber(props.remainingBalance)}</td>
                    </TableSubtotalRow>
                    <TableTotalRow>
                      <td ></td>
                      <td className="label-field">{translations.stripePayment.total}</td>
                      <td className="total-field">{formatNumber(props.remainingBalance)}</td>
                    </TableTotalRow>
                    <TableCurrencyRow>
                      <td ></td>
                      <td ></td>
                      <td className="currency-field">{props.currency}</td>
                    </TableCurrencyRow>
                  </tbody>
                </Table>
              </PreviewContainer>
              <PaymentContainer>
                {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                  <CheckoutForm formTitle={translations.stripePayment.formTitle} emailLabel={translations.stripePayment.emailLabel} emailPlaceholder={translations.stripePayment.emailPlaceholder} buttonLabel={translations.stripePayment.buttonLabel}/>
                </Elements>
                )}
              </PaymentContainer>
            </Container>
            <LogoContainer>
              <Logo alt="Qmerit" src={`${window.env.blobUrl}/qmerit/wordmark/png/Qmerit-Wordmark-IG400.png`} />
            </LogoContainer>
            </MainContainer>
          </SuperContainer>
          
        )
    }else{
      return (<Loader color={"#000000"} isLoading={isLoading} />) 
    }
}
export default Deposit;

