import React from 'react';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';
import styledComponents from './styles';
import OptionButton from '../../components/OptionButton';

const {

} = commonStyles;

const {
  OptionsContainer
} = styledComponents;

export default function OptionsSection({ currentIdx, setCurrentIdx, partnerName }) {
  const BUTTONS = [
    {id: 0, primaryText:'Request financing', secondaryText: `A request will be sent to ${partnerName} for review and funding approval.`},
    {id: 1, primaryText: 'Continue without financing', secondaryText: 'A final invoice will be sent to you upon completion of your project.'}
  ]

  const onClick = index => {
    setCurrentIdx(currentIdx => currentIdx !== index ? index : -1);
  }

  return (
    <OptionsContainer>
      {BUTTONS.map((button, index) => (
        <OptionButton key={button.id} isActive={index === currentIdx} onClickFxn={() => onClick(index)} primaryText={button.primaryText} secondaryText={button.secondaryText} />
        ))
      }
    </OptionsContainer>
  )
}