import React from "react";

export default (() => {
  return (
    <svg
      enable-background="new 0 0 140 150"
      viewBox="0 0 140 150"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <linearGradient id="a">
        <stop offset="0" stop-color="#2b4dac" />
        <stop offset=".35" stop-color="#3862c5" />
        <stop offset="1" stop-color="#00bcd4" />
      </linearGradient>
      <linearGradient
        id="b"
        gradientTransform="matrix(1 0 0 -1 0 200)"
        gradientUnits="userSpaceOnUse"
        x1="69.15"
        x2="69.15"
        xlinkHref="#a"
        y1="49.8948"
        y2="200.0583"
      />
      <linearGradient
        id="c"
        gradientTransform="matrix(1 0 0 -1 0 200)"
        gradientUnits="userSpaceOnUse"
        x1="70"
        x2="70"
        xlinkHref="#a"
        y1="50.1885"
        y2="200.3385"
      />
      <path
        d="m69.8 40.3c-19.8-.4-36 15.8-35.6 35.6.3 18.7 15.6 34 34.3 34.3 19.8.4 36-15.8 35.6-35.6-.3-18.7-15.6-34-34.3-34.3z"
        fill="url(#b)"
      />
      <path
        d="m119.9 22.8-29.8-17.3c-12.5-7.3-27.9-7.3-40.4 0l-29.6 17.3c-12.5 7.2-20.1 20.5-20.1 34.9v34.9c0 14.4 7.6 27.6 20.1 34.8l29.8 17.3c12.5 7.3 27.9 7.3 40.4 0l11.4-6.6c.6-.3.5-1.2-.1-1.5-7.4-3.5-13.2-9.2-17.1-14.7-.3-.4-.8-.6-1.4-.5-4.5 1.3-9.3 1.9-14.3 1.8-26.3-.6-47.4-22.7-46.9-49s22.1-47.5 48.6-47.3c26 .2 47.8 22.3 47.7 48.4-.1 16.5-8.4 31.1-21.2 39.7-.4.3-.5.8-.2 1.2 3.8 4.7 9.4 8.9 15.8 10.1 3 .5 5.9.4 8.7-.5 1.6-.5 3-1.3 4.3-2.4 9-7.6 14.4-18.8 14.4-30.9v-34.8c0-14.4-7.6-27.7-20.1-34.9z"
        fill="url(#c)"
      />
    </svg>
  );
})();
