import React from 'react';
import StyledComponents from './styles';

const {
  Card,
  TitleBox,
  IconBox,
  Details
} = StyledComponents;

export default function ReviewingQuoteCard({ assigneeName, translations, show }) {
  if(!show) return null;
  return (
    <Card>
      <TitleBox>
        <IconBox>
          <i className="far fa-search-dollar" />
        </IconBox>
        <h2>{translations.title}</h2>
      </TitleBox>
      <Details>
        <p>
          {translations.descriptionOne} {assigneeName} {translations.descriptionTwo}
        </p>
      </Details>
    </Card>
  )
}