import React from "react";
import Calendar from "../calendar";
import TimeRangeComponent from "../timeRangeComponent";
import moment from "moment";
import timeZones from "./timeZones";
import styledComponents from "./style";
import _ from "lodash";

class DateTimeWraper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropDown: false,
      activeTimeZone: props.timeZone || "HST"
    };
  }

  toggle = e => {
    e.preventDefault();
    const { dropDown } = this.state;
    this.setState({ dropDown: !dropDown });
  };

  selectedTimeZone = data => {
    this.setState({
      activeTimeZone: data.abbr,
      dropDown: false
    });
  };

  setDateTimeField = () => {
    const props = this.props;
    let month = props.selectedDate.month.slice(0, 3).toUpperCase();
    let setDateTimeField = {
      date:
        this.props.show === "time"
          ? ""
          : `${props.selectedDate.year}-${month}-${props.selectedDate.date}`,
      time:
        this.props.show === "date"
          ? ""
          : `${props.rangeHourValue}:${props.rangeMinuteValue}${props.timePeriod}`,
      timeZone: `${this.state.activeTimeZone}`,
      originDate: {
        year: props.selectedDate.year,
        date: props.selectedDate.date,
        month: props.setDateTime.month(),
        utc: props.setDateTime.toISOString()
      }
    };

    this.props.closeDateTime(setDateTimeField);
  };

  footerDateTime = () => {
    const props = this.props;
    const state = this.state;
    if (props.show === "time") {
      return `${props.rangeHourValue}:${props.rangeMinuteValue}
      ${props.timePeriod} ${state.activeTimeZone}`;
    } else if (props.show === "date") {
      return `${props.selectedDate.day}, ${props.selectedDate.month}
      ${props.selectedDate.date}, ${props.selectedDate.year}`;
    } else if (props.show === "dateTime") {
      return `${props.selectedDate.day}, ${props.selectedDate.month}
      ${props.selectedDate.date}, ${props.selectedDate.year},${" "}
      ${props.rangeHourValue}:${props.rangeMinuteValue}
      ${props.timePeriod} ${state.activeTimeZone}`;
    }
  };

  header = () => {
    const { Header } = styledComponents;
    const props = this.props;
    const { show } = props;
    return (
      <Header>
        {show === "time" && <i className="fal fa-arrow-left" />}
        {(show === "date" || show === "dateTime") && (
          <React.Fragment>
            <i
              className="fal fa-angle-left"
              name="arrowLeft"
              onClick={() => props.changeMonth("arrowLeft")}
            />
            <i
              className="fal fa-angle-right"
              name="arrowRight"
              onClick={() => props.changeMonth("arrowRight")}
            />
          </React.Fragment>
        )}
        {show === "time"
          ? "Time"
          : `${props.selectedDate.month} ${props.selectedDate.year}`}
      </Header>
    );
  };

  dropDownItems = () => {
    const { DropDownItem } = styledComponents;
    return (
      <div>
        {_.map(timeZones, (data, index) => {
          return (
            <DropDownItem
              key={index}
              active={data.abbr === this.state.activeTimeZone}
              onClick={() => this.selectedTimeZone(data)}
            >
              <h3>{data.name}</h3>
              <p>{data.city}</p>
            </DropDownItem>
          );
        })}
      </div>
    );
  };

  render() {
    const {
      DropDownBtn,
      Meridiem,
      Wraper,
      Body,
      CalendarWraper,
      TimeSection,
      TimeWraper,
      Time,
      TimeZoneWraper,
      DropDownWraper,
      HoursRange,
      Footer
    } = styledComponents;
    const { dropDown } = this.state;
    return (
      <Wraper>
        {this.header()}
        <Body>
          {(this.props.show === "date" || this.props.show === "dateTime") && (
            <CalendarWraper single={this.props.show !== "dateTime"}>
              <Calendar
                moment={this.props.value || moment()}
                onChange={this.props.dateSelection}
              />
            </CalendarWraper>
          )}

          {(this.props.show === "time" || this.props.show === "dateTime") && (
            <TimeSection single={this.props.show !== "dateTime"}>
              <TimeWraper>
                <Time>
                  <input
                    type="text"
                    onChange={this.props.handleHoursChange}
                    value={this.props.rangeHourValue}
                    onBlur={this.props.onBlurHoursInput}
                  />
                  <span>:</span>
                  <input
                    onChange={this.props.handleMinutesChange}
                    type="text"
                    value={this.props.rangeMinuteValue}
                  />
                </Time>
                <Meridiem>
                  <button
                    className={this.props.timePeriod === "am" ? "active" : ""}
                    id="am"
                    onClick={this.props.handleTimePeriodChange}
                  >
                    am
                  </button>
                  <button
                    className={this.props.timePeriod === "pm" ? "active" : ""}
                    id="pm"
                    onClick={this.props.handleTimePeriodChange}
                  >
                    pm
                  </button>
                </Meridiem>
                <TimeZoneWraper>
                  <DropDownBtn onClick={e => this.toggle(e)}>
                    {this.state.activeTimeZone}{" "}
                    <i className="fas fa-caret-down" />
                  </DropDownBtn>
                  <DropDownWraper active={dropDown}>
                    {this.dropDownItems()}
                  </DropDownWraper>
                </TimeZoneWraper>
              </TimeWraper>

              <HoursRange>
                <p>Hours</p>
                <TimeRangeComponent
                  onInput={this.props.onInputHours}
                  value={this.props.rangeCurrentHourValue}
                  max={12}
                  step={0.1}
                  min={1}
                />
              </HoursRange>

              <HoursRange>
                <p>Minutes</p>
                <TimeRangeComponent
                  onInput={this.props.onInputMinutes}
                  value={this.props.rangeCurrentMinuteValue}
                  max={59}
                  step={0.5}
                  min={0}
                />
              </HoursRange>
            </TimeSection>
          )}
        </Body>
        <Footer>
          <p>{this.footerDateTime()}</p>
          <i onClick={() => this.setDateTimeField()}>Done</i>
        </Footer>
      </Wraper>
    );
  }
}
export default DateTimeWraper;
