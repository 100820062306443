import styled from 'styled-components';

const Root = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh;
`;

const Title = styled.h2`
    margin: 16px 0;
    font-size: 18px;
    font-weight: bold;
    font-family: Montserrat;
    color: #3e4148;
`;

const Description = styled.p`
    margin: 0;
    font-size: 16px;
    color: #3e4148;
    max-width: 360px;
    text-align: center;
`

export default {
    Root,
    Title,
    Description
}
