import { useState, useEffect } from 'react';
import { getProduct, getActualLoad } from '../helper';
import { postData, updateValuesAndRecs, putData, deleteData } from '../services';
import useDebounce from './useDebounce';

export function useEditRow(arr, id, setCurrentRec, guid) {
  const [rowsData, setRowsData] = useState(arr ? arr : []);
  const [inputValue, setInputValue] = useDebounce('¿', 1000);
  const [inputName, setInputName] = useState(null);
  const [itemId, setItemId] = useState(-1);
  const [value1, setValue1] = useState(-1);
  const [value2, setValue2] = useState(-1);
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');

  useEffect(() => {
    if (inputValue !== null && inputValue !== '¿' && itemId > -1) {
      if (value1 === -1 || value2 === -1) {
        updateValuesAndRecs(id, itemId, inputName, description, inputValue, setCurrentRec, guid, value1, value2);
      } else if (category === 'Appliance') { // Change appliance name
        putData(id, itemId, inputValue, description, value1, value2);
        // console.log(`=> change Appliance name putData(${id}, ${itemId}, ${inputValue}, ${description}, ${value1}, ${value2});`);
      } else if (category === 'HeavyLoad') { 
        if (inputName === 'name') { // Change breaker id (name)
          putData(id, itemId, inputValue, description, value1, value2);
          // console.log(`=> change HeavyLoad breaker id putData(${id}, ${itemId}, ${inputValue}, ${description}, ${value1}, ${value2});`);
        } else { // Change description
          putData(id, itemId, inputName, inputValue, value1, value2);
          // console.log(`=> change HeavyLoad desc putData(${id}, ${itemId}, ${inputName}, ${inputValue}, ${value1}, ${value2});`);
        }
      }
      setInputValue(null);
    }
  }, [inputValue, itemId])

  const addRow = async (categoryStr) => {
    let newRow = {};
    if (categoryStr === 'HeavyLoad') {
      const rowId = await postData(id, categoryStr);

      newRow = {
        id: rowId,
        category: categoryStr,
        name: '',
        description: '',
        type: '',
        value1: 0,
        value2: 0,
        uom1: 'amps',
        uom2: 'volts',
        newRow: true,
        total: 0
      }
    } else if (categoryStr === 'Appliance') {
      const rowId = await postData(id, categoryStr); 

      newRow = {
        id: rowId,
        category: categoryStr,
        name: '',
        description: '',
        type: '',
        value1: 0,
        value2: 0,
        uom1: 'qty',
        uom2: 'watts',
        newRow: true,
        total: 0
      } 
    }

    setRowsData([...rowsData, newRow]);
  }

  const deleteRow = index => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);

    deleteData(id, rowsData[index]['id']);
  }

  const handleChange = (event, index) => {
    const { name, value } = event.target;
    const rowsInput = [...rowsData];

    rowsInput[index][name] = value;
    setRowsData(rowsInput); // set local array for UI

    setInputValue(value); // value is being debounced for PUT request
    setCategory(rowsInput[index].category); // set category to determine proper PUT request to use in useEffect 
    setItemId(rowsInput[index].id); // set itemId to use in fetch url
    
    // TODO: consolidate duplicate code
    if (rowsInput[index].category === 'HeavyLoad') {
      if (name === 'description') { 
        setInputName(rowsInput[index].name);
        setDescription(rowsInput[index].description);
        setValue1(rowsInput[index].value1);
        setValue2(rowsInput[index].value2);
      } else if (name === 'name') { 
        setInputName(name); 
        setDescription(rowsInput[index].description);
        setValue1(rowsInput[index].value1);
        setValue2(rowsInput[index].value2);
      } else if (name === 'value1') {
        setInputName(rowsInput[index].name);
        setDescription(rowsInput[index].description);
        setValue1(-1);
        setValue2(rowsInput[index].value2);
        rowsInput[index].total = getActualLoad(value, rowsInput[index].value2);
      } else {
        setInputName(rowsInput[index].name);
        setDescription(rowsInput[index].description);
        setValue1(rowsInput[index].value1);
        setValue2(-1);
        rowsInput[index].total = getActualLoad(rowsInput[index].value1, value);
      }
    } else if (rowsInput[index].category === 'Appliance' || rowsInput[index].category === 'General') {
      if (name === 'name') {
        setInputName(name);
        setDescription(rowsInput[index].description);
        setValue1(rowsInput[index].value1);
        setValue2(rowsInput[index].value2);
      } else if (name === 'value1') {
        setInputName(rowsInput[index].name);
        setDescription(rowsInput[index].description);
        setValue1(-1);
        setValue2(rowsInput[index].value2);
        rowsInput[index].total = getProduct(value, rowsInput[index].value2);
      } else {
        setInputName(rowsInput[index].name);
        setDescription(rowsInput[index].description);
        setValue1(rowsInput[index].value1);
        setValue2(-1);
        rowsInput[index].total = getProduct(rowsInput[index].value1, value);
      }
    } 
  }

  return {
    rowsData,
    addRow,
    deleteRow,
    handleChange
  }
}