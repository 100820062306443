import React from 'react'
import styledComponents from './styles';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';

import CancelBox from '../../components/CancelBox';

const {
  Label_Field
} = commonStyles;

const {
  MobileCancelSection
} = styledComponents;

export default function MobileCancel({requestId, translations}) {

  const message = translations.mobileCancelSection.message;
  let splittedMessage = message.split('\n').map((i, index) => {
    return <Label_Field key={index + Math.random().toString(36).substring(2, 9)}>{i}</Label_Field>
  });

  return (
    <MobileCancelSection>
      <div>{splittedMessage}</div>
      <CancelBox requestId={requestId} translations={translations}/>
    </MobileCancelSection>
  )
}
