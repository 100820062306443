import React from "react";
import styledComponents from "./styles";
import logo from "../../../../../../../../public/images/Qmerit-logo.svg";
const { Root, LogoWrapper, ImageWrapper, Name, BidNumber } = styledComponents;

/** Renders Owners's info (logo and name) also render activity id */
export default ({ activity }) => (
  <Root>
    <LogoWrapper>
      <ImageWrapper>
        <img src={activity.Owner.ImageUrl} alt="" />
      </ImageWrapper>
      <Name>{activity.Owner.DBAName}</Name>
    </LogoWrapper>
    <BidNumber>Bid #{activity.BidRequest.Id}</BidNumber>
  </Root>
);
