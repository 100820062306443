import React from 'react';
import StyledComponents from './styles';

const {
  Card,
  Title,
  TitleBox,
  IconBox
} = StyledComponents;

export default function QuoteDeclinedCard({ declinedOnDate, serviceProviderName, translations, show }) {
  // translations = projectStatusTracker.statusScreen.completedStatus.quoteDeclined
  const {
    title,
    dateTimeSeparator
  } = translations;

  if(!show) return null;
  return (
    <Card>
      <Title>
        <TitleBox>
          <IconBox>
            <i className="far fa-user-times" />
          </IconBox>
          <h3>{serviceProviderName}{title}</h3>
        </TitleBox>
        <p className='timeStamp'>
          {declinedOnDate.formattedDate} {dateTimeSeparator} {declinedOnDate.formattedTime}
        </p>
      </Title>
    </Card>
  )
}