export default [
  {
    User: {
      CompanyName: "AshbyKTech",
      Name: "adam ashbyqakteck"
    },
    Type: "Decline",
    Contact: {
      DBAName: "Bill Murray Incorp",
      Email: "ashbyqaktech+adam@gmail.com",
      Phone: "+19494364242"
    },
    Activities: [
      {
        MasterActivityId: 22001,
        ParentActivityId: null,
        DerivedActivityId: null,
        GeneratedActivityId: null,
        Name: "Site Readiness Review",
        Program: {
          Id: 4430,
          Name: "QMerit Default Program",
          DBAName: "AshbyKTech",
          ExternalData: null
        },
        OwnerProgram: {
          Id: 4430,
          Name: "QMerit Default Program",
          DBAName: "AshbyKTech",
          ExternalData: null
        },
        ActivityTemplateId: 1491,
        Priority: {
          Id: 5,
          Description: "Medium"
        },
        Status: {
          Id: "New",
          Name: "New"
        },
        StartBy: null,
        StartNoLaterThan: null,
        Assignment: {
          Id: 12551,
          Name: "adam ashbyqakteck",
          Type: "Employee",
          ImageUrl: null,
          Contact: {
            Id: 12551,
            FirstName: "adam",
            LastName: "ashbyqakteck",
            Email: "ashbyqaktech+adam@gmail.com",
            Phone: "+19494364242",
            PreferredMethod: null,
            Title: null,
            Address: "",
            ImageUrl: null
          }
        },
        Description:
          "Evaluate the installation site and collect enough information to create an acurate proposal.",
        Location: {
          Id: 3066,
          Name: "WHS",
          Description: null,
          Address: "2 Meadowbrook",
          City: "Irvine",
          State: "California",
          Zip: "92604",
          Country: "United States",
          Latitude: 33.6783988,
          Longitude: -117.8064609,
          Contact: {
            Id: null,
            FirstName: null,
            LastName: null,
            Email: null,
            PhoneNumber: null,
            PreferredMethod: null,
            Title: null,
            Address: null,
            ImageUrl: null
          }
        },
        Customer: {
          Id: 3256,
          Name: "Woodbridge High",
          Type: "Company",
          Contact: {
            Id: null,
            FirstName: "woodbridge",
            LastName: "irvine",
            Email: "woodbridge@na.com",
            PhoneNumber: null,
            PreferredMethod: null,
            Title: null,
            Address: null,
            ImageUrl: null
          }
        },
        DueDate: "2019-03-28T20:25:28.7357168",
        Tasks: {
          Id: "b",
          Name: "Perform site readiness review",
          Status: "New",
          CanComplete: true,
          Events: [],
          Actions: [
            {
              CustomInt1: 59,
              CustomString1:
                "https://survey2.qmerit.com/newsurvey/3?guid=22001-b-1b",
              CustomString2: "",
              Files: [],
              MultiDocument: null,
              ProjectDocument: null,
              Id: "1b",
              Label:
                "The site survey will assist you with gathering all the necessary information for creating an accurate proposal for the customer. You will be able to add as many charging stations, electrical runs, and power sources necessary for the customer's unique installation needs.",
              Type: "Survey",
              IsCompleted: false
            }
          ],
          Tasks: [],
          Next: null
        },
        Events: [],
        Owner: {
          Id: 8284,
          DBAName: "AshbyKTech",
          ImageUrl: null,
          Contact: {
            Id: null,
            FirstName: "Deepak",
            LastName: "AshbyKT",
            Email: "ashbyqaktech@gmail.com",
            PhoneNumber: "(949) 232-3333",
            PreferredMethod: null,
            Title: "President",
            Address: null,
            ImageUrl: null
          }
        },
        CompletionPercentage: 0,
        Type: "Bid",
        Project: {
          Id: 12460,
          Name: "Woodbridge High EV Charging Stations",
          DueDate: null,
          Status: "New",
          ModifiedOn: null,
          Attachments: []
        },
        BidRequest: {
          Id: 12461,
          Name: "Woodbridge High EV Charging Stations Bid",
          DueDate: "2019-06-14T22:18:39.5616559",
          Status: "New",
          ModifiedOn: null,
          Attachments: []
        },
        Collaborators: [],
        SelectedOn: null,
        CreatedOn: "2019-03-14T18:02:12.193",
        IsFrozen: false,
        AcceptedBy: null,
        Id: 22001
      }
    ],
    OperationGuids: {}
  }
];
