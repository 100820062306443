import React, { Component } from "react";
import StyledComponent from "../../style";
import { DetailedItem } from "../components";
export default class ListActivities extends Component {
  render() {
    const {
        locationDiffArray,
        fetchedData,
        dataLength,
        openInfoPage
      } = this.props,
      { TotalItems, ListActivitiesWrapper } = StyledComponent;
    return (
      <ListActivitiesWrapper>
        <TotalItems>
          {dataLength} {dataLength === 1 ? "Item" : "Items"}
        </TotalItems>
        {dataLength !== 0 && (
          <DetailedItem
            fetchedData={fetchedData}
            locationDiffArray={locationDiffArray}
            openInfoPage={openInfoPage}
          />
        )}
      </ListActivitiesWrapper>
    );
  }
}
