
import React from "react"
import styledComponents from "./style";
import acceptedConfirmation from '../../../../../../public/images/acceptedConfirmation.svg'

const  {Container, ThanksForAccepting, StatusMessage} = styledComponents
 export const AcceptedConfirmation = () => {
    return (
        <Container>
            <img style={{height: 160, width: 190}} alt="Oops" src={acceptedConfirmation} />
            <ThanksForAccepting>Thanks for accepting this request!</ThanksForAccepting>
            <StatusMessage>We’ll relay this information to the customer. Feel free to close this window and you’ll be notified once the customer approves.</StatusMessage>
        </Container>

    )
}