import React, { useState, forwardRef, useEffect } from 'react';
import styledComponents from './styles';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';
import TotalRow from '../../components/TotalRow';
import useDebounce from '../../hooks/useDebounce';
import { updateACValueAndRecs } from '../../services';

const {
  P_MicroBold
} = commonStyles;

const {
  ACContainer,
  SectionHeaderSC,
  MobileSectionHeaderSC,
  InputField,
  InputWrapperSC,
  TotalWrapperSC
} = styledComponents;

const ACLoad = forwardRef(({ ac, id, acValue1, setACValue1, setCurrentRec, guid }, ref) => {
  const [inputValue, setInputValue] = useDebounce(acValue1 ? acValue1 : ac.value1, 1000);
  const [inputName, setInputName] = useState('');

  useEffect(() => {
    if (inputValue !== ac.value1 && inputValue !== null) { // updates from inputValue
      updateACValueAndRecs(id, ac.id, inputName, '', inputValue, 0, setCurrentRec, guid);
      ac.value1 = inputValue;
      setACValue1(inputValue);
    } else if (acValue1 !== null && inputValue !== acValue1) {// checking for updates from HL
      setInputValue(acValue1);
    }
  }, [inputValue, acValue1])

  const handleACInput = event => {
    if (event.target.value.trim().length === 0) {
      setInputValue(0);
      setInputName(event.target.name);
      setACValue1(0);
    } else {
      setInputValue(event.target.value);
      setInputName(event.target.name);
      setACValue1(parseFloat(event.target.value));
    }
  }

  return (
    <ACContainer>
      <SectionHeaderSC>A/C Load</SectionHeaderSC>
      <MobileSectionHeaderSC>Air Conditioner </MobileSectionHeaderSC>
      <div className="inputSection">
        <div className="inputLabel">
          <P_MicroBold>A/C Load</P_MicroBold>
        </div>
        <InputWrapperSC>
          <InputField key={inputValue} ref={ref}>
            <input 
              type="number"
              name="AC"
              defaultValue={inputValue}
              onChange={handleACInput}
              aria-label={inputValue + '  watts'}
              title={inputValue + '  watts'}
            />
            <label>watts</label>
          </InputField>
        </InputWrapperSC>
        <TotalWrapperSC>
          <TotalRow label={'Subtotal'} total={inputValue} uom={'watts'} />
        </TotalWrapperSC>
      </div>
    </ACContainer>
  )
})

export default ACLoad;