
import React from "react"
import styledComponents from "./style";
//import smileBeam from '../../../../../../public/images/smileBeam.svg'
import thumpUp from '../../../../../../public/images/thumpUp.png'
import { checkPropTypes } from "prop-types";

const  {Container, InstallerConfirmedTitle ,InstallerConfirmedMessage, Footer} = styledComponents
 export const InstallerConfirmed = ({companyName, translations}) => {
    return (
        <Container>
            <img alt="Oops" src={thumpUp}  />
            <InstallerConfirmedTitle>{translations.installerConfirmed.title}</InstallerConfirmedTitle>
            <InstallerConfirmedMessage>
            {translations.installerConfirmed.descriptionOne}{companyName} {translations.installerConfirmed.descriptionTwo} {translations.installerConfirmed.descriptionThree} {translations.installerConfirmed.descriptionFour} </InstallerConfirmedMessage>
            <Footer src={`${window.env.blobUrl}/qmerit/wordmark/png/Qmerit-Wordmark-IG400.png`}/>
        </Container>
    )
}