import styled, { createGlobalStyle, css } from "styled-components"
import { mediaMax } from "../../shared/style";

const CalloutWrapper = styled.div`
    display: flex;
    width: 100%;
    height: calc(100vh - 64px - 49px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;

    ${mediaMax.tablet`
        padding: 24px 16px;
        justify-content: start;
        height: calc(100vh - 49px - 81px);
    `}; 

    @media (max-height: 745px) {
        overflow-y: scroll;
    }

`;

const Callout = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
`;

const Message = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const MessageTitle = styled.h2`
    color: var(--Text-Primary, #000);
    text-align: center;
    font-family: "Red Hat Display";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
    margin: 0px;
`;

const Icon = styled.i`
    display: flex;
    width: 64px;
    height: 64px;
    justify-content: center;
    align-items: center;
    color: var(--Icon-Primary, #2F55FE);
    text-align: center;

    /* Icon/Large ++/Light */
    font-family: "Font Awesome 6 Pro";
    font-size: 54px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%; /* 54px */
`;

const DepositSpan = styled.span`
    font-family: inherit;
    color: var(--Text-Link, #2F55FE);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
`;

const WhatsNext = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
`;

const Card = styled.div`
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 8px;
    background: var(--Background-Page-Alt, #F6F7F8); 
    width: 600px;
    max-width: 600px;  

    ${mediaMax.tablet`
        width: 100%;
    `};
`;

const CardIconWrapper = styled. div`
    display: flex;
    width: 40px;
    height: 40px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
`;

const CardIcon = styled.i`
    color: var(--Icon-Primary, #2F55FE);
    text-align: center;
    font-family: "Font Awesome 6 Pro";
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: 40px; /* 125% */
`;

const CardTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
`;


export default {
    CalloutWrapper,
    Callout,
    Message,
    MessageTitle,
    Icon,
    DepositSpan,
    WhatsNext,
    Card,
    CardIconWrapper,
    CardIcon,
    CardTextWrapper
}