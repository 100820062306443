import React from "react";
import _ from 'lodash';
import Message from "../../../components/message";
import AlreadyImage from '../../../../../public/images/Already-Declined.png';

const BidDeclined = (props) => {

  return (
        <Message>
            <img alt="Already Decline" src={AlreadyImage} />
            <h1 style={{ color: "#3E4148" }}>Looks like this was already declined.</h1>
            {_.has(props, 'fetchedData.Activities[0].DeclinedBy.Name') && !_.isEmpty(props.fetchedData.Activities[0].DeclinedBy.Name) &&
                <p style={{ color: "#3E4148" }}>{props.fetchedData.Activities[0].DeclinedBy.Name} rejected this on {props.localDeclineDateMessage}</p>
            }
        </Message>
    );
};

export default BidDeclined;
