import React, { useState, useEffect, useRef, Fragment } from 'react';
import styledComponents from './styles';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';
import useDelayModalUnmount from '../../components/Modal/hook';
import VideoModal from '../../components/VideoModal';
import GhostButton from '../../mobileComponents/GhostButton';
import VimeoIframe from '../../components/VimeoIframe';

const {
  P
} = commonStyles;
const {
  BannerWrapper, 
  BannerHeader, 
  H1, 
  H3, 
  ToggleButton, 
  MobileToggleButton,
  TabletToggleButton, 
  Chevron, 
  BannerContent, 
  ValuePoints, 
  ValuePoint, 
  H5, 
  Icon,
  ContentAndVid,
  TextBox,
  DesktopVideoPreview,
  MobileVideoView
  } = styledComponents;

export default function Banner({ translations, cultureCode }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 913 ? true : false);
  const [toggle, setToggle] = useState(!isMobile);
  const [height, setHeight] = useState(0);
  const [isMounted, setIsMounted] = useState(false);
  const [watchVideo, setWatchVideo] = useState(false);

  const contentRef = useRef(null);
  const modalOpen = useDelayModalUnmount(isMounted, 250);

  useEffect(() => {
    setIsMobile(window.innerWidth < 913 ? true : false);
    if (toggle) {
      const content = contentRef.current;
      setHeight(content.height);
    } else {
      setHeight(0);
    }
  }, [toggle]);

  const handleToggle = () => {
    setToggle(!toggle);
  }

  const handleVideoModal = () => {
    setIsMounted(!isMounted);
  }

  const handleWatchVideo = () => {
    setWatchVideo(!watchVideo);
  }

  return (
    <BannerWrapper showBg={toggle}>
      <BannerHeader cultureCode={cultureCode} domain={`${window.env.evBlob}`}>
        {cultureCode !== 'en-US' ?
          <Fragment>
            <H1>{translations.banner.title}</H1>
            <H3>{translations.banner.subtitle}</H3>
          </Fragment>
          :
          <ContentAndVid>
            <TextBox>
              <H1>{translations.banner.title}</H1>
              <H3>{translations.banner.subtitle}</H3>
            </TextBox>
            <DesktopVideoPreview domain={`${window.env.evBlob}`}>
              <div className='container'>
                <div className='imgDiv'></div>
                <img src="https://dummyimage.com/543x306/000/fff" alt="image placeholder" />
                <div className='iconBox' onClick={handleVideoModal}>
                  <i className="far fa-play-circle" alt="play icon" />
                </div>
                {modalOpen &&
                  <VideoModal 
                    isMounted={isMounted}
                    toggleMountModal={handleVideoModal}
                  />
                }
              </div>
            </DesktopVideoPreview>
            <MobileVideoView>
              {!watchVideo &&
                <GhostButton hasIcon={true} text={'watch video'} textColor={'#FFFFFF'} borderColor={'#FFFFFF'} bgColor={'transparent'} onClick={handleWatchVideo} />
              }
              {watchVideo &&
                <VimeoIframe />
              }
            </MobileVideoView>
          </ContentAndVid>
        }
        <ToggleButton onClick={handleToggle}>
          {toggle ? translations.banner.buttonClose : translations.banner.buttonOpen}
          {toggle ? <Chevron className="far fa-chevron-up" /> : <Chevron className="far fa-chevron-down" />}
        </ToggleButton>
      </BannerHeader>
      <BannerContent style={{height}}>
        <ValuePoints ref={contentRef}>
          <ValuePoint>
            <Icon src={`${window.env.evBlob}/upfront-pricing/file-certificate.png`} alt="certificate icon" />
            <H5>{translations.banner.valuePoints[0].title}</H5>
            <P>{translations.banner.valuePoints[0].description}</P>
          </ValuePoint>
          <ValuePoint>
            <Icon src={`${window.env.evBlob}/upfront-pricing/coins.png`} alt="coins icon" />
            <H5>{translations.banner.valuePoints[1].title}</H5>
            <P>{translations.banner.valuePoints[1].description}</P>
          </ValuePoint>
          <ValuePoint>
            <Icon src={`${window.env.evBlob}/upfront-pricing/user-helmet-safety.png`} alt="installer icon" />
            <H5>{translations.banner.valuePoints[2].title}</H5>
            <P>{translations.banner.valuePoints[2].description}</P>
          </ValuePoint>
          <ValuePoint>
            <Icon src={`${window.env.evBlob}/upfront-pricing/shield-check.png`} alt="shield icon" />
            <H5>{translations.banner.valuePoints[3].title}</H5>
            <P>{translations.banner.valuePoints[3].description}</P>
          </ValuePoint>
          <ValuePoint>
            <Icon src={`${window.env.evBlob}/upfront-pricing/star.png`} alt="star icon" />
            <H5>{translations.banner.valuePoints[4].title}</H5>
            <P>{translations.banner.valuePoints[4].description}</P>
          </ValuePoint>
          <ValuePoint>
            <Icon src={`${window.env.evBlob}/upfront-pricing/headset.png`} alt="headset icon" />
            <H5>{translations.banner.valuePoints[5].title}</H5>
            <P>{translations.banner.valuePoints[5].description}</P>
          </ValuePoint>
          <ValuePoint>
            <Icon src={`${window.env.evBlob}/upfront-pricing/Icon.png`} alt="clock icon" />
            <H5>{translations.banner.valuePoints[6].title}</H5>
            <P>{translations.banner.valuePoints[6].description}</P>
          </ValuePoint>
          {/* Mobile only */}
          <MobileToggleButton onClick={handleToggle}>
            {translations.banner.buttonClose}
            <Chevron className="far fa-chevron-up" />
          </MobileToggleButton>
        </ValuePoints>
        {/* Tablet only */}
        <TabletToggleButton onClick={handleToggle}>
          {translations.banner.buttonClose}
            <Chevron className="far fa-chevron-up" />
        </TabletToggleButton>
      </BannerContent>
    </BannerWrapper>
  )
}