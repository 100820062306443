import { useState, useEffect } from 'react';
import _ from "lodash";
import translationsLocalData from "../../../../../server/locale/en_US.json"


export default function getStatusesList(translations) {

  const statuses = [
    {
      name: translations.assignment.statusName,
      active: false,
      value: 1,
      icon: "far fa-user-check",
      title: translations.assignment.title,
      declinedTitle: translations.assignment.declinedTitle,
      declinedSubtitle: translations.assignment.declinedSubtitle
    },
    {
      name: translations.quoting.statusName,
      active: false,
      value: 2,
      icon: "far fa-file-invoice-dollar",
      title: translations.quoting.title
    },
    {
      name: translations.approval.statusName,
      active: false,
      value: 3,
      icon: "far fa-stamp",
      title: translations.approval.title,
      revisedTitle: translations.approval.revisedTitle,
      revisedSubtitle: translations.approval.revisedTitle
    },
    {
      name: translations.scheduling.statusName,
      active: false,
      value: 4,
      icon: "far fa-calendar-day",
      title: translations.scheduling.title
    },
    {
      name: translations.activeSignOff.statusName,
      active: false,
      value: 5,
      icon: "far fa-pen-nib",
      title: translations.activeSignOff.title,
      inactiveTitle: translations.inactiveSignOff.title
    },
  ]
  return statuses;
}