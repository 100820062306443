import styled, { css } from "styled-components";
import { theme, fade, text, mediaMin } from "../../../../../../shared/style";
const AlertHeading = styled.h2`
  margin-top: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
`;
const alertText = css`
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: 0.3px;
`;
const AlertReason = styled.p`
  ${alertText};
`;
const AlertReasonTime = styled.p`
  ${alertText};
`;
const Alert = styled.div`
  width: 100%;
  padding: 12px 12px 13px;
  margin-bottom: 16px;
  ${props =>
    props.type === "warning" &&
    `background-color: ${theme.Warning[100]};
    ${AlertHeading}, ${AlertReason}, ${AlertReasonTime}{
      color: ${theme.Warning[900]}
    }
    `};
  ${props =>
    props.type === "primary" &&
    ` background-color: ${theme.Primary[100]};
      ${AlertHeading}, ${AlertReason}, ${AlertReasonTime}{
        color: ${theme.Primary[900]}
      }
      `};
`;

const AlertReasonWraper = styled.div`
  display: flex;
  ${props =>
    props.center
      ? `justify-content: center;`
      : `justify-content: space-between;`};
`;
export default {
  Alert,
  AlertHeading,
  AlertReasonWraper,
  AlertReason,
  AlertReasonTime
};
