import React, { Fragment } from 'react';
import styledComponents from './styles';

const {
  DeleteItem,
  IconBox,
  P_Micro_Grayed
} = styledComponents;

export default function DeleteItemButton({ onClick, mobile }) {
  return (
    <Fragment>
      <DeleteItem onClick={onClick} mobile={mobile}>
        <IconBox><i className="far fa-minus-circle" alt="minus icon" /> <P_Micro_Grayed>Remove</P_Micro_Grayed></IconBox>
      </DeleteItem>
    </Fragment>
  )
}