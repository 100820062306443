import React, { Fragment } from 'react';
import StyledComponents from './styles';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';

const {
  Link,
  ChevronRight
} = commonStyles;

const {
  ModalBackground,
  ModalContainer,
  ModalTitle,
  ModalCloseButton,
  ModalCloseIcon,
  ModalListContainer,
  Header2,
  RebatesSection,
  ProgramTypeTextWrapper,
  ProgramTypeText,
  Rebate,
  RebateName,
  RebatePrice,
  LearnMore,
  ScrollDisabler
} = StyledComponents;

const ModalRebates = ({ isMounted, toggleMountModal, title, content }) => {
  let prevType = null;

  return (
    <Fragment>
      <ModalBackground onClick={toggleMountModal} animate={isMounted ? 'in' : 'out'}>
        <ModalContainer onClick={e => e.stopPropagation()} animate={isMounted ? 'in' : 'out'}>
          <ModalTitle>
            <Header2>{title}</Header2>
            <ModalCloseButton onClick={toggleMountModal}>
              <ModalCloseIcon className="far fa-times" />
            </ModalCloseButton>
          </ModalTitle>
          <ModalListContainer padding={content.length > 4 ? '8px' : '0px'}>
            <RebatesSection>
              {/* Start mapping here */}
              {
                content.map(item => {
                  return (
                    <Fragment key={item.Id}>
                      {
                        item.Type !== prevType ? 
                        (
                          <ProgramTypeTextWrapper spacing={item.Type !== prevType ? '16px 0px 8px' : '0px'}>
                            {item.Type !== prevType && (prevType = item.Type) && <ProgramTypeText>{item.Type}s</ProgramTypeText> }
                          </ProgramTypeTextWrapper>
                        )
                        : null
                      }
                      <Rebate>
                        <RebateName>{item.Name}</RebateName>
                        <RebatePrice>{item.Text}</RebatePrice>
                        <LearnMore>
                          <Link href={item.WebAddress} target="_blank">Learn More</Link>
                          <Link href={item.WebAddress} target="_blank"><ChevronRight className="far fa-chevron-right"></ChevronRight></Link>
                        </LearnMore>
                      </Rebate>
                    </Fragment>
                  )
                })
              }
            </RebatesSection>
          </ModalListContainer>
        </ModalContainer>
      </ModalBackground>
      <ScrollDisabler />
    </Fragment>
  )
}

export default ModalRebates