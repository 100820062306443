import React, { Component } from "react";
import InfoCard from "../InfoCard";
import { StatusTitle, StatusButton, StatusText } from "./styles";
import UserTimes from "../../../../../../public/images/User-times.svg";
import NoResponse from "../../../../../../public/images/no-response.svg";
import UserHardHat from "../../../../../../public/images/User-hard-hat.svg";
import FileInvoiceDollar from "../../../../../../public/images/File-invoice-dollar.svg";

const buttonDetails = [
  { view: "declined", icon: UserTimes, text: "Customer declined" },
  { view: "estimate", icon: FileInvoiceDollar, text: "Offering an estimate" },
  { view: "waitingCustomer", icon: NoResponse, text: "Waiting on customer" },
  { view: "spSelected", icon: UserHardHat, text: "I was selected!" }
];

/** Component RenderStatusButtons render the status buttons to get the status about activity */
const RenderStatusButtons = ({ activity, handleView, isWaiting }) => {
  const inSyncButtonDetails = buttonDetails.slice();
  if (isWaiting) {
    inSyncButtonDetails.splice(1, 1);
  } else inSyncButtonDetails.splice(2, 1);
  return inSyncButtonDetails.map(({ icon, text, view }, index) => (
    <StatusButton key={index} onClick={() => handleView(activity, view)}>
      <img src={icon} alt={text} />
      <StatusText>{text}</StatusText>
    </StatusButton>
  ));
};

/** Component BidUpdates renders the view to get updates about activity */
export default class BidUpdates extends Component {
  render() {
    return (
      <div>
        <InfoCard activity={this.props.activity} />
        <StatusTitle>Any updates? Select a status.</StatusTitle>
        <RenderStatusButtons {...this.props} />
      </div>
    );
  }
}
