import React, { PureComponent } from "react";
import { apiConfig } from "../../../lib/apiConfig";
import fetchData from "../../../lib/fetchData";
import QuickCard from "../../../components/QuickCard";
import styles from "./styles.module.css";
import BidChanged from "../../../features/BidStatus/BidChanged";
import _ from "lodash";
import Tab from "./Tabs";
import BidComponent from "../../../components/BidComponent/index";
import SavedChanges from "../../../components/SavedChanges/index";

class WeSelected extends PureComponent {
  constructor(props) {
    super(props);
    console.log("weSelected", props);
    this.state = {
      ...props,
      formValues: {
        decimal: "",
        message: ""
      },
      uploadArray: [],
      disabled: false,
      fetchSuccess: false,
      showIntegerError: false,
      showOver500: false,
      token: props.token,
      coreLink: "",
      tabIndex: "1",
      uploadZoneSize: 0,
      done: false
    };
    this.setUploadArray = this.setUploadArray.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.onResize);
    this.setState({
      token: "token=" + this.state.fetchedData.OperationGuids.Submit,
      coreLink: `${
        window.env && window.env.webRoot ? window.env.webRoot : "app.qmerit.com"
      }`
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  onResize = () => {
    var r = document.getElementById("uploadZone");
    r && this.setState({ uploadZoneSize: r.offsetWidth });
  };

  submitForm() {
    this.decimalNumCheck();
    if (this.state.disabled === true) {
      return;
    } else if (this.state.formValues.decimal) {
      let actions = [],
        QuoteDecimal,
        QuoteFiles,
        QuoteMessage;
      _.map(this.props.fetchedData.Tasks.Actions, (action, index) => {
        if (action.Type === "Currency") {
          QuoteDecimal = action.Id;
        } else if (action.Type === "Upload") {
          QuoteFiles = action.Id;
        } else if (action.Type === "Text") {
          QuoteMessage = action.Id;
        }
      });
      const options = {
        ...apiConfig.postConsumerInfo,
        query: "token=" + this.state.fetchedData.OperationGuids.Submit,
        body: {
          taskId: this.props.fetchedData.Tasks.Id,
          actions: [
            {
              Id: QuoteDecimal,
              CustomDecimal1: this.state.formValues.decimal
            },
            {
              Id: QuoteFiles,
              Files: this.state.uploadArray
            },
            {
              Id: QuoteMessage,
              CustomString1: this.state.formValues.message
            }
          ]
        }
      };
      fetchData(options);
      this.setState({
        fetchSuccess: true
      });
    }
  }

  disableEnterKey(event) {
    if (event.which === 13) {
      event.preventDefault();
    }
  }
  changeValue(fieldName, value) {
    let valueNoComma = value.replace(/\,/g, "");
    let valueWithComma = valueNoComma.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.setState({
      formValues: {
        ...this.state.formValues,
        [fieldName]: valueWithComma
      }
    });
  }

  handleChange = event => {
    if (event.target.id === "quoteDecimal") {
      const test1 = /^\d+$/.test(event.target.value),
        test2 = /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{2}$/.test(
          event.target.value
        ),
        test3 = /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{1}$/.test(
          event.target.value
        ),
        test4 = /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{0}$/.test(
          event.target.value
        );
      if (test1 || test2 || test3 || test4) {
        this.changeValue("decimal", event.target.value);
        this.setState({
          showIntegerError: false,
          disabled: false
        });
      } else {
        this.changeValue("decimal", event.target.value);
        this.setState({
          showIntegerError: true,
          disabled: true
        });
      }
    }
    if (event.target.id === "quoteMessage") {
      if (event.target.value.length < 500) {
        this.changeValue("message", event.target.value);
      }
    }
  };

  setUploadArray(array) {
    this.setState({
      uploadArray: array
    });
  }

  decimalNumCheck() {
    if (this.state.formValues.decimal) {
      const test1 = /^\d+$/.test(this.state.formValues.decimal),
        test2 = /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{2}$/.test(
          this.state.formValues.decimal
        );

      let value = test1 === true || test2 === true ? false : true;

      if (test1) {
        let addDeci = `${this.state.formValues.decimal}${".00"}`;
        this.changeValue("decimal", addDeci);
      }
      this.setState({
        showIntegerError: value,
        disabled: value
      });
    }
  }

  blurNumCheck() {
    if (this.state.formValues.decimal) {
      const test1 = /^\d+$/.test(this.state.formValues.decimal),
        test2 = /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{2}$/.test(
          this.state.formValues.decimal
        ),
        test3 = /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{1}$/.test(
          this.state.formValues.decimal
        ),
        test4 = /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{0}$/.test(
          this.state.formValues.decimal
        );

      let value = test1 === true || test2 === true ? false : true;

      if (test1) {
        let addDeci = `${this.state.formValues.decimal}${".00"}`;
        this.changeValue("decimal", addDeci);
      }
      this.setState({
        showIntegerError: value,
        disabled: value
      });
    }
  }
  changeTab = event => {
    event.preventDefault();
    this.setState({ tabIndex: event.target.value });
    setTimeout(() => {
      this.onResize();
    }, 1);
  };

  openDateTime(value) {
    this.setState({
      modalDateTime: value
    });
  }
  done = e => {
    e.preventDefault();
    this.setState({ done: true });
  };

  render() {
    if (this.state.done) {
      return <SavedChanges />;
    }

    if (this.state.fetchSuccess) {
      return <BidChanged />;
    }

    return (
      <QuickCard>
      {console.log("weSelected STATE", this.state)}
        <div>
          <a href={this.state.coreLink} className={styles.quoteTitle}>
            {this.state.fetchedData.Activities[0].Name}
          </a>
          <h4 className={styles.quoteBRnum}>
            Bid Request #{this.state.fetchedData.Activities[0].Project.Id}
          </h4>
        </div>
        <div className={styles.weSelectedTitle}>
          First, please tell us how much you bid.
        </div>

        <div>
          <form className={styles.quoteForm} onKeyPress={this.disableEnterKey}>
            <BidComponent
              handleChange={this.handleChange}
              setUploadArray={this.setUploadArray}
              {...this.state}
            />
            <p className={`${styles.weSelectText} ${styles.activityText}`}>
              Have you completed the activity for
              <strong>
                {" "}
                {this.state.fetchedData.Activities[0].Customer.Name}
              </strong>
              ?
            </p>
            <Tab />
            <div className={styles.btnWraper}>
              <button className={`${styles.btn} ${styles.btnNote}`}>
                ADD A NOTE
              </button>
              <button
                className={`${styles.btn} ${styles.btnDone}`}
                onClick={this.done}
              >
                DONE
              </button>
            </div>
          </form>
        </div>
      </QuickCard>
    );
  }
}
export default WeSelected;
