import styled, { css, keyframes } from "styled-components";
import { Root as LookingRoot } from "./common/looking";
import {
  theme,
  headerSizes,
  mediaMax,
  mediaMin,
  fade
} from "../../../shared/style";
import { Article, Section } from "../../../components/QuickCard";
import { Body, Wraper } from "../../../components/Modal/index";
const gap = 24;
const MapWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  /* ${({ allBlured }) =>
    allBlured &&
    css`
      filter: blur(6px);
    `}; */
    border: 1px solid #dedfe2;
    padding: 16px;
    border-radius: 8px;
    min-height: calc(100vh - 56px - 16px);
  ${mediaMax.tablet` height: 100vh; flex: 0 0 100%; max-width:100%;`}
`;
const MobileSearchCard = styled.div`
  max-height: 100vh;
  @media (width: 414px) and( height:896px) {
    padding-bottom: 32px !important;
  }
  @media (width: 375px) and (height: 812px) {
    padding-bottom: 32px !important;
  }
  ${({ noBlur }) => noBlur && `filter: none !important;`};
  ${props =>
    props.back
      ? `background-color: ${props.back};`
      : ` backdrop-filter: blur(10px);background-color: rgba(244, 245, 246, 0.5);`};
  box-shadow: inset 0 -1px 0 0 var(--IndiqoGrey-300);
  position: absolute;
  z-index: 9999;
  width: 100%;
  left: 0;
  padding: 16px;

  border-radius: 8px 8px 0 0;
  ${({ card }) =>
    card &&
    css`
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14),
        0px 4px 5px 0px rgba(0, 0, 0, 0.12), 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
      background-color: #fff;
    `};
  ${({ listing }) =>
    listing &&
    css`
      overflow: auto;
      ${mediaMin.tablet` 
        padding-top:0;
        top: 20%;
        width: 0;
        height: 0;
        opacity: 0;
        transform: translate3d(-150%,0,0);
        animation-duration: .3s;
        animation-fill-mode: both;
        animation-name: ${listingdesktopAnimation};
        border-radius: 8px;
        &.reverse{
        oveflow: hidden;
        animation-name: ${listingdesktopAnimationRevers}; 
        }
      `};
    `};
  ${mediaMin.tablet`top: 0px;`};
  ${({ withSwipe }) =>
    withSwipe &&
    mediaMax.tablet`padding-top: 0 !important;
    .swipe{
      background: #fff;
      top: 0 ;
      position: sticky;
      z-index:999;
    }
    `};
  ${mediaMax.tablet`
  position: fixed;border-radius: 16px 16px 0 0; bottom: 0px;  padding: 12px 16px 16px;`};
  ${({ filter }) =>
    filter &&
    css`
      ${mediaMax.tablet`padding-bottom: 32px;`};
    `};

  ${({ showActivity }) =>
    showActivity &&
    css`
      overflow: initial;
      ${mediaMin.tablet`padding:0;`};
      ${mediaMax.tablet`
        border-radius: 0;
        box-shadow: none;
        position: initial;
        padding-top: ${headerSizes.desktop}px;
      `};
      ${mediaMax.phone`
        padding-top: ${headerSizes.phone}px;
      `};
    `};
`;
const LineBtn = styled.button`
  width: 40px;
  margin: 0 auto;
  position: relative;
  height: 24px;
  border-radius: 2px;
  border: 0;
  display: block;
  cursor: pointer;
  background: transparent;
  &::before {
    content: "";
    position: absolute;
    display: block;
    height: 4px;
    width: 100%;
    left: 0;
    top: 12px;
    position: absolute;
    background: ${fade("#000000", 0.08)};
  }
  ${mediaMin.tablet`display:none;`};
`;
const Root = styled.div`
  ${({ blured }) =>
    blured &&
    css`
      ${MobileSearchCard},${LookingRoot},${MapWrapper} {
       filter: blur(8px);
      }
    `};
  ${({ mobileBlured }) =>
    mobileBlured &&
    css`
      ${mediaMax.tablet`
      ${LookingRoot},${MapWrapper} {
       filter: blur(8px);
      }
    `};
    `};

  ${Article} {
    min-height: initial;
  }
  ${Section} {
    ${props =>
      props.fullMobileSection &&
      mediaMax.phone`min-height:100vh;padding-top:0;`};
    ${props =>
      props.map &&
      css`
        ${mediaMax.phone`padding-top:0;`};
      `};
  }
  ${props =>
    props.noPadding &&
    css`
      padding: 0;
      ${mediaMax.phone`padding: 0;`};
    `};
  ${props =>
    props.map &&
    css`
      ${Section} {
        padding: 0;
      }
    `};
`;

const listingdesktopAnimation = keyframes`
0% { left: -150px;top: 20%; width: 0; height: 0; opacity: 0; transform: translate3d(-150%,0,0); }
100% { left: 0;top:0; height: 100%; width: 100%; opacity: 1; transform: translateZ(0); }
`;
const listingdesktopAnimationRevers = keyframes`
0% {left: 0; top:0; height: 100%; width: 100%; opacity: 1; transform: translateZ(0); }
100% {left: -150px; top: 20%; width: 0; height: 0; opacity: 0; transform: translate3d(-150%,0,0); }
`;
const Overlay = styled.div`
  ${mediaMax.tablet`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    .back-drop {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: ${fade("#dedfe2", 0.5)};
      /* backdrop-filter: blur(8px);*/
    }
  `};
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const InpWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  .n-search {
    color: #b9bbc1;
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    font-size: 16px;
    line-height: 20px;
    width: 20px;
    height: 20px;
  }
`;
const CancleSearch = styled.button`
  cursor: pointer;
  background: transparent;
  border: 0;
  padding: 0;
  margin-left: 8px;
  color: ${theme.Primary.main};
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: center;
`;
const FilterBtn = styled.button`
  cursor: pointer;
  background: transparent;
  border: 0;
  font-size: 18px;
  padding: 0;
  position: relative;
  padding-right: 9px;
  padding-top: 6px;
  padding-left: 9px;
  color: #969ba5;
  .notification {
    color: #fff;
    background: ${theme.Primary.dark};
    font-size: 12px;
    padding: 0 4px;
    border-radius: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }
  .filter-text {
    margin: 0;
    color: #6e727c;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.25px;
    line-height: 16px;
  }
`;

const SearchButton = styled.button`
  cursor: pointer;
  z-index: 1;
  position: absolute;
  right: 0;
  top: 50%;
  right: 4px;
  transform: translateY(-50%);
  color: #fff;
  background: #01b4ca;
  padding: 6px;
  border: 0px;
  border-radius: 100%;
  width: 36px;
  height: 36px;
  font-size: 18px;
  display: ${({ searchFocus }) => (searchFocus ? "block" : "none")};
`;
const SearchCancleButton = styled.button`
  cursor: pointer;
  display: none;
  background: transparent;
  border: 0;
  padding: 0;
  position: absolute;
  z-index: 99999;
  right: 0;
  top: 50%;
  right: 54px;
  transform: translateY(-50%);
  display: ${({ searchFocus }) => (searchFocus ? "block" : "none")};
  i {
    color: #ddd;
    font-size: 14px;
  }
`;
const SearchTextField = styled.input`
  width: 100%;
  height: 44px;
  padding: 0 12px 2px;
  padding-left: ${({ isFocused }) => (!isFocused ? 36 : 24)}px;
  color: ${theme.IndiqoGrey[800]};
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  border-radius: 44px;
  border: solid 1px ${theme.IndiqoGrey[300]};
  background-color: ${theme.IndiqoGrey.main};
  ::-webkit-input-placeholder {
    color: ${fade(theme.IndiqoGrey[800], 0.35)};
  }
  ::-moz-placeholder {
    color: ${fade(theme.IndiqoGrey[800], 0.35)};
  }
  :-ms-input-placeholder {
    color: ${fade(theme.IndiqoGrey[800], 0.35)};
  }
  :-moz-placeholder {
    color: ${fade(theme.IndiqoGrey[800], 0.35)};
  }
  ${({ isFocused }) =>
    isFocused &&
    css`
      padding-right: 64px;
      border-color: #01b4ca;
      box-shadow: 0 0 4px 0 #01b4ca;
    `};
`;

const HelpLocationService = styled.div``;

const ContentWraper = styled.div`
  ${mediaMax.phone`min-height: calc(100vh - 112px);`};
`;
const NotFind = styled.div`
  text-align: center;
  min-height: calc(100% - 130px);
  display: flex;
  align-items: center;
  ${mediaMax.phone`
display: flex;
height: calc(100vh - 112px - 16px - 50px);
align-items: center;
`} > div {
    margin: 0 auto;
  }
  i {
    font-size: 64px;
    line-height: 1.13;
    margin-bottom: 24px;
    color: ${theme.IndiqoGrey[400]};
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    color: ${theme.IndiqoGrey[800]};
    max-width: 328px;
    margin: 0 auto;
    margin-bottom: 24px;
  }
`;
const TotalItems = styled.h2`
  display: flex;
  background-color: #fff;
  position: relative;
  z-index: 1;
  margin-top: 0;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  color: ${theme.IndiqoGrey[800]};
  position: sticky;
  /* z-index: 9999; */
  ${mediaMin.tablet`top: 0;`}
  ${mediaMax.tablet`top: 24px;`}
  ${mediaMin.phone`
    
    
    padding:16px;
    margin-left:-16px;
    margin-right:-16px;
    margin-bottom:0;

`};
  ${mediaMax.phone`
    line-height: 24px;
    margin: 0 -16px;
    padding: 0 16px 16px;
`};
/* on Mobile */
  /* box-shadow: inset 0 -1px 0 0 ${theme.IndiqoGrey[300]}; */
`;

const Items = styled.div`
  position: relative;
  z-index: 0;
`;

const Icon = styled.div`
  flex: 0 0 52px;
  max-width: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    &[class*="clipboard"] {
      color: ${theme.Primary.dark};
    }
    &[class*="user"] {
      color: ${theme.IndiqoGrey[500]};
    }
    &[class*="invoice"] {
      color: ${theme.Primary.main};
    }
  }
`;

const ItemFadeIn = keyframes`
from {
  opacity: 0;
  transform: translate3d(0, -100%, 0);
}
to {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
`;
const Info = styled.div`
  flex: 0 0 calc(100% - 52px);
  max-width: calc(100% - 52px);
  display: flex;
  align-items: center;
  padding: 12px 0;
`;
const Item = styled.div`
  cursor: pointer;
  &:not(:nth-child(1)) {
    background-color: #fff;
  }

  position: relative;
  animation-name: ${ItemFadeIn};
  animation-duration: 0.6s;
  animation-fill-mode: both;
  display: flex;
  flex-wrap: wrap;
  &:not(:last-child) {
    ${Info} {
      box-shadow: inset 0 -1px 0 0 ${theme.IndiqoGrey[300]};
    }
  }
  /* &:not(:nth-last-child(1)) {
    ${Info} {
      box-shadow: inset 0 -1px 0 0 ${theme.IndiqoGrey[300]};
    }
  } */

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background: radial-gradient(
        circle,
        rgba(199, 199, 199, 0) 1%,
        rgba(0, 0, 0, 0) 1%
      )
      center/100%; */
    background-position: center;
    transition: background 0.8s;
  }
  &.ripple {
    &::before {
      /* background: radial-gradient(
          circle,
          rgba(181, 181, 181, 0.44) 1%,
          rgba(0, 0, 0, 0) 1%
        )
        center/100%; */
      background-size: 15000%;
    }
  }
  &:active {
  }
  margin-left: -16px;
`;

const InfoWraper = styled.div`
  width: 100%;
`;

const Holder = styled.div`
  display: flex;
  justify-content: space-between;
`;
const noMargin = css`
  margin: 0;
`;
const Name = styled.h3`
  ${noMargin};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 12px;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${theme.IndiqoGrey[800]};
`;

const Mi = styled.p`
  ${noMargin};
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33;
  color: ${theme.IndiqoGrey[800]};
  transform: translateY(2px);
`;

const Ca = styled.h4`
  ${noMargin};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 12px;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
  color: ${theme.IndiqoGrey[600]};
`;

const Time = styled.p`
  ${noMargin};
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33;
  color: ${props => props.dueTime};
  transform: translateY(2px);
`;
const Back = styled.div`
  display: flex;

  align-items: center;

  ${mediaMax.tablet`display:none;`} button {
    transition: 0.3s opacity;
    cursor: pointer;
    padding: 0;
    border: 0;
    background: transparent;
    display: flex;
    align-items: center;
    &:hover {
      opacity: 0.7;
    }
  }

  i {
    color: #6e727c;
    font-size: 16px;
    line-height: 1.25;
    margin-right: 8px;
  }

  p {
    margin: 0;
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 0.5px;
    color: ${theme.Primary.main};
    ${mediaMax.phone`color:#fff`};
  }
`;
const Popup = styled.div`
  ${({ noMobile }) =>
    noMobile &&
    css`
      ${mediaMax.phone`display:none`};
    `} ${Wraper} {
    z-index: 9999999;
    .back-drop{
      background-color: ${fade("#dedfe2", 0.5)};
      /* backdrop-filter: blur(8px); */
    }
  }
  ${Body} {
    max-width: 311px;
    border-radius: 12px;
    /* box-shadow: 0 2px 4px 0 ${fade(theme.Black, 0.5)},
      0 3px 4px 0 ${fade(theme.Black, 0.23)}; */
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12),
    0px 3px 5px 0px rgba(0, 0, 0, 0.2);
    background-color: ${theme.White};
  }
`;

const Content = styled.div`
  padding: 15px;
  position: relative;
`;

const Cross = styled.i`
  float: right;
  cursor: pointer;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${theme.IndiqoGrey[500]};
  transition: 0.3s color;
  &:hover {
    color: ${theme.IndiqoGrey[400]};
  }
`;
const IconMarker = styled.div`
  text-align: center;
  padding: 10px 0 16px;
  i {
    font-size: 64px;
    line-height: 1.13;
    color: ${theme.Primary.main};
  }
`;

const AlertTitle = styled.p`
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: ${theme.IndiqoGrey[800]};
  margin: 0;
  ${props => props.bottomSpace && `margin-bottom: ${props.bottomSpace}px`};
`;

const AlertDescription = styled.p`
  font-family: "Lato";
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: ${theme.IndiqoGrey[800]};
  margin: 0;
  ${props =>
    props.bottomSpace
      ? `margin-bottom: ${props.bottomSpace}px`
      : `margin-bottom: 16px`};
`;

const SearchField = styled.div`
  margin-bottom: 24px;
`;
const TextField = styled.input`
  width: 100%;
  height: 44px;
  padding: 12px;
  color: ${theme.IndiqoGrey[800]};
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  border-radius: 4px;
  border: solid 1px ${theme.IndiqoGrey[300]};
  background-color: ${theme.IndiqoGrey.main};
  ::-webkit-input-placeholder {
    color: ${fade(theme.IndiqoGrey[800], 0.35)};
  }
  ::-moz-placeholder {
    color: ${fade(theme.IndiqoGrey[800], 0.35)};
  }
  :-ms-input-placeholder {
    color: ${fade(theme.IndiqoGrey[800], 0.35)};
  }
  :-moz-placeholder {
    color: ${fade(theme.IndiqoGrey[800], 0.35)};
  }
`;
const ButtonWraper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
`;

const MButton = styled.button`
  cursor: pointer;
  width: 100%;
  border-radius: 100px;
  height: 32px;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 1px;
  text-align: center;
  ${props =>
    !props.submit
      ? `
    color: ${theme.IndiqoGrey[600]}
    border: solid 1px ${theme.IndiqoGrey[300]};
    background-image: linear-gradient(
      to bottom,
      ${theme.IndiqoGrey[100]}
      ,${theme.IndiqoGrey[300]}
    );
    &:hover {
      color: ${theme.IndiqoGrey[500]}
    border: solid 1px ${theme.IndiqoGrey[200]};
    background-image: linear-gradient(
      to bottom,
      ${theme.IndiqoGrey[300]}
      ,${theme.IndiqoGrey[100]}
    );
  }
    `
      : `
    color: ${theme.White}
    border: solid 1px ${theme.Action["700"]};
    background-image: linear-gradient(
      to bottom, ${theme.Primary.main}, ${theme.Action["700"]});
      &:hover {
        color: ${fade(theme.White, 0.8)}
    border: solid 1px ${fade(theme.Action["700"], 0.8)};
    background-image: linear-gradient(
      to bottom, ${theme.Action["700"]}, ${theme.Primary.main});
  }
    `};
`;
const MapContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;

  ${mediaMax.tablet`
  margin: 0 -16px;
  `};
  ${mediaMax.phone`
  margin: 0 ;
  `};
`;
const WrapContent = styled.div`
  ${props => props.show && `display:none`};
`;

const DataWrapper = styled.div`
  flex-grow: 1;
  ${mediaMin.tablet`position: relative;margin-left: 16px; flex: 0 0 358px;
  max-width: 358px;`};
`;

const ListActivitiesWrapper = styled.div`
  ${mediaMax.tablet`margin-top: 96px;`};
`;
export default {
  Root,
  MobileSearchCard,
  LineBtn,
  SearchWrapper,
  SearchButton,
  SearchCancleButton,

  InpWrapper,
  SearchTextField,
  FilterBtn,
  CancleSearch,
  HelpLocationService,
  ContentWraper,
  NotFind,
  TotalItems,
  Items,
  Icon,
  Info,
  Item,
  InfoWraper,
  Holder,
  Name,
  Mi,
  Ca,
  Time,
  Back,
  Content,
  Cross,
  Popup,
  IconMarker,
  AlertTitle,
  TextField,
  ButtonWraper,
  MButton,

  AlertDescription,
  WrapContent,
  SearchField,
  MapWrapper,
  DataWrapper,
  MapContainer,
  Overlay,
  ListActivitiesWrapper
};
