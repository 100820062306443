import { render } from "enzyme";
import React, {PureComponent} from "react"
import styledComponents from "./style";
import queryString from "query-string";
import _ from "lodash";


import Wrapper from "../../components/Wrapper";
import Loader from "../../components/Loader";
import Radio from '../../components/Radio'
import {TierUpdateSuccess} from './components/TierUpdateSuccess'
import {TierUpdateFail} from './components/TierUpdateFail';
import {AlreadyUpdated} from './components/AlreadyUpdated';
import BidStatusError from '../../components/Errors';
import {AddDocument, RemoveDocument, FileUpload} from './components/AddDocument';
import FileEncoder from '../../lib/fileEncoder';

class TierManage extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {  
            isLoading: true,
            selectedTier: '',
            tierChangeReason: '',
            guId: '',
            companyId: '',
            fetchedProjectInfo: {},
            tierBySurveyMapId: {},
            SurveyMapId: 0,
            tierEditionSucces: false,
            tierFechedSucces: false,
            customPrice: '',
            selectedTierCode: '',
            document: '',
            contentType: '',
            translations: {}
        };
    }

    componentDidMount() {

        let bidGuid = this.getProjectGuid(this.props.location.search)
        let companyId = this.getCompanyId(this.props.location.search)
        if (!_.isEmpty(bidGuid) || !_.isEmpty(companyId)) {
            this.setState({guId: bidGuid, companyId: companyId})
           this.getBidInfo(bidGuid)
        } 
        if (_.has(window, 'env.translations') && !_.isEmpty(window.env.translations)) {
            this.setState({
                translations: window.env.translations
            })
        }
    }

    getProjectGuid = (url) => {
        return queryString.parse(url).token
    }

    getCompanyId = (url) => {
        return queryString.parse(url).companyid
    }

    handleRadioOnchange = (tierId, tierCode) => {

        this.setState({selectedTier: tierId, selectedTierCode: tierCode})
    }

    handleTextareaChange = (event) => {
        event.persist();
        this.setState({tierChangeReason: event.target.value})
    }

    handleCustomePriceInput = (event) => {
        event.persist();
        this.setState({customPrice: event.target.value})
    }


    getBidInfo = (guId) => {
        let optionalParams = {
            headers: {"content-type": "application/json; charset=UTF-8", "Subscription-Key": `${window.env.apiKey}`},
            method: "GET"
        }

        return new Promise((resolve, reject) => {

                      fetch(`${window.env.qmtUrl}/Bid/${guId}`, optionalParams)
                      .then(res => {
                        this.setState({tierFechedSucces: res.status})
                        return res.json()})
                      .then(data => {
                          this.getTierBySurveyMapId(data.Project.Tier.SurveyMapId)
                          this.setState({fetchedProjectInfo: data})
                      })
                      .catch(error => {
                          reject({
                              isLoading: false,
                              fetchedData: { ErrorCode: 500, ...error }
                          });
                      })
        })
    }

    getTierBySurveyMapId = (surveyMapId) => {
        let optionalParams = {
            headers: {"content-type": "application/json; charset=UTF-8", "Subscription-Key": `${window.env.apiKey}`},
            method: "GET"
        }

        return new Promise((resolve, reject) => {
                      fetch(`${window.env.qmtUrl}/Tier/SurveyMapId/${surveyMapId}`, optionalParams)
                      .then(res => res.json())
                      .then(data => {
                          this.setState({tierBySurveyMapId: data.Payload, isLoading: false})
                      })
                      .catch(error => {
                          reject({
                              isLoading: false,
                              fetchedData: { ErrorCode: 500, ...error }
                          });
                      })
        })
    }

    editProjectTier = (event) => {
        if (event.detail === 1) {
            this.setState({isLoading: true})
            let data = {
                ContentType: this.state.contentType,
                Document: this.state.document,
                TierId: this.state.selectedTier,
                Reason: this.state.tierChangeReason,
                BidAmount: Number(this.state.customPrice),
            }
            let optionalParams = {
                headers: {"content-type": "application/json; charset=UTF-8", "Subscription-Key": `${window.env.apiKey}`},
                body: JSON.stringify(data),
                method: "POST"
            }
    
            new Promise((resolve, reject) => {
                fetch(`${window.env.qmtUrl}/Bid/${this.state.guId}/RecommendTier`, optionalParams)
                .then(res => {
  
                    this.setState({tierEditionSucces: res.status, isLoading: false})
                })
                .catch(error => {
    
                    reject({
                        isLoading: false,
                        fetchedData: { ErrorCode: 500, ...error }
                    });
                })
            })
        }

    }

    handleFileToBase64 = (file) => {
       file.persist();
      let fileBase64 = new Promise((resolve, reject) => {
        FileEncoder.base64(file.target.files[0], resolve);
      });
      Promise.resolve(fileBase64).then(response => {
          this.setState({document: response, contentType: file.target.files[0].type})
      })
    }

    handleRemoveDocument = () => {
        this.setState({document: '', contentType: ''})
    }

    render() {

        const {
            Card,
            Header,
            BidId,
            TierPrice,
            TierChecksContainer,
            TierTextContainer,
            TierText,
            TierTextArea,
            Button,
            CustomePriceInput,
            CustomePriceContainer,
            CurrencyText,
        } = styledComponents;

        if (_.has(this.state, 'fetchedProjectInfo.Status') && this.state.fetchedProjectInfo.Status === 'Declined') {
            return <BidStatusError status={this.state.fetchedProjectInfo.Status} />
        }

        if (_.has(this.state, 'fetchedProjectInfo.ChangeReason') && !_.isEmpty(this.state.fetchedProjectInfo.ChangeReason)) {
            return <AlreadyUpdated/>
        } 
        
        if (!_.isEmpty(this.state.fetchedProjectInfo) && !_.isEmpty(this.state.tierBySurveyMapId) && this.state.isLoading === false && this.state.tierEditionSucces === false && _.has(this.state, 'fetchedProjectInfo.Project.Tier')) {
            return (
                <Wrapper name="" company="">
                    <Card>
                        <Header>Update Quote Price</Header>
                        <BidId>Project #{`${this.state.fetchedProjectInfo.Project.Id}`}</BidId>
                        <TierPrice>What price tier do you recommend for this project?</TierPrice>
                        <TierChecksContainer>
                            {this.state.tierBySurveyMapId.filter(tier => tier.Id !== this.state.fetchedProjectInfo.Project.Tier.Id).map((data, inx) => {
                                let WholesalePrice = function formater(num) {
                                    let arr = String(num).split('');
                                    let end = arr.slice(0,1);
                                    end.push(",");
                                    end.push(arr.slice(1).join(''));
                                    return end.join('');
                                  }

                                if (data.Name === 'Custom Price') {
                                    return (
                                        <Radio
                                            key={inx}
                                            name="reason"
                                            id={`reason-${data.Id}`}
                                            label={data.Name}
                                            onChange={e => this.handleRadioOnchange(data.Id, data.Code)}
                                        />
                                    );
                                }
                                return (
                                    <Radio
                                        key={inx}
                                        name="reason"
                                        id={`reason-${data.Id}`}
                                        label={!_.isNil(data.WholesalePrice.LongFormat) ?
                                            data.WholesalePrice.LongFormat
                                            :
                                            `${data.Name} ($${WholesalePrice(data.WholesalePrice)} ${this.state.fetchedProjectInfo.ProgramCurrencyType})`}
                                        onChange={e => this.handleRadioOnchange(data.Id, data.Code)}
                                    />
                                );
                            })}
                        </TierChecksContainer>
                        <TierText displayed={this.state.selectedTierCode === "CUSTOM" ? true : false} customePrice>Quote Amount</TierText>
                        <CustomePriceContainer displayed={this.state.selectedTierCode === "CUSTOM" ? true : false}>
                            <CustomePriceInput
                                type={'number'}
                                value={this.state.customPrice} 
                                placeholder={'$ 00.00'}
                                onChange={this.handleCustomePriceInput}
                                active={this.state.selectedTier}
                            />
                            <CurrencyText>{this.state.fetchedProjectInfo.ProgramCurrencyType}</CurrencyText>
                        </CustomePriceContainer>

                        <FileUpload
                            displayed={this.state.selectedTierCode === "CUSTOM" ? true : false}
                            displayAddFile={!_.isEmpty(this.state.document) && !_.isEmpty(this.state.contentType)}
                            onHandlehandleFileToBase64={this.handleFileToBase64}
                            onHandleRemoveDocument={this.handleRemoveDocument} />
                        <TierTextContainer>
                            <TierText>Reason </TierText>
                        </TierTextContainer>
                        <TierTextArea
                            placeholder='Let the customer know why the price changed...'
                            value={this.state.tierChangeReason}
                            onChange={this.handleTextareaChange}
                            active={this.state.selectedTier}
                        />
                        <Button disabled={_.isEmpty(this.state.tierChangeReason) && _.isEmpty(this.state.selectedTier) ? true : false} onClick={this.editProjectTier}>SUBMIT</Button>
                    </Card>
                </Wrapper>
            );

        }

    if (this.state.tierEditionSucces === 200) {
        return <TierUpdateSuccess/>
    }

    if (this.state.tierEditionSucces === 400 ||this.state.tierEditionSucces === 404) {
        return <TierUpdateFail translations={this.state.translations}/>
    }

     return (<Loader color={"#000000"} isLoading={this.state.isLoading} />)
    }
}

export default TierManage