import React from 'react'
import styledComponents from "./styles";
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';

const {
  H1,
  P
} = commonStyles;

const {
  TitleWrapper,
  TOU_Link
} = styledComponents;

export default function TitleSection({fetchedBidData, bidData, translations}) {
  return (
    <TitleWrapper>
      <H1>{translations.titleSection.titleOne} {bidData.CategoryType} {translations.titleSection.titleTwo} #
        {fetchedBidData.BidList[0].QuoteId && fetchedBidData.Request.QuoteBuilderEnabled ? 
          fetchedBidData.BidList[0].QuoteId 
          : 
          fetchedBidData.ProjectId && !fetchedBidData.Request.QuoteBuilderEnabled ?
            fetchedBidData.ProjectId
            :
            ''
        }
      </H1>
      <P>{bidData.Assignee.Name} {translations.titleSection.descriptionOne} {bidData.CategoryType}{translations.titleSection.descriptionTwo} <TOU_Link href={fetchedBidData.TermsOfUse} target="_blank">{translations.titleSection.qmeritTermsOfUse}</TOU_Link>{translations.titleSection.descriptionThree} {bidData.Assignee.Name} {translations.titleSection.descriptionFour} {bidData.CategoryType}{translations.titleSection.descriptionFive} {fetchedBidData.WorkerType} {translations.titleSection.descriptionSix} {bidData.Assignee.Name}{translations.titleSection.descriptionSeven} {fetchedBidData.WorkerType} {translations.titleSection.descriptionEight}</P>
    </TitleWrapper>
  )
}
