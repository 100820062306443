import React from 'react';
import StyledComponents from './styles';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';
import _ from 'lodash';
import Markdown from 'react-markdown';

const {
  P,
  P_Grayed,
  P_SM
} = commonStyles;

const {
  ProductRow,
  ProductDescription,
  ProductDetails,
  UnorderedList,
  ItemName
} = StyledComponents;

export default function PreliminaryEstimateTable({ fetchedRequestData, translations }) {
  return (
    <React.Fragment>
      {fetchedRequestData.ServicesList.map(item => {
        let customQuote = fetchedRequestData.Request.Tier.Code.toLowerCase() === 'custom';
        let predictivePermitEnabled = item.Type.toLowerCase() === 'permit' && !_.isEmpty(fetchedRequestData.PredictivePermitPrice) && !_.isNil(fetchedRequestData.PredictivePermitPrice);
        let predictivePermitDisabled = item.Type.toLowerCase() === 'permit' && (_.isEmpty(fetchedRequestData.PredictivePermitPrice) || _.isNil(fetchedRequestData.PredictivePermitPrice));
        let ukPermit = item.Descriptions[0].Description == "Permit" && item.Descriptions[0].CultureCode == "en-GB";
        return (
          !ukPermit ? 
            (
              <ProductRow key={item.Id}>
                <ProductDescription>
                  <ItemName>
                    {fetchedRequestData.Request.QuoteBuilderEnabled ?
                      <P>{item.Name}</P>
                      :
                      <P>{item.Descriptions[0].Description}</P>
                    }
                    {predictivePermitEnabled &&
                      <P_SM style={{ color: "#3963FE", margin: "0px" }}>{translations.preliminaryEstimateSection.estimatedWarning}</P_SM>
                    }
                  </ItemName>
                  {
                    customQuote || predictivePermitDisabled ? <P_Grayed>{translations.preliminaryEstimateSection.toBeDetermined}</P_Grayed> :
                      predictivePermitEnabled ? <P number>{fetchedRequestData.PredictivePermitPrice.ShortFormat}</P> :
                        <P number>{item.RetailPrice.ShortFormat}</P>
                  }
                </ProductDescription>
                {predictivePermitEnabled &&
                    <P_SM style={{ margin: "0px" }}>{translations.preliminaryEstimateSection.predictiveDisclaimer}</P_SM>
                  }
                <ProductDetails>
                    {fetchedRequestData.Request.QuoteBuilderEnabled && (item.Type.toLowerCase() !== "custom") ? 
                      <Markdown children={item.Descriptions[0].Description} />
                      :
                      <UnorderedList>
                        {item.Details.map(detail => {
                          return (
                            <li key={detail.SortPriorityId}>{detail.Detail}</li>
                          );
                        })}
                      </UnorderedList>
                    }
                </ProductDetails>
              </ProductRow>
            ) : null
        );
      })}
    </React.Fragment>
  )
}
