const timeZones = [
  { name: "Hawaiian Standard Time", abbr: "HST", city: "(UTC-10:00) Hawaii" },
  { name: "Alaskan Standard Time", abbr: "AKDT", city: "(UTC-09:00) Alaska" },
  {
    name: "Pacific Standard Time",
    abbr: "PDT",
    city: "(UTC-08:00) Pacific Time (US and Canada)"
  },
  {
    name: "Mountain Standard Time",
    abbr: "MDT",
    city: "(UTC-07:00) Mountain Time (US and Canada)"
  },
  {
    name: "US Mountain Standard Time",
    abbr: "MST",
    city: "(UTC-07:00) Arizona"
  },
  {
    name: "Central Standard Time",
    abbr: "CDT",
    city: "(UTC-06:00) Central Time (US and Canada)"
  },
  {
    name: "Eastern Standard Time",
    abbr: "EDT",
    city: "(UTC-05:00) Eastern Time (US and Canada)"
  },
  {
    name: "US Eastern Standard Time",
    abbr: "EDT",
    city: "(UTC-05:00) Indiana (East)"
  }
];
export default timeZones;
