import React, { Fragment, Component } from "react";
import InfoCard from "../../InfoCard";
import { StatusCard } from "../../styles";
import DateTimeInput from "../../../../../components/DateTime";
import BidTotalInput from "../../../../../components/BidTotalInput";
import { InvoiceIcon, CheckListWrapper, InputWrapper } from "./styles";
import FileInvoiceDollar from "../../../../../../../public/images/File-invoice-dollar.svg";

/** Component EstimateSubmitted renders when checked off tasks and
 required fields are filled successfully done */
export const EstimateSubmitted = props => {
  return (
    <Fragment>
      <StatusCard pt={8.76}>
        <InvoiceIcon src={FileInvoiceDollar} />
        <p>This item has been updated with your progress. Thanks!</p>
      </StatusCard>
      <InfoCard isDone {...props} />
    </Fragment>
  );
};

/** Component RenderTasks renders tasks list for "offering an estimate" */
export const RenderTasks = ({
  tasks,
  costValue,
  getDateTime,
  showIntegerError,
  handleCostChange,
  handleTaskStatus,
  showDateTimeError
}) => {
  return tasks.map((task, index) => (
    <CheckListWrapper active={task.active} key={index}>
      <label onClick={() => handleTaskStatus(task.id, !task.active)}>
        <i className={`far fa-${task.active ? "check" : "circle"}`} />
        {task.label}
      </label>
      {task.active && task.type === "dateTime" && (
        <InputWrapper>
          <p className="inp-label">Date and/or Time</p>
          <DateTimeInput
            showInput
            getDate={getDateTime}
            error={showDateTimeError}
          />
        </InputWrapper>
      )}
      {task.active && task.type === "currency" && (
        <InputWrapper>
          <BidTotalInput
            noMb
            value={costValue}
            showIntegerError={showIntegerError}
            handleChange={handleCostChange}
          />
        </InputWrapper>
      )}
    </CheckListWrapper>
  ));
};
