
import React, {useEffect, useState} from 'react';
import styledComponents from "./style";

const {
    TermsContainer,
    TermsButton,
    TermsButtonContainer,
    TermsSideTextButton,
    Iframe,
    Img,
    LogoContainer
} = styledComponents;


const TermsOfUse = ({confirmTerms, onHandleScroll, programTermsOfUse, userLocale}) => {


    const htmlUrl = `${window.env.evBlob}/terms-of-use/html/${programTermsOfUse}_${userLocale.replace('-','_')}.html`

    const [termsViewed, setTermsViewed] = useState(false)

    useEffect(() => {
        window.addEventListener('message', handleScrollToBottom, false)
    }, [])

    const handleScrollToBottom = (event) => {
        if (event && event.data === 'ScrollingComplete'){
            setTermsViewed(true)
        }    
    }

    return(
        <TermsContainer onScroll={onHandleScroll} >
            <LogoContainer>
                <Img src={`${window.env.blobUrl}/qmerit/Qmerit-Wordmark-Indiqo.png`} alt="Raiven" />
            </LogoContainer>
            <Iframe id='touFrame' src={htmlUrl} />
            <TermsButtonContainer>
                <TermsSideTextButton displayed={!termsViewed}>Scroll to the bottom to accept&hellip;</TermsSideTextButton>
                <TermsButton actived={!termsViewed} disabled={!termsViewed} onClick={confirmTerms}>I agree</TermsButton>
            </TermsButtonContainer>
        </TermsContainer>
    );
    }

export default TermsOfUse
