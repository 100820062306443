import React, {PureComponent} from 'react';
import _ from "lodash";
import queryString from "query-string";

import Loader from "../../components/Loader";

import { InstallerDeclinedConfirmation } from "../../components/InstallerDeclined/"
import { InstallerStatus } from '../../components/InstallerStatus';
import {InstallerConfirmed} from './components/InstallerConfirmed';
import {TierUpdateFail} from '../tierManage/components/TierUpdateFail';
import styledComponents from "./style";

class TieredConfirmationDeclined extends PureComponent {
    constructor(props) {
        super(props) ;
            this.state = {
                isLoading: true,
                InstallerDeclinedConfirmation: false,
                installerStatus: false,
                installerConfirmed: true,
                token: "",
                fechedBidData: {},
                bidConfirmed: false,
                declined: false,
                translations:{},
                country: "",
                fetchBidStatus: false
            }
    }

    componentDidMount() {

        let bidGuid = this.getBidGuid(this.props.location.search);
        let country = queryString.parse(this.props.location.search).country;
        if (!_.isEmpty(bidGuid)) {
            this.setState({token: bidGuid})
            this.getBidByGuid(bidGuid)
        } 
        if (_.has(window, 'env.translations') && !_.isEmpty(window.env.translations)) {
            this.setState({
                translations: window.env.translations
            })
        }
        if (!_.isEmpty(country)) this.setState({country: country})
    }

    getBidGuid = (url) => {
        return queryString.parse(url).token
    }

    getBidByGuid = (bidGuid) => {
        let optionalParams = {
            headers: {"content-type": "application/json; charset=UTF-8", "Subscription-Key": `${window.env.apiKey}`},
            method: "GET"
        }

        return new Promise((resolve, reject) => {
                      fetch(`${window.env.qmtUrl}/Bid/${bidGuid}`, optionalParams)
                      .then(res => {
                        this.setState({fetchBidStatus: res.status})
                        return res.json()
                      })
                      .then(data => {
                        let declinedStatus = data.Status === 'Completed' ? false : true;
                        this.setState({fechedBidData: data, isLoading: false, declined: declinedStatus})
                      })
                      .catch(error => {
                          reject({
                              isLoading: false,
                              fetchedData: { ErrorCode: 500, ...error }
                          });
                      })
        })
    }

    declineBidByGuid = (guid) => {
        this.setState({
            isLoading: true,
        })

        let optionalParams = {
            headers: {
                "content-type": "application/json; charset=UTF-8", 
                // "Sec-Fetch-Mode": "no-cors", 
                // "Access-Control-Request-Headers": "*",
                // "Access-Control-Request-Method": "*",
                // "Access-Control-Allow-Origin": "*"
                "Subscription-Key": `${window.env.apiKey}`
                },
            method: "POST"
        }

        new Promise((resolve, reject) => {
            fetch(`${window.env.qmtUrl}/Bid/${guid}/Reject`, optionalParams)
            .then(res => {
                this.setState({bidConfirmed: res.status, isLoading: false, declined: true})
            })
            .catch(error => {

                reject({
                    isLoading: false,
                    fetchedData: { ErrorCode: 500, ...error }
                });
            })
        })
    }

    formatPhoneNumber(phoneNumberString) {
        let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          var intlCode = (match[1] ? '+1 ' : '');
          return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return null;
      }

    
    render() {
        const  {Card, Header, TierText, Button, CompanyHeader, CompanyDetails, CompanyLink, Footer, Warning } = styledComponents;
        if (!this.state.isLoading && this.state.fechedBidData.Status==='Completed' && this.state.declined === false) {
            return (
                <div className="helloThere" style={{display: 'flex', flexDirection: 'column', height: '100vh', justifyContent: 'center'}}>
                    <div className="center" style={{flexDirection: 'row', justifyContent: 'center', margin: 'auto'}}>
                    <Card>
                        <Header>
                            {this.state.translations.declineInstaller.title}
                        </Header>
                        <TierText>
                            {this.state.translations.declineInstaller.description}
                        </TierText>
                        <CompanyHeader>{this.state.translations.declineInstaller.labelOne}</CompanyHeader>
                            <CompanyDetails>{this.state.fechedBidData.Assignee.Name}</CompanyDetails>
                            <div>
                            <CompanyLink href={`tel:${this.state.fechedBidData.Assignee.Phone}`}>{this.formatPhoneNumber(this.state.fechedBidData.Assignee.Phone)}</CompanyLink>
                            </div>
                            
                            <CompanyLink href={`mailto:${this.state.fechedBidData.Assignee.Email}`}>{this.state.fechedBidData.Assignee.Email}</CompanyLink>
                        
                        <Button onClick={() => this.declineBidByGuid(this.state.token)}>
                            {this.state.translations.declineInstaller.button}
                        </Button>
                        <Warning>{this.state.translations.declineInstaller.warning}</Warning>
                    </Card>
                    </div>
                    <Footer 
                        phone
                        src={`${window.env.blobUrl}/qmerit/wordmark/png/Qmerit-Wordmark-IG400.png`}
                        alt="Qmerit"
                    />
                </div>
            )
        }

        if (!this.state.isLoading && this.state.bidConfirmed === 200 && this.state.declined === true && this.state.translations) {
            return (
                <InstallerDeclinedConfirmation translations={this.state.translations}/>
            )
        }

        if (!this.state.isLoading && this.state.bidConfirmed === false && (this.state.fechedBidData.Status === 'Cancelled' || this.state.fechedBidData.Status === 'Closed')) {
            return (<InstallerStatus translations={this.state.translations} installerStatus={this.state.fechedBidData.Status === "Closed" ? "Accepted" : this.state.fechedBidData.Status === "Cancelled" ? "Cancelled" : ''}/>)
        }

        if ([400, 404, 500].includes(this.state.bidConfirmed) || [400, 404, 500].includes(this.state.fetchBidStatus)) {
            return <TierUpdateFail translations={this.state.translations} country={this.state.country}/>
        }


        return (<Loader color={"#000000"} isLoading={this.state.isLoading} />)
    }
}

export default TieredConfirmationDeclined

