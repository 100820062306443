import React, { Component } from 'react';
import QuickCard from '../../../../components/QuickCard';
import Dropdown from '../../../../components/DropDown';
import FaceImg from '../../../../../../public/images/HappyFace.svg';
import QmeritLogo from '../../../../components/Powered-by-Qmerit-Indiqo-Grey';
import { Button } from '../../../../shared/style';
import { Wrapper } from '../../styles';
import StyledComponents from './styles';

const { CardContainer, Title, DropdownContainer } = StyledComponents;

class SelectedView extends Component {
    state = {
        selectedCompanies: null,
        disableSubmit: true
    }

    handleSelected = companies => {
        const selectedCompanies = [ companies ]
        this.setState({ selectedCompanies, disableSubmit: false })
    }

    render() {
        const { selectedCompanies, disableSubmit } = this.state
        const { companies, onSubmit } = this.props

        return (
            <Wrapper>
                <QuickCard maxWidth='480px'>
                    <CardContainer>
                        <img alt="happy-face" src={FaceImg} />
                        <Title>We're happy to hear that!</Title>
                        <DropdownContainer>
                            <Dropdown 
                                placeholder="Select a company..."
                                label="Who was selected to install your EV charger?"
                                data={companies}
                                selected={selectedCompanies}
                                setSelected={this.handleSelected}
                            />
                        </DropdownContainer>
                        <Button 
                            action={'action'} 
                            className={disableSubmit?"disabled":""}
                            disabled={disableSubmit}
                            onClick={() => onSubmit({ selectedCompanies })}>
                            Done
                        </Button>
                    </CardContainer>
                </QuickCard>
                <QmeritLogo />
            </Wrapper>
        )
    }
}

export default SelectedView;
