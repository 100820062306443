import React from "react";
import Message from "../../components/message";
import face from "../../../../public/images/face.svg";
import styledComponent from "../commonStyle";
const SavedChanges = () => {
  const { Icon, Heading, Description } = styledComponent;
  return (
    <Message>
      <Icon alt="done" src={face} />
      <Heading>Your changes have been saved!</Heading>
      <Description>
        Feel free to close this window, knowing that we’ll take good care of
        your stuff.
      </Description>
    </Message>
  );
};

export default SavedChanges;
