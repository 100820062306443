import React, { Fragment } from "react"
import StyledComponents from './styles'

const {
    GlobalDraftStyles,
    MainWrapper,
    Header,
    PoweredBy,
    CalloutWrapper,
    Callout,
    Message,
    MessageTitle,
    MessageContent,
    Icon,
    Footer,
    Support,
    SecondaryText,
    SupportAnchor
} = StyledComponents

const HasBidDraft = ({projectId, csEmail, csPhone}) => {
    return (
        <MainWrapper>
            <GlobalDraftStyles />
            <Header>
                <PoweredBy
                    src={`${window.env.blobUrl}/qmerit/Qmerit-Wordmark-Indiqo.png`}
                    alt="Qmerit" />
            </Header>
            <CalloutWrapper>
                <Callout>
                    <Icon className="fa-light fa-file-pen" />
                    <Message>
                        <MessageTitle>Your quote is being revised.</MessageTitle>
                        <MessageContent>Your local Qmerit certified installer is making updates to your installation quote and will send it to you shortly. You should receive an email once it's complete or check back here for an update.</MessageContent>
                    </Message>
                </Callout>
            </CalloutWrapper>
            <Footer>
                <Support>
                    <SecondaryText>Need assistance?</SecondaryText>
                    <SupportAnchor href={`mailto:${csEmail}`}>{csEmail}</SupportAnchor>
                    <SupportAnchor href={`tel:${csPhone}`}>{csPhone}</SupportAnchor>
                </Support>
                <SecondaryText>PROJECT ID: {projectId}</SecondaryText>
            </Footer>
        </MainWrapper>
    )
}


export default HasBidDraft;