import React, { PureComponent } from "react";
import { apiConfig } from "../../../lib/apiConfig";
import { getToken } from "../../../lib/getUserData";
import fetchData from "../../../lib/fetchData";
import Message from "../../../components/message";
import Wrapper from "../../../components/Wrapper";
import QuickCard from "../../../components/QuickCard";
// import styles from "./styles.module.css";
import Upload from "../../../components/Upload";
import BidChanged from "../../../features/BidStatus/BidChanged";
import BidAlreadySubmitted from "../../../features/BidStatus/BidAlreadySubmitted";
import _ from "lodash";
// import BidComponent from "../../../components/BidComponent";
import styledComponent from "./style";
class QuickSubmitQuote extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      formValues: {
        decimal: "",
        message: ""
      },
      uploadArray: [],
      disabled: false,
      fetchSuccess: false,
      showIntegerError: false,
      showEmptyError: false,
      showOver500: false,
      token: props.token,
      coreLink: ""
    };
    this.setUploadArray = this.setUploadArray.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    this.setState({
      token: "token=" + this.state.fetchedData.OperationGuids.Submit,
      coreLink: `${
        window.env && window.env.webRoot ? window.env.webRoot : "app.qmerit.com"
      }`
    });
  }

  submitForm() {
    this.decimalNumCheck();
    if (this.state.disabled === true) {
      return;
    } else if (this.state.formValues.decimal) {
      let actions = [],
        QuoteDecimal,
        QuoteFiles,
        QuoteMessage;
      _.map(
        this.props.fetchedData.Activities[0].Tasks.Actions,
        (action, index) => {
          if (action.Type === "Currency") {
            QuoteDecimal = action.Id;
          } else if (action.Type === "Upload") {
            QuoteFiles = action.Id;
          } else if (action.Type === "Text") {
            QuoteMessage = action.Id;
          }
        }
      );
      const options = {
        ...apiConfig.postConsumerInfo,
        query: "token=" + this.state.fetchedData.OperationGuids.Submit,
        body: {
          taskId: this.props.fetchedData.Activities[0].Tasks.Id,
          actions: [
            {
              Id: QuoteDecimal,
              CustomDecimal1: this.state.formValues.decimal
            },
            {
              Id: QuoteFiles,
              Files: this.state.uploadArray
            },
            {
              Id: QuoteMessage,
              CustomString1: this.state.formValues.message
            }
          ]
        }
      };
      fetchData(options);
      this.setState({
        fetchSuccess: true,
        showEmptyError: false
      });
    } else {
      this.setState({
        showEmptyError: true
      });
    }
  }

  disableEnterKey(event) {
    if (event.which === 13) {
      event.preventDefault();
    }
  }

  handleChange(event) {
    if (event.target.id === "quoteDecimal") {
      const test1 = /^\d+$/.test(event.target.value),
        test2 = /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{2}$/.test(
          event.target.value
        ),
        test3 = /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{1}$/.test(
          event.target.value
        ),
        test4 = /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{0}$/.test(
          event.target.value
        );
      if (test1 || test2 || test3 || test4) {
        this.changeValue("decimal", event.target.value);
        this.setState({
          showIntegerError: false,
          disabled: false,
          showEmptyError: false
        });
      } else {
        this.changeValue("decimal", event.target.value);
        this.setState({
          showIntegerError: true,
          disabled: true,
          showEmptyError: false
        });
      }
    }
    if (event.target.id === "quoteMessage") {
      if (event.target.value.length < 500) {
        this.changeValue("message", event.target.value);
      }
    }
  }

  changeValue(fieldName, value) {
    this.setState({
      formValues: {
        ...this.state.formValues,
        [fieldName]: value
      }
    });
  }

  setUploadArray(array) {
    this.setState({
      uploadArray: array
    });
  }

  decimalNumCheck() {
    if (this.state.formValues.decimal) {
      const test1 = /^\d+$/.test(this.state.formValues.decimal),
        test2 = /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{2}$/.test(
          this.state.formValues.decimal
        );

      let value = test1 === true || test2 === true ? false : true;

      if (test1) {
        let addDeci = `${this.state.formValues.decimal}${".00"}`;
        this.changeValue("decimal", addDeci);
      }
      this.setState({
        showIntegerError: value,
        disabled: value,
        showEmptyError: false
      });
    }
  }

  blurNumCheck() {
    if (this.state.formValues.decimal) {
      const test1 = /^\d+$/.test(this.state.formValues.decimal),
        test2 = /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{2}$/.test(
          this.state.formValues.decimal
        ),
        test3 = /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{1}$/.test(
          this.state.formValues.decimal
        ),
        test4 = /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{0}$/.test(
          this.state.formValues.decimal
        );

      let value = test1 === true || test2 === true ? false : true;

      if (test1) {
        let addDeci = `${this.state.formValues.decimal}${".00"}`;
        this.changeValue("decimal", addDeci);
      }
      this.setState({
        showIntegerError: value,
        disabled: value
      });
    }
  }

  render() {
    if (this.state.fetchSuccess) {
      return <BidChanged />;
    }
    const fetchedDataActivities = this.state.fetchedData.Activities[0];
    const {
      Root,
      QuoteTitle,
      QuoteForm,
      QuoteBRnum,
      QuoteSubTitle,
      DeciUpload,
      Button,
      QuoteButton,
      QuoteButtonSection,
      Textarea,
      QuoteOption2,
      DeciError,
      ShowIntegerWrapper,
      UsdSpan,
      Input,
      QuoteDollar,
      QuoteIcon,
      QuoteErrorContainer
    } = styledComponent;
    const inputError = this.state.showIntegerError || this.state.showEmptyError;
    return (
      <Root>
        <QuickCard>
          <div>
            <QuoteTitle href={this.state.coreLink}>
              {fetchedDataActivities
                ? fetchedDataActivities.Project &&
                  fetchedDataActivities.Project.Name
                : ""}
            </QuoteTitle>
            <QuoteBRnum>
              Bid Request #
              {fetchedDataActivities
                ? fetchedDataActivities.BidRequest &&
                  fetchedDataActivities.BidRequest.Id
                : ""}
            </QuoteBRnum>

            <QuoteForm onKeyPress={this.disableEnterKey}>
              <DeciUpload>
                <div>
                  <QuoteSubTitle>Total Estimated Cost</QuoteSubTitle>
                  <ShowIntegerWrapper showIntegerError={inputError}>
                    <QuoteDollar showIntegerError={inputError}>$</QuoteDollar>
                    <Input
                      inputRed={inputError}
                      id="quoteDecimal"
                      value={this.state.formValues.decimal}
                      onChange={e => this.handleChange(e)}
                    />
                    {/* <i className="fal fa-angle-down" /> */}
                    <UsdSpan showIntegerError={inputError}>
                      USD
                    </UsdSpan>
                  </ShowIntegerWrapper>
                  {inputError && (
                    <QuoteErrorContainer>
                      <QuoteIcon>
                        <i className="far fa-exclamation-circle" />
                      </QuoteIcon>
                      <DeciError>
                        Please enter{" "}
                        {this.state.showEmptyError ? "" : "a valid"} amount.
                      </DeciError>
                    </QuoteErrorContainer>
                  )}
                </div>

                <Upload setUploadArray={array => this.setUploadArray(array)} />
              </DeciUpload>

              <div>
                <QuoteSubTitle>
                  Bid Message
                  <QuoteOption2> - Optional</QuoteOption2>
                </QuoteSubTitle>
                <Textarea
                  id="quoteMessage"
                  value={this.state.formValues.message}
                  onChange={e => {
                    this.handleChange(e);
                  }}
                  rows="2"
                  placeholder="Enter any additional information you'd like the customer to know..."
                />
              </div>
              {this.state.showOver500 && (
                <QuoteErrorContainer>
                  <QuoteIcon>
                    <i className="far fa-exclamation-circle" />
                  </QuoteIcon>
                  <DeciError>Please enter a valid amount.</DeciError>
                </QuoteErrorContainer>
              )}
            </QuoteForm>
          </div>
          <div>
            <QuoteButtonSection>
              <QuoteButton>
                <Button
                  type="button"
                  disabled={this.state.disabled}
                  onClick={() => this.submitForm()}
                >
                  SUBMIT BID
                </Button>
              </QuoteButton>
            </QuoteButtonSection>
          </div>
        </QuickCard>
      </Root>
    );
  }
}
export default QuickSubmitQuote;
