import React, { useState, useEffect } from "react";
import _ from "lodash";
import styledComponents from "./style";
import rocket from '../../../../public/images/logistics_payment.svg'
import Loader from "../Loader";

const  {Container, InstallerConfirmedTitle ,InstallerConfirmedMessage } = styledComponents
 export default function DepositSuccess (props) {

    const [translations, setTranslations] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        if (_.has(window, 'env.translations') && !_.isEmpty(window.env.translations)) {
          setTranslations(window.env.translations)
          setIsLoading(false);
        };

    }, []);

    if(translations.depositReceived){
        return (
            <Container data-testid="depositSuccessContainer">
                <img alt="Oops" src={rocket}  />
                <InstallerConfirmedTitle>{translations.depositReceived.title}</InstallerConfirmedTitle>
                <InstallerConfirmedMessage>
                {translations.depositReceived.description}
                </InstallerConfirmedMessage>
            </Container>
        )
    } else {
        return (<Loader color={"#000000"} isLoading={isLoading} />) 
    }
    
}