import React, { PureComponent } from "react";
import styled from "styled-components";

class Modal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.getElementById("mainHeader") &&
      document.getElementById("mainHeader").classList.add("blured");
  }
  componentWillUnmount() {
    document.getElementById("mainHeader") &&
      document.getElementById("mainHeader").classList.remove("blured");
  }
  render() {
    return (
      <Wraper>
        <div
          className="back-drop"
          onClick={() => this.props.backDropClick && this.props.backDropClick()}
        />
        <Body maxWidth={this.props.maxWidth}>{this.props.content}</Body>
      </Wraper>
    );
  }
}
export default Modal;

export const Wraper = styled.div`
  position: fixed;
  z-index: 9999999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: auto;

  .back-drop {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }
`;
export const Body = styled.div`
  z-index: 999999;
  position: relative;
  background-color: #fefefe;
  margin: 0 auto;
  /*border: 1px solid #888;*/
  width: calc(100% - 24px);
  max-width: 700px;
  max-height: 100vh;
  /* overflow: auto; */
  @media (max-width: 767.98px) {
    max-width: 350px;
  }
  &::-webkit-scrollbar {
    width: 0;
  }
`;
