import React from 'react'
import styledComponents from "./styles";
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';

import Concepts from '../../components/Concepts';
import ApproveDeclineQuote from '../../components/ApproveDeclineQuote';
import DetailsComponent from '../../components/DetailsComponent';
import CancelBox from '../../components/CancelBox';
import QuoteApprovedButton from '../../components/QuoteApprovedButton';
import TaxExemption from '../../components/TaxExemption';

const {
  H1,
  Label_Field
} = commonStyles;

const {
  Sticky,
  TotalContainer,
  TotalDivider,
  CancelSection,
} = styledComponents;

export default function TotalSection({ fetchedBidData, browserLanguage, requestId, bidData, acceptBidByGuid, declineBidByGuid, handleContinue, translations, financingEnabled }) {

  return (
    <Sticky>
      <DetailsComponent
        fetchedBidData={fetchedBidData}
        browserLanguage={browserLanguage}
        bidData={bidData}
        translations={translations} />
      <TotalContainer>
        <H1>{translations.totalSection.total.title}</H1>
        {/*  Concept Common Component */}
        <Concepts fetchedBidData={fetchedBidData} translations={translations} />
        <TotalDivider />
        {/* Tax Exemption Section */}
        {fetchedBidData.Request?.TaxExemptionContractUrl &&
          <TaxExemption 
            TaxExemptionContractUrl={fetchedBidData.Request.TaxExemptionContractUrl} 
            screenSize={'desktop'} 
            translations={translations}/>
        }
        {bidData.Status !== 'confirmed' ?
          <ApproveDeclineQuote acceptBidByGuid={acceptBidByGuid} declineBidByGuid={declineBidByGuid} handleContinue={handleContinue} translations={translations} financingEnabled={financingEnabled} />
          :
          <QuoteApprovedButton translations={translations.totalSection.total.QuoteApproved.button} />
        }
      </TotalContainer>
      <CancelSection>
        <Label_Field>{translations.totalSection.total.cancel.message}</Label_Field>
        <CancelBox requestId={requestId} translations={translations} />
      </CancelSection>
    </Sticky>
  )
}
