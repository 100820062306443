import React, { Component } from "react";
import GloabalStyle from "./shared/gloabalStyle";
import Router from "./router";

import "./Fonts.css";
import "./App.css";
import "@fortawesome/fontawesome-pro/css/all.css";

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <GloabalStyle />
        <Router />
      </React.Fragment>
    );
  }
}

export default App;
