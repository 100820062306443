import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;

  .endCapPlaceHolder {
    visibility: hidden;
    opacity: 0;
    .p {
      color: #0B1C54;
    }
  }
`;

const SemiCircleProgress = styled.div`
  @keyframes progress {
    0% { --percentage: 0; }
    100% { --percentage: var(--val); }
  }

  @property --percentage {
    syntax: '<number>';
    inherits: true;
    initial-value: 0;
  }

  --size: 24px; // thickness of arc
  --secondary: #C2C6D4;
  --val: ${({ value }) => value > 100 ? 100 : value};
  --percentage: var(--val);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  width: 240px; //diameter
  aspect-ratio: 2 / 1;
  border-radius: 50% / 100% 100% 0 0; // makes a circle and set the border radius for each quadrant
  animation: progress 2s forwards;
  overflow: hidden;

  ::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      to right,
      #3967fe 0%,
      #008aa8 27.08%,
      #7bc342 51.56%,
      #ffb300 76.04%,
      #f4511e 100%
    );
    mask:
      radial-gradient(
        farthest-side at bottom,
        #0000 calc(99% - var(--size)),
        #000 calc(100% - var(--size))
      );
    -webkit-mask: 
      radial-gradient(
        farthest-side at bottom,
        #0000 calc(99% - var(--size)),
        #000 calc(100% - var(--size))
      );
  }

  ::after {
    content: '';
    position: absolute;
    inset: 0;
    background: conic-gradient(
      from 0.75turn at 50% 100%,
      #0000 calc(var(--percentage) * 1% / 2),
      var(--secondary) calc((var(--percentage) * 1% / 2) + 0.1%)
    );
    mask: radial-gradient(
      farthest-side at bottom,
      #0000 calc(99% - var(--size)),
      #000 calc(100% - var(--size))
    );
    -webkit-mask: radial-gradient(
      farthest-side at bottom,
      #0000 calc(99% - var(--size)),
      #000 calc(100% - var(--size))
    );
  }

  .middle {
    display: flex;
    flex-direction: column;
    text-align: center;

    .h1 {
      color: #FFF;
    }

    .h3 {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      color: rgba(255, 255, 255, 0.75);
      margin: 0px;
    }
  }
`;

const Mark = styled.div`
  aspect-ratio: 2 / 1;
  border-radius: 50% / 100% 100% 0 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 240px;
  background: conic-gradient(
    from 0.75turn at bottom,
    #0000 143deg,
    #000 144deg,
    #0000 145deg
  );
  mask: radial-gradient(at 50% 100%, white 56.5%, transparent 56.5%);
  mask-mode: alpha;
  -webkit-mask: radial-gradient(at 50% 100%, #0000 56.5%, #000 56.5%);
  -webkit-mask-mode: alpha;
`;

const MaxLoad = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 28px;
  left: 241px;

  .p {
    color: rgba(255, 255, 255, 0.75);
  }

  .pSm {
    color: rgba(255, 255, 255, 0.75);
    margin: 0px;
  }
`;

const Start = styled.div`
  width: 100%;
  position: absolute;
  color: rgba(255, 255, 255, 0.75);
  top: 124px;
  left: -15px;
`;

const End = styled(Start)`
  top: 124px;
  left: 188px;
`;

const SmallIconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 42px;
  left: 220px;

  i {
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: #B9BBC1;
  }
`;

export default {
  Container,
  SemiCircleProgress,
  Mark,
  MaxLoad,
  Start,
  End,
  SmallIconBox
};