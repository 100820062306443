import React, { Fragment, useState } from 'react';
import styledComponents from './styles';

const {
  Select,
  Input
} = styledComponents;

export function checkIfOptionIsDefault(obj1, obj2) {
  for (const key of Object.keys(obj1)) {
    if (obj1[key].description === obj2.description) {
      return true;
    }
  }
  return false;
}

export default function Dropdown({ options, defaultVal, handleChange }) {
  const [isDropDownActive, setIsDropDownActive] = useState(true);
  const [selectedOption, setSelectedOption] = useState(defaultVal);

  const handleSelection = event => {
    if (event.target.value === '-Custom Description-') {
      setIsDropDownActive(false);
    } else {
      handleChange(event);
      setSelectedOption(event.target.value);
    }
  }

  const handleBlur = event => {
    if (event.target.value === '') {
      handleChange(event);
      setIsDropDownActive(true);
      setSelectedOption('');
    } 
  }

  return (
    <Fragment>
      {isDropDownActive ?
        <Select id="heavyload" name="description" defaultValue={selectedOption} onChange={handleSelection} required>
          {options.map(breaker =>
            breaker.description === '' ?
              <option key={breaker.id} value={breaker.description} disabled hidden>Select description</option>
              :
              <option key={breaker.id} value={breaker.description}>{breaker.description}</option>
          )}
          {!checkIfOptionIsDefault(options, {description: selectedOption}) &&
            <option value={selectedOption} hidden>{selectedOption}</option>
          }
        </Select>
        :
        <Input 
          type="text"
          name="description"
          placeholder={'Type your description'}
          readOnly={false}
          defaultValue={''}
          onChange={handleSelection}
          onBlur={handleBlur}
          autoFocus
        />
      }
    </Fragment>
  )
}