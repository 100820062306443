import styled from 'styled-components';
import { mediaMax } from '../../shared/style';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: max-content;
  width: 1000px;
  gap: 48px;
  background-color: white;
  margin: 0px auto;
  padding: 136px 32px;

  @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;700;900&family=Red+Hat+Display:wght@400;500;700&display=swap');

  h1, h2, h3 {
    font-family: "Red Hat Display", sans-serif;
  } 

  p, a, td, th, input {
    font-family: "Mulish", sans-serif;
  }

  ${mediaMax.desktop`
    width: 100%;
    gap: 24px;
    padding: 68px 16px 88px;
  `};
`;

const Divider = styled.div`
  display: block;
  height: 1px;
  background: #DEDFE2;
`;

const QuoteDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const HideTotalWhenMobile = styled.div`
  ${mediaMax.desktop`
    display: none;
  `}
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0px auto;
  padding: 24px 0px;
  position: fixed;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(12px);
  font-family: "Mulish", sans-serif;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 1000px;
  padding: 0px 32px;
  gap: 24px;

  ${mediaMax.desktop`
    width: 100%;
    padding: 0px 16px;
  `};
`;

export default {
  Content,
  Divider,
  QuoteDetails,
  HideTotalWhenMobile,
  Footer,
  ButtonContainer
}