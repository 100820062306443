import React, {PureComponent} from 'react';
import _ from "lodash";

import Loader from "../../components/Loader";
import ParseUrl from '../../components/ParseUrl'
import BidStatusError from '../../components/Errors';
import Deposit from './components/deposit';
import DepositSuccess from '../../components/DepositSuccess'

class TieredRemainingBalance extends PureComponent {
    constructor(props) {
        super(props) ;
            this.state = {
                isLoading: true,
                installerStatus: false,
                installerConfirmed: true,
                token: "",
                fechedBidData: {},
                bidConfirmed: false,
                acceptedBy: '',
                remainingBalance: '',
                programCurrency: ''
            }
    }

    componentDidMount() {
        let bidGuid = this.getBidGuid(this.props.location.search);
        
        if (!_.isEmpty(bidGuid)) {
            this.setState({token: bidGuid, isLoading: false})
        } 
    }



    getBidGuid = (url) => {
        let x = ParseUrl(this.props.location.search, 'token');
        this.getRequest(x);   
        return x;
    }

    getRequest = (guid) => {
        this.setState({isLoading: true})

        let optionalParams = {
            headers: {
                "content-type": "application/json; charset=UTF-8", 
                // "Sec-Fetch-Mode": "no-cors", 
                // "Access-Control-Request-Headers": "*",
                // "Access-Control-Request-Method": "*",
                // "Access-Control-Allow-Origin": "*"
                "Subscription-Key": `${window.env.apiKey}`
                },
            method: "GET"
        }

        new Promise((resolve, reject) => {
            fetch(`${window.env.qmtUrl}/Request/${guid}`, optionalParams)
            .then(res => res.json())
                    .then(data => {
                console.log("data", data);
                this.setState({
                    remainingBalance: data.Settlement.RemainingBalance, 
                    isLoading: false,
                    programCurrency: data.ProgramCurrencyType,
                })
            })
            .catch(error => {
                reject({
                    isLoading: false,
                    fetchedData: { ErrorCode: 500, ...error }
                });
            })
        })
    }
    
    render() {

        if (!this.state.isLoading && (parseInt(this.state.remainingBalance) > 0)) {
            return (
                <div>
                    <Deposit 
                        remainingBalance={this.state.remainingBalance}
                        token={this.state.token}
                        currency={this.state.programCurrency}
                    />
                </div>

            )
        }

        if (!this.state.isLoading && !this.state.remainingBalance) {
            return (
                <div>
                    <DepositSuccess
                    />
                </div>

            )
        }      

        return (<Loader color={"#000000"} isLoading={this.state.isLoading} />)

    }
}


export default TieredRemainingBalance

