import styled, { css } from "styled-components";
import { theme, fade } from "../../../../../shared/style";

const Root = styled.div``;
const Req = styled.div`
  margin-bottom: 15px;
`;

export default {
  Root,
  Req
};
