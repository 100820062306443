import React, { PureComponent, Fragment } from "react";
import { apiConfig } from "../../lib/apiConfig";
import fetchData from "../../lib/fetchData";
import fetchImageUrl from "../../lib/fetchImageUrl";
import Dropzone from "react-dropzone";
import FileEncoder from "../../lib/fileEncoder";
import fileTypeHelper from "../../lib/fileTypeHelper";
import SupportFiles from "../../lib/supportedFileTypes";
import _ from "lodash";
import * as sharedStyledComponents from "../../shared/style";
import * as f from "../../shared/methods";
import styledComponents from "./style";

const extensions = [
  "text/rtf",
  "application/rtf",
  "text/plain",
  "text/csv",
  "image/jpeg",
  "image/png",
  "image/jpg",
  "application/pdf",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.ms-powerpoint",
  "application/vnd.oasis.opendocument.text",
  "application/vnd.oasis.opendocument.spreadsheet",
  "application/vnd.oasis.opendocument.presentation",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation"
];
class TierUpload extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      preview: "",
      previewArray: props.previewArray || [],
      previewURLArray: [],
      ContentType: "",
      fileType: "",
      type: {
        contentType: "",
        type: "",
        classname: ""
      }
    };
    this.onDrop = this.onDrop.bind(this);
  }

  setUploadArray = value => {
    this.setState({
      previewArray: value
    });
  };

  onDrop = files => {
    let uploadFile = files,
      previewURL = "";
      console.log("onDrop", files);
    if (uploadFile !== null) {
      const filesBase64 = _.map(uploadFile, (file, index) => {
        if (file.size > 10485760) return;
        return new Promise((resolve, reject) => {
          FileEncoder.base64(file, resolve);
        });
      });
      Promise.all(filesBase64).then(response => {
        _.map(response, (file, index) => {
          if (file !== undefined) {
            const fileType = fileTypeHelper.getFileType({
                file: uploadFile[index]
              }),
              imageData = file;

            if (!fileType) return;
            const body = {
              body: {
                ImageData: imageData,
                ContentType: fileType.fileType
              }
            };
            const options = this.props.readOnly
              ? { ...apiConfig.postRequirementsUploadImage, ...body }
              : { ...apiConfig.postUploadImage, ...body };
            this.fetchImagesUrl(options, uploadFile[index], fileType);
          }
        });
      });
    }
  };

  fetchImagesUrl(options, uploadFile, fileType) {
    fetchImageUrl(options).then(data => {
      let arr = this.props.readOnly ? [] : this.state.previewArray.slice();
      arr.push({
        contentType: fileType.contentType,
        Url: data.URL,
        FileType: uploadFile.type,
        Type: "Qmerit"
      });

      this.setState({
        previewArray: arr,
        preview: data.URL,
        ContentType: fileType.contentType,
        fileType: uploadFile.type,
        type: {
          contentType: fileType.contentType,
          type: fileType.type,
          classname: fileType.classname
        }
      });
      this.props.setUploadArray(this.state.previewArray);
    });
  }

  removeImage = index => {
    if (index > -1) {
      let doc = [].concat(this.state.previewArray);
      doc.splice(index, 1);
      this.setState({
        ...this.state,
        previewArray: doc
      });
      this.props.setUploadArray(doc);
    }
  };

  quoteDropSection = Q2 => {
    const {
      QuoteDrop,
      QuoteCircle,
      QuotePlus,
      UploadLabel,
      QuoteDropBody,
      QuoteInstructions
    } = styledComponents;

    const { noLable, bgColor } = this.props;
    return (
      <QuoteDrop Q2={Q2} bgColor={bgColor}>
        <div>
          <QuoteDropBody>
            <QuoteCircle Q2={Q2 || noLable}>
              <QuotePlus Q2={Q2 || noLable}>+</QuotePlus>
            </QuoteCircle>
            <UploadLabel Q2={Q2 || noLable}>Upload</UploadLabel>

                <Fragment>
                  <QuoteInstructions className="noMobile">
                    Drop or click to add
                  </QuoteInstructions>
                  <QuoteInstructions className="noDesktop">
                    Drop or click to add
                  </QuoteInstructions>
                </Fragment> 
          </QuoteDropBody>
        </div>
      </QuoteDrop>
    );
  };

  borderInput = (Q2, getRootProps, getInputProps) => {
    const { BorderInput, Input } = styledComponents;
    return (
      <BorderInput {...getRootProps}>
        {!Q2 && (
          <svg>
            <rect />
          </svg>
        )}
        <Input {...getInputProps} />
        {this.quoteDropSection(Q2)}
      </BorderInput>
    );
  };
  uploadIconFileWrapper = () => {
    const { UploadFileWrapper } = sharedStyledComponents;
    return (
      <Fragment>
        <i className="fas fa-file" />
        <UploadFileWrapper>
          <div>View</div>
          <div>Document</div>
        </UploadFileWrapper>
      </Fragment>
    );
  };

  quoteBoundingBox = (url, type, readOnly) => {
    const { Zeek } = styledComponents;
    const { Doc, QuoteBoundingBox, QuoteImages } = sharedStyledComponents;
    return (
      <QuoteBoundingBox type={type} href={url} target="_blank">
        {type !== "quoteImage" ? (
          <Doc type={type}>{this.uploadIconFileWrapper()}</Doc>
        ) : (
          <QuoteImages src={url} />
        )}
        {/* {!readOnly && (
          <Zeek>
            <i className="far fa-link" />
          </Zeek>
        )} */}
      </QuoteBoundingBox>
    );
  };

  previewSection = (getRootProps, getInputProps, readOnly) => {
    const {
      PreviewCol,
      QuotePreview,
      QuoteRemove,
      PreviewWrapper,
      Caption
    } = styledComponents;
    return (
      <PreviewWrapper>
        {!_.isEmpty(this.state.previewArray) &&
          _.map(this.state.previewArray, (preview, index) => {
            return (
              <PreviewCol
                key={index}
                singleRow={this.props.singleRow ? this.props.singleRow : false}
              >
                <QuotePreview QPrev={index % 2 === 0}>
                  {this.quoteBoundingBox(
                    preview.Url,
                    f.quotePreviewSection(preview),
                    readOnly
                  )}
                  {readOnly && (
                    <Caption warning={true}>
                      {preview.caption && preview.caption}
                    </Caption>
                  )}
                  <QuoteRemove onClick={() => this.removeImage(index)}>
                    REMOVE
                  </QuoteRemove>
                </QuotePreview>
              </PreviewCol>
            );
          })}
        {/* <PreviewCol singleRow={this.props.singleRow || false}>
          {this.borderInput(true, getRootProps, getInputProps)}
        </PreviewCol> */}
      </PreviewWrapper>
    );
  };

  render() {
    const { QuoteUploadSection, QuoteOption, QuoteSubTitle } = styledComponents;
    const { readOnly, multiple, label, noLable } = this.props;
    console.log("props", this.props);
    return (
      <QuoteUploadSection>
        {!readOnly &&
          (!noLable && (
            <QuoteSubTitle>
              {label ? `${label}` : "Bid Attachment"}
              <QuoteOption> - Optional</QuoteOption>
            </QuoteSubTitle>
          ))}
        <Dropzone
          multiple={multiple || false}
          accept={extensions}
          onDropAccepted={this.onDrop}
        >
          {({ getRootProps, getInputProps }) => {
            return (
              <div>
        
                  {this.borderInput(false, getRootProps(), getInputProps())}
                {!_.isEmpty(this.state.previewArray) &&
                  this.previewSection(
                    getRootProps(),
                    getInputProps(),
                    readOnly || false
                  )}
              </div>
            );
          }}
        </Dropzone>
      </QuoteUploadSection>
    );
  }
}

export default TierUpload;
