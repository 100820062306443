import React from "react";
import commonStyledComponents from "../../commanStyle";
import AlertWrapper from "./AlertWrapper";
import moment from "moment";

function getMoment(date) {
  //UTC& to local time conversion
  console.log(date + "Z", " UTC time");
  var localDate = new Date(date + "Z");
  console.log(localDate, " Local time");
  return {
    date: moment(localDate)
      .format("YYYY-MMM-DD")
      .toUpperCase(),
    time: moment(localDate).format("hh:mma")
  };
  // "2018–DEC–07 • 5:24pm"
}

// function getDate(status, data) {
//   let date;
//   if (status.status === "rejected") {
//     if (data.CompletedBy && data.CompletedBy.CompletedOn) {
//       date = getMoment(data.CompletedBy.CompletedOn);
//     } else if (data.Collateral && data.Collateral.Expiration) {
//       date = getMoment(data.Collateral.Expiration);
//     }
//   } else if (
//     status.status === "overridenExpiring" ||
//     status.status === "overridenExpired"
//   ) {
//     date = data.OverridenUntil && getMoment(data.OverridenUntil);
//   }
//   return date;
// }
function getPreviousNoteDate(status, data) {
  let date;
  if (status.status === "rejected") {
    if (data.CompletedBy && data.CompletedBy.CompletedOn) {
      date = getMoment(data.CompletedBy.CompletedOn);
    } else if (data.Collateral && data.Collateral.Expiration) {
      date = getMoment(data.Collateral.Expiration);
    }
  } else if (
    status.status === "overridenExpiring" ||
    status.status === "overridenExpired"
  ) {
    date = data.OverridenUntil && getMoment(data.OverridenUntil);
  }
  return date;
}
const Alert = props => {
  const { Text } = commonStyledComponents;
  const { status, data } = props,
    { PreviousNote } = data;
  const overrideUntillDate =
    data.OverridenUntil && getMoment(data.OverridenUntil);
  const previousNoteDate =
    PreviousNote.CreatedOn && getMoment(PreviousNote.CreatedOn);

  // const date = getDate(status, data);
  const AlertData = {
    type:
      status.status === "overridenExpiring"
        ? "primary"
        : (status.status === "overridenExpired" ||
            status.status === "rejected") &&
          "warning",
    heading:
      status.status === "rejected"
        ? "Rejected"
        : `Manually Overridden until ${overrideUntillDate.date}`,
    reasonTime: `${previousNoteDate.date} • ${previousNoteDate.time}`,
    reason: "Reason",
    data
  };
  return (
    <AlertWrapper data={AlertData}>
      {PreviousNote.Message &&
        PreviousNote.Message.length > 0 && <Text>{PreviousNote.Message}</Text>}
    </AlertWrapper>
  );
};
export default Alert;
