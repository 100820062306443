import React from 'react'
import styledComponents from './styles';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';

const {
    P,
    P_SM
} = commonStyles;

const {
    TaxExemptionContainer,
    TaxContentWrapper,
    TaxExemptionTextWrapper,
    CircleInfoIconWrapper,
    Divider
} = styledComponents;

export default function TaxExemption({ TaxExemptionContractUrl, screenSize, translations }) {

    return (
        <TaxExemptionContainer screenSize={screenSize}>
            <TaxContentWrapper screenSize={screenSize}>
                <CircleInfoIconWrapper screenSize={screenSize}>
                    <i className="far fa-triangle-exclamation" />
                </CircleInfoIconWrapper>
                <TaxExemptionTextWrapper>
                    {screenSize == 'desktop' ?
                        <>
                            <P black>{translations.taxExemption.message}</P>
                            <a href={TaxExemptionContractUrl} target='_blank'><P underline black>{translations.taxExemption.underlinedLink}</P></a>
                        </>
                        :
                        <>
                            <P_SM black>{translations.taxExemption.message}</P_SM>
                            <a href={TaxExemptionContractUrl} target='_blank'><P_SM underline black>{translations.taxExemption.underlinedLink}</P_SM></a>
                        </>
                    }
                </TaxExemptionTextWrapper>
            </TaxContentWrapper>
            <Divider />
        </TaxExemptionContainer>
    )
}
