
import React from "react"
import styledComponents from "./style";
import declineTieredBid from '../../../../../../public/images/declineTieredBid.png';
import bidUnavailable from '../../../../../../public/images/bidUnavailable.png'

const  {Container, ThanksForUpdate ,ThanksForUpdateText} = styledComponents
 export const AcceptDeclineBid = (props) => {
    if (props.status === 'Cancelled') {
        return (
            <Container>
                <img style={{height: 72}} alt="Bid Unavailable" src={bidUnavailable} />
                <ThanksForUpdate>This request is not available.</ThanksForUpdate>
                <ThanksForUpdateText>It seems this request is no longer available—it may have been cancelled or assigned to another company. </ThanksForUpdateText>            
            </Container>            
        )
    } else {
        return (
            <Container>
                <img style={{height: 72}} alt="Bid Declined" src={declineTieredBid} />
                <ThanksForUpdate>We're sorry you declined this opportunity.</ThanksForUpdate>
                <ThanksForUpdateText>Thank you for the update. Feel free to close this window.</ThanksForUpdateText>
            </Container>
        )
    }
}