import React from 'react';
import styledComponents from './styles';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';
import { getSum, formatNumber } from '../../helper';

const {
  P_MicroBold,
  P
} = commonStyles;

const {
  Content,
  LineItemStyle1,
  LineItemStyle2,
  IconBox
} = styledComponents;

export default function CalcContent({ general, appliances, heavyLoad, acValue1 }) {
  const subtotalA = getSum(new Array(general, appliances, heavyLoad));
  const subtotalB = ((subtotalA - 10000) * 0.4) + 10000;
  acValue1 = parseFloat(acValue1);
  return (
    <Content>
      <LineItemStyle1>
        <div className="header">
          <div className="namedColumn">
            <P_MicroBold>General Assumptions</P_MicroBold>
          </div>
          <div className="iconColumn">
            {' '}
          </div>
          <div className="namedColumn">
            <P_MicroBold>Standard Appliances</P_MicroBold>
          </div>
          <div className="iconColumn">
            {' '}
          </div>
          <div className="namedColumn">
            <P_MicroBold>Heavier Loads</P_MicroBold>
          </div>
          <div className="iconColumn">
            {' '}
          </div>
          <div className="namedColumn">
            <P_MicroBold>Subtotal A</P_MicroBold>
          </div>
        </div>
        <div className="totals">
          <div className="numberColumn">
            <P>{formatNumber(general)}</P>
          </div>
          <IconBox><i className="far fa-plus"/></IconBox>
          <div className="numberColumn">
            <P>{formatNumber(appliances)}</P>
          </div>
          <IconBox><i className="far fa-plus"/></IconBox>
          <div className="numberColumn">
            <P>{formatNumber(heavyLoad)}</P>
          </div>
          <IconBox><i className="far fa-equals"/></IconBox>
          <div className="numberColumn">
            <P>{formatNumber(subtotalA)}</P>
          </div>
        </div>
      </LineItemStyle1>
      <LineItemStyle2>
        <div className="header">
          <div className="namedColumn">
            <P_MicroBold>Subtotal A</P_MicroBold>
          </div>
          <div className="iconColumn">
            {' '}
          </div>
          <div className="namedColumn">
            <P_MicroBold className="pHidden">No col title</P_MicroBold>
          </div>
          <div className="iconColumn">
            {' '}
          </div>
          <div className="namedColumn">
            <P_MicroBold className="pHidden">No col title</P_MicroBold>
          </div>
        </div>
        <div className="totals">
          <div className="numberColumn">
            <P>{formatNumber(subtotalA)}</P>
          </div>
          <IconBox><i className="far fa-minus"/></IconBox>
          <div className="numberColumn">
            <P>{formatNumber(10000)}</P>
          </div>
          <IconBox><i className="far fa-equals"/></IconBox>
          <div className="numberColumn">
            <P>{formatNumber(subtotalA - 10000)}</P>
          </div>
        </div>
      </LineItemStyle2>
      <LineItemStyle2>
        <div className="header">
          <div className="namedColumn">
            <P_MicroBold className="pHidden">No col titles</P_MicroBold>
          </div>
        </div>
        <div className="totals">
          <div className="numberColumn">
            <P>{formatNumber(subtotalA - 10000)}</P>
          </div>
          <IconBox><i className="far fa-times"/></IconBox>
          <div className="numberColumn">
            <P>40</P>
            <P className="pGray">%</P>
          </div>
          <IconBox><i className="far fa-equals"/></IconBox>
          <div className="numberColumn">
            <P>{formatNumber((subtotalA - 10000) * 0.4)}</P>
          </div>
        </div>
      </LineItemStyle2>
      <LineItemStyle2>
        <div className="header">
          <div className="namedColumn">
            <P_MicroBold className="pHidden">No col title</P_MicroBold>
          </div>
          <div className="iconColumn">
            {' '}
          </div>
          <div className="namedColumn">
            <P_MicroBold className="pHidden">No col title</P_MicroBold>
          </div>
          <div className="iconColumn">
            {' '}
          </div>
          <div className="namedColumn">
            <P_MicroBold>Subtotal B</P_MicroBold>
          </div>
        </div>
        <div className="totals">
          <div className="numberColumn">
            <P>{formatNumber((subtotalA - 10000) * 0.4)}</P>
          </div>
          <IconBox><i className="far fa-plus"/></IconBox>
          <div className="numberColumn">
            <P>{formatNumber(10000)}</P>
          </div>
          <IconBox><i className="far fa-equals"/></IconBox>
          <div className="numberColumn">
            <P>{formatNumber(subtotalB)}</P>
          </div>
        </div>
      </LineItemStyle2>
      <LineItemStyle2>
        <div className="header">
          <div className="namedColumn">
            <P_MicroBold>Subtotal B</P_MicroBold>
          </div>
          <div className="iconColumn">
            {' '}
          </div>
          <div className="namedColumn">
            <P_MicroBold>A/C Load Watts</P_MicroBold>
          </div>
          <div className="iconColumn">
            {' '}
          </div>
          <div className="namedColumn">
            <P_MicroBold>Total Wattage</P_MicroBold>
          </div>
        </div>
        <div className="totals">
          <div className="numberColumn">
            <P>{formatNumber(subtotalB)}</P>
          </div>
          <IconBox><i className="far fa-plus"/></IconBox>
          <div className="numberColumn">
            <P>{formatNumber(acValue1)}</P>
          </div>
          <IconBox><i className="far fa-equals"/></IconBox>
          <div className="numberColumn">
            <P>{formatNumber(subtotalB + acValue1)}</P>
          </div>
        </div>
      </LineItemStyle2>
      <LineItemStyle2>
        <div className="header">
          <div className="namedColumn">
            <P_MicroBold>Total Wattage</P_MicroBold>
          </div>
          <div className="iconColumn">
            {' '}
          </div>
          <div className="namedColumn">
            <P_MicroBold>Volts</P_MicroBold>
          </div>
          <div className="iconColumn">
            {' '}
          </div>
          <div className="namedColumn">
            <P_MicroBold>Total Amperage</P_MicroBold>
          </div>
        </div>
        <div className="totals">
          <div className="numberColumn">
            <P>{formatNumber(subtotalB + acValue1)}</P>
          </div>
          <IconBox><i className="far fa-divide"/></IconBox>
          <div className="numberColumn">
            <P>{formatNumber(240)}</P>
          </div>
          <IconBox><i className="far fa-equals"/></IconBox>
          <div className="numberColumn">
            <P>{formatNumber((subtotalB + acValue1) / 240)}</P>
          </div>
        </div>
      </LineItemStyle2>
    </Content>
  )
}