import React from 'react';
import styledComponents from "./styles";

const {
  QuoteApprovedContainer
} = styledComponents;

export default function QuoteApprovedButton({ translations }) {
  return (
    <QuoteApprovedContainer>
      <i className="far fa-check" />
      {translations}
    </QuoteApprovedContainer>
  )
};