import styled, { css } from 'styled-components';
import {
  mediaMax,
  mediaMin
} from "../../shared/style";

const MainQuoteWrapper = styled.div`
/* common */
  @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;700;900&family=Red+Hat+Display:wght@400;500;700&display=swap');

  & p, a, li, span, td, th, button {
    font-family: "Mulish", sans-serif;
  }

  & h1, h2, h3 {
    font-family: "Red Hat Display", sans-serif;
  }

  height: max-content;
  background-color: white;
  padding: 88px 0px 0px;
  width: 100%;

  ${mediaMax.desktop`
   padding: 52px 0px 179px;
  `};
`;

const InfoWrapper = styled.div`
  display: flex;
  gap: 0px 48px;
  padding: 48px 100px 0px;
  min-height: calc(100vh - 486px);

  display:flex;
  justify-content: flex-start;

  background-color: white;

  ${mediaMax.desktop`
    flex-direction: column;
    padding: 16px 16px 0px 16px;
  `};

  ${mediaMax.tablet`
    gap: 0px 16px;
  `};
`;

const LeftColumn = styled.div`
  width: 1003px;

  ${mediaMax.desktop`
    width: 100%;
  `};

  ${mediaMin.desktop`
    min-width: 482px;
  `};

`;

const FinanceContainer = styled.div`
  width: 100%;
  color: #3E4148;

  .h2Body {
    margin-bottom: 24px;
  }

  .pBody {
    margin-bottom: 24px;
  }
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 477px;

  ${mediaMax.desktop`
    display: none;
  `};

  ${mediaMin.desktop`
    min-width: 322px;
  `};
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 24px;
  height: 68px;
  width: 1528px;
  padding: 24px 0px;

  ${mediaMax.desktop`
    display: none;
  `};
`;

const FooterMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 100%;
  gap: 24px;

  ${mediaMin.desktop`
    display: none;
  `};
`;

const FooterDivider = styled.div`
  display: block;
  height: 1px;
  background: #EDEEEF;
  margin-top: 100px;

  ${mediaMax.desktop`
    margin-top: 48px;
    height: 0px
  `};

`;

const FooterContainer = styled.div`
  display: flex;
  padding: 0px 24px;

  @media only screen and (max-width: 1728px) {
    padding: 0px 24px;
  }

  @media only screen and (max-width: 1166px) {
    padding: 0px 24px;
  }

  ${mediaMax.desktop`
    padding: 24px 24px 34px
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.75);
    border-top: 1px solid #DEDFE2;
    backdrop-filter: blur(12px);
  `};
`;



export default {
  MainQuoteWrapper,
  InfoWrapper,
  LeftColumn,
  FinanceContainer,
  RightColumn,
  Footer,
  FooterMobile,
  FooterDivider,
  FooterContainer
}