import _ from "lodash";

/* Filtering the Bids array to look for the one that it's not rejected or declined */
export function filterForActiveBid(bidsArr) {
  if(bidsArr){
    return bidsArr.filter((bid) => {
      return !["declined", "rejected", "cancelled"].includes(bid.Status);
    })
  } else {
    return null;
  }
}

/* Filtering the Bids array to look for the one that it's not rejected or declined and the Id matches the one in the params given*/
export function filterForActiveSpecificBid(bidsArr, Id) {
  if(bidsArr){
    return bidsArr.filter((bid) => {
      return !["declined", "rejected", "cancelled"].includes(bid.Status) && bid.BidId == Id;
    })
  } else {
    return null;
  }
}

/* Filtering the Bids array to look for the one that it's rejected or declined */
export function filterForDeclinedOrRejectedBid(bidsArr, Id) {
  return bidsArr.filter((bid) => {
    return ["declined", "rejected", "cancelled"].includes(bid.Status) && bid.BidId == Id;
  })
}

/* Filtering the Bids array to look for the one that and it's cancelled */
export function filterForCancelledBid(bidsArr, Id) {
  return bidsArr.filter((bid) => {
    return ["cancelled"].includes(bid.Status) && bid.BidId == Id;
  })
}

//Getting Bid's Confirmed On date

/* Since filtering provides us with a new array with only one element in this case, we're gonna 
pick  the element [0] so we can take the actual Confirmed On date from it */
export function getConfirmedOnDate(requestData, status, Id) {
  let currentBidArr = []
  if(["declined", "rejected", "cancelled"].includes(status)){
    currentBidArr = filterForDeclinedOrRejectedBid(requestData.Bids, Id);
  } else {
    currentBidArr = filterForActiveBid(requestData.Bids);
  }
  return (currentBidArr && !_.isEmpty(currentBidArr)) ? currentBidArr[0].ConfirmedOn : null;
}

//Getting Bid's Submitted On date

/* Since filtering provides us with a new array with only one element in this case, we're gonna 
pick  the element [0] so we can take the actual Confirmed On date from it */
export function getBidSubmittedOnDate(requestData, status, Id) {
  let currentBidArr = []
  if(["declined", "rejected", "cancelled"].includes(status)){
    currentBidArr = filterForDeclinedOrRejectedBid(requestData.Bids, Id);
  } else {
    currentBidArr = filterForActiveBid(requestData.Bids);
  }
  return (currentBidArr && !_.isEmpty(currentBidArr)) ? currentBidArr[0].SubmittedOn : null;
}

//Getting Bid's Accepted On date

/* Since filtering provides us with a new array with only one element in this case, we're gonna 
pick  the element [0] so we can take the actual Confirmed On date from it */
export function getBidAcceptedOnDate(requestData, status, Id) {
  let currentBidArr = []
  if(["declined", "rejected", "cancelled"].includes(status)){
    currentBidArr = filterForDeclinedOrRejectedBid(requestData.Bids, Id);
  } else {
    currentBidArr = filterForActiveBid(requestData.Bids);
  }
  return (currentBidArr && !_.isEmpty(currentBidArr)) ? currentBidArr[0].AcceptedOn : null;
}

//Getting Bid's Reviewed On date

/* Since filtering provides us with a new array with only one element in this case, we're gonna 
pick  the element [0] so we can take the actual Confirmed On date from it */
export function getReviewedOnDate(requestData, status, Id) {
  let currentBidArr = []
  if(["declined", "rejected", "cancelled"].includes(status)){
    currentBidArr = filterForDeclinedOrRejectedBid(requestData.Bids, Id);
  } else {
    currentBidArr = filterForActiveBid(requestData.Bids);
  }
  return (currentBidArr && !_.isEmpty(currentBidArr)) ? currentBidArr[0].ReviewedOn : null;
}

//Getting Bid's Revised On date based on the Id

/* Since filtering provides us with a new array with only one element in this case, we're gonna 
pick  the element [0] so we can take the actual Confirmed On date from it */
export function getRevisedOnDate(requestData, status, Id) {
  let currentBidArr = []
  if(["declined", "rejected", "cancelled"].includes(status)){
    currentBidArr = filterForDeclinedOrRejectedBid(requestData.Bids, Id);
  } else {
    currentBidArr = filterForActiveSpecificBid(requestData.Bids, Id);
  }
  return (currentBidArr && !_.isEmpty(currentBidArr)) ? currentBidArr[0].RevisedOn : null;
}

//Getting Active Bid's Revised On date

/* Since filtering provides us with a new array with only one element in this case, we're gonna 
pick  the element [0] so we can take the actual Confirmed On date from it */
export function getCurrentStatusCardRevisedOnDate(requestData, status, Id) {
  let currentBidArr = []
  if(["declined", "rejected", "cancelled"].includes(status)){
    currentBidArr = filterForDeclinedOrRejectedBid(requestData.Bids, Id);
  } else {
    currentBidArr = filterForActiveBid(requestData.Bids);
  }
  return (currentBidArr && !_.isEmpty(currentBidArr)) ? currentBidArr[0].RevisedOn : null;
}

//Getting Bid's Declined On date

/* Since filtering provides us with a new array with only one element in this case, we're gonna 
pick  the element [0] so we can take the actual Confirmed On date from it */
export function getDeclinedOnDate(requestData, status, Id) {
  let currentBidArr = []
  if(["declined", "rejected", "cancelled"].includes(status)){
    currentBidArr = filterForDeclinedOrRejectedBid(requestData.Bids, Id);
  } else {
    currentBidArr = filterForActiveSpecificBid(requestData.Bids, Id);
  }
  return (currentBidArr && !_.isEmpty(currentBidArr)) ? currentBidArr[0].DeclinedOn : null;
}

//Getting Bid's Rejected On date

/* Since filtering provides us with a new array with only one element in this case, we're gonna 
pick  the element [0] so we can take the actual Confirmed On date from it */
export function getRejectedOnDate(requestData, status, Id) {
  let currentBidArr = []
  if(["declined", "rejected", "cancelled"].includes(status)){
    currentBidArr = filterForDeclinedOrRejectedBid(requestData.Bids, Id);
  } else {
    currentBidArr = filterForActiveSpecificBid(requestData.Bids, Id);
  }
  return (currentBidArr && !_.isEmpty(currentBidArr)) ? currentBidArr[0].RejectedOn : null;
}

//Getting Bid's Cancelled On date

/* Since filtering provides us with a new array with only one element in this case, we're gonna 
pick  the element [0] so we can take the actual Confirmed On date from it */
export function getCancelledOnDate(requestData, status, Id) {
  let currentBidArr = []
  currentBidArr = filterForCancelledBid(requestData.Bids, Id);
  return (currentBidArr && !_.isEmpty(currentBidArr)) ? currentBidArr[0].CancelledOn : null;
}

//Finding out if a Bid needs Fleet Manager review

/* Since filtering provides us with a new array with only one element in this case, we're gonna 
pick  the element [0] so we can take the actual Confirmed On date from it */
export function getBidNeedsReview(requestData, status) {
  let currentBidArr = []
  if(["declined", "rejected", "cancelled"].includes(status)){
    currentBidArr = filterForDeclinedOrRejectedBid(requestData.Bids);
  } else {
    currentBidArr = filterForActiveBid(requestData.Bids);
  }
  return (currentBidArr && !_.isEmpty(currentBidArr)) ? currentBidArr[0].NeedsReview : null;
}

//Getting Active Bid's Status

/* Since filtering provides us with a new array with only one element in this case, we're gonna 
pick  the element [0] so we can take the actual Confirmed On date from it */
export function getActiveBidStatus(requestData, status, Id) {
  let currentBidArr = []
  if(["declined", "rejected", "cancelled"].includes(status)){
    currentBidArr = filterForDeclinedOrRejectedBid(requestData.Bids);
  } else {
    currentBidArr = filterForActiveBid(requestData.Bids);
  }
  return (currentBidArr && !_.isEmpty(currentBidArr)) ? currentBidArr[0].Status : null;
}

//Getting Bid's Service Provider Data (Name, Phone and Email)

/* Since filtering provides us with a new array with only one element in this case, we're gonna 
pick  the element [0] so we can take the actual Confirmed On date from it */
export function getBidServiceProviderName(requestData, status, Id) {
  let currentBidArr = []
  if(["declined", "rejected", "cancelled"].includes(status)){
    currentBidArr = filterForDeclinedOrRejectedBid(requestData.Bids, Id);
  } else {
    currentBidArr = filterForActiveBid(requestData.Bids);
  }
  return (currentBidArr && !_.isEmpty(currentBidArr)) ? currentBidArr[0].ServiceProviderName : null;
}

/* Since filtering provides us with a new array with only one element in this case, we're gonna 
pick  the element [0] so we can take the actual Confirmed On date from it */
export function getBidServiceProviderPhone(requestData, status, Id) {
  let currentBidArr = []
  if(["declined", "rejected", "cancelled"].includes(status)){
    currentBidArr = filterForDeclinedOrRejectedBid(requestData.Bids, Id);
  } else {
    currentBidArr = filterForActiveBid(requestData.Bids);
  }
  return (currentBidArr && !_.isEmpty(currentBidArr)) ? currentBidArr[0].ServiceProviderPhone : null;
}

/* Since filtering provides us with a new array with only one element in this case, we're gonna 
pick  the element [0] so we can take the actual Confirmed On date from it */
export function getBidServiceProviderEmail(requestData, status, Id) {
  let currentBidArr = []
  if(["declined", "rejected", "cancelled"].includes(status)){
    currentBidArr = filterForDeclinedOrRejectedBid(requestData.Bids, Id);
  } else {
    currentBidArr = filterForActiveBid(requestData.Bids);
  }
  return (currentBidArr && !_.isEmpty(currentBidArr)) ? currentBidArr[0].ServiceProviderEmail : null;
}

//Getting Bid Id

export function getBidToken(requestData, status) {
  let currentBidArr = []
  if(["declined", "rejected", "cancelled"].includes(status)){
    currentBidArr = filterForDeclinedOrRejectedBid(requestData.Bids);
  } else {
    currentBidArr = filterForActiveBid(requestData.Bids);
  }
  return (currentBidArr && !_.isEmpty(currentBidArr)) ? currentBidArr[0].Id : null;
}

