import React from 'react';
import StyledComponents from './styles';
import _ from 'lodash';
import { getQuoteUrl } from './utils';
import { getBidToken } from '../../../helpers/BidsDates';

const { Card, TitleBox, IconBox, TitleIcon, TitleText, Subtitle, Details, DetailsText, ViewQuoteButton } =
  StyledComponents;

export default function QuoteApprovedCard({ requestData, confirmedOnDate, translations, show }) {
  if (!show) return null;

  const id = getBidToken(requestData);

  const viewQuoteButtonUrl = requestData.QuoteBuilderCustomerUiEnabled
    ? getQuoteUrl(requestData)
    : `${window.location.origin}/installation-quote?&token=${id}`;

  return (
    <React.Fragment>
      <Card>
        <React.Fragment>
          <TitleBox>
            <IconBox>
              <TitleIcon className="far fa-stamp" />
            </IconBox>
            <TitleText>{translations.title}</TitleText>
          </TitleBox>
          <Subtitle>
            {confirmedOnDate.formattedDate} {translations.dateTimeSeparator} {confirmedOnDate.formattedTime}
          </Subtitle>
          <Details>
            <DetailsText>{translations.description}</DetailsText>
            <ViewQuoteButton
              as="a"
              href={viewQuoteButtonUrl}
              target="_blank"
              disabled={requestData.Status == 'Canceled'}
            >
              {translations.button}
            </ViewQuoteButton>
          </Details>
        </React.Fragment>
      </Card>
    </React.Fragment>
  );
}
