import React from 'react';
import styledComponents from './styles';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';
import { formatNumber } from '../../helper';

const {
  P,
  P_SM,
  H1
} = commonStyles;

const {
  Container,
  SemiCircleProgress,
  Mark,
  MaxLoad,
  Start,
  End,
  SmallIconBox
} = styledComponents;

export default function SemiCircleProgressBar({ totalAmp, mainBreakerAmp }) {
  const percent = (totalAmp / mainBreakerAmp) * 100;

  return (
    <Container>
      <SmallIconBox>
        <i className="far fa-caret-left" alt="left caret" />
      </SmallIconBox>
      <MaxLoad>
        <P_SM className="pSm">Max. Load</P_SM>
        <P className="p">{formatNumber(mainBreakerAmp * .8)} amps</P>
      </MaxLoad>
      <SemiCircleProgress
        role="progressbar"
        aria-valuenow={percent}
        aria-valuemin="0"
        aria-valuemax={mainBreakerAmp}
        value={percent}
      >
        <div className="middle">
          <H1 className="h1">{formatNumber(totalAmp)}</H1>
          <h3 className="h3">amps</h3>
        </div>
      </SemiCircleProgress>
      <Mark />
      <div className="endCapPlaceHolder">
        <P className="p">0 amps</P>
      </div>
      <Start>
        <P>0 amps</P>
      </Start>
      <End>
        <P>{mainBreakerAmp} amps</P>
      </End>
    </Container>
  );
}