import React, {PureComponent} from 'react';
import styledComponents from "./style";
import _ from "lodash";

import Wrapper from "../../components/Wrapper";
import Loader from "../../components/Loader";
import DropDown from '../../../common/components/DropDown'



import {TierUpdateFail} from '../tierManage/components/TierUpdateFail';
import {InstallerConfirmed} from '../TieredConfirmationAccepted/components/InstallerConfirmed';

class UserAcceptanceTest extends PureComponent {
    constructor(props){
        super(props)

        this.state = {
            isLoading: true,
            name: '',
            firstName: '',
            lastName: '',
            email: '',
            address: '',
            tierUATSucces: 0,
            tierWebhookSucce: 0,
            guid: '',
            tierByNocToken: {},
            selectedTier: "TIER1",
            translations: {}
        }
    }

    componentDidMount() {
      this.getTierByNocToken("TIEREDUAT")

      if (_.has(window, 'env.translations') && !_.isEmpty(window.env.translations)) {
        this.setState({
            translations: window.env.translations
        })
    }
    }

    createConsumer = () => {
      this.setState({isLoading:true})
        let data = {
            FirstName: this.state.firstName,
            LastName: this.state.lastName,
            Phone: "1234567890",
            Email: this.state.email,
            Street: "10688 Willow Fishhook Rd",
            City: "Wasilla",
            StateId: 2,
            Zip: "99654",
            Address: "",
            ContactMethod: "email",
            Notes: "",
            NOCToken: "TIEREDUAT",
            Data: {
                Questions: [
                    {
                        Question: "Do you own or rent?",
                        Answer: "Own"
                    },
                    {
                        Question: "Where will the charger be installed?",
                        Answer: "Single Family Home"
                    },
                    {
                        Question: "Do you need to get permission from your Home Owner Association (HOA)?",
                        Answer: "I already have approval or I do not need approval"
                    },
                    {
                        Question: "What model vehicle do you have?",
                        Answer: "test model vehicle"
                    },
                    {
                        Question: "When do you want your charger installed?",
                        Answer: "Immediately"
                    }
                ]
            },
            process: true,
            Code: this.state.selectedTier.toUpperCase().split(' ').join(''),
        }
        let optionalParams = {
            headers: {"content-type": "application/json; charset=UTF-8", "Subscription-Key": `${window.env.apiKey}`},
            body: JSON.stringify(data),
            method: "POST"
        }

        new Promise((resolve, reject) => {
            fetch(`${window.env.qmtUrl}/Consumer`, optionalParams)
            .then(res => {
                this.setState({tierUATSucces: res.status})
                return res.json();
            })
            .then(data => {
                let projectGuid = data.split('guid=')
                if (!_.isEmpty(projectGuid)) {
                    this.setState({guid: projectGuid[1]})
                    this.createTypeForm(projectGuid[1])
                }
            })
            .catch(error => {
                reject({
                    isLoading: false,
                    fetchedData: { ErrorCode: 500, ...error }
                });
            })
        })

    }


    createTypeForm = (guid) => {
        let data = {
                event_id: "01EQEQDF464WCGMAWQPGSXZWJJ",
                event_type: "form_response",
                form_response: {
                  form_id: "B9Ah1s",
                  token: "0swaechifb37esi0swaecho1z8h8bs55",
                  landed_at: "2020-11-18T22:25:05Z",
                  submitted_at: "2020-11-18T22:25:47Z",
                  hidden: {
                    guid: guid,
                  },
                  calculated: {
                    score: 0
                  },
                  variables: [
                    {
                      key: "score",
                      type: "number",
                      number: 0
                    }
                  ],
                  definition: {
                    id: "B9Ah1s",
                    title: "EV Readiness Review v3.4 - PRC",
                    fields: [
                      {
                        id: "aqNM6LnD9OFV",
                        title: "Did you already purchase a Level 2, 240v charger?",
                        type: "multiple_choice",
                        ref: "90637249-7ed8-429e-becb-169c249e1312",
                        properties: {},
                        choices: [
                          {
                            id: "w324p3bjHxV8",
                            label: "Yes, I have a Level 2 charger that I need installed"
                          },
                          {
                            id: "bVybCNzz5jeE",
                            label: "No, but I plan on buying my charger separately"
                          },
                          {
                            id: "Y3HjsJ6LIJGV",
                            label: "No, I'd like the installer to include the charger in my bid"
                          }
                        ]
                      },
                      {
                        id: "ZSOnaHqDwSbB",
                        title: "What brand and model charger?",
                        type: "short_text",
                        ref: "2f1d7344-9fa6-406c-9b79-65e0da480dc3",
                        properties: {}
                      },
                      {
                        id: "PZ8LfMVYKMhL",
                        title: "Where will the charger be installed?",
                        type: "multiple_choice",
                        allow_other_choice: true,
                        ref: "f0881d34-0f4c-480a-ad39-fca4002b8b41",
                        properties: {},
                        choices: [
                          {
                            id: "YQv1yaEoizYK",
                            label: "In my garage"
                          },
                          {
                            id: "P7eIlrnlvYr6",
                            label: "On an outside wall of my home or garage"
                          }
                        ]
                      },
                      {
                        id: "u8OkRHCG0s9a",
                        title: "Approximately, when was your home built?",
                        type: "multiple_choice",
                        ref: "cb72bbb7-ccdb-47c9-94b4-8b182bac0a7c",
                        properties: {},
                        choices: [
                          {
                            id: "brPOARqoHJKd",
                            label: "Before 1990"
                          },
                          {
                            id: "e7YMiAw051fT",
                            label: "1991 to 2000"
                          },
                          {
                            id: "gwNueVH1Sdmh",
                            label: "2001 to 2010"
                          },
                          {
                            id: "lO1Ir2vJ6f7T",
                            label: "2011 to date"
                          }
                        ]
                      },
                      {
                        id: "QjU1aqo1Qg1H",
                        title: "How is your home's Wi-Fi Signal strength where you want the charger installed?",
                        type: "multiple_choice",
                        ref: "39269f12-101f-4725-9c05-78ef61a22ddd",
                        properties: {},
                        choices: [
                          {
                            id: "lo9i30lhtuGS",
                            label: "Excellent"
                          },
                          {
                            id: "OGV47YMs7Jxw",
                            label: "Good"
                          },
                          {
                            id: "dRxuLd7KcByA",
                            label: "Poor"
                          },
                          {
                            id: "UTuW5xriFaHR",
                            label: "No Coverage"
                          }
                        ]
                      },
                      {
                        id: "L4zWj5Nk93gQ",
                        title: "Do you have an existing high voltage (240v) outlet you would like to use?",
                        type: "multiple_choice",
                        ref: "9899251233f6e990",
                        properties: {},
                        choices: [
                          {
                            id: "d3LWlnAIKrD1",
                            label: "Yes, there's a high voltage outlet near where I'd like to install the charger"
                          },
                          {
                            id: "vUqedF3AWaiX",
                            label: "No, there's no close high voltage outlet"
                          }
                        ]
                      },
                      {
                        id: "FLAS7dmHjjMt",
                        title: "How many electrical panels do you have?",
                        type: "multiple_choice",
                        ref: "413ef57a-2133-4cd1-a6a1-da5e67e9a206",
                        properties: {},
                        choices: [
                          {
                            id: "EVlqV4QUvoyJ",
                            label: "One"
                          },
                          {
                            id: "iZ33iFVjRBhM",
                            label: "Two"
                          }
                        ]
                      },
                      {
                        id: "UzSNosdAHsYy",
                        title: "Where is your electrical panel located?",
                        type: "multiple_choice",
                        allow_other_choice: true,
                        ref: "58731c974bddcc5b",
                        properties: {},
                        choices: [
                          {
                            id: "w31zeF3Ukayw",
                            label: "Inside the garage"
                          },
                          {
                            id: "FfQQGVSz9w5M",
                            label: "Inside my home"
                          },
                          {
                            id: "jbkqmrqj8lQw",
                            label: "On an outside wall"
                          },
                          {
                            id: "foEE5CfaR8Uu",
                            label: "In the basement"
                          },
                          {
                            id: "Bbr5s9l4LgOo",
                            label: "I don't know"
                          }
                        ]
                      },
                      {
                        id: "iZkE9AWjWYia",
                        title: "How far will the charger be from the closest electrical panel?",
                        type: "multiple_choice",
                        allow_other_choice: true,
                        ref: "055f1a62-83aa-49fb-88b6-465d4cba2e61",
                        properties: {},
                        choices: [
                          {
                            id: "OZTkcco0QLsT",
                            label: "Under 10 feet"
                          },
                          {
                            id: "WOnf7uoO505z",
                            label: "Between 10 to 30 feet"
                          },
                          {
                            id: "G0DeDI6g6dIO",
                            label: "More than 30 feet"
                          },
                          {
                            id: "YPto3lBmvPyO",
                            label: "I don't know"
                          }
                        ]
                      },
                      {
                        id: "L039Q0qRpgpx",
                        title: "Anything else that you think installers should know?",
                        type: "long_text",
                        ref: "dcdd7abb9df2625b",
                        properties: {}
                      }
                    ]
                  },
                  answers: [
                    {
                      type: "choice",
                      choice: {
                        label: "Yes, I have a Level 2 charger that I need installed"
                      },
                      field: {
                        id: "aqNM6LnD9OFV",
                        type: "multiple_choice",
                        ref: "90637249-7ed8-429e-becb-169c249e1312"
                      }
                    },
                    {
                      type: "text",
                      text: "JAG",
                      field: {
                        id: "ZSOnaHqDwSbB",
                        type: "short_text",
                        ref: "2f1d7344-9fa6-406c-9b79-65e0da480dc3"
                      }
                    },
                    {
                      type: "choice",
                      choice: {
                        label: "In my garage"
                      },
                      field: {
                        id: "PZ8LfMVYKMhL",
                        type: "multiple_choice",
                        ref: "f0881d34-0f4c-480a-ad39-fca4002b8b41"
                      }
                    },
                    {
                      type: "choice",
                      choice: {
                        label: "Before 1990"
                      },
                      field: {
                        id: "u8OkRHCG0s9a",
                        type: "multiple_choice",
                        ref: "cb72bbb7-ccdb-47c9-94b4-8b182bac0a7c"
                      }
                    },
                    {
                      type: "choice",
                      choice: {
                        label: "Excellent"
                      },
                      field: {
                        id: "QjU1aqo1Qg1H",
                        type: "multiple_choice",
                        ref: "39269f12-101f-4725-9c05-78ef61a22ddd"
                      }
                    },
                    {
                      type: "choice",
                      choice: {
                        label: "Yes, there's a high voltage outlet near where I'd like to install the charger"
                      },
                      field: {
                        id: "L4zWj5Nk93gQ",
                        type: "multiple_choice",
                        ref: "9899251233f6e990"
                      }
                    },
                    {
                      type: "choice",
                      choice: {
                        label: "One"
                      },
                      field: {
                        id: "FLAS7dmHjjMt",
                        type: "multiple_choice",
                        ref: "413ef57a-2133-4cd1-a6a1-da5e67e9a206"
                      }
                    },
                    {
                      type: "choice",
                      choice: {
                        label: "Inside the garage"
                      },
                      field: {
                        id: "UzSNosdAHsYy",
                        type: "multiple_choice",
                        ref: "58731c974bddcc5b"
                      }
                    },
                    {
                      type: "choice",
                      choice: {
                        label: "Under 10 feet"
                      },
                      field: {
                        id: "iZkE9AWjWYia",
                        type: "multiple_choice",
                        ref: "055f1a62-83aa-49fb-88b6-465d4cba2e61"
                      }
                    },
                    {
                      type: "text",
                      text: "JAG",
                      field: {
                        id: "L039Q0qRpgpx",
                        type: "long_text",
                        ref: "dcdd7abb9df2625b"
                      }
                    }
                  ]
                }
        }
        let optionalParams = {
            headers: {"content-type": "application/json; charset=UTF-8", "Subscription-Key": `${window.env.apiKey}`},
            body: JSON.stringify(data),
            method: "POST"
        }

        new Promise((resolve, reject) => {
            fetch(`${window.env.qmtUrl}/Webhook/typeform`, optionalParams)
            .then(res => {
                this.setState({tierWebhookSucce: res.status, isLoading: false})
            })
            .catch(error => {

                reject({
                    isLoading: false,
                    fetchedData: { ErrorCode: 500, ...error }
                });
            })
        })

    }

    getTierByNocToken = (nocToken) => {
      let optionalParams = {
          headers: {"content-type": "application/json; charset=UTF-8"},
          method: "GET"
      }

      return new Promise((resolve, reject) => {
                    fetch(`https://svcint.qmerit.com:7800/api/SurveyMap/${nocToken}`, optionalParams)
                    .then(res => res.json())
                    .then(data => {
                        this.setState({tierByNocToken: data.Tiers, isLoading: false})
                    })
                    .catch(error => {
                        reject({
                            isLoading: false,
                            fetchedData: { ErrorCode: 500, ...error }
                        });
                    })
      })
  }

    handleUATPriceInputEmail = (event) => {
        event.persist();
        this.setState({email: event.target.value})
    }

    handleUATPriceInputName = (event) => {
        event.persist();
        let fullName = event.target.value.split(' ')
        this.setState({name: event.target.value,firstName: fullName[0], lastName: fullName[1]})
    }

    handleTierSelection = event => {
      this.setState({ selectedTier: event });
    };
  


    render() {
        const {
            Card,
            UATTitle,
            InputContainer,
            InputLabel,
            UATInput,
            UATButton,
        } = styledComponents;

        console.log("PROJECT ID",this.state.guid)
        if (!this.state.isLoading && !_.isEmpty(this.state.tierByNocToken)) {
          return (
            <Wrapper name="" company="">
                <Card>
                    <UATTitle>Generate a test request…</UATTitle>

                    <InputContainer>
                        <InputLabel name='name'>Customer Name</InputLabel>
                        <UATInput 
                            placeholder={""}
                            value={this.state.name} 
                            onChange={this.handleUATPriceInputName}/>
                    </InputContainer>

                    <InputContainer>
                        <InputLabel name='email'>Customer Email</InputLabel>
                        <UATInput 
       
                            onChange={this.handleUATPriceInputEmail}
                            placeholder={""} />
                    </InputContainer>

                    <InputContainer>
                        <InputLabel>Customer Site</InputLabel>
                        <UATInput disabled placeholder={"10688 Willow Fishhook Rd, Wasilla 99654"} />
                    </InputContainer>
                      <DropDown
                        label={'Tier'}
                        selected={this.state.selectedTier}
                        setSelected={this.handleTierSelection}
                        data={this.state.tierByNocToken.map((tier, id) => tier.Name)} />
                <UATButton onClick={this.createConsumer}>CREATE REQUEST</UATButton>
            </Card>
        </Wrapper>
        );

        }


        if (this.state.tierUATSucces === 400 || this.state.tierUATSucces === 404 || this.state.tierWebhookSucce === 400 || this.state.tierWebhookSucce === 404) {
            return <TierUpdateFail translations={this.state.translations}/>
        }

        if (this.state.tierUATSucces === 200 && this.state.tierWebhookSucce === 200) {
            return (
              <InstallerConfirmed  translations={this.state.translations}/>
            )
        }

        return (<Loader color={"#000000"} isLoading={this.state.isLoading} />)
    }
    
}

export default UserAcceptanceTest;