import React, { Component } from "react";
import range from "lodash/range";
import chunk from "lodash/chunk";
import styled from "styled-components";

const Day = ({ i, w, d, className, active, ...props }) => {
  return (
    <td id="calender" className={`${active === 2 ? "active" : ""}`} {...props}>
      <DayValue className={`${d === i && active === 2 ? "active" : ""}`}>
        {i}
      </DayValue>
    </td>
  );
};

const weeks = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: 0,
      datesForMap: []
    };
  }

  componentDidMount() {
    this.setInitialStates();
  }

  componentWillReceiveProps(nextProps) {
    const state = this.state;
    if (
      state.selectedDate === 0 ||
      state.selectedDate !== nextProps.selectDate
    ) {
      this.setInitialStates();
    }
  }

  setInitialStates = () => {
    const m = this.props.moment,
      d = m.date(),
      d1 = m
        .clone()
        .subtract(1, "month")
        .endOf("month")
        .date(),
      d2 = m
        .clone()
        .date(1)
        .day(),
      d3 = m
        .clone()
        .endOf("month")
        .date(),
      days =
        42 - d3 - d2 > 6
          ? [].concat(
              range(d1 - d2 + 1, d1 + 1),
              range(1, d3 + 1),
              range(1, 35 - d3 - d2 + 1)
            )
          : [].concat(
              range(d1 - d2 + 1, d1 + 1),
              range(1, d3 + 1),
              range(1, 42 - d3 - d2 + 1)
            );
    this.setState({
      selectedDate: d,
      datesForMap: days
    });
  };

  selectDate = (i, active) => {
    const m = this.props.moment;
    if (active === 1) m.subtract(1, "month");
    if (active === 3) m.add(1, "month");
    m.date(i);
    this.setState({ selectedDate: m.date() });
    this.props.onChange(m);
  };

  mapCalenderComponent = (calenderMap, d) => {
    return calenderMap.map((row, w) => {
      return (
        <tr key={w}>
          {row.map(i => {
            let active = 1;
            if (w === 0 && i > 7) {
              active = 1;
            } else if (w === calenderMap.length - 1 && i < 8) {
              active = 3;
            } else {
              active = 2;
            }
            return (
              <Day
                active={active}
                key={i}
                i={i}
                d={d}
                w={w}
                onClick={() => this.selectDate(i, active)}
              />
            );
          })}
        </tr>
      );
    });
  };

  mapWeekDays = () => {
    return (
      <tr>
        {weeks.map((w, i) => (
          <td key={i}>
            <div>{w}</div>
          </td>
        ))}
      </tr>
    );
  };

  render() {
    let calenderMap = chunk(this.state.datesForMap, 7);
    return (
      <div>
        <Table>
          <thead>{this.mapWeekDays()}</thead>
          <tbody>
            {this.mapCalenderComponent(calenderMap, this.state.selectedDate)}
          </tbody>
        </Table>
      </div>
    );
  }
}
export default Calendar;
const Table = styled.table`
  display: flex;
  flex-wrap: wrap;
  thead {
    width: 100%;
    background-color: #2b4dac;
  }
  thead td {
    height: 24px;
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
  }
  tbody {
    width: 100%;
  }
  tr {
    width: 100%;
    display: flex;
    text-align: center;
  }
  td {
    max-width: 14.28%;
    height: 44px;
    line-height: 1.43;
    flex: 0 0 14.28%;
    align-items: center;
    display: flex;
    justify-content: center;
    @media (max-width: 576.98px) {
      height: 40px;
    }
  }

  tbody td {
    color: #b9bbc1;
    font-size: 14px;
    font-weight: 400;
    &.active {
      color: #3e4148;
      background: #fff;
    }
  }
`;
const DayValue = styled.div`
  position: relative;
  &.active {
    &::before {
      content: "";
      position: absolute;
      width: 32px;
      height: 32px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: solid 2px #b2ebf2;
      border-radius: 100%;
    }
  }
`;
