import React from 'react'
import styledComponents from './styles';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';
import formatter from '../../../../lib/formatter';

const {
  H2,
  P_Grayed,
  P_RightAligned
} = commonStyles;

const {
  Details,
  DetailBox,
} = styledComponents;

export default function DetailsComponent({fetchedBidData, browserLanguage, bidData, translations}) {
  
  const SurveySubmittedOn = formatter.getIntlFormattedDateTime(bidData.SubmittedOn, browserLanguage, fetchedBidData.Request.TimeZone.timeZoneId);

  return (
    <Details>
      <H2>{translations.totalSection.details.title}</H2>
      {fetchedBidData.Request.QuoteBuilderEnabled &&
        <DetailBox>
          <P_Grayed>{translations.totalSection.details.projectId}</P_Grayed>
          <P_RightAligned>#{fetchedBidData.ProjectId}</P_RightAligned>
        </DetailBox>
      }
      <DetailBox>
        <P_Grayed>{translations.totalSection.details.quoteNo}</P_Grayed>
        <P_RightAligned>
          #
          {fetchedBidData.BidList[0].QuoteId && fetchedBidData.Request.QuoteBuilderEnabled ? 
            fetchedBidData.BidList[0].QuoteId 
            : 
            fetchedBidData.ProjectId && !fetchedBidData.Request.QuoteBuilderEnabled ?
              fetchedBidData.ProjectId
              :
              ''
          }
        </P_RightAligned>
      </DetailBox>
      <DetailBox>
        <P_Grayed>{translations.totalSection.details.estimateDate}</P_Grayed>
        <P_RightAligned>{SurveySubmittedOn.longDateWithoutDay}</P_RightAligned>
      </DetailBox>
    </Details>
  )
}
