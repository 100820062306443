import React from "react";
import styled from "styled-components";
import docThumbnail from "../../../../public/images/document.png";
import { mediaMin, mediaMax, theme } from "../../shared/style";
export default props =>
  props.attachments && (
    <Root>
      {props.attachments.map((data, inx) => (
        <AttachmentContainer key={inx}>
          <Attachment>
            <a href={data.Url} target="_blank">
              <AttachmentImg
                src={(props.thumbnail && docThumbnail) || data.Url}
              />
            </a>
          </Attachment>
          {data.Name && <Text>{data.Name}</Text>}
        </AttachmentContainer>
      ))}
    </Root>
  );

const Root = styled("div")`
  margin-bottom: 24px
  display: flex;
  flex-wrap: wrap;
`;

const AttachmentContainer = styled("div")`
  width: 100%;
  flex: 0 0 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  ${mediaMax.tablet`flex-direction: column;`};
`;

const Attachment = styled("div")`
  background: #fafafa;
  width: 120px;
  height: 120px;
  position: relative;
  z-index: 0;
  ${mediaMin.tablet`margin-right: 16px;`};
  ${mediaMax.tablet`margin-bottom: 8px;`};
`;

const AttachmentImg = styled(`img`)`
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100%;
  max-height: 100%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.14),
    0px 2px 2px 0px rgba(0, 0, 0, 0.12), 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
`;

const Text = styled("p")`
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: ${theme.Primary.main};
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  flex: 1;
`;
