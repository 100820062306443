import styled from 'styled-components';
import { mediaMax, mediaMin } from '../../../../shared/style';

const DeleteItem = styled.button`
  border: none;
  background: #FFF;
  padding: 0px;
  cursor: pointer;

  ${mediaMax.tablet`
    ${props => props.mobile == true ? `display: inline; background: var(--ig-ig-10,#F4F5F6);` : `display: none` }
  `}
  ${mediaMin.tablet`
    ${props => props.mobile && `display: none` }
  `}
`;

const IconBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;

  ${mediaMax.tablet`
    width: 100%;
    justify-content: flex-start;
    gap: 4px;
  `}

  i {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #B9BBC1;
  }
`;

const P_Micro_Grayed = styled.p`
/* common */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0px;
  color: #6E727C;

  ${mediaMin.tablet`
    display: none;
  `}
`;

export default {
  DeleteItem,
  IconBox,
  P_Micro_Grayed
}