export function iconData(submit, verify, status) {
  return submit
    ? !verify
      ? {
          icon: "shield-check",
          dec: "Completed"
        }
      : {
          icon: "check-double",
          dec: "Pending verification…"
        }
    : {
        icon: status.icon,
        dec: status.showStatus
      };
}
