import styled from 'styled-components';

const ModalContainer = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;700;900&family=Red+Hat+Display:wght@400;500;700&display=swap');
  
  & p, a, li, span, td, th, button, label {
    font-family: "Mulish", sans-serif; 
  }

  & h1, h2, h3 {
    font-family: "Red Hat Display", sans-serif;
    color: #3E4148;
  }

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 600px;
  max-width: 100%;
  padding: 24px;
  flex-direction: column;
  gap: 24px;
  border-radius: 8px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14), 0px 4px 5px 0px rgba(0, 0, 0, 0.12), 0px 1px 10px 0px rgba(0, 0, 0, 0.20);
  text-align: start;

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .button {
    display: flex;
    justify-content: center;
  }
`;

const IconBox = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  i {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #B9BBC1;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #3E4148;
    margin: 0px;

    i {
      color: #B9BBC1;
    }
  }

  .radios {
    gap: 4px;
  }

  .reason {
    width: 100%;
    padding: 0px 40px;
    gap: 8px;
  }
`;

const TextArea = styled.textarea`
  display: flex;
  padding: 12px;
  width: 100%;
  height: 76px;
  max-height: 100%;
  padding: 12px;
  align-items: flex-start;
  border-radius: 4px;
  border: ${({ highlight }) => highlight ? '1px solid #3963FE' : '1px solid #DEDFE2'};
  box-shadow: ${({ highlight }) => highlight ? 'box-shadow: 0px 0px 4px 0px rgba(57, 99, 254, 0.25)' : 'none'};
  background: #FAFAFA;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #3E4148;
  letter-spacing: normal;
`;

export default {
  ModalContainer,
  Content,
  IconBox,
  TextArea
}