import React, { Component } from 'react';
import StyledComponents from './styles';
import AttachmentPreview from '../../../../components/AttachmentPreview';
import { Button } from '../../../../shared/style';
import { Banner } from "@q-core/components/lib/general";
import formatter from "../../../../lib/formatter";

const {
    Root,
    CorpInfo,
    Header,
    Description,
    Detail,
    Details,
    ContactDetail,
    QuickBidWrapper
} = StyledComponents;

class AcceptBid extends Component {
    convertUTCDate = (date) => {
        const localDate = formatter.getDateTime(date)
        return `${localDate.formattedDate}, ${localDate.formattedTime}`;
    }

    addressLink = (location) => {
        const address = `${location.Address} ${location.City}, ${location.State} ${location.Zip}`

        return (
            <a
                href={`https://www.google.com/maps/search/?api=1&query=${address}`}
                target="_blank"
                rel="noopener noreferrer"
            >
                <span className="link">
                    {location.Address} <br/> 
                    {location.City}, {location.State} {location.Zip}
                </span>
            </a>
        )
    }

    emailLink = (email) => (
        <a
            href={`mailto:${email}`}
            title="Email Address"
        >
            <span className="link">
                {email}
            </span>
        </a>
    )

    phoneLink = (phone) => {
        return (
            <a
                href={`tel:${phone}`}
                title="Phone Number"
            >
                <span className="link">
                    {phone}
                </span>
            </a>
        )
    }

    handleActionClick = () => {
        this.props.onActionClick({
            view: 'submit', 
            header: { subTitle: 'accepted', status: 'success' }
        })
    }
    

    render() {
        const { activity, location, quickBid } = this.props;

        return (
            <Root>
                <Header>
                    <CorpInfo>
                        <img src={activity.Owner.ImageUrl} alt="owner-logo" />
                        <p>{activity.Owner.DBAName}</p>
                    </CorpInfo>
                    <p className="bid">Bid #{activity.BidRequest.Id}</p>
                </Header>
                <Description>
                    {activity.Description}
                </Description>
                {activity 
                    && activity.BidRequest 
                    && activity.BidRequest.Attachments 
                    && activity.BidRequest.Attachments.length > 0 &&
                    <AttachmentPreview activity={activity.BidRequest} />
                }
                <Details>
                    <Detail className="customer-contact">
                        Customer Contact
                        <h3>{activity.Customer.Name}</h3>
                        <ContactDetail>
                            {this.phoneLink(activity.Customer.Contact.PhoneNumber)}
                            {activity.Customer.Contact.PreferredMethod === "phone" && <i className="preferred-contact">Preferred Contact</i>}
                        </ContactDetail>
                        <ContactDetail>
                            {this.emailLink(activity.Customer.Contact.Email)}
                            {activity.Customer.Contact.PreferredMethod !== "phone" && <i className="preferred-contact">Preferred Contact</i>}
                        </ContactDetail>
                    </Detail>
                    <Detail className="site">
                        Site
                        {this.addressLink(location)}
                    </Detail>
                    <Detail className="due">
                        Due
                        <span>{this.convertUTCDate(activity.BidRequest.DueDate)}</span>
                    </Detail>
                    <Detail className="priority">
                        Priority
                        <span>{activity.Priority.Description}</span>
                    </Detail>
                    <Detail className="created">
                        Created
                        <span>{this.convertUTCDate(activity.CreatedOn)}</span>
                    </Detail>
                    <Detail className="site">
                        Accepted
                        <span>{this.convertUTCDate(activity.AcceptedBy.AcceptedOn)}</span>
                        <span>by {activity.AcceptedBy.Name}</span>
                    </Detail>
                </Details>
                <QuickBidWrapper>
                    {!quickBid ? (
                        <Button 
                            primary
                            fWidth
                            onClick={this.handleActionClick}
                        >
                            Bid Now
                        </Button>
                    ) : (
                        <h3>Your bid was submitted. Thanks!</h3>
                    )}
                </QuickBidWrapper>
                {activity.OwnerProgram.ExternalData && 
                    <Banner
                        {...activity.OwnerProgram.ExternalData}
                        className="banner"
                    />
                }
            </Root>
        )
    }
}

export default AcceptBid;
