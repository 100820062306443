import styled from 'styled-components';

const BackBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 24px;
  background-color: #FFF;
  position: sticky;
  top: 0;
  z-index: 1;

  .back {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    color: #6E727C;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    text-transform: uppercase;
    cursor: pointer;
  }

  i {
    color: #B9BBC1;
    cursor: pointer;
  }
`;

const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;

  i {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;

export default {
  BackBar,
  IconBox
}