import styled from "styled-components";

const Document = styled.div`
    margin: 8px 0;

    a:first-of-type {
        background-color: #fafafa;
        width: 120px;
        height: 120px;
        float: left;
    }
    
    img {
        object-fit: contain;
        width: 93px;
        height: 120px;
        margin-left: 14px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12),
          0 0 2px 0 rgba(0, 0, 0, 0.14);
    }

    h4 {
        margin-left: 16px;
        padding-top: 24px;
        margin-bottom: 24px;
        float: left;
        font-size: 16px;
        font-weight: bold;
        color: #00bcd4;
    }
`

export default {
    Document
};