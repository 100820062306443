import React from "react";
import commonStyledComponents from "../../commanStyle";

const RenderButton = props => {
  const { Button, ButtonHolder } = commonStyledComponents;
  return (
    <ButtonHolder onlyMobile={props.cancel}>
      <Button
        onClick={() => (props.check ? props.click() : {})}
        className={props.type}
      >
        {props.text}
      </Button>
    </ButtonHolder>
  );
};

const FooterWrapper = props => {
  const { Footer, ButtonWrapper, VerifiedText } = commonStyledComponents;
  const check =
    (props.uploadArray && props.uploadArray !== null ? true : false) ||
    (props.fieldValue ? props.fieldValue !== "" : false) ||
    props.confirmation;
  const typeCancel = check || !props.showEdit ? "grey" : "";
  return (
    <Footer className={`${props.verified && "normal"}`}>
      {props.verified ? (
        <VerifiedText>Verified on {props.dateTime.date}</VerifiedText>
      ) : (
        <React.Fragment>
          <ButtonWrapper>
            <RenderButton
              click={props.cancel}
              type={typeCancel}
              text="cancel"
              cancel={!props.editMode ? true : false}
              check
            />
            <RenderButton
              click={!props.showEdit ? props.submitChanges : props.edit}
              type={check ? (!props.showEdit ? "green" : "grey active") : ""}
              text={!props.showEdit ? "Submit" : "EDIT..."}
              cancel={false}
              check
            />
          </ButtonWrapper>
        </React.Fragment>
      )}
    </Footer>
  );
};
export default FooterWrapper;
