import React, { Fragment } from "react";
import styledComponents from "./style";
import commonStyledComponents from "../commanStyle";
import Switch from "../../../../../components/Switch";
import Message from "../Components/Message";
import Collapse from "../Components/Collapse";
import Alert from "../Components/Alert";
import Error from "../Components/Error";
import FooterWrapper from "../Components/FooterWrapper";
import * as cf from "../methods";

export default class RequirementField extends React.PureComponent {
  constructor(props) {
    super(props);
    const CustomString =
      props.requirementData &&
      props.requirementData.Data &&
      props.requirementData.Data.CustomString1;
    this.state = {
      ...props,
      fieldValue: CustomString || "",
      // commentValue: "",
      resultantFieldValue: CustomString || "",
      // resultantCommentValue: "",
      // isChecked: false,
      // addComment: false,-
      submit: false,
      showEdit: CustomString ? true : false,
      editMode: false,
      rejected: false,
      fieldError: false
    };
  }

  fieldChange = e => {
    e.preventDefault();
    const value = e.target.value;
    if (value.length > 500) {
      return;
    }
    this.setState({ fieldValue: e.target.value });
  };
  // commentChange = e => {
  //   this.setState({ commentValue: e.target.value });
  // };
  // addComment = () => {
  //   this.setState({ addComment: true });
  // };
  // toggleSwitch = () => {
  //   this.setState({ isChecked: !this.state.isChecked });
  // };
  submit = () => {
    const { fieldValue } = this.state;
    const fieldFilled = fieldValue !== undefined && fieldValue !== "";
    if (fieldFilled) {
      const options = {
        body: {
          RequirementId: this.props.requirementData.Id,
          CustomString1: fieldValue
        }
      };
      this.setState(
        {
          submit: true,
          showEdit: true,
          editMode: false,
          fieldError: false,
          resultantFieldValue: fieldValue
          // resultantCommentValue: this.state.commentValue
        },
        () => {
          this.collapse.toggle();
          this.props.updateRequirements(options);
        }
      );
    } else {
      this.setState({
        fieldError: true
      });
    }
  };

  edit = () => {
    this.setState({ showEdit: false, editMode: true });
  };

  cancel = () => {
    this.setState(
      {
        editMode: false,
        showEdit: true,
        fieldError: false,
        fieldValue: this.state.resultantFieldValue
        // commentValue: this.state.resultantCommentValue
      },
      () => {
        this.collapse.toggle();
      }
    );
  };
  render() {
    const {
      Text,
      InputWraper,
      Label,
      Input,
      // Textarea,
      // RequirementsApply,
      InputBox,
      Note,
      // Comment,
      SmallText,
      Content
      // CommentSection,
    } = commonStyledComponents;

    const state = this.state,
      { Root } = styledComponents,
      { requirementData, status, submit, fieldError, fieldValue } = state,
      iconData = cf.iconData(
        submit,
        requirementData.VerificationRequired,
        status
      ),
      headerDetail = {
        ...iconData,
        title: requirementData.Name
      };
    const showAlert =
      status.status === "overridenExpiring" ||
      status.status === "overridenExpired" ||
      status.status === "rejected";

    return (
      <Collapse headerDetail={headerDetail} ref={ref => (this.collapse = ref)}>
        <div>
          <Message bigMsg={true} requirementData={{ ...requirementData }} />
          <Content>
            <Root>
              {showAlert && <Alert status={status} data={requirementData} />}
              {!state.showEdit ? (
                <Fragment>
                  <Text spaceBottom={15}>
                    <b>Fill out the info below to complete this requirement</b>
                  </Text>
                  <InputBox>
                    <InputWraper>
                      <Label>{requirementData.FieldLabel}</Label>
                      <Input onChange={this.fieldChange} value={fieldValue} />
                    </InputWraper>
                    {fieldError && <Error text="This field is required." />}
                  </InputBox>
                  {/* <RequirementsApply bottomSpace={13}>
                  <Switch
                    text="This requirement does not apply to our company"
                    toggleSwitch={this.toggleSwitch}
                    isChecked={state.isChecked}
                  />
                </RequirementsApply> */}
                  {/* {this.state.addComment ? (
                  <InputWraper>
                    <Label>Comment</Label>
                    <Textarea
                      onChange={this.commentChange}
                      value={state.commentValue}
                    />
                  </InputWraper>
                ) : (
                  <Comment onClick={() => this.addComment()}>
                    Add a comment…
                  </Comment>
                )} */}
                  {requirementData.VerificationRequired && (
                    <Note spaceTop={15} spaceBottom={-8}>
                      <i className="far fa-info-circle" />
                      This requirement will be verified by your connection.
                    </Note>
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  <SmallText>{requirementData.FieldLabel}</SmallText>
                  <Text>{fieldValue}</Text>
                  {/* {state.commentValue && (
                      <CommentSection>
                        <SmallText>Comment</SmallText>
                        <Text>{state.commentValue}</Text>
                      </CommentSection>
                    )} */}
                </Fragment>
              )}
            </Root>
          </Content>
        </div>
        <FooterWrapper
          cancel={this.cancel}
          submitChanges={this.submit}
          edit={this.edit}
          {...state}
        />
      </Collapse>
    );
  }
}
