import styled, { css } from "styled-components";
import { theme, primaryButton, mediaMax } from "../../../shared/style";
import { Article, Section } from "../../../components/QuickCard";

const gap = 24;

const noMargin = css`
  margin: 0;
`;

const anchorHover = css`
  cursor: pointer;
  transition: 0.3 opacity;
  &:hover {
    opacity: 0.7;
  }
`;

const Root = styled.div`
${Section}{
    ${mediaMax.phone`
   padding-top:0;
`}
}
${Article}{
    ${mediaMax.phone`
        background-color: transparent;
        box-shadow: none;
        margin-top: 0px;
    `}
`;
const Wraper = styled.div`
  ${mediaMax.phone`
    min-height: calc(100vh - 108px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top:16px;
    position:relative;
    &::before{
        content:'';
        position:absolute;
        top:0;
        left:-16px;
        right:-16px;
        bottom:-16px;
        background-color:#fff;
        z-index:-1;
    }
`};
`;
const Back = styled.div`
  display: inline-flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  transition: 0.3s opacity;
  margin-bottom: ${gap}px;
  &:hover {
    opacity: 0.7;
  }
  ${mediaMax.phone` margin-bottom: ${16}px;`} i {
    color: ${theme.Primary.main};
    font-size: 16px;
    line-height: 1.25;
    margin-right: 8px;
  }
  i {
    ${mediaMax.phone`
    color: #fff;
    opacity:.5;
    `};
  }
  p {
    margin: 0;
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 0.5px;
    color: ${theme.Primary.main};
    ${mediaMax.phone`color:#fff`};
  }
`;

const Title = styled.h2`
  ${noMargin};
  font-family: "Montserrat", cursive;
  font-size: 18px;
  font-weight: bold;
  color: #3e4148;
  ${mediaMax.phone`font-size: 16px;`};
`;

const Activity = styled.p`
  margin-top: 0;
  margin-bottom: ${gap}px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33;
  color: ${theme.IndiqoGrey[600]};
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Col = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: ${gap}px;
  ${mediaMax.phone`
    flex: 0 0 100%;
    max-width: 100%;`};
`;

const NearbyLabel = styled.h4`
  ${noMargin};
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${theme.IndiqoGrey[600]};
  ${props =>
    props.faclity &&
    css`
      margin-top: 3px;
    `};
`;

const content = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  -webkit-font-smoothing: antialiased;
`;

const NearbyLabelContent = styled.h3`
  ${noMargin};
  ${content};
  color: ${theme.IndiqoGrey[800]};
  letter-spacing: 0.5px;
`;

const NearbyLabelAddress = styled.a`
  ${noMargin};
  ${content};
  ${anchorHover};
  color: ${theme.Primary.main};
`;

const TimeLeft = styled.p`
  ${noMargin};
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
  -webkit-font-smoothing: antialiased;
  color: ${theme.Warning[700]};
`;
const Connect = styled.a`
  ${anchorHover};
  margin-top: 3px;
  display: inline-flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  color: #00bcd4;
  svg {
    ${props =>
      props.call &&
      css`
        width: 21.33px;
        height: auto;
        margin-right: 8px;
      `} ${props =>
      props.email &&
      css`
        width: 19.21px;
        height: auto;
        margin-right: 9px;
      `};
  }
`;
const Button = styled.button`
  ${primaryButton};
  text-transform: uppercase;
  min-height: 40px;
  min-width: 200px;
  margin-top: 20px;
  ${mediaMax.phone`width:100%;max-width:300px;`};
`;

export default {
  Root,
  Back,
  Wraper,
  Title,
  Activity,
  Row,
  Col,
  NearbyLabel,
  NearbyLabelContent,
  TimeLeft,
  Connect,
  NearbyLabelAddress,
  Button
};
