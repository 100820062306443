import React, { useState } from 'react';
import styledComponents from './styles';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';
import ToggleButton from '../../components/ToggleButton';

const {
  P
} = commonStyles;

const {
  Panel,
  ImageContainer,
  Img,
  ImageZoom,
  ImageOptions,
  TextContainer
} = styledComponents;

export default function PanelZoom({ image, analyticImage }) {
  const [[x, y], setXY] = useState([0, 0]);
  const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
  const [showZoom, setShowZoom] = useState(false);
  const [toggle, setToggle] = useState(true);

  const handleMouseMove = event => {
    const elem = event.currentTarget;
    const { top, left } = elem.getBoundingClientRect();
    const x = event.pageX - left - window.scrollX;
    const y = event.pageY - top - window.scrollY;
    setXY([x, y]);
  }

  const handleMouseEnter = event => {
    const elem = event.currentTarget;
    const { width, height } = elem.getBoundingClientRect();
    setSize([width, height]);
    setShowZoom(true);
  }

  const handleMouseLeave = () => {
    setShowZoom(false);
  }

  return (
    <Panel>
      <ImageContainer>
        <Img name="panel" src={toggle ? image : analyticImage} onMouseEnter={handleMouseEnter} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave} />
        {showZoom &&
          <ImageZoom image={toggle ? image : analyticImage} imgWidth={imgWidth} imgHeight={imgHeight} x={x} y={y} />
        }
      </ImageContainer>
      <TextContainer>
        <P>Hover over image to zoom in</P>
        <ImageOptions>
          <ToggleButton toggle={toggle} setToggle={setToggle} onText={'Original'} offText={'Analyzed'} />
        </ImageOptions>
      </TextContainer>
    </Panel>
  )
}