export const detectmob = () => {
  if (navigator.userAgent.match(/Android/i)) {
    return "android";
  } else if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
    return "iOS";
  } else {
    let isFirefox = typeof InstallTrigger !== "undefined";
    let isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (p => {
        return p.toString() === "[object SafariRemoteNotification]";
      });

    let isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    if (isChrome) {
      return "dChrome";
    } else if (isFirefox) {
      return "dFirefox";
    } else if (isSafari) {
      return "dSafari";
    }
  }
};
