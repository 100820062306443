/* 
    This file can be used for styles specific to this component.
    Please keep it as simple and clean as possible :)
*/
import styled from "styled-components";

const Root = styled("div")``;

const CheckListContainer = styled("div")`
  padding-left: 44px;
  padding-right: 12px;
`;

export default { Root, CheckListContainer };
