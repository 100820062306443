import React from 'react'
import styledComponents from './styles';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';
import formatter from '../../../../lib/formatter';

const {
  H2,
  P_Grayed,
  P_RightAligned
} = commonStyles;

const {
  Details,
  DetailBox,
} = styledComponents;

export default function DetailsComponent({fetchedRequestData, browserLanguage, translations}) {

  const SurveySubmittedOn = formatter.getIntlFormattedDateTime(fetchedRequestData.Request.SurveySubmittedOn, browserLanguage, fetchedRequestData.Request.TimeZone.timeZoneId)

  return (
    <Details>
      <H2>{translations.totalSection.details.title}</H2>
      <DetailBox>
        <P_Grayed>{translations.totalSection.details.estimateDate}</P_Grayed>
        <P_RightAligned>{SurveySubmittedOn.longDateWithoutDay}</P_RightAligned>
      </DetailBox>
    </Details>
  )
}
