import styled, { css } from "styled-components";

const Container = styled.div`

    display: flex;
    height: 100vh;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const InstallerConfirmedTitle = styled.h2`
  color: rgb(62, 65, 72);
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  height: 24px;
  line-height: 24px;
  text-align: center;
 // width: 400px;

 @media (min-width: 426px) {
    width: 400px;
  }
`;

const InstallerConfirmedMessage = styled.p`
  color: rgb(62, 65, 72);
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 20px;
  text-align: left;
  //width: 400px;
  margin: 0px;

  @media (min-width: 426px) {
    width: 400px;
  }
`;


const InstallerConfirmedMessageRedirec = styled.a`
  color: rgb(0, 188, 212);
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  height: 40px;
  letter-spacing: 0px;
  line-height: 20px;
  text-align: center;
 // width: 400px;

 @media (min-width: 426px) {
    width: 400px;
  }
`;

const Footer = styled.img`
  display: block;
  margin: 0 auto;
  height: 24px;
  width: 81px;
  object-fit: contain;
  margin-bottom: 24px;
  position: absolute;
  bottom: 0px;
`;


export default {
    Container,
    InstallerConfirmedTitle,
    InstallerConfirmedMessage,
    InstallerConfirmedMessageRedirec,
    Footer
}