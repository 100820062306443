import styled from 'styled-components';

const CalculationContainer = styled.div`
  display: none;
  flex-direction: column;
  align-items: flex-end;
  gap: 48px;
  align-self: stretch;
  overflow: hidden;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin: 0px;
    }

    .pGray {
      color: rgba(62, 65, 72, 0.35);
    }

    .subtotal {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 16px;
      width: 100%;

      .row {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;

        .numberCenter {
          display: flex;
          max-width: 161px;
          min-width: 128px;
          padding: 12px;
          align-items: flex-start;
          justify-content: center;
          gap: 8px;
        }

        .numberEnd {
          display: flex;
          max-width: 161px;
          min-width: 128px;
          padding: 12px;
          align-items: flex-start;
          justify-content: flex-end;
          gap: 8px;
        }

        .numberTotal {
          display: flex;
          max-width: 161px;
          min-width: 100px;
          padding: 12px;
          align-items: flex-start;
          justify-content: flex-end;
          gap: 8px;
        }
      }
    }
`;

const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;

  i {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #B9BBC1;
  }
`;

export default {
  CalculationContainer,
  IconBox
}