
import React from "react"
import styledComponents from "./style";
import customerDeclined from "../../../../public/images/customerDeclined.png"


const  {Container, InstallerDeclined ,InstallerDeclinedMessage, Footer} = styledComponents
 export const InstallerDeclinedConfirmation = ({translations}) => {
    return (
        <Container>
            <img style={{height: 72, width: 64}} alt="Oops" src={customerDeclined}  />
            <InstallerDeclined>{translations.installerDeclined.title}</InstallerDeclined>
            <InstallerDeclinedMessage>{translations.installerDeclined.description}</InstallerDeclinedMessage>
            <Footer src={`${window.env.blobUrl}/qmerit/wordmark/png/Qmerit-Wordmark-IG400.png`}/>
        </Container>

    )
}