import React from 'react'
import styledComponents from "./styles";
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';

const {
  H1,
  P,
} = commonStyles;

const {
  TitleWrapper,
} = styledComponents;

export default function TitleSection({translations}) {
  return (
    <TitleWrapper>
      <H1>{translations.titleSection.title}</H1>
      <P>{translations.titleSection.description}</P>
    </TitleWrapper>
  )
}
