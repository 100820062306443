import styled from 'styled-components';
import {
  mediaMax
} from "../../../../shared/style";

const QuoteApprovedContainer = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;

  width: 100%;
  height: 52px;

  background: #F1F8E9;
  border-radius: 4px;
  border: none;

  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #AED581;
`;

export default {
  QuoteApprovedContainer
};

