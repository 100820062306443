import React, { Component } from 'react';
import DocImage from "../../../../public/images/DocImage.png";
import StyledComponents from './styles';

const { Document } = StyledComponents;

class AttachmentPreview extends Component {
    render() {
        const { activity } = this.props;

        return (
            <Document>
                {activity.Attachments.map(
                    (file, index) => (
                        <div key={index}>
                            <a
                                href={file.Url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={DocImage}
                                    alt="document thumbnail"
                                />
                            </a>
                            <a
                                href={file.Url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <h4>{file.Name}</h4>
                            </a>
                        </div>
                    )
                )}
            </Document>
        )
    }
}

export default AttachmentPreview;
