import styled from 'styled-components';
import {
  mediaMax,
  mediaMin
} from "../../../../shared/style"


const InformationSection = styled.div`

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;

`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  ${mediaMax.tablet`
      display: none;
  `};
`;

const Table = styled.table`

  color: rgb(62, 65, 72);
  margin: 0px;
  width: 100%;
  text-align: left;

  border: 1px solid #DEDFE2;
  border-radius: 4px;
  border-spacing: 0px;

  table-layout: fixed;

  ${mediaMax.desktop`
   display: none;
`};

`;

const TableHeader = styled.tr`

  th{
    border-spacing: 0px;
    border-right-width: 1px;
    border-top-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-style: solid;
    border-color: #DEDFE2;
    padding: 8px;

    font-weight: 700;
    font-size: 12px;
    line-height: 16px;

    &:last-child{
      border-right: 0px;
    }
  }

  .preliminary-estimate{
    background: #F4F5F6;
    border-right-width: 0px;
  }
    
`;

const TableRow = styled.tr`

  padding: 16px;

  td{
    overflow-wrap: anywhere;
    border-right-width: 1px;
    border-top-width: 1px;
    border-top-color: #EDEEEF;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-style: solid;
    border-right-color: #DEDFE2;
    border-spacing: 0px;
    padding: 8px;

    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    vertical-align: top;

    &:last-child{
      border-right: 0px;
    }
  }

  .preliminary-estimate{
    border-right-width: 0px;
  }

`;

const InformationMobile = styled.div`

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  ${mediaMin.desktop`
   display: none;
`};

`;

const MobileHeaderContainer = styled.div`
  display: flex;

  h3 {
    color: #3E4148;
    font-family: Red Hat Display;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin: 0px;
  }
`;

const RowContainer = styled.div`

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 100%;

`;

const RowTitle = styled.p`

  width: 100%;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0px;
  display: flex;
  align-items: center;

  color: #6E727C;

`;

const RowDescription = styled.p`

  width: 100%;

  overflow-wrap: anywhere;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin: 0px;
  display: flex;
  align-items: center;

  color: #3E4148;

`;

const Link = styled.a`

  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  text-align: center;

  /* link / new */
  color: #3963FE;

  ${mediaMax.tablet`
    font-size: 14px;
    font-weight: 400;
    text-transform: none;
  `};

`;

export default {
  InformationSection,
  HeaderContainer,
  Table,
  TableHeader,
  TableRow,
  InformationMobile,
  MobileHeaderContainer,
  RowContainer,
  RowTitle,
  RowDescription,
  Link
}