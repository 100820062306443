import React, { Fragment } from 'react';
import styledComponents from './styles';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';

const {
  P_Micro
} = commonStyles;

const {
  Divider,
  StyledCancelSection,
  StyledGhostButtonSmall
} = styledComponents;

export default function CancelSection({ cancelHandler, disabled, translations }) {
  return (
    <Fragment>
      <Divider />
      <StyledCancelSection>
        <P_Micro>{translations.cancel.description}</P_Micro>
        <StyledGhostButtonSmall onClick={cancelHandler} disabled={disabled} >
          <div className="iconBox">
            <i className="far fa-times" alt="x icon" />
          </div>
          {translations.cancel.button}
        </StyledGhostButtonSmall>
      </StyledCancelSection>
    </Fragment>
  )
}