import RequirementField from "./CollapseData/RequirementField";
import RequirementHold from "./CollapseData/RequirementHold";
import RequirementComplete from "./CollapseData/RequirementComplete";
import RequirementUpload from "./CollapseData/RequirementUpload";
import moment from "moment";
import _ from "lodash";

export function getComponent(data, status) {
  if (status === "incomplete" && data.Type === 4) return RequirementComplete;
  switch (data.Type) {
    case 1:
      return RequirementUpload;
    case 2:
      return RequirementHold;
    case 3:
      return RequirementField;
  }
}

export function getStatus(data, corpOffice) {
  if (data.Status === "rejected" && !data.OverridenUntil) {
    //UTC Conversion in Local Time
    var date = data.CompletedBy.CompletedOn;
    var localDate = new Date(date + "Z");
    var currDate = moment(localDate).fromNow();
    return {
      status: "rejected",
      showStatus: `Rejected ${currDate}`,
      icon: "times-circle"
    };
  }
  // if (data.Status === "pending") {
  //   return {
  //     status: "pending",
  //     showStatus: "Pending verification…",
  //     icon: "check-double"
  //   };
  // }
  if (data.Type === 2) {
    return {
      status: "onHold",
      showStatus: `On hold, waiting on ${corpOffice.DBAName}`,
      icon: "ellipsis-h"
    };
  }

  if (data.Status === "complete" && !data.VerificationRequired && !_.has(data, 'Collateral.Expiration')) {
    return {
      status: "complete",
      showStatus: "Complete Verification...",
      icon: "shield-check"
    };
  }

  if (data.Type === 4) {
    return {
      status: "incomplete",
      showStatus: "Incomplete",
      icon: "circle"
    };
  }

  if (data.OverridenUntil) {
    const date = moment(data.OverridenUntil).fromNow();
    const expired = new Date(data.OverridenUntil) < new Date();
    const expiring = new Date(data.OverridenUntil) < moment().add({ days: 30 });
    if (expiring && !expired) {
      return {
        status: "overridenExpiring",
        showStatus: `Override expires ${date}`,
        icon: "badge-check"
      };
    } else if (expired) {
      return {
        status: "overridenExpired",
        showStatus: `Override expired ${date}`,
        icon: "exclamation-triangle"
      };
    }
  }

  if (_.has(data, 'Collateral.Expiration')) {
      const date = moment(data.Collateral.Expiration).fromNow();
      const expired = new Date(data.Collateral.Expiration) - new Date() < 0;
      const expiring =
      moment(data.Collateral.Expiration) - moment().add({ days: 30 }) < 0;
      if (expiring && !expired) {
          return {
              status: "expiring",
              showStatus: `Expiring ${date}`,
              icon: "exclamation-circle"
          };
      } else if (expired) {
          return {
              status: "expired",
              showStatus: `Expired ${date}`,
              icon: "exclamation-triangle"
          };
      }
  }

  switch (data.Status) {
    case "incomplete":
      return { status: "incomplete", showStatus: "Incomplete", icon: "circle" };
    case "complete":
      return { status: "complete", showStatus: "Incomplete", icon: "circle" };
    case "verified":
      return {
        status: "complete",
        showStatus: "Verified",
        icon: "shield-check"
      };
  }
}
