import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Paging = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  text-transform: uppercase;
  margin-bottom: 48px;

  p {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
  }

  .pLink {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #B9BBC1;
  }
`;

const BackLink = styled(Link)`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #B9BBC1;
  cursor: pointer;
`;

const IconBox = styled.div`
  width: 20px;
  height: 20px;
  display:flex;
  align-items: center;
  justify-content: center;

  i {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #B9BBC1;
    cursor: pointer;
  }
`;

export default {
  Paging,
  BackLink,
  IconBox
};