import React, { useState, Fragment, useRef } from 'react';
import styledComponents from './styles';
import Loader from '../../components/Loader';

import { useFetchData } from './hooks/useFetchData';
import { getGeneral, getAppliances, getHeavyLoad, getAC } from './helper';
import BackNavigation from './components/BackNavigation';
import MobileHeader from './components/MobileHeader';
import Summary from './sections/Summary';
import ProjectInformation from './sections/ProjectInformation';
import GeneralAssumptions from './sections/GeneralAssumptions';
import StandardAppliances from './sections/StandardAppliances';
import HeavierLoads from './sections/HeavierLoads';
import Calculations from './sections/Calculations';
import PanelZoom from './sections/PanelZoom';
import Feedback from './sections/Feedback';
import ACLoad from './sections/ACLoad';
import MainPanelMobile from './sections/MainPanelMobile';

const {
  GlobalLoadCalculationFormStyles,
  Content,
  ColumnContainer,
  Sticky
} = styledComponents;

export default function LoadCalculationForm(props) {
  const [general, setGeneral] = useState(-1);
  const [appliances, setAppliances] = useState(-1);
  const [heavyLoad, setHeavyLoad] = useState(-1);
  const [totalAmp, setTotalAmp] = useState(-1);
  const [acValue1, setACValue1] = useState(null);
  const [currentRec, setCurrentRec] = useState([]);
  const acRef = useRef(null);

  const {
    loadCalculationData,
    isLoading,
    responseStatus,
    translations, 
    guid
  } = useFetchData(props);

  if (responseStatus === 200 && !isLoading) {
    return (
      <Fragment>
        <GlobalLoadCalculationFormStyles />
          <Content>
            <BackNavigation />
            <MobileHeader />
            <ColumnContainer>
              <div className="rightColumn">
                <Summary mainBreaker={loadCalculationData.mainBreakerAmperage} totalAmp={totalAmp} general={general} appliances={appliances} heavyLoad={heavyLoad} ac={getAC(loadCalculationData)[0]} acValue1={acValue1} recommendations={currentRec.length !== 0 ? currentRec[0] : loadCalculationData.recommendations[0]} id={loadCalculationData.id} setCurrentRec={setCurrentRec} guid={guid} />
                <MainPanelMobile image={loadCalculationData.panelImageUrl} analyticImage={loadCalculationData.analyticPanelImageUrl} />
                <ProjectInformation data={loadCalculationData} translations={translations.quotePage} />
                <GeneralAssumptions data={getGeneral(loadCalculationData.items)} setFxn={setGeneral} id={loadCalculationData.id} setCurrentRec={setCurrentRec} guid={guid} />
                <StandardAppliances data={getAppliances(loadCalculationData.items)} setFxn={setAppliances} id={loadCalculationData.id} setCurrentRec={setCurrentRec} guid={guid} />
                <HeavierLoads data={getHeavyLoad(loadCalculationData.items)} setFxn={setHeavyLoad} id={loadCalculationData.id} acValue1={acValue1} setACValue1={setACValue1} ac={getAC(loadCalculationData)[0]} setCurrentRec={setCurrentRec} guid={guid} acRef={acRef} />
                <ACLoad ac={getAC(loadCalculationData)[0]} id={loadCalculationData.id} acValue1={acValue1} setACValue1={setACValue1} setCurrentRec={setCurrentRec} guid={guid} ref={acRef} />
                <Calculations general={general} appliances={appliances} heavyLoad={heavyLoad} ac={getAC(loadCalculationData)[0]} id={loadCalculationData.id} setTotalAmp={setTotalAmp} acValue1={acValue1} setACValue1={setACValue1} />
              </div>
              <div className="leftColumn">
                <Sticky>
                  <PanelZoom image={loadCalculationData.panelImageUrl} analyticImage={loadCalculationData.analyticPanelImageUrl} />
                  <Feedback externalRef={loadCalculationData.externalReferenceId} />
                </Sticky>
              </div>
            </ColumnContainer>
          </Content>
      </Fragment>
    )
  }

  return <Loader color={"#000000"} isLoading={isLoading} />
}