import styled from "styled-components";
import {
  theme,
  fade,
  primaryButton,
  mediaMin,
  mediaMax
} from "./../../../shared/style";

export const CardRoot = styled.div`
  &:not(:nth-last-child(1)) {
    margin-bottom: 48px;
  }
`;
const ContentWraper = styled.div`
  ${mediaMin.phone`padding: 0 46px;`};
`;
const HeaderWraper = styled.div`
  padding: 0 46px;
  ${mediaMax.phone`padding-right: 0;`};
`;
export const Header = styled.div`
  position: relative;
`;
const LogoWraper = styled.div`
  background-color: #e6e4e4;
  position: absolute;
  height: 40px;
  width: 40px;
  right: calc(100% + 9px);
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 40px;
    width: 40px;
    object-fit: contain;
  }
`;

const Title = styled.h2`
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${theme.Primary.main};
`;
const Dec = styled.p`
  margin: 0 0 20px;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
`;

export const CollapseTitle = styled.p`
  margin-top: 17px;
  font-size: 12px;
  font-style: italic;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${theme.IndiqoGrey[500]};
`;
export const DoneButton = styled.button`
  margin-top: -24px;
  display: block;
  height: 32px;
  border-radius: 100px;
  min-width: 120px;
  max-width: 279px;
  border: solid 1px ${theme.Action[700]};
  background-image: linear-gradient(
    to bottom,
    ${theme.Primary.main},
    ${theme.Action[700]}
  );

  font-size: 12px;
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: 1px;
  color: ${theme.White};
  text-transform: uppercase;
  margin-left: auto;
  margin-right: 0;
  ${mediaMax.phone`
   height: 40px;
   width:100%;
   margin-right: auto;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 3px 3px 0 rgba(0, 0, 0, 0.14);
  
  `};
`;
export default {
  CardRoot,
  ContentWraper,
  HeaderWraper,
  Header,
  LogoWraper,
  Title,
  Dec,
  DoneButton,
  CollapseTitle
};
