import React from 'react'
import styledComponents from "./styles";
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';

import Concepts from '../../components/Concepts';
import LaunchProject from '../../components/LaunchProjectContainer';
import DetailsComponent from '../../components/DetailsComponent';
import CancelBox from '../../components/CancelBox';

const {
  H1,
  Label_Field,
} = commonStyles;

const {
  Sticky,
  TotalContainer,
  TotalDivider,
  CancelSection,
} = styledComponents;

export default function TotalSection({fetchedRequestData, browserLanguage, requestId, translations}) {

  return (
    <Sticky>
      <DetailsComponent 
        fetchedRequestData={fetchedRequestData}
        browserLanguage={browserLanguage}
        translations={translations}/>
      <TotalContainer>
        <H1>{translations.totalSection.total.title}</H1>
        {/*  Concept Common Component */}
        <Concepts fetchedRequestData={fetchedRequestData} translations={translations}/>
        <TotalDivider />
        <LaunchProject fetchedRequestData={fetchedRequestData} requestId={requestId} translations={translations}/>
      </TotalContainer>
      <CancelSection>
        <Label_Field>{translations.totalSection.total.cancel.message}</Label_Field>
        <CancelBox requestId={requestId} translations={translations}/>
      </CancelSection>
    </Sticky>
  )
}
