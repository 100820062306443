import React from 'react'
import StyledComponents from './styles';
import _ from "lodash";

import { getBidServiceProviderName } from '../../../helpers/BidsDates';

const {
	Card,
	TitleBox,
	IconBox,
	TitleIcon,
	TitleText,
	Details
} = StyledComponents;

export default function WaitingForQuoteCard({ requestData, translations, show }) {

	if(!show) return null;
	return (
		<React.Fragment>
			<Card>
				<TitleBox>
					<IconBox>
						<TitleIcon className="far fa-file-invoice-dollar" />
					</IconBox>
					<TitleText>
						{translations.title}
					</TitleText>
				</TitleBox>
				<Details>
					{getBidServiceProviderName(requestData)} {translations.descriptionOne} {getBidServiceProviderName(requestData)} {translations.descriptionTwo}
				</Details>
			</Card>
		</React.Fragment>
	)
}
