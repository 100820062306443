export default {
  User: {
    CompanyName: "AshbyCancun2",
    Name: "Luis TestAdmin2"
  },
  ConnectedRequirements: [
    {
      MinimalConnectedCorporateOffice: {
        Id: 4508,
        DBAName: "Qmerit Inc.",
        ImageUrl:
          "https://senstorescorpiusqa.blob.core.windows.netimages-company/ea8d93d7-531b-45be-b9ab-41f94cff11d2",
        Score: 307,
        IsProvisional: false,
        ConnectionTypes: [1],
        Locations: []
      },
      ConnectedPrograms: [
        {
          Id: 7831,
          Name: "Test 22064 Update Requirements",
          ConnectionId: 6430,
          Type: 1,
          IsEnabled: true,
          Status: "incomplete",
          StatusId: 1,
          IsDefault: false,
          Requirements: [
            {
              Id: 1936,
              Name: "Incomplete",
              Description: "Test For Incomplete requirement",
              Collateral: null,
              Status: "incomplete",
              Attachment: [
                {
                  FileType: "application/vnd.oasis.opendocument.text",
                  Type: "Qmerit",
                  Url:
                    "https://dacstorescorpiusqa.blob.core.windows.net/documents/fcdf7c01-a410-4478-9789-91394b702cd3",
                  contentType: "application/vnd.oasis.opendocument.text"
                }
              ],
              OverridenUntil: null,
              PreviousNote: null,
              Type: 1,
              VerificationRequired: false,
              Actor: "child",
              FieldLabel: null,
              Data: null,
              CompletedBy: null,
              AttachmentUrls: []
            },
            {
              Id: 1937,
              Name: "On Hold Requirement",
              Description: "Test On Hold Requirement",
              Collateral: null,
              Status: "incomplete",
              Attachment: null,
              OverridenUntil: null,
              PreviousNote: null,
              Type: 2,
              VerificationRequired: false,
              Actor: "parent",
              FieldLabel: "Field label",
              Data: null,
              CompletedBy: null,
              AttachmentUrls: []
            },
            {
              Id: 1941,
              Name: "Expired Override",
              Description: "Expired Override Desc",
              Collateral: null,
              Status: "incomplete",
              Attachment: null,
              OverridenUntil: "2019-06-18T00:00:00",
              PreviousNote: {
                Id: 1027,
                CreatedOn: "2019-06-18T01:14:16.7806292",
                CreatedById: 228559,
                CreatedByName: "QMeritMain",
                Message: null
              },
              Type: 1,
              VerificationRequired: false,
              Actor: "child",
              FieldLabel: null,
              Data: null,
              CompletedBy: null,
              AttachmentUrls: []
            },
            {
              Id: 1943,
              Name: "ExpiringOverride",
              Description: "ExpiringOverride",
              Collateral: null,
              Status: "incomplete",
              Attachment: null,
              OverridenUntil: "2019-07-16T00:00:00",
              PreviousNote: {
                Id: 1028,
                CreatedOn: "2019-06-18T01:14:49.9442908",
                CreatedById: 228559,
                CreatedByName: "QMeritMain",
                Message: null
              },
              Type: 1,
              VerificationRequired: false,
              Actor: "child",
              FieldLabel: null,
              Data: null,
              CompletedBy: null,
              AttachmentUrls: []
            },
            {
              Id: 1944,
              Name: "Text Reject",
              Description: "Text Reject Desc",
              Collateral: null,
              Status: "rejected",
              Attachment: null,
              OverridenUntil: null,
              PreviousNote: {
                Id: 1021,
                CreatedOn: "2019-05-09T00:37:03.7651556",
                CreatedById: 228559,
                CreatedByName: "QMeritMain",
                Message: null
              },
              Type: 3,
              VerificationRequired: true,
              Actor: "child",
              FieldLabel: "Field",
              Data: {
                CustomString1: "Test",
                CustomBool1: null,
                CustomInt1: null
              },
              CompletedBy: {
                FirstName: "Luis",
                LastName: "TestAdmin2",
                CompletedOn: "2019-05-09T00:36:45.6638501"
              },
              AttachmentUrls: []
            },
            {
              Id: 1946,
              Name: "ExpiredOverRideComplete",
              Description: "Expired Override but complete",
              Collateral: null,
              Status: "incomplete",
              Attachment: null,
              OverridenUntil: "2019-05-10T00:00:00",
              PreviousNote: {
                Id: 1024,
                CreatedOn: "2019-05-09T23:51:55.3499596",
                CreatedById: 228559,
                CreatedByName: "QMeritMain",
                Message: null
              },
              Type: 1,
              VerificationRequired: false,
              Actor: "child",
              FieldLabel: null,
              Data: null,
              CompletedBy: null,
              AttachmentUrls: []
            },
            {
              Id: 1938,
              Name: "Expired",
              Description: "Expired Requirement",
              Collateral: {
                Id: 3737,
                Name: "TestExpired Doc",
                Url:
                  "https://senstorescorpiusqa.blob.core.windows.nethttps://storescorpiusqa.blob.core.windows.net/collateral/sv=2017-07-29&sr=b&sig=IonHMz7FIyo9GJby5CGlFk5WHUAhY%252BcYbsUJTbIjK1M%253D&se=2019-05-10T00%253A16%253A41Z&sp=r?sv=2017-07-29&sr=b&sig=NxyUOJVvpmkzQLH1JdM9wAhvRxQJQNBRB7GbJjb2nw8%3D&se=2019-06-26T05%3A41%3A27Z&sp=r",
                Expiration: "2019-05-08T06:00:00",
                ContentType: "application/pdf",
                CollateralTypeId: 11
              },
              Status: "complete",
              Attachment: null,
              OverridenUntil: null,
              PreviousNote: null,
              Type: 1,
              VerificationRequired: false,
              Actor: "child",
              FieldLabel: null,
              Data: null,
              CompletedBy: {
                FirstName: "Luis",
                LastName: "TestAdmin2",
                CompletedOn: "2019-05-09T21:16:47.651031"
              },
              AttachmentUrls: []
            },
            {
              Id: 1939,
              Name: "Rejected",
              Description: "Rejected Requirement",
              Collateral: {
                Id: 3739,
                Name: "Rejected Doc",
                Url:
                  "https://senstorescorpiusqa.blob.core.windows.nethttps://storescorpiusqa.blob.core.windows.net/collateral/sv=2017-07-29&sr=b&sig=bEnpTKpNC0PtcUdLy76lF5t%252B0vzaDzeQIJ1euIUkYBM%253D&se=2019-05-10T02%253A04%253A41Z&sp=r?sv=2017-07-29&sr=b&sig=isxavI9MmKJ%2FjJxFu4K5zvirCw9i%2FYApRdfDqvEXdlI%3D&se=2019-06-26T05%3A41%3A27Z&sp=r",
                Expiration: null,
                ContentType: "text/plain",
                CollateralTypeId: 18
              },
              Status: "rejected",
              Attachment: null,
              OverridenUntil: null,
              PreviousNote: {
                Id: 1023,
                CreatedOn: "2019-05-09T23:05:02.8718182",
                CreatedById: 228559,
                CreatedByName: "QMeritMain",
                Message: "Testing"
              },
              Type: 3,
              VerificationRequired: true,
              Actor: "child",
              FieldLabel: null,
              Data: null,
              CompletedBy: {
                FirstName: "Luis",
                LastName: "TestAdmin2",
                CompletedOn: "2019-05-09T23:04:44.2303435"
              },
              AttachmentUrls: [
                {
                  FileType: "application/vnd.oasis.opendocument.text",
                  Type: "Qmerit",
                  Url:
                    "https://dacstorescorpiusqa.blob.core.windows.net/documents/fcdf7c01-a410-4478-9789-91394b702cd3",
                  contentType: "application/vnd.oasis.opendocument.text"
                }
              ]
            },
            {
              Id: 1942,
              Name: "Expiring",
              Description: "Expiring requirement",
              Collateral: {
                Id: 3741,
                Name: "ExpiringDocFor-Jul16",
                Url:
                  "https://senstorescorpiusqa.blob.core.windows.nethttps://storescorpiusqa.blob.core.windows.net/collateral/sv=2017-07-29&sr=b&sig=Xvcvk03TsT8JiCbilkTK1S9V1a8mEkdxA0fq8c4POY4%253D&se=2019-06-18T04%253A17%253A15Z&sp=r?sv=2017-07-29&sr=b&sig=M59fZyuX11T%2FY89BxAZJy6eiUuwwvBnN218ceNiHcVM%3D&se=2019-06-26T05%3A41%3A27Z&sp=r",
                Expiration: "2019-07-16T06:00:00",
                ContentType: "text/plain",
                CollateralTypeId: 14
              },
              Status: "complete",
              Attachment: null,
              OverridenUntil: null,
              PreviousNote: null,
              Type: 1,
              VerificationRequired: false,
              Actor: "child",
              FieldLabel: null,
              Data: null,
              CompletedBy: {
                FirstName: "Luis",
                LastName: "TestAdmin2",
                CompletedOn: "2019-06-18T01:17:20.618765"
              },
              AttachmentUrls: []
            }
          ],
          CompletionPercentage: 0.27272727272727271
        }
      ]
    }
  ]
};
//[
//   {
//     MinimalConnectedCorporateOffice: {
//       Id: 12698,
//       DBAName: "Aayushi Parent company",
//       ImageUrl: null,
//       Score: 200,
//       IsProvisional: false,
//       ConnectionTypes: [1],
//       Locations: []
//     },
//     ConnectedPrograms: [
//       {
//         Id: 7668,
//         Name: "QMerit Default Program",
//         ConnectionId: 6241,
//         Type: 1,
//         IsEnabled: true,
//         Status: "incomplete",
//         StatusId: 1,
//         IsDefault: true,
//         Requirements: [
//           {
//             Id: 1933,
//             Name: "Test requirements",
//             Description: "testing requirements",
//             Collateral: null,
//             Status: "incomplete",
//             Attachment: null,
//             OverridenUntil: null,
//             PreviousNote: null,
//             Type: 2,
//             VerificationRequired: false,
//             Actor: "parent",
//             FieldLabel: "qa",
//             Data: null,
//             CompletedBy: null,
//             AttachmentUrls: []
//           }
//           // {
//           //   Id: 1935,
//           //   Name: "test",
//           //   Description: "test",
//           //   Collateral: null,
//           //   Status: "incomplete",
//           //   Attachment: null,
//           //   OverridenUntil: null,
//           //   PreviousNote: null,
//           //   Type: 4,
//           //   VerificationRequired: false,
//           //   Actor: "child",
//           //   FieldLabel: "I've read and agreed to the requirements",
//           //   Data: null,
//           //   CompletedBy: null,
//           //   AttachmentUrls: []
//           // }
//         ],
//         CompletionPercentage: 0
//       }
//     ]
//   },
//   {
//     MinimalConnectedCorporateOffice: {
//       Id: 4508,
//       DBAName: "Qmerit Inc.",
//       ImageUrl:
//         "https://senstoreqmtqa510612.blob.core.windows.net/images-company/c28a0c5a-f0a8-46ab-ac79-4187328f4160",
//       Score: 307,
//       IsProvisional: false,
//       ConnectionTypes: [1],
//       Locations: []
//     },
//     ConnectedPrograms: [
//       {
//         Id: 111,
//         Name: "BMW EV Charger",
//         ConnectionId: 160,
//         Type: 1,
//         IsEnabled: true,
//         Status: "verified",
//         StatusId: 4,
//         IsDefault: false,
//         Requirements: [
//           {
//             Id: 68,
//             Name: "Automobile Insurance",
//             Description:
//               "Review/Update your automobile policy to assure a minimum liability and physical damage coverage of $1,000,000 / occurrence\r\n\r\nFor questions, contact: customerservice@qmerit.com",
//             Collateral: null,
//             Status: "incomplete",
//             Attachment: {
//               Id: 3489,
//               Name: "sample doc",
//               Url:
//                 "https://senstoreqmtqa510612.blob.core.windows.net/collateral/1794b92c-1353-4d02-844d-4ba36c852e88?sv=2017-07-29&sr=b&sig=olsTwPTycvrgwLKBsVhX2ARIQKWSVmK9MHGL8WMcO1g%3D&se=2019-04-30T03%3A02%3A58Z&sp=r",
//               Expiration: null,
//               ContentType: "null/null",
//               CollateralTypeId: 12
//             },
//             OverridenUntil: "2022-02-12T00:00:00",
//             PreviousNote: {
//               Id: 1011,
//               CreatedOn: "2019-02-11T21:47:13.2612225",
//               CreatedById: 228559,
//               CreatedByName: "QMeritMain",
//               Message: null
//             },
//             Type: 1,
//             VerificationRequired: true,
//             Actor: "child",
//             FieldLabel: null,
//             Data: null,
//             CompletedBy: null,
//             AttachmentUrls: []
//           },
//           {
//             Id: 74,
//             Name: "BMW Agreement",
//             Description:
//               "Please review and sign: ADDENDUM-BMW EVSE Residential Installer Program Separate.pdf\r\n\r\nFor questions, contact: customerservice@qmerit.com",
//             Collateral: null,
//             Status: "incomplete",
//             Attachment: null,
//             OverridenUntil: "2022-02-12T00:00:00",
//             PreviousNote: {
//               Id: 1010,
//               CreatedOn: "2019-02-11T21:46:58.3467361",
//               CreatedById: 228559,
//               CreatedByName: "QMeritMain",
//               Message: null
//             },
//             Type: 1,
//             VerificationRequired: true,
//             Actor: "child",
//             FieldLabel: null,
//             Data: null,
//             CompletedBy: null,
//             AttachmentUrls: []
//           },
//           {
//             Id: 223,
//             Name: "Safety License",
//             Description: "For the company's safety",
//             Collateral: null,
//             Status: "complete",
//             Attachment: null,
//             OverridenUntil: null,
//             PreviousNote: null,
//             Type: 2,
//             VerificationRequired: false,
//             Actor: "parent",
//             FieldLabel: "Safety Licence exists?",
//             Data: {
//               CustomString1: "t",
//               CustomBool1: null,
//               CustomInt1: null
//             },
//             CompletedBy: null,
//             AttachmentUrls: []
//           },
//           {
//             Id: 233,
//             Name: "Health certificate",
//             Description: "To ensure the employee is healthy",
//             Collateral: null,
//             Status: "complete",
//             Attachment: null,
//             OverridenUntil: null,
//             PreviousNote: null,
//             Type: 2,
//             VerificationRequired: false,
//             Actor: "parent",
//             FieldLabel: "Health check cleared?",
//             Data: {
//               CustomString1: "t",
//               CustomBool1: null,
//               CustomInt1: null
//             },
//             CompletedBy: null,
//             AttachmentUrls: []
//           },
//           {
//             Id: 69,
//             Name: "Worker's Compensation Insurance",
//             Description:
//               "Minimum Coverage:\r\nStatutory limit required by each state you provide services in; covering the employer’s liabilities, and employees’ work-related injuries that don’t fall under workers’ compensation statute. \r\n\r\nFor questions: customerservice@qmerit.com",
//             Collateral: {
//               Id: 1856,
//               Name: "License",
//               Url:
//                 "https://senstoreqmtqa510612.blob.core.windows.net/collateral/ae445efe-b652-4311-b839-c1b43df99d97?sv=2017-07-29&sr=b&sig=%2FE26w6UBqpzWwcyQErw1s6fgEk%2Fsf7nRP2mU4DhE9Fo%3D&se=2019-04-30T03%3A02%3A58Z&sp=r",
//               Expiration: null,
//               ContentType: null,
//               CollateralTypeId: 14
//             },
//             Status: "verified",
//             Attachment: null,
//             OverridenUntil: null,
//             PreviousNote: {
//               Id: 380,
//               CreatedOn: "2017-10-05T21:14:27.1797819",
//               CreatedById: 228559,
//               CreatedByName: "QMeritMain",
//               Message: null
//             },
//             Type: 1,
//             VerificationRequired: true,
//             Actor: "child",
//             FieldLabel: null,
//             Data: null,
//             CompletedBy: null,
//             AttachmentUrls: []
//           },
//           {
//             Id: 67,
//             Name: "General Liability Insurance",
//             Description:
//               "Update your policy by:\r\n1: Contacting your insurance provider\r\n2: Sending them the “QMerit-BMW Installer Insurance Requirements Guide.pdf” \r\n3: Uploading the updated policy here\r\n\r\nFor questions, contact: customerservice@qmerit.com",
//             Collateral: {
//               Id: 2877,
//               Name: "all good, no expiration",
//               Url:
//                 "https://senstoreqmtqa510612.blob.core.windows.net/collateral/1ec54017-abe6-443f-b096-88935dc0e7d6?sv=2017-07-29&sr=b&sig=vrdRp6yuFPwn9rYYsAGE8yB6MJRsEN%2FiWdBFnrS%2BdjE%3D&se=2019-04-30T03%3A02%3A58Z&sp=r",
//               Expiration: null,
//               ContentType: "application/pdf",
//               CollateralTypeId: 12
//             },
//             Status: "verified",
//             Attachment: {
//               Id: 3490,
//               Name: "sample doc for General",
//               Url:
//                 "https://senstoreqmtqa510612.blob.core.windows.net/collateral/2d58232f-a251-44db-ab81-bd1bd5a9d9d4?sv=2017-07-29&sr=b&sig=Eom%2FUIq9m%2BWs8SnKX%2BbEIpAMnfQqP2%2BqeE0aWCXL1s4%3D&se=2019-04-30T03%3A02%3A58Z&sp=r",
//               Expiration: null,
//               ContentType: "application/pdf",
//               CollateralTypeId: 13
//             },
//             OverridenUntil: null,
//             PreviousNote: {
//               Id: 378,
//               CreatedOn: "2017-10-05T21:14:22.5837186",
//               CreatedById: 228559,
//               CreatedByName: "QMeritMain",
//               Message: null
//             },
//             Type: 1,
//             VerificationRequired: true,
//             Actor: "child",
//             FieldLabel: null,
//             Data: null,
//             CompletedBy: null,
//             AttachmentUrls: []
//           },
//           {
//             Id: 81,
//             Name: "Background Check List",
//             Description:
//               "List all employees who will be performing work and have a background check",
//             Collateral: {
//               Id: 2877,
//               Name: "all good, no expiration",
//               Url:
//                 "https://senstoreqmtqa510612.blob.core.windows.net/collateral/1ec54017-abe6-443f-b096-88935dc0e7d6?sv=2017-07-29&sr=b&sig=vrdRp6yuFPwn9rYYsAGE8yB6MJRsEN%2FiWdBFnrS%2BdjE%3D&se=2019-04-30T03%3A02%3A58Z&sp=r",
//               Expiration: null,
//               ContentType: "application/pdf",
//               CollateralTypeId: 12
//             },
//             Status: "verified",
//             Attachment: null,
//             OverridenUntil: null,
//             PreviousNote: {
//               Id: 382,
//               CreatedOn: "2017-10-05T21:14:30.4858741",
//               CreatedById: 228559,
//               CreatedByName: "QMeritMain",
//               Message: null
//             },
//             Type: 1,
//             VerificationRequired: true,
//             Actor: "child",
//             FieldLabel: null,
//             Data: null,
//             CompletedBy: null,
//             AttachmentUrls: []
//           }
//         ],
//         CompletionPercentage: 0.7142857142857143
//       },
//       {
//         Id: 193,
//         Name: "ChargePoint Charger",
//         ConnectionId: 160,
//         Type: 1,
//         IsEnabled: true,
//         Status: "verified",
//         StatusId: 4,
//         IsDefault: false,
//         Requirements: [
//           {
//             Id: 68,
//             Name: "Automobile Insurance",
//             Description:
//               "Review/Update your automobile policy to assure a minimum liability and physical damage coverage of $1,000,000 / occurrence\r\n\r\nFor questions, contact: customerservice@qmerit.com",
//             Collateral: null,
//             Status: "incomplete",
//             Attachment: {
//               Id: 3489,
//               Name: "sample doc",
//               Url:
//                 "https://senstoreqmtqa510612.blob.core.windows.net/collateral/1794b92c-1353-4d02-844d-4ba36c852e88?sv=2017-07-29&sr=b&sig=olsTwPTycvrgwLKBsVhX2ARIQKWSVmK9MHGL8WMcO1g%3D&se=2019-04-30T03%3A02%3A58Z&sp=r",
//               Expiration: null,
//               ContentType: "null/null",
//               CollateralTypeId: 12
//             },
//             OverridenUntil: "2022-02-12T00:00:00",
//             PreviousNote: {
//               Id: 1011,
//               CreatedOn: "2019-02-11T21:47:13.2612225",
//               CreatedById: 228559,
//               CreatedByName: "QMeritMain",
//               Message: null
//             },
//             Type: 1,
//             VerificationRequired: true,
//             Actor: "child",
//             FieldLabel: null,
//             Data: null,
//             CompletedBy: null,
//             AttachmentUrls: []
//           },
//           {
//             Id: 1433,
//             Name: "on hold",
//             Description: "on hold",
//             Collateral: null,
//             Status: "complete",
//             Attachment: null,
//             OverridenUntil: null,
//             PreviousNote: null,
//             Type: 2,
//             VerificationRequired: false,
//             Actor: "parent",
//             FieldLabel: "on hold",
//             Data: {
//               CustomString1: "hgfsh",
//               CustomBool1: null,
//               CustomInt1: null
//             },
//             CompletedBy: null,
//             AttachmentUrls: []
//           },
//           {
//             Id: 1434,
//             Name: "text input",
//             Description: "text input",
//             Collateral: null,
//             Status: "incomplete",
//             Attachment: null,
//             OverridenUntil: "2022-02-12T00:00:00",
//             PreviousNote: {
//               Id: 1012,
//               CreatedOn: "2019-02-11T21:47:27.3591876",
//               CreatedById: 228559,
//               CreatedByName: "QMeritMain",
//               Message: null
//             },
//             Type: 3,
//             VerificationRequired: false,
//             Actor: "child",
//             FieldLabel: "text input",
//             Data: null,
//             CompletedBy: null,
//             AttachmentUrls: []
//           },
//           {
//             Id: 1435,
//             Name: "for Kim",
//             Description: "Please add something",
//             Collateral: null,
//             Status: "complete",
//             Attachment: null,
//             OverridenUntil: null,
//             PreviousNote: null,
//             Type: 2,
//             VerificationRequired: false,
//             Actor: "parent",
//             FieldLabel: "Kim to add on hold requirement",
//             Data: {
//               CustomString1: "h",
//               CustomBool1: null,
//               CustomInt1: null
//             },
//             CompletedBy: null,
//             AttachmentUrls: []
//           },
//           {
//             Id: 69,
//             Name: "Worker's Compensation Insurance",
//             Description:
//               "Minimum Coverage:\r\nStatutory limit required by each state you provide services in; covering the employer’s liabilities, and employees’ work-related injuries that don’t fall under workers’ compensation statute. \r\n\r\nFor questions: customerservice@qmerit.com",
//             Collateral: {
//               Id: 1856,
//               Name: "License",
//               Url:
//                 "https://senstoreqmtqa510612.blob.core.windows.net/collateral/ae445efe-b652-4311-b839-c1b43df99d97?sv=2017-07-29&sr=b&sig=%2FE26w6UBqpzWwcyQErw1s6fgEk%2Fsf7nRP2mU4DhE9Fo%3D&se=2019-04-30T03%3A02%3A58Z&sp=r",
//               Expiration: null,
//               ContentType: null,
//               CollateralTypeId: 14
//             },
//             Status: "verified",
//             Attachment: null,
//             OverridenUntil: null,
//             PreviousNote: {
//               Id: 380,
//               CreatedOn: "2017-10-05T21:14:27.1797819",
//               CreatedById: 228559,
//               CreatedByName: "QMeritMain",
//               Message: null
//             },
//             Type: 1,
//             VerificationRequired: true,
//             Actor: "child",
//             FieldLabel: null,
//             Data: null,
//             CompletedBy: null,
//             AttachmentUrls: []
//           },
//           {
//             Id: 67,
//             Name: "General Liability Insurance",
//             Description:
//               "Update your policy by:\r\n1: Contacting your insurance provider\r\n2: Sending them the “QMerit-BMW Installer Insurance Requirements Guide.pdf” \r\n3: Uploading the updated policy here\r\n\r\nFor questions, contact: customerservice@qmerit.com",
//             Collateral: {
//               Id: 2877,
//               Name: "all good, no expiration",
//               Url:
//                 "https://senstoreqmtqa510612.blob.core.windows.net/collateral/1ec54017-abe6-443f-b096-88935dc0e7d6?sv=2017-07-29&sr=b&sig=vrdRp6yuFPwn9rYYsAGE8yB6MJRsEN%2FiWdBFnrS%2BdjE%3D&se=2019-04-30T03%3A02%3A58Z&sp=r",
//               Expiration: null,
//               ContentType: "application/pdf",
//               CollateralTypeId: 12
//             },
//             Status: "verified",
//             Attachment: {
//               Id: 3490,
//               Name: "sample doc for General",
//               Url:
//                 "https://senstoreqmtqa510612.blob.core.windows.net/collateral/2d58232f-a251-44db-ab81-bd1bd5a9d9d4?sv=2017-07-29&sr=b&sig=Eom%2FUIq9m%2BWs8SnKX%2BbEIpAMnfQqP2%2BqeE0aWCXL1s4%3D&se=2019-04-30T03%3A02%3A58Z&sp=r",
//               Expiration: null,
//               ContentType: "application/pdf",
//               CollateralTypeId: 13
//             },
//             OverridenUntil: null,
//             PreviousNote: {
//               Id: 378,
//               CreatedOn: "2017-10-05T21:14:22.5837186",
//               CreatedById: 228559,
//               CreatedByName: "QMeritMain",
//               Message: null
//             },
//             Type: 1,
//             VerificationRequired: true,
//             Actor: "child",
//             FieldLabel: null,
//             Data: null,
//             CompletedBy: null,
//             AttachmentUrls: []
//           },
//           {
//             Id: 81,
//             Name: "Background Check List",
//             Description:
//               "List all employees who will be performing work and have a background check",
//             Collateral: {
//               Id: 2877,
//               Name: "all good, no expiration",
//               Url:
//                 "https://senstoreqmtqa510612.blob.core.windows.net/collateral/1ec54017-abe6-443f-b096-88935dc0e7d6?sv=2017-07-29&sr=b&sig=vrdRp6yuFPwn9rYYsAGE8yB6MJRsEN%2FiWdBFnrS%2BdjE%3D&se=2019-04-30T03%3A02%3A58Z&sp=r",
//               Expiration: null,
//               ContentType: "application/pdf",
//               CollateralTypeId: 12
//             },
//             Status: "verified",
//             Attachment: null,
//             OverridenUntil: null,
//             PreviousNote: {
//               Id: 382,
//               CreatedOn: "2017-10-05T21:14:30.4858741",
//               CreatedById: 228559,
//               CreatedByName: "QMeritMain",
//               Message: null
//             },
//             Type: 1,
//             VerificationRequired: true,
//             Actor: "child",
//             FieldLabel: null,
//             Data: null,
//             CompletedBy: null,
//             AttachmentUrls: []
//           },
//           {
//             Id: 83,
//             Name: "ChargePoint Agreement",
//             Description:
//               "Please review and sign: ADDENDUM - ChargePoint EVSE Residential Installer Program Separate.pdf.  \n\nFor questions, contact: customerservice@qmerit.com",
//             Collateral: {
//               Id: 2877,
//               Name: "all good, no expiration",
//               Url:
//                 "https://senstoreqmtqa510612.blob.core.windows.net/collateral/1ec54017-abe6-443f-b096-88935dc0e7d6?sv=2017-07-29&sr=b&sig=vrdRp6yuFPwn9rYYsAGE8yB6MJRsEN%2FiWdBFnrS%2BdjE%3D&se=2019-04-30T03%3A02%3A58Z&sp=r",
//               Expiration: null,
//               ContentType: "application/pdf",
//               CollateralTypeId: 12
//             },
//             Status: "verified",
//             Attachment: null,
//             OverridenUntil: null,
//             PreviousNote: {
//               Id: 386,
//               CreatedOn: "2017-10-05T21:15:35.2133663",
//               CreatedById: 228559,
//               CreatedByName: "QMeritMain",
//               Message: null
//             },
//             Type: 1,
//             VerificationRequired: true,
//             Actor: "child",
//             FieldLabel: null,
//             Data: null,
//             CompletedBy: null,
//             AttachmentUrls: []
//           }
//         ],
//         CompletionPercentage: 0.75
//       },
//       {
//         Id: 219,
//         Name: "QMerit Default",
//         ConnectionId: 160,
//         Type: 1,
//         IsEnabled: true,
//         Status: "verified",
//         StatusId: 4,
//         IsDefault: true,
//         Requirements: [
//           {
//             Id: 67,
//             Name: "General Liability Insurance",
//             Description:
//               "Update your policy by:\r\n1: Contacting your insurance provider\r\n2: Sending them the “QMerit-BMW Installer Insurance Requirements Guide.pdf” \r\n3: Uploading the updated policy here\r\n\r\nFor questions, contact: customerservice@qmerit.com",
//             Collateral: {
//               Id: 2877,
//               Name: "all good, no expiration",
//               Url:
//                 "https://senstoreqmtqa510612.blob.core.windows.net/collateral/1ec54017-abe6-443f-b096-88935dc0e7d6?sv=2017-07-29&sr=b&sig=vrdRp6yuFPwn9rYYsAGE8yB6MJRsEN%2FiWdBFnrS%2BdjE%3D&se=2019-04-30T03%3A02%3A58Z&sp=r",
//               Expiration: null,
//               ContentType: "application/pdf",
//               CollateralTypeId: 12
//             },
//             Status: "verified",
//             Attachment: {
//               Id: 3490,
//               Name: "sample doc for General",
//               Url:
//                 "https://senstoreqmtqa510612.blob.core.windows.net/collateral/2d58232f-a251-44db-ab81-bd1bd5a9d9d4?sv=2017-07-29&sr=b&sig=Eom%2FUIq9m%2BWs8SnKX%2BbEIpAMnfQqP2%2BqeE0aWCXL1s4%3D&se=2019-04-30T03%3A02%3A58Z&sp=r",
//               Expiration: null,
//               ContentType: "application/pdf",
//               CollateralTypeId: 13
//             },
//             OverridenUntil: null,
//             PreviousNote: {
//               Id: 378,
//               CreatedOn: "2017-10-05T21:14:22.5837186",
//               CreatedById: 228559,
//               CreatedByName: "QMeritMain",
//               Message: null
//             },
//             Type: 1,
//             VerificationRequired: true,
//             Actor: "child",
//             FieldLabel: null,
//             Data: null,
//             CompletedBy: null,
//             AttachmentUrls: []
//           }
//         ],
//         CompletionPercentage: 1
//       },
//       {
//         Id: 218,
//         Name: "QMerit EV Charger",
//         ConnectionId: 160,
//         Type: 1,
//         IsEnabled: true,
//         Status: "verified",
//         StatusId: 4,
//         IsDefault: false,
//         Requirements: [
//           {
//             Id: 68,
//             Name: "Automobile Insurance",
//             Description:
//               "Review/Update your automobile policy to assure a minimum liability and physical damage coverage of $1,000,000 / occurrence\r\n\r\nFor questions, contact: customerservice@qmerit.com",
//             Collateral: null,
//             Status: "incomplete",
//             Attachment: {
//               Id: 3489,
//               Name: "sample doc",
//               Url:
//                 "https://senstoreqmtqa510612.blob.core.windows.net/collateral/1794b92c-1353-4d02-844d-4ba36c852e88?sv=2017-07-29&sr=b&sig=olsTwPTycvrgwLKBsVhX2ARIQKWSVmK9MHGL8WMcO1g%3D&se=2019-04-30T03%3A02%3A58Z&sp=r",
//               Expiration: null,
//               ContentType: "null/null",
//               CollateralTypeId: 12
//             },
//             OverridenUntil: "2022-02-12T00:00:00",
//             PreviousNote: {
//               Id: 1011,
//               CreatedOn: "2019-02-11T21:47:13.2612225",
//               CreatedById: 228559,
//               CreatedByName: "QMeritMain",
//               Message: null
//             },
//             Type: 1,
//             VerificationRequired: true,
//             Actor: "child",
//             FieldLabel: null,
//             Data: null,
//             CompletedBy: null,
//             AttachmentUrls: []
//           },
//           {
//             Id: 223,
//             Name: "Safety License",
//             Description: "For the company's safety",
//             Collateral: null,
//             Status: "complete",
//             Attachment: null,
//             OverridenUntil: null,
//             PreviousNote: null,
//             Type: 2,
//             VerificationRequired: false,
//             Actor: "parent",
//             FieldLabel: "Safety Licence exists?",
//             Data: {
//               CustomString1: "t",
//               CustomBool1: null,
//               CustomInt1: null
//             },
//             CompletedBy: null,
//             AttachmentUrls: []
//           },
//           {
//             Id: 233,
//             Name: "Health certificate",
//             Description: "To ensure the employee is healthy",
//             Collateral: null,
//             Status: "complete",
//             Attachment: null,
//             OverridenUntil: null,
//             PreviousNote: null,
//             Type: 2,
//             VerificationRequired: false,
//             Actor: "parent",
//             FieldLabel: "Health check cleared?",
//             Data: {
//               CustomString1: "t",
//               CustomBool1: null,
//               CustomInt1: null
//             },
//             CompletedBy: null,
//             AttachmentUrls: []
//           },
//           {
//             Id: 69,
//             Name: "Worker's Compensation Insurance",
//             Description:
//               "Minimum Coverage:\r\nStatutory limit required by each state you provide services in; covering the employer’s liabilities, and employees’ work-related injuries that don’t fall under workers’ compensation statute. \r\n\r\nFor questions: customerservice@qmerit.com",
//             Collateral: {
//               Id: 1856,
//               Name: "License",
//               Url:
//                 "https://senstoreqmtqa510612.blob.core.windows.net/collateral/ae445efe-b652-4311-b839-c1b43df99d97?sv=2017-07-29&sr=b&sig=%2FE26w6UBqpzWwcyQErw1s6fgEk%2Fsf7nRP2mU4DhE9Fo%3D&se=2019-04-30T03%3A02%3A58Z&sp=r",
//               Expiration: null,
//               ContentType: null,
//               CollateralTypeId: 14
//             },
//             Status: "verified",
//             Attachment: null,
//             OverridenUntil: null,
//             PreviousNote: {
//               Id: 380,
//               CreatedOn: "2017-10-05T21:14:27.1797819",
//               CreatedById: 228559,
//               CreatedByName: "QMeritMain",
//               Message: null
//             },
//             Type: 1,
//             VerificationRequired: true,
//             Actor: "child",
//             FieldLabel: null,
//             Data: null,
//             CompletedBy: null,
//             AttachmentUrls: []
//           },
//           {
//             Id: 67,
//             Name: "General Liability Insurance",
//             Description:
//               "Update your policy by:\r\n1: Contacting your insurance provider\r\n2: Sending them the “QMerit-BMW Installer Insurance Requirements Guide.pdf” \r\n3: Uploading the updated policy here\r\n\r\nFor questions, contact: customerservice@qmerit.com",
//             Collateral: {
//               Id: 2877,
//               Name: "all good, no expiration",
//               Url:
//                 "https://senstoreqmtqa510612.blob.core.windows.net/collateral/1ec54017-abe6-443f-b096-88935dc0e7d6?sv=2017-07-29&sr=b&sig=vrdRp6yuFPwn9rYYsAGE8yB6MJRsEN%2FiWdBFnrS%2BdjE%3D&se=2019-04-30T03%3A02%3A58Z&sp=r",
//               Expiration: null,
//               ContentType: "application/pdf",
//               CollateralTypeId: 12
//             },
//             Status: "verified",
//             Attachment: {
//               Id: 3490,
//               Name: "sample doc for General",
//               Url:
//                 "https://senstoreqmtqa510612.blob.core.windows.net/collateral/2d58232f-a251-44db-ab81-bd1bd5a9d9d4?sv=2017-07-29&sr=b&sig=Eom%2FUIq9m%2BWs8SnKX%2BbEIpAMnfQqP2%2BqeE0aWCXL1s4%3D&se=2019-04-30T03%3A02%3A58Z&sp=r",
//               Expiration: null,
//               ContentType: "application/pdf",
//               CollateralTypeId: 13
//             },
//             OverridenUntil: null,
//             PreviousNote: {
//               Id: 378,
//               CreatedOn: "2017-10-05T21:14:22.5837186",
//               CreatedById: 228559,
//               CreatedByName: "QMeritMain",
//               Message: null
//             },
//             Type: 1,
//             VerificationRequired: true,
//             Actor: "child",
//             FieldLabel: null,
//             Data: null,
//             CompletedBy: null,
//             AttachmentUrls: []
//           },
//           {
//             Id: 81,
//             Name: "Background Check List",
//             Description:
//               "List all employees who will be performing work and have a background check",
//             Collateral: {
//               Id: 2877,
//               Name: "all good, no expiration",
//               Url:
//                 "https://senstoreqmtqa510612.blob.core.windows.net/collateral/1ec54017-abe6-443f-b096-88935dc0e7d6?sv=2017-07-29&sr=b&sig=vrdRp6yuFPwn9rYYsAGE8yB6MJRsEN%2FiWdBFnrS%2BdjE%3D&se=2019-04-30T03%3A02%3A58Z&sp=r",
//               Expiration: null,
//               ContentType: "application/pdf",
//               CollateralTypeId: 12
//             },
//             Status: "verified",
//             Attachment: null,
//             OverridenUntil: null,
//             PreviousNote: {
//               Id: 382,
//               CreatedOn: "2017-10-05T21:14:30.4858741",
//               CreatedById: 228559,
//               CreatedByName: "QMeritMain",
//               Message: null
//             },
//             Type: 1,
//             VerificationRequired: true,
//             Actor: "child",
//             FieldLabel: null,
//             Data: null,
//             CompletedBy: null,
//             AttachmentUrls: []
//           }
//         ],
//         CompletionPercentage: 0.8333333333333334
//       },
//       {
//         Id: 4322,
//         Name: "Demotest",
//         ConnectionId: 160,
//         Type: 1,
//         IsEnabled: false,
//         Status: "complete",
//         StatusId: 4,
//         IsDefault: false,
//         Requirements: [],
//         CompletionPercentage: 1
//       },
//       {
//         Id: 7675,
//         Name: "BMW Wireless",
//         ConnectionId: 160,
//         Type: 1,
//         IsEnabled: true,
//         Status: "complete",
//         StatusId: 4,
//         IsDefault: true,
//         Requirements: [],
//         CompletionPercentage: 1
//       },
//       {
//         Id: 7706,
//         Name: "Pilot Program -- BMW Wireless",
//         ConnectionId: 160,
//         Type: 1,
//         IsEnabled: true,
//         Status: "complete",
//         StatusId: 4,
//         IsDefault: false,
//         Requirements: [
//           {
//             Id: 74,
//             Name: "BMW Agreement",
//             Description:
//               "Please review and sign: ADDENDUM-BMW EVSE Residential Installer Program Separate.pdf\r\n\r\nFor questions, contact: customerservice@qmerit.com",
//             Collateral: null,
//             Status: "incomplete",
//             Attachment: null,
//             OverridenUntil: "2022-02-12T00:00:00",
//             PreviousNote: {
//               Id: 1010,
//               CreatedOn: "2019-02-11T21:46:58.3467361",
//               CreatedById: 228559,
//               CreatedByName: "QMeritMain",
//               Message: null
//             },
//             Type: 1,
//             VerificationRequired: true,
//             Actor: "child",
//             FieldLabel: null,
//             Data: null,
//             CompletedBy: null,
//             AttachmentUrls: []
//           }
//         ],
//         CompletionPercentage: 0
//       },
//       {
//         Id: 7707,
//         Name: "Flo Program",
//         ConnectionId: 160,
//         Type: 1,
//         IsEnabled: true,
//         Status: "verified",
//         StatusId: 4,
//         IsDefault: false,
//         Requirements: [
//           {
//             Id: 67,
//             Name: "General Liability Insurance",
//             Description:
//               "Update your policy by:\r\n1: Contacting your insurance provider\r\n2: Sending them the “QMerit-BMW Installer Insurance Requirements Guide.pdf” \r\n3: Uploading the updated policy here\r\n\r\nFor questions, contact: customerservice@qmerit.com",
//             Collateral: {
//               Id: 2877,
//               Name: "all good, no expiration",
//               Url:
//                 "https://senstoreqmtqa510612.blob.core.windows.net/collateral/1ec54017-abe6-443f-b096-88935dc0e7d6?sv=2017-07-29&sr=b&sig=vrdRp6yuFPwn9rYYsAGE8yB6MJRsEN%2FiWdBFnrS%2BdjE%3D&se=2019-04-30T03%3A02%3A58Z&sp=r",
//               Expiration: null,
//               ContentType: "application/pdf",
//               CollateralTypeId: 12
//             },
//             Status: "verified",
//             Attachment: {
//               Id: 3490,
//               Name: "sample doc for General",
//               Url:
//                 "https://senstoreqmtqa510612.blob.core.windows.net/collateral/2d58232f-a251-44db-ab81-bd1bd5a9d9d4?sv=2017-07-29&sr=b&sig=Eom%2FUIq9m%2BWs8SnKX%2BbEIpAMnfQqP2%2BqeE0aWCXL1s4%3D&se=2019-04-30T03%3A02%3A58Z&sp=r",
//               Expiration: null,
//               ContentType: "application/pdf",
//               CollateralTypeId: 13
//             },
//             OverridenUntil: null,
//             PreviousNote: {
//               Id: 378,
//               CreatedOn: "2017-10-05T21:14:22.5837186",
//               CreatedById: 228559,
//               CreatedByName: "QMeritMain",
//               Message: null
//             },
//             Type: 1,
//             VerificationRequired: true,
//             Actor: "child",
//             FieldLabel: null,
//             Data: null,
//             CompletedBy: null,
//             AttachmentUrls: []
//           }
//         ],
//         CompletionPercentage: 1
//       },
//       {
//         Id: 7663,
//         Name: "Jaguar",
//         ConnectionId: 160,
//         Type: 1,
//         IsEnabled: false,
//         Status: "complete",
//         StatusId: 4,
//         IsDefault: false,
//         Requirements: [],
//         CompletionPercentage: 1
//       },
//       {
//         Id: 7708,
//         Name: "Juice Ready EV",
//         ConnectionId: 160,
//         Type: 1,
//         IsEnabled: true,
//         Status: "complete",
//         StatusId: 4,
//         IsDefault: false,
//         Requirements: [],
//         CompletionPercentage: 1
//       },
//       {
//         Id: 7813,
//         Name: "eMotorWerks JuiceReady EV",
//         ConnectionId: 160,
//         Type: 1,
//         IsEnabled: true,
//         Status: "complete",
//         StatusId: 4,
//         IsDefault: false,
//         Requirements: [
//           {
//             Id: 223,
//             Name: "Safety License",
//             Description: "For the company's safety",
//             Collateral: null,
//             Status: "complete",
//             Attachment: null,
//             OverridenUntil: null,
//             PreviousNote: null,
//             Type: 2,
//             VerificationRequired: false,
//             Actor: "parent",
//             FieldLabel: "Safety Licence exists?",
//             Data: {
//               CustomString1: "t",
//               CustomBool1: null,
//               CustomInt1: null
//             },
//             CompletedBy: null,
//             AttachmentUrls: []
//           }
//         ],
//         CompletionPercentage: 1
//       }
//     ]
//   }
// ];
