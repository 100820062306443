import styled from 'styled-components';
import {
	mediaMax,
} from "../../../../../shared/style"

const Card = styled.div`
  width: 100%;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  padding: 24px 24px 48px;

  ${mediaMax.phone`
    padding: 24px 16px;
  `}
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
  height: 40px;

  ${mediaMax.phone`
    gap: 8px;
    height: 20px;
  `};
`;

const IconBox = styled.div`
  width: 40px;
  height: 40px;
  display:flex;
  align-items: center;
  justify-content: center;

  ${mediaMax.phone`
    width: 20px;
    height: 20px;
  `};
`;

const TitleIcon = styled.i`
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #3963FE;

  ${mediaMax.phone`
    font-size: 16px;
    line-height: 20px;
  `};
`;

const TitleText = styled.h2`

  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  align-items: center;
  margin: 0px;
  color: #3E4148;

  ${mediaMax.phone`
    font-size: 16px;
    line-height: 20px;
  `};
`;

const Subtitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  margin: 8px 0px 0px 0px;
  padding-left: 56px;
  color: #6E727C;

  ${mediaMax.phone`
    font-size: 14px;
    line-height: 16px;
    padding-left: 28px;
    margin: 4px 0px 0px 0px;
  `};
`;

const Details =styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-left: 56px;

  ${mediaMax.phone`
    padding-left: 28px;
  `};

`;

const DetailsText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin: 48px 0px 0px;
  color: #3E4148;

  ${mediaMax.phone`
    margin: 16px 0px 0px;
  `};
`;


const ViewQuoteButton = styled.button`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 200px;
  height: 40px;
  border: 2px solid #3E4148;
  border-radius: 4px;
  background: white;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #3963FE;
  cursor: pointer;

  &:hover {
    background: #3963fe;
    color: white;
    border: 0px;
  }
`;

export default {
	Card,
	TitleBox,
	IconBox,
	TitleIcon,
	TitleText,
	Subtitle,
  Details,
	DetailsText,
  ViewQuoteButton
}

