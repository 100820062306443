import React, { Fragment } from 'react';
import styledComponents from './styles';

const {
  AddItem,
  IconBox
} = styledComponents;

export default function AddItemButton({ onClick, mobile }) {
  return (
    <Fragment>
      <AddItem onClick={onClick} mobile={mobile}>
        <IconBox><i className="far fa-plus" alt="plus icon" /></IconBox>
        Add Item
      </AddItem>
    </Fragment>
  )
}