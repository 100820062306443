import React, { PureComponent, Fragment } from "react";
import fetchData from "../../../lib/fetchData";
import Loader from "../../../components/Loader";
import FetchFailure from "../../../components/FetchFailure";
import { apiConfig } from "../../../lib/apiConfig";
import _ from "lodash";
import { getHref } from "../../../lib/utils";
import StyledComponent from "./style";
import { HeaderContent } from "../../../components/Wrapper";
import moment from "moment";
class NearbyInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      isLoading: false,
      fetchError: false,
      scrollPosition: false,
      showAssignNote: true
    };
  }

  componentDidMount() {
    this.setState({
      coreLink: `${window.env && window.env.webRoot ? window.env.webRoot : "app.qmerit.com"}`
    });
    document.getElementById("mainHeader").classList.add("noMobile");
    document.getElementById("mapWrapper").classList.add("noMobile");
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    document.getElementById("mainHeader").classList.remove("noMobile");
    document.getElementById("mapWrapper").classList.remove("noMobile");
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll = event => {
    event.preventDefault();
    if (window.scrollY > 36 && this.state.scrollPosition === false) {
      this.setState({ scrollPosition: true });
    } else if (window.scrollY <= 36 && this.state.scrollPosition === true) {
      this.setState({ scrollPosition: false });
    }
  };
  filterSeqTasks = assign => {
    const { surveyLink, token, selectedBid } = this.state;
    let openWinRef = "";
    let isSafari =
      /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ||
      (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/) || navigator.userAgent.match(/iPod/i));
    if (isSafari) {
      openWinRef = window.open();
    }
    if (assign && assign !== undefined) {
      if (isSafari) {
        openWinRef.location = surveyLink;
      } else {
        window.open(surveyLink);
      }
      this.props.surveyLinkDone(true);
    } else {
      const options = {
        ...apiConfig.postConsumerInfo,
        query: token,
        body: {
          ActivityId: selectedBid.Id,
          Type: "Assign"
        }
      };
      fetchData(options).then(data => {
        if (data && data.fetchedData && !data.fetchedData.ErrorCode) {
          if (isSafari) {
            openWinRef.location = surveyLink;
          } else {
            window.open(surveyLink);
          }
          this.props.surveyLinkDone(true);
        } else {
          if (isSafari) {
            // const link = window.location.href.split("/Nearby")[0];
            openWinRef.location = `/taken`;
            setTimeout(() => {
              this.props.surveyLinkDone(true);
            }, 1000);
          } else {
            this.props.surveyLinkDone(false);
          }
        }
      });
    }
  };

  renderLocation = loc => {
    const { NearbyLabelAddress } = StyledComponent;
    const { Location } = this.state.selectedBid;
    const href = getHref(Location);
    return loc ? (
      <NearbyLabelAddress href={href} target="_blank" rel="noopener noreferrer">
        {loc.Address}
        <br />
        {loc.City}, {loc.State} {loc.Zip}
        <br />
        {loc.Country}
      </NearbyLabelAddress>
    ) : (
      "No Site Address Available."
    );
  };

  renderContact = (contact, type) => {
    return contact && contact[type] && <Fragment>{contact[type]}</Fragment>;
  };

  hideAssignNote = () => {
    this.setState({ showAssignNote: false });
  };

  assignment = () => {
    const { Caption } = StyledComponent;
    return (
      <Caption>
        <h4>Starting this survey will assign you to this activity.</h4>
        <i className="far fa-times" onClick={this.hideAssignNote} />
      </Caption>
    );
  };

  render() {
    const { props } = this,
      { dueDateTime, selectedBid } = props;
    let dueDateTimeData = props && dueDateTime.split(" ");
    const { fetchError, isLoading, scrollPosition, fetchedData, showAssignNote } = this.state,
      dateData = dueDateTimeData[0].split("-"),
      customer = selectedBid && selectedBid.Customer,
      contact = customer && customer.Contact,
      loc = selectedBid && selectedBid.Location,
      assignment = selectedBid.Assignment && Object.keys(selectedBid.Assignment).length > 0 && selectedBid.Assignment,
      createdOn = moment.tz(`${selectedBid.CreatedOn}`).format("YYYY-MM-DD, hh:mma z");

    if (fetchError) {
      return <FetchFailure />;
    }
    const {
      Back,
      Wraper,
      Title,
      Activity,
      ContentCard,
      NearbyLabel,
      NearbyLabelContent,
      TimeLeft,
      Connect,
      ButtonWrapper,
      Button,
      Servey,
      DateCard,
      CommonButtonWrapper,
      AttachmentWraper
    } = StyledComponent;
    return (
      <Fragment>
        <Loader isLoading={isLoading}>
          <HeaderContent
            backGround="#ffffff"
            noText={scrollPosition}
            noView={scrollPosition}
            maps={true}
            name={fetchedData.User.Name}
            company={fetchedData.User.CompanyName}
            className="noDesktop"
            previous={this.props.openInfo}
          />
          <Wraper>
            <div>
              <Title textMove={scrollPosition}>
                <Back className="noMobile">
                  <button onClick={() => this.props.openInfo(false)}>
                    <i className="far fa-arrow-left" />
                  </button>
                </Back>

                <div className="main-wrap">
                  <div className="text-wrap">
                    {selectedBid.Name && selectedBid.Name}
                    {/* <i className="fal fa-ellipsis-v noDesktop" /> */}
                  </div>
                  <Activity>Activity #{selectedBid.Id && selectedBid.Id}</Activity>
                </div>
              </Title>

              <ContentCard onDesktop dateWrapper>
                <NearbyLabel>Bid Due</NearbyLabel>
                <DateCard>
                  <div className="date-card">
                    <p className="date-month">{dateData[1]}</p>
                    <p className="date-day">{dateData[2].slice(0, dateData[2].length - 1)}</p>
                  </div>
                  <p className="date-time">{dueDateTimeData[1]}</p>
                  <TimeLeft dueTime={props && props.dueTimeColor}>{props && props.dueTime}</TimeLeft>
                </DateCard>
              </ContentCard>

              <ContentCard mt={24} onMobile>
                <NearbyLabel>Due</NearbyLabel>
                <NearbyLabelContent>{props && props.dueDateTime}</NearbyLabelContent>
                <TimeLeft dueTime={props && props.dueTimeColor}>{props && props.dueTime}</TimeLeft>
              </ContentCard>

              <ContentCard>
                <NearbyLabel>Description</NearbyLabel>
                <NearbyLabelContent>{selectedBid && selectedBid.Description}</NearbyLabelContent>
              </ContentCard>

              {selectedBid &&
                selectedBid.Project &&
                selectedBid.Project.Attachments &&
                selectedBid.Project.Attachments.length > 0 && (
                  <ContentCard>
                    <NearbyLabel>Attachments</NearbyLabel>
                    <AttachmentWraper>
                      {selectedBid.Project.Attachments.map((data, inx) => (
                        <div className="attachment-container" key={inx}>
                          <div className="attachment">
                            <img className="attachment-img" src={data.Url} />
                          </div>
                          {data.Name && <p className="attachment-text">{data.Name}</p>}
                        </div>
                      ))}
                    </AttachmentWraper>
                  </ContentCard>
                )}

              <ContentCard>
                <NearbyLabel>Customer</NearbyLabel>
                <NearbyLabelContent>{customer && customer.Name}</NearbyLabelContent>
              </ContentCard>

              <ContentCard>
                <NearbyLabel>Contact</NearbyLabel>
                <NearbyLabelContent>{contact && `${contact.FirstName} ${contact.LastName}`}</NearbyLabelContent>
                {/* <NearbyLabel faclity>Facility Manager</NearbyLabel> */}
                <Connect call href={`tel:{contact && contact.PhoneNumber}}`}>
                  {this.renderContact(contact, "PhoneNumber")}
                </Connect>
                {contact && contact.PreferredMethod && <NearbyLabel italic>Preferred Contact</NearbyLabel>}
                <Connect email href={`mailto:${contact && contact.Email}`}>
                  {this.renderContact(contact, "Email")}
                </Connect>
              </ContentCard>

              <ContentCard>
                <NearbyLabel>Site</NearbyLabel>
                {this.renderLocation(loc)}
              </ContentCard>
              <ContentCard>
                <NearbyLabel>Priority</NearbyLabel>
                <NearbyLabelContent>{selectedBid.Priority.Description}</NearbyLabelContent>
              </ContentCard>
              <ContentCard>
                <NearbyLabel>Created</NearbyLabel>
                <NearbyLabelContent>{createdOn}</NearbyLabelContent>
              </ContentCard>
            </div>
            <div />
          </Wraper>
          <Servey>
            {showAssignNote && !assignment && this.assignment()}
            <ButtonWrapper
            // className="noDesktop"
            >
              <Button
                onClick={e => {
                  this.filterSeqTasks(assignment);
                }}
              >
                Start Survey
              </Button>
            </ButtonWrapper>
          </Servey>
        </Loader>
      </Fragment>
    );
  }
}

export default NearbyInfo;
