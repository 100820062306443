import React, { PureComponent } from "react";
import { apiConfig } from "../../../lib/apiConfig";
import { getToken } from "../../../lib/getUserData";
import fetchData from "../../../lib/fetchData";
import Message from "../../../components/message";
import Wrapper from "../../../components/Wrapper";
import QuickCard from "../../../components/QuickCard";
import styles from "./styles.module.css";
import Upload from "../../../components/Upload";
import BidChanged from "../../../features/BidStatus/BidChanged";
import BidAlreadySubmitted from "../../../features/BidStatus/BidAlreadySubmitted";
import BidRequest from "../../../components/BidRequest";
import BidComponent from "../../../components/BidComponent/index";
import DateTime from "../../../components/DateTime/index";
import _ from "lodash";
import successNoteIcom from "../../../../../public/images/note-02.svg";

class CustomerContacted extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      formValues: {
        decimal: "",
        message: ""
      },
      uploadArray: [],
      disabled: false,
      fetchSuccess: false,
      showIntegerError: false,
      showOver500: false,
      token: props.token,
      coreLink: "",
      scheduledSiteVisit: false,
      providedBid: false,
      calledEmailedCustomer: false,
      disabledProvidedBid: false,
      disabledScheduledSiteVisit: false
    };
    this.setUploadArray = this.setUploadArray.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    this.setState({
      token: "token=" + this.state.fetchedData.OperationGuids.Submit,
      coreLink: `${
        window.env && window.env.webRoot ? window.env.webRoot : "app.qmerit.com"
      }`
    });
  }

  submitForm() {
    this.decimalNumCheck();
    if (this.state.disabled === true) {
      return;
    } else if (this.state.formValues.decimal) {
      let actions = [],
        QuoteDecimal,
        QuoteFiles,
        QuoteMessage;
      _.map(this.props.fetchedData.Tasks.Actions, (action, index) => {
        if (action.Type === "Currency") {
          QuoteDecimal = action.Id;
        } else if (action.Type === "Upload") {
          QuoteFiles = action.Id;
        } else if (action.Type === "Text") {
          QuoteMessage = action.Id;
        }
      });
      const options = {
        ...apiConfig.postConsumerInfo,
        query: "token=" + this.state.fetchedData.OperationGuids.Submit,
        body: {
          taskId: this.props.fetchedData.Tasks.Id,
          actions: [
            {
              Id: QuoteDecimal,
              CustomDecimal1: this.state.formValues.decimal
            },
            {
              Id: QuoteFiles,
              Files: this.state.uploadArray
            },
            {
              Id: QuoteMessage,
              CustomString1: this.state.formValues.message
            }
          ]
        }
      };
      fetchData(options);
      this.setState({
        fetchSuccess: true
      });
    }
  }

  disableEnterKey(event) {
    if (event.which === 13) {
      event.preventDefault();
    }
  }

  handleChange(event) {
    if (event.target.id === "quoteDecimal") {
      const test1 = /^\d+$/.test(event.target.value),
        test2 = /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{2}$/.test(
          event.target.value
        ),
        test3 = /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{1}$/.test(
          event.target.value
        ),
        test4 = /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{0}$/.test(
          event.target.value
        );
      if (test1 || test2 || test3 || test4) {
        this.changeValue("decimal", event.target.value);
        this.setState({
          showIntegerError: false,
          disabled: false
        });
      } else {
        this.changeValue("decimal", event.target.value);
        this.setState({
          showIntegerError: true,
          disabled: true
        });
      }
    }
    if (event.target.id === "quoteMessage") {
      if (event.target.value.length < 500) {
        this.changeValue("message", event.target.value);
      }
    }
  }

  changeValue(fieldName, value) {
    this.setState({
      formValues: {
        ...this.state.formValues,
        [fieldName]: value
      }
    });
  }

  setUploadArray(array) {
    this.setState({
      uploadArray: array
    });
  }

  decimalNumCheck() {
    if (this.state.formValues.decimal) {
      const test1 = /^\d+$/.test(this.state.formValues.decimal),
        test2 = /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{2}$/.test(
          this.state.formValues.decimal
        );

      let value = test1 === true || test2 === true ? false : true;

      if (test1) {
        let addDeci = `${this.state.formValues.decimal}${".00"}`;
        this.changeValue("decimal", addDeci);
      }
      this.setState({
        showIntegerError: value,
        disabled: value
      });
    }
  }

  blurNumCheck() {
    if (this.state.formValues.decimal) {
      const test1 = /^\d+$/.test(this.state.formValues.decimal),
        test2 = /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{2}$/.test(
          this.state.formValues.decimal
        ),
        test3 = /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{1}$/.test(
          this.state.formValues.decimal
        ),
        test4 = /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{0}$/.test(
          this.state.formValues.decimal
        );

      let value = test1 === true || test2 === true ? false : true;

      if (test1) {
        let addDeci = `${this.state.formValues.decimal}${".00"}`;
        this.changeValue("decimal", addDeci);
      }
      this.setState({
        showIntegerError: value,
        disabled: value
      });
    }
  }

  toggleCheckbox = (e, type) => {
    e.preventDefault();
    if (type === "calledEmailedCustomer") {
      if (this.state.calledEmailedCustomer === false) {
        this.setState({
          providedBid: false,
          calledEmailedCustomer: true,
          scheduledSiteVisit: false,
          disabledProvidedBid: true,
          disabledScheduledSiteVisit: true
        });
      } else {
        this.setState({
          providedBid: false,
          calledEmailedCustomer: false,
          scheduledSiteVisit: false,
          disabledProvidedBid: false,
          disabledScheduledSiteVisit: false
        });
      }
    } else {
      this.setState({ [type]: !this.state[type] });
    }
  };

  render() {
    if (this.state.fetchSuccess) {
      return <BidChanged />;
    }

    return (
      <QuickCard>
        <BidRequest
          title={this.state.fetchedData.Name}
          link={this.state.coreLink}
          requestNumber={this.state.fetchedData.Project.Id}
        />
        <div className={styles.alertMsg}>
          <img src={successNoteIcom} />
          <p>
            Great! We&apos;ll take note that you&apos;ve contacted the
            customer.
          </p>
        </div>
        <div>
          <p className={styles.incMsg}>
            Check the box if you’ve completed any of the following for{" "}
            <strong> {this.state.fetchedData.Customer.Name}</strong>.
          </p>
        </div>
        <div>
          <form className={styles.quoteForm} onKeyPress={this.disableEnterKey}>
            <div className="inp-checkbox">
              <input
                id="c-1"
                type="checkbox"
                checked={this.state.scheduledSiteVisit}
                disabled={this.state.disabledScheduledSiteVisit}
              />
              <label
                for="c-1"
                onClick={e =>
                  this.state.disabledProvidedBid
                    ? {}
                    : this.toggleCheckbox(e, "scheduledSiteVisit")
                }
              >
                Scheduled a site visit
              </label>
              {this.state.scheduledSiteVisit && (
                <div className="child">
                  <p
                    className={`${styles.weSelectText} ${
                      styles.appointmentText
                    }`}
                  >
                    Date and/or Time{" "}
                    <span className={styles.comments}>– Optional</span>
                  </p>
                  <DateTime />
                </div>
              )}
            </div>

            <div className="inp-checkbox">
              <input
                id="c-aa"
                type="checkbox"
                checked={this.state.providedBid}
                disabled={this.state.disabledProvidedBid}
              />
              <label
                for="c-aa"
                onClick={e =>
                  this.state.disabledProvidedBid
                    ? {}
                    : this.toggleCheckbox(e, "providedBid")
                }
              >
                Provided a bid
              </label>
              {this.state.providedBid && (
                <div className="child">
                  <BidComponent className="bid-status" state={this.state} />
                </div>
              )}
            </div>
            <div className="inp-checkbox emailed-cust">
              <input
                id="Called"
                type="checkbox"
                checked={this.state.calledEmailedCustomer}
              />
              <label
                for="Called"
                onClick={e => this.toggleCheckbox(e, "calledEmailedCustomer")}
              >
                Called or emailed the customer
              </label>
            </div>

            <div className={styles.btnWraper}>
              <button className={`${styles.btn} ${styles.btnNote}`}>
                Add A Note
              </button>
              <button className={`${styles.btn} ${styles.btnDone}`}>
                Done
              </button>
            </div>
          </form>
        </div>
      </QuickCard>
    );
  }
}
export default CustomerContacted;
