import React, { Fragment } from 'react';
import StyledComponents from './styles';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';
import ConceptRow from '../ConceptRow';
import _ from 'lodash';
import { currencyFormatter } from '../../helpers/currencyFormatter';

const {
  P_Grayed,
  P_Bold,
  P,
  P_Lrg_Bold
} = commonStyles;

const {
  ConceptsContainer,
  SubtotalRow,
  TotalRow,
  TotalDivider,
  CreditsContainer,
  FinancingContainer,
  CreditsLogo,
  LogoImg,
  UnorderedList
} = StyledComponents;

export default function Concepts({ fetchedBidData, translations }) {

  let isFullySposored = false;

  if (!_.isNil(fetchedBidData.Pricing.Subtotal) && !_.isNil(fetchedBidData.Request.Program.FullySponsoredThreshold))
    isFullySposored = fetchedBidData.Pricing.Subtotal.Amount <= fetchedBidData.Request.Program.FullySponsoredThreshold.Amount;

  return (
    <Fragment>
      {/* Top section to display amounts */}
      <ConceptsContainer>
        <SubtotalRow>
          <P_Bold>{translations.totalSection.total.concepts.subtotal}</P_Bold>
          {/* Display Subtotal short format amount */}
          <P_Bold>
            {isFullySposored ?
              currencyFormatter('0.00', fetchedBidData.Request.Program.ProgramCurrencyType, fetchedBidData.Request.UserLocale)
              :
              fetchedBidData.Pricing.Subtotal.ShortFormat
            }
          </P_Bold>
        </SubtotalRow>
        {/* display Permit Credit Amount if applicable */}
        {isFullySposored ?
          null
          :
          !_.isNil(fetchedBidData.Pricing.PermitCredit) && !_.isEmpty(fetchedBidData.Pricing.PermitCredit) ?
            <ConceptRow
              show={fetchedBidData.Pricing.PermitCredit.Amount}
              concept={translations.totalSection.total.concepts.permitCredit}
              amount={fetchedBidData.Pricing.PermitCredit.ShortFormat}
            />
            :
            null
        }
        {/* show only sponsored if Sponsored credit amount is > 0 and not Null
            otherwise display Installation Credit if it's > 0
            "Show" is the prop used to control conditional rendering using Guard-clauses */}
        {isFullySposored ?
          null
          :
          !_.isNil(fetchedBidData.Pricing.SponsoredCredit) && !_.isEmpty(fetchedBidData.Pricing.SponsoredCredit) ?
            !(fetchedBidData.Pricing.SponsoredCredit.Amount > 0) ?
              <ConceptRow
                show={fetchedBidData.Pricing.InstallationCredit.Amount}
                concept={translations.totalSection.total.concepts.installationCredit}
                amount={fetchedBidData.Pricing.InstallationCredit.ShortFormat}
              />
              :
              <ConceptRow
                show={fetchedBidData.Pricing.SponsoredCredit.Amount}
                concept={translations.totalSection.total.concepts.sponsoredCredit}
                amount={fetchedBidData.Pricing.SponsoredCredit.ShortFormat}
              />
            :
            <ConceptRow
              show={fetchedBidData.Pricing.InstallationCredit.Amount}
              concept={translations.totalSection.total.concepts.installationCredit}
              amount={fetchedBidData.Pricing.InstallationCredit.ShortFormat}
            />
        }
        {/* Display Discount if applicable */}
        {isFullySposored ?
          null
          :
          !_.isNil(fetchedBidData.Pricing.Discounts) && !_.isEmpty(fetchedBidData.Pricing.Discounts) && (
            <UnorderedList>
              {fetchedBidData.Pricing.Discounts.map((discount) => (
                  <ConceptRow
                    key={discount.DiscountId}
                    show={discount.AmountApplied.Amount}
                    concept={discount.Name}
                    amount={discount.AmountApplied.ShortFormat}
                  />
              ))}
            </UnorderedList>
          )
        }
        {/* display Financed Amount if applicable */}
        {isFullySposored ?
          null
          :
          !_.isNil(fetchedBidData.Pricing.FinancedAmount) && !_.isEmpty(fetchedBidData.Pricing.FinancedAmount) ?
            <ConceptRow
              show={fetchedBidData.Pricing.FinancedAmount.Amount}
              concept={translations.totalSection.total.concepts.financedAmount}
              amount={fetchedBidData.Pricing.FinancedAmount.ShortFormat}
            />
            :
            null
        }
        {/* display Deposit if applicable */}
        {isFullySposored ?
          null
          :
          !_.isNil(fetchedBidData.Pricing.Deposit) && !_.isEmpty(fetchedBidData.Pricing.Deposit) ?
            <ConceptRow
              show={fetchedBidData.Pricing.Deposit.Amount}
              concept={translations.totalSection.total.concepts.deposit}
              amount={fetchedBidData.Pricing.Deposit.ShortFormat}
            />
            :
            null
        }
      </ConceptsContainer>
      <TotalRow>
        <P_Lrg_Bold>{translations.totalSection.total.concepts.total}</P_Lrg_Bold>
        {/* Display Total long format amount */}
        <P_Lrg_Bold>
          {
            isFullySposored ?
              (currencyFormatter('0.00', fetchedBidData.Request.Program.ProgramCurrencyType, fetchedBidData.Request.UserLocale) + ' ' + fetchedBidData.Request.Program.ProgramCurrencyType)
              : fetchedBidData.Pricing.Total.LongFormat
          }
        </P_Lrg_Bold>
      </TotalRow>

      {/* section to conditionally display financing message */}
      {(fetchedBidData.Request.Program.FinancingEnabled && !fetchedBidData.Request.AcceptedOperationalTermsOn && fetchedBidData.BidList[0].Status != "confirmed") ?
        <FinancingContainer>
          <P_Grayed>
            Financing is available with {fetchedBidData.Request.Program.PartnerName}.
          </P_Grayed>
        </FinancingContainer>
        :
        null
      }

      {/* display financing submitted message if AcceptedOperationalTermsOn != null  */}
      {fetchedBidData.Request.AcceptedOperationalTermsOn ?
        <FinancingContainer>
          <P_Grayed>
            Financing request submitted! {fetchedBidData.Request.Program.PartnerName} will provide further details.
          </P_Grayed>
        </FinancingContainer>
        :
        null
      }

      {/* Middle section to conditionally display logo (PermitCredit or InstallationCredit > 0 & logo exists) or nothing */}
      {(fetchedBidData.Pricing.InstallationCredit.Amount > 0 || fetchedBidData.Pricing.PermitCredit.Amount > 0 || fetchedBidData.Pricing.SponsoredCredit.Amount > 0) ?
        (fetchedBidData.Request.Program.CreditDescripiton &&
          <Fragment>
            <TotalDivider />
            <CreditsContainer>
              <P>{fetchedBidData.Request.Program.CreditDescripiton}</P>
              {fetchedBidData.Request.Program.Logo &&
                <CreditsLogo>
                  <LogoImg src={fetchedBidData.Request.Program.Logo} alt="No Image" />
                </CreditsLogo>
              }
            </CreditsContainer>
          </Fragment>
        ) :
        null
      }
    </Fragment>
  )
}
