import React, { Fragment } from 'react';
import StyledComponents from './styles';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';

const {
  P_Bold,
  P,
  P_Lrg_Bold,
} = commonStyles;

const {
  ConceptsContainer,
  ConceptRow,
  TotalRow,
  TotalDivider,
  CreditsContainer,
  CreditsLogo,
  LogoImg
} = StyledComponents;

export default function Concepts({ fetchedBidData, translations }) {
  return (
    <Fragment>
      {/* top section to display amounts */}
      <ConceptsContainer>
        <ConceptRow>
          <P_Bold>{translations.totalSection.total.concepts.subtotal}</P_Bold>
          {/* always display Subtotal short format amount */}
          <P_Bold>{fetchedBidData.Pricing.Subtotal.ShortFormat}</P_Bold>
        </ConceptRow>
        {/* display Installation Credit if applicable */}
        {fetchedBidData.Pricing.InstallationCredit.Amount > 0 ?
          <ConceptRow>
            <P>{translations.totalSection.total.concepts.installationCredit}</P>
            <P>- {fetchedBidData.Pricing.InstallationCredit.ShortFormat}</P>
          </ConceptRow> 
          :
          null
        }
        {/* display Permit Credit if applicable */}
        {fetchedBidData.Pricing.PermitCredit.Amount > 0 ?
          <ConceptRow>
            <P>{translations.totalSection.total.concepts.permitCredit}</P>
            <P>- {fetchedBidData.Pricing.PermitCredit.ShortFormat}</P>
          </ConceptRow>
          :
          null
        }
        {/* display Deposit if applicable */}
        {fetchedBidData.Pricing.Deposit.Amount > 0 ?
          <ConceptRow>
            <P>{translations.totalSection.total.concepts.deposit}</P>
            <P>- {fetchedBidData.Pricing.Deposit.ShortFormat}</P>
          </ConceptRow>
          :
          null
        }
      </ConceptsContainer>

      {/* always display Total amount with currency type*/}
      <TotalRow>
        <P_Lrg_Bold>{translations.totalSection.total.concepts.total}</P_Lrg_Bold>
        <P_Lrg_Bold>{fetchedBidData.Pricing.Total.LongFormat}</P_Lrg_Bold>
      </TotalRow>

      {/* middle section to display Program Credit Description and/or Logo if applicable */}
      {fetchedBidData.Pricing.InstallationCredit.Amount > 0 || fetchedBidData.Pricing.PermitCredit.Amount > 0 ?
        fetchedBidData.Request.Program.CreditDescripiton &&
          <Fragment>
            <TotalDivider />
            <CreditsContainer>
              <P>{fetchedBidData.Request.Program.CreditDescripiton}</P>
              {fetchedBidData.Request.Program.Logo &&
                <CreditsLogo>
                  <LogoImg src={fetchedBidData.Request.Program.Logo} alt="logo" />
                </CreditsLogo>
              }
            </CreditsContainer>
          </Fragment>
        :
        null
      }
    </Fragment>
  )
}
