import React, { useState } from 'react';
import styledComponents from './styles';

const {
  ButtonSC,
  IconBox
} = styledComponents;

export default function NewTabButton({ text, url }) {
  const [isClicked, setIsClicked] = useState(false);

  const handleNewTabButtonClick = () => {
    if (!isClicked) {
      setIsClicked(true);
    }
  }

  return (
    <ButtonSC name={text} type="button" onClick={handleNewTabButtonClick} as="a" href={url} target="_blank">
      {isClicked &&
        <IconBox>
          <i className="fas fa-check" alt="check icon" name="check icon" aria-label="check icon" />
        </IconBox>
      }
      {text}
    </ButtonSC>
  )
}