
import React from "react"
import styledComponents from "./style";
//import smileBeam from '../../../../../../public/images/smileBeam.svg'
import rocket from '../../../../../../public/images/rocket.png'

const  {Container, InstallerConfirmedTitle ,InstallerConfirmedMessage, InstallerConfirmedMessageRedirec, Footer} = styledComponents
export const InstallerConfirmed = ({translations}) => {
    return (
        <Container>
            <img alt="Oops" src={rocket}  />
            <InstallerConfirmedTitle>{translations.launchedConfirmation.title}</InstallerConfirmedTitle>
            <InstallerConfirmedMessage>
                {translations.launchedConfirmation.description}
            </InstallerConfirmedMessage>
            <Footer src={`${window.env.blobUrl}/qmerit/wordmark/png/Qmerit-Wordmark-IG400.png`}/>
        </Container>
    )
}