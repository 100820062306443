import React, { Fragment } from 'react';
import styledComponents from './styles';
import { formatNumber } from '../../helper';

const {
  Total
} = styledComponents;

export default function TotalRow({ label, total, uom }) {
  return (
    <Fragment>
      <Total>
        <div className="label">
          <h5>{label}</h5>
        </div>
        <div className="total">
          <h5>{formatNumber(total)} {uom ? uom : null}</h5>
        </div>
      </Total>
    </Fragment>
  )
}