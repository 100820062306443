import styled, { css } from "styled-components";
import {
  theme,
  mediaMax,
  mediaMin,
  fade
} from "../../../../../../shared/style";

const spaceing = 16;
const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: -${spaceing}px;
  margin-left: -${spaceing / 2}px;
  margin-right: -${spaceing / 2}px;
  margin-bottom: 20px;
`;
const ItemWraper = styled.div`
  flex: 0 0 25%;
  max-width: 25%;
  padding-top: ${spaceing}px;
  padding-left: ${spaceing / 2}px;
  padding-right: ${spaceing / 2}px;
  ${mediaMax.phone`flex: 0 0 33.33%; max-width: 33.33%;`};
  ${mediaMin.phone`flex: 0 0 25%; max-width: 25%;`};
`;
const Item = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
  background-color: ${theme.IndiqoGrey.main};
  img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 1px 3px 0 ${fade(theme.Black, 0.2)},
      0 2px 2px 0 ${fade(theme.Black, 0.12)},
      0 0 2px 0 ${fade(theme.Black, 0.14)};
  }
`;

const Time = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: 0.3px;
  text-align: center;
  ${props =>
    props.warning
      ? `color: ${theme.Warning[700]}`
      : `color: ${theme.IndiqoGrey[600]};`};
`;

const Remove = styled.p`
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${theme.CoolGrey};
  text-transform: uppercase;
  cursor: pointer;
`;
const Replace = styled.p`
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${theme.CoolGrey};
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  span {
    cursor: pointer;
    position: relative;
    z-index: 1;
  }
  input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
  }
`;

const UploadButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  i {
    margin-top: 9px;
    background-color: ${theme.Primary.main};
    background-image: linear-gradient(
      to bottom,
      ${theme.Primary.main},
      ${theme.Primary.mainDark}
    );
    color: ${theme.White};
    border-radius: 100%;
    height: 33px;
    width: 33px;
    position: relative;
    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  p {
    margin-bottom: 0;
    margin-top: 9px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    color: ${theme.Primary.main};
  }
`;

export default {
  Root,
  Item,
  ItemWraper,
  Time,
  Remove,
  UploadButton,
  Replace
};
