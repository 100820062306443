import styled from "styled-components";
import { mediaMax, mediaMin } from "../../../../shared/style";

const InputWrapperOne = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16px;
  flex: 2.8 1 0;

  ${mediaMax.tablet`
    width: 100%;
    flex: initial;
    gap: 0px;
    justify-content: space-between
  `}
`;

const InputWrapperTwo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16px;
  flex: 1.5 1 0;

  ${mediaMax.tablet`
    width: 100%;;
    border-radius: 4px;
    border-top: 1px solid #DEDFE2;
  `}
`;

const InputWrapperThree = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16px;
  flex: 1.5 1 0;

  ${mediaMax.tablet`
    justify-content: flex-end;
    gap: 0px;
  `}
`;

const MobileRemoveWrapper = styled.div`
  display: none;
  align-items: center;
  flex-direction: row;
  gap: 16px;
  flex: 1.5 1 0;

  ${mediaMax.tablet`
    display: flex;
  `}
`;

const InputField = styled.div`
  display: flex;
  padding: 12px;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 8px;
  border-radius: 4px;
  border: ${({ readOnly }) => readOnly ? 'none' : '1px solid #DEDFE2'};
  background: ${({ readOnly }) => readOnly ? '#FFF' : '#FAFAFA'};
  white-space: nowrap;
  flex: 1 1 0;

    ${mediaMax.tablet`
      ${props =>
      props.left && `justify-content: space-between;`}
      ${props =>
      props.heavyload == "true" ? ` 
          width: 30%;
          flex: initial;
          border-radius: 4px;
          border: 1px solid var(--input-default-stroke, #DEDFE2);
        ` : `width: 50%`}
      ${props =>
      !props.left && props.readOnly && !props.heavyload && `padding-right: 0px;`}
      background: ${({ readOnly, heavyload }) => readOnly && heavyload ? 'var(--input-default-fill, #FAFAFA);' : readOnly && !heavyload ? '#F4F5F6' : '#FFFFFF'};
    `}

  input {
    border: none;
    outline: none;
    background: transparent;
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #3E4148;
    padding: 0px;
    cursor: ${({ readOnly }) => readOnly ? 'default' : 'text'};
   

    ${mediaMax.tablet`
      flex: initial;
      justify-content: space-between;
      ${props => props.left ? `text-align: left` : `text-align: right`}
      ${props => props.content && `width: ${props.content.length}ch`}
      ${props => props.heavyload && `width: 40%; min-width: 30px;`}
    `}
  }

  label {
    display: flex;
    color: ${({ readOnly }) => readOnly ? '#6E727C' : 'rgba(62, 65, 72, 0.35)'};
    justify-content: flex-end;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${mediaMax.tablet`
      justify-content: center;
    `}
  }
`;

const Input = styled.input`
  display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #DEDFE2;
  background: #FAFAFA;
  margin: 0px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #3E4148;
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1.7 1 0;
  cursor: ${({ readOnly }) => readOnly ? 'default' : 'text'};

  ${mediaMax.tablet`
    flex: 0 1 auto;
    width: 100%;
    background: var(--white-white-100, #FFF);;
  `}
`;

const BreakerIdInput = styled(Input)`
  flex: 1 1 0;
`;

const BreakerIdInputAC = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  flex: 1.25 1 0;

  input {
    display: flex;
    padding: 12px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid #DEDFE2;
    background: #FAFAFA;
    margin: 0px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #3E4148;
    text-align: start;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: ${({ readOnly }) => readOnly ? 'default' : 'text'};
    width: 100%;

    ${mediaMax.tablet`
      background: #FFF;
    `}
  }

  ${mediaMax.tablet`
    flex: none;
    width: 100%;
  `}
`;

const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;

  i {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #B9BBC1;
  }
`;

const XMobileIconBox = styled(IconBox)`
  ${mediaMin.tablet`
    display: none;
  `}
  ${mediaMax.tablet`
    display: flex;
  `}
`;

const XDesktopIconBox = styled(IconBox)`
  ${mediaMax.tablet`
    display: none;
  `}
`;

const Result = styled.div`
  display: flex;
  padding: 12px;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 8px;
  margin: 0px;
  text-align: end;
  flex: 1 1 0;

  .acGrayText {
    color: #969BA5;
  }

  .wattsText {
    color: ${({ wattsGray }) => wattsGray ? '#969BA5' : '#6E727C'};
  }

  ${mediaMax.tablet`
    flex: 0 1 auto;
    padding: 12px 0px 12px 8px;
  `}  
`;

const Eighty = styled.div`
  display: flex;
  padding: 12px;
  align-items: flex-start;
  justify-content: center;

  ${mediaMax.tablet`
    padding: 12px 0px;
  `}
`;

const MobileEighty = styled.div`
  display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;

  ${mediaMax.tablet`
    gap: 4px;
    width: 40%;
  `}  
`;

export default {
  InputWrapperOne,
  InputWrapperTwo,
  InputWrapperThree,
  MobileRemoveWrapper,
  InputField,
  Input,
  BreakerIdInput,
  BreakerIdInputAC,
  IconBox,
  XMobileIconBox,
  XDesktopIconBox,
  Result,
  Eighty,
  MobileEighty
}