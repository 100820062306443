import styled from 'styled-components';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LineItemStyle1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 16px;

  .header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;

    .namedColumn {
      display: flex;
      padding: 8px;
      justify-content: center;
      align-items: center;
      color: #6E727C;
      flex: 1 1 0;
      text-align: center;
      /* white-space: nowrap; */
      /* min-height: 100%; */
      /* min-width: 0; */
    }

    .iconColumn {
      display: flex;
      width: 20px;
      padding: 8px;
      justify-content: center;
      align-items: center;
      color: #FFF;
    }
  }

  .pGray {
    color: #6E727C;
  }

  .totals {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
    width: 100%;

    .numberColumn {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding: 12px;
      flex: 1 1 0;
    }
  }
`;

const LineItemStyle2 = styled(LineItemStyle1)`
  .header {
    width: 70%;

    .namedColumn {
      .pHidden {
        color: #0000;
      }
    }
  }
  .totals {
    width: 70%;
  }
`;

const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin: 12px 0;

  i {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #B9BBC1;
  }
`;

export default {
  Content,
  LineItemStyle1,
  LineItemStyle2,
  IconBox
}