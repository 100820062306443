import moment from 'moment-timezone';

const formatter = {
  rawDate: new Date(),
  formattedUTCDate: undefined,
  longDateWithoutDay: undefined,
  shortDateWithoutDay: undefined,
  formattedDate: undefined,
  formattedTime: undefined,
  setRawDate(date) {
    this.rawDate = date ? date : new Date();
    return this;
  },
  setUtcDateWithZ() {
    this.formattedUTCDate = moment(this.rawDate).format('YYYY-MM-DDTHH:mm:ss.SSSSZ', true);
    return this;
  },
  setUtcDate() {
    this.formattedUTCDate = moment(this.rawDate).format('YYYY-MM-DDTHH:mm');
    return this;
  },
  setDate() {
    this.formattedDate = moment
      .utc(this.formattedUTCDate)
      .local()
      .format('YYYY-MMM-DD');
    return this;
  },
  setTime() {
    this.formattedTime = moment
      .utc(this.formattedUTCDate)
      .local()
      .format('h:mma');
    return this;
  },
  getDate(date) {
    this.setRawDate(date)
      .setUtcDate(this.rawDate)
      .setDate();
    return this.formattedDate;
  },
  getTime(date) {
    this.setRawDate(date)
      .setUtcDate(this.rawDate)
      .setTime();
    return this.formattedTime;
  },
  getDateTime(date) {
    this.setRawDate(date)
      .setUtcDate(this.rawDate)
      .setDate()
      .setTime();

    const timeZoneAbbr = moment.tz(moment.tz.guess()).format('z');

    return {
      formattedTime: `${this.formattedTime} ${timeZoneAbbr}`,
      formattedDate: this.formattedDate.toUpperCase(),
      timeZone: `${timeZoneAbbr}`,
    };
  },
  getDateWithZTime(date) {
    this.setRawDate(date)
      .setUtcDateWithZ(this.rawDate)
      .setDate()
      .setTime();

    const timeZoneAbbr = moment.tz(moment.tz.guess()).format('z');

    return {
      formattedTime: `${this.formattedTime} ${timeZoneAbbr}`,
      formattedDate: this.formattedDate.toUpperCase(),
      timeZone: `${timeZoneAbbr}`,
    };
  },
  setFormattedDate(dates, format){
    let options = { year: 'numeric', month: 'long', day: 'numeric' };
    this.formattedDate = new Date(dates).toLocaleDateString(format.replace('_','-'), options);

    return this;
  },
  getDateString(date, format) {
    this.setFormattedDate(date, format)

    return {
      formattedDate: this.formattedDate,
    };
  },
  setIntlFormattedDateTime(date, format, timeZone){
    let dateOptions = { weekday: 'long', year: "numeric", month: "short", day: "numeric" };
    let timeOptions = { hour: "numeric", minute: "numeric", timeZoneName: "short", timeZone: timeZone };
    let longWithoutDayOptions = { year: "numeric", month: "long", day: "numeric" };
    let shortWithoutDayOptions = { year: "numeric", month: "short", day: "numeric" };
    const languageTag = format ? format.trim().replace('_', '-') : 'en-US';
    this.formattedDate = new Date(`${date}Z`).toLocaleDateString(languageTag, dateOptions);
    this.formattedTime = new Date(`${date}Z`).toLocaleString(languageTag, timeOptions);
    this.longDateWithoutDay = new Date(`${date}Z`).toLocaleString(languageTag, longWithoutDayOptions);
    this.shortDateWithoutDay = new Date(`${date}Z`).toLocaleString(languageTag, shortWithoutDayOptions);
    return this;
  },
  getIntlFormattedDateTime(date, format, timeZone) {
    this.setIntlFormattedDateTime(date, format, timeZone)
    return {
      formattedDate: this.formattedDate,
      formattedTime: this.formattedTime,
      longDateWithoutDay: this.longDateWithoutDay,
      shortDateWithoutDay: this.shortDateWithoutDay
    };
  },
};

export default formatter;
