import React, { Component } from 'react';
import QuickCard from '../../../../components/QuickCard';
import Radio from "../../../../components/Radio";
import EstimatesImg from '../../../../../../public/images/estimates-logo.svg';
import QmeritLogo from '../../../../components/Powered-by-Qmerit-Indiqo-Grey';
import { Button } from '../../../../shared/style';
import { Wrapper } from '../../styles';
import StyledComponents from './styles';

const { CardContainer, RadioContainer } = StyledComponents;

class EstimatesView extends Component {
    state = {
        selectedCompanies: [],
        disableSubmit: true
    }

    handleChange = (event) => {
        const { value } = event.target
        let { selectedCompanies } = this.state

        if(selectedCompanies.includes(value)) {
            selectedCompanies.splice(selectedCompanies.indexOf(value), 1)
        } else {
            selectedCompanies.push(value)
        }

        this.setState({ 
            selectedCompanies, 
            disableSubmit: selectedCompanies.length === 0 
        })
    }

    render() {
        const { companies, onSubmit } = this.props;
        const { disableSubmit, selectedCompanies } = this.state;

        return (
            <Wrapper>
                <QuickCard maxWidth='480px'>
                    <CardContainer>
                        <img alt="estimates-logo" src={EstimatesImg} />
                        <RadioContainer>
                            <p>Have you received an estimate or scheduled a site visit with any of the following companies?</p>
                            {companies.map((company, inx) => {
                                return (
                                    <Radio
                                        key={inx}
                                        name={company}
                                        id={inx}
                                        label={company}
                                        onChange={this.handleChange}
                                        square
                                        checkBox
                                    />
                                );
                            })}
                        </RadioContainer>
                        <Button 
                            action={'action'}
                            className={disableSubmit?"disabled":""}
                            disabled={disableSubmit}
                            onClick={() => onSubmit({ selectedCompanies })}>
                            Done
                        </Button>
                    </CardContainer>
                </QuickCard>
                <QmeritLogo />
            </Wrapper>
        )
    }
}

export default EstimatesView;
