
import React from "react"
import styledComponents from "./style";
import Grimace from "../../../../../public/images/Grimace@3x.png";


const { Container, ThanksForUpdate, ThanksForUpdateText, Footer } = styledComponents
export const TierUpdateFail = ({ translations, country }) => {

    let csEmail =  window.env.csEmail;
    switch (country) {
        case 'CA':
            csEmail = window.env.caCsEmail;
            break;
        case 'GB':
            csEmail = window.env.ukCsEmail;
            break;
    }

    return (
        <Container>
            <img alt="Oops" style={{ height: 110 }} src={Grimace} />
            <ThanksForUpdate>{translations.tierUpdateFail.messageOne}&hellip;</ThanksForUpdate>
            <ThanksForUpdateText>{translations.tierUpdateFail.messageTwo}{" "}
                <a href={`mailto:${csEmail}`}>
                    {csEmail}
                </a>
            </ThanksForUpdateText>
            <Footer src={`${window.env.blobUrl}/qmerit/wordmark/png/Qmerit-Wordmark-IG400.png`} />
        </Container>

    )
}