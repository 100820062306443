import React, { PureComponent } from "react";
import Upload from "../Upload/index";
import styledComponents from "./style";
const {
  Root,
  Title,
  TotalEstimatedWraper,
  TotalEstimatedCostInputWraper,
  TotalEstimatedCostBtnWraper,
  QuoteDollar,
  QuoteErrorContainer
} = styledComponents;

export const BidInput = props => {
  const {
    label = { cost: "Total Estimated Cost" },
    value,
    formValues,
    placeholder
  } = props;
  let inputValue =
    value !== ""
      ? value
      : formValues && formValues.decimal && formValues.decimal;
  return (
    <div>
      <Title>{label.cost}</Title>
      <TotalEstimatedWraper error={props.showIntegerError}>
        <TotalEstimatedCostInputWraper>
          <QuoteDollar>$</QuoteDollar>
          <input
            id="quoteDecimal"
            value={inputValue || ""}
            onChange={props.handleChange}
            placeholder={placeholder ? placeholder : ""}
          />
        </TotalEstimatedCostInputWraper>
        <TotalEstimatedCostBtnWraper error={props.showIntegerError}>
          <button
            onClick={e => {
              e.preventDefault();
            }}
          >
            USD
          </button>
        </TotalEstimatedCostBtnWraper>
      </TotalEstimatedWraper>
      {props.showIntegerError && (
        <QuoteErrorContainer>
          <span>
            <i className="far fa-exclamation-circle" />
          </span>
          <div>Please enter a valid amount.</div>
        </QuoteErrorContainer>
      )}
    </div>
  );
};

class BidComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const props = this.props;
    const { className } = props;

    return (
      <Root className={`${className}`}>
        <BidInput {...props} />
        <Upload
          setUploadArray={props.setUploadArray}
          uploadZoneSize={props.uploadZoneSize}
        />
      </Root>
    );
  }
}
export default BidComponent;
