import React, { Fragment } from 'react';
import styledComponents from './styles';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';
import Dropdown from '../Dropdown';
import DeleteItemButton from '../DeleteItemButton';
import { SVGToolTip } from '../ToolTip';
import { getActualLoad, getProduct, formatNumber } from '../../helper';
import { heavyLoadOptions } from '../Dropdown/options';

const {
  P,
} = commonStyles;

const {
  InputField,
  Input,
  BreakerIdInput,
  BreakerIdInputAC,
  IconBox,
  XMobileIconBox,
  XDesktopIconBox,
  Result,
  Eighty,
  MobileEighty,
  InputWrapperOne,
  InputWrapperTwo,
  InputWrapperThree,
  MobileRemoveWrapper
} = styledComponents;

function svgWarning() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
      <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"
        fill='#3963fe'
      />
    </svg>
  )
}

export default function InputsRow({ category, description, name, value1, uom1, value2, uom2, total, deleteRow, handleChange, newRow }) {
  return (
    <Fragment>

      {category === 'General' &&
        <Input
          type="text"
          name="name"
          defaultValue={name}
          readOnly={true}
          aria-label={name}
          title={name}
        />
      }
      {category === 'Appliance' &&
        <Input
          type="text"
          name="name"
          placeholder={'Appliance'}
          defaultValue={name}
          onChange={handleChange}
          readOnly={false}
          aria-label={name ? name : 'Appliance'}
          title={name ? name : 'Appliance'}
        />
      }
      {category === 'HeavyLoad' && description === 'AC' && total !== 0 ?
        <BreakerIdInputAC>
          <SVGToolTip svgIcon={svgWarning()}>
            This line is not calculated with the Heavier Loads subtotal and has been moved to the A/C Load section below.
          </SVGToolTip>
          <input
            type="text"
            name="name"
            placeholder={'Breaker#'}
            defaultValue={name}
            onChange={handleChange}
            readOnly={false}
            aria-label={name}
            title={name}
          />
        </BreakerIdInputAC>
        :
        category === "HeavyLoad" &&
        <BreakerIdInput
          type="text"
          name="name"
          placeholder={'Breaker#'}
          defaultValue={name}
          onChange={handleChange}
          readOnly={false}
          aria-label={name ? name : 'Breaker#'}
          title={name ? name : 'Breaker#'}
        />
      }




      {category === 'HeavyLoad' &&
        <Dropdown options={heavyLoadOptions} defaultVal={description} handleChange={handleChange} />
      }



      <InputWrapperOne>
        {category === 'HeavyLoad' ?
          description === 'AC' ?
            <InputField readOnly={false} left='true' heavyload="true" content={value1.toString()}>
              <input
                type="number"
                name="value1"
                defaultValue={value1}
                onBlur={handleChange}
                readOnly={false}
                aria-label={value1 + ' ' + uom1}
                title={value1 + ' ' + uom1}
              />
              <label>{uom1}</label>
            </InputField>
            :
            <InputField readOnly={false} left='true' heavyload="true" content={value1.toString()}>
              <input
                type="number"
                name="value1"
                defaultValue={value1}
                onChange={handleChange}
                readOnly={false}
                aria-label={value1 + ' ' + uom1}
                title={value1 + ' ' + uom1}
              />
              <label>{uom1}</label>
            </InputField>
          :
          <InputField readOnly={false} left='true'>
            <input
              type="number"
              name="value1"
              defaultValue={value1}
              onChange={handleChange}
              readOnly={false}
              aria-label={value1 + ' ' + uom1}
              title={value1 + ' ' + uom1}
            />
            <label>{uom1}</label>
          </InputField>
        }


        <XDesktopIconBox><i className="far fa-times" alt="x icon" aria-label="x icon" /></XDesktopIconBox>


        {category === 'HeavyLoad' &&
          <MobileEighty>
            <XMobileIconBox className="mobile"><i className="far fa-times" alt="x icon" aria-label="x icon" /></XMobileIconBox>
            <Eighty>
              <P>0.80</P>
            </Eighty>
            <IconBox><i className="far fa-times" alt="x icon" aria-label="x icon" /></IconBox>
          </MobileEighty>
        }


        {category === 'HeavyLoad' ?
          description === 'AC' ?
            <InputField readOnly={newRow ? false : true} heavyload="true" content={value2.toString()}>
              <input
                type="number"
                name="value2"
                defaultValue={value2}
                onBlur={handleChange}
                readOnly={newRow ? false : true}
                aria-label={value2 + ' ' + uom2}
                title={value2 + ' ' + uom2}
              />
              <label>{uom2}</label>
            </InputField>
            :
            <InputField readOnly={newRow ? false : true} heavyload="true" content={value2.toString()}>
              <input
                type="number"
                name="value2"
                defaultValue={value2}
                onChange={handleChange}
                readOnly={newRow ? false : true}
                aria-label={value2 + ' ' + uom2}
                title={value2 + ' ' + uom2}
              />
              <label>{uom2}</label>
            </InputField>
          :
          category === 'General' ?
            <InputField readOnly={true} content={value2.toString()}>
              <XMobileIconBox><i className="far fa-times" alt="x icon" aria-label="x icon" /></XMobileIconBox>
              <input
                type="number"
                name="value2"
                defaultValue={value2}
                onChange={handleChange}
                readOnly={true}
                aria-label={value2 + ' ' + uom2}
                title={value2 + ' ' + uom2}
              />
              <label>{uom2}</label>
            </InputField>
            :
            <InputField readOnly={newRow ? false : true} content={value2.toString()}>
              <XMobileIconBox><i className="far fa-times" alt="x icon" aria-label="x icon" /></XMobileIconBox>
              <input
                type="number"
                name="value2"
                defaultValue={value2}
                onChange={handleChange}
                readOnly={newRow ? false : true}
                aria-label={value2 + ' ' + uom2}
                title={value2 + ' ' + uom2}
                content={value2}
              />
              <label>{uom2}</label>
            </InputField>
        }
      </InputWrapperOne>


      <InputWrapperTwo>
        <DeleteItemButton onClick={deleteRow} mobile={true} />
        <InputWrapperThree>
          <IconBox><i className="far fa-equals" alt="equals" aria-label="equals" /></IconBox>
          <Result wattsGray={category === 'HeavyLoad' && description === 'AC' && total !== 0}>
            {category === 'HeavyLoad' ?
              description === 'AC' && total !== 0 ?
                <P className="acGrayText">{formatNumber(getActualLoad(value1, value2))}</P>
                :
                <P>{formatNumber(getActualLoad(value1, value2))}</P>
              :
              <P>{formatNumber(getProduct(value1, value2))}</P>
            }
            <P className='wattsText'>watts</P>
          </Result>
        </InputWrapperThree>
      </InputWrapperTwo>



      <DeleteItemButton onClick={deleteRow} mobile={false} />
    </Fragment>
  )
}