import React, { PureComponent, Fragment } from "react";
import Modal from "../../../../components/Modal";
import StyledComponent from "../style";
import { mediaMax, headerSizes, Button } from "../../../../shared/style";
import * as cf from "./methods";
import _ from "lodash";
import styled from "styled-components";
import LocationTracker from "../../../../../../public/images/location-tracker.svg";
import telegramIcon from "../../../../../../public/images/telegram.svg";
import searchLocationMobile from "../../../../../../public/images/SearchLocation-02.svg";

const ContentAlert = styled("div")`
  padding: 15px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - ${headerSizes.desktop * 2}px);
  ${mediaMax.tablet`
    margin-top: ${headerSizes.phone}px;
    min-height: calc(100vh - ${headerSizes.phone * 2}px);
  `};
`;

class SearchLocation extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchedLocation: "",
      searchFocus: false
    };
  }
  searchFocusHandle = active => {
    this.setState({ searchFocus: active });
  };

  enterPressed = e => {
    if (e.keyCode === 13) {
      this.search();
    }
  };

  searchLocation = (e, val) => {
    this.setState({
      searchFocus: true,
      searchedLocation: val ? val === "clear" && "" : e.target.value
    });
  };
  search = e => {
    this.setState({ searchFocus: false });
    this.props.setCustomLocation(e, this.state.searchedLocation);
  };
  render() {
    const {
      MobileSearchCard,
      FilterBtn,
      SearchWrapper,
      CancleSearch,
      InpWrapper,
      SearchTextField,
      SearchButton,
      SearchCancleButton
    } = StyledComponent;
    document.body.classList.add("popUpBlur");
    const { searchFocus, searchedLocation } = this.state,
      { showOnly } = this.props.filterCount;
    return (
      <Fragment>
        <MobileSearchCard>
          {/* <LineBtn onClick={() => this.searchFocusHandle(!searchFocus)} /> */}
          <SearchWrapper>
            <InpWrapper>
              <SearchTextField
                value={searchedLocation}
                isFocused={searchFocus}
                onFocus={() => this.searchFocusHandle(true)}
                placeholder={"Search locations and items…"}
                onChange={e => this.searchLocation(e)}
                onKeyUp={this.enterPressed}
                type="search"
              />
              {!searchFocus && <i className="n-search far fa-search" />}
              <SearchButton
                searchFocus={searchFocus}
                onClick={this.search}
                submit={true}
              >
                <i className="fal fa-search" />
              </SearchButton>
              <SearchCancleButton
                searchFocus={searchFocus}
                // onClick={e => this.searchLocation(e, null)}
                submit={true}
              >
                <i
                  className="far fa-times"
                  onClick={e => this.searchLocation(e, "clear")}
                />
              </SearchCancleButton>
            </InpWrapper>
            {searchFocus ? (
              <CancleSearch onClick={() => this.searchFocusHandle(false)}>
                CANCEL
              </CancleSearch>
            ) : (
              <FilterBtn onClick={() => this.props.showFilters()}>
                <span className="notification">
                  {showOnly && showOnly !== "" ? "2" : "1"}
                </span>
                <i className="fas fa-sliders-h" />
                <p className="filter-text">Filter</p>
              </FilterBtn>
            )}
          </SearchWrapper>
        </MobileSearchCard>
      </Fragment>
    );
  }
}

export const AccessLocationAlert = props => {
  const {
    IconMarker,
    AlertTitle,
    ButtonWraper,
    MButton,

    AlertDescription
  } = StyledComponent;
  return (
    <ContentAlert>
      <div style={{ maxWidth: 327 }}>
        <IconMarker>
          <img src={LocationTracker} />
        </IconMarker>
        <AlertTitle bottomSpace={8}>Can you share your location?</AlertTitle>
        <AlertDescription bottomSpace={24}>
          We’ll use your location to find available items around you.
        </AlertDescription>
        <ButtonWraper>
          <Button
            style={{ maxWidth: 279 }}
            mb={16}
            onClick={() => props.cancelLocation()}
            submit={false}
          >
            NO THANKS
          </Button>
          <Button
            style={{ maxWidth: 279 }}
            action
            onClick={() => props.allowLocation()}
            submit={true}
          >
            SURE
          </Button>
        </ButtonWraper>
      </div>
    </ContentAlert>
  );
};

export const LocationNotFoundAlert = () => {
  const { IconMarker, AlertDescription } = StyledComponent;
  return (
    <ContentAlert>
      <div style={{ maxWidth: 327 }}>
        <IconMarker>
          <img src={telegramIcon} />
        </IconMarker>
        <AlertDescription bottomSpace={24}>
          To find nearby items, turn on your Location Services. Then, you will
          want to make sure your web browser’s location access is enabled.
        </AlertDescription>
      </div>
    </ContentAlert>
  );
};

export const NoItemsFoundAlert = () => {
  const { IconMarker, AlertDescription } = StyledComponent;
  return (
    <ContentAlert>
      <div style={{ maxWidth: 327 }}>
        <IconMarker>
          <img src={searchLocationMobile} />
        </IconMarker>
        <AlertDescription bottomSpace={24}>
          We can’t find any items nearby—it seems there are no available
          activities in your area.
        </AlertDescription>
      </div>
    </ContentAlert>
  );
};

export const DetailedItem = props => {
  const { Items } = StyledComponent;
  return (
    <Items>
      {_.map(props.fetchedData.Activities, (data, index) => {
        if (data !== -1) {
          return renderEachItem(
            data,
            index,
            props.locationDiffArray,
            props.openInfoPage
          );
        }
      })}
    </Items>
  );
};

export const renderEachItem = (
  data,
  index,
  locationDiffArray,
  openInfoPage
) => {
  const {
    Item,
    Icon,
    Info,
    InfoWraper,
    Holder,
    Name,
    Mi,
    Ca,
    Time
  } = StyledComponent;
  const surveyLink = cf.getSurvey(data.Tasks);
  if (surveyLink !== undefined && surveyLink !== "" && surveyLink !== null) {
    const dueTime = cf.getDueTime(data.localDueDate, data.localDueTime);
    const dueDateTime = `${data.localDueDate}, ${data.localDueTime}`;
    const dueTimeColor = cf.getDueTimeColor(dueTime);
    const locationDiff = cf.findWithAttr(locationDiffArray, "id", data.Id);
    return (
      <Item
        key={index}
        style={{
          animationDelay: `${index / 16}s`,
          zIndex: index + 1
        }}
        onClick={() =>
          openInfoPage({
            openInfo: true,
            record: locationDiff,
            surveyLink,
            dueDateTime,
            dueTime,
            dueTimeColor
          })
        }
      >
        <Icon>
          {data.Assignment && Object.keys(data.Assignment).length ? (
            <i className={"fas fa-user-circle"} />
          ) : (
            <i className="far fa-clipboard-list" />
          )}
        </Icon>
        <Info>
          <InfoWraper>
            <Holder>
              <Name>
                {data.Project && data.Project.Name ? data.Project.Name : ""}
              </Name>
              <Mi>
                {locationDiff.value !== undefined &&
                  Number(locationDiff.value).toFixed(2)}{" "}
                mi
              </Mi>
            </Holder>
            <Holder>
              {data.Location && (
                <Ca>
                  {data.Location.City}, {data.Location.State}{" "}
                  {data.Location.Zip}
                </Ca>
              )}
              <Time dueTime={dueTimeColor}>{dueTime}</Time>
            </Holder>
          </InfoWraper>
        </Info>
      </Item>
    );
  }
};

export default SearchLocation;
