import React, { PureComponent, Fragment } from "react";
import styledComponent from "./style";
import commonStyledComponents from "../../../features/RequirementStatus/Card/CollapseData/commanStyle";
import Switch from "../../Switch";
import UploadForShow from "../../../features/RequirementStatus/Card/CollapseData/Components/Upload";
import _ from "lodash";
import DateTimeModal from "../../../components/DateTime";
import * as m from "./methods";

class UploadModal extends PureComponent {
  constructor(props) {
    super(props);
    // const date = props.expirationDate && new Date(props.expirationDate);
    // const day = date && date.getDate(),
    //   month = date && date.getMonth() + 1,
    //   year = date && date.getFullYear();
    this.state = {
      // noExpiration: props.expiration || false,
      // name: props.name || null,
      // date: { year: year || null, month: month || null, day: day || null },
      // type: props.type || "",
      noExpiration: false,
      name: null,
      date: { year: null, month: null, day: null },
      type: "",
      showCalendar: false,
      documentType: false
    };
  }

  checkDates = (length, value, min, max) => {
    let val;
    var pattern = /^\d+$/;
    if (pattern.test(value)) {
      if (value.length === length) {
        if (min <= value && value < max) {
          val = value;
        } else if (min >= value) {
          val = min;
        } else {
          val = max;
        }
      } else if (value.length > length) {
        val = max;
      } else {
        val = value;
      }
    } else {
      val = value.slice(0, value.length - 1);
    }
    return val;
  };

  inputChange = (e, field) => {
    let value = field === "type" ? e : e && e.target && e.target.value;
    const minYear = 1969,
      maxYear = 2068,
      minMonth = 1,
      maxMonth = 12,
      minDay = 1,
      maxDay = 30;
    let val;
    switch (field) {
      case "year":
        val = m.checkDates(4, value, minYear, maxYear);
        this.setState({ date: { ...this.state.date, year: val } });
        break;
      case "day":
        val = m.checkDates(2, value, minDay, maxDay);
        this.setState({ date: { ...this.state.date, day: val } });
        break;
      case "month":
        val = m.checkDates(2, value, minMonth, maxMonth);
        this.setState({ date: { ...this.state.date, month: val } });
        break;
      case "name":
        this.setState({ name: value });
        break;
      case "type":
        this.setState({ type: value, documentType: !this.state.documentType });
        break;
    }
  };

  toggleSwitch = () => {
    this.setState({ noExpiration: !this.state.noExpiration });
  };

  numberInput = (className, placeholder, value) => {
    const { Input } = commonStyledComponents;
    return (
      <Input
        className={className}
        placeholder={placeholder}
        value={value}
        onChange={e => this.inputChange(e, className)}
      />
    );
  };

  valid(val) {
    if (val !== null && val !== undefined && val !== "" && val !== false)
      return true;
  }

  submit = () => {
    const { noExpiration, name, date, type } = this.state;
    if (
      (m.valid(noExpiration) ||
        (m.valid(date.day) && m.valid(date.month) && m.valid(date.year))) &&
      m.valid(name) &&
      m.valid(type)
    ) {
      this.props.saveModal({ ...this.state });
    }
  };

  showCalendar = () => {
    this.setState({ showCalendar: true });
  };

  getDate = (val, currentTime) => {
    let month =
      `${val.month}`.length < 2 ? "0" + (val.month + 1) : val.month + 1;
    let day = `${val.date}`.length < 2 ? "0" + val.date : val.date;
    let date = { year: val.year, month, day };
    this.setState({ date, showCalendar: false, currentTime });
  };

  dropDownItems = () => {
    const { DropDownItem } = styledComponent;
    return (
      <div>
        {_.map(m.types, (data, index) => {
          return (
            <DropDownItem
              key={index}
              active={data === this.state.type}
              onClick={e => this.inputChange(data, "type")}
            >
              <p>{data}</p>
            </DropDownItem>
          );
        })}
      </div>
    );
  };

  toggle = () => {
    this.setState({ documentType: !this.state.documentType });
  };

  render() {
    const {
      Root,
      BackDrop,
      Body,
      TitleWraper,
      Title,
      SwitchWraper,
      DateInputWrapper,
      Hyphen,
      CalendarBtn,
      AcceptText,
      ButtonWrapper,
      Button,
      Close,
      Rectangle,
      SelectWrapper,
      DropDown,
      DropDownBtn,
      DropDownWraper
    } = styledComponent;
    const {
      InputWraper,
      Label,
      Input,
      InputSelect,
      InputBox
    } = commonStyledComponents;
    const { noExpiration, date, name, type, documentType } = this.state;
    const disableSave = !name || !type || (noExpiration ? false : !date.day);
    return (
      <Fragment>
        <Root>
          <BackDrop />
          <Body className="active">
            <Close onClick={() => this.props.cancelModal()}>
              <i className="far fa-times" />
            </Close>
            <Rectangle />
            <TitleWraper>
              <Title>Upload Document</Title>
            </TitleWraper>
            {this.props.data &&
              this.props.data.length && (
                <UploadForShow
                  hideCaption={false}
                  bigMsg={true}
                  data={{ ...this.props.data[0], caption: "REPLACE" }}
                  replaceDocument={
                    this.props.replaceDocument && this.props.replaceDocument
                  }
                />
              )}
            <InputBox>
              <InputWraper>
                <Label>Document Name</Label>
                <Input
                  value={name}
                  onChange={e => this.inputChange(e, "name")}
                />
              </InputWraper>
              {/* <Error text="This field is required." /> */}
            </InputBox>
            <InputBox>
              <InputWraper>
                <Label>Document Type</Label>
                <DropDown>
                  <DropDownBtn onClick={e => this.toggle(e)}>
                    <span>{this.state.type}</span>
                    <i className="far fa-chevron-down" />
                  </DropDownBtn>
                  <DropDownWraper active={documentType}>
                    {this.dropDownItems()}
                  </DropDownWraper>
                </DropDown>
                {/* <SelectWrapper>
                  <InputSelect
                    value={type}
                    onChange={e => this.inputChange(e, "type")}
                  >
                    {types.map((data, index) => {
                      return (
                        <option key={index} selected={data === type}>
                          {data}
                        </option>
                      );
                    })}
                  </InputSelect>
                  <i className="far fa-chevron-down" />
                </SelectWrapper> */}
              </InputWraper>
              {/* <Error text="This field is required." /> */}
            </InputBox>
            <InputWraper flow={true} active={noExpiration}>
              <Label>Expiration Date</Label>
              <DateInputWrapper>
                {this.numberInput("year", "YYYY", date.year)}
                <Hyphen />
                {this.numberInput("month", "MM", date.month)}
                <Hyphen />
                {this.numberInput("day", "DD", date.day)}
                <CalendarBtn onClick={() => this.showCalendar()}>
                  <i className="far fa-calendar-alt" />
                </CalendarBtn>
              </DateInputWrapper>
            </InputWraper>

            <SwitchWraper isChecked={noExpiration}>
              <Switch
                text="Does not expire"
                toggleSwitch={this.toggleSwitch}
                isChecked={noExpiration}
              />
            </SwitchWraper>
            <AcceptText>
              By saving this document, I certify that the provided information
              is correct and accurate.
            </AcceptText>
            <ButtonWrapper>
              <Button className="grey" onClick={() => this.props.cancelModal()}>
                CANCEL
              </Button>
              <Button
                className="green"
                disabledBtn={disableSave}
                disabled={disableSave}
                onClick={this.submit}
              >
                SAVE
              </Button>
            </ButtonWrapper>
          </Body>
          {this.state.showCalendar && (
            <DateTimeModal
              callFrom="upload"
              currentTime={this.state.currentTime && this.state.currentTime}
              getDate={this.getDate}
            />
          )}
        </Root>
      </Fragment>
    );
  }
}
export default UploadModal;
