import { useEffect, useState } from 'react';
import queryString from 'query-string';
import _ from 'lodash';
import translationsLocalData from '../../../../server/locale/en_US.json';

export function useFetchData(props) {
  const [loadCalculationData, setLoadCalculationData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [responseStatus, setResponseStatus] = useState(null);
  const [translations, setTranslations] = useState([]);
  const guid = queryString.parse(props.location.search).token;

  const getData = async (guid, signal) => {
    const settings = {
      method: 'GET',
      headers: { 'content-type': 'application/json', "Subscription-Key": `${window.env.apiKey}` }
    }
    try {
      setIsLoading(true);
      const response = await fetch(`${window.env.qmtLoadUrl}/LoadCalculation/${guid}`, settings);
      setResponseStatus(response.status);
      if (!response.ok) throw new Error(`HTTP error! Load Calcualtion status: ${response.status}`);
      const dataObj1 = await response.json();
      
      if (!signal.aborted) {
        setLoadCalculationData(dataObj1);
      }
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  }
  useEffect(() => {
    if (_.has(window, 'env.translations') && !_.isEmpty(window.env.translations)) {
      setTranslations(window.env.translations);
    }
    else { 
      setTranslations(translationsLocalData);
    }; 
    const abortController = new AbortController();
    getData(guid, abortController.signal);
    return () => abortController.abort(); // Cleanup 
  }, []);

  return {
    loadCalculationData,
    isLoading,
    responseStatus,
    translations,
    guid
  }
}