import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  
  p {
    font-family: "Mulish", sans-serif;
  }

  h1, h2, h3 {
    font-family: "Red Hat Display", sans-serif;
  }

  img {
    width: 80px;
    height: 80px;
    object-fit: contain;
  }
`;

const Title = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #3E4148;
  margin: 24px 0px 16px;
`;

const Message = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #3E4148;
  max-width: 55ch;
  margin: 0px;
`;

const Footer = styled.img`
  display: block;
  margin: 0 auto;
  height: 24px;
  width: 81px;
  object-fit: contain;
  margin-bottom: 24px;
  position: absolute;
  bottom: 0px;
`;

export default {
    Container,
    Title,
    Message,
    Footer
}