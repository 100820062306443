export const types = [
  "Certificates",
  "Insurance",
  "Legal",
  "Licenses",
  "Marketing",
  "Safety",
  "Sales",
  "Union"
];

export function checkDates(length, value, min, max) {
  let val;
  var pattern = /^\d+$/;
  if (pattern.test(value)) {
    if (value.length === length) {
      if (min <= value && value < max) {
        val = value;
      } else if (min >= value) {
        val = min;
      } else {
        val = max;
      }
    } else if (value.length > length) {
      val = max;
    } else {
      val = value;
    }
  } else {
    val = value.slice(0, value.length - 1);
  }
  return val;
}

export function valid(val) {
  if (val !== null && val !== undefined && val !== "" && val !== false)
    return true;
}
