import React, { Fragment } from "react";
import InfoCard from "../../../InfoCard";
import { StatusCard } from "../../../styles";
import { ProgressStatusIcon } from "../styles";
import UserHardHat from "../../../../../../../../public/images/User-hard-hat.svg";

/** Component ProgressActivity renders when "Activity in progress" status is selected */
export default props => {
  return (
    <Fragment>
      <StatusCard pt={9.56}>
        <ProgressStatusIcon src={UserHardHat} />
        <p>
          Congrats! Once your selection is confirmed, we’ll close out this bid
          for you.
        </p>
      </StatusCard>
      <InfoCard isDone {...props} />
    </Fragment>
  );
};
