import { theme, fade, mediumText, mediaMin } from "../../../shared/style";
import styled, { css } from "styled-components";
export const StatusCard = styled("div")`
  text-align: center;
  padding: ${({ pt }) => pt || 24}px 24px 24px;
  margin-bottom: 24px;
  box-shadow: inset 0px -1px 0px 0px ${theme.IndiqoGrey["200"]};
  p {
    margin: 0;
    color: ${theme.IndiqoGrey["800"]};
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    line-height: 20px;
  }
`;

const FieldTitle = styled.p`
  margin-top: 22px;
  margin-bottom: ${({ mb }) => mb || 4}px;
  color: #3e4148;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 20px;
  span {
    color: #b9bbc1;
    font-family: Lato;
    font-size: 14px;
    font-style: italic;
    height: 20px;
    letter-spacing: 0.25px;
    line-height: 20px;
  }
`;

const Textarea = styled.textarea`
  display: block;
  border: solid 1px ${theme.IndiqoGrey[300]};
  background-color: ${theme.IndiqoGrey.main};
  border-radius: 4px;
  padding: 12px;
  min-height: 64px;
  width: 100% !important;
  ${({ req }) =>
    req &&
    css`
      border: 1px solid #00bcd4;
      box-shadow: 0px 0px 4px 0px #4dd0e1;
    `};
`;

const OptionWrapper = styled.div`
  margin-top: ${({ mt }) => mt || 22}px;
`;
const TitleDesc = styled.p`
  ${mediumText};
  margin: 16px 0 0;
  ${mediaMin.tablet`margin: 0`};
  color: #3e4148;
  letter-spacing: 0.25px;
`;

export const Note = styled("p")`
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  color: ${fade(theme.IndiqoGrey[800], 0.7)};
  margin: ${({ mt }) => mt || 24}px 0 16px;
  text-align: center;
`;
export const SurveyBtnWrap = styled("div")`
  margin-top: 16px;
`;
export default {
  TitleDesc,
  OptionWrapper,
  FieldTitle,
  Textarea,
  Note,
  SurveyBtnWrap
};
