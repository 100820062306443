import React, { Component } from "react";
import _ from "lodash";
import { getToken, fetchBids, submitBid } from "../../lib/getUserData";
import { setDatesInArray } from "../ActivityStatus/Methods/setDates";
import FetchFailure from "../../components/FetchFailure";
import CustomerThanks from "../../components/CustomerThanks";
import SelectBid from "./views/SelectBid";
import StyledComponents from "./styles";
import Loader from "../../components/Loader";
import mock from "../../lib/mockBidSelect";

const { Root } = StyledComponents;

class BidSelect extends Component {
  state = {
    owner: {},
    fetchedData: null,
    requestError: false,
    confirmed: false,
    isLoading: true
  };

  componentDidMount() {
    if (!this.state.fetchedData) {
      getToken(this.props.location.search).then(token => {
        if (token === "token=mockBidSelect") {
          let fetchedData = mock;
          fetchedData.Activities = setDatesInArray(mock);
          this.setOwner(fetchedData.Activities);
          this.setState({
            fetchedData,
            isLoading: false
          });
        } else {
          fetchBids(this.props.location.search).then(userData => {
            if (userData) {
              let fetchedData = userData.fetchedData;
              fetchedData.Activities = setDatesInArray(fetchedData);
              this.setState({
                fetchedData,
                isLoading: false,
                token
              });
            }
          });
        }
      });
    }
    document.body.classList.add("bg-grey");
  }

  handleConfirm = async (bidId, bidRequestId) => {
    this.setState({ confirmed: true });
    await submitBid(bidId, bidRequestId);
  };

  setOwner = activities => {
    if (activities[0].Owner && activities[0].Owner.Contact)
      this.setState({
        owner: {
          company: activities[0].Owner.DBAName,
          phone: activities[0].Owner.Contact.PhoneNumber,
          email: activities[0].Owner.Contact.Email
        }
      });
  };

  render() {
    const { confirmed, isLoading, fetchedData, owner } = this.state;

    if (this.state.requestError) {
      return (
        <Root>
          <FetchFailure />
        </Root>
      );
    }

    if (isLoading) {
      return (
        <Root>
          <Loader isLoading={true} color="#3e4148" />
        </Root>
      );
    }

    if (!confirmed && !_.isEmpty(fetchedData.Activities)) {
      return (
        <Root>
          <SelectBid
            activities={fetchedData.Activities}
            operations={fetchedData.OperationGuids}
            history={this.props.history}
            onConfirm={this.handleConfirm}
          />
        </Root>
      );
    } else {
      return (
        <Root>
          <CustomerThanks
            company={owner.company}
            phone={owner.phone}
            email={owner.email}
          />
        </Root>
      );
    }
  }
}

export default BidSelect;
