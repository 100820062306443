import styled from 'styled-components';
import { mediaMax } from '../../../../shared/style';

const MobileCancelSection = styled.div`

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  ${mediaMax.phone`
    align-items: flex-start;
  `};
  
`;

export default {
  MobileCancelSection
}