import React, { Fragment, useState } from 'react';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';
import styledComponents from './styles';
import _ from 'lodash';
import CustomRadio from '../../../../components/CustomRadio';
import Button from '../../../QuoteFleetReviewPage/components/Button';

const {
  H2
} = commonStyles;

const {
  ModalContainer,
  Content,
  IconBox,
  TextArea
} = styledComponents;

export default function Modal({ submitCancel, translations }) {
  const [buttonDisable, setButtonDisable] = useState(true);
  const [reason, setReason] = useState(null);
  const [radioActive, setRadioActive] = useState(null);

  const RADIOS = [
    {
      id: 0,
      label: translations.radioOne,
      value: translations.radioOne
    },
    {
      id: 1,
      label: translations.radioTwo,
      value: translations.radioTwo
    },
    {
      id: 2,
      label: translations.radioThree,
      value: translations.radioThree
    },
    {
      id: 3,
      label: translations.radioFour,
      value: translations.radioFour
    },
    {
      id: 4,
      label: translations.radioFive,
      value: translations.radioFive
    },
    {
      id: 5,
      label: translations.radioOther,
      value: translations.radioOther
    }
  ];

  const handleReasonChange = (msg, activeRadio) => {
    if (activeRadio === translations.radioOther) {
      if (_.isNil(msg) || msg.length < 2) {
        setButtonDisable(true);
      } else {
        setButtonDisable(false);
      }
    } else if (_.isNil(activeRadio)) {
      setButtonDisable(true);
    }

    setReason(msg);
  };
  
  const handleRadioChange = value => {
    if (value === translations.radioOther) {
      handleReasonChange(reason, value);
    } else {
      setButtonDisable(false);
    }

    setRadioActive(value);
  };

  const mapRadios = () => {
    return RADIOS.map(data => {
      return (
        <Fragment key={data.id}>
          <CustomRadio 
            onChange={e => handleRadioChange(e.target.value)}
            id={data.id}
            name='reason'
            label={data.label}
            value={data.value}
          />
        </Fragment>
      )
    })
  };

  return (
    <ModalContainer>
      <div className="title">
        <H2>{translations.title}</H2>
        <IconBox title="close and go back to review page" onClick={() => window.history.back()} >
          <i className="fas fa-times" />
        </IconBox>
      </div>
      <Content>
        <p>{translations.description}</p>
        <div className="radios">
          {mapRadios()}
        </div>
        <div className="reason">
          <p>
            {translations.reason} 
            {radioActive !== translations.radioOther &&
              <i> – {translations.optional}</i>
            }
          </p> 
          <TextArea
            highlight={radioActive === translations.radioOther}
            onChange={e => handleReasonChange(e.target.value, radioActive)}
            placeholder={translations.placeholder}
          />
        </div>
      </Content>
      <div className="button">
        <Button text={translations.button} onClick={() => submitCancel(radioActive, reason)} isDisabled={buttonDisable} styled={'red'} showIcon={false} />
      </div>
    </ModalContainer>
  )
}