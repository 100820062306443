import React, { Component, Fragment } from "react";
import StyledComponents from "../styles";
import ButtonComponent from "../ButtonComponent";
import {
  fetchCreatingNotes,
  postCancelStaleActivity
} from "../../../../lib/getUserData";
import { DeclineSubmitted, RenderRadios, radioData } from "./SubViews";

const {
  TitleDesc,
  OptionWrapper,
  FieldTitle,
  Textarea,
  Note
} = StyledComponents;

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    Component CustomerDeclined is rendering view for Customer decline flow
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
export default class CustomerDeclined extends Component {
  constructor(props) {
    super(props);
    this.state = { disableSubmit: true };
  }

  /** 
  function handleRadioChange =>
    - sets state that which reason is selected (radio button) 
    - also toggles the disabling/enabling of the done button
  */
  handleRadioChange = event => {
    const { value } = event.target;
    let statesToSet;
    switch (value) {
      case radioData[0].label:
      case radioData[1].label:
      case radioData[2].label:
        statesToSet = {
          isReasonOptional: false,
          disableSubmit: false
        };
        break;
      case radioData[3].label:
        if (this.state.message) {
          statesToSet = {
            isReasonOptional: true,
            disableSubmit: false
          };
        } else {
          statesToSet = {
            isReasonOptional: true,
            disableSubmit: true
          };
        }
        break;
      default:
        statesToSet = { disableSubmit: true };
        break;
    }
    this.setState({ ...statesToSet, reason: value });
  };

  /** 
  function handleReasonUpdate =>
    - sets state for reason description (message)
  */
  handleReasonUpdate = event => {
    let disableSubmit;
    if (this.state.reason === radioData[3].label) {
      if (event.target.value !== "") disableSubmit = false;
      else disableSubmit = true;
    }
    this.setState({ disableSubmit, message: event.target.value });
  };

  createReason = () => {
    const { reason, message } = this.state;
    if (reason === radioData[3].label) {
      return message;
    } else {
      return `${reason}. ${message ? message : ""}`;
    }
  };

  /** 
  function handleSubmit =>
    - sets state that customer succesfully declined (submitted)
    - call handleStatusChange from props to set Status of activity and subTitle
  */
  handleSubmit = () => {
    const { reason, message } = this.state,
      { handleStatusChange, activity } = this.props;
    if (
      (reason === radioData[3].label && message !== "") ||
      (reason && reason !== radioData[3].label)
    ) {
      const body = {
        Note: `Customer declined. Reason: ${this.createReason()}`
      };
      fetchCreatingNotes(body, activity.ParentActivityId).then(() => {
        postCancelStaleActivity(body.Note, activity.Id);
        this.setState({ submitted: true });
        handleStatusChange(activity.Id, {
          subTitle: "Removed from your list",
          status: null
        });
      });
    }
  };

  /** 
  function goBack =>
    - makes user to previous view
  */
  goBack = () => {
    const { activity, handleView } = this.props;
    handleView(activity, "updates");
  };

  /** Rendering view for Customer decline flow */
  render() {
    const { activity } = this.props;
    const { isReasonOptional, message, disableSubmit, submitted } = this.state;
    if (submitted) return <DeclineSubmitted {...this.props} />;
    return (
      <Fragment>
        <TitleDesc>Why did this customer choose not to proceed?</TitleDesc>
        <OptionWrapper mt={10}>
          <RenderRadios onChange={this.handleRadioChange} activity={activity} />
        </OptionWrapper>
        <FieldTitle>
          Reason {!isReasonOptional && <span>- Optional</span>}
        </FieldTitle>
        <Textarea
          value={message}
          req={isReasonOptional}
          onChange={this.handleReasonUpdate}
          placeholder="Let us know why you're declining to bid…"
        />
        <Note>
          We’ll stop sending you reminders for this item and remove it from your
          list.
        </Note>
        <ButtonComponent
          goBack={this.goBack}
          disableSubmit={disableSubmit}
          handleSubmit={this.handleSubmit}
        />
      </Fragment>
    );
  }
}
