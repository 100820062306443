import React, { Component, Fragment } from "react";
import _ from "lodash";
import Attachement from "../../../../components/Attachment";
import styledComponents from "./styles";
import BidHeader from "./view/BidHeader";
import formatter from "../../../../lib/formatter";
import { getHref } from "../../../../lib/utils";
const { Row, Col, ContentCard, Label, Content, Link } = styledComponents;

/** Component ContactCard to render links for email and contact number */
const ContactCard = ({ Contact, isHide }) => {
  if (!isHide)
    return (
      <ContentCard>
        <Link call href={`tel:${Contact.PhoneNumber || ""}}`}>
          {Contact.PhoneNumber || ""}
        </Link>
        {Contact.PreferredMethod === "phoneNumber" && (
          <Label italic>Preferred Contact</Label>
        )}

        <Link email href={`mailto:${Contact.Email || ""}`}>
          {Contact.Email || ""}
        </Link>
        {Contact.PreferredMethod === "email" && (
          <Label italic>Preferred Contact</Label>
        )}
      </ContentCard>
    );
  return null;
};

/** Component LocationCard to render link for location (address) */
const LocationCard = ({ loc, isHide, allDisable }) => {
  if (allDisable) {
    return (
      <Fragment>
        {loc.Address}
        <br />
        {loc.State}, {loc.Country} {loc.Zip}
      </Fragment>
    );
  }
  if (!isHide)
    return (
      <Link href={getHref(loc)} target="_blank">
        {loc.Address}
        <br />
        {loc.State}, {loc.Country} {loc.Zip}
      </Link>
    );
  return null;
};

/** 
function convertUTCDate =>
- return : convert date from UTC to Locale
 */
function convertUTCDate(date) {
  const localDate = formatter.getDateTime(date);
  return `${localDate.formattedDate}, ${localDate.formattedTime}`;
}

/** Component InfoCard renders info about activity */
class InfoCard extends Component {
  render() {
    const { partial, activity, allDisable, isDone } = this.props,
      { Contact } = activity.Customer,
      loc = activity.Location;
    return (
      <div>
        {!partial && (
          <Fragment>
            <BidHeader activity={activity} />
            {_.head(activity.BidRequest.Attachments) && (
              <Attachement
                thumbnail={true}
                attachments={activity.BidRequest.Attachments}
              />
            )}
          </Fragment>
        )}
        <Row mb={isDone}>
          <Col>
            <ContentCard mb={8}>
              <Label>Customer</Label>
              <Content>
                <b>{activity.Customer.Name}</b>
              </Content>
            </ContentCard>
            <ContactCard Contact={Contact} isHide={partial} />
          </Col>
          <Col>
            <ContentCard>
              <Label>Site</Label>
              <LocationCard loc={loc} isHide={!loc} allDisable={allDisable} />
            </ContentCard>
          </Col>
          <Col>
            <ContentCard>
              <Label>Due</Label>
              <Content>{`${activity.localDueDate} ${
                activity.localDueTime
              }`}</Content>
            </ContentCard>
          </Col>
          <Col>
            <ContentCard>
              <Label>Accepted</Label>
              <Content>
                {`${activity.localAcceptedDate} ${activity.localAcceptedTime}`}{" "}
                by {`${activity.AcceptedBy && activity.AcceptedBy.Name}`}
              </Content>
            </ContentCard>
          </Col>
        </Row>
      </div>
    );
  }
}

export default InfoCard;
