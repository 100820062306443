import React, { Component } from 'react';
import PropTypes from 'prop-types'
import StyledComponent from "./styles";
import { Button } from '../../../../shared/style';
import DeclineBidImg from '../../../../../../public/images/Decline-Bid.png'
import { apiConfig } from '../../../../lib/apiConfig';
import fetchData from '../../../../lib/fetchData';
import Radio from "../../../../components/Radio";

const {
    ButtonWrapper,
    Title,
    FieldTitle,
    Root,
    TitleDesc,
    Textarea,
    OptionWrapper,
    DeclinedBid
} = StyledComponent;

const radioData = [
    { id: 1, label: "Out of the service area" },
    { id: 2, label: "Unable to perform" },
    { id: 3, label: "Other..." }
];

class DeclineBid extends Component {
    state = {
        showReason: false,
        reason: undefined,
        message: "",
        disableSubmit: true,
        declined: false
    }

    handleRadioChange = (event) => {
        switch (event.target.value) {
            case radioData[0].label:
                return this.setState({ 
                    disableSubmit: false,
                    showReason: false, 
                    reason: event.target.value 
                })
            case radioData[1].label:
                return this.setState({ 
                    disableSubmit: false, 
                    showReason: false,
                    reason: event.target.value
                })
            case radioData[2].label:
                if(this.state.message) {
                    return this.setState({
                        reason: event.target.value,
                        showReason: true,
                        disableSubmit: false,
                    })
                } else {
                    return this.setState({
                        reason: event.target.value,
                        showReason: true,
                        disableSubmit: true
                    })
                }
            default:
                return this.setState({ disableSubmit: true })
        } 
    }
    
    handleReasonUpdate = (event) => {
        if(this.state.reason === radioData[2].label) {
            if( event.target.value !== "") {
                this.setState({ disableSubmit: false })
            }
            else {
                this.setState({ disableSubmit: true })
            }
        } 
        this.setState({ message: event.target.value })
    }

    handleSubmit = () => {
        const reason = this.state.reason === radioData[2].label ? this.state.message : this.state.reason;

        if(!this.context.isMockData) {
            const options = {
                ...apiConfig.postConsumerInfo,
                query: "token=" + this.props.activity.Operations.Decline,
                body: {
                    reason: reason
                }
            };
            fetchData(options).then(data => {
                if (data.fetchedData && !data.fetchedData.ErrorCode) {
                    this.setState({ data, declined: true, fetchSuccess: true });
                    this.props.onActionClick({ 
                        view: 'decline', 
                        header: { subTitle: 'declined', status: 'warning' },
                    })
                } else {
                    this.setState({ fetchError: true });
                }
            });
        } else {
            this.setState({ declined: true })
            this.props.onActionClick({ 
                view: 'decline', 
                header: { subTitle: 'declined', status: 'warning' },
            })
        }
    }

    render() {
        const { activity, onActionClick, header } = this.props;
        const { message, showReason, disableSubmit, declined } = this.state;

        if (!declined) {
            return (
                <Root>
                    <Title>Decline Bid</Title>
                    <TitleDesc>
                        Let {activity.Owner.DBAName} know why you won’t be providing a bid.
                    </TitleDesc>
                    <OptionWrapper mt={10}>
                        {radioData.map((data, inx) => {
                            return (
                                <Radio
                                    key={inx}
                                    name={"reason"+activity.Id}
                                    id={`${activity.Id}-${inx}`}
                                    label={data.label}
                                    onChange={this.handleRadioChange}
                                />
                            );
                        })}
                    </OptionWrapper>
            
                    <FieldTitle>Reason {!showReason && <span>- Optional</span>}</FieldTitle>
                    <Textarea
                        req={showReason}
                        onChange={this.handleReasonUpdate}
                        value={message}
                        placeholder="Let us know why you're declining to bid…"
                    />
                    <ButtonWrapper sideDesktop>
                        <div>
                            <Button
                                fWidth
                                pFloating
                                onClick={() => onActionClick({ view: 'view' })}
                            >
                            CANCEL
                            </Button>
                        </div>
                        <div>
                            <Button
                                className={disableSubmit?"disabled":""}
                                fWidth
                                pFloating
                                danger={true}
                                onClick={this.handleSubmit}
                                disabled={disableSubmit}
                            >
                                Decline
                            </Button>
                        </div>
                    </ButtonWrapper>
                </Root>
            )
        } else {
            return (
                <Root>
                    <DeclinedBid>
                        <img src={DeclineBidImg} alt="decline-bid" />
                        <p>We’ll take care of this. You can always adjust your settings to get bid requests that are more relevant to you.</p>
                    </DeclinedBid>
                </Root>
            )
        }

    }
}

DeclineBid.contextTypes = {
    isMockData: PropTypes.boolean
};

export default DeclineBid;
