import React, { PureComponent, Fragment } from "react";
import Radio from "../../../../components/Radio";
import DateTime from "../../../../components/DateTime";
import styles from "./styles.module.css";

const Tab = props => {
  const { id, name, children } = props;

  return (
    <div className={styles.root}>
      <Radio id="yes" label="Yes, all done!" name="rr">
        <div className={styles.survey}>
          <p>
            Please take a few moments to fill out the closeout survey. Once it’s
            submitted, this activity will be marked as complete.
          </p>
          <button>
            <i className="far fa-clipboard-check" />
            START CLOSEOUT SURVEY
          </button>
        </div>
      </Radio>
      <Radio id="not" label="Not yet" name="rr">
        <p className={styles.weSelectText}>
          If you have an appointment scheduled, please let us know.
        </p>
        <p className={`${styles.weSelectText} ${styles.appointmentText}`}>
          Scheduled Appointment{" "}
          <span className={styles.comments}>– Optional</span>
        </p>

        <DateTime />
      </Radio>
    </div>
  );
};

export default Tab;
