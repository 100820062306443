import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from "query-string";
import _ from "lodash";
import commonStyles from '../../components/DesignPatternV2.0/commonStyles';
import styledComponents from "./styles";

import { InstallerConfirmed } from '../TieredConfirmationAccepted/components/InstallerConfirmed';
import { InstallerStatus } from '../../components/InstallerStatus';
import BidStatusError from '../../components/Errors';
import { TierUpdateFail } from '../tierManage/components/TierUpdateFail';
import { InstallerDeclinedConfirmation } from '../../components/InstallerDeclined';

import Loader from "../../components/Loader";
import PagingSection from './sections/PagingSection';
import HeaderSection from './sections/HeaderSection';
import OptionsSection from './sections/OptionsSection';
import TermsSection from './sections/TermsSection';
import TotalSection from './sections/TotalSection';
import Checkbox from './components/Checkbox';
import QuoteApprovedButton from './components/QuoteApprovedButton';
import RequestCancelled from '../../components/RequestCancelled';
// import FinancedRequestSubmitted from '../FinancedRequestSubmitted';

import translationsLocalData from "../../../server/locale/en_US.json";

const {
  Header,
  QmeritLogo,
  H4,
  P_Contact,
  GlobalTrackerStyles
} = commonStyles;

const {
  MainQuoteWrapper,
  InfoWrapper,
  LeftColumn,
  FinanceContainer,
  RightColumn,
  Footer,
  FooterMobile,
  FooterDivider,
  FooterContainer
} = styledComponents;

export default function QuoteFinancingPage(props) {
  const [fetchedBidData, setFetchedBidData] = useState([]);
  const [fetchedBidStatus, setFetchedBidStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [browserLanguage, setBrowserLanguage] = useState("");
  const [token, setToken] = useState("");
  const [acceptSuccess, setAcceptSuccess] = useState(false);
  const [translations, setTranslations] = useState({});
  const [bidConfirmed, setBidConfirmed] = useState(false);
  const [declineSuccess, setDeclineSuccess] = useState(false);
  const [testBidData, setTestBidData] = useState("");
  const [requestId, setRequestId] = useState("");
  const [financingEnabled, setFinancingEnabled] = useState(false);
  const [currentIdx, setCurrentIdx] = useState(-1);
  const [isChecked, setIsChecked] = useState(false);
  const [markdown, setMarkdown] = useState(null);
  const [financingAccepted, setFinancingAccepted] = useState(null);
  const [country, setCountry] = useState("");
  const navigate = useHistory();
  const isQuotePage = false;
  
  useEffect(() => {

    if (_.has(window, 'env.translations') && !_.isEmpty(window.env.translations)) {
      setTranslations(window.env.translations);
    }
    else { 
      setTranslations(translationsLocalData);
    };  

    fetch("/language", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => setBrowserLanguage(data.browserLanguage))

    let bidId = getBidId(props.location.search);
    let country = queryString.parse(props.location.search).country;
    setToken(bidId);
    if (!_.isEmpty(country)) setCountry(country);
    
    if (!_.isEmpty(bidId)) {
      getIdByGuid(bidId)
    };

  }, []);

  //Getting Request Guid parsing token from the URL
  const getBidId = (url) => {
    return queryString.parse(url).token
  }

  //Getting Request data using the Guis previously obtained
  const getIdByGuid = async (bidGuid) => {
    let optionalParams = {
      headers: { "content-type": "application/json; charset=UTF-8", "Subscription-Key": `${window.env.apiKey}` },
      method: "GET"
    }

    const response = await fetch(`${window.env.qmtUrl}/Estimate/Bid/${bidGuid}`, optionalParams);
    setFetchedBidStatus(response.status);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    const data = await response.json();
    setFetchedBidData(data);
    setTestBidData(data.BidList[0]);
    setIsLoading(false);
    setRequestId(data.Request.Id);
    setFinancingEnabled(data.Request.Program.FinancingEnabled);
    setMarkdown(data.Request.Program.OperationalTerms);
    setFinancingAccepted(data.Request.AcceptedOperationalTermsOn);

    return data;
  }

  // Handle Non-Finance Accept click
  const acceptBidByGuid = async () => {
    setIsLoading(true);

    const settings = {
      method: "POST",
      headers: {
        "content-type": "application/json", 
        "Subscription-Key": `${window.env.apiKey}`
      },
      body: JSON.stringify({ OperationalTerms: "" })
    };

    const response = await fetch(`${window.env.qmtUrl}/Bid/${testBidData.Id}/Confirm`, settings);
    setBidConfirmed(response.status);
    setIsLoading(false);
    setAcceptSuccess(true);

    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
  }

  // Handle Finance Accept click
  const acceptFinance = async () => {
    setIsLoading(true);

    const settings = {
      method: "POST",
      headers: {
        "content-type": "application/json", 
        "Subscription-Key": `${window.env.apiKey}`
      },
      body: JSON.stringify({ OperationalTerms: markdown })
    };

    const response = await fetch(`${window.env.qmtUrl}/Bid/${testBidData.Id}/Confirm`, settings);
    setBidConfirmed(response.status);

    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

    setIsLoading(false);
    setAcceptSuccess(true);

    const data = { assigneeName: testBidData.Assignee.Name, partnerName: fetchedBidData.Request.Program.PartnerName };
    navigate.push({ pathname: "/financed-request-submitted", state: data });
  }

  // Handle checkbox action
  const toggleFinanceApproveButton = () => setIsChecked(!isChecked);

  if (!isLoading && testBidData.Status === 'cancelled') {
    return <RequestCancelled translations={translations} />
  }

  if (!isLoading && acceptSuccess === true) {
    return (<InstallerConfirmed companyName={testBidData.Assignee.Name} translations={translations} />)
  }

  if (!isLoading && bidConfirmed === 200 && declineSuccess === true && translations) {
    return (<InstallerDeclinedConfirmation translations={translations}/>)
  }

  if (!isLoading && testBidData.Status === 'rejected') {
    return (<InstallerStatus translations={translations} installerStatus={testBidData.Status === "confirmed" ? "Accepted" : testBidData.Status === "rejected" ? "Cancelled" : ''} />)
  }

  if (!isLoading && testBidData.Status === 'declined') {
    return (<BidStatusError status={testBidData.Status} contactInfo={true} />)
  }

  if (financingEnabled && fetchedBidStatus == 200 && !isLoading && testBidData.Status === "submitted") {

    return (
      <React.Fragment>
        <GlobalTrackerStyles />
        <Header>
          <QmeritLogo src={`${window.env.blobUrl}/qmerit/wordmark/png/Qmerit-Wordmark-White.png`} />
        </Header>
        <MainQuoteWrapper>
          <InfoWrapper>
            <LeftColumn>
              <FinanceContainer>
                <PagingSection token={token}/>
                <HeaderSection partnerName={fetchedBidData.Request.Program.PartnerName} />
                <OptionsSection currentIdx={currentIdx} setCurrentIdx={setCurrentIdx} partnerName={fetchedBidData.Request.Program.PartnerName} />
              </FinanceContainer>
              {currentIdx == 0 && markdown &&
                <TermsSection markdown={markdown} isQuotePage={isQuotePage} />
              }
            </LeftColumn>
            <RightColumn>
              {/* Total Section */}
              <TotalSection
                fetchedBidData={fetchedBidData}
                browserLanguage={browserLanguage}
                requestId={requestId}
                bidData={testBidData}
                acceptBidByGuid={acceptBidByGuid} 
                translations={translations.quotePage}
                currentIdx={currentIdx}
                acceptFinance={acceptFinance}
                isChecked={isChecked}
                toggleFinanceApproveButton={toggleFinanceApproveButton} 
              />
            </RightColumn>
          </InfoWrapper>
          <FooterDivider />
          <FooterContainer>
            <Footer>
              <H4>{translations.quotePage.footerSection.message}</H4>
              <P_Contact href={`tel: ${fetchedBidData.Request.Program.CustomerSupportPhone}`}>{fetchedBidData.Request.Program.CustomerSupportPhone}</P_Contact>
              <P_Contact href={`mailto: ${fetchedBidData.Request.Program.CustomerSupportEmail}`}>{fetchedBidData.Request.Program.CustomerSupportEmail}</P_Contact>
            </Footer>
            {/* Mobile View */}
            <FooterMobile>
              {currentIdx == 0 &&
                <Checkbox isChecked={isChecked} onClick={toggleFinanceApproveButton} />
              }
              <QuoteApprovedButton acceptFinance={acceptFinance} acceptBidByGuid={acceptBidByGuid} currentIdx={currentIdx}  isChecked={isChecked} />
            </FooterMobile>
            {/* End of Mobile View */}
          </FooterContainer>
        </MainQuoteWrapper>
      </React.Fragment>
    )
  }

  if (!isLoading && (acceptSuccess === false || [400, 404, 500].includes(bidConfirmed) || [400, 404, 500].includes(fetchedBidStatus))) {
    return <TierUpdateFail translations={translations} country={country}/>
  }

  return (<Loader color={"#000000"} isLoading={isLoading} />)

}
