import React, { Component } from "react";
import _ from "lodash";
import { getToken } from "../../lib/getUserData";
import Wrapper from "../../components/Wrapper";
import Loader from "../../components/Loader/Loading";
import FetchFailure from "../../components/FetchFailure";
import Mock from "../../lib/mockNewNearby2";
import Nearby from "./Nearby";
import NotFound from "../../components/NotFound";
import AccountNotFound from "../../components/AccountNotFound";
import {
  AccessLocationAlert,
  LocationNotFoundAlert,
  NoItemsFoundAlert
} from "./Nearby/common/components";
import * as constants from "../../../constants";
import * as sd from "./Methods/setDates";
import * as l from "./Methods/location";

class ActivityStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      fetchedData: null,
      fetchError: false,
      token: undefined,
      latitude: "",
      longitude: "",
      usingMock: false,
      isLocationError: true,
      accessLocationAlert: false,
      locationNotFound: false
    };
  }

  componentDidMount() {
    document.body.style.background = "none";
    this.checkLocationAccess();
    if (!this.state.fetchedData) {
      getToken(this.props.location.search).then(token => {
        if (token === "token=mockNearby") {
          const userData = Mock;
          const fetchedData = userData[0];
          fetchedData.Activities = sd.setDatesInArray(userData[0]);
          this.setState({
            fetchedData,
            usingMock: true,
            token,
            isLoading: false
          });
        } else {
          this.getMyLocation();
        }
      });
    }
  }

  checkLocationAccess = () => {
    l.checkLocationAccess().then(data => {
      if (data.data) this.setState({ ...data.data });
      if (data.getLoc) {
        this.getMyLocation();
      }
    });
  };

  cancelLocation = () => {
    document.body.classList.remove("popUpBlur");
    this.setState({
      accessLocationAlert: false,
      isLocationError: true,
      isLoading: false,
      locationNotFound: true
    });
  };

  allowLocation = () => {
    document.body.classList.remove("popUpBlur");
    this.setState({
      accessLocationAlert: false,
      isLoading: true
    });
    this.getMyLocation();
  };

  useSurveyLocations = (lat, long, type) => {
    l.useSurveyLocations({ ...this.state }, lat, long, type).then(data => {
      this.setState({ ...data });
    });
  };

  getMyLocation = () => {
    const location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition(
        position => {
          this.position(position);
        },
        e => {
          localStorage.setItem("gotLocationPermission", "0");
          this.setState({
            isLocationError: true,
            isLoading: false,
            locationNotFound: true
          });
        }
      );
    }
  };

  position = position => {
    localStorage.setItem("gotLocationPermission", "1");
    this.setState({
      isLoading: false,
      isLocationError: false,
      locationNotFound: false,
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      accessLocationAlert: false
    });
    if (this.state.token !== "token=mockNearby") {
      this.useSurveyLocations(
        position.coords.latitude,
        position.coords.longitude,
        constants.radius
      );
    }
  };

  getChildComponent() {
    if (
      (this.state.longitude === "" && this.state.latitude === "") ||
      this.state.fetchedData
    ) {
      return Nearby;
    }
    return NotFound;
  }

  render() {
    const {
      isLoading,
      fetchError,
      fetchedData,
      accessLocationAlert,
      locationNotFound
    } = this.state;
    const gotGoodData =
      !isLoading && !fetchError && fetchedData && !locationNotFound;
    const user = fetchedData && fetchedData.User ? fetchedData.User.Name : "";
    const company =
      fetchedData && fetchedData.User ? fetchedData.User.CompanyName : "";

    if (fetchError) {
      return (
        <Wrapper name="" company="" maps backGround="#ffffff">
          <FetchFailure maps />
        </Wrapper>
      );
    }
    if (fetchedData && !fetchedData.User) {
      return (
        <Wrapper name="" company="" maps backGround="#ffffff">
          <AccountNotFound maps />
        </Wrapper>
      );
    }
    // if (accessLocationAlert) {
    //   return (
    //     <Wrapper name="" company="" maps backGround="#ffffff">
    //       <AccessLocationAlert
    //         cancelLocation={this.cancelLocation}
    //         allowLocation={this.allowLocation}
    //       />
    //     </Wrapper>
    //   );
    // }

    if (locationNotFound && !isLoading) {
      return (
        <Wrapper name={user} company={company} maps backGround="#ffffff">
          <LocationNotFoundAlert />
        </Wrapper>
      );
    }

    if (
      fetchedData &&
      fetchedData.Activities &&
      fetchedData.Activities.length === 0 &&
      !isLoading
    ) {
      return (
        <Wrapper name={user} company={company} maps backGround="#ffffff">
          <NoItemsFoundAlert />
        </Wrapper>
      );
    }

    if (gotGoodData) {
      const ActivityStatusChild = this.getChildComponent();
      return (
        <Wrapper name={user} company={company} maps backGround="#ffffff">
          <ActivityStatusChild blured={accessLocationAlert} {...this.state} />
        </Wrapper>
      );
    }

    return (
      <Wrapper name="" company="" maps backGround="#ffffff">
        {isLoading && <Loader color="#6e727c" />}
      </Wrapper>
    );
  }
}

export default ActivityStatus;
