import React, { Fragment } from "react";
import styledComponents from "./style";
import UploadStatic from "../Upload";

const AlertWrapper = props => {
  const {
    Alert,
    AlertHeading,
    AlertReason,
    AlertReasonTime,
    AlertReasonWraper
  } = styledComponents;
  const attaches = props.data.data.AttachmentUrls;
  const attachments = attaches && attaches.length > 0 && attaches;
  const showReason = (props.children && true) || false;
  // const data =
  //   props.data.heading === "Rejected"
  //     ? props.data.data.Collateral && props.data.data.Collateral
  //     : attachments;
  return (
    <Alert type={props.data.type}>
      <AlertHeading>
        <b>{props.data.heading}</b>
      </AlertHeading>
      {attachments && (
        <Fragment>
          <AlertReason>Attachments</AlertReason>
          <UploadStatic
            spaceBottom={14}
            uploadArray={attachments}
            bigMsg={true}
            hideCaptions={true}
          />
        </Fragment>
      )}
      <AlertReasonWraper center={!showReason}>
        {showReason && <AlertReason>Reason</AlertReason>}
        <AlertReasonTime>{props.data.reasonTime}</AlertReasonTime>
      </AlertReasonWraper>
      {showReason && props.children}
    </Alert>
  );
};
export default AlertWrapper;
