import styled, { css } from "styled-components";

const Container = styled.div`

    display: flex;
    height: 100vh;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const AlreadyUpdatedTitle = styled.h2`
    color: rgb(62, 65, 72);
    font-family: Montserrat;
    font-weight: bold;
    height: 24px;
    line-height: 24px;
    text-align: center;
    width: 400px;
    margin-bottom: 16px;
    margin-top: 24px;
`;

const AlreadyUpdatedText = styled.p`
    color: rgb(62, 65, 72);
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 20px;
    text-align: center;
    width: 400px;
    margin:0;
`;


export default {
    Container,
    AlreadyUpdatedTitle,
    AlreadyUpdatedText
}