import React from 'react'
import StyledComponents from './styles';
import _ from "lodash";

const {
	Card,
	TitleBox,
	IconBox,
	TitleIcon,
	TitleText,
	Details,
	DetailsText,
	DetailsList,
	DetailsListElement
} = StyledComponents;

export default function ImportantInfoCard({ translations, show }) {

	if(!show) return null;
	return (
		<React.Fragment>
			<Card>
				<TitleBox>
					<IconBox>
						<TitleIcon className="far fa-info-circle" />
					</IconBox>
					<TitleText>
						{translations.title}
					</TitleText>
				</TitleBox>
				<Details>
					<DetailsText>{translations.descriptionOne}</DetailsText>
					<DetailsList>
						<DetailsListElement>{translations.bulletOne}</DetailsListElement>
						<DetailsListElement>{translations.bulletTwo}</DetailsListElement>
						<DetailsListElement>{translations.bulletThree}</DetailsListElement>
						<DetailsListElement>{translations.bulletFour}</DetailsListElement>
					</DetailsList>
					<DetailsText>{translations.descriptionTwo}</DetailsText>
				</Details>
			</Card>
		</React.Fragment>
	)
}
