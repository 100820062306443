import styled, { css } from "styled-components";


const Card = styled.div`
  width: 480px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14),
    0px 4px 5px 0px rgba(0, 0, 0, 0.12),
    0px 1px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  padding: 24px 24px 24px 24px;
  margin-top: 1.5rem;
  @media screen and (max-width: 767px) {
    border-radius: 0;
    box-shadow: none;
    margin-top: 20px
    width: 100%;
    overflow: hidden;
  }
`;

const Header = styled.h2`
  color: rgb(62, 65, 72);
  font-size: 18px;
  font-family: Montserrat;
  font-weight: bold;
  margin: 0;
`;



const TierText = styled.p`
    color: rgb(62, 65, 72);
    font-size: 16px;
    font-family: Lato;
    font-weight: normal;
    line-height: 20px;
    margin-top: 16px;
`;

const CompanyHeader = styled.div`
    color: rgba(62, 65, 72, 0.7);
    font-size: 12px;
    font-family: Lato;
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 16px;
    margin: 24px 0 4px 8px;
`;

const CompanyDetails = styled.div`
    color: rgb(62, 65, 72);
    font-size: 16px;
    font-family: Lato;
    font-weight: normal;
    line-height: 20px;
    margin: 0 0 0 8px;
`;

const CompanyLink = styled.a`
    color: rgb(62, 65, 72);
    font-size: 16px;
    font-family: Lato;
    font-weight: normal;
    line-height: 20px;
    margin: 0 0 0 8px;
    cursor: pointer;
    color: #3963fe;
`;






const Button = styled.button`
  width: 200px;
  height: 40px;
  background: linear-gradient(-180deg, rgb(255, 87, 34) 0%, rgb(244, 81, 30) 100%);
  border: 1px solid rgb(244, 81, 30);
  border-radius: 8px;

  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 16px;
  text-align: center;
  text-transform: capitalize;
  margin: 48px 124px 8px 124px;
  cursor: pointer;
`;

const Footer = styled.img`
  display: block;
  margin: 0 auto;
  height: 24px;
  width: 160px;
  object-fit: contain;
  margin-bottom: 48px;
`;

const Warning = styled.div`
  color: rgba(62, 65, 72, 0.7);
  font-size: 14px;
  font-family: Lato;
  font-weight: normal;
  line-height: 20px;
  text-align: center;
  margin-top: 1.5rem;
  
`;






export default {
    Card,
    Header,
    Button,
    TierText,
    CompanyHeader,
    CompanyDetails,
    CompanyLink,
    Footer,
    Warning,
}