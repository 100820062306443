import React, { Component } from 'react';
import QmeritLogo from '../../../../components/Powered-by-Qmerit-Indiqo-Grey';
import ChargingStation from '../../../../../../public/images/charging-station.svg';
import { Wrapper, Title, Description } from '../../styles';
import { Button } from '../../../../shared/style';
import StyledComponents from './styles';

const { Container } = StyledComponents;

class CompletedView extends Component {
    state = {
        surveyId: window.env.csatId,
    }

    render() {
        const { activityId } = this.props;
        const { surveyId } = this.state;

        return (
            <Wrapper absoluteLogo>
                <Container>
                    <img alt="happy-face" src={ChargingStation} />
                    <Title>Are you enjoying your new EV Charger?</Title>
                    <Description>
                        If you have a minute, we’d love to hear how your installation experience went!
                    </Description>
                    <a href={`https://survey.qmerit.com/survey/${surveyId}${activityId?"?guid="+activityId:''}`} target="_self">
                        <Button action={'action'}>
                            LET US KNOW
                        </Button>
                    </a>
                </Container>
                <QmeritLogo />
            </Wrapper>
        )
    }
}

export default CompletedView;
