import React from 'react'
import StyledComponents from './styles';
import formatter from '../../../../../lib/formatter';
import _ from "lodash";

import {
	getBidServiceProviderName,
	getBidServiceProviderPhone,
	getBidServiceProviderEmail,
  } from "../../../helpers/BidsDates"

const {
	Card,
	TitleBox,
	IconBox,
	TitleIcon,
	TitleText,
	Subtitle,
	Details,
	CompanyInfo,
	CompanyInfoTitle,
	CompanyInfoContent
} = StyledComponents;

export default function WorkerAssignedCard({ requestData, acceptedOnDate, translations, bid, show }) {

	if(!show) return null;
	return (
		<React.Fragment>
			<Card>
				<React.Fragment>
					<TitleBox>
						<IconBox>
							<TitleIcon className="far fa-user-check" />
						</IconBox>
						<TitleText>
							{translations.title}
						</TitleText>
					</TitleBox>
					<Subtitle>
						{acceptedOnDate.formattedDate} {translations.dateTimeSeparator} {acceptedOnDate.formattedTime}
					</Subtitle>
					<Details>
						<CompanyInfo>
							<CompanyInfoTitle>{translations.companyInformation}</CompanyInfoTitle>
							<CompanyInfoContent disabled>{getBidServiceProviderName(requestData, bid.Status, bid.BidId)}</CompanyInfoContent>
							<CompanyInfoContent link href={`tel:${getBidServiceProviderPhone(requestData, bid.Status, bid.BidId)}`}>{getBidServiceProviderPhone(requestData, bid.Status, bid.BidId)}</CompanyInfoContent>
							<CompanyInfoContent link href={`mailto:${getBidServiceProviderEmail(requestData, bid.Status, bid.BidId)}`}>{getBidServiceProviderEmail(requestData, bid.Status, bid.BidId)}</CompanyInfoContent>
						</CompanyInfo>
					</Details>
				</React.Fragment>
			</Card>
		</React.Fragment>
	)
}
