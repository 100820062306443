import React, { useState, useEffect } from 'react';
import Loader from '../../components/Loader';
import queryString from 'query-string';
import _ from 'lodash';

import { InstallerConfirmed } from '../TieredConfirmationAccepted/components/InstallerConfirmed';
import { InstallerStatus } from '../../components/InstallerStatus';
import BidStatusError from '../../components/Errors';
import { TierUpdateFail } from '../tierManage/components/TierUpdateFail';
import { InstallerDeclinedConfirmation } from '../../components/InstallerDeclined';
import HasBidDraft from './components/HasBidDraft';

import DetailsComponent from './components/DetailsComponent';
import ApproveDeclineQuote from './components/ApproveDeclineQuote';
import MobileCancel from './mobileComponents/MobileCancelSection/';
import TaxExemption from './components/TaxExemption';

import PreliminaryEstimate from './sections/PreliminaryEstimate';
import TitleSection from './sections/Title';
import ProjectInformation from './sections/ProjectInformation';
import WorkerTypeInformation from './sections/WorkerInformation';
import PricingAssumptionsSection from './sections/PricingAssumptions';
import Rebates from './sections/Rebates';
import TotalSection from './sections/TotalSection';
import QuoteApprovedButton from './components/QuoteApprovedButton';
import Concepts from './components/Concepts';

import commonStyles from '../../components/DesignPatternV2.0/commonStyles';
import styledComponents from './styles';

import translationsLocalData from '../../../server/locale/en_US.json';
import RequestCancelled from '../../components/RequestCancelled';
import TermsSection from '../QuoteFinancingPage/sections/TermsSection';
import { useHistory } from 'react-router-dom';
import { QUOTE, PREPAID } from './constants';
import { doesPrepaidItemExist } from './helpers/servicesList';

const { Header, QmeritLogo, H4, P_Contact, GlobalTrackerStyles } = commonStyles;

const {
  MainQuoteWrapper,
  InfoWrapper,
  LeftColumn,
  EstimateDivider,
  MobileSection,
  RightColumn,
  Footer,
  FooterMobile,
  FooterDivider,
  FooterContainer,
} = styledComponents;

export default function QuotePage(props) {
  //Getting Request Guid parsing token from the URL
  const getBidId = (url) => {
    return queryString.parse(url).token;
  };

  //Getting Request data using the Guis previously obtained
  const getIdByGuid = async (bidGuid) => {
    let optionalParams = {
      headers: {
        'Cache-Control': 'no-cache',
        'content-type': 'application/json; charset=UTF-8',
        'Subscription-Key': `${window.env.apiKey}`,
      },
      method: 'GET',
    };
    let data = null;
    try {
      const response = await fetch(`${window.env.qmtUrl}/Estimate/Bid/${bidGuid}`, optionalParams);
      setFetchedBidStatus(response.status);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      data = await response.json();
      setFetchedBidData(data);
      setActiveBid(data.BidList[0]);
      setRequestId(data.Request.Id);
      setFinancingEnabled(data.Request.Program.FinancingEnabled);
      setMarkdown(data.Request.Program.OperationalTerms);
      setFinancingAccepted(data.Request.AcceptedOperationalTermsOn);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
    return data;
  };

  // Handle Accept click
  const acceptBidByGuid = (guid) => {
    setIsLoading(true);

    let optionalParams = {
      headers: {
        'content-type': 'application/json; charset=UTF-8',
        'Subscription-Key': `${window.env.apiKey}`,
      },
      method: 'POST',
    };
    return new Promise((resolve, reject) => {
      fetch(`${window.env.qmtUrl}/Bid/${activeBid.Id}/Confirm`, optionalParams)
        .then((res) => {
          setBidConfirmed(res.status);
          setIsLoading(false);
          setAcceptSuccess(true);
          return;
        })
        .catch((error) => {
          reject({
            isLoading: false,
            fetchedData: { ErrorCode: 500, ...error },
          });
        });
    });
  };

  // Handle Decline click
  const declineBidByGuid = (guid) => {
    setIsLoading(true);

    let optionalParams = {
      headers: {
        'content-type': 'application/json; charset=UTF-8',
        'Subscription-Key': `${window.env.apiKey}`,
      },
      method: 'POST',
    };

    new Promise((resolve, reject) => {
      fetch(`${window.env.qmtUrl}/Bid/${activeBid.Id}/Reject`, optionalParams)
        .then((res) => {
          setBidConfirmed(res.status);
          setIsLoading(false);
          setDeclineSuccess(true);
        })
        .catch((error) => {
          reject({
            isLoading: false,
            fetchedData: { ErrorCode: 500, ...error },
          });
        });
    });
  };

  const [fetchedBidData, setFetchedBidData] = useState([]);
  const [fetchedBidStatus, setFetchedBidStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [browserLanguage, setBrowserLanguage] = useState('en_US');
  const [token, setToken] = useState('');
  const [acceptSuccess, setAcceptSuccess] = useState(false);
  const [translations, setTranslations] = useState({});
  const [bidConfirmed, setBidConfirmed] = useState(false);
  const [declineSuccess, setDeclineSuccess] = useState(false);
  const [activeBid, setActiveBid] = useState('');
  const [requestId, setRequestId] = useState('');
  const [financingEnabled, setFinancingEnabled] = useState(false);
  const [markdown, setMarkdown] = useState(null);
  const [financingAccepted, setFinancingAccepted] = useState(null);
  const [country, setCountry] = useState('');
  const navigate = useHistory();
  const isQuotePage = true;

  useEffect(() => {
    if (_.has(window, 'env.translations') && !_.isEmpty(window.env.translations)) {
      setTranslations(window.env.translations);
    } else {
      setTranslations(translationsLocalData);
    }

    fetch('/language', {
      method: 'POST',
      headers: { 'Cache-Control': 'no-cache', 'Content-Type': 'application/json' },
    })
      .then((res) => res.json())
      .then((data) => setBrowserLanguage(data.browserLanguage));

    let bidId = getBidId(props.location.search);
    let country = queryString.parse(props.location.search).country;
    setToken(bidId);
    if (!_.isEmpty(country)) setCountry(country);

    if (!_.isEmpty(bidId)) {
      getIdByGuid(bidId).then((data) => {
        if (data.BidList[0].NeedsReview == true) {
          navigate.push(`/project-status-tracker?token=${data.Request.Id}`);
        }
      });
    }
  }, []);

  // Handle continue click
  const handleContinue = () => {
    navigate.push(`/finance-option?token=${token}`);
  };

  if (!isLoading && fetchedBidStatus == 200) {
    //If SP tried to revise and 'saved' but not 'submitted' the changes, the Bid enters in "Draft" mode and we prevent the user to approve the quote until such changes are actually submitted
    if (fetchedBidData.Request.HasQuoteDraft) {
      return (
        <HasBidDraft
          projectId={fetchedBidData.ProjectId}
          csEmail={fetchedBidData.Request.Program.CustomerSupportEmail}
          csPhone={fetchedBidData.Request.Program.CustomerSupportPhone}
        />
      );
    }

    if (bidConfirmed === 200 && acceptSuccess === true && translations) {
      return <InstallerConfirmed companyName={activeBid.Assignee.Name} translations={translations} />;
    }

    if (bidConfirmed === 200 && declineSuccess === true && translations) {
      return <InstallerDeclinedConfirmation translations={translations} />;
    }

    if (activeBid.Status === 'cancelled') {
      return <RequestCancelled translations={translations} />;
    }

    if (activeBid.Status === 'rejected') {
      return (
        <InstallerStatus
          translations={translations}
          installerStatus={
            activeBid.Status === 'confirmed' ? 'Accepted' : activeBid.Status === 'rejected' ? 'Cancelled' : ''
          }
        />
      );
    }

    if (activeBid.Status === 'declined') {
      return <BidStatusError status={activeBid.Status} contactInfo={true} />;
    }

    if (activeBid.Status !== 'rejected' && activeBid.Status !== 'declined') {
      return (
        <>
          <GlobalTrackerStyles />
          <Header>
            <QmeritLogo src={`${window.env.blobUrl}/qmerit/wordmark/png/Qmerit-Wordmark-White.png`} />
          </Header>
          <MainQuoteWrapper>
            <InfoWrapper>
              <LeftColumn>
                {/* Title */}
                <TitleSection
                  fetchedBidData={fetchedBidData}
                  bidData={activeBid}
                  translations={translations.quotePage}
                />
                <EstimateDivider />
                {/* Project Information */}
                <ProjectInformation fetchedBidData={fetchedBidData} translations={translations.quotePage} />
                <EstimateDivider />
                {/* WorkerType Information */}
                <WorkerTypeInformation
                  fetchedBidData={fetchedBidData}
                  bidData={activeBid}
                  translations={translations.quotePage}
                />
                <EstimateDivider />
                {/* Preliminary Estimate */}
                <PreliminaryEstimate
                  fetchedBidData={fetchedBidData}
                  bidData={activeBid}
                  translations={translations.quotePage}
                  items={<Concepts fetchedBidData={fetchedBidData} translations={translations.quotePage} />}
                  title={QUOTE}
                />
                <EstimateDivider />
                {/* Prepaid Items */}
                {doesPrepaidItemExist(fetchedBidData.ServicesList) && (
                  <>
                    <PreliminaryEstimate
                      fetchedBidData={fetchedBidData}
                      bidData={activeBid}
                      translations={translations.quotePage}
                      title={PREPAID}
                    />
                    <EstimateDivider />
                  </>
                )}
                {/* Tax Exemption Section */}
                {fetchedBidData.Request?.TaxExemptionContractUrl && (
                  <TaxExemption
                    TaxExemptionContractUrl={fetchedBidData.Request.TaxExemptionContractUrl}
                    screenSize={'mobile'}
                    translations={translations.quotePage}
                  />
                )}
                {/* Pricing Assumptions */}
                <PricingAssumptionsSection
                  fetchedBidData={fetchedBidData}
                  noRebates={
                    _.isEmpty(fetchedBidData.Request.Rebates) || _.isNil(fetchedBidData.Request.Rebates) ? true : false
                  }
                  translations={translations.quotePage}
                />
                {/* Rebates */}
                <Rebates fetchedBidData={fetchedBidData} />
                {/* Terms */}
                {markdown && financingAccepted && <TermsSection markdown={markdown} isQuotePage={isQuotePage} />}
                {/* Mobile View */}
                <MobileSection>
                  <EstimateDivider
                    noRebates={
                      _.isEmpty(fetchedBidData.Request.Rebates) || _.isNil(fetchedBidData.Request.Rebates)
                        ? true
                        : false
                    }
                  />
                  <DetailsComponent
                    fetchedBidData={fetchedBidData}
                    browserLanguage={browserLanguage}
                    bidData={activeBid}
                    translations={translations.quotePage}
                  />
                </MobileSection>
                {/* End of Mobile View */}
                {/* Mobile View */}
                <MobileSection>
                  <EstimateDivider />
                  <MobileCancel requestId={requestId} translations={translations.quotePage} />
                </MobileSection>
                {/* End of Mobile View */}
              </LeftColumn>
              <RightColumn>
                {/* Total Section */}
                <TotalSection
                  fetchedBidData={fetchedBidData}
                  browserLanguage={browserLanguage}
                  requestId={requestId}
                  bidData={activeBid}
                  acceptBidByGuid={acceptBidByGuid}
                  declineBidByGuid={declineBidByGuid}
                  handleContinue={handleContinue}
                  translations={translations.quotePage}
                  financingEnabled={financingEnabled}
                />
              </RightColumn>
            </InfoWrapper>
            <FooterDivider />
            <FooterContainer>
              <Footer>
                <H4>{translations.quotePage.footerSection.message}</H4>
                <P_Contact href={`tel: ${fetchedBidData.Request.Program.CustomerSupportPhone}`}>
                  {fetchedBidData.Request.Program.CustomerSupportPhone}
                </P_Contact>
                <P_Contact href={`mailto: ${fetchedBidData.Request.Program.CustomerSupportEmail}`}>
                  {fetchedBidData.Request.Program.CustomerSupportEmail}
                </P_Contact>
              </Footer>
              {/* Mobile View */}
              <FooterMobile>
                {activeBid.Status !== 'confirmed' ? (
                  <ApproveDeclineQuote
                    acceptBidByGuid={acceptBidByGuid}
                    declineBidByGuid={declineBidByGuid}
                    handleContinue={handleContinue}
                    translations={translations.quotePage}
                    financingEnabled={financingEnabled}
                  />
                ) : (
                  <QuoteApprovedButton translations={translations.quotePage.totalSection.total.QuoteApproved.button} />
                )}
              </FooterMobile>
              {/* End of Mobile View */}
            </FooterContainer>
          </MainQuoteWrapper>
        </>
      );
    }
  }

  if (
    !isLoading &&
    (acceptSuccess === false || [400, 404, 500].includes(bidConfirmed) || [400, 404, 500].includes(fetchedBidStatus))
  ) {
    return <TierUpdateFail translations={translations} />;
  }

  return <Loader color={'#000000'} isLoading={isLoading} country={country} />;
}
