import styled, { css } from "styled-components";

const Container = styled.div`
    display: flex;
	min-height: 100vh
    height: auto;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
      color: #6e727c;
      font-size: 12px;
      line-height: 16px;      
    }
`;

const CardContainer = styled.div`
	margin-top: 56px;
	min-height: 75vh;
	display: flex;
`;

const Card = styled.div`
  width: 480px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14),
    0px 4px 5px 0px rgba(0, 0, 0, 0.12),
    0px 1px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  padding: 24px 24px 24px 24px;
  margin: auto;
  overflow: hidden;
  ::-webkit-scrollbar {
    display: none;
}
`;

const Header = styled.h1`
  color: rgb(62, 65, 72);
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  margin: 0;
  margin-bottom: 24px;
`;

const QuoteInfo = styled.div`
  display: flex;
  flex-direction: row;

  border-bottom: 1px solid #dedfe2;
  padding-bottom: 28px;
  margin-bottom:48px;


  ul {
    margin: 0px 24px 0 0;
    padding: 0px;
	max-width: 204px;
	
	li {
		list-style-type: none;
	
		label {
		  margin-bottom: 3px;
		}
		a {
			color: #3963fe;
		}
	}
  }

  .emailElips {
	text-overflow: ellipsis;
	overflow: hidden;
  }
  .viewFull {
	color: rgb(57, 99, 254);
	font-size: 16px;
	font-family: Lato;
	font-weight: normal;
}
`;

const QuoteDetails = styled.div`
`;

const QuoteDetailsHeader = styled.div`
  text-align: center;

  label {
    display: block;
  }
  .balance {
	color: rgb(62, 65, 72);
	font-size: 24px;
	font-family: Montserrat;
	font-weight: bold;
	text-align: center;
	line-height: 32px;
	text-align: center;
  }
	.details {
		color: rgb(57, 99, 254);
		font-size: 12px;
		font-family: Lato;
		font-weight: bold;
		text-align: center;
		letter-spacing: 0.5px;
		line-height: 16px;
	}
	.chevron {
		display: flex;
		flex-direction: row;
		justify-content: center;
		cursor: pointer;
		i {
			width: 16px;
			height: 16px;
			color: rgb(57, 99, 254);
			font-size: 13.4px;
			font-weight: normal;
			text-align: center;
			line-height: 16px;
			margin 4px;
		}
	}
	.detailBox {
		padding-bottom: 20px;
		.chevron {
			display: flex;
			flex-direction: row;
			justify-content: center;
			cursor: pointer;
			i {
				width: 16px;
				height: 16px;
				color: rgb(57, 99, 254);
				font-size: 13.4px;
				font-weight: normal;
				text-align: center;
				line-height: 16px;
				margin 4px;
			}
		}
		.innerData {
			margin: 24px -24px 0 -24px;
			padding: 24px;
			background: rgb(250, 250, 250);
			border-radius: 0px;
		
			.detailItem {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				.labelSpan {
					display: flex;
					flex-direction: row;
					.itemLabel {
						color: rgb(62, 65, 72);
						font-size: 16px;
						font-family: Lato;
						font-weight: normal;
					}
					.itemMicroLabel {
						color: rgba(62, 65, 72, 0.7);
						font-size: 12px;
						font-family: Lato;
						font-weight: normal;
						margin-top: 8px;
					}

				}
				.itemData {
					color: rgb(62, 65, 72);
					font-size: 16px;
					font-family: Lato;
					font-weight: normal;
					text-align: right;
					& .credit {
						color: rgb(150, 155, 165);
					}
				}
				.credit {
					color: rgb(150, 155, 165);
					font-size: 16px;
					font-family: Lato;
					font-weight: normal;
					margin-left: 12px;
				}
			}
		}
	}

	.borderBalance {
		border-top: 1px solid #dedfe2;
		margin-top: 24px;
	
		.balanceBox {
			margin: 20px 0 0 0;
			.detailItem {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				.remain {
					color: rgb(62, 65, 72);
					font-size: 16px;
					font-family: Lato;
					font-weight: bold;
					line-height: 20px;
				}
			}
		}
	}
	

  .date {
	width: 448px;
	height: 17px;
	color: rgb(110, 114, 124);
	font-size: 12px;
	font-family: Lato;
	font-weight: normal;
	font-style: italic;
	text-align: center;
	letter-spacing: 0.5px;
	line-height: 16px;
  }
`;

const Button = styled.button`

  width: 200px;
  height: 40px;
  background: #7cb342;
  border: 1px solid #7cb342;
  border-radius: 8px;
  
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 16px;
  text-align: center;
  text-transform: capitalize;
  margin: 24px 0px 8px;
  cursor: pointer;

  ${props => props.disabled && css`
    background: #8aa3ff;
    border: none;
   `}
`;

const PoweredBy = styled.img`
  display: block;
  margin: 0 auto;
  height: 24px;
  width: 160px;
  object-fit: contain;
  margin-bottom: 24px;
  margin-top: 56px;
`;

export default {
	Container,
	CardContainer,
	Card,
	Header,
	QuoteInfo,
	QuoteDetails,
	QuoteDetailsHeader,
	Button,
	PoweredBy,
}