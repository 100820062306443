import styled from 'styled-components';

const FeedbackContainer = styled.div`
  display: flex;
  padding: 16px;
  margin-bottom: 48px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  background: #FAFAFA;
  width: 100%;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color:  #3E4148;
    text-align: center;
    margin: 0;
  }
`;

export default {
  FeedbackContainer,
  Text
}