import React, { PureComponent } from "react";

import { apiConfig } from "../../../lib/apiConfig";
import { getToken } from "../../../lib/getUserData";
import Loader from "../../../components/Loader";
import fetchData from "../../../lib/fetchData";
import FetchFailure from "../../../components/FetchFailure";
import QuickCard from "../../../components/QuickCard";
import NoteImg from "../../../../../public/images/note-02.svg";
import ArrowText from "../../../../../public/images/icon-02.svg";
import NearbyInfo from "../NearbyInfo";
import StyledComponent from "./style";
import _ from "lodash";
import TimeAgo from "react-timeago";
import TimeStamp from "../../../lib/timeAgo";

// const itemdata = [
//   {
//     icon: "far fa-clipboard-list",
//     name: "Site Survey",
//     mi: ".2 mi",
//     ca: "Irvine, CA 92602",
//     time: "2 days"
//   },
//   {
//     icon: "fas fa-user-circle",
//     name: "Walkthrough Survey",
//     mi: ".7 mi",
//     ca: "Irvine, CA 92601",
//     time: "1 day"
//   },
//   {
//     icon: "far fa-clipboard-list",
//     name: "Site Survey",
//     mi: "1.4 mi",
//     ca: "Irvine, CA 92604",
//     time: "10 weeks"
//   },
//   {
//     icon: "far fa-clipboard-list",
//     name: "Site Walkthrough",
//     mi: "2.1 mi",
//     ca: "Lake Forest, CA 92609",
//     time: "2 days"
//   },
//   {
//     icon: "far fa-file-invoice-dollar",
//     name: "EV Charger Installation",
//     mi: "3 mi",
//     ca: "Lake Forest, CA 92610",
//     time: "3 days"
//   }
// ];

class Nearby extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      isLoading: false,
      fetchSuccess: false,
      fetchedData: props.fetchedData || null,
      fetchError: false,
      openInfo: false,
      latitude: 0,
      longitude: 0,
      record: "",
      surveyLink: ""
    };
  }

  componentDidMount() {

  }

  openInfoPage(value, index) {

    this.setState({
      openInfo: value,
      record: index
    });
  }

  detailedItem = () => {
    const {
      Items,
      Item,
      Icon,
      Info,
      InfoWraper,
      Holder,
      Name,
      Mi,
      Ca,
      Time
    } = StyledComponent;
    return (
      <Items>
        {_.map(this.state.fetchedData, (data, index) => {
          return (
            <Item onClick={() => this.openInfoPage(true, index)}>
              <Icon>
                <i className={"fas fa-user-circle"} />
              </Icon>
              <Info>
                <InfoWraper>
                  <Holder>
                    <Name>{data.fetchedData.Project.Name}</Name>
                    <Mi>.5 mi</Mi>
                  </Holder>
                  <Holder>
                    <Ca>
                      {data.fetchedData.Location.City},{" "}
                      {data.fetchedData.Location.State}{" "}
                      {data.fetchedData.Location.Zip}
                    </Ca>
                    <Time>2 days</Time>
                  </Holder>
                </InfoWraper>
              </Info>
            </Item>
          );
        })}
      </Items>
    );
  };

  notFound = () => {
    const { NotFind } = StyledComponent;
    return (
      <NotFind>
        <div>
          <i className="fas fa-search-location" />
          <p>
            We can’t find any items nearby— you may want to make sure your
            Location Services are enabled in your Settings.
          </p>
        </div>
      </NotFind>
    );
  };

  render() {
    if (this.state.fetchError) {
      return <FetchFailure />;
    }

    if (this.state.openInfo) {
      let bid = this.state.fetchedData;
      let forward = { ...this.state, selectedBid: bid[this.state.record] };
      return <NearbyInfo {...forward} />;
    }
    const { Root, Wraper, TotalItems } = StyledComponent;
    return (
      <Root notFiend={this.state.fetchedData.length === 0 ? true : false}>
        <QuickCard>
          <Wraper>
            <TotalItems>{this.state.fetchedData.length} Items</TotalItems>
            {this.state.fetchedData.length !== 0
              ? this.detailedItem()
              : this.notFound()}
          </Wraper>
        </QuickCard>
      </Root>
    );
  }
}

export default Nearby;
