import { groupArrays } from "../helper";

// helper for postData()
async function getItemId(dataObj, category) {
  if (category === 'Appliance') {
    const appliancesArray = groupArrays(dataObj.items).Appliance;
    return appliancesArray.slice(-1)[0].id;
  }

  const heavyArray = groupArrays(dataObj.items).HeavyLoad;
  return heavyArray.slice(-1)[0].id;
}

// Create row
export async function postData(id, category) {
  let settings = {
    method: 'POST',
    headers: { 'content-type': 'application/json', "Subscription-Key": `${window.env.apiKey}` },
    body: JSON.stringify({
      category: category
    })
  };

  try {
    const response = await fetch(`${window.env.qmtLoadUrl}/LoadCalculation/${id}/Item`, settings);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    const dataObj = await response.json();
    return await getItemId(dataObj, category);
  } catch (err) {
    console.log(err);
  }
}

// Read recommendations
async function getNewRecommendations(guid) {
  let settings = {
    method: 'GET',
    headers: { 'content-type': 'application/json', "Subscription-Key": `${window.env.apiKey}` }
  }
  try {
    const response = await fetch(`${window.env.qmtLoadUrl}/LoadCalculation/${guid}`, settings);
    if (!response.ok) throw new Error(`HTTP error! Load Calcualtion status: ${response.status}`);
    const obj = await response.json();
    return obj.recommendations;
  } catch (err) {
    console.log(err);
  }
}

// Update mainBreakerAmperage
export async function putMainBreakerAmp(id, mainBreakerAmp) {
  let settings = {
    method: 'PUT',
    headers: { 'content-type': 'application/json', "Subscription-Key": `${window.env.apiKey}` },
    body: JSON.stringify({
      mainBreakerAmperage: mainBreakerAmp
    })
  };

  try {
    const response = await fetch(`${window.env.qmtLoadUrl}/LoadCalculation/${id}`, settings);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
  } catch (err) {
    console.log(err);
  }
}

// Wrapper to update mainBreakerAmperage & get recommendations
export async function updateMainBreakerAmpAndRecs(id, mainBreakerAmp, setCurrentRec, guid) {
  await putMainBreakerAmp(id, mainBreakerAmp);
  const rec = await getNewRecommendations(guid);
  setCurrentRec(rec);
}

// Update input
export async function putData(id, itemId, itemName, description = '', value1 = 0, value2 = 0) {
  let settings = {
    method: 'PUT',
    headers: { 'content-type': 'application/json', "Subscription-Key": `${window.env.apiKey}` },
    body: JSON.stringify({
      name: itemName,
      description: description,
      value1: parseFloat(value1),
      value2: parseFloat(value2)
    })
  };

  try {
    const response = await fetch(`${window.env.qmtLoadUrl}/LoadCalculation/${id}/Item/${itemId}`, settings);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
  } catch (err) {
    console.log(err);
  }
}

// Wrapper to update value1 and value2 & get recommendations
export async function updateValuesAndRecs(id, itemId, inputName, description, inputValue, setCurrentRec, guid, value1 = -1, value2 = -1) {
  if (value1 === -1) {
    await putData(id, itemId, inputName, description, inputValue, value2);
    // console.log(`=> change value1 putData(${id}, ${itemId}, ${inputName}, ${description}, ${inputValue}, ${value2});`);
  } else if (value2 === -1) {
    await putData(id, itemId, inputName, description, value1, inputValue);
    // console.log(`=> change value2 putData(${id}, ${itemId}, ${inputName}, ${description}, ${value1}, ${inputValue});`);
  }
  const rec = await getNewRecommendations(guid);
  setCurrentRec(rec);
}

// Wrapper to update AC & get recommendations
export async function updateACValueAndRecs(id, acId, inputName, description, inputValue, value2, setCurrentRec, guid) {
  await putData(id, acId, inputName, description, inputValue, value2);
  const rec = await getNewRecommendations(guid);
  setCurrentRec(rec);
}

// Delete row
export async function deleteData(id, itemId) {
  let settings = {
    method: 'DELETE',
    headers: { 'content-type': 'application/json', "Subscription-Key": `${window.env.apiKey}` }
  };

  try {
    const response = await fetch(`${window.env.qmtLoadUrl}/LoadCalculation/${id}/Item/${itemId}`, settings);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
  } catch (err) {
    console.log(err);
  }
}