import styled, { css } from "styled-components";

const Title = styled("h4")`
  font-size: 14px;
  color: #3e4148;
  font-weight: 400;
  margin: 0;
  margin-bottom: 4px;
`;

const QuoteDollar = styled("span")`
  color: #b9bbc1;
  font-size: 16px;
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
`;
const TotalEstimatedCostInputWraper = styled("div")`
  position: relative;
  flex: 0 0 calc(100% - 92px);
  max-width: calc(100% - 92px);
`;
const TotalEstimatedWraper = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border: 1px solid #dedfe2;
  background-color: #fafafa;
  height: 44px;
  border-radius: 4px;
  input {
    border: 0;
    width: 100%;
    height: 100%;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    margin: 0;
    padding: 0 16px 0 28px;
    text-align: left;
    border-radius: 4px;
    background-color: #fafafa;
    color: #3e4148;
    -moz-box-shadow: inset 0 0 0 30px #fff;
    -webkit-box-shadow: inset 0 0 0 30px #fff;
    box-shadow: inset 0 0 0 30px #fff;
  }
  ${({ error }) =>
    error &&
    css`
      border: 1px solid #ff5722;
      ${QuoteDollar} {
        color: red;
      }
      input {
        background-color: #fbe9e7;
        color: #ff5722;
        -moz-box-shadow: inset 0 0 0 30px #fbe9e7;
        -webkit-box-shadow: inset 0 0 0 30px #fbe9e7;
        box-shadow: inset 0 0 0 30px #fbe9e7;
      }
    `};
`;
const TotalEstimatedCostBtnWraper = styled("div")`
  flex: 0 0 92px;
  max-width: 92px;
  button {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: 1px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    border: 1px solid #dedfe2;
    height: 100%;
    background-color: #dedfe2;
    padding: 6px;
    color: #6e727c;
    border-radius: 0 4px 4px 0;
    &:hover {
      background-color: #d8d8d8;
    }
    ${({ error }) =>
      error &&
      css`
        border-color: #ff5722;
        background-color: #ff5722;
        color: #fff;
      `} i {
      font-size: 30px;
      line-height: 20px;
      padding-left: 10px;
      transform: translateY(2px);
    }
  }
`;
const QuoteErrorContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  > span {
    margin: 2.55px;
    color: #ff5722;
  }
  > div {
    font-style: italic;
    color: #ff5722;
  }
`;

export default {
  Title,
  QuoteDollar,
  TotalEstimatedWraper,
  TotalEstimatedCostInputWraper,
  TotalEstimatedCostBtnWraper,
  QuoteErrorContainer
};
