import React, { PureComponent, Fragment } from "react";
import Loader from "../../../components/Loader";
import fetchData from "../../../lib/fetchData";
import QuickCard from "../../../components/QuickCard";
import QmeritLogo from "../../../components/QmeritLogo";
import DocImage from "../../../../../public/images/DocImage.png";
import styles from "./styles.module.css";
import { apiConfig } from "../../../lib/apiConfig";
import FetchFailure from "../../../components/FetchFailure";
import { Banner, Pill } from "@q-core/components/lib/general";
import _ from "lodash";
import BidCard from "../../../../../public/images/BidCard.png";
import QuickSubmitQuote from "../../../features/BidStatus/QuickSubmitQuote";
import styled from "styled-components";
import { theme, mediaMax } from "../../../shared/style";

class BidAccept extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props,
      hasPostData: false,
      showSubmit: false,
      usingMock: false,
    };
  }

  componentDidMount() {
    if (this.state.usingMock) {
      console.log("mocking bird", this.state);
    } else {
      if (!this.state.hasPostData) {
        const options = {
          ...apiConfig.postConsumerInfo,
          query: "token=" + this.state.fetchedData.OperationGuids.Accept
        };
        fetchData(options).then(data => {
          if (data && data.fetchedData && !data.fetchedData.ErrorCode) {
            this.setState({ ...data, fetchSuccess: true });
          } else {
            this.setState({ fetchError: true });
          }
        });
      }
    }
    this.setState({
      coreLink: `${window.env.webRoot}`
    });
  }

  changeBidView() {
    this.setState({
      showSubmit: true
    });
  }

  render() {
    const {
      fetchedData,
      localDueDate,
      localDueTime,
      localCreatedDate,
      localCreatedTime,
      localAcceptedDate,
      localAcceptedTime
    } = this.state;
    const fetchedDataActivities = fetchedData.Activities[0];

    if (this.state.fetchError) {
      return <FetchFailure />;
    }

    if (this.state.showSubmit) {
      return <QuickSubmitQuote {...this.state} />;
    }

    return (
      <QuickCard
        header
        headerText="Thanks for choosing to bid!"
        headerType="info"
      >
        <Loader isLoading={this.state.isLoading}>
          <div className="main-content bid-view">
            <div className={styles.bidderLogo}>
              {QmeritLogo}
              <p>Qmerit</p>
            </div>
            <a href={this.state.coreLink} className={styles.bidderName}>
              {fetchedDataActivities
                ? fetchedDataActivities.Project &&
                  fetchedDataActivities.Project.Name
                : ""}
            </a>
            <h3 className={styles.bidRequest}>
              Bid Request #
              {fetchedDataActivities
                ? fetchedDataActivities.BidRequest &&
                  fetchedDataActivities.BidRequest.Id
                : ""}
            </h3>
            <p className={styles.description}>
              {fetchedDataActivities.Description}
            </p>
            {fetchedDataActivities &&
              fetchedDataActivities.Project &&
              fetchedDataActivities.Project.Attachments && (
                <Fragment>
                  {fetchedDataActivities.Project.Attachments.map(
                    (file, index) => (
                      <div key={index} className={styles.documentArea}>
                        <a
                          href={file.Url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={DocImage}
                            alt="document thumbnail"
                            className={styles.over}
                          />
                        </a>
                        <a
                          href={file.Url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <h4>{file.Name}</h4>
                        </a>
                      </div>
                    )
                  )}
                </Fragment>
              )}
            <div className={styles.customerInfo}>
              {fetchedDataActivities &&
                fetchedDataActivities.Customer && (
                  <div className={styles.customerOverflow}>
                    <p className={styles.infoLabel}>customer contact</p>
                    <p className={styles.contactName}>
                      {fetchedDataActivities.Customer.Name}
                    </p>
                    {fetchedDataActivities.Customer &&
                      fetchedDataActivities.Customer.Contact &&
                      fetchedDataActivities.Customer.Contact.PhoneNumber && (
                        <a
                          href={`tel:${
                            fetchedDataActivities.Customer.Contact.PhoneNumber
                          }`}
                          title="Phone Number"
                          className={styles.contactDetailsEmail}
                        >
                          {fetchedDataActivities.Customer.Contact.PhoneNumber}
                        </a>
                      )}
                    {fetchedDataActivities.Customer.Contact.PreferredMethod ===
                      "phone" && (
                      <p className={styles.preferredContact}>
                        Preferred Contact
                      </p>
                    )}
                    {fetchedDataActivities.Customer &&
                      fetchedDataActivities.Customer.Contact &&
                      fetchedDataActivities.Customer.Contact.Email && (
                        <a
                          href={`mailto:${
                            fetchedDataActivities.Customer.Contact.Email
                          }`}
                          title="Email Address"
                          className={styles.contactDetailsEmail}
                        >
                          {fetchedDataActivities.Customer.Contact.Email}
                        </a>
                      )}
                    {fetchedDataActivities.Customer.Contact.PreferredMethod ===
                      "email" && (
                      <p className={styles.preferredContact}>
                        Preferred Contact
                      </p>
                    )}
                  </div>
                )}
              {fetchedDataActivities &&
                fetchedDataActivities.Location && (
                  <div className={styles.customerLocationWrapper}>
                    <div className={styles.infoLabel}>site</div>
                    <a
                      href={`https://www.google.com/maps/search/?api=1&query=${
                        fetchedDataActivities.Location.Address
                      } ${fetchedDataActivities.Location.City}, ${
                        fetchedDataActivities.Location.State
                      } ${fetchedDataActivities.Location.Zip}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p className={styles.siteAddressDetails}>
                        {fetchedDataActivities.Location.Address}
                      </p>
                      <p className={styles.siteAddressDetails}>
                        {fetchedDataActivities.Location.City},{" "}
                        {fetchedDataActivities.Location.State}{" "}
                        {fetchedDataActivities.Location.Zip}
                      </p>
                    </a>
                  </div>
                )}
            </div>
            <div className={styles.infoField}>
              {fetchedDataActivities &&
                fetchedDataActivities &&
                fetchedDataActivities.DueDate &&
                this.state.localDueDate &&
                this.state.localDueTime && (
                  <div className={styles.customerLocationWrapper}>
                    <p className={styles.infoLabel}>due</p>
                    <p className={styles.bidDetails}>
                      {localDueDate}, {localDueTime}
                    </p>
                  </div>
                )}
              {fetchedDataActivities &&
                fetchedDataActivities.Priority && (
                  <div className={styles.customerLocationWrapper}>
                    <p className={styles.infoLabel}>Priority</p>
                    <p className={styles.bidDetails}>
                      {fetchedDataActivities.Priority.Description}
                    </p>
                  </div>
                )}
            </div>
            <div className={styles.infoField}>
              {fetchedDataActivities &&
                fetchedDataActivities.CreatedOn &&
                localCreatedDate &&
                localCreatedTime && (
                  <div className={styles.customerLocationWrapper}>
                    <p className={styles.infoLabel}>Created</p>
                    <p className={styles.bidDetails}>
                      {localCreatedDate}, {localCreatedTime}
                    </p>
                  </div>
                )}
              {fetchedDataActivities &&
                fetchedDataActivities.AcceptedBy &&
                fetchedDataActivities.AcceptedBy &&
                localAcceptedDate &&
                localAcceptedTime && (
                  <div className={styles.customerLocationWrapper}>
                    <p className={styles.infoLabel}>Accepted</p>
                    <p className={styles.bidDetails}>
                      {localAcceptedDate}, {localAcceptedTime}
                    </p>
                    {fetchedDataActivities.AcceptedBy &&
                      fetchedDataActivities.AcceptedBy.Name && (
                        <p className={styles.bidDetails}>
                          by {fetchedDataActivities.AcceptedBy.Name}
                        </p>
                      )}
                  </div>
                )}
            </div>
            {!this.state.usingMock &&
              _.has(fetchedDataActivities, "OwnerProgram.ExternalData") &&
              !_.isEmpty(fetchedDataActivities.OwnerProgram.ExternalData) && (
                <Banner
                  {...fetchedDataActivities.OwnerProgram.ExternalData}
                  className={styles.banner}
                />
              )}
            <div className={styles.viewButtonWrapper}>
              <a
                className={styles.viewButton}
                href={`${this.state.coreLink}/activities/open?bidId=${
                  fetchedDataActivities.Id
                }`}
              >
                <b>more details</b>
              </a>
            </div>
          </div>
          <div className={styles.bidNowSection}>
            <div className={styles.viewBidNowTitle}>
              Have enough info? Bid now!
            </div>
            <div className={styles.viewBidNowText}>
              All you&apos;ll need to do is upload your bid and input the total
              estimated cost. You can even add a messge that the customer will
              be able to see.
            </div>

            <BidNowWraper>
              <BidNowCol>
                <BidNowButton onClick={() => this.changeBidView()}>
                  BID NOW
                </BidNowButton>
              </BidNowCol>
              <BidNowCol>
                <BidNowImg src={BidCard} />
              </BidNowCol>
            </BidNowWraper>
          </div>
          <div className={styles.viewBidContact}>
            If you have any questions about this bid, contact{" "}
            {fetchedDataActivities.Owner.DBAName} by calling{" "}
            <a
              href={`tel:${fetchedDataActivities.Owner.Contact.PhoneNumber}`}
              title="Phone Number"
              className={styles.viewBidContactText}
            >
              {fetchedDataActivities.Owner.Contact.PhoneNumber}
            </a>{" "}
            or emailing{" "}
            <a
              href={`mailto:${fetchedDataActivities.Owner.Contact.Email}`}
              title="Email Address"
              className={styles.viewBidContactText}
            >
              {fetchedDataActivities.Owner.Contact.Email}
            </a>
            .
          </div>
        </Loader>
      </QuickCard>
    );
  }
}

export default BidAccept;
const BidNowWraper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -12px;
  line-height: 0;
`;

const BidNowCol = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 12px;
  ${mediaMax.phone`
   flex: 0 0 100%;
   max-width: 100%;
   text-align: center;
 `};
`;
const BidNowButton = styled.button`
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 1px;

  height: 40px;
  background-image: linear-gradient(to bottom, #ffffff, #f4f5f6);
  border-radius: 100px;
  color: #00bcd4;
  cursor: pointer;
  border: none;

  width: 100%;

  ${mediaMax.phone`
  max-width: 264px;margin-bottom: 12px;
 `};
`;
const BidNowImg = styled.img`
  width: 100%;
  max-height: 100%;
  margin-top: 1px;
  ${mediaMax.phone`
  max-width: 264px;
 `};
`;
