import styled from 'styled-components';
import {
  mediaMax
} from "../../../../shared/style";

const QuoteApprovedContainer = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;

  width: 100%;
  height: 52px;

  background: #7BC342;
  border-radius: 4px;
  border: none;

  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;

  &:hover {
    box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }

  &:disabled {
    background: #F1F8E9;
    color: #AED581;

    :hover {
      box-shadow: none;
      cursor: auto;
    }
  }
`;

export default {
  QuoteApprovedContainer
};

