import React from "react";
import { ButtonWrapper } from "./styles";
import { Button } from "../../../../shared/style";

/** renders the cancel and done button for different views in AcceptedBids */
export default ({ goBack, handleSubmit, disableSubmit }) => {
  return (
    <ButtonWrapper>
      <div>
        <Button fWidth pFloating onClick={goBack}>
          CANCEL
        </Button>
      </div>
      <div>
        <Button
          action
          fWidth
          pFloating
          disabled={disableSubmit}
          onClick={handleSubmit}
          className={disableSubmit ? "disabled" : ""}
        >
          Done
        </Button>
      </div>
    </ButtonWrapper>
  );
};
