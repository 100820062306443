import React, { Component, Fragment } from "react";
import styledComponents from "./style";
class DropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropDownActive: false
    };
  }
  dropDownToggle = () => {
    this.setState({
      isDropDownActive: !this.state.isDropDownActive
    });
  };
  setSelected = value => {
    this.dropDownToggle();
    this.props.setSelected(value);
  };
  render() {
    const {
      DropDownBtn,
      DropDownOption,
      DropDownOptions,
      DropDownWrapper,
      DropDownLabel
    } = styledComponents;
    const { isDropDownActive } = this.state;
    const {
      data,
      selected,
      label,
      isOptional,
      mb,
      clr,
      placeholder,
      noLabel
    } = this.props;
    return (
      <Fragment>
        {!noLabel && (
          <DropDownLabel>
            {label}
            {isOptional && <span> - Optional</span>}
          </DropDownLabel>
        )}
        <DropDownWrapper mb={mb}>
          <DropDownBtn
            clr={clr}
            active={isDropDownActive}
            onClick={this.dropDownToggle}
            // onBlur={this.dropDownToggle}
          >
            {placeholder && !selected ? (
              <span className="placeholder">{placeholder}</span>
            ) : (
              <span>{selected || data[0]}</span>
            )}
            <i className="far fa-chevron-down" />
          </DropDownBtn>
          {isDropDownActive && (
            <DropDownOptions active={isDropDownActive} count={data.length}>
              {data.map((val, index) => {
                return (
                  <DropDownOption
                    onClick={() => this.setSelected(val)}
                    key={index}
                  >
                    <span key={index}>{val}</span>
                    {val === selected && <i className="far fa-check" />}
                  </DropDownOption>
                );
              })}
            </DropDownOptions>
          )}
        </DropDownWrapper>
      </Fragment>
    );
  }
}
export default DropDown;
