import React, { useEffect } from 'react';
import styledComponents from './styles';
import TotalRow from '../../components/TotalRow';
import { getSum, formatNumber } from '../../helper';

const {
  CalculationContainer,
  IconBox
} = styledComponents;

// TODO: component currently sets the amps value in SemiCircleProgressbar, but is not used in the UI... need to move setTotalAmp
export default function Calculations({general, appliances, heavyLoad, ac, id, setTotalAmp, acValue1, setACValue1}) {
  const acValue = acValue1 !== null ? acValue1 : ac.value1;

  useEffect(() => {
    const loadSubtotal = getSum(new Array(general, appliances, heavyLoad));
    setTotalAmp(((((loadSubtotal - 10000) * 0.4) + 10000) + parseFloat(acValue)) / 240);
}, [general, appliances, heavyLoad, acValue1])

  const loadSubtotal = getSum(new Array(general, appliances, heavyLoad));

  return (
    <CalculationContainer>
      <div className="subtotal">
        <div className="row">
          <div className="numberCenter">
            <p>{formatNumber(general)}</p>
          </div>
          <IconBox><i className="far fa-plus"/></IconBox>
          <div className="numberCenter">
            <p>{formatNumber(appliances)}</p>
          </div>
          <IconBox><i className="far fa-plus"/></IconBox>
          <div className="numberCenter">
            <p>{formatNumber(heavyLoad)}</p>
          </div>
          <IconBox><i className="far fa-equals"/></IconBox>
          <div className="numberTotal">
            <p>{formatNumber(loadSubtotal)}</p>
          </div>
        </div>
        <TotalRow label={'Load Subtotal'} total={(loadSubtotal)} uom={'watts'} />
      </div>

      <div className="subtotal">
        <div className="row">
          <div className="numberCenter">
            <p>{formatNumber(loadSubtotal)}</p>
          </div>
          <IconBox><i className="far fa-minus"/></IconBox>
          <div className="numberCenter">
            <p>{formatNumber(10000)}</p>
            <p className="pGray">watts</p>
          </div>
          <IconBox><i className="far fa-equals"/></IconBox>
          <div className="numberCenter">
            <p>{formatNumber(loadSubtotal - 10000)}</p>
          </div>
          <IconBox><i className="far fa-times"/></IconBox>
          <div className="numberEnd">
            <p>40</p>
            <p className="pGray">%</p>
          </div>
          <IconBox><i className="far fa-equals"/></IconBox>
          <div className="numberTotal">
            <p>{formatNumber((loadSubtotal - 10000) * 0.4)}</p>
          </div>
        </div>
        <div className="row">
          <div className="numberCenter">
            <p>{formatNumber((loadSubtotal - 10000) * 0.4)}</p>
          </div>
          <IconBox><i className="far fa-plus"/></IconBox>
          <div className="numberEnd">
            <p>{formatNumber(10000)}</p>
            <p className="pGray">watts</p>
          </div>
          <IconBox><i className="far fa-equals"/></IconBox>
          <div className="numberTotal">
            <p>{formatNumber(((loadSubtotal - 10000) * 0.4) + 10000)}</p>
          </div>
        </div>
        <div className="row">
          <div className="numberCenter" key={acValue}>
            <p>{formatNumber(acValue)}</p>
            <p className="pGray">A/C load watts</p>
          </div>
          <IconBox><i className="far fa-plus"/></IconBox>
          <div className="numberEnd">
            <p>{formatNumber(((loadSubtotal - 10000) * 0.4) + 10000)}</p>
          </div>
          <IconBox><i className="far fa-equals"/></IconBox>
          <div className="numberTotal">
            <p>
              {formatNumber((((loadSubtotal - 10000) * 0.4) + 10000) + parseFloat(acValue))}
            </p>
          </div>
        </div>
        <TotalRow 
          label={'Total Watts'} 
          total={((((loadSubtotal - 10000) * 0.4) + 10000) + parseFloat(acValue))}
          uom={'watts'} 
        />
      </div>

      <div className="subtotal">
        <div className="row">
          <div className="numberCenter">
            <p>
            {formatNumber((((loadSubtotal - 10000) * 0.4) + 10000) + parseFloat(acValue))}
            </p>
            <p className="pGray">watts</p>
          </div>
          <IconBox><i className="far fa-divide"/></IconBox>
          <div className="numberEnd">
            <p>240</p>
            <p className="pGray">volts</p>
          </div>
          <IconBox><i className="far fa-equals"/></IconBox>
          <div className="numberTotal">
            <p>
            {formatNumber(((((loadSubtotal - 10000) * 0.4) + 10000) + parseFloat(acValue)) / 240)}
            </p>
          </div>
        </div>
        <TotalRow 
          label={'Total Amperage'} 
          total={(((((loadSubtotal - 10000) * 0.4) + 10000) + parseFloat(acValue)) / 240)}
          uom={'amps'} 
        />
      </div>
    </CalculationContainer>
  )
}