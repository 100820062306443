import React from "react";
import Message from "../../components/message";
import Wrapper from "../Wrapper";
import Ghost from "../../../../public/images/ghostie.svg";

const NotFound = props => {
  return (
    <Message background={props.maps}>
      <img style={{ marginBottom: "1rem" }} alt="Page Not Found" src={Ghost} />
      <h1 style={{ color: "#3E4148" }}>There&apos;s nothing here for you&hellip;</h1>
      <p style={{ color: "#3E4148" }}>
        This item was either &quot;removed&quot; or never existed in the first
        place. Double-check your link to make sure that you didn&apos;t hit this
        page accidentally.
      </p>
    </Message>
  );
};

export default NotFound;
