import _ from "lodash";

const urlController = {
  url: "",
  validUrl: false,
  buildUrl: function(options) {
    this.url = options.root ? options.root + "/" : "";
    if (options.version && options.version.length > 0)
      this.url += options.version + "/";
    if (options.url) this.url += options.url;
    if (options.query && options.query.length > 0)
      this.url += "?" + options.query;
    if (options.locParams && options.locParams.length > 0)
      this.url += "&" + options.locParams;

    return this;
  },
  validateUrl: function() {
    // from: https://gist.github.com/dperini/729294
    this.validUrl = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      this.url
    );
    return this;
  }
};

const getFetchOptions = options => {
  let newOptions = {};
  if (_.has(options, "body")) {
    newOptions.body = JSON.stringify(options.body);
  }

  if (_.has(options, "method")) {
    newOptions.method = options.method;
  }

  newOptions.headers = {
    "Content-Type": "application/json"
  };

  return newOptions;
};

const fetchData = options => {
  return new Promise((resolve, reject) => {
    fetch(
      urlController.buildUrl(options).validateUrl().url,
      getFetchOptions(options)
    )
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          return { ErrorCode: response.status };
        }
      })
      .then(data => {
        if (data) resolve({ fetchedData: data, isLoading: false });
      })
      .catch(fetchError => {
        reject({
          isLoading: false,
          fetchedData: { ErrorCode: 500, ...fetchError }
        });
      });
  });
};

const fetchData2 = options => {
  return fetch(
    urlController.buildUrl(options).validateUrl().url,
    getFetchOptions(options)
  )
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        return { ErrorCode: response.status };
      }
    })
    .then(data => {
      return { fetchedData: data, isLoading: false };
    })
    .catch(fetchError => {
      Promise.reject({
        isLoading: false,
        fetchedData: { ErrorCode: 500, ...fetchError }
      });
    });
};

export default fetchData;
