
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import styledComponents from "./style";
import queryString from "query-string";
import _ from 'lodash';

import Loader from '../../components/Loader';
import { TierUpdateFail } from '../tierManage/components/TierUpdateFail';

const {
    TermsContainer,
    TermsButton,
    TermsButtonContainer,
    TermsSideTextButton,
    Iframe,
    Img,
    LogoContainer
} = styledComponents;


const TermsOfUse = (props) => {

    const [termsViewed, setTermsViewed] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [htmlUrl, setHtmlUrl] = useState(true);
    const [fetchRequestStatus, setFetchRequestStatus] = useState(false);
    const [requestData, setRequestData] = useState(false);
    const [acceptPostStatus, setAcceptPostStatus] = useState(false);

    //Getting Request Guid parsing requestId from the URL
    const getRequestId = (url) => {
        return queryString.parse(url).requestId
    }

    //Getting Request data using the Guis previously obtained
    const getRequestData = async (requestId) => {
        let optionalParams = {
            headers: { "Cache-Control": "no-cache", "content-type": "application/json; charset=UTF-8", "Subscription-Key": `${window.env.apiKey}` },
            method: "GET"
        }
        let data = null;
        const response = await fetch(`${window.env.qmtMrktPlcUrl}/Request/${requestId}`, optionalParams);
        //Setting the status of the fetch so we can use it as a flag of when the data fetching was performed correctly
        setFetchRequestStatus(response.status);
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        data = await response.json();
        //Filling the requestData state variable with the response data 
        setRequestData(data);
        //Setting the HTML URL we're going to fetch the TOU from and then setting the loader to false so we can render accordingly 
        setHtmlUrl(`${window.env.evBlob}/terms-of-use/html/${data.termsOfUse.termsOfUse}_en_US.html`)
        setIsLoading(false);
        return data;
    }

    const confirmTerms = async (referenceId, requestData) => {
        setIsLoading(true);
        let data = { termsOfUse: `${requestData.termsOfUse.termsOfUse}.pdf` }
        let options = {
            headers: { "content-type": "application/json; charset=UTF-8", "Subscription-Key": `${window.env.apiKey}` },
            body: JSON.stringify(data),
            method: "POST"
        }

        const postCall = await fetch(`${window.env.qmtMrktPlcUrl}/Request/${referenceId}/TermsOfUse/Accept`, options);
        //Setting the status of the fetch so we can use it as a flag of when the TOU were accepted and saved correctly
        setAcceptPostStatus(postCall.status);
        if (!postCall.ok) throw new Error(`HTTP error! status: ${postCall.status}`);
        // Setting the loader to false so we can render accordingly
        setIsLoading(false);
    }

    useEffect(() => {
        window.addEventListener('message', handleScrollToBottom, false)

        //These lines of code are commented out since they can be used in later sprints when we add localization

        /*   if (_.has(window, 'env.translations') && !_.isEmpty(window.env.translations)) setTranslations(window.env.translations);
            else setTranslations(translationsLocalData);
      
          fetch("/language", {
            method: "POST",
            headers: { "Cache-Control": "no-cache", "Content-Type": "application/json" },
          })
            .then((res) => res.json())
            .then((data) => setBrowserLanguage(data.browserLanguage))
       */

        let requestId = getRequestId(props.location.search);
        if (!_.isEmpty(requestId)) getRequestData(requestId);
    }, []);

    const handleScrollToBottom = (event) => {
        if (event && event.data === 'ScrollingComplete') {
            setTermsViewed(true)
        }
    }


    if (!isLoading && ![400, 404, 500].includes(fetchRequestStatus)) {

        if (!_.isNil(requestData.deposit)) {
            if (requestData.deposit.paidOn)
                return <Redirect to={`/deposit-success?requestId=${requestData.referenceId}`} />
        }

        if (!_.isNil(requestData.termsOfUse)) {
            if (requestData.termsOfUse.signedTermsOfUse || acceptPostStatus == 200) {
                if (requestData.deposit) window.location.replace(requestData.deposit.url)
                else return <Redirect to={`/deposit-success?requestId=${requestData.referenceId}`} />
            }


            if (!requestData.termsOfUse.signedTermsOfUse && acceptPostStatus != 200)
                return (
                    <TermsContainer onScroll={props.onHandleScroll}>
                        <LogoContainer>
                            <Img src={`${window.env.blobUrl}/qmerit/Qmerit-Wordmark-Indiqo.png`} alt="Raiven" />
                        </LogoContainer>
                        <Iframe data-testid="touFrame" id='touFrame' src={htmlUrl} />
                        <TermsButtonContainer>
                            <TermsSideTextButton displayed={!termsViewed}>Scroll to the bottom to accept</TermsSideTextButton>
                            <TermsButton actived={!termsViewed} disabled={!termsViewed} onClick={() => confirmTerms(requestData.referenceId, requestData)}>Accept</TermsButton>
                        </TermsButtonContainer>
                    </TermsContainer>
                );
        }
    }

    if (!isLoading && ([400, 404, 500].includes(fetchRequestStatus))) {
        return <TierUpdateFail translations={translations} />
    }

    return (<Loader color={"#000000"} isLoading={isLoading} />)
}

export default TermsOfUse
