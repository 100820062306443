import styled, { css } from "styled-components";
import {
  mediaMax,
  mediaMin,
  headerSizes,
  theme,
  fade
} from "../../shared/style";
import { Article, Section } from "../../components/QuickCard";
const Root = styled.div`
  display: flex;
  align-items: center;
  min-height: 100vh;
  ${Article} {
    ${mediaMin.phone` max-width: 480px;`};
  }
  ${Section} {
    padding: 16px;
    ${mediaMax.phone`    display: flex;
    flex-direction: column;
    min-height: 100vh`};
  }
`;

const Container = styled.div`

  width: 100%;
  display: flex;
  justify-content: center;

`;

const Title = styled.h2`
  margin: 0 0 16px;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${theme.IndiqoGrey[800]};
`;
const Text = styled.p`
  margin: 0px 0px 4px;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: ${theme.IndiqoGrey[800]};
  i {
    color: ${theme.IndiqoGrey[400]};
  }
`;
const Btn = styled.button`
  cursor: pointer;
  display: block;
  margin: 0 auto;
  text-transform: uppercase;
  width: 200px;
  height: 40px;
  border-radius: 100px;
  border: solid 1px ${theme.OrangeyRed[200]};
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 1px;
  text-align: center;
  color: ${theme.White};

  background-image: linear-gradient(
    to bottom,
    ${theme.OrangeyRed[100]},
    ${theme.OrangeyRed[200]}
  );
  &:hover {
    background-image: linear-gradient(
      to bottom,
      ${theme.OrangeyRed[200]},
      ${theme.OrangeyRed[100]}
    );
  }
`;

const ContentWrapper = styled.div`
  padding: 0px;
`;
const RadioWrapper = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;700;900&family=Red+Hat+Display:wght@400;500;700&display=swap');
  font-family: "Mulish", sans-serif;
  padding: 6px 0 10px;
`;
const DropDownWrapper = styled.div`
  position: relative;
`;

const DropDownBtn = styled.button`
  padding: 0 12px;
  cursor: pointer;
  width: 100%;
  height: 44px;
  border-radius: 4px;
  border: solid 1px ${theme.IndiqoGrey[300]};
  background-color: ${theme.IndiqoGrey.main};
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${theme.IndiqoGrey[800]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  i {
    color: ${theme.IndiqoGrey[500]};
    transition: 0.3s;
    ${props =>
      props.active &&
      css`
        transform: scaleY(-1);
      `};
  }
`;
const DropDownOptions = styled.div`
  position: absolute;
  margin-top: 4px;
  top: 100%;
  left: 0;
  z-index: 1;
  width: 100%;
  height: ${props => (props.active ? `${props.count * 36}` : "0")}px;
  overflow: hidden;
  transition: 0.3s;
  border-radius: 8px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 6px 10px 0 rgba(0, 0, 0, 0.14);
`;
const DropDownOption = styled.button`
  height: 36px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${theme.IndiqoGrey[800]};
  background: ${theme.White};
  padding: 0 12px;
  border: 0;

  cursor: pointer;
  &:hover {
    background: ${theme.IndiqoGrey[100]};
  }
  i {
    font-size: 16px;
    color: ${theme.Primary.main};
  }
`;

const MessageWrapper = styled.div`
  padding: 0 12px;
`;
const TextArea = styled.textarea`
  padding: 12px;
  width: 100% !important;
  min-height: 64px;
  border-radius: 4px;
  border: solid 1px ${theme.IndiqoGrey[300]};
  background-color: ${theme.IndiqoGrey.main};
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: normal;
  max-width: 100% !important;
  min-width: 100% !important;
`;

const PoweredBy = styled.img`
  display: block;
  margin: 0 auto;
  height: 24px;
  width: 134px;
  object-fit: contain;
  position: fixed;
  bottom: 24px;
  width: auto;

  @media (max-height: 700px) {
    bottom: 12px;
  }

`;

export default {
  Root,
  Container,
  Title,
  Text,
  Btn,
  ContentWrapper,
  RadioWrapper,
  DropDownWrapper,
  DropDownBtn,
  DropDownOptions,
  DropDownOption,
  MessageWrapper,
  TextArea,
  PoweredBy
};
