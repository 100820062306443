import styled from 'styled-components';
import {
  mediaMax,
  mediaMin
} from "../../../../shared/style"


const PricingAssumptionsContainer = styled.div`

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;

  ${mediaMax.desktop`
    display: none;
  `};

`;

const PricingAssumptions = styled.div`

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;

  width: 50%;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

`;

const UnorderedListTruncated = styled.ul`

  padding-left: 20px;
  margin: 0px;
  max-height: 80px;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;

`;

const EstimateDivider = styled.div`
  display: block;
  height: 1px
  background: #DEDFE2;
  margin: 48px 0px;

  ${mediaMax.desktop`
    margin: 24px 0px;
    width: 100%
  `};

  ${mediaMax.desktop`
  display: none;
  `};

  ${props =>
    props.noRebates &&
    `
    display: none;
  `} 

`;

export default {
  PricingAssumptionsContainer,
  PricingAssumptions,
  UnorderedListTruncated,
  EstimateDivider
}