export const daysAgo = (date) => {
    const today = ddMMYYY(new Date())
    const days = Math.abs(today - date) / (60*60*24*1000)

    if(days === 0)
        return 'today'
    else if (days === 1)
        return 'yesterday'
    else
        return days.toString() + ' days ago'
}

export const modifyDate = (date, endBy) => {
    const utcDate = toUTC(date)
    const dateToString = toLocaleString(utcDate) + ' at ' + toLocalTime(utcDate)
    
    if(endBy) {
        const endByDate = toUTC(endBy)
        return dateToString.replace(/AM|PM/g,'') + '-' + toLocalTime(endByDate, true)
    }
    else
        return dateToString
} 

export const renderBidStatus = (activity) => {
    const status = activity.Status.Name || null
    
    if (status === 'Completed') {
        let utcDate = ddMMYYY(toUTC(activity.ModifiedOn))
        return `Bid submitted ${daysAgo(utcDate)}`
    } else if (status !== 'Completed' && activity.StartBy !== null && activity.AcceptedBy !== null) {
        let utcDate = ddMMYYY(toUTC(activity.ModifiedOn))
        return `Updated ${daysAgo(utcDate)}`
    } else {
        let utcDate = ddMMYYY(toUTC(activity.AcceptedBy.AcceptedOn))
        return `Accepted ${daysAgo(utcDate)}`
    }
}

export const toCurrency = (money) => {
    return `$${(money).toFixed(2)}`
}

export const ddMMYYY = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate())
}

export const toUTC = (date) => {
    return new Date(`${date}Z`)
}

export const toLocaleString = (utcDate) => {
    const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};

    return utcDate.toLocaleDateString('en-US', dateOptions)
}

export const toLocalTime = (utcDate, timeZone) => {
    const timeOptions = { hour: 'numeric', minute: 'numeric' }
    if(timeZone) timeOptions.timeZoneName = 'short'

    return utcDate.toLocaleTimeString('en-US', timeOptions).replace(' AM', 'am').replace(' PM', 'pm')
}