import React from 'react';
import styledComponents from './styles';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';

const {
  H2,
  P
} = commonStyles;

const {
  IconBox,
  Recommendation
} = styledComponents;

export default function RecContainer({type, header, message}) {
  return (
    <Recommendation color={type}>
      <div className="recHeader">
        {/* Will change color of icon and header based on messages received from BE */}
        {type === 'SufficientCapacityWithOpenSlots' &&
          <IconBox>
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" title="check-circle">
              <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"
                fill="#7BC342"
              />
            </svg>
          </IconBox>
        }  
        {type === 'SufficientCapacityWithoutOpenSlots' &&
          <IconBox>
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" title="exclamation-circle">
              <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"
                fill="#FFB300"
              />
              </svg>
          </IconBox>
        }
        {type === 'InsufficientCapacity' &&
          <IconBox>
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" title="exclamation-triangle">
              <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"
                fill="#F4511E"
              />
              </svg>
          </IconBox>
        }
        {type === 'IndeterminatedCapacity' &&
          <IconBox>
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" title="times-circle">
              <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"
                fill="#FFFFFFBF" 
              />
              </svg>
          </IconBox>
        }
        <H2 className="h2">{header ? header : "Undetermined capacity"}</H2>
      </div>
      <div className="recContent">
        <P className="p">{message}</P>
      </div>
    </Recommendation>
  )
}