import styled, { css, createGlobalStyle } from 'styled-components';
import {
  mediaMax,
  mediaMin
} from "../../shared/style"

const MainQuoteWrapper = styled.div`
/* common */
  @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;700;900&family=Red+Hat+Display:wght@400;500;700&display=swap');

  & p, a, li, span, td, th, button {
    font-family: "Mulish", sans-serif;
  }

  & h1, h2, h3 {
    font-family: "Red Hat Display", sans-serif;
  }

  height: max-content;
  background-color: white;
  padding: 88px 0px 0px;
  width: 100%;

  ${mediaMax.desktop`
   padding: 52px 0px 179px;
`};
`;

const InfoWrapper = styled.div`
  /* margin-top: 280px */
  display: flex;
  gap: 0px 48px;
  padding: 48px 100px 0px;
  min-height: calc(100vh - 486px);

  display:flex;
  justify-content: center;

  background-color: white;

  /* position: relative; */

  ${mediaMax.desktop`
    flex-direction: column;

    /* padding: 24px 16px 0px 16px; */
    padding: 16px 16px 0px 16px;
  `};

  ${mediaMax.tablet`
    gap: 0px 16px;
  `};
`;

const LeftColumn = styled.div`
  width: 1003px;

/*   ${mediaMax.tablet`
    width: 450px;
  `}; */

  ${mediaMax.desktop`
    width: 100%;
  `};

  ${mediaMin.desktop`
    min-width: 482px;
  `};

`;


const EstimateDivider = styled.div`
  display: block;
  height: 1px;
  background: #DEDFE2;
  margin: 48px 0px;

  ${mediaMax.desktop`
    margin: 24px 0px;
    width: 100%
  `};

  ${props => props.noRebates &&
    ` display: none;`
  } 

`;

const ProductRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0px;
  gap: 8px;
  width: 100%;
  box-shadow: inset 0px -1px 0px #EDEEEF;

  &:last-child{
    box-shadow: none;
  }

  ${mediaMax.phone`
    padding: 0px;
  `};
  
`;


const ProductDescription = styled.div`

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px;
  gap: 8px;

  width: 100%;

`;


const ProductDetails = styled.div`
  width: 100%;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

`;


const Text2 = styled.p`

  overflow-wrap: anywhere;  
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin: 0px;

  ${props =>
    props.number &&
    css`
      overflow-wrap: unset;
    `};

`;

const UnorderedList = styled.ul`

  padding-left: 20px;
  margin: 0px;

`;



const MobileSection = styled.div`

  display: flex;
  flex-direction: column;
  padding: 0px;

  ${mediaMin.desktop`
    display: none;
  `};

`;

const ModalBackground = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.0);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  transform: scale(1);
  /* animation: fadeIn .3s ease-in forwards; */
  animation: ${({ animate }) => {
    switch (animate) {
      case 'in':
        return 'fadeIn .3s ease-in forwards';
      case 'out':
        return 'fadeOut .3s ease-in forwards';
      default:
        return 'fadeIn .3s ease-in forwards';
    }
  }};
  @keyframes fadeIn {
    0% { background: rgba(0, 0, 0, 0.0); }
    100% { background: rgba(0, 0, 0, 0.5); }
  }

  @keyframes fadeOut {
    0% { background: rgba(0, 0, 0, 0.5); }
    100% { background: rgba(0, 0, 0, 0.0); }
  }
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  gap: 24px;
  width: 800px;
  background: #FFFFFF;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 8px;

  opacity:0;
  /* animation: animateUp .3s ease-in forwards; */
  animation: ${({ animate }) => {
    switch (animate) {
      case 'in':
        return 'animateUp .3s ease-in forwards';
      case 'out':
        return 'animateDown .3s ease-in forwards';
      default:
        return 'animateUp .3s ease-in forwards';
    }
  }};
  @keyframes animateUp {
    0% {
      transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  
  @keyframes animateDown {
    0% {
      transform: translateY(0px);
      opacity: 1;
    }
    100% {
      transform: translateY(1000px);
      opacity: 0;
    }  
  }
`;

const ModalTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 24px;

  width: 100%;
  height: 32px;

  justify-content: space-between;
`;

const ModalCloseButton = styled.button`
  border: none;
  padding: 0px 5px;
  background: none;
  cursor: pointer;
`;

const ModalCloseIcon = styled.i`
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #B9BBC1;
`;

const ModalList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  
  width: 100%;
`;

const ModalListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 16px;
  width: 100%;

  justify-content: space-between;
`;

const ModalListItemIconContainer = styled.div`
  width: 40px;
  height: 40px;
  margin: 14px 16px 14px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalCheckIcon = styled.i`
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #7BC342;
`;

const ModalListItemContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  padding: 24px 0px;
  box-shadow: inset 0px -1px 0px #EDEEEF;
`;


const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 477px;

/*   ${mediaMax.tablet`
    width: 450px;
  `}; */

  ${mediaMax.desktop`
    /* width: 100%; */
    display: none;
  `};

  ${mediaMin.desktop`
    min-width: 322px;
  `};
`;

const Divider = styled.div`
  display: none;

  ${mediaMax.phone`
    display: block;
    height: 1px
    background: #DEDFE2;
  `};
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: flex-start;
  /* padding: 32px 0px; */
  gap: 24px;

  height: 68px;
  /* width: 1124px; */

  width: 1528px;
  padding: 24px 0px;

  ${mediaMax.desktop`
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: center; */
    /* align-items: center; */
    /* padding: 24px 0px; */

    /* height: 156px; */

    /* ig/ig-05 */
    /* background: #FAFAFA; */

    display: none;
  `};
`;

const FooterMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 100%;

  ${mediaMin.desktop`
    display: none;
  `};
`;

const FooterDivider = styled.div`
  display: block;
  height: 1px;
  background: #EDEEEF;
  margin-top: 100px;

  ${mediaMax.desktop`
    margin-top: 48px;
    height: 0px
  `};

`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 1728px) {
    padding: 0px 24px;
  }

  @media only screen and (max-width: 1166px) {
    padding: 0px 24px;
  }

  ${mediaMax.desktop`
    padding: 24px 24px 34px
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    
    background: rgba(255, 255, 255, 0.75);
    border-top: 1px solid #DEDFE2;
    backdrop-filter: blur(12px);
  `};
`;



export default {
  MainQuoteWrapper,
  InfoWrapper,
  LeftColumn,
  EstimateDivider,
  ProductRow,
  ProductDescription,
  ProductDetails,
  Text2,
  UnorderedList,
  MobileSection,
  ModalBackground,
  Modal,
  ModalTitle,
  ModalCloseButton,
  ModalCloseIcon,
  ModalList,
  ModalListItem,
  ModalListItemIconContainer,
  ModalCheckIcon,
  ModalListItemContentContainer,
  RightColumn,
  Divider,
  Footer,
  FooterMobile,
  FooterDivider,
  FooterContainer
}