import styled from 'styled-components';

const ButtonStyle = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 276px;
  height: 40px;
  background: ${({styled}) => styled === 'red' ? '#F4511E' : '#7BC342'};
  border-radius: 4px;
  border: none;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;

  &:hover {
    background: ${({styled}) => styled === 'red' ? '#BF360C' : '#7BC342'};
    box-shadow: ${({styled}) => styled === 'red' ? 'none' : '0px 4px 4px rgba(0, 0, 0, 0.25)'};
    cursor: pointer;
  }

  &:disabled {
    background: ${({styled}) => styled === 'red' ? '#FBE9E7' : '#F1F8E9'};
    color: ${({styled}) => styled === 'red' ? '#FF8A65' : '#AED581'};

    :hover {
      box-shadow: none;
      cursor: auto;
    }
  }
`;

const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;

  i {
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #AED581;
  }
`;

export default {
  ButtonStyle,
  IconBox
};