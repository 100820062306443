import {
    theme,
    fade,
    mediumText,
    mediaMax,
    mediaMin
  } from "../../../../shared/style";
  import styled, { css } from "styled-components";
  
  const Root = styled.div`
    padding: 0 16px 16px;
    ${mediaMin.tablet`padding: 16px;`};
  `;
  
  const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 0 -8px;
    margin-top: 48px;
    ${mediaMin.tablet`margin-top: 12px`};
    > div {
      padding: 0 8px;
      width: 100%;
      text-align: center;
    }
    ${({ sideDesktop }) =>
      sideDesktop &&
      css`
        ${mediaMin.tablet`margin-left: auto;
      margin-right: 0;
      max-width: 352px;`};
      `};
  `;
  
  const Close = styled.i`
    color: #969ba5;
    font-size: 16px;
    height: 20px;
    text-align: center;
    width: 20px;
    position: absolute;
    top: 18px;
    right: 16px;
    cursor: pointer;
    transition: 0.3s;
    line-height: 20px !important;
    &:hover {
      background: ${fade("#969ba5", 0.1)};
    }
    ${mediaMax.tablet`display:none !important;`};
  `;
  
  const Title = styled.h2`
    margin: 0;
    color: #3e4148;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    ${mediaMin.tablet`display:none !important;`};
  `;
  const FieldTitle = styled.p`
    margin-top: 22px;
    margin-bottom: ${({ mb }) => mb || 4}px;
    color: #3e4148;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.25px;
    line-height: 20px;
    span {
      color: #b9bbc1;
      font-family: Lato;
      font-size: 14px;
      font-style: italic;
      height: 20px;
      letter-spacing: 0.25px;
      line-height: 20px;
    }
  `;
  
  const Textarea = styled.textarea`
    border: solid 1px ${theme.IndiqoGrey[300]};
    background-color: ${theme.IndiqoGrey.main};
    border-radius: 4px;
    padding: 12px;
    min-height: 64px;
    width: 100% !important;
    ${({ req }) =>
      req &&
      css`
        border: 1px solid #00bcd4;
        box-shadow: 0px 0px 4px 0px #4dd0e1;
      `};
  `;
  
  const Limit = styled.p`
    color: rgb(110, 114, 124);
    font-family: Lato;
    font-size: 14px;
    font-style: italic;
    letter-spacing: 0.25px;
    line-height: 20px;
    text-align: right;
    margin: 0;
    padding-bottom: 48px;
  `;
  const OptionWrapper = styled.div`
    margin-top: ${({ mt }) => mt || 22}px;
  `;
  const TitleDesc = styled.p`
    ${mediumText};
    margin: 16px 0 0;
    ${mediaMin.tablet`margin: 0`};
    color: #3e4148;
  `;

  const DeclinedBid = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 60px;
    text-align: center;

    img {
        height: 72px;
        object-fit: contain;
        margin: 8px;
    }
  `;
  
  export default {
    ButtonWrapper,
    Close,
    Title,
    FieldTitle,
    Root,
    TitleDesc,
    OptionWrapper,
    Textarea,
    Limit,
    DeclinedBid
  };
  