import React, { useEffect, useState } from 'react'
import styledComponents from "./styles";
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';

import Concepts from '../../components/Concepts';
import DetailsComponent from '../../components/DetailsComponent';
import CancelBox from '../../components/CancelBox';
import QuoteApprovedButton from '../../components/QuoteApprovedButton';
import Checkbox from '../../components/Checkbox';

const {
  H1,
  Label_Field,
} = commonStyles;

const {
  Sticky,
  TotalContainer,
  TotalDivider,
  CancelSection,
} = styledComponents;

export default function TotalSection({fetchedBidData, browserLanguage, requestId, bidData, acceptBidByGuid, translations, currentIdx, acceptFinance, isChecked, toggleFinanceApproveButton }) {
  return (
    <Sticky>
      <DetailsComponent 
        fetchedBidData={fetchedBidData}
        browserLanguage={browserLanguage}
        bidData={bidData}
        translations={translations}/>
      <TotalContainer>
        <H1>{translations.totalSection.total.title}</H1>
        {/*  Concept Common Component */}
        <Concepts fetchedBidData={fetchedBidData} translations={translations}/>
        <TotalDivider />
        {currentIdx == 0 &&
          <Checkbox isChecked={isChecked} onClick={toggleFinanceApproveButton} />
        }
        <QuoteApprovedButton acceptFinance={acceptFinance} acceptBidByGuid={acceptBidByGuid} currentIdx={currentIdx}  isChecked={isChecked} />
      </TotalContainer>
      <CancelSection>
        <Label_Field>{translations.totalSection.total.cancel.message}</Label_Field>
        <CancelBox requestId={requestId} translations={translations}/>
      </CancelSection>
    </Sticky>
  )
}
