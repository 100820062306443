export function quotePreviewSection(preview) {
  switch (preview.FileType || preview.ContentType || preview.contentType) {
    case "image":
    case "image/jpeg":
      return "quoteImage";
    case "text/plain":
    case "text/rtf":
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "microDoc";
    case "application/vnd.ms-excel":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "microExcel";
    case "application/vnd.ms-powerpoint":
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return "microPower";
    case "application/pdf":
      return "microPdf";
    default:
      return null;
  }
}
// export function quotePreviewSectionStatic(preview) {
//   switch (preview.ContentType || preview.contentType || preview.FileType) {
//     case "image":
//     case "image/jpeg":
//       return "quoteImage";
//     case "application/msword":
//     case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
//       return "microDoc";
//     case "application/vnd.ms-excel":
//     case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
//       return "microExcel";
//     case "application/vnd.ms-powerpoint":
//     case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
//       return "microPower";
//     case "text/plain":
//     case "text/rtf":
//     case "application/pdf":
//       return "microPdf";
//     default:
//       return null;
//   }
// }
