import React, { Fragment } from 'react';
import styledComponents from './styles';
import InputsRow from '../InputsRow';

const {
  FormRow
} = styledComponents;

export default function RowsMapper({ data, deleteRow, handleChange }) {
  if (data !== undefined && data.length !== 0) {
    return (
      <Fragment>
        {data.map((item, index) =>
          <FormRow key={item.id} className="FormRow" role="row">
            <InputsRow
              key={item.id}
              category={item.category}
              description={item.description}
              name={item.name}
              value1={item.value1}
              uom1={item.uom1}
              value2={item.value2}
              uom2={item.uom2}
              total={item.total}
              deleteRow={() => deleteRow(index)}
              handleChange={e => handleChange(e, index)}
              newRow={item.newRow}
            />
          </FormRow>
        )}
      </Fragment>
    )
  }

  return (
    <Fragment>
      {null}
    </Fragment>
  )
}