import React from 'react'
import StyledComponents from './styles';
import formatter from '../../../../../lib/formatter';
import _ from "lodash";

const {
	Card,
	TitleBox,
	IconBox,
	TitleIcon,
	TitleText,
	Subtitle,
	Details,
	DetailsText,
} = StyledComponents;

export default function AppointmentScheduledCard({ acceptedInstallationDate, appointmentDateStart, appointmentDateEnd, translations, show }) {

	if(!show) return null;
	return (
		<React.Fragment>
			<Card>
				<React.Fragment>
					<TitleBox>
						<IconBox>
							<TitleIcon className="far fa-calendar-day" />
						</IconBox>
						<TitleText>
							{translations.title}
						</TitleText>
					</TitleBox>
					<Subtitle>
						{acceptedInstallationDate.formattedDate} {translations.dateTimeSeparator} {acceptedInstallationDate.formattedTime}
					</Subtitle>
					<Details>
						<DetailsText>{translations.descriptionOne} <span style={{ fontWeight: 700 }}>{appointmentDateStart.formattedDate}</span> {appointmentDateStart.formattedTime == appointmentDateEnd.formattedTime ? translations.descriptionNotWindow : translations.descriptionWindow} {appointmentDateStart.formattedTime == appointmentDateEnd.formattedTime ? <span style={{ fontWeight: 700 }}>{appointmentDateStart.formattedTime} {appointmentDateEnd.timeZone}</span> : <span style={{ fontWeight: 700 }}>{appointmentDateStart.formattedTime} - {appointmentDateEnd.formattedTime} {appointmentDateEnd.timeZone}</span>}.</DetailsText>
					</Details>
				</React.Fragment>
			</Card>
		</React.Fragment>
	)
}
