import styled, {css} from 'styled-components';

const ConceptsContainer = styled.div`
  width: 100%;
`;

const ConceptRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 8px 0px;
  gap: 16px;
  width: 100%;
  height: 36px;
`;

const TotalRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0px;
  gap: 16px;
  width: 100%;
  height: 24px;
`;

const TotalDivider = styled.div`
  display: block;
  height: 1px;
  background: #DEDFE2;
  margin: 0px;
  width: 100%;
`;

const CreditsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 100%;
  max-height: 136px;

  p {
    text-align: center;
  }

  h2 {
    text-align: center;
  }

  ${props =>
    props.custom &&
    css`
      height: unset;
    `};
`;

const CreditsLogo = styled.div`
  width: 160px;
  height: 100px;
  padding: 8px;
  display: flex;
  justify-content: center;
`;

const LogoImg = styled.img`
  object-fit: contain;
   max-width: 100%;
   max-height: 100%;
   width: auto;
   height: auto;
`;

export default {
  ConceptsContainer,
  ConceptRow,
  TotalRow,
  TotalDivider,
  CreditsContainer,
  CreditsLogo,
  LogoImg
}