import styled from 'styled-components';
import { mediaMax } from '../../../../shared/style';

const GeneralAssumptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${mediaMax.tablet`
    gap: 8px;
  `}
`;

const SectionHeaderSC = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin: 0px;

  ${mediaMax.tablet`
    font-size: 18px;
    line-height: 24px;
  `}
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;

  ${mediaMax.tablet`
    gap: 4px;
  `}

  .header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
    box-shadow: 0px -1px 0px 0px #EDEEEF inset;

    ${mediaMax.tablet`
      display: none;
    `}

    .generalItemsColumn {
      display: flex;
      padding: 8px;
      align-items: flex-start;
      gap: 4px;
      flex: 2 1 0;
    }

    .multiplier {
      display: flex;
      flex-direction: row;
      padding: 8px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      flex: 1 1 0;
    }

    .watts {
      display: flex;
      padding: 8px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      flex: 1 1 0;
    }

    .totalWattsColumn {
      display: flex;
      padding: 8px;
      justify-content: flex-end;
      align-items: center;
      gap: 4px;
      flex: 1 1 0;
    }

    .iconColumn {
      display: flex;
      width: 20px;
      padding: 8px;
      justify-content: flex-end;
      align-items: center;
      gap: 4px;
      color: #FFF;
    }
  }
`;

export default {
  GeneralAssumptionsContainer,
  SectionHeaderSC,
  Form
}