import styled, { css, createGlobalStyle } from 'styled-components';
import {
  mediaMax,
} from "../../shared/style"

const GlobalTrackerStyles = createGlobalStyle`
  #root {
    padding: 0px !important;
  }

  ${props =>
    props.canceled &&
    css`
      & a {
        color: #3E4148 !important;
        pointer-events: none;
        cursor: default;
      }
  `};
`;

const MainWrapper = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;700;900&family=Red+Hat+Display:wght@400;500;700&display=swap');

  & p, a, li, span {
    font-family: "Mulish", sans-serif;
  }

  & h1, h2, h3 {
    font-family: "Red Hat Display", sans-serif;
  }

  height: max-content;
  background-color: white;
  padding: 88px 0px 0px;
  width: 100%;

  ${mediaMax.phone`
   padding-top: 52px;
`};
`;

const LogoWrapper = styled.div`

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 8px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;

  height: 88px;
  width: 100%;

  /* darq-blue/darq-blue-40 */
  background: #0B1C54;

  ${mediaMax.phone`
    height: 52px;
    justify-content: flex-end;
    padding: 16px;
  `};
`;

const InfoWrapper = styled.div`
  /* margin-top: 280px */
  display: flex;
  gap: 0px 48px;
  padding: 48px 32px 0px 32px;
  min-height: calc(100vh - 486px);

  display:flex;
  justify-content: center;

  background-color: white;

  ${mediaMax.phone`
    flex-direction: column;

    padding: 24px 16px 0px 16px;
  `};

  ${mediaMax.tablet`
    gap: 0px 16px;
  `};

  @media (max-width: 820px) {
    gap: 0px 16px;
  }
`;

const StepsWrapper = styled.div`
  width: 776px;

/*   ${mediaMax.tablet`
    width: 450px;
  `}; */

  ${mediaMax.phone`
    width: 100%;
  `};
`;

const Divider = styled.div`
  display: none;

  ${mediaMax.phone`
    display: block;
    height: 1px
    background: #DEDFE2;
    margin-top: 24px;
  `};
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 32px 0px;
  gap: 24px;

  height: 68px;
  width: 1124px;

  ${mediaMax.phone`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 0px;

    height: 156px;

    /* ig/ig-05 */
    background: #FAFAFA;
  `};
`;

const FooterDivider = styled.div`
  display: block;
  height: 1px;
  background: #EDEEEF;
  margin-top: 48px;
`;

const QmeritLogo = styled.img`
  width: 135.14px;
  height: 40px;

  ${mediaMax.phone`
    width: 67.57px;
    height: 20px;
  `};
`;

const Assistance = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  display: flex;
  align-items: center;
  margin: 0px;

  /* text/default */
  color: #3E4148;
`;

const QmeritContactInfo = styled.a`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  display: flex;
  align-items: center;
  margin: 0px;

  /* ig/ig-40 */
  color: #3963FE;
`;

const ContactUsBtn = styled.a`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: #B9BBC1;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 1190px) {
    padding: 0px 32px;
  }

  ${mediaMax.phone`
    padding: 0px;
  `};
`;

export default {
  MainWrapper,
  LogoWrapper,
  InfoWrapper,
  StepsWrapper,
  Divider,
  Footer,
  FooterDivider,
  QmeritLogo,
  Assistance,
  QmeritContactInfo,
  ContactUsBtn,
  FooterContainer,
  GlobalTrackerStyles
}
