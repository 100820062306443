import styled from "styled-components";

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Title = styled.h4`
    font-size: 16px;
    font-weight: bold;
    height: 20px;
    line-height: 20px;
    margin: 0;
`;

const DropdownContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 16px 0 24px;
`

export default {
    CardContainer,
    Title,
    DropdownContainer,
};
