import _ from 'lodash'

import supportedFileTypes, { extensionToType } from './supportedFileTypes'

export default {
    getFileType: function(params) {
        let { file, contentType } = params;

        let fileDocument = [],
            acceptedContentType = false,
            fileContentType = contentType || null,
            fileType = {
                contentType: null,
                type: null,
                classname: null
            },
            acceptedFileFormat = false,
            that = this;

        if (file && file.name) {
            const fileExt = file.name.substring(file.name.lastIndexOf('.') + 1);
            fileContentType = file.type || extensionToType[fileExt];
        }

        if (_.findIndex((fileContentType, n) => n === '/')) {
            try {
                fileDocument = _.split(fileContentType, '/');
                if (fileDocument.length > 1) {
                    let contentVar = fileDocument[0] === 'image' ? fileDocument[0] : fileContentType;
                    fileType = {
                        contentType: contentVar,
                        type: fileDocument[1],
                        fileType: fileContentType
                    }
                }
            } catch (e) {
            }
        }

        acceptedContentType = _.has(supportedFileTypes, fileType.contentType)

        if (acceptedContentType) {
            let isSupported = _.each(supportedFileTypes, function(value, key) {
                let found = _.find(value, function(element, index) {

                    return (element === fileType.type)
                })

                if (found) {
                    acceptedFileFormat = true;
                }

                fileType.classname = that.getClassName(fileType.type)
            })
        }

        if (!acceptedContentType || !acceptedFileFormat) {
            fileType = {
                ...fileType,
                classname: that.getClassName(fileType.type),
                type: null
            }
        }

        return fileType
    },
    getClassName: function(type) {
        let classname = 'document',
            presentation = ['ms-powerpoint', 'presentationml', 'keynote'],
            spreadsheet = ['ms-excel', 'spreadsheetml', 'csv'];


        if (_.isString(type)) {
            if (_.some(presentation, (el) => _.includes(type, el))) {
                classname = 'presentation'
            } else if (_.some(spreadsheet, (el) => _.includes(type, el))) {
                classname = 'spreadsheet'
            }
        }

        return classname
    }
}
