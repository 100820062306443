import React, { Component } from "react";
import PropTypes from 'prop-types';
import _ from "lodash";
import { getToken, fetchPendingBids } from "../../lib/getUserData";
import { setDatesInArray } from "../ActivityStatus/Methods/setDates";
import Wrapper from "../../components/Wrapper";
import Loader from "../../components/Loader";
import FetchFailure from "../../components/FetchFailure";
import BidsList from "../../components/BidsList";
import PendingBidView from './views';
import AllDone from '../../components/AllDone';
import mock from "../../lib/mockPendingBids";

class PendingBid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      fetchedData: null,
      fetchError: false,
      isMockData: false,
      allDone: false,
      bidStatuses: [],
    };
  }

  getChildContext() {
    let context = {
        isMockData: this.state.isMockData,
    };
    return context;
  }

  handleForceCollapse = (declineIndex) => {
    this.setState({ declineIndex })
  }
  
  // Put in BidList
  handleStatusChange = (id, header) => {
    const { bidStatuses } = this.state;
    bidStatuses[id] = {
      subTitle: `Request ${header.subTitle}${header.subTitle==='submitted'?'!':''}`,
      status: header.status
    };
    this.setState({ bidStatuses })
 
    if(!_.find(bidStatuses, { status: null })) {
        return this.setState({ finished: true })
    }
  }

  // Put in BidList
  setBidStatus = (activities) => {
    let bidStatuses = activities.map((activity) => ({
      subTitle: `${activity.Location.City}, ${activity.Location.State} ${activity.Location.Zip}`,
      status: null
    }))
    this.setState({ bidStatuses })
  }

  handleView = (activity, index) => {
    return (
      <PendingBidView 
        activity={activity} 
        onStatusChange={this.handleStatusChange}
        index={index}
        setForceCollapse={this.handleForceCollapse}
      />
    )
  }

  componentDidMount() {
    if (!this.state.fetchedData) {
      getToken(this.props.location.search).then(token => {
        if (token === "token=mockPendingBids") {
          let fetchedData = mock;
          fetchedData.Activities = setDatesInArray(mock);
          this.setState({
            isMockData: true,
            fetchedData,
            isLoading: false
          });
          this.setBidStatus(fetchedData.Activities)
        } else {
          fetchPendingBids(this.props.location.search).then(userData => {
            if (userData) {
              let fetchedData = userData.fetchedData;
              fetchedData.Activities = setDatesInArray(fetchedData);
              this.setState({
                fetchedData,
                isLoading: false,
                token
              });
              this.setBidStatus(fetchedData.Activities)
            }
          });
        }
      });
    }
    document.body.classList.add("bg-grey");
  }

  render() {
    const { fetchedData, allDone, bidStatuses, finished, declineIndex } = this.state;
    if (this.state.fetchError) {
      return (
        <Wrapper name="" company="" maps>
          <FetchFailure />
        </Wrapper>
      );
    }

    if(fetchedData && _.isEmpty(fetchedData.Activities)) {
      console.log("TRIUE")
      return(
        <Wrapper
          name={fetchedData && fetchedData.User ? fetchedData.User.Name : ""}
          company={
            fetchedData && fetchedData.User ? fetchedData.User.CompanyName : ""
          }
          maps
        >
          <AllDone />
        </Wrapper>
      )
    }

    if (fetchedData) {
      return (
        <Wrapper
          name={fetchedData && fetchedData.User ? fetchedData.User.Name : ""}
          company={
            fetchedData && fetchedData.User ? fetchedData.User.CompanyName : ""
          }
          maps
        >
          <BidsList
            header="Your available requests" 
            activities={fetchedData.Activities}
            finished={finished}
            setView={this.handleView}
            itemHeaders={bidStatuses}
            forceCollapseIndex={declineIndex}
          >
          </BidsList>
        </Wrapper>
      );
    }

    return (
      <Wrapper name="" company="" maps>
        <Loader isLoading={true} />
      </Wrapper>
    );
  }
}

PendingBid.childContextTypes = {
  isMockData: PropTypes.boolean,
};

export default PendingBid;
