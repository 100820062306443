import styled from "styled-components";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #3e4148;
  overflow-y: scroll;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const Title = styled.div`
  h4 {
    font-size: 18px;
    font-weight: bold;
    height: 24px;
    line-height: 24px;
    margin: 0;
  }
  p {
    margin-top: 8px;
  }
  margin-bottom: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 56px 0px;
  svg {
    ${props =>
      props.absoluteLogo &&
      `position: absolute;
            bottom: 24px;`}
    width: 131px;
  }
`;

const Company = styled.div`
  a {
    color: #00bcd4;
  }
`;

const CancelRequest = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px;
  background: #fafafa;
  border-radius: 0 0 8px 8px;

  p {
    max-width: 252px;
    color: rgba(62, 65, 72, 0.7);
    font-size: 14px;
  }
`;

const ConfirmContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 24px;
`;

export default {
  Root,
  Wrapper,
  CardContainer,
  Title,
  Company,
  CancelRequest,
  ConfirmContainer
};
