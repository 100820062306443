
import React from "react"
import styledComponents from "./style";
import tierUpdateSuccess from '../../../../../public/images/tierUpdateSuccess.svg';

const  {Container, ThanksForUpdate ,ThanksForUpdateText} = styledComponents
 export const TierUpdateSuccess = (props) => {
    return (
        <Container>
            <img alt="Oops" src={tierUpdateSuccess} />
            <ThanksForUpdate>Thanks for updating!</ThanksForUpdate>
            <ThanksForUpdateText>Feel free to close this window. We'll let the customer</ThanksForUpdateText>
            <ThanksForUpdateText>know that their pricing has been updated.</ThanksForUpdateText>
        </Container>

    )
}