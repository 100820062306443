import styled, { css, keyframes } from "styled-components";
import {
  theme,
  primaryButton,
  mediaMax,
  fade,
  mediaMin
} from "../../shared/style";

const QuoteUploadSection = styled.div`
  margin-left: 0 0 24px 24px;
  cursor: pointer;
`;
const QuoteOption = styled.span`
  margin-left: 0 0 24px 24px;
  cursor: pointer;
`;
const QuoteSubTitle = styled.h4`
  font-size: 14px;
  color: #3e4148;
  font-weight: normal;
  margin: 0;
  margin-bottom: 4px;
`;

const rectUpdate = keyframes`
to { width: 99.99%; }
from { width: 100%; }
`;

const BorderInput = styled.div`
  position: relative;
  &:focus {
    outline: none;
  }
  svg {
    position: absolute;
    width: 100%;
    height: 100%;
    rect {
      fill: transparent;
      stroke: rgb(222, 223, 226);
      stroke-dasharray: 4, 4;
      stroke-width: 2;
      width: 100%;
      height: 100%;
      animation: ${rectUpdate} 0.1s infinite;
    }
  }
`;
const Input = styled.input`
  &:focus {
    outline: none;
  }
`;
const QuoteDrop = styled.div`
  width: 100%;
  background-color: ${({ bgColor }) => bgColor || "#f4f5f6"};
  ${props =>
    props.Q2
      ? `padding-top: 100%;`
      : `
  height: 144px;
  display: flex;
  flex-direction: column;
  justify-content: center;`};
`;
const QuoteCircle = styled.div`
  display: flex;
  align-self: center;
  background-image: linear-gradient(to bottom, #00bcd4, #00acc1);
  color: #fff;
  justify-content: center;
  border-radius: 100%;
  ${props =>
    props.Q2
      ? `width: 32px;
    height: 32px;
    margin-top: 14px;
    margin-bottom: 10px;`
      : `width: 44px;
    height: 44px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 11px;
  `};
`;
const QuotePlus = styled.div`
  font-size: 27px;
  margin-top: 4px;
  ${props =>
    props.Q2 &&
    `font-size: 22px;
  margin-top: 2px;`};
`;
const UploadLabel = styled.div`
  ${props =>
    props.Q2 || props.noLable
      ? ` display: flex;
      align-self: center;
      font-weight: bold;
      font-size: 12px;
      color: #00bcd4;
      margin-bottom: 0px;`
      : `text-align: center;
      font-weight: bold;
      font-size: 16px;
      color: #3e4148;
      margin-bottom: 5px;
      line-height: 1.25;
`};
`;
const QuoteInstructions = styled.div`
  text-align: center;
  font-size: 12px;
  color: #6e727c;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: 0.3px;
`;
const PreviewCol = styled.div`
  ${mediaMax.phone`
    ${props =>
      props.singleRow &&
      `
    flex: 0 0 33.33%;
    max-width: 33.33%;
    `}
  `} ${mediaMin.phone`
    ${props =>
      props.singleRow &&
      `
    flex: 0 0 25%;
    max-width: 25%;
    `}
  `}
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 16px;
`;
const QuotePreview = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
`;

const Zeek = styled.div`
  cursor: pointer;
  z-index: 99;
  position: absolute;
  text-align: right;
  bottom: 4px;
  right: 4px;
  width: 24px;
  height: 24px;
  -webkit-backdrop-filter: blur(1px);
  background-color: rgba(31, 32, 35, 0.5);
  text-align: center;
  border-radius: 50%;
  i {
    color: #fff;
    margin-top: 5px;
    font-size: 14px;
    font-weight: 300 !important;
  }
`;

const QuoteRemove = styled.p`
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${theme.CoolGrey};
  text-transform: uppercase;
  cursor: pointer;
`;

const QuoteDropBody = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PreviewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;
  margin-top: -16px;
`;

const Caption = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: 0.3px;
  text-align: center;
  margin-bottom: 9px;
  ${props =>
    props.warning
      ? `color: ${theme.Warning[700]}`
      : `color: ${theme.IndiqoGrey[600]};`};
`;

export default {
  QuoteUploadSection,
  QuoteOption,
  QuoteSubTitle,
  BorderInput,
  Input,
  QuoteDrop,
  QuoteCircle,
  QuotePlus,
  UploadLabel,
  QuoteInstructions,
  PreviewCol,
  QuotePreview,
  Zeek,
  QuoteRemove,
  QuoteDropBody,
  PreviewWrapper,
  Caption
};
