import React from 'react'
import StyledComponents from './styles';
import formatter from '../../../../../lib/formatter';

const {
	Card,
	CompletedStepTitleBox,
	CompletedStepIconBox,
	CompletedStepTitleIcon,
	CompletedStepTitleText,
	CompletedStepSubtitle
} = StyledComponents;

export default function RequestSubmittedCard({ requestData, browserLanguage, translations, show }) {

	var timeZoneT = requestData.TimeZone.timeZoneId;

	const submittedOnDate = formatter.getIntlFormattedDateTime(requestData.Survey.SubmittedOn, browserLanguage, timeZoneT);

	if(!show) return null;
	return (
		<React.Fragment>
			<Card>
				<React.Fragment>
					<CompletedStepTitleBox>
						<CompletedStepIconBox>
							<CompletedStepTitleIcon className="far fa-file" />
						</CompletedStepIconBox>
						<CompletedStepTitleText>
							{translations.title}
						</CompletedStepTitleText>
					</CompletedStepTitleBox>
					<CompletedStepSubtitle>
						{submittedOnDate.formattedDate} {translations.dateTimeSeparator} {submittedOnDate.formattedTime}
					</CompletedStepSubtitle>
				</React.Fragment>
			</Card>
		</React.Fragment>
	)
}
