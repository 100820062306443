import React from "react";

export default props => {
  return (
    <img
      src="https://storeqmtprd.blob.core.windows.net:443/qmerit/Powered-by-Qmerit-IG400.png"
      alt="qmerit-logo"
      style={{ maxHeight: 24, width: "unspecified", objectFit: "contain" }}
    />
  );
};
