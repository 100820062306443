import React from 'react';

export default function VimeoIframe() {
  return (
    <iframe 
      src="https://player.vimeo.com/video/842584189?h=56175ace57&amp;app_id=122963" 
      width="426" 
      height="240" 
      frameborder="0" 
      allow="autoplay; fullscreen; picture-in-picture" 
    />
  )
}