import styled, { css } from "styled-components";
import { mediaMin, mediaMax, fade, theme } from "../../../shared/style";
import { Label, Slider, Text } from "../../Switch/style";
const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
`;
const BackDrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(6px);
  background-color: ${fade(theme.Black, 0.5)};
`;

const Body = styled.div`
  position: absolute;
  border-radius: 6px;
  box-shadow: ${`0 2px 4px 0 ${fade(theme.Black, 0.5)}, 0 3px 4px 0 ${fade(
    theme.Black,
    0.23
  )}`};
  background-color: #ffffff;
  width: 100%;
  max-width: 480px;
  left: 50%;
  padding: 16px;
  transition: 0.3s;
  ${mediaMin.phone`
  
    max-height: 632px;
    top: 50%;transform: translate(-50%, -50%);
  `} ${mediaMax.phone`
    max-height: 100%;
    overflow:auto;
    border-radius: 16px 16px 0 0;
    bottom: -100vh;
    left: 0;
    max-width: 100%;
  `};
  &.active {
    ${mediaMax.phone`bottom: 0;`};
  }
`;
const TitleWraper = styled.div`
  margin-bottom: 24px;
`;
const Title = styled.h2`
  margin: 0;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${theme.IndiqoGrey[800]};
`;
const SwitchWraper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  ${Label} {
    width: 48px;
    height: 24px;
  }
  ${Slider} {
    &::before {
      width: 16px;
      height: 16px;
      left: ${props => (props.isChecked ? `calc(100% - 20px)` : `4px`)};
    }
  }
  ${Text} {
    padding-top: 0;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.25;
    color: ${props =>
      props.isChecked
        ? theme.IndiqoGrey[800]
        : fade(theme.IndiqoGrey[800], 0.35)};
  }
`;
const DateInputWrapper = styled.div`
  display: flex;

  align-items: center;
  .year,
  .month,
  .day {
    text-align: center;
  }
  .year {
    width: 72px;
  }
  .month,
  .day {
    width: 55px;
  }
  .month {
  }
  .day {
  }
`;
const Hyphen = styled.span`
  margin: 0 2px;
  height: 1px;
  width: 8px;
  background: ${theme.IndiqoGrey[300]};
`;

const CalendarBtn = styled.button`
  cursor: pointer;
  margin-left: 10px;
  width: 44px;
  height: 44px;
  padding: 0;
  border-radius: 100%;
  font-size: 15px;
  color: ${theme.Primary.main};
  border: 1px solid #ececec;
  background: linear-gradient(to bottom, #fefefe, #fafafa);
`;

const AcceptText = styled.p`
  margin: 0 auto 16px;
  max-width: 343px;

  font-size: 14px;
  line-height: 1.43;
  text-align: center;
  margin: 48px auto 16px;

  color: ${fade(theme.IndiqoGrey[800], 0.7)};
`;

const greenBtn = css`
  color: ${theme.White};
  background-color: ${theme.Success[500]};
  &:hover {
    background-color: "#7cb342";
  }

  border: solid 1px ${theme.Success[600]};
  background-image: linear-gradient(
    to bottom,
    ${theme.Success[500]},
    ${theme.Success[600]}
  );
  &:hover {
    background-image: linear-gradient(
      to bottom,
      ${theme.Success[600]},
      ${theme.Success[500]}
    );
  }
`;

const greyBtn = css`
  color: ${theme.IndiqoGrey[600]};
  background-color: ${theme.IndiqoGrey[100]};
  background-image: linear-gradient(
    to bottom,
    ${theme.IndiqoGrey[100]},
    ${theme.IndiqoGrey[200]}
  );
  &:hover {
    color: ${theme.IndiqoGrey[600]};
    background-image: linear-gradient(
      to bottom,
      ${theme.IndiqoGrey[200]},
      ${theme.IndiqoGrey[100]}
    );
  }

  border: 0;
  background-color: ${theme.IndiqoGrey[100]};
  background-image: none;
  &:hover {
    background-color: ${fade(theme.IndiqoGrey[100], 0.8)};
  }

  &.active {
    ${mediaMax.phone`${greenBtn}`};
  }
`;

const greenBtnDisabled = css`
  color: ${theme.DisabledGreen[200]};
  border: solid 1px ${theme.DisabledGreen[100]};
  background-color: ${theme.DisabledGreen[100]};
  background-image: none;
`;

const Button = styled.button`
  flex-grow: 1;
  cursor: pointer;
  text-transform: uppercase;
  height: 32px;
  min-width: 120px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 1px;
  border: solid 1px ${theme.IndiqoGrey[200]};
  color: ${theme.DisabledGreen[200]};
  background-color: ${theme.DisabledGreen[100]};

  color: ${theme.White};
  border: solid 1px ${theme.Success[600]};
  background-image: linear-gradient(
    to bottom,
    ${theme.Success[500]},
    ${theme.Success[600]}
  );
  &:hover {
    background-image: linear-gradient(
      to bottom,
      ${theme.Success[600]},
      ${theme.Success[500]}
    );
  }

  border-radius: 100px;
  transition: 0.3s all;

  height: 40px;
  box-shadow: 0 1px 8px 0 ${fade(theme.Black, 0.2)},
    0 3px 4px 0 ${fade(theme.Black, 0.12)},
    0 3px 3px 0 ${fade(theme.Black, 0.14)};

  &:hover {
    color: ${fade(theme.DisabledGreen[200], 0.7)};
    background-color: ${fade(theme.DisabledGreen[100], 0.8)};
  }

  &.green {
    ${props => (props.disabledBtn ? greenBtnDisabled : greenBtn)};
  }
  &.grey {
    ${greyBtn};
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  margin: 0 -8px;
  ${Button} {
    margin: 0 8px;
  }
`;
const Close = styled.button`
  width: 20px;
  height: 20px;
  border: 0;
  cursor: pointer;
  background: transparent;
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${theme.IndiqoGrey[500]};
  ${mediaMax.phone`display:none;`};
`;
const Rectangle = styled.div`
  margin: 0 auto 8px;
  width: 40px;
  height: 4px;
  border-radius: 2px;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  background-color: ${fade(theme.Black, 0.08)};

  ${mediaMin.phone`display:none;`};
`;
const SelectWrapper = styled.div`
  position: relative;
  background-color: ${theme.IndiqoGrey.main};
  select {
    position: relative;
    z-index: 2;
    background-color: transparent;
  }
  i {
    text-align: center;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    color: ${theme.CoolGrey};
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: normal;
    width: 20px;
    height: 20px;
  }
`;

const DropDown = styled.div`
  position: relative;
  color: #6e727c;
  height: fit-content;
  select {
    height: 44px;
    min-width: 35px;
    border: none;
    font-family: Lato;
    font-size: 16px;
    font-weight: normal;
    background: transparent;
    -webkit-font-smoothing: antialiased;
    color: #6e727c;
    -webkit-appearance: none;
    margin-left: 10px;
  }
`;
const DropDownBtn = styled.button`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #6e727c;
  border: 0;
  background: transparent;
  cursor: pointer;
  height: 44px;
  width: 100%;
  border-radius: 4px;
  border: solid 1px #dedfe2;
  background-color: #fafafa;
  text-align: right;
  padding: 0 12px;
  i {
    font-size: 16px;
    width: 20px;
    height: 20px;
    line-height: 1.25;
    letter-spacing: normal;
    color: ${theme.CoolGrey};
  }
  span {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: normal;
    color: ${theme.IndiqoGrey[800]};
  }
`;
const DropDownWraper = styled.div`
  overflow: auto;
  z-index: 1;
  position: absolute;
  background: #fff;
  top: 0;
  left: 0px;
  height: 0;
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-radius: 5px;
  box-shadow: 0 0 6px 0 #bdbdbd;
  width: 100%;
  ${props =>
    props.active &&
    `padding:5px
   height:${170}px`};
`;
const DropDownItem = styled.div`
  color: #6e727c;
  cursor: pointer;
  min-width: 240px;

  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${theme.IndiqoGrey[800]};

  border: 0;
  padding: 12px;
  background: ${props => (props.active ? `#f4f5f6` : "transparent")};
  transition: 0.2s;
  text-align: left;
  &:hover {
    background: #f4f5f6;
  }
  &:not(:last-child) {
    border-bottom: 1px solid #e6e6e6;
  }

  h3 {
    margin: 0;
    font-size: 16px;
  }
  p {
    margin: 0;
    padding-top: 4px;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: normal;
    color: ${theme.IndiqoGrey[800]};
  }
`;

export default {
  DropDownBtn,
  DropDown,
  DropDownItem,
  DropDownWraper,
  ButtonWrapper,
  Button,
  Root,
  BackDrop,
  Body,
  TitleWraper,
  Title,
  SwitchWraper,
  DateInputWrapper,
  Hyphen,
  CalendarBtn,
  AcceptText,
  Close,
  Rectangle,
  SelectWrapper
};
