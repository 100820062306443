import styled, { css } from "styled-components";

const CustomePriceFileContainer = styled.div`
display: flex;
flex-direction: row;

${props => {
  if (props.displayed === false) {
    return (
      css`
        display: none;
      `
    );
  }
}}
`;


const addDocumentText = styled.p`
  color: #3e4148;
  font-size: 14px;
  font-weight: normal;
  height: 20px;
  letter-spacing: 0.25px;
  line-height: 20px;
  margin: 18px 0 4px 24px;

  ${props => props.gray && css`
    font-style: italic;
    color: #b9bbc1;
    margin-left: 0;
  `}

  ${props => props.customePrice && css`
    margin: 0 0 4px 48px; 
  `}

  ${props => {
    if (props.displayed === false) {
      return (
        css`
          display: none;
        `
      );
    }
  }}
`;

const QuoteCircle = styled.label`
  background: linear-gradient(to bottom, #00bcd4, #00acc1);
  color: #fff;
  text-align: center;
  border-radius: 100%;
  width: 16px;
  height: 16px;
  font-size: 16px;
  font-weight: bolder;
  cursor: pointer;
  font-family: "Font Awesome 5 Pro";
  margin-left: 48px;
  margin-right: 4px;
  margin-top: 2px;
`;

const QuotePlus = styled.div`
  font-size: 16px;
font-weight: solid;
`;

const CustomePriceContainerRemoveDocument = styled.div`
  display: flex;
  flex-direction: column;


${props => {
  if (props.displayed === false) {
    return (
      css`
        display: none;
      `
    );
  }
}}
`;

const CustomePriceRemoveDocumentText = styled.p`
  color: rgb(185, 187, 193);
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  height: 16px;
  letter-spacing: 0.5px;
  line-height: 16px;
  text-align: center;
  width: 96px;
  cursor: pointer;
  margin-left: 48px;
  text-align: center;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(224, 247, 250);
  border-radius: 0px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.14),
    0px 2px 2px 0px rgba(0, 0, 0, 0.12),
    0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  height: 96px;
  width: 96px;
  margin-left: 48px;
  margin-top: 14px;
`;

export default {
    CustomePriceFileContainer,
    addDocumentText,
    QuoteCircle,
    QuotePlus,
    CustomePriceContainerRemoveDocument,
    CustomePriceRemoveDocumentText,
    ImageContainer
}