import React, { Component } from 'react';
import StyledComponents from './styles';
import HappyFace from '../../../../public/images/HappyFace.svg'

const {
    Root,
    Title,
    Description
} = StyledComponents;

class AllDone extends Component {
    render() {
        return (
            <Root>
                <img alt="happy-face" src={HappyFace} />
                <Title>You're all done!</Title>
                <Description>
                    You’ve updated all your items—nice work. 
                    Feel free to close this window and we’ll take care of the rest.
                </Description>
            </Root>
        )
    }
}

export default AllDone
