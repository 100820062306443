import styled, {css} from 'styled-components';

const Row = styled.div`

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 8px 0px;
  gap: 16px;

  width: 100%;
  height: 36px;

`;

export default {
  Row
}