import React, { Component } from "react";
import PropTypes from "prop-types";
import StyledComponent from "./style";
import AttachmentPreview from "../../../../components/AttachmentPreview";
import { Button } from "../../../../shared/style";
import { apiConfig } from "../../../../lib/apiConfig";
import fetchData from "../../../../lib/fetchData";
import formatter from "../../../../lib/formatter";

const {
  ButtonWrapper,
  Root,
  CorpInfo,
  Header,
  Description,
  Details,
  Detail,
  Action
} = StyledComponent;

class ViewBid extends Component {
  convertUTCDate = date => {
    const localDate = formatter.getDateTime(date);
    return `${localDate.formattedDate}, ${localDate.formattedTime}`;
  };

  handleAcceptClick = () => {
    if (!this.context.isMockData) {
      const options = {
        ...apiConfig.postConsumerInfo,
        query: "token=" + this.props.activity.Operations.Accept
      };
      fetchData(options).then(data => {
        if (data && data.fetchedData && !data.fetchedData.ErrorCode) {
          this.props.onAcceptedActivity(data.fetchedData);
          this.props.onActionClick({
            view: "accept",
            header: { subTitle: "accepted", status: "success" }
          });
        } else {
          this.setState({ fetchError: true });
        }
      });
    } else {
      this.props.onActionClick({
        view: "accept",
        header: { subTitle: "accepted", status: "success" }
      });
    }
  };

  render() {
    const { activity, location } = this.props;

    return (
      <Root>
        <Header>
          <CorpInfo>
            <img src={activity.Owner.ImageUrl} alt="owner-logo" />
            <p>{activity.Owner.DBAName}</p>
          </CorpInfo>
          <p className="bid">Bid #{activity.BidRequest.Id}</p>
        </Header>
        <Description>{activity.Description}</Description>
        {activity &&
          activity.BidRequest &&
          activity.BidRequest.Attachments &&
          activity.BidRequest.Attachments.length > 0 && (
            <AttachmentPreview activity={activity.BidRequest} />
          )}
        <Details>
          <Detail className="site">
            Site
            <span>{`${location.City}, ${location.State} ${location.Zip}`}</span>
          </Detail>
          <Detail className="priority">
            Priority
            <span>{activity.Priority.Description}</span>
          </Detail>
          <Detail className="due">
            Due
            <span>{this.convertUTCDate(activity.BidRequest.DueDate)}</span>
          </Detail>
          <Detail className="created">
            Created
            <span>{this.convertUTCDate(activity.CreatedOn)}</span>
          </Detail>
        </Details>
        <Action>
          <p>Your company will be charged if you accept the bid request</p>
          <ButtonWrapper>
            <Button
              fWidth
              pFloating
              danger={true}
              onClick={() => this.props.onActionClick({ view: "decline" })}
            >
              Decline&hellip;
            </Button>
            <Button
              fWidth
              pFloating
              success={true}
              onClick={this.handleAcceptClick}
            >
              Accept
            </Button>
          </ButtonWrapper>
        </Action>
      </Root>
    );
  }
}

ViewBid.contextTypes = {
  isMockData: PropTypes.boolean
};

export default ViewBid;
