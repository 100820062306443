import styled, { createGlobalStyle } from 'styled-components';
import {
  mediaMax,
  mediaMin
} from "../../shared/style";

const GlobalStyles =  createGlobalStyle`
  #root {
    padding: 0px !important;
    height: 100vh;
    width: 100vw;
  }
`;

const MainWrapper = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;700;900&family=Red+Hat+Display:wght@400;500;700&display=swap');

  & p, a, li, span, td, th, button {
    font-family: "Mulish", sans-serif;
  }

  & h1, h2, h3 {
    font-family: "Red Hat Display", sans-serif;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: white;
  padding: 0px;
  color: #3E4148;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 8px;
  height: 88px;
  width: 100%;
  background: #0B1C54;

  ${mediaMax.desktop`
    justify-content: flex-end;
    height: 52px;
    padding: 16px 16px;
  `};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  width: 0;
  flex: 1;
  overflow: hidden;

  img {
    height: calc(100vh - 88px);
    width: calc(100vw / 2);
    object-fit: cover;
    object-position: center;
  }

  ${mediaMax.desktop`
    img {
      height: calc(100vh - 52px);
      object-position: 40% 100%;
    }
  `};
`;

const ContentText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 24px;
  padding: 0px 100px;
  margin: 0px auto;

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    margin: 0px;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin: 0px;
  }

  ${mediaMax.desktop`
    padding: 0px 16px;
  `};

  ${mediaMax.phone`
    padding: 0px 16px;

    h1 {
      font-size: 24px;
      line-height: 24px;
    }

    p {
      font-size: 16px;
      line-height: 20px;
    }
  `};
`;

export default {
  GlobalStyles,
  MainWrapper,
  Header,
  ContentContainer,
  Content,
  ContentText
};