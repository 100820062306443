import React, { Fragment } from "react";
import styledComponents from "./style";
import commonStyledComponents from "../commanStyle";
import Upload from "../../../../../components/Upload";
import Switch from "../../../../../components/Switch";
import Collapse from "../Components/Collapse";
import Alert from "../Components/Alert";
import Error from "../Components/Error";
import Message from "../Components/Message";
import UploadModal from "../../../../../components/Upload/uploadModal/index";
import FooterWrapper from "../Components/FooterWrapper";
import moment from "moment";
import * as m from "./methods";
import { PreView } from "./components";
import * as cf from "../methods";

export default class RequirementUpload extends React.PureComponent {
  constructor(props) {
    super(props);
    const isCollateral = props.requirementData.Collateral;
    const collateral = isCollateral && [isCollateral];
    this.state = {
      // isChecked: false,
      // commentValue: "",
      submit: false,
      // addComment: false,
      showEdit: isCollateral ? true : false,
      editMode: false,
      uploadArray: collateral || null,
      resultantUploadArray: collateral || null,
      uploadError: false,
      showModal: false,
      modalData: {
        noExpiration: false,
        expirationDate: null,
        name: (isCollateral && isCollateral.name) || null,
        type: (isCollateral && isCollateral.type) || null
      }
    };
  }

  setUploadArray = array => {
    const check = array.length !== 0;
    this.setState({
      uploadArray: check ? array : null,
      showEdit: false,
      uploadError: !check,
      showModal: check
    });
  };
  // commentChange = e => {
  //   this.setState({ commentValue: e.target.value });
  // };
  // addComment = () => {
  //   this.setState({ addComment: true });
  // };
  // toggleSwitch = () => {
  //   this.setState({ isChecked: !this.state.isChecked });
  // };

  submit = () => {
    const { uploadArray, showDone, modalData } = this.state;
    const hasUploads = uploadArray !== null && uploadArray.length > 0;
    if (hasUploads) {
      const options = {
        body: {
          RequirementId: this.props.requirementData.Id,
          Document: {
            Description: `${modalData.type}`,
            FileUrl: `${uploadArray[0].Url}`,
            Expiration: `${modalData.expirationDate}`,
            Name: `${modalData.name}`,
            ContentType: `${uploadArray[0].contentType}`
          }
        }
      };
      this.setState({
        submit: true,
        showEdit: true,
        editMode: false,
        uploadError: false,
        resultantUploadArray: uploadArray ? uploadArray.slice() : null
      });
      this.collapse.toggle();
      this.props.updateRequirements(options);
    } else {
      this.setState({
        uploadError: true
      });
    }
  };

  edit = () => {
    this.setState({ showEdit: false, editMode: true });
  };

  cancel = () => {
    const { resultantUploadArray } = this.state;
    this.setState({
      editMode: false,
      showEdit: true,
      uploadError: false,
      uploadArray: resultantUploadArray || []
    });
    if (this.upload) {
      this.upload.setUploadArray(resultantUploadArray || []);
    }
    this.collapse.toggle();
  };

  replaceDocument = doc => {
    if (this.upload) {
      this.upload.setUploadArray([]);
      this.upload.onDrop(doc.target.files);
    }
  };

  cancelModal = () => {
    const array = this.state.resultantUploadArray || [];
    this.setState({
      showModal: false,
      uploadArray: array
    });
    if (this.upload) {
      this.upload.setUploadArray(array);
    }
  };

  saveModal = data => {
    const expiryDate = data.noExpiration
      ? null
      : moment()
          .year(data.date.year)
          .month(data.date.month - 1)
          .date(data.date.day)
          .format("YYYY-MM-DDThh:mm:ssZ");
    const modalData = {
      noExpiration: data.noExpiration,
      expirationDate: expiryDate,
      name: `${data.name}`,
      type: `${data.type}`
    };
    this.setState({ showModal: false, modalData });
    let array = m.getCaption(this.state.uploadArray, expiryDate);
    if (this.upload) {
      this.upload.setUploadArray(array);
    }
  };

  uploadModal = () => {
    const { state } = this;
    return (
      <UploadModal
        // {...state.modalData}
        cancelModal={this.cancelModal}
        saveModal={this.saveModal}
        data={state.uploadArray}
        replaceDocument={this.replaceDocument}
      />
    );
  };

  footer = () => {
    return (
      <FooterWrapper
        cancel={this.cancel}
        submitChanges={this.submit}
        edit={this.edit}
        {...this.state}
      />
    );
  };

  uploadSection = () => {
    const { uploadArray, uploadError } = this.state;
    const { Text, SmallText } = commonStyledComponents;
    const previewArray =
      uploadArray !== null && uploadArray.length > 0 ? uploadArray : null;
    return (
      <Fragment>
        <Text spaceBottom={18}>
          <b>Upload the required documents to complete this requirement</b>
        </Text>
        <SmallText>Attachments</SmallText>
        <Upload
          ref={refs => (this.upload = refs)}
          setUploadArray={this.setUploadArray}
          readOnly={true}
          singleRow={true}
          previewArray={previewArray}
        />
        {uploadError && <Error text="Uploads are required." />}
      </Fragment>
    );
  };
  render() {
    const {
      // RequirementsApply,
      // Comment,
      // Msg,
      Content,
      // CenterMsg,
      // InputWraper,
      // Label,
      Note
      // Textarea
    } = commonStyledComponents;

    const state = this.state,
      { Root } = styledComponents,
      { requirementData, status } = this.props,
      iconData = cf.iconData(
        state.submit,
        requirementData.VerificationRequired,
        status
      ),
      headerDetail = {
        ...iconData,
        title: requirementData.Name
      };
    const showAlert =
      status.status === "overridenExpiring" ||
      status.status === "overridenExpired" ||
      status.status === "rejected";
    return (
      <Fragment>
        <Collapse
          headerDetail={headerDetail}
          ref={ref => (this.collapse = ref)}
        >
          <div>
            <Message bigMsg={true} requirementData={{ ...requirementData }} />
            <Content>
              <Root>
                {showAlert && <Alert status={status} data={requirementData} />}
                {!state.showEdit ? (
                  <Fragment>
                    {this.uploadSection()}
                    {/* <RequirementsApply spaceTop={14} bottomSpace={13}>
                  <Switch
                    text="This requirement does not apply to our company"
                    toggleSwitch={this.toggleSwitch}
                    isChecked={state.isChecked}
                  />
                </RequirementsApply> */}
                    {/* {this.state.addComment ? (
                  <InputWraper>
                    <Label>Comment</Label>
                    <Textarea
                      onChange={this.commentChange}
                      value={state.commentValue}
                    />
                  </InputWraper>
                ) : (
                  <Comment onClick={() => this.addComment()}>
                    Add a comment…
                  </Comment>
                )} */}
                    {this.props.requirementData.VerificationRequired && (
                      <Note spaceTop={15} spaceBottom={-8}>
                        <i className="far fa-info-circle" />
                        This requirement will be verified by your connection.
                      </Note>
                    )}
                  </Fragment>
                ) : (
                  <PreView uploadArray={state.uploadArray} />
                )}
              </Root>
            </Content>
          </div>
          {this.footer()}
        </Collapse>
        {state.showModal && this.uploadModal()}
      </Fragment>
    );
  }
}
