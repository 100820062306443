import _ from 'lodash';

export default (url, paramName) => {
    let urlParams = {};

    if (url && url.indexOf('?') === 0) {
        urlParams = _.chain(url)
            .replace('?', '')
            .split('&')
            .map(_.partial(_.split, _, '=', 2))
            .fromPairs()
            .value();
    } else {
        let questionIndex = _.indexOf(url, '?'),
            params = url.substring(questionIndex, url.length);

        urlParams = _.chain(params)
            .replace('?', '')
            .split('&')
            .map(_.partial(_.split, _, '=', 2))
            .fromPairs()
            .value();
    }

    urlParams = _.each(urlParams, (value, key) => {
        urlParams[key] = decodeURIComponent(value);

        if (!_.isNaN(_.parseInt(value))) {
            urlParams[key] = value;
        }
    });

    if (paramName) {
        if (_.has(urlParams, paramName)) {
            urlParams = urlParams[paramName];
        }
    }

    return urlParams;
};
