import styled, { createGlobalStyle, css } from "styled-components"
import { mediaMax, mediaMin } from "../../../../shared/style";

const ModalBackground = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  background: rgba(0, 0, 0, 0.50);
  backdrop-filter: blur(6px);

  transform: scale(1);
  animation: ${({ animate }) => {
    switch (animate) {
      case 'in':
        return 'fadeIn .3s ease-in forwards';
      case 'out':
        return 'fadeOut .3s ease-in forwards';
      default:
        return 'fadeIn .3s ease-in forwards';
    }
  }};
  @keyframes fadeIn {
    0% { background: rgba(0, 0, 0, 0.0); }
    100% { background: rgba(0, 0, 0, 0.5); }
  }

  @keyframes fadeOut {
    0% { background: rgba(0, 0, 0, 0.5); }
    100% { background: rgba(0, 0, 0, 0.0); }
  }
`;

const ModalContainer = styled.div`
  display: flex;
  width: 480px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;

  border-radius: 8px;
  background: var(--white-white-100, #FFF);
  
  /* shadow/modal */
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14), 0px 4px 5px 0px rgba(0, 0, 0, 0.12), 0px 1px 10px 0px rgba(0, 0, 0, 0.20);

  opacity:0;
  animation: ${({ animate }) => {
    switch (animate) {
      case 'in':
        return 'animateUp .3s ease-in forwards';
      case 'out':
        return 'animateDown .3s ease-in forwards';
      default:
        return 'animateUp .3s ease-in forwards';
    }
  }};
  @keyframes animateUp {
    0% {
      transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  
  @keyframes animateDown {
    0% {
      transform: translateY(0px);
      opacity: 1;
    }
    100% {
      transform: translateY(1000px);
      opacity: 0;
    }  
  }

  ${mediaMax.desktop`
    margin: 0px 16px;
  `};
`;

const ModalTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

const ModalTitle1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  width: 100%;
  gap: 24px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

const LargeIconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;

  i {
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    color: #B9BBC1;
  }
`;

const IconBox = styled(LargeIconBox)`
  width: 20px;
  height: 20px;

  i {
    font-size: 16px;
    line-height: 20px;;
  }
`;

const ModalCloseButton = styled.button`
  border: none;
  padding: 0px 5px;
  background: none;
  cursor: pointer;
`;

const ModalCloseIcon = styled.i`
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #B9BBC1;
`;

const ScrollDisabler = createGlobalStyle`
  body {
    overflow: hidden !important;
  }
`;

export default {
  ModalBackground,
  ModalContainer,
  ModalTitle,
  ModalTitle1,
  ModalContent,
  LargeIconBox,
  IconBox,
  ModalCloseButton,
  ModalCloseIcon,
  ScrollDisabler
}