import styled, { css } from "styled-components";
import { mediaMax, headerSizes, theme, fade } from "../../../shared/style";
import { Section } from "../../../components/QuickCard";
const Root = styled.div`
  ${Section} {
    ${mediaMax.phone`
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     min-height: calc(100vh - ${headerSizes.phone}px);
   `};
  }
`;
const QuoteTitle = styled.a`
  font-size: 16px;
  font-weight: bold;
  color: #00bcd4;
  margin: 0;
  margin-bottom: 4px;
  display: inline-block;
`;
const QuoteForm = styled.form`
  > div {
    /* margin-bottom: 5px; */
  }
`;
const QuoteBRnum = styled.h4`
  font-size: 12px;
  margin: 0 0 24px 0;
  color: #6e727c;
`;
const QuoteSubTitle = styled.h4`
  font-size: 14px;
  color: #3e4148;
  font-weight: normal;
  margin: 0;
  padding-bottom: 7px;
`;

const DeciUpload = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* margin-bottom: 24px; */
  flex-wrap: wrap;
  > div {
    box-sizing: border-box;
    margin-bottom: 24px;
    flex: 0 0 calc(50% - 12px);
    max-width: calc(50% - 12px);
    ${mediaMax.phone`
    flex: 0 0 100%;
    max-width: 100%;
    `};
  }
`;

const Input = styled.input`
  width: 50%;
  border: 0;
  margin-left: 24px;
  font-size: 16px;
  background-color: #fafafa;
  text-align: right;
  padding-right: 16px;
  &:focus {
    outline: none;
  }
  ${props =>
    props.inputRed &&
    `
  width: 50%;
    border: 0;
    margin-left: 24px;
    font-size: 12px;
    background-color: #fbe9e7;
    text-align: right;
    padding-right: 16px;
    color: #ff5722;
  `} &::-webkit-input-placeholder {
    color: #b9bbc1;
    font-family: "Lato";
    font-size: 16px;
  }
  &:-ms-input-placeholder {
    color: #b9bbc1;
    font-family: Lato;
    font-size: 16px;
  }

  &::-moz-placeholder {
    color: #b9bbc1;
    font-family: Lato;
    font-size: 16px;
  }
  &:-moz-placeholder {
    color: #b9bbc1;
    font-family: Lato;
    font-size: 16px;
  }
`;

const QuoteErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const QuoteIcon = styled.span`
  margin: 2.55px;
  color: #ff5722;
`;

const ShowIntegerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border: 1px solid #dedfe2;
  background-color: #fafafa;
  height: 44px;
  border-radius: 4px;
  ${props =>
    props.showIntegerError &&
    `
  border: 1px solid #ff5722;
  background-color: #fbe9e7;
  z-index: 1;
`};
`;

const QuoteDollar = styled.div`
  padding: 12px 0 0 12px;
  color: #b9bbc1;
  font-size: 16px;
  ${props =>
    props.showIntegerError &&
    `
color: red;
`};
`;

const UsdSpan = styled.div`
  width: 92px;
  border: 1px solid ${theme.IndiqoGrey[300]};
  height: 100%;
  background-color: ${theme.IndiqoGrey[300]};
  text-align: center;
  padding: 0 12px;
  color: #6e727c;
  border-radius: 0 4px 4px 0;

  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: 1px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  transation: 0.3s all;
  &:hover {
    background-color: ${fade(theme.IndiqoGrey[300], 0.7)};
  }

  i {
    font-size: 26px;
  }
  ${props =>
    props.showIntegerError &&
    `
  border-color: #ff5722;
  border: 0 0 0 1px;

  background-color: #ff5722;
  z-index: 99;
  color: #fff;
  &:hover {
    border-color: ${fade("#ff5722", 0.7)};
    background-color: ${fade("#ff5722", 0.7)};
  }
`};
`;

const DeciError = styled.div`
  color: #ff5722;
  font-size: 14px;
  font-style: italic;
  margin-top: 4px;
`;

const QuoteOption2 = styled.span`
  font-size: 14px;
  font-style: italic;
  color: #b9bbc1;
`;
const Textarea = styled.textarea`
  max-height: 40vh;
  min-height: 64px;
  border: 1px solid #dedfe2;
  background-color: #fafafa;
  border-radius: 4px;
  font-size: 16px;
  padding: 13px 11px;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  &:focus {
    outline: #00bcd4;
  }
`;
const QuoteButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  ${mediaMax.phone`margin-bottom: 33px;`};
`;
const QuoteButton = styled.div`
  margin: 0;
  padding: 0;
`;
const Button = styled.button`
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 1px;
  width: 200px;
  height: 40px;
  background-image: linear-gradient(to bottom, #8bc34a, #7cb342);
  border-radius: 100px;
  color: #fff;
  cursor: pointer;
  border: none;
  ${mediaMax.phone`
 width: 279px;
 border: solid 1px #689f38;
 background-image: linear-gradient(to bottom, ${theme.Success[500]}, #689f38)`};
  &:disabled {
    opacity: 0.5;
  }
`;
export default {
  Root,
  QuoteTitle,
  QuoteForm,
  QuoteBRnum,
  QuoteSubTitle,
  DeciUpload,
  Button,
  QuoteButton,
  QuoteButtonSection,
  Textarea,
  QuoteOption2,
  DeciError,
  ShowIntegerWrapper,
  UsdSpan,
  Input,
  QuoteDollar,
  QuoteIcon,
  QuoteErrorContainer
};
