import { createGlobalStyle } from "styled-components";
import { mediaMax, mediaMin, theme, fade } from "./style";
const GlobalStyle = createGlobalStyle`
.text-danger {
   color: #ff5722 !important;
 }
 .text-warning {
   color: #ffa000 !important;
 }
 .text-success{
   color: #8bc34a !important;
 }
 .text-gtext {
   color: ${fade(theme.IndiqoGrey[800], 0.7)} !important;
 }
 
 .d-none{
   display: none !important;
 }
.noMobile{
  ${mediaMax.tablet`display:none !important;`}
}
.noDesktop{
  ${mediaMin.tablet`display:none !important;`}
}

.isFilterBlur{
  header,.rightContent{
    filter: blur(8px);
  }
}
`;
export default GlobalStyle;
