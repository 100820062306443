import styled, { css, createGlobalStyle } from 'styled-components';
import {
  mediaMax
} from "../../../../shared/style"

const ProductRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0px;
  gap: 8px;
  width: 100%;
  box-shadow: inset 0px -1px 0px #EDEEEF;

  &:last-child{
    box-shadow: none;
  }

  ${mediaMax.phone`
    &:last-child{
      box-shadow: none;
      padding-bottom: 0px;
    }
    &:first-child{
      padding-top: 0px;
    }
  `};
  
`;

const ProductDescription = styled.div`

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px;
  gap: 8px;

  width: 100%;

`;

const ProductDetails = styled.div`
  width: 100%;
  gap: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  ul {
    padding-left: 20px;
    margin: 0px;

    li {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      margin: 0px;
    }
  }
`;

const UnorderedList = styled.ul`
  padding-left: 20px;
  margin: 0px;
`;

const ItemName = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
`;

export default {
  ProductRow,
  ProductDescription,
  ProductDetails,
  UnorderedList,
  ItemName
}