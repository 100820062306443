import React from 'react';
import styledComponents from './styles';

const {
  Paging,
  BackLink,
  IconBox
} = styledComponents;

export default function PagingSection({ token }) {
  return (
    <Paging>
      <BackLink to={`installation-quote/?token=${token}`}>
        <IconBox>
          <i className='far fa-arrow-left' />
        </IconBox>
      </BackLink>
      <BackLink to={`installation-quote/?token=${token}`}>Quote</BackLink>
      <p className='pLink'>/</p>
      <p>Financing</p>
    </Paging>
  )
}