import React, { PureComponent } from 'react';
import _ from "lodash";
import queryString from "query-string";

import Loader from "../../components/Loader";

import { InstallerStatus } from '../../components/InstallerStatus';
import { InstallerConfirmed } from './components/InstallerConfirmed';
import { TierUpdateFail } from '../tierManage/components/TierUpdateFail';
import { TierAcceptConfirm } from './components/TierAcceptConfirm';
import BidStatusError from '../../components/Errors';

class TieredConfirmationAccepted extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            installerStatus: false,
            installerConfirmed: true,
            token: "",
            fechedBidData: '',
            bidConfirmed: false,
            acceptedBy: '',
            acceptSuccess: false,
            hasRevision: false,
            translations: {},
            browserLanguage: "",
            country: "",
            getBidStatus: ""
        }
    }

    componentDidMount() {

        fetch("/language", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
        })
            .then((res) => res.json())
            .then(data => { this.setState({ browserLanguage: data.browserLanguage }) })

        let bidGuid = this.getBidGuid(this.props.location.search);
        let country = queryString.parse(this.props.location.search).country;
        if (!_.isEmpty(bidGuid)) {
            this.setState({ token: bidGuid });
            if (_.isEmpty(this.state.fechedBidData)) {
                this.getBidByGuid(bidGuid);
            }
        }
        if (_.has(window, 'env.translations') && !_.isEmpty(window.env.translations)) {
            this.setState({
                translations: window.env.translations
            })
        }
        if (!_.isEmpty(country)) this.setState({ country: country })
    }


    getBidGuid = (url) => {
        return queryString.parse(url).token
    }

    getBidByGuid = async (bidGuid) => {
        let optionalParams = {
            headers: { "content-type": "application/json; charset=UTF-8", "Subscription-Key": `${window.env.apiKey}` },
            method: "GET"
        }

        const response = await fetch(`${window.env.qmtUrl}/Bid/${bidGuid}`, optionalParams);
        this.setState({ getBidStatus: response.status });

        if (response.ok) {
            const data = await response.json();
            this.setState({
                fechedBidData: data,
                hasRevision: !_.isNil(data.Settlement.RevisedOn) ? true : false
            });
        }
        this.setState({ isLoading: false });
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    }

    formatNumber = (value) => {
        let formattedNumber = new Intl.NumberFormat(this.state.browserLanguage.replace("_", "-"), {
            style: 'currency',
            currency: this.state.fechedBidData.ProgramCurrencyType,
            currencyDisplay: "narrowSymbol"
        });
        return formattedNumber.format(value);
    }

    acceptBidByGuid = (guid) => {
        this.setState({
            isLoading: true,
        })

        let optionalParams = {
            headers: {
                "content-type": "application/json; charset=UTF-8",
                "Subscription-Key": `${window.env.apiKey}`
            },
            method: "POST"
        }

        return new Promise((resolve, reject) => {
            fetch(`${window.env.qmtUrl}/Bid/${this.state.token}/Confirm`, optionalParams)
                .then(res => {
                    this.setState({ bidConfirmed: res.status, isLoading: false, acceptSuccess: true })
                    return;
                })
                .catch(error => {
                    reject({
                        isLoading: false,
                        fetchedData: { ErrorCode: 500, ...error }
                    });
                })
        })

    }




    render() {

        if (!this.state.isLoading && this.state.acceptSuccess === true) {
            return (
                <InstallerConfirmed companyName={this.state.fechedBidData.Assignee.Name} translations={this.state.translations} />
            )
        }

        if (!this.state.isLoading && this.state.fechedBidData.Status === 'Cancelled' || this.state.fechedBidData.Status === 'Closed') {
            return (<InstallerStatus translations={this.state.translations} installerStatus={this.state.fechedBidData.Status === "Closed" ? "Accepted" : this.state.fechedBidData.Status === "Cancelled" ? "Cancelled" : ''} />)
        }

        if (!this.state.isLoading && this.state.fechedBidData.Status === 'Declined') {
            return (<BidStatusError status={this.state.fechedBidData.Status} contactInfo={true} />)
        }

        if (!this.state.isLoading && this.state.fechedBidData.Status !== 'Cancelled' && this.state.fechedBidData.Status !== 'Closed' && this.state.fechedBidData.Status !== 'Declined' && ![400, 404, 500].includes(this.state.getBidStatus)) {
            return (
                <TierAcceptConfirm
                    bidData={this.state.fechedBidData}
                    hasRevision={this.state.hasRevision}
                    acceptBidByGuid={this.acceptBidByGuid}
                    formatNumber={this.formatNumber}
                    translations={this.state.translations}
                    browserLanguage={this.state.browserLanguage}
                />
            );
        }

        if (!this.state.isLoading && (this.state.acceptSuccess === false || [400, 404, 500].includes(this.state.bidConfirmed) || [400, 404, 500].includes(this.state.getBidStatus))) {
            return <TierUpdateFail translations={this.state.translations} country={this.state.country} />
        }

        return (<Loader color={"#000000"} isLoading={this.state.isLoading} />)

    }
}

export default TieredConfirmationAccepted

