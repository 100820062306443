import React, { Fragment } from "react"
import StyledComponents from './styles'

const {
    GlobalDraftStyles,
    MainWrapper,
    Header,
    PoweredBy,
    Footer,
    Support,
    SecondaryText,
    SupportAnchor
} = StyledComponents

const WhiteWrapper = ({children, projectId=false}) => {
    return (
        <MainWrapper>
            <GlobalDraftStyles />
            <Header>
                <PoweredBy
                    src={`${window.env.blobUrl}/qmerit/Qmerit-Wordmark-Indiqo.png`}
                    alt="Qmerit" />
            </Header>
            {children}
            <Footer projectId={projectId}>
                <Support>
                    <SecondaryText>Need assistance?</SecondaryText>
                    <SupportAnchor href={`mailto:${window.env.csEmail}`}>{window.env.csEmail}</SupportAnchor>
                    <SupportAnchor href={`tel:${window.env.csPhone}`}>{window.env.csPhone}</SupportAnchor>
                </Support>
                {projectId &&
                    <SecondaryText>PROJECT ID: {projectId}</SecondaryText>
                }
            </Footer>
        </MainWrapper>
    )
}


export default WhiteWrapper;