import React from "react";
import styles from "./styles.module.css";
import styled from "styled-components";
const CustomRadio = props => {
  const { id, name, label, children, value } = props;
  return (
    <Root className={styles.radioWraper}>
      <div className={styles.radioFieldWraper}>
        <input
          id={id}
          name={name}
          type={props.checkBox?"checkBox":"radio"}
          value={value}
          className={styles.radioField}
          onChange={e => props.onChange(e)}
        />
        <label className={props.square?styles.square:""} htmlFor={id}>{label}</label>
        <div className={styles.radioBody}>{children}</div>
      </div>
    </Root>
  );
};

export default CustomRadio;
const RootGap = 12;
const Root = styled.div`
  padding-left: ${RootGap + 2}px;
  padding-right: ${RootGap}px;
  margin-bottom: 12px;
`;
