import styled from "styled-components";
import { theme, mediaMax, headerSizes } from "../../shared/style";
const Content = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - ${headerSizes.desktop * 2}px - 8px);
  ${mediaMax.tablet`min-height: 100vh;`};
   & > div {
    padding: 0 12px;
    max-width: 400px;
  }
  img {
    max-height: 72px;
  }
  h1,
  h2 {
    margin: 0 0 16px;
    opacity: 0.75;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    color: ${({ background }) =>
      background ? theme.IndiqoGrey[800] : theme.White};
    /* color: ${theme.White}; */
  }
  p,
  a {
    font-family: Lato;
    font-size: 16px;
    line-height: 1.25;
  }
  p {
    margin: 0;
    color: ${({ background }) =>
      background ? theme.IndiqoGrey[800] : theme.White};
    /* color: ${theme.White}; */
  }
  a {
    color: ${theme.Action["400"]};
    cursor: pointer;
  }
`;
const ContentMobile = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    padding: 8px 16px 16px 16px;
    max-width: 400px;
  }
  img {
    max-height: 72px;
    margin-bottom: 4px;
  }
  h1,
  h2 {
    margin: 0 0 8px;
    opacity: 0.75;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    color: ${theme.White};
    ${mediaMax.phone`color: ${theme.IndiqoGrey[800]}`};
  }
  p,
  a {
    font-family: Lato;
    font-size: 16px;
    line-height: 1.25;
  }
  p {
    margin: 0;
    color: ${theme.White};
    ${mediaMax.phone`color: ${theme.IndiqoGrey[800]}`};
  }
  a {
    color: ${theme.Action["100"]};
    cursor: pointer;
  }
`;

export default { Content, ContentMobile };
