import React from 'react';
import _ from 'lodash';
import formatter from '../../../../lib/formatter';
import StyledComponents from './styles';

import AssigningProjectCard from '../CurrentStatusCards/AssigningProjectCard';
import WaitingForQuoteCard from '../CurrentStatusCards/WaitingForQuoteCard';
import ApproveQuoteCard from '../CurrentStatusCards/ApproveQuoteCard';
import SchedulingCard from '../CurrentStatusCards/SchedulingCard';
import ImportantInfoCard from '../CurrentStatusCards/ImportantInfoCard';
import SignOffCard from '../CurrentStatusCards/SignOffCard';
import ReviewingQuoteCard from '../CurrentStatusCards/ReviewingQuoteCard';
import ReassigningProjectCard from '../CurrentStatusCards/ReassigningProjectCard';
import ApproveRevisedQuoteCard from '../CurrentStatusCards/ApproveRevisedQuoteCard';

import AppointmentScheduledCard from '../CompletedStepsCards/AppointmentScheduledCard';
import QuoteApprovedCard from '../CompletedStepsCards/QuoteApprovedCard';
import QuoteSubmittedCard from '../CompletedStepsCards/QuoteSubmittedCard';
import WorkerAssignedCard from '..//CompletedStepsCards/WorkerAssignedCard';
import RequestSubmittedCard from '../CompletedStepsCards/RequestSubmittedCard';
import QuoteReviewedCard from '../CompletedStepsCards/QuoteReviewedCard';
import QuoteDeclinedCard from '../CompletedStepsCards/QuoteDeclinedCard';
import QuoteRevisedCard from '../CompletedStepsCards/QuoteRevisedCard';
import ProjectCancelledCard from '../CompletedStepsCards/ProjectCancelledCard';

import {
  getConfirmedOnDate,
  getBidSubmittedOnDate,
  getBidAcceptedOnDate,
  getReviewedOnDate,
  getRevisedOnDate,
  getCurrentStatusCardRevisedOnDate,
  getRejectedOnDate,
  getBidServiceProviderName,
  getBidNeedsReview,
} from '../../helpers/BidsDates';

import { getInstallationAppointmentDate, getAppointmentDates } from '../../helpers/InstallationsDates';

const { TitleWrapper, StatusHistoryTitle, Divider } = StyledComponents;

export default function StatusScreen({ requestData, status, browserLanguage, installedOnDate, translations }) {
  //Bids array was sorted according to the Bid Id so they can be iterated and displayed in order
  if (requestData.Bids) {
    requestData.Bids.sort((a, b) => b.BidId - a.BidId);
  }

  var timeZoneT = requestData.TimeZone.timeZoneId;

  return (
    <React.Fragment>
      {/* In this case, if the request is cancelled, we continue with no "Current Status Card";
		  In case the request is not cancelled, "Current Status Cards" are displayed */}
      {requestData.Status != 'Canceled' ? (
        <React.Fragment>
          <SignOffCard
            requestData={requestData}
            installedOnDate={installedOnDate}
            translations={translations.statusScreen.currentStatus.signOff}
            show={status.name == translations.bannerSection.activeSignOff.statusName && status.active}
          />
          <ImportantInfoCard
            requestData={requestData}
            translations={translations.statusScreen.currentStatus.importantInfo}
            show={status.name == translations.bannerSection.activeSignOff.statusName && !status.active}
          />
          <SchedulingCard
            requestData={requestData}
            translations={translations.statusScreen.currentStatus.scheduling}
            show={status.name == translations.bannerSection.scheduling.statusName}
          />
          <ReviewingQuoteCard
            assigneeName={getBidServiceProviderName(requestData)}
            translations={translations.statusScreen.currentStatus.reviewingQuote}
            show={status.name == translations.bannerSection.approval.statusName && getBidNeedsReview(requestData)}
          />
          <ApproveRevisedQuoteCard
            serviceProviderName={getBidServiceProviderName(requestData)}
            requestData={requestData}
            translations={translations.statusScreen.currentStatus.approveRevisedQuote}
            show={
              status.name == translations.bannerSection.approval.statusName &&
              !getBidNeedsReview(requestData) &&
              ['BidRevised', 'QuoteReviewApproved'].includes(_.last(requestData.RequestLogs).LogType) &&
              getCurrentStatusCardRevisedOnDate(requestData)
            }
          />
          <ApproveQuoteCard
            requestData={requestData}
            translations={translations.statusScreen.currentStatus.approveQuote}
            show={
              status.name == translations.bannerSection.approval.statusName &&
              !getBidNeedsReview(requestData) &&
              !['BidRevised'].includes(_.last(requestData.RequestLogs).LogType) &&
              !getCurrentStatusCardRevisedOnDate(requestData)
            }
          />
          <WaitingForQuoteCard
            requestData={requestData}
            translations={translations.statusScreen.currentStatus.waitingForQuote}
            show={status.name == translations.bannerSection.quoting.statusName}
          />
          <ReassigningProjectCard
            requestData={requestData}
            translations={translations.statusScreen.currentStatus.reassigningProject}
            show={_.last(requestData.RequestLogs).LogType == 'BidRejected'}
          />
          <AssigningProjectCard
            requestData={requestData}
            translations={translations.statusScreen.currentStatus.assigningProject}
            show={
              status.name == translations.bannerSection.assignment.statusName &&
              !(_.last(requestData.RequestLogs).LogType == 'BidRejected')
            }
          />
        </React.Fragment>
      ) : null}
      <Divider canceled={requestData.Status == 'Canceled'} />
      <TitleWrapper canceled={requestData.Status == 'Canceled'}>
        <StatusHistoryTitle>{translations.statusScreen.title}</StatusHistoryTitle>
      </TitleWrapper>
      <ProjectCancelledCard
        requestCancelledOnDate={formatter.getIntlFormattedDateTime(
          _.last(requestData.RequestLogs).CreatedOn,
          browserLanguage,
          timeZoneT
        )}
        translations={translations.statusScreen.completedStatus.projectCancelled}
        show={
          requestData.RequestLogs.some((obj) => obj.LogType === 'RequestCancelled') && requestData.Status === 'Canceled'
        }
      />
      <AppointmentScheduledCard
        requestData={requestData}
        acceptedInstallationDate={formatter.getIntlFormattedDateTime(
          getInstallationAppointmentDate(requestData),
          browserLanguage,
          timeZoneT
        )}
        appointmentDateStart={formatter.getIntlFormattedDateTime(
          getAppointmentDates(requestData, 'start'),
          browserLanguage,
          timeZoneT
        )}
        appointmentDateEnd={formatter.getIntlFormattedDateTime(
          getAppointmentDates(requestData, 'end'),
          browserLanguage,
          timeZoneT
        )}
        translations={translations.statusScreen.completedStatus.appointmentScheduled}
        show={getInstallationAppointmentDate(requestData)}
      />
      {requestData.Bids
        ? requestData.Bids.map((bid) => {
            return (
              <React.Fragment key={bid.BidId}>
                <QuoteApprovedCard
                  requestData={requestData}
                  confirmedOnDate={formatter.getIntlFormattedDateTime(
                    getConfirmedOnDate(requestData, bid.Status, bid.BidId),
                    browserLanguage,
                    timeZoneT
                  )}
                  translations={translations.statusScreen.completedStatus.quoteApproved}
                  show={
                    getConfirmedOnDate(requestData, bid.Status, bid.BidId) &&
                    !['reopened', 'declined', 'rejected', 'cancelled'].includes(bid.Status)
                  }
                />
                <QuoteDeclinedCard
                  declinedOnDate={formatter.getIntlFormattedDateTime(
                    getRejectedOnDate(requestData, bid.Status, bid.BidId),
                    browserLanguage,
                    timeZoneT
                  )}
                  serviceProviderName={getBidServiceProviderName(requestData, bid.Status, bid.BidId)}
                  translations={translations.statusScreen.completedStatus.quoteDeclined}
                  show={bid.Status == 'rejected'}
                />
                <QuoteReviewedCard
                  reviewedOnDate={formatter.getIntlFormattedDateTime(
                    getReviewedOnDate(requestData, bid.Status, bid.BidId),
                    browserLanguage,
                    timeZoneT
                  )}
                  translations={translations.statusScreen.completedStatus.quoteReviewed}
                  show={
                    getReviewedOnDate(requestData, bid.Status, bid.BidId) &&
                    !getBidNeedsReview(requestData) &&
                    !getRejectedOnDate(requestData, bid.Status, bid.BidId)
                  }
                />
                <QuoteRevisedCard
                  requestData={requestData}
                  translations={translations.statusScreen.completedStatus.quoteRevised}
                  revisedOnDate={formatter.getIntlFormattedDateTime(
                    getRevisedOnDate(requestData, bid.Status, bid.BidId),
                    browserLanguage,
                    timeZoneT
                  )}
                  serviceProviderName={getBidServiceProviderName(requestData, bid.Status, bid.BidId)}
                  show={getRevisedOnDate(requestData, bid.Status, bid.BidId)}
                />
                <QuoteSubmittedCard
                  requestData={requestData}
                  submittedOnDate={formatter.getIntlFormattedDateTime(
                    getBidSubmittedOnDate(requestData, bid.Status, bid.BidId),
                    browserLanguage,
                    timeZoneT
                  )}
                  serviceProviderName={getBidServiceProviderName(requestData, bid.Status, bid.BidId)}
                  translations={translations.statusScreen.completedStatus.quoteSubmitted}
                  show={getBidSubmittedOnDate(requestData, bid.Status, bid.BidId)}
                />
                <WorkerAssignedCard
                  requestData={requestData}
                  acceptedOnDate={formatter.getIntlFormattedDateTime(
                    getBidAcceptedOnDate(requestData, bid.Status, bid.BidId),
                    browserLanguage,
                    timeZoneT
                  )}
                  translations={translations.statusScreen.completedStatus.workerAssigned}
                  bid={bid}
                  show={getBidAcceptedOnDate(requestData, bid.Status, bid.BidId)}
                />
              </React.Fragment>
            );
          })
        : null}
      <RequestSubmittedCard
        requestData={requestData}
        browserLanguage={browserLanguage}
        translations={translations.statusScreen.completedStatus.requestSubmitted}
        show={requestData.Survey.SubmittedOn}
      />
    </React.Fragment>
  );
}
