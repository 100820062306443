import React from 'react';
import styled from 'styled-components';

const ImageFallBack = (props) => {
    if(props.type === 'corp-logo') {
        if(!props.src || props.src !== null) {
            return <CorpLogoDefault className='corp-logo'>
                        {props.name.charAt(0).toUpperCase()}
                   </CorpLogoDefault>
        }
        else {
            return <img className='corp-logo' alt="corp-logo" src={props.src}/>
        }
    }
}

export default ImageFallBack;

const CorpLogoDefault = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    color: #ffffff;
    background-color: #DEDFE2;
    font-size: 24px;
    align-items: center;
    justify-content: center;
`;
