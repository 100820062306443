import styled from 'styled-components';

const Root = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 552px;
    border-radius: 8px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.14),
        0px 2px 2px 0px rgba(0, 0, 0, 0.12),
        0px 1px 3px 0px rgba(0, 0, 0, 0.2);
    margin-bottom: 24px; 
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    height: 64px;
    background-color: #fafafa;
    box-shadow: 0px 1px 0px 0px rgb(237, 238, 239);

    button {
        height: 32px;
        width: 120px;
    }
`;

const HeaderCorpOffice = styled.div`
    display: flex;

    .corpOfficeTitle {
        display: flex;
        flex-direction: column;
    }

    .corp-logo {
        height: 40px;
        width: 40px;
        object-fit: contain;
        margin-right: 8px;
        border-radius: 4px;
    }

    p {
        margin: 0;
    }

    .Completed {
        color: #8bc34a;
    }
`;

const Status = styled.p`
    font-size: 14px;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 24px 0;
`;

const Detail = styled.div`
    display: flex;
    margin-bottom: 24px;
    
    i {
        color: #969ba5
        font-size: 16px;
        margin-right: 8px;
        padding-top: 2px;
    }

    .contact {
        display: flex;
        flex-direction: column;
        color: #00bcd4;
    }
    .waiting {
        color: #969ba5;
    }
`;

const Attachment = styled.a`
    margin-right: 16px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.14),
                0px 2px 2px 0px rgba(0, 0, 0, 0.12),
                0px 1px 3px 0px rgba(0, 0, 0, 0.2);
    height: 56px;
    img {
        height: 56px;
    }
`;

export default {
    Root,
    Header,
    HeaderCorpOffice,
    Status,
    Body,
    Detail,
    Attachment
}