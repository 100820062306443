import styled from 'styled-components';
import { mediaMax, mediaMin } from '../../../../shared/style'

const Banner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  gap: 24px;
  padding: 24px;
  background: #0B1C54;
  background-image: ${({domain}) => `url('${domain}/upfront-pricing/bg3.png')`};
  background-position: bottom 0px left;
  background-repeat: no-repeat;

  .h2 {
    color: #FFF;
  }

  .h4 {
    color: rgba(255, 255, 255, 0.75);
  }

  .p {
    color: rgba(255, 255, 255, 0.75);
  }

  ${mediaMax.tablet`
      flex-direction: column;
      border-radius: 0px;
      gap: normal;
      padding: 16px;
      background-image: none; 
      margin-left: -16px;
      margin-right: -16px;
    `}
`;

const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 26px;
  flex: 1 1 0;
  padding: 0px;

  ${mediaMax.tablet`
      padding: 0px 0px 16px 0px;
      gap:8px;
    `}
`;

const ProgressHeader = styled.h2`
  color: #FFF;
  font-family: Red Hat Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; 
  margin: 0px;

  ${mediaMax.tablet`
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  `}
`;

const ModalButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 100%;
  color: #FFFFFF;
  cursor: pointer;

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    text-align: center;
  }

  ${mediaMax.tablet`
    display: none;
  `}
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  flex: 1 1 0;

  .amps {
    display: flex;
    flex-direction: row;
    gap: 24px;
    width: 100%;
    justify-content: center;

    .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 16px;
      gap: 8px;
      border-radius: 4px;
      background: #17275D;
      flex: 1 1 0;

      ${mediaMax.tablet`
        padding: 8px;
        gap: 0;
      `}

      .boxHeader {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;

        .iconContainer {
          ${mediaMax.tablet`
            display: none;
          `}
        }
      }

      .capacityContent {
        display: flex;
        flex-direction: row;
        gap: 4px;
      }
    }

    ${mediaMax.tablet`
      gap: 8px;
    `}
  }
`;

const H2Progress = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin: 0px;
  color: ${({ color }) => `rgb(${color[0]}, ${color[1]}, ${color[2]})`};

  ${mediaMax.tablet`
    font-size: 16px;
    line-height: 20px; 
  `}
`;

const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;

  i {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${({color}) => color};
  }
`;

const BoxHeader = styled.p`
  color: rgba(255, 255, 255, 0.75);
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; 

  ${mediaMax.tablet`
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  `}
`;

const BoxText = styled.h2`
  color: #FFF;
  font-family: Red Hat Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; 
  margin: 0px;

  ${mediaMax.tablet`
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  `}
`;

const MobileModalButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 100%;
  color: #FFFFFF;
  cursor: pointer;

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    text-align: center;
  }

  ${mediaMin.tablet`
    display: none;
  `}
`;

export default {
  Banner,
  ProgressBarContainer,
  ProgressHeader,
  ModalButton,
  MobileModalButton,
  InfoContainer,
  H2Progress,
  IconBox,
  BoxHeader,
  BoxText
}