import React from "react";
import styled, { css } from "styled-components";

const bidRequest = props => {
  const { title, link, requestNumber } = props;
  return (
    <Wraper>
      {!link ? (
        <Title>{title}</Title>
      ) : (
        <LinkTitle href={link}>{title}</LinkTitle>
      )}
      <RequestNumber>Bid Request #{requestNumber}</RequestNumber>
    </Wraper>
  );
};
export default bidRequest;

const Wraper = styled.div`
  width: fit-content;
  margin-bottom: 25px;
`;

const title = css`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  color: #00bcd4;
  display: block;
  margin-bottom: 3px;
`;

const Title = styled.h2`
  ${title};
`;
const LinkTitle = styled.a`
  ${title};
`;
const RequestNumber = styled.p`
font-size: 12px;
margin: 0;
color: #6e727c;
line-height: 16px;
letter-spacing: 0.3px;
}`;
