import React, { Fragment } from "react";
import styledComponents from "./style";
import { sizes } from "../../../../../../shared/style";
import ellipsisH from "../../../../../../../../public/images/ellipsisH.svg";

export default class Collapse extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      style: {
        height: 0,
        overflow: "hidden"
      }
      // active: true,
      // style: {}
    };
    this.myRef = React.createRef();
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this.props.headerDetail.active && this.toggle();
  }

  toggle = () => {
    const { active } = this.state;
    window.innerWidth < sizes.phone && window.scrollTo({ top: 0, left: 0 });
    let bodyHeight = this.myRef.current.clientHeight;
    if (!active) {
      document.body.classList.add("requirements-collaps");
      document
        .getElementById("collapseCard")
        .classList.add("requirements-collapse");
    } else {
      document.body.classList.remove("requirements-collaps");
      document
        .getElementById("collapseCard")
        .classList.remove("requirements-collapse");
    }
    this.setState({
      active: !active,
      style: {
        height: bodyHeight,
        overflow: "hidden"
      }
    });

    setTimeout(() => {
      this.setState({
        style: active
          ? {
              height: 0,
              overflow: "hidden"
            }
          : {}
      });
    }, active ? 100 : 250);
  };

  render() {
    const {
      CollapseRoot,
      CollapseButton,
      Title,
      Dec,
      Body,
      Handler,
      Icon,
      CollapseButtonText,
      Arrow
    } = styledComponents;
    const { active, style } = this.state;
    const { icon, title, dec } = this.props.headerDetail;

    return (
      <CollapseRoot active={active} className={`${active && `active`}`}>
        <CollapseButton onClick={this.toggle} active={active}>
          <Icon>
            {icon === "ellipsis-h" ? (
              <img
                alt="Page Not Found"
                style={{ width: "100%" }}
                src={ellipsisH}
              />
            ) : (
              <i className={`fal fa-${icon}`} />
            )}
          </Icon>
          <CollapseButtonText>
            <Title>{title}</Title>
            <Dec>{dec}</Dec>
          </CollapseButtonText>
          <Arrow active={active}>
            <i className="far fa-chevron-right" />
          </Arrow>
        </CollapseButton>
        <Body active={active} style={style}>
          <Handler ref={this.myRef}>{this.props.children}</Handler>
        </Body>
      </CollapseRoot>
    );
  }
}
