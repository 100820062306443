import React, { Fragment } from 'react';
import commonStyles from '../../../../components/DesignPatternV2.0/commonStyles';

const {
  H2,
  P
} = commonStyles;

export default function HeaderSection({ partnerName }) {
  return (
    <Fragment>
      <H2 className='h2Body'>
        Finance your project?
      </H2>
      <P className='pBody'>
        You can apply to finance the total installation cost through {partnerName}. 
        If approved, {partnerName} will sell a voucher for the total installation cost. 
        The cost of the voucher will be included within the retail finance contract.
      </P>
    </Fragment>
  )
}