export default {
    base64: (file, resolve) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            if (resolve) {
                resolve(event.target.result.replace(/^data:.*;base64,/, ""))
            } else {
                return event.target.result.replace(/^data:.*;base64,/, "")
            }
        };

        reader.readAsDataURL(file);
    },
}
