import styled, { css } from "styled-components";
import {
  theme,
  headerSizes,
  fade,
  mediaMax,
  mediaMin
} from "../../shared/style";

const DropDownLabel = styled.label`
  color: #3e4148;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  height: 20px;
  letter-spacing: 0.25px;
  line-height: 20px;
  margin-bottom: 4px;
  span {
    color: #b9bbc1;
    font-family: Lato;
    font-size: 14px;
    font-style: italic;
    height: 20px;
    letter-spacing: 0.25px;
    line-height: 20px;
  }
`;

const DropDownWrapper = styled.div`
  position: relative;
  ${({ mb }) =>
    mb &&
    css`
      margin-bottom: ${mb}px;
    `};
`;
const DropDownBtn = styled.button`
  padding: 0 12px;
  cursor: pointer;
  width: 100%;
  height: 44px;
  border-radius: 4px;
  border: solid 1px ${theme.IndiqoGrey[300]};
  background-color: ${theme.IndiqoGrey.main};
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${theme.IndiqoGrey[800]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  i {
    color: ${({ clr }) => (clr ? "#b9bbc1" : theme.IndiqoGrey[500])};
    transition: 0.3s;
    ${props =>
      props.active &&
      css`
        transform: scaleY(-1);
      `};
  }
  span.placeholder {
    color: ${fade("#3e4148", 0.35)};
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
`;
const DropDownOptions = styled.div`
  position: absolute;
  overflow: auto !important;
  margin-top: 4px;
  top: 100%;
  left: 0;
  z-index: 99;
  width: 100%;
  height: ${props => (props.active ? `${props.count * 36}` : "0")}px;
  max-height: ${4 * 36}px;
  overflow: hidden;
  transition: 0.3s;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 6px 10px 0 rgba(0, 0, 0, 0.14);
`;
const DropDownOption = styled.button`
  height: 36px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${theme.IndiqoGrey[800]};
  background: ${theme.White};
  padding: 0 12px;
  border: 0;
  cursor: pointer;
  &:hover {
    background: ${theme.IndiqoGrey[100]};
  }
  i {
    font-size: 16px;
    color: ${theme.Primary.main};
  }
`;
export default {
  DropDownBtn,
  DropDownOption,
  DropDownOptions,
  DropDownWrapper,
  DropDownLabel
};
